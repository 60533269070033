import { Spinner, Text } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { ToggleContainer } from "../Generic/ToggleContainer";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import type { MachineToList } from "../Machines/models";
import { listAsyncMachines, selectMachinesStatus, selectMachinesToList } from "../Machines/reducer";
import type { ResponseProjectDetails } from "../Projects/models";
import { selectProjects } from "../Projects/reducer";
import { Stack } from "../Stack";
import { DataloggerAddDialog } from "./DataloggerAddEditDialogs";
import { DataloggersTable } from "./DataloggersTable";
import { DModels } from "./models";

type DataloggerToggleTableProps = {
  searchText: string;
  modelKey: string;
  isMetaDataContributor: boolean;
  projectsRef: ResponseProjectDetails[];
  machinesRef: MachineToList[];
  onToggleOpen?: (id: string) => void;
};

/**
 * Gets the command bar items
 * @param onAdd Function called when the Add button is clicked.
 * @returns The command bar items
 */
const commandBarItems = (t, title, isMetaDataContributor: boolean, onAdd: () => void): any => {
  const titleItemProp: any = {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => <Text style={titleStyle}>{title}</Text>,
  };

  if (!isMetaDataContributor) {
    return [titleItemProp];
  }

  return [
    titleItemProp,
    ...[
      {
        key: "add",
        text: t("Add"),
        type: CommandBarItemType.Button,
        icon: <AddRegular />,
        onClick: onAdd,
      },
    ],
  ];
};

/**
 * Gets the datalogger table inside a toggle container.
 * @param isMeasuredDataContributor A value indicating whether the current user is measured data contributor.
 * @param modelKey The datalogger model key.
 * @param projectsRef The reference project list to complete the dataloggers project information.
 * @param machinesRef The reference machines list, to complete the dataloggers machines information.
 * @param searchText The search text key to filtered the dataloggers table. If empty, all dataloggers will be shown.
 * @param onToggleOpen Function called when the toggle container is opening.
 * @returns
 */
const DataloggersToggleTable = ({
  isMetaDataContributor,
  modelKey,
  projectsRef,
  machinesRef,
  searchText,
  onToggleOpen,
}: DataloggerToggleTableProps) => {
  const [show, setShow] = useState(true);

  // Gets the dataloggers count after the API call, and decides whether to show the component.
  const onDataloggersRead = (count: number) => {
    setShow(count > 0);
  };

  return (
    <ToggleContainer
      id={`${modelKey}-section`}
      title={DModels[modelKey]}
      sideText={""}
      open={true}
      show={show}
      onOpen={onToggleOpen}
    >
      <DataloggersTable
        key={`${modelKey}-section`}
        model={DModels[modelKey]}
        isMetaDataContributor={isMetaDataContributor}
        projectsRef={projectsRef}
        machinesRef={machinesRef}
        searchText={searchText}
        onDataloggersRead={onDataloggersRead}
      />
    </ToggleContainer>
  );
};

/**
 * The Dataloggers list component.
 * @returns The dataloggers list component.
 */
export const DataloggersList = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const projects = useAppSelector(selectProjects);
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();

  // Gets the machines list, if it is null.
  useEffect(() => {
    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, [dispatch]);

  // Scrolls the elements to the desired table.
  const handleOnOpen = (id: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function called when the add click button is pressed.
  const onClickAdd = () => {
    if (addButtonClicked) {
      return;
    }

    setAddButtonClicked(true);
  };

  // Shows a spinner if the authentication permissions are being loaded. Otherwise, shows the tables.
  let result: JSX.Element | null = null;
  if (auth.loading) {
    result = (
      <Stack verticalFill verticalAlign='center' horizontalAlign='center' style={{ gap: 10 }}>
        <Spinner size='small' />
        <Text size={300}>{t("Loading data...")}</Text>
      </Stack>
    );
  } else {
    result = (
      <div style={pageStyle}>
        <BaseCommandBar
          items={computeCommandBarItems(
            commandBarItems(t, t("Dataloggers"), auth.metaDataContributor, onClickAdd),
          )}
          onSearch={(newValue) => setSearchText(newValue.trim())}
        />

        {React.Children.map(Object.keys(DModels), (key) => {
          return (
            <DataloggersToggleTable
              modelKey={key}
              isMetaDataContributor={auth.metaDataContributor}
              projectsRef={projects}
              machinesRef={machines}
              searchText={searchText}
              onToggleOpen={handleOnOpen}
            />
          );
        })}

        {addButtonClicked && (
          <DataloggerAddDialog
            onClose={() => {
              setAddButtonClicked(false);
            }}
          />
        )}
      </div>
    );
  }

  return result;
};
