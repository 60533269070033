import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { TabItemProps } from "../../../common/TabMenu";
import { TabMenu } from "../../../common/TabMenu";
import SensorHeader from "./SensorHeader";
import SettingsTab from "./SettingsTab";
import TriggersTab from "./TriggersTab";

export const TRIGGERS_TAB = "Triggers";
export const SETTINGS_TAB = "Settings";

const SensorTabs = ({ sensor }: any) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TRIGGERS_TAB);
  const tabItems: TabItemProps[] = [
    {
      key: TRIGGERS_TAB,
      title: t("Triggers"),
      content: <TriggersTab sensor={sensor} />,
      tabProps: {
        className: "tab-container",
      },
    },
    {
      key: SETTINGS_TAB,
      title: t("Settings"),
      content: <SettingsTab sensor={sensor} />,
      tabProps: {
        className: "tab-container",
      },
    },
  ];

  return (
    <>
      <SensorHeader sensor={sensor} activeTab={activeTab} />
      <TabMenu
        tabs={tabItems}
        selectedValue={activeTab}
        onTabSelect={(e, data) => {
          const activePivot = (data.value as string) ?? undefined;
          setActiveTab(activePivot || TRIGGERS_TAB);
        }}
      />
    </>
  );
};

export default SensorTabs;
