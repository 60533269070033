/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import type { CSSProperties } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ImageIcon from "../../../../../assets/svg/ImageIcon";
import ListIcon from "../../../../../assets/svg/ListIcon";
import { File } from "../../../../common/Preview";
import type { UploadedFile } from "../../../../common/Uploader/types";
import { Stack } from "../../../../Stack";

const linkStyle: CSSProperties = {
  color: "#0078d4",
  cursor: "pointer",
  fontSize: "12px",
  textDecoration: "underline",
  marginLeft: 4,
};

const HorizontalLine = () => <div className='horizontal-line' />;

function getFileIcon(type: string) {
  if (["jpg", "jpeg", "png"].find((format) => type.includes(format))) {
    return <ImageIcon />;
  }

  return <ListIcon />;
}

export default function Files({ files }: { files: UploadedFile[] }) {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState(false);

  function renderFile(file: UploadedFile, idx: number) {
    if (!seeMore && idx > 1) {
      return null;
    }

    return (
      <Stack key={file.id} horizontal className='document'>
        {getFileIcon(file?.type)}

        <File className='document-file' file={file} />
      </Stack>
    );
  }

  return (
    <>
      <HorizontalLine />
      <div>
        {files.map(renderFile)}
        {files.length > 2 && (
          <div style={linkStyle} onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? t(`Show less files`) : t("Show more files")}
          </div>
        )}
      </div>
    </>
  );
}
