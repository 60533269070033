/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import TextArea from "../../../common/TextArea";
import { Stack } from "../../../Stack";
import type { HistoricalChangesItemDetailed } from "../../Schema/models";
import { AutomationStatusType, ConnectionStatusType } from "../../Schema/models";
import { Utils } from "../../Utils/utils";
import { FormItemRow, FormItemSection } from "../generic/FormDialogComponents";

type HistoricalChangesDetailsDialogProps = {
  item: HistoricalChangesItemDetailed;
  onClose: () => void;
};

type HistoricalChangesDetailsData = {
  automationStatus: string;
  automationStatusComments: string;
  connectionStatus: string;
  connectionStatusComments: string;
  emailContacts: string;
  connectionDetails: string;
};

const timeStampStyle: CSSProperties = {
  backgroundColor: "#F3F2F1",
  padding: "0.8em",
};

/**
 * Gets the historical changes details dialog component.
 * @param item The historical changes detailed item.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The historical changes details dialog component.
 */
const HistoricalChangesDetailsDialog = ({ item, onClose }: HistoricalChangesDetailsDialogProps) => {
  const { t } = useTranslation();

  const [data, setData] = useState<HistoricalChangesDetailsData | null>(null);

  // Builds the data object.
  useEffect(() => {
    if (!item) {
      onClose?.();
      return;
    }

    const result: HistoricalChangesDetailsData = {
      automationStatus: AutomationStatusType[item.automationStatus.status],
      automationStatusComments: item.automationStatus.comment || "",
      connectionStatus: ConnectionStatusType[item.connectionStatus.status],
      connectionStatusComments: item.connectionStatus.comment || "",
      emailContacts: item.emailDetails || "",
      connectionDetails: item.connectionDetails || "",
    };

    setData(result);
  }, [item]);

  const timeStamp = new Date(item.timeStamp);

  return (
    <BaseDialog open={!!item} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onClose}>
      <BaseDialogTitle>{Utils.firstToUpperCase(item.projectName)}</BaseDialogTitle>

      <DialogContent>
        <Stack style={timeStampStyle}>
          <p className='label-value-text'>
            {Utils.getDate(timeStamp)} {Utils.getTime(timeStamp)}
            {"   "}
            {item.changedBy}
          </p>
        </Stack>
        <FormItemSection title={t("Automation Status")}>
          <FormItemRow label={t("Status:")}>
            <p className='label-value-text'>{data?.automationStatus || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Comments:"}>
            <p className='label-value-text'>{data?.automationStatusComments || ""}</p>
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={t("Connection Status")}>
          <FormItemRow label={t("Status:")}>
            <p className='label-value-text'>{data?.connectionStatus || ""}</p>
          </FormItemRow>
          <FormItemRow label={"Comments:"}>
            <p className='label-value-text'>{data?.connectionStatusComments || ""}</p>
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={t("Email Contacts")}>
          <TextArea
            readOnly
            style={{ width: "100%", height: "100px" }}
            value={data?.emailContacts || ""}
          />
        </FormItemSection>
        <FormItemSection title={t("Connection Details")}>
          <TextArea
            readOnly
            style={{ width: "100%", height: "200px" }}
            value={data?.connectionDetails || ""}
          />
        </FormItemSection>
        <DialogActions>
          <Button appearance='primary' onClick={() => onClose?.()}>
            {t("Done")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default HistoricalChangesDetailsDialog;
