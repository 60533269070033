import { Text } from "@fluentui/react-components";

import { Stack } from "../../Stack";

const Tab = ({ tab, isActive, onClick }) => {
  return (
    <div className={`settings-tab ${isActive ? "active" : ""}`} onClick={onClick}>
      <Stack
        horizontal
        style={{
          alignItems: "center",
        }}
      >
        {tab.hasIcon ? tab.icon : null}
        <Text>{tab.label}</Text>
      </Stack>
    </div>
  );
};

export default Tab;
