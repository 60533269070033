/* eslint-disable @typescript-eslint/no-explicit-any */
import { Switch } from "@fluentui/react-components";
import { format as fnsFormat } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import PeriodIcon from "../../../../assets/svg/TasksIcon";
import { ErrorDialog } from "../../../../modules/analysis-trend-view/components/TrendViewControls/ErrorDialog";
import { dropdownIconStyle } from "../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import useTrendViewControls from "../../../../modules/analysis-trend-view/components/TrendViewControls/useTrendViewControls";
import {
  getPeriodOptions,
  MAX_GROUP_THRESHOLD,
  PeriodOptionsEnum,
} from "../../../../modules/analysis-trend-view/constants/controlOptions";
import type { ControlsStore } from "../../../../modules/analysis-trend-view/hooks/useControlsStore";
import { FORMAT } from "../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../modules/common/components/CustomDateDialog";
import { onRenderLabel } from "../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../common/Dropdown";
import type { TabItemProps } from "../../../common/TabMenu";
import { TabMenu } from "../../../common/TabMenu";
import useSummaryStore from "../hooks/useSummaryStore";
import type { SectionCommandBarItem } from "../SectionContainer";
import SectionContainer from "../SectionContainer";
import { AlertsTab } from "./AlertsTab";
import { OperationsTab } from "./OperationsTab";
import OperationCommandBar from "./OperationsTab/OperationCommandBar";
import { ReportsTab } from "./ReportsTab";
import { TasksTab } from "./TasksTab";
import { TrendTab } from "./TrendTab";

const TAB_TITLES = {
  Trend: "Trend",
  Reports: "Reports",
  Tasks: "Tasks",
  Operations: "Operations",
  Alerts: "Alerts",
};

type TabTitlesType = "Trend" | "Reports" | "Tasks" | "Operations" | "Alerts";

const tabItems = (search: string, t): TabItemProps[] => [
  {
    key: TAB_TITLES.Trend,
    title: t("Trend"),
    content: <TrendTab />,
  },
  {
    key: TAB_TITLES.Reports,
    title: t("Reports"),
    content: <ReportsTab search={search} />,
  },
  {
    key: TAB_TITLES.Tasks,
    title: t("Tasks"),
    content: <TasksTab search={search} />,
  },
  {
    key: TAB_TITLES.Operations,
    title: t("Operations"),
    content: <OperationsTab />,
    tabProps: {
      className: "operation-tab-pivot",
    },
  },
  {
    key: TAB_TITLES.Alerts,
    title: t("Alerts"),
    content: <AlertsTab search={search} />,
    tabProps: {
      className: "operation-tab-pivot",
    },
  },
];

export const DEFAULT_TAB_MAX_HEIGHT = 530;

function PeriodDropdown({ t, controlsStore, onPeriodChange }) {
  const { transparent } = useDropdownStyles();
  return (
    <Dropdown
      className={transparent}
      style={{ minWidth: "max-content" }}
      button={onRenderLabel({
        label: t("Period"),
        icon: <PeriodIcon fill='#2c529f' style={dropdownIconStyle} />,
        text: t(controlsStore.period?.text),
      })}
      options={getPeriodOptions(t)}
      selectedOptions={[controlsStore.period?.key || ""]}
      onOptionSelect={onPeriodChange}
    />
  );
}

type CommandBarProps = {
  t: any;
  activeTab: TabTitlesType;
  controlsStore: ControlsStore;
  groupSignals: boolean;
  onPeriodChange: (event: any, option?: any | undefined, index?: number | undefined) => void;
  onGroupSignalsChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => void;
};

const getCommandBarItems = ({
  t,
  activeTab,
  controlsStore,
  groupSignals,
  onPeriodChange,
  onGroupSignalsChange,
}: CommandBarProps): SectionCommandBarItem[] => {
  const trendButtons: SectionCommandBarItem[] = [
    {
      key: "section-trends-period-dropdown",
      onRender: () => (
        <PeriodDropdown t={t} controlsStore={controlsStore} onPeriodChange={onPeriodChange} />
      ),
    },
    {
      key: "section-trends-average-trend-toggle",
      onRender: () => (
        <Switch
          label={t("Average trend")}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 20,
          }}
          checked={controlsStore.averageTrend}
          onChange={(_, { checked }) => controlsStore.changeAverageTrend(checked)}
        />
      ),
    },
    {
      key: "section-trends-group-signals-toggle",
      onRender: () => (
        <Switch
          label={t("Group Signals")}
          checked={groupSignals}
          style={{ marginBottom: 0, marginLeft: 20 }}
          onChange={(e, { checked }) => onGroupSignalsChange(e as any, checked)}
        />
      ),
    },
  ];

  const operationsBar: SectionCommandBarItem = {
    key: "section-details-operations-bar",
    onRender: () => <OperationCommandBar />,
  };

  if (activeTab === TAB_TITLES.Trend) return trendButtons;
  else if (activeTab === TAB_TITLES.Operations) return [operationsBar];
  else return [];
};

export const DetailsSection = ({ ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab]: any = useState(TAB_TITLES.Reports);
  const [search, setSearch] = useState("");
  const [visibleCustomDateDialog, setVisibleCustomDateDialog] = useState(false);
  const { controlsStore } = useTrendViewControls();

  const {
    selectedSignals,
    isWarningModalOpen,
    setIsWarningModalOpen,
    isGroupedEverything,
    setIsGroupedEverything,
  } = useSummaryStore((state) => ({
    selectedSignals: state.selectedSignals,
    isWarningModalOpen: state.isWarningModalOpen,
    setIsWarningModalOpen: state.setIsWarningModalOpen,
    isGroupedEverything: state.isGroupedEverything,
    setIsGroupedEverything: state.setIsGroupedEverything,
  }));

  const errorMessage = t(
    `You cannot have more than <b>{{MAX_GROUP_THRESHOLD}} signals selected</b> in <b>Grouped Signals mode</b>. Please remove\nsignals until there are <b>maximum {{MAX_GROUP_THRESHOLD}}</b>.`,
    { MAX_GROUP_THRESHOLD },
  );

  const handleDismiss = () => setIsWarningModalOpen(false);

  const onCustomDateChange = ({ start, end }: any) => {
    controlsStore.changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: t(PeriodOptionsEnum.CustomDate),
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  const onPeriodChange = (e: any, item: any) => {
    const key = item.optionValue;
    const text = item.optionText;
    if (key !== PeriodOptionsEnum.CustomDate) {
      controlsStore.changePeriod({ key, text });
    } else {
      setVisibleCustomDateDialog(true);
    }
  };

  function handleGroupSignalsChange(_: any, checked: boolean | undefined) {
    return selectedSignals.length > MAX_GROUP_THRESHOLD
      ? setIsWarningModalOpen(true)
      : setIsGroupedEverything(checked);
  }

  return (
    <SectionContainer
      title={t("Details")}
      commandBarItems={getCommandBarItems({
        t,
        activeTab,
        controlsStore,
        groupSignals: isGroupedEverything,
        onPeriodChange,
        onGroupSignalsChange: handleGroupSignalsChange,
      })}
      searchBoxProps={{
        defaultValue: search,
        onChange: (_, { value: newValue }) => setSearch(newValue ?? ""),
        style: {
          display:
            activeTab === TAB_TITLES.Operations || activeTab === TAB_TITLES.Trend ? "none" : "flex",
          width: 300,
        },
      }}
      {...rest}
    >
      <TabMenu
        tabsContainer={{ className: "details-section-tabs-container" }}
        tabs={tabItems(search, t)}
        appearance='filled-circular'
        selectedValue={activeTab}
        style={{ padding: "8px" }}
        stackProps={{ style: { height: "100%" } }}
        onTabSelect={(e, data) => {
          setActiveTab(data.value);
          setSearch("");
        }}
      />
      <CustomDateDialog
        initialValues={controlsStore.period}
        hidden={!visibleCustomDateDialog}
        setVisible={setVisibleCustomDateDialog}
        onSubmit={onCustomDateChange}
      />
      <ErrorDialog open={isWarningModalOpen} content={errorMessage} onDismiss={handleDismiss} />
    </SectionContainer>
  );
};
