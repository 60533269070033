import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { ObjectWithKey } from "../../../../types";

type Actions =
  | { type: "SET_SELECTION"; payload: ObjectWithKey[] }
  | { type: "SHOW_DELETE"; payload: boolean }
  | { type: "SHOW_UPLOAD"; payload: boolean };

export interface DocumentsStore {
  selectedItems: ObjectWithKey[];
  showDelete: boolean;
  showUpload: boolean;
  dispatch: (action: Actions) => void;
}

const reducer = (state: DocumentsStore, action: Actions): DocumentsStore => {
  switch (action.type) {
    case "SET_SELECTION": {
      return {
        ...state,
        selectedItems: action.payload,
      };
    }
    case "SHOW_DELETE": {
      return {
        ...state,
        showDelete: action.payload,
      };
    }
    case "SHOW_UPLOAD": {
      return {
        ...state,
        showUpload: action.payload,
      };
    }
  }
};

const useDocumentsStore = createWithEqualityFn<DocumentsStore>(
  (set) => ({
    selectedItems: [],
    showDelete: false,
    showUpload: false,
    dispatch: (action) => set((state) => reducer(state, action)),
  }),
  shallow,
);

export default useDocumentsStore;
