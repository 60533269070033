import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { ObjectWithKey } from "../../../../types";

export enum ThreadCreated {
  Today = "Today",
  PastWeek = "Past Week",
  PastMonth = "Past Month",
  PastYear = "Past Year",
  AllTime = "All Time",
}

type Actions =
  | { type: "SET_SELECTION"; payload: ObjectWithKey[] }
  | { type: "SHOW_DELETE"; payload: boolean }
  | { type: "SHOW_ADD"; payload: boolean };

export interface ThreadsStore {
  selectedItems: ObjectWithKey[];
  showDelete: boolean;
  showAdd: boolean;
  dispatch: (action: Actions) => void;
}

const reducer = (state: ThreadsStore, action: Actions): ThreadsStore => {
  switch (action.type) {
    case "SET_SELECTION": {
      return {
        ...state,
        selectedItems: action.payload,
      };
    }
    case "SHOW_DELETE": {
      return {
        ...state,
        showDelete: action.payload,
      };
    }
    case "SHOW_ADD": {
      return {
        ...state,
        showAdd: action.payload,
      };
    }
  }
};

const useThreadsStore = createWithEqualityFn<ThreadsStore>(
  (set) => ({
    selectedItems: [],
    showDelete: false,
    showAdd: false,
    dispatch: (action) => set((state) => reducer(state, action)),
  }),
  shallow,
);

export default useThreadsStore;
