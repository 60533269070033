import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatFileSize } from "../../schema/Utils";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import type { TableProps } from "../common/Table/v9/Table";
import Table from "../common/Table/v9/Table";
import Uploader from "../common/Uploader";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

const getColumns = ({ t, onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      return (
        <Link href='#' style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}>
          {isValid ? formatFileSize(size) : t("Too large")}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: "Actions",
    minWidth: 100,
    onRender: ({ id }) => (
      <Tooltip relationship='label' content={t("Delete")}>
        <Button
          icon={<DeleteRegular />}
          aria-label={t("Delete")}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(id);
          }}
        />
      </Tooltip>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type AddDocumentDialogProps = {
  hidden: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (files: UploadedFile[]) => void;
  extension: string;
};

const AddDocumentDialogOneFile = ({
  hidden,
  isSubmitting,
  onClose,
  onSubmit,
  extension,
}: AddDocumentDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<AcceptedFile[]>([]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog-machine-metadata",
        version: 1,
      },
      items: localFiles.sort((a, b) => (a.name > b.name ? 1 : -1)),
      v8Columns: getColumns({
        t,
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
      hidePerPage: true,
    }),
    [localFiles, t],
  );

  useEffect(() => {
    if (hidden) {
      setLocalFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const onFormSubmit = (data) => {
    const files = localFiles.map((file) => ({ ...file, ...data }));
    onSubmit(files);
  };

  const isDisabled = useMemo(
    () => localFiles.filter(({ isValid }) => isValid).length === 0,
    [localFiles],
  );

  const onUpload = (accepted: AcceptedFile[]) => {
    const aux = [...accepted];
    const unique = aux.filter(
      (obj, index) =>
        aux.findIndex((item) => item.name === obj.name && item.size === obj.size) === index,
    );
    !(unique.length === aux.length) && notification.warning(t("Some file(s) are already added"));
    setLocalFiles(unique);
  };

  return (
    <BaseDialog open={!hidden} onOpenChange={onClose}>
      <BaseDialogTitle>{t("Add new document")}</BaseDialogTitle>
      <DialogContent>
        <Uploader accept={extension} multiple={false} onChange={onUpload} />
        <Table {...tableProps} />
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isDisabled || isSubmitting}
            icon={isSubmitting ? <Spinner size='extra-tiny' /> : null}
            onClick={onFormSubmit}
          >
            {t("Upload")}
          </Button>
          <Button onClick={onClose}>{t("Cancel")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddDocumentDialogOneFile;
