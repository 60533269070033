import type { InputProps } from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import { Controller } from "react-hook-form";

import TextField from "../../TextField";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormTextFieldProps = BaseFormFieldProps &
  InputProps & {
    name: string;
    fieldProps: any;
  };

export const FormTextField = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  fieldProps,
  ...rest
}: FormTextFieldProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <Field
          validationMessage={errors?.[name]?.message}
          style={{ width: "100%" }}
          {...fieldProps}
        >
          <TextField
            value={value}
            style={{ width: "100%" }}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          />
        </Field>
      )}
    />
  </FormGroup>
);
