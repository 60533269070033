import React from "react";
import { useTranslation } from "react-i18next";

import TrendViewGroupedSection from "../../../../../modules/analysis-trend-view/components/TrendViewGroupedSection";
import { TrendViewPlaceholder } from "../../../../../modules/analysis-trend-view/components/TrendViewPlaceholder";
import { SuspenseSpin } from "../../../../Analysis/TrendViewPage/TrendViewPage";
import Pagination from "../../../../common/Pagination";
import useSummaryStore from "../../hooks/useSummaryStore";

const TrendViewSection = React.lazy(
  () =>
    import(
      "../../../../../modules/analysis-trend-view/components/TrendViewSection/TrendViewSection"
    ),
);

export const TrendTab = () => {
  const { t } = useTranslation();

  const { selectedSignals, isGroupedEverything } = useSummaryStore((state) => ({
    isGroupedEverything: state.isGroupedEverything,
    selectedSignals: state.selectedSignals,
  }));

  if (selectedSignals.length === 0) {
    return (
      <div style={{ height: "100%" }}>
        <TrendViewPlaceholder />
      </div>
    );
  }

  if (isGroupedEverything) {
    return (
      <React.Suspense fallback={<SuspenseSpin />}>
        <TrendViewGroupedSection
          title={t("Grouped Signals")}
          signals={selectedSignals}
          machineId='all'
          chartStyles={{ loading: { height: "100%" } }}
          style={{ height: "100%" }}
        />
      </React.Suspense>
    );
  }

  return (
    <Pagination.Items
      hidePerPage={true}
      perPage={4}
      style={{ marginBottom: "1rem" }}
      items={selectedSignals.map((selectedSignal) => ({
        key: `trend-view-section-${selectedSignal.id}`,
        Element: (
          <React.Suspense fallback={<SuspenseSpin />}>
            <TrendViewSection
              signal={selectedSignal}
              headerProps={{
                noTitle: true,
                noStatistics: true,
                noMaximize: true,
              }}
              chartStyles={{ chart: { height: 300 } }}
            />
          </React.Suspense>
        ),
      }))}
    />
  );
};
