import { Spinner } from "@fluentui/react-components";
import React from "react";
import { Tree } from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import { NoData } from "../../../../common/NoData";
import { useWSSidebarData } from "../../../hooks/useWSSidebarData";
import SidebarNode from "./SidebarNode";

export const WSSidebar = () => {
  const { t } = useTranslation();
  const { required, handlers, isLoading } = useWSSidebarData();

  if (isLoading) {
    return <Spinner size='small' label={t("Loading sensors...")} />;
  }

  if (required.data.length === 0) {
    return (
      <NoData
        text={t("There is no data!")}
        style={{
          width: "100%",
        }}
      />
    );
  }

  return (
    <Tree
      {...required}
      {...handlers}
      disableLines
      depthGap={10}
      renderNode={(props) => <SidebarNode {...props} />}
    />
  );
};
