import { t } from "i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export interface Range {
  startIndex: number;
  endIndex: number;
}

export interface ConnectedTimeRange {
  range: Range;
  signalId: string;
}

export interface ControlsStore {
  loadingCharts: boolean;
  setLoadingCharts: (loadingCharts: boolean) => void;
  trendsGrouping: any | undefined;
  referenceSignal: any | undefined;
  period: any;
  isTimeAxisConnected: boolean | undefined;
  averageTrend: boolean | undefined;
  connectedTimeRange: ConnectedTimeRange | null;
  changeTrendsGrouping: (trendsGrouping: any | undefined) => void;
  changeReferenceSignal: (referenceSignal: any | undefined) => void;
  changePeriod: (period: any) => void;
  changeTimeAxis: (isTimeAxisConnected: boolean | undefined) => void;
  setConnectedTimeRange: (connectedTime: ConnectedTimeRange | null) => void;
  changeAverageTrend: (averageTrend: boolean | undefined) => void;
  getReferenceSignal: () => any;
  getPeriod: () => any;
}

const useControlsStore = createWithEqualityFn<ControlsStore>(
  (set, get) => ({
    trendsGrouping: { key: "separated", text: t("separated") },
    referenceSignal: undefined,
    period: {
      key: "Last month",
      text: t("Last month"),
      startDate: null,
      endDate: null,
    },
    isTimeAxisConnected: false,
    averageTrend: false,
    connectedTimeRange: null,
    loadingCharts: false,
    getReferenceSignal: () => get().referenceSignal,
    getPeriod: () => get().period,
    changeTrendsGrouping: (trendsGrouping: any | undefined) =>
      set((state) => ({
        ...state,
        trendsGrouping,
        connectedTimeRange: null,
        referenceSignal: trendsGrouping?.key !== "separated" ? undefined : state.referenceSignal,
      })),
    changeReferenceSignal: (referenceSignal: any | undefined) => set({ referenceSignal }),
    changePeriod: (period: any | undefined) => set({ period, connectedTimeRange: null }),
    changeTimeAxis: (isTimeAxisConnected: boolean | undefined) =>
      set((state) => ({
        ...state,
        isTimeAxisConnected,
        connectedTimeRange: isTimeAxisConnected ? state.connectedTimeRange : null,
      })),
    changeAverageTrend: (averageTrend: boolean | undefined) =>
      set((state) => ({
        ...state,
        averageTrend,
      })),
    setConnectedTimeRange: (connectedTime: ConnectedTimeRange | null) =>
      set((state) => ({
        ...state,
        connectedTimeRange: state.isTimeAxisConnected ? connectedTime : null,
      })),
    setLoadingCharts: (loadingCharts: boolean) =>
      set((state) => ({
        ...state,
        loadingCharts,
      })),
  }),
  shallow,
);

export default useControlsStore;
