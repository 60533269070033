import type { DialogProps, SelectTabData, SelectTabEvent } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Field,
  Label,
  Link,
  Spinner,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { SkypeCheckIcon } from "@fluentui/react-icons-mdl2";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import type { RoleType, ScopeLevelType } from "../../schema/status";
import { Status } from "../../schema/status";
import { format } from "../../schema/Utils";
import Checkbox from "../common/Checkbox";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { getDayPickerStrings } from "../common/Form";
import { NoData } from "../common/NoData";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table from "../common/Table/v9";
import type { ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import { ScopeSelections } from "../ServiceAccount/ScopesSelections";
import { Stack } from "../Stack";
import type { IRole } from "./models";
import { listAsyncRoles, selectRoles, selectRolesStatus } from "./reducer";
import type { CustomItemsType } from "./ScopesOfRole/reducer";
import {
  listAsyncScopesOfRole,
  selectScopesOfRoleItems,
  selectScopesOfRoleStatus,
} from "./ScopesOfRole/reducer";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

type AddRoleProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  onClose: () => void;
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<React.SetStateAction<ServiceAccountAddRoleToShow[]>>;
};

interface pivotsProps {
  name: string;
  completed: boolean;
  availableOnComplete: string[];
  next?: string;
  prev?: string;
}

interface pivotsCustom {
  selected: string;
  items: pivotsProps[];
}

export type RoleItemDescription = {
  roleId: string;
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  scopeLevelId?: string | undefined;
};

export const AddRole = ({ show, onClose, rolesToAdd, setRolesToAdd, ...rest }: AddRoleProps) => {
  const { t } = useTranslation();
  const [pivotProps, setPivotProps] = useState<pivotsCustom>({
    selected: "role",
    items: [],
  });
  const dispatch = useAppDispatch();
  const [rolesId, setRolesId] = useState<RoleItemDescription[] | undefined>();

  const rolesStatus = useAppSelector(selectRolesStatus);
  const roles = useAppSelector(selectRoles);
  const allItems: CustomItemsType[] = useAppSelector(selectScopesOfRoleItems);
  const scopePerRoleStatus = useAppSelector(selectScopesOfRoleStatus);
  const [withOutExpiration, setWithOutExpiration] = useState(false);
  const [controlledItems, setControlledItems] = useState<ControlledItem[]>();

  const [expiresAtUtc, setExpiresAtUtc] = useState<string>();

  useEffect(() => {
    rolesId &&
      setControlledItems(
        allItems?.map((ele) => {
          if (ele.name === "name") return { ...ele, check: true };
          else return { ...ele, check: false };
        }),
      );
    return () => {};
  }, [allItems, rolesId]);

  useEffect(() => {
    if (rolesStatus === Status.void) dispatch(listAsyncRoles());
    else if (rolesStatus === Status.idle && rolesId)
      dispatch(listAsyncScopesOfRole(rolesId.at(0).roleId));
  }, [dispatch, rolesStatus, rolesId]);

  useEffect(() => {
    setPivotProps({
      selected: pivotProps.selected,
      items: [
        {
          name: "role",
          completed: !!rolesId,
          availableOnComplete: ["scope"],
          next: "scope",
        },
        {
          name: "scope",
          completed: rolesId && controlledItems?.filter((ele) => ele?.check === true).length > 0,
          availableOnComplete: ["role", "expiration"],
          next: "expiration",
          prev: "role",
        },
        {
          name: "expiration",
          completed: rolesId && controlledItems?.filter((ele) => ele?.check === true).length > 0,
          availableOnComplete: ["role", "scope", "review"],
          next: "review",
          prev: "members",
        },
        {
          name: "review",
          completed: rolesId && controlledItems?.filter((ele) => ele?.check === true).length > 0,
          availableOnComplete: ["role", "scope", "expiration"],
          prev: "expiration",
        },
      ],
    });
    return () => {};
  }, [pivotProps.selected, rolesId, controlledItems]);

  const handleClose = () => {
    setWithOutExpiration(false);
    setPivotProps({
      selected: "role",
      items: [],
    });
    setRolesId(undefined);

    onClose?.();
  };

  const handleAdd = async () => {
    const auxItems: ServiceAccountAddRoleToShow[] = [];
    const filteredItems = controlledItems?.filter((ele) => ele?.check === true);

    rolesId.forEach((rol) =>
      filteredItems.forEach((ele) =>
        auxItems.push({
          roleId: rol.roleId,
          scopeLevelId: ele.scopeId,
          resource: ele.name,
          role: rol.role,
          scopeResourceId: ele.scopeResourceId,
          scopeLevel: ele.scope,
          expiresAtUtc: expiresAtUtc,
        }),
      ),
    );

    setWithOutExpiration(false);
    setPivotProps({
      selected: "role",
      items: [],
    });

    const filteredArr = [...auxItems, ...rolesToAdd].reduce((acc, current) => {
      const x = acc.find(
        (item) =>
          item.roleId === current.roleId &&
          item.scopeLevelId === current.scopeLevelId &&
          item.scopeResourceId === current.scopeResourceId,
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        notification.info(t("One or more items are already selected."));
        return acc;
      }
    }, []);

    setRolesToAdd(filteredArr);

    handleClose();
  };

  const getColumns = () => {
    const columns: Column[] = [
      {
        key: "type",
        name: t("Name"),
        fieldName: "Name",
        minWidth: 150,
        isSortable: true,
        onRender: (item: IRole) => (
          <Link
            onClick={(e) => {
              setPivotProps({ ...pivotProps, selected: "scope" });
              setRolesId([{ roleId: item.id, role: item.type }]);
            }}
          >
            {item.type}
          </Link>
        ),
      },
      {
        key: "description",
        name: t("Description"),
        fieldName: "description",
        minWidth: 200,
        isSortable: true,
        onRender: (item: IRole) => {
          return <span>{t(item.description || "")}</span>;
        },
      },
      {
        key: "hasScope",
        name: t("Has Scope"),
        fieldName: "hasScope",
        minWidth: 70,
        isSortable: true,
        onRender: (item: IRole) => (
          <div style={{ display: "flex" }}>{item.hasScope.toString()}</div>
        ),
      },
      {
        key: "parent",
        name: t("Parent"),
        fieldName: "parent",
        minWidth: 150,
        isSortable: true,
        onRender: (item: IRole) => (
          <div style={{ display: "flex" }}>{item.parent ? item.parent.type : undefined}</div>
        ),
      },
    ];
    return columns;
  };

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    const myProps = pivotProps.items.find((ite) => ite.name === pivotProps?.selected);
    const myPropsToGo = pivotProps.items.find((ite) => ite.name === data.value);

    if (myProps?.completed || myPropsToGo?.completed)
      setPivotProps({
        selected: data.value as string,
        items: [...pivotProps.items],
      });
    else {
      notification.warning(t("Please complete the {{name}} selection", { name: myProps.name }));
    }
  };

  const rolePivot = useCallback(() => {
    return (
      <Table
        // perPage={roles.length}
        persistOpts={{
          key: "table-role",
          version: 2,
        }}
        header={{
          title: t("Role selection"),
        }}
        items={roles}
        v8Columns={getColumns()}
        isLoading={rolesStatus === Status.loading}
        isError={rolesStatus === Status.error}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, t]);

  const scopePivot = useCallback(() => {
    return (
      <>
        {scopePerRoleStatus === Status.idle ? (
          <Stack>
            <ScopeSelections
              controlledItems={controlledItems}
              setControlledItems={setControlledItems}
            />
          </Stack>
        ) : scopePerRoleStatus === Status.loading ? (
          <Spinner />
        ) : (
          <Stack style={{ height: "100vh" }} verticalAlign='space-around'>
            <NoData />
          </Stack>
        )}
      </>
    );
  }, [scopePerRoleStatus, controlledItems]);

  const expirationPivot = useCallback(() => {
    return (
      <Stack horizontalAlign='center'>
        <Checkbox
          label={t("Without expiration date.")}
          style={{ padding: 2 }}
          checked={withOutExpiration}
          onChange={(checked) => {
            setWithOutExpiration(checked);
            if (checked === true) setExpiresAtUtc(undefined);
          }}
        />
        {!withOutExpiration ? (
          <>
            <Field style={{ gap: 8 }}>
              <Label style={{ textAlign: "center" }}>{t("Select the expiration date")}</Label>
              <DatePicker
                style={{ width: "20vw", textAlign: "center" }}
                placeholder={t("Select a date")}
                aria-label={t("Select a date")}
                strings={getDayPickerStrings(t)}
                onSelectDate={(date) => setExpiresAtUtc(format(date))}
              />
            </Field>
            <h5>{t("Permission expires on (UTC)")}</h5>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  }, [withOutExpiration]);

  const reviewPivot = useCallback(() => {
    const filteredItems = controlledItems?.filter((ele) => ele?.check === true);
    return (
      <>
        {
          <>
            <Stack verticalFill horizontalAlign='baseline' style={{ padding: 40, paddingLeft: 80 }}>
              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3>{t("Role:")}</h3>
                </Stack.Item>
                <>
                  {rolesId.map((rol) => (
                    <div key={rol.roleId}>
                      <Stack.Item>{rol.role}</Stack.Item>
                    </div>
                  ))}
                </>
              </Stack>

              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3>{t("Scopes:")} </h3>
                </Stack.Item>
                <Stack.Item>
                  {filteredItems?.map((ele) => (
                    <div key={ele.scopeResourceId}>
                      <Stack.Item>
                        <b>{ele.scope}</b>: {ele.name}.
                      </Stack.Item>
                    </div>
                  ))}
                </Stack.Item>
              </Stack>

              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3> {t("Expires:")} </h3>
                </Stack.Item>
                <Stack.Item>{expiresAtUtc ? t("True") : t("False")}</Stack.Item>
              </Stack>
              {expiresAtUtc ? (
                <Stack horizontal>
                  <Stack.Item style={{ width: "20vw" }}>
                    <h3> {t("Expires on:")} </h3>
                  </Stack.Item>
                  <Stack.Item>{expiresAtUtc}</Stack.Item>
                </Stack>
              ) : null}
            </Stack>
          </>
        }
      </>
    );
  }, [controlledItems, expiresAtUtc, rolesId]);

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{ width: DialogSize.L }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Add Role")}</BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={pivotProps.selected}
          aria-label={t("Pivot to add permission")}
          onTabSelect={onTabSelect}
        >
          <Tab value='role'>
            <div>
              {rolesId ? (
                <>
                  <SkypeCheckIcon /> {t("Role")}
                </>
              ) : (
                <> {t("Role")}</>
              )}
            </div>
          </Tab>
          <Tab value='scope'>
            <div>
              {rolesId && controlledItems?.filter((ele) => ele?.check === true).length > 0 ? (
                <>
                  <SkypeCheckIcon />{" "}
                  {t("Scope [{{scopeName}}]", {
                    scopeName: controlledItems
                      ?.filter((item) => item.check === true)
                      .length.toString(),
                  })}
                </>
              ) : (
                <> {t("Scope")}</>
              )}
            </div>
          </Tab>
          <Tab value='expiration'>
            <div>
              {rolesId &&
              controlledItems?.filter((ele) => ele?.check === true).length > 0 &&
              (expiresAtUtc || withOutExpiration) ? (
                <>
                  <SkypeCheckIcon /> {t("Expiration")}
                </>
              ) : (
                <> {t("Expiration")}</>
              )}
            </div>
          </Tab>
          <Tab value='review'>{t("Review")}</Tab>
        </TabList>
        {pivotProps.selected === "role" && (
          <div
            style={{
              overflowY: "auto",
              height: "60vh",
              position: "relative",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {rolePivot()}
          </div>
        )}
        {pivotProps.selected === "scope" && (
          <div
            style={{
              height: "60vh",
            }}
          >
            {scopePivot()}
          </div>
        )}
        {pivotProps.selected === "expiration" && (
          <div
            style={{
              overflowY: "auto",
              height: "60vh",
              position: "relative",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack verticalFill verticalAlign='center' style={{ height: "50vh" }}>
              {expirationPivot()}
            </Stack>
          </div>
        )}
        {pivotProps.selected === "review" && rolesId && (
          <div
            style={{
              overflowY: "auto",
              height: "60vh",
              position: "relative",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {reviewPivot()}
          </div>
        )}
        <DialogActions>
          <Button
            appearance='primary'
            disabled={
              !(rolesId && controlledItems?.filter((ele) => ele?.check === true).length > 0)
            }
            onClick={handleAdd}
          >
            {t("Add Role")}
          </Button>
          <Button onClick={handleClose}>{t("Close")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
