/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import TextField from "../../../common/TextField";
import type { FormDialogProps } from "../../../Generic/FormDialog";
import FormDialog from "../../../Generic/FormDialog";
import FormItemRow from "../../../Generic/FormItemRow";
import { UploadDefaultButton } from "../../../Generic/UploadDefaultButton";
import { Stack } from "../../../Stack";
import { HaagMappingApi } from "./api";

type FormData = {
  sourceIdentifier?: string;
  file?: File;
};

type HaagMappingImportDialogProps = Omit<
  FormDialogProps,
  "title" | "isLoading" | "isValid" | "type" | "size" | "onSubmit" | "onClose" | "submitButtonText"
> & {
  machineId: string;
  onClose: (refresh: boolean) => void;
};

const HaagMappingImportDialog = ({ machineId, onClose, ...rest }: HaagMappingImportDialogProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({});
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const isValid: boolean = useMemo(() => {
    return formData.sourceIdentifier?.length > 0 && formData.file !== undefined;
  }, [formData]);

  // Imports the file
  useEffect(() => {
    if (!isImporting || !machineId) {
      return;
    }

    let mount = true;
    HaagMappingApi.import(machineId, formData.sourceIdentifier, formData.file).then((response) => {
      if (!mount) {
        return;
      }

      setIsImporting(false);
      if (response.status !== 200) {
        notification.error(
          response["text"]?.message
            ? t(`Error importing HAAG mapping entries: {{errorMessage}}`, {
                errorMessage: response["text"]?.message,
              })
            : t(`Error importing HAAG mapping entries: Unknown error`),
        );
        return;
      }

      notification.success(
        t(
          `All HAAG mapping entries with the source identifier {{sourceIdentifier}} were successfully created.`,
          { sourceIdentifier: formData.sourceIdentifier },
        ),
      );
      onClose?.(true);
    });

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [isImporting]);

  return (
    <FormDialog
      {...rest}
      title={t("Import HAAG Mapping")}
      isLoading={isImporting}
      isValid={isValid}
      size={DialogSize.M}
      submitButtonText={isImporting ? t("Importing...") : t("Import")}
      onSubmit={() => setIsImporting(true)}
      onClose={() => onClose?.(false)}
    >
      <FormItemRow label={t("Source Identifier")} htmlFor={"haag-source-identifier"}>
        <TextField
          id='haag-source-identifier'
          value={formData.sourceIdentifier || ""}
          onChange={(newValue) => setFormData({ ...formData, sourceIdentifier: newValue })}
        />
      </FormItemRow>
      <FormItemRow label={t("File")} htmlFor={"haag-file"}>
        <Stack horizontal verticalAlign='center' style={{ columnGap: "1em" }}>
          <TextField readOnly id='haag-file' value={formData?.file?.name || ""} />
          <UploadDefaultButton
            disabled={isImporting}
            accept={".json"}
            onFilesSelected={(files) => setFormData({ ...formData, file: files.at(0) })}
          />
        </Stack>
      </FormItemRow>
    </FormDialog>
  );
};

export default HaagMappingImportDialog;
