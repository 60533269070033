import { Button, Tooltip } from "@fluentui/react-components";
import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import { EditIcon, ImportIcon, RefreshIcon } from "@fluentui/react-icons-mdl2";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { HaagMappingData } from "../../../../Hooks/useHaagMapping";
import { Status } from "../../../../schema/status";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";
import type { Column } from "../../../common/Table/v9";
import Table, { useTableFilters } from "../../../common/Table/v9";
import { Stack } from "../../../Stack";
import type { ResponseMachineDetailsWithChilds } from "../../models";
import HaagMappingAddEditDialog from "./HaagMappingAddEditDialog";
import HaagMappingDeleteBySourceDialog from "./HaagMappingDeleteBySourceDialog";
import HaagMappingDeleteDialog from "./HaagMappingDeleteDialog";
import HaagMappingImportDialog from "./HaagMappingImportDialog";
import type { HaagSignalMapping } from "./models";
import { OpcUaAccessMode, OpcUaDataType, SignalSource } from "./models";

enum UserActions {
  None,
  Add,
  Import,
  Edit,
  Delete,
  DeleteBySource,
}

type TableItem = Omit<
  HaagSignalMapping,
  "machineId" | "signalId" | "plantId" | "source" | "accessMode" | "dataType"
> & {
  plantId: string;
  source: string;
  signalName: string;
  accessMode: string;
  dataType: string;
};

type UserActionsState = {
  action: UserActions;
  item?: HaagSignalMapping;
};

type GetTableColumnsProps = {
  t: any;
  onEditClick: (tableItem: TableItem) => void;
  onDeleteClick: (tableItem: TableItem) => void;
};

type ActionDialogProps = {
  machineId: string;
  state: UserActionsState;
  onClose: (refresh: boolean) => void;
};

type HaagMappingSectionProps = {
  machine: ResponseMachineDetailsWithChilds;
  data: HaagMappingData;
};

const getCommandBarItems = ({
  t,
  machineId,
  onRefreshClick,
  onImportClick,
  onAddClick,
  onDeleteBySourceClick,
}): any[] => {
  const result: any = [
    {
      key: `haag-${machineId}-refresh`,
      text: t("Refresh"),
      type: CommandBarItemType.Button,
      icon: <RefreshIcon />,
      onClick: onRefreshClick,
    },
    {
      key: `haag-${machineId}-import`,
      text: t("Import"),
      type: CommandBarItemType.Button,
      icon: <ImportIcon />,
      onClick: onImportClick,
    },
    {
      key: `haag-${machineId}-add`,
      text: t("Add"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAddClick,
    },
    {
      key: `haag-${machineId}-delete-by-source`,
      text: t("Delete by Source"),
      type: CommandBarItemType.Button,
      icon: <DeleteRegular />,
      onClick: onDeleteBySourceClick,
    },
  ];

  return computeCommandBarItems(result);
};

const getTableColumns = ({ t, onEditClick, onDeleteClick }: GetTableColumnsProps) => {
  const dataColumns: Column[] = [
    {
      key: "nodeId",
      name: t("Node ID"),
      fieldName: "nodeId",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "plantId",
      name: t("Plant ID"),
      fieldName: "plantId",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "deviceName",
      name: t("Device Name"),
      fieldName: "deviceName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "componentName",
      name: t("Component Name"),
      fieldName: "componentName",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "metricName",
      name: t("Metric Name"),
      fieldName: "metricName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "unit",
      name: t("Unit"),
      fieldName: "unit",
      minWidth: 50,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "sourceIdentifier",
      name: t("Source Identifier"),
      fieldName: "sourceIdentifier",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "signalName",
      name: t("Signal Name"),
      fieldName: "signalName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "source",
      name: t("Source"),
      fieldName: "source",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "namespaceIndex",
      name: t("Namespace Index"),
      fieldName: "namespaceIndex",
      minWidth: 125,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "periodSec",
      name: t("Period (sec)"),
      fieldName: "periodSec",
      minWidth: 50,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "accessMode",
      name: t("Access Mode"),
      fieldName: "accessMode",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "dataType",
      name: t("Data Type"),
      fieldName: "dataType",
      minWidth: 75,
      isSortable: true,
      isExportable: true,
    },
  ];

  const actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 100,
    isSortable: false,
    isExportable: false,
    onRender: (item: TableItem) => (
      <Stack horizontal>
        <Tooltip relationship='label' content={t("Edit")}>
          <Button
            key={`haag-${item.id}-edit`}
            appearance='transparent'
            aria-label={t("Edit")}
            icon={<EditIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onEditClick(item);
            }}
          />
        </Tooltip>
        <Tooltip relationship='label' content={t("Delete")}>
          <Button
            key={`haag-${item.id}-delete`}
            appearance='transparent'
            aria-label={t("Delete")}
            icon={<DeleteRegular />}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(item);
            }}
          />
        </Tooltip>
      </Stack>
    ),
  };

  return [...dataColumns, actionsColumn];
};

const ActionDialog = ({ machineId, state, onClose }: ActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (state.action) {
    case UserActions.Add:
      result = <HaagMappingAddEditDialog machineId={machineId} onClose={onClose} />;
      break;

    case UserActions.Import:
      result = <HaagMappingImportDialog machineId={machineId} onClose={onClose} />;
      break;

    case UserActions.Edit:
      result = (
        <HaagMappingAddEditDialog machineId={machineId} item={state.item} onClose={onClose} />
      );
      break;

    case UserActions.Delete:
      result = (
        <HaagMappingDeleteDialog machineId={machineId} item={state.item} onClose={onClose} />
      );
      break;

    case UserActions.DeleteBySource:
      result = <HaagMappingDeleteBySourceDialog machineId={machineId} onClose={onClose} />;
      break;
  }

  return result;
};

const HaagMappingSection = ({ machine, data, ...rest }: HaagMappingSectionProps) => {
  const { t } = useTranslation();
  const [tableItems, setTableItems] = useState<TableItem[]>([]);
  const [actionsState, setActionsState] = useState<UserActionsState>({
    action: UserActions.None,
  });
  const { filters, handleSearch } = useTableFilters<TableItem>({
    keys: [
      "nodeId",
      "signalName",
      "deviceName",
      "componentName",
      "metricName",
      "sourceIdentifier",
      "source",
      "accessMode",
      "dataType",
    ],
  });
  const machineId = useMemo(() => {
    return machine?.id ?? "";
  }, [machine.id]);

  // Builds the table items.
  useEffect(() => {
    if (data.status !== Status.idle || !data.payload) {
      return;
    }

    // Builds the items.
    const tableItems: TableItem[] = Array.from(data.payload.values()).map((item) => {
      const index = machine.signals?.findIndex((s) => s.id === item.signalId);
      const signalName = index >= 0 ? machine.signals![index].name : "";

      return {
        ...item,
        signalName,
        plantId: item.plantId ?? "",
        source: SignalSource[item.source],
        accessMode: item.accessMode ? OpcUaAccessMode[item.accessMode] : "",
        dataType: item.dataType ? OpcUaDataType[item.dataType] : "",
      };
    });

    setTableItems(tableItems);
  }, [data.status]);

  // Handlers
  const onRefreshClick = () => data.refresh();

  const onImportClick = () => setActionsState({ action: UserActions.Import });

  const onAddClick = () => setActionsState({ action: UserActions.Add });

  const onEditClick = (tableItem: TableItem) => {
    const item = data.payload?.get(tableItem.id);
    if (!item) {
      return;
    }

    setActionsState({ action: UserActions.Edit, item });
  };

  const onDeleteClick = (tableItem: TableItem) => {
    const item = data.payload?.get(tableItem.id);
    if (!item) {
      return;
    }

    setActionsState({ action: UserActions.Delete, item });
  };

  const onDeleteBySourceClick = () => setActionsState({ action: UserActions.DeleteBySource });

  const onCloseActionDialog = (refresh: boolean) => {
    refresh === true && onRefreshClick();
    setActionsState({ action: UserActions.None });
  };

  return (
    <React.Fragment>
      <Stack {...rest} role='presentation'>
        <BaseCommandBar
          items={getCommandBarItems({
            t,
            machineId,
            onRefreshClick,
            onImportClick,
            onAddClick,
            onDeleteBySourceClick,
          })}
          onSearch={handleSearch}
        />
        <Table
          persistOpts={{
            key: `haag-${machineId}-table`,
            version: 2,
          }}
          header={{ title: "HAAG" }}
          isLoading={data.status === Status.loading}
          isError={data.status === Status.error}
          items={tableItems}
          v8Columns={getTableColumns({ t, onEditClick, onDeleteClick })}
          filters={filters}
        />
      </Stack>
      <ActionDialog machineId={machineId} state={actionsState} onClose={onCloseActionDialog} />
    </React.Fragment>
  );
};

export default HaagMappingSection;
