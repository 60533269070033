import { useQuery } from "@tanstack/react-query";

import { usePowerBi } from "../../../../Hooks";
import { apiService } from "../../../../modules/common";
import type { Company, PowerBiEmbed, Project } from "../../../../types";

type CompanyData = {
  company: Company & { projects?: Project[] };
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  dashboard: {
    embed?: PowerBiEmbed;
    isLoading: boolean;
    isError: boolean;
  };
};

type CompanyDataProps = {
  id: string;
};

export const useCompanyData = ({ id }: CompanyDataProps): CompanyData => {
  const {
    data = {} as Company,
    isLoading,
    isError,
    isFetched,
  } = useQuery(["company-data", id], () => apiService.metaDataRead.getCompany(id), {
    cacheTime: 0,
    enabled: !!id,
  });

  const dashboardResponse = usePowerBi({
    id,
    type: "Company",
    options: { cacheTime: 0, retry: 0 },
  });

  return {
    company: data,
    isLoading,
    isError,
    isFetched,
    dashboard: {
      embed: dashboardResponse.data,
      isLoading: dashboardResponse.isLoading,
      isError: dashboardResponse.isError,
    },
  };
};
