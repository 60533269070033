import { Button, Switch, Text } from "@fluentui/react-components";
import classnames from "classnames";
import { isEmpty, min } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CharLayoutIcon from "../../../../assets/svg/ChartLayoutIcon";
import { Stack } from "../../../../Components/Stack";
import { useMaximize, useUniqueId } from "../../../../Hooks";
import type { DataPoint, ResponseSimplifiedSignal } from "../../../../types";
import type { Range } from "../../hooks/useControlsStore";
import useControlsStore from "../../hooks/useControlsStore";
import useGroupedSignalMonitoring from "../../hooks/useGroupSignalMonitoring";
import useMachineMetaData from "../../hooks/useMachineMetaData";
import type { TrendViewRawDetails } from "../../types";
import getStatistics from "../../utils/getStatistics";
import useLayoutSettingsStore from "../ChartLayoutModal/useLayoutSettingsStore";
import TrendViewGroupedInfo from "../TrendViewGroupedInfo";
import TrendViewGroupedPlot from "../TrendViewGroupedPlot";
import { TrendViewGroupedPlotNoData, TrendViewPlotSpinner } from "../TrendViewPlotPlaceholders";

interface TrendViewGroupedSectionProps extends React.HTMLAttributes<HTMLElement> {
  machineId: string;
  signals: ResponseSimplifiedSignal[];
  title?: string;
  chartStyles?: any;
}

const TrendViewGroupedSection: React.FC<TrendViewGroupedSectionProps> = ({
  machineId,
  signals,
  title,
  chartStyles,
  ...rest
}) => {
  const { t } = useTranslation();
  const { corporation, company, project, machine } = useMachineMetaData(machineId);
  const { updateStore: updateLayoutSettingsStore } = useLayoutSettingsStore((store: any) => ({
    updateStore: store.updateStore,
  }));

  const { period, averageTrend, connectedTimeRange, setConnectedTimeRange } = useControlsStore(
    (state) => ({
      period: state.period,
      averageTrend: state.averageTrend,
      connectedTimeRange: state.connectedTimeRange,
      setConnectedTimeRange: state.setConnectedTimeRange,
    }),
  );

  const { data, signalsToRequest, isLoading } = useGroupedSignalMonitoring({
    signals,
    machineId,
    period,
    averageTrend,
  });

  const uniqueId = useUniqueId();

  const { maximizeAction, maximizeIcon, maximizeLabel, isChartMaximized, isChartHidden } =
    useMaximize({ id: uniqueId, page: "trend-view" });

  const dataForPlot = useMemo(() => {
    const maxLength = Math.max(...data.map(({ length }) => length));

    const signalsPlots: any[] = Array.from({ length: maxLength }).map(() => ({}));

    data.forEach((signalPlot, index) => {
      if (!signalPlot?.length) {
        return;
      }

      for (let dataPointIndex = 0; dataPointIndex < maxLength; dataPointIndex++) {
        const dataPoint = signalPlot[dataPointIndex];
        if (dataPoint) {
          signalsPlots[dataPointIndex][`date_${index}`] = dataPoint.timeStamp;
          signalsPlots[dataPointIndex][`value_${index}`] = dataPoint.value;
        }
      }
    });

    return signalsPlots;
  }, [data]);

  const [selectedRange, setSelectedRange] = useState<Range>({
    startIndex: 0,
    endIndex: (min(data.map(({ length }) => length)) as number) - 1,
  });

  useEffect(() => {
    setSelectedRange({
      startIndex: 0,
      endIndex: (min(data.map(({ length }) => length)) as number) - 1,
    });
  }, [data]);

  const setRange = (selectedIndexes: Range, selectedTimes: Range) => {
    setSelectedRange(selectedIndexes);
    setConnectedTimeRange({
      range: selectedTimes,
      signalId: machineId,
    });
  };

  const trendStatistics = useMemo(() => {
    const statistics = [] as Array<TrendViewRawDetails & { name: string }>;

    data.forEach((dataPoints, index) => {
      const signalStatistics = getStatistics({
        selectedRange,
        data: dataPoints.map((item: DataPoint) => item.value),
      }) as TrendViewRawDetails & { name: string };

      signalStatistics.name = signalsToRequest[index]?.name || "";
      statistics.push(signalStatistics);
    });

    return statistics;
  }, [selectedRange, data, signalsToRequest]);

  const [isRawDetailsOpen, setIsRawDetailsOpen] = useState<boolean | undefined>(false);

  if (isLoading) {
    return <TrendViewPlotSpinner style={chartStyles?.loading || {}} />;
  }

  if (isEmpty(dataForPlot)) {
    return <TrendViewGroupedPlotNoData machineId={machineId} />;
  }

  return (
    <div
      className={classnames("trend-view-grouped-section", {
        "trend-view-chart-section--hidden": isChartHidden,
        "trend-view-chart-section--maximized": isChartMaximized,
      })}
    >
      <Stack
        horizontal
        style={{
          height: "100%",
        }}
      >
        <Stack {...rest} style={{ width: "100%", height: "100%", ...rest.style }}>
          <Stack horizontal verticalAlign='center' horizontalAlign='space-between'>
            <Stack horizontal>
              <Text
                style={{
                  borderRight: "1px solid rgb(200, 198, 196)",
                  paddingRight: "16px",
                  margin: "0px 16px",
                  fontWeight: 600,
                }}
              >
                {title || t("Trend View")}
              </Text>
              <Button
                appearance='transparent'
                style={{
                  padding: 0,
                }}
                onClick={() => updateLayoutSettingsStore({ isChartLayoutOpen: true })}
              >
                <CharLayoutIcon />
                <span style={{ marginLeft: 8 }}>{t("Chart Layout")}</span>
              </Button>
            </Stack>
            <Stack horizontal style={{ gap: 16 }}>
              <Switch
                label={t("Statistics")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0 0 8px",
                }}
                checked={isRawDetailsOpen}
                onChange={(_, { checked }) => setIsRawDetailsOpen(checked)}
              />
              <Button
                appearance='transparent'
                style={{ padding: 0, minWidth: 0 }}
                onClick={maximizeAction}
              >
                {maximizeIcon}
                <span>{maximizeLabel}</span>
              </Button>
              <div className={`export-${machineId}`} style={{ marginRight: 16 }} />
            </Stack>
          </Stack>
          <TrendViewGroupedPlot
            isAverageTrend={averageTrend}
            connectedTimeRange={connectedTimeRange}
            setSelectedRange={setRange}
            data={dataForPlot}
            signals={signalsToRequest}
            numberOfSignals={data.length}
            machineId={machineId}
            corporation={corporation}
            company={company}
            project={project}
            machine={machine}
            exportSelector={`export-${machineId}`}
            chartStyles={chartStyles}
          />
        </Stack>
      </Stack>
      {isRawDetailsOpen && (
        <TrendViewGroupedInfo data={trendStatistics} signals={signalsToRequest} />
      )}
    </div>
  );
};

export default TrendViewGroupedSection;
