import { Button, Text, Tooltip } from "@fluentui/react-components";
import { Delete20Regular, TableDismiss20Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { titleStyle } from "../../schema/Constants";
import { Personas } from "../../schema/personas";
import FacePile from "../common/FacePile";
import type { Column } from "../common/Table/v9";
import Table from "../common/Table/v9";
import type { personaProps, ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import type { ServiceAccountAddRole } from "../ServiceAccount/models";
import { Stack } from "../Stack";
import { AddRole } from "./AddRole";
import { AddRolePersona } from "./AddRolePersona";

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onDelete: (serviceAccount: ServiceAccountAddRole) => void;
};

const getColumns = ({ t, hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: t("Role"),
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scopeLevel",
      name: t("Scope Level"),
      fieldName: "scopeLevel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "resource",
      name: t("Resource"),
      fieldName: "resource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: t("Expires at UTC"),
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: ServiceAccountAddRole) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(role);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

type RolesComponentProps = {
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<React.SetStateAction<ServiceAccountAddRoleToShow[]>>;
  onDelete: (roles: ServiceAccountAddRoleToShow) => void;
};

export const RolesComponentToAdd = ({
  rolesToAdd,
  setRolesToAdd,
  onDelete,
}: RolesComponentProps) => {
  const { t } = useTranslation();
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddRolePersona, setShowAddRolePersona] = useState(false);
  const [persona, setPersona] = useState<personaProps>();

  const onClickPersona = (data) => {
    setShowAddRolePersona((prv) => !prv);
    setPersona(data);
  };

  const personas = Personas.allPersonas
    //.filter((ele) => ele.type === memberType)
    .map((eleF) => {
      return {
        personaName: eleF.name,
        imageInitials: eleF.imageInitials,
        onClick: () => onClickPersona({ persona: eleF.personaRoles, root: eleF.root }),
      };
    });

  return (
    <div>
      <AddRole
        show={showAddRole}
        rolesToAdd={rolesToAdd}
        setRolesToAdd={setRolesToAdd}
        onClose={() => setShowAddRole((prv) => !prv)}
      />
      {persona && (
        <AddRolePersona
          key={Math.random()}
          show={showAddRolePersona}
          rolesToAdd={rolesToAdd}
          setRolesToAdd={setRolesToAdd}
          persona={persona}
          onClose={() => setShowAddRolePersona((prv) => !prv)}
        />
      )}

      <Stack verticalFill>
        <Stack horizontal style={{ gap: 20 }}>
          <Text style={titleStyle}>{t("Incorporate")}</Text>
          <Tooltip withArrow relationship='label' content={t("Remove all")}>
            <Button
              appearance='transparent'
              icon={<TableDismiss20Regular />}
              style={{ padding: "8px 0" }}
              disabled={!(rolesToAdd.length > 0)}
              onClick={() => setRolesToAdd([])}
            />
          </Tooltip>
          <Button appearance='primary' onClick={() => setShowAddRole((prv) => !prv)}>
            {t("Add Single")}
          </Button>
          <FacePile personas={personas} maxPersonas={4} />
        </Stack>
        <Table
          persistOpts={{
            key: "table-rolesAndScopes",
            version: 2,
          }}
          header={{
            title: "",
          }}
          items={rolesToAdd}
          v8Columns={getColumns({
            t,
            hasActions: true,
            onDelete,
          })}
          isLoading={showAddRole}
          isError={rolesToAdd.length === 0}
        />
      </Stack>
    </div>
  );
};
