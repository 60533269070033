import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getFileIds,
  useFileDelete,
  useFileDownload,
  useFileUpload,
  useLocationSearch,
  useMachineUsersQuery,
} from "../../../../../../Hooks";
import type { DeleteItemDataProps } from "../../../../../../modules/common/components/Dialogs/DeleteDialog";
import DeleteDialog from "../../../../../../modules/common/components/Dialogs/DeleteDialog";
import DownloadDialog from "../../../../../../modules/common/components/Dialogs/DownloadDialog";
import AddDocumentDialog from "../../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { notification } from "../../../../../common/Notification";
import { useTableFilters } from "../../../../../common/Table/v9";
import type { TableProps } from "../../../../../common/Table/v9/Table";
import Table from "../../../../../common/Table/v9/Table";
import type { UploadedFile } from "../../../../../common/Uploader/types";
import type { DocumentResponse } from "../../../../MachineCVDocumentsPage/hooks/useDocumentsQuery";
import { useTask } from "../../../hooks/useTask";
import { useTaskUploadDocument } from "../../../hooks/useTaskMutations";
import useTasksStore from "../../../hooks/useTasksStore";
import SectionTitle from "../../SectionTitle";
import { getColumns } from "./columns";
import { CommandBar } from "./CommandBar";

export const TaskDocuments = () => {
  const { t } = useTranslation();

  const [{ id }] = useLocationSearch();

  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));

  const { task, getTaskDetails, isLoading, isError } = useTask({
    taskId: edit.id,
  });

  const [showUpload, setShowUpload] = useState(false);
  const [deleteData, setDeleteData] = useState<DeleteItemDataProps>({
    ids: [],
    name: "",
  });

  const { showDownloadDialog } = useFileDownload();

  const { filters, handleSearch } = useTableFilters<DocumentResponse>({
    keys: ["name", "description"],
  });

  const { usersList } = useMachineUsersQuery({ machineId: id as string });

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-task-documents",
        version: 1,
      },
      items: task.files,
      isLoading,
      filters,
      isError,
      perPage: 5,
      hidePerPage: true,
      v8Columns: getColumns({ t, onRemove: setDeleteData, users: usersList }),
    }),
    [task.files, isLoading, filters, isError, t, usersList],
  );

  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();
  const { uploadTaskDocumentsAsync } = useTaskUploadDocument();

  const onUpload = async (files: UploadedFile[]) => {
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });

    const successIds = getFileIds(uploadResponse);
    await uploadTaskDocumentsAsync({ id: edit.id, fileIds: successIds })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success(t("Task updated successfully"));
        } else {
          notification.success(
            t(`Task updated successfully with {{filesNumber}} out of {{totalFiles}} files`, {
              filesNumber: successIds.length,
              totalFiles: uploadResponse.length,
            }),
          );
        }
        getTaskDetails();
      })
      .catch(() => {
        notification.error(t("Failed updating task"));
        deleteFilesAsync(successIds);
      });
  };

  return (
    <div>
      <SectionTitle>{t("Documents")}</SectionTitle>

      <CommandBar handleSearch={handleSearch} onUpload={() => setShowUpload(true)} />
      <Table {...tableProps} />
      <DownloadDialog open={showDownloadDialog} />
      <AddDocumentDialog
        hidden={!showUpload}
        onSubmit={onUpload}
        onClose={() => setShowUpload(false)}
      />
      <DeleteDialog
        data={deleteData}
        open={!(deleteData.ids.length === 0)}
        route={`meta/machinecv/v1/tasks/${edit.id}/documents`}
        onDismiss={() => setDeleteData({ ids: [], name: "" })}
        onSuccess={getTaskDetails}
      />
    </div>
  );
};
