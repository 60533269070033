import { Button, Divider, Text } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import classnames from "classnames";
import type { CSSProperties } from "react";
import { useState } from "react";

import type { Corporation, MachineCvOverview } from "../../../../types";
import { Stack } from "../../../Stack";
import MachinesOverview from "./MachinesOverview";

const baseTitleStyle: CSSProperties = {
  display: "block",
  fontSize: 16,
  fontWeight: 600,
};

const companyTitleStyle: CSSProperties = {
  ...baseTitleStyle,
};

type TogglerProps = {
  isOpen: boolean;
};

const Toggler = ({ isOpen }: TogglerProps) => (
  <Button
    appearance='transparent'
    className='toggle-overview-button'
    icon={isOpen ? <ChevronUpRegular /> : <ChevronDownRegular />}
  />
);

const Header = ({ name, isOpen, toggle }: { name: string; toggle: () => void } & TogglerProps) => (
  <Stack
    className='corporation-header'
    style={{
      position: "sticky",
      top: 44,
      backgroundColor: "#FAF9F8",
      zIndex: 10,
      minHeight: 50,
      verticalAlign: "middle",
      lineHeight: "50px",
      cursor: "pointer",
    }}
    onClick={toggle}
  >
    <Divider>
      <Text style={{ fontSize: 16 }}>{name}</Text>
      <Toggler isOpen={isOpen} />
    </Divider>
  </Stack>
);

const CorporationOverview = ({ data }: { data: MachineCvOverview }) => {
  const [isOpen, setOpen] = useState(true);

  const toggle = () => setOpen(!isOpen);

  const corporation: Corporation = {
    id: data.id,
    name: data.name,
    number: data.number,
  };

  return (
    <>
      <Header name={data.name} isOpen={isOpen} toggle={toggle} />
      {data.companies.map((company, idx) => (
        <div
          key={`${data.id}-${company.id}`}
          className={classnames({
            "company-section": isOpen,
            "company-section__last-item": isOpen && idx === data.companies.length - 1,
          })}
        >
          {isOpen && (
            <>
              <Stack horizontal>
                <Text style={companyTitleStyle}>{company.name}</Text>
              </Stack>
              {company.projects.map((project) => (
                <MachinesOverview
                  key={project.metaData.id}
                  corporation={corporation}
                  company={company}
                  project={project}
                />
              ))}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default CorporationOverview;
