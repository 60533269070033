import "./style.scss";

import { Link, Spinner } from "@fluentui/react-components";
import { useCallback, useState } from "react";

import { useFileDownload } from "../../../../Hooks";
import type { UploadedFile } from "../../Uploader/types";
import { useFilePreview } from "../hooks";
import { FileType } from "../utils";

export type CommentFileProps = {
  file: UploadedFile;
  className?: string;
};

export const File = ({ file, className = "file-name" }: CommentFileProps) => {
  const { openPreview, PreviewModal } = useFilePreview();
  const { getFile } = useFileDownload();
  const [isLoading, setIsLoading] = useState(false);

  const handlePreview = useCallback(
    async (ev) => {
      ev?.preventDefault();
      ev?.stopPropagation();
      setIsLoading(true);

      if (file.isValid) return;

      try {
        const fileResponse = await getFile({ id: file.id });
        const fileType = fileResponse.data.type === "application/pdf" ? FileType.Pdf : FileType.Img;
        setIsLoading(false);
        return openPreview(file.id, file.name, fileType);
      } catch (error) {
        console.error("Error getting file type:", error);
        setIsLoading(false);
        return openPreview(file.id, file.name, FileType.Img);
      }
    },
    [file, getFile, openPreview],
  );

  return (
    <div className='file-container'>
      {isLoading ? <Spinner size='extra-tiny' /> : null}
      <Link className={className} onClick={handlePreview}>
        {file.name}
      </Link>
      <PreviewModal />
    </div>
  );
};
