import type { DialogTitleProps } from "@fluentui/react-components";
import { DialogTitle } from "@fluentui/react-components";
import classNames from "classnames";
import { type PropsWithChildren } from "react";

import { DefaultCloseButton } from "./DefaultCloseButton";
import { useStyles } from "./useStyles";

export const BaseDialogTitle = ({
  children,
  style,
  ...rest
}: PropsWithChildren<DialogTitleProps>) => {
  const classes = useStyles();
  return (
    <DialogTitle
      className={classNames("draggable-header", classes.title)}
      style={style}
      action={<DefaultCloseButton />}
      {...rest}
    >
      {children}
    </DialogTitle>
  );
};
