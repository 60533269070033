/* eslint-disable jsx-a11y/no-static-element-interactions */
import { makeStyles } from "@fluentui/react-components";
import React, { useEffect, useRef, useState } from "react";

import { useBoolean } from "../../../Hooks";
import { Stack } from "../../Stack";

interface IThreeColumnLayoutProps {
  leftChildren?: React.ReactNode;
  centerChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  minColumnWidth?: number;
}

interface IDividerProps {
  onMouseDown: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles({
  dividerClassName: {
    width: "6px",
    cursor: "col-resize",
    background: "#f3f2f1",
    margin: "0 -3px",
    position: "relative",
    zIndex: 1,
    transition: "background-color 0.2s ease",

    ":hover": {
      backgroundColor: "#c8c6c4",
    },
  },
});

const Divider: React.FC<IDividerProps> = ({ onMouseDown }) => {
  const { dividerClassName } = useStyles();
  return <div className={dividerClassName} onMouseDown={onMouseDown} />;
};

const useColumnStyles = makeStyles({
  column: {
    height: "100%",
    overflow: "auto",
  },
});

export const ThreeColumnLayout: React.FC<IThreeColumnLayoutProps> = ({
  leftChildren,
  centerChildren,
  rightChildren,
  minColumnWidth = 20,
}) => {
  const [leftWidth, setLeftWidth] = useState<number>(33);
  const [rightWidth, setRightWidth] = useState<number>(33);
  const [isDraggingLeft, { setTrue: startDraggingLeft, setFalse: stopDraggingLeft }] =
    useBoolean(false);
  const [isDraggingRight, { setTrue: startDraggingRight, setFalse: stopDraggingRight }] =
    useBoolean(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const initialX = useRef<number>(0);
  const initialLeftWidth = useRef<number>(0);
  const initialRightWidth = useRef<number>(0);
  const { column } = useColumnStyles();

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDraggingLeft && !isDraggingRight) return;
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const deltaX = e.clientX - initialX.current;
      const deltaPercentage = (deltaX / containerWidth) * 100;

      if (isDraggingLeft) {
        const newLeftWidth = Math.max(
          minColumnWidth,
          Math.min(100 - minColumnWidth * 2, initialLeftWidth.current + deltaPercentage),
        );
        setLeftWidth(newLeftWidth);
      }

      if (isDraggingRight) {
        const newRightWidth = Math.max(
          minColumnWidth,
          Math.min(100 - minColumnWidth * 2, initialRightWidth.current - deltaPercentage),
        );
        setRightWidth(newRightWidth);
      }
    };

    const handleMouseUp = () => {
      stopDraggingLeft();
      stopDraggingRight();
    };

    if (isDraggingLeft || isDraggingRight) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDraggingLeft, isDraggingRight, minColumnWidth, stopDraggingLeft, stopDraggingRight]);

  const handleLeftDividerMouseDown = (e: React.MouseEvent) => {
    initialX.current = e.clientX;
    initialLeftWidth.current = leftWidth;
    startDraggingLeft();
  };

  const handleRightDividerMouseDown = (e: React.MouseEvent) => {
    initialX.current = e.clientX;
    initialRightWidth.current = rightWidth;
    startDraggingRight();
  };

  const stackStyles: any = {
    height: "100%",
    position: "relative",
    overflow: "hidden",
  };

  return (
    <div ref={containerRef} style={{ height: "100%" }}>
      <Stack horizontal style={stackStyles}>
        <div className={column} style={{ width: `${leftWidth}%` }}>
          {leftChildren}
        </div>
        <Divider onMouseDown={handleLeftDividerMouseDown} />
        <div className={column} style={{ width: `${100 - leftWidth - rightWidth}%` }}>
          {centerChildren}
        </div>
        <Divider onMouseDown={handleRightDividerMouseDown} />
        <div className={column} style={{ width: `${rightWidth}%` }}>
          {rightChildren}
        </div>
      </Stack>
    </div>
  );
};
