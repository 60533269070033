import type { CSSProperties } from "react";

export const buttonStyle = {
  border: "unset",
  minWidth: "30px",
  padding: "0px",
  background: "transparent",
  fontWeight: 600,
};

export const referenceTextStyles: CSSProperties = {
  width: "auto",
  maxWidth: 150,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontWeight: 600,
  color: "currentColor",
  paddingLeft: 8,
  textAlign: "left",
};

export const signalAutocompleteStyles: CSSProperties = {
  display: "flex",
  flexFlow: "row nowrap",
  width: "auto",
  height: "auto",
  alignItems: "center",
};

export const dropdownIconStyle: CSSProperties = {
  display: "block",
  marginTop: 1,
  marginRight: 5,
  width: 16,
  height: 16,
};
