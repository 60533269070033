import { Button, Input } from "@fluentui/react-components";
import { EyeFilled, EyeRegular } from "@fluentui/react-icons";
import { useMemo, useState } from "react";

export function PasswordField({ onInputChange, ...rest }) {
  const [isRevealed, setIsRevealed] = useState(false);

  const password = useMemo(() => {
    if (!String(rest?.value).length) {
      return "";
    }

    return isRevealed ? `${rest?.value}` : "*****";
  }, [isRevealed, rest?.value]);

  return (
    <Input
      {...rest}
      type={isRevealed ? "text" : "password"}
      contentAfter={
        <Button
          appearance='transparent'
          icon={isRevealed ? <EyeFilled /> : <EyeRegular />}
          onClick={() => setIsRevealed(!isRevealed)}
        />
      }
      value={password}
      onChange={onInputChange}
    />
  );
}
