/* eslint-disable react-hooks/exhaustive-deps */

import { Field, Label } from "@fluentui/react-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMachines } from "../../Hooks/useMachines";
import { useProjects } from "../../Hooks/useProjects";
import Combobox from "../common/Combobox";
import type { MachineToList } from "../Machines/models";

type MachineFormItemProps = {
  onMachineSelected: (machine?: MachineToList) => void;
  initialMachineId?: string;
};

const MachineFormItems = ({ initialMachineId, onMachineSelected }: MachineFormItemProps) => {
  const { t } = useTranslation();
  const { projects } = useProjects();
  const machines = useMachines();
  const [projectId, setProjectId] = useState<string>("");
  const [machineId, setMachineId] = useState<string>(initialMachineId ?? "");

  const projectsOptions: any[] = useMemo(() => {
    if (!projects) {
      return [];
    }

    return Array.from(projects.values())
      .filter((p) => p.name !== null)
      .map((p) => {
        return {
          key: p.id,
          text: `${p.name} (${p.company?.name})`,
        };
      });
  }, [projects]);

  const machinesOptions: any[] = useMemo(() => {
    if (!machines || machines.length === 0 || !projectId || projectId.length === 0) {
      return [];
    }

    return machines
      .filter((m) => m.name !== null && m.projectId === projectId)
      .map((m) => {
        return { key: m.id, text: m.name };
      });
  }, [machines, projectId]);

  // Selects the initial value.
  useEffect(() => {
    if (projects.size === 0) {
      return;
    }

    const machine = machines.find((m) => m.id === initialMachineId);
    if (!machine) {
      return;
    }

    setProjectId(projects.get(machine.projectId)?.id ?? "");
    setMachineId(machine.id);
    onMachineSelected?.(machine);
  }, [initialMachineId, projects.size]);

  // Selects a default machine
  useEffect(() => {
    if (!machinesOptions || machinesOptions.length === 0) {
      onMachineIdSelected("");
      return;
    }

    onMachineIdSelected(machinesOptions.at(0).key.toString());
  }, [machinesOptions]);

  // Handlers
  const onProjectSelected = (key) => {
    key !== machineId && setMachineId("");
    setProjectId(key);
  };

  const onMachineIdSelected = (key) => {
    setMachineId(key);
    const machine = machines.find((m) => m.id === key);
    onMachineSelected?.(machine);
  };

  return (
    <React.Fragment>
      <Field required={true}>
        <Label>{t(`Project`)}</Label>
        <Combobox
          required
          options={projectsOptions}
          value={projectId}
          onChange={onProjectSelected}
        />
      </Field>
      <Field required={true}>
        <Label>{t("Machine")}</Label>
        <Combobox
          required
          options={machinesOptions}
          value={machineId}
          onChange={onMachineIdSelected}
        />
      </Field>
    </React.Fragment>
  );
};

export default MachineFormItems;
