/* eslint-disable react-hooks/exhaustive-deps */
import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";
import { DialogContent, Tab, TabList } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../common/Dialog";
import type {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "../tableItemModels";
import DdpConfigForm from "./DdpConfigForm";
import { useRawSignals } from "./hooks";
import IoTLinkConfigForm from "./IoTLinkConfigForm";

export type GenerateConfigDialogProps = {
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  onClose: () => void;
};

const excludeSignalCases: string[] = ["env", "torque", "torq", "trq", "toq"];

/**
 * Gets the Generate Configuration dialog component.
 * @param tableItem the table item
 * @param onClose Method called to close this dialog.
 * @returns The Generate Configuration dialog component.
 */
const GenerateConfigDialog = ({ tableItem, onClose }: GenerateConfigDialogProps) => {
  const { t } = useTranslation();
  const { signals, signalsAreLoading } = useRawSignals(tableItem.machineIds?.[0]);

  // Closes the dialog, if the signals were not found.
  useEffect(() => {
    if (!signals) {
      onClose?.();
      return;
    }
  }, [signals]);

  const [selectedValue, setSelectedValue] = useState<TabValue>("iot_link");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog open={true} surfaceStyle={{ width: DialogSize.S }} onOpenChange={onClose}>
      <BaseDialogTitle>{t("Generate Configuration")}</BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("generate datalogger configuration pivot")}
          onTabSelect={onTabSelect}
        >
          <Tab value='iot_link'>{t("IoT Link")}</Tab>
          <Tab value='data_platform'>{t("Data Platform")}</Tab>
        </TabList>
        <div style={{ marginTop: "16px" }}>
          {selectedValue === "iot_link" && (
            <IoTLinkConfigForm
              tableItem={tableItem}
              signals={signals?.filter((signal) => {
                for (const word of excludeSignalCases) {
                  if (!signal.name.toLocaleLowerCase().includes(word)) {
                    continue;
                  }

                  return false;
                }

                return true;
              })}
              isLoading={signalsAreLoading}
              onClose={onClose}
            />
          )}
          {selectedValue === "data_platform" && (
            <DdpConfigForm tableItem={tableItem} onClose={onClose} />
          )}
        </div>
      </DialogContent>
    </BaseDialog>
  );
};

export default GenerateConfigDialog;
