import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import { Status } from "../../schema/status";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { listAsyncCompanies, selectCompaniesStatus } from "../Companies/reducer";
import type { gatewaysBulkNotCommon, gatewaysBulkProps } from "./api";
import { editBulkGateways } from "./api";
import type { ItemsSelected } from "./GatewaysList";

type EditBDialogProps = {
  gatewaysNotCommon: gatewaysBulkNotCommon[];
  options: any[];
  data: ItemsSelected; // | null;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

const getSchema = (t) =>
  z
    .object({
      projectId: z.string(),
      ltePlanSize: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: t("Try a number between (1.0:1000.0)"),
      },
    );
const getGatewayFields: (t, options) => FormItemProps[] = (t, options) => [
  {
    name: "projectId",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Select project:") },
    options,
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: t("Lte Plan Size *") },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: t("Firmware") },
  },
];

export const EditBulkDialog = ({
  gatewaysNotCommon,
  options,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditBDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();

  const defaultSelectedProjectID =
    data && data.projectId !== "00000000-0000-0000-0000-000000000000" ? data.projectId : undefined;

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        projectId: defaultSelectedProjectID,
        ltePlanSize: data?.ltePlanSize?.toString() || "",
        firmware: data?.firmware || "",
      },
    }),
  });

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    const toSend: gatewaysBulkProps = {
      notCommon: gatewaysNotCommon,
      editGateWay: {
        projectId: formData.projectId,
        ltePlanSize: Number(formData.ltePlanSize),
        firmware: formData.firmware,
      },
    };
    await editBulkGateways(toSend).then((response) => onSuccess("status" in response));

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);
    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{t("Edit Bulk Gateways")}</BaseDialogTitle>
      <DialogContent>
        {renderFormItems(getGatewayFields(t, options), {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}

        <DialogActions>
          <Button
            appearance='primary'
            type='submit'
            disabled={isLoading || !isValid || !isDirty}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onSubmit}
          >
            {t("Save Changes")}
          </Button>
          <Button appearance='transparent' onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
