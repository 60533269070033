import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import React, { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import type { CommentTag } from "../../../../../types";
import BaseDialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";
import { useTagsAdd, useTagsQuery } from "../../hooks/useTagsQuery";

const getSchema = (t, tags: CommentTag[]) =>
  z
    .object({
      name: z.string(),
    })
    .refine(
      ({ name }) => {
        const nameList = tags.map((tag) => tag.name.toLowerCase());
        return !nameList.includes(name.toLowerCase());
      },
      { path: ["name"], message: t("Tag already exists") },
    );

const getfields: (t) => FormItemProps[] = (t) => [
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: t("Tag") },
    placeholder: t("Tag name"),
  },
];

type AddTagDialogProps = Omit<DialogProps, "children" | "open"> & {
  onDismiss?: () => void;
  open: boolean;
};

const AddTagDialog: React.FC<AddTagDialogProps> = (props) => {
  const { t } = useTranslation();
  const [{ id: machineId }, ,] = useLocationSearch();
  const [isLoading, setIsLoading] = useState(false);

  const { tags, refetch } = useTagsQuery({ machineId: machineId as string });

  const schema = useMemo(() => getSchema(t, tags), [t, tags]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({ schema });

  const onFinish = () => {
    refetch();
    onClose();
    notification.success(t(`Tag created successfully`));
  };

  const { addTagAsync } = useTagsAdd();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    addTagAsync({ ...data, name: data.name, machineId: machineId as string })
      .then(() => onFinish())
      .catch(() => notification.error(t("Error adding tag")))
      .finally(() => setIsLoading(false));
    return;
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  return (
    <BaseDialog {...props}>
      <DialogTitle>{t("Add tag")}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {renderFormItems(getfields(t), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Create")}
            </Button>
            <Button appearance='transparent' onClick={onClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddTagDialog;
