import "./style.scss";

import { makeStyles, Option, Tooltip } from "@fluentui/react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { default as chartSymbols } from "../../../../../../assets/svg/ChartSymbolsIcons";
import Dropdown from "../../../../../../Components/common/Dropdown";
import useLayoutSettingsStore from "../../useLayoutSettingsStore";

const getStyles = makeStyles({
  dropdown: {
    "& .fui-Dropdown__button": {
      display: "flex",
    },
  },
});

const chartSymbolsComponents = chartSymbols.components;

function DropdownItem({ text, value }) {
  return (
    <div className='axis-picker-dropdown-item'>
      {chartSymbolsComponents[value]}
      <span className='axis-picker-dropdown-item_text'>{text}</span>
    </div>
  );
}

function onRenderOption(option: any) {
  const key = option?.key || 0;
  const text = option?.text || "";

  return (
    <Tooltip key={key} relationship='label' content={text}>
      <Option checkIcon={null} text={text} value={key}>
        <DropdownItem value={key} text={text} />
      </Option>
    </Tooltip>
  );
}

export default function AxisSelector() {
  const { t } = useTranslation();
  const { dropdown } = getStyles();

  const { syncSettingsMode, selectedYAxisIndex, seriesNames, updateStore } = useLayoutSettingsStore(
    (store: any) => ({
      syncSettingsMode: store.syncSettingsMode,
      selectedYAxisIndex: store.selectedYAxisIndex,
      seriesNames: store.seriesNames,
      updateStore: store.updateStore,
    }),
  );

  const options = useMemo(
    () =>
      (seriesNames || []).map((name: string, index: number) => ({
        key: index,
        text: name,
      })),
    [seriesNames],
  );

  function onDropdownChange(_, option: any) {
    const newIndex = option.optionValue || 0;
    updateStore({ selectedYAxisIndex: newIndex });
  }

  return (
    <Dropdown
      button={
        <DropdownItem
          value={options?.[selectedYAxisIndex]?.key}
          text={options?.[selectedYAxisIndex]?.text}
        />
      }
      className={dropdown}
      placeholder={t("Select an option")}
      options={options}
      disabled={syncSettingsMode === "syncAll"}
      selectedOptions={[options?.[selectedYAxisIndex]?.text]}
      onOptionSelect={onDropdownChange}
      onRenderOption={onRenderOption}
    />
  );
}
