import {
  Button,
  makeStyles,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
} from "@fluentui/react-components";
import { SettingsIcon } from "@fluentui/react-icons-mdl2";
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../../../../Hooks";
import type { FormItemProps } from "../../../../common/Form";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import { notification } from "../../../../common/Notification";
import { settingsUpdateUsersRBAC } from "../../../../Settings/api";
import { updateUserSettingsSlice } from "../../../../Settings/reducer";
import {
  dashboardSettingsFieldsSchema,
  getDashboardSettingsformItems,
} from "../../../../Settings/Sections/Dashboard/Dashboard";
import { getRowsPerTableDropdownField } from "../../../../Settings/Sections/List/List";
import { selectUserSettings } from "../../../../Settings/selectors";
import { Stack } from "../../../../Stack";
import { selectUserAccountMemberID } from "../../../../UserAccount/selectors";

const useStyles = makeStyles({
  rowsPerTable: {
    minWidth: 0,

    "& .fui-Combobox__input": {
      width: "60px",
    },
  },
});

const getSchema = () =>
  z.object({
    ...dashboardSettingsFieldsSchema,
    rowsPerTable: z.number(),
  });

export default function QuickSettings() {
  const { t } = useTranslation();
  const { rowsPerTable } = useStyles();

  const getFormItems = (t) => [
    ...getDashboardSettingsformItems(t),
    {
      ...getRowsPerTableDropdownField(t),
      groupProps: {
        ...getRowsPerTableDropdownField(t).groupProps,
        labelProps: {
          style: {
            fontWeight: 600,
          },
        },
        stackProps: {
          styles: {
            root: {
              flexDirection: "row",
            },
          },
        },
      },
      fieldProps: {
        style: { width: "auto" },
      },
      className: rowsPerTable,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectUserSettings);

  const userId = useAppSelector(selectUserAccountMemberID);

  const schema = useMemo(() => getSchema(), []);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    values: {
      ...settings?.dashboardSettings,
      rowsPerTable: settings?.list?.rowsPerTable,
    },
  });

  const onSubmit = handleSubmit(async (data: any) => {
    if (!data) return;

    const { rowsPerTable, ...dashboardSettings } = data;

    const payload = {
      ...settings,
      dashboardSettings,
      list: {
        ...settings.list,
        rowsPerTable,
      },
    };

    setIsLoading(true);

    await settingsUpdateUsersRBAC(userId, payload)
      .then((response) => {
        if (response) {
          dispatch(updateUserSettingsSlice(response));

          setIsLoading(false);

          notification.success(t("Settings updated successfully!"));
        }
      })
      .catch(() => {
        notification.warning(t("Something went wrong, please refresh the page. And try it again."));
      });
  });

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button style={{ height: "100%" }} appearance='primary' icon={<SettingsIcon />}>
          {t("Quick Settings")}
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <form onSubmit={onSubmit}>
            <div style={{ width: 280, padding: "8px 8px 0px 8px" }}>
              {renderFormItems(getFormItems(t) as FormItemProps[], {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              }).map((element) => (
                <Stack.Item key={element.key}>{element}</Stack.Item>
              ))}
            </div>

            <div style={{ padding: "8px" }}>
              <Button
                type='submit'
                appearance='primary'
                style={{ width: "100%" }}
                disabled={!isDirty || isLoading}
                icon={isLoading ? <Spinner size='tiny' /> : null}
              >
                {t("Apply Settings")}
              </Button>
            </div>
          </form>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}
