import { DialogSurface, makeStyles, mergeClasses } from "@fluentui/react-components";
import { useRef, useState } from "react";
import Draggable from "react-draggable";

type Position = {
  top: number | string;
  right: number | string;
  bottom: number | string;
  left: number | string;
};

const useStyles = makeStyles({
  draggableHandle: {
    "& .fui-DialogTitle": {
      cursor: "move",
    },
  },
});

export const DraggableSurface = ({ children, className, style }) => {
  const { draggableHandle } = useStyles();
  const nodeRef = useRef(null);

  const [position, setPosition] = useState<Position>({ top: 0, right: 0, bottom: 0, left: 0 });

  function onStart(e, data) {
    const top = data?.node?.offsetTop;
    const left = data?.node?.offsetLeft;
    setPosition({ top, right: "unset", bottom: "unset", left });
  }

  function onDrag(e, data) {
    setPosition({ top: data?.y, right: "unset", bottom: "unset", left: data?.x });
  }

  return (
    <Draggable
      handle='.draggable-header'
      position={{ x: position.left as number, y: position.top as number }}
      nodeRef={nodeRef}
      onStart={onStart}
      onDrag={onDrag}
    >
      <DialogSurface
        ref={nodeRef}
        className={mergeClasses(draggableHandle, className)}
        style={{
          ...style,
          // width: "100%",
          ...position,
        }}
      >
        {children}
      </DialogSurface>
    </Draggable>
  );
};
