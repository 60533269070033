import { Button, DialogActions, DialogContent, Spinner, Tooltip } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { axiosInstance } from "../..";
import { formatFileSize } from "../../schema/Utils";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import Dropdown from "../common/Dropdown";
import { FilePreview } from "../common/Preview";
import type { Column } from "../common/Table/v9";
import type { TableProps } from "../common/Table/v9/Table";
import Table from "../common/Table/v9/Table";
import { addBulkFiles, getFilesOfMachine } from "../FIles/api";
import type { CreateFile, File } from "../FIles/models";
import type { MachineDrawing } from "../Machines/models";
import AddDocumentDialog from "./AddDocumentDialog";
import { NoFilesToSelect } from "./NoFilesToSelect";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
  items: ItemsSelected[];
  options: (items: ItemsSelected[], item: ItemsSelected) => any[];
  onChangeSelSortIndex: (ele: ItemsSelected, opt, idx: number) => void;
};

function refreshFiles(
  machineId: string,
  currentDrawings: MachineDrawing[],
  setLocalFiles: React.Dispatch<React.SetStateAction<ItemsSelected[]>>,
) {
  getFilesOfMachine(machineId).then((resp: File[]) => {
    const aux: ItemsSelected[] = [];
    resp
      .filter((ele) => ele.type.split("/").at(0) === "image")
      .forEach((ele) =>
        currentDrawings.some((dra) => ele.id === dra.fileId)
          ? aux.push({
              ...ele,
              key: ele.id,
              sortIndex: currentDrawings.find((draw) => draw.fileId === ele.id).sortIndex,
            })
          : aux.push({ ...ele, key: ele.id }),
      );
    setLocalFiles(
      aux
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort((a, b) => {
          if (a.sortIndex === undefined) {
            return 1;
          }
          if (b.sortIndex === undefined) {
            return -1;
          }
          if (a.sortIndex === b.sortIndex) {
            return 0;
          }
          return a.sortIndex.toString() < b.sortIndex.toString() ? -1 : 1;
        }),
    );
  });
}

const getColumns = ({
  t,
  onRemove,
  items,
  options,
  onChangeSelSortIndex,
}: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (file) => {
      return (
        <FilePreview
          axiosInstance={axiosInstance}
          file={{
            name: file.name,
            id: file.id,
          }}
        />
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ sizeInBytes }) => {
      return (
        <span style={{ fontSize: 13, color: "currentColor" }}>{formatFileSize(sizeInBytes)}</span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 0,
    onRender: (ele: ItemsSelected, idx) => (
      <Tooltip withArrow relationship='label' content={t("Choose the sort index")}>
        <div key={uuidv4()}>
          <Dropdown
            style={{ minWidth: "unset" }}
            options={options(items, ele)}
            selectedOptions={[`${ele?.sortIndex + 1 || ""}`]}
            onOptionSelect={(ev, opt) => {
              ev.stopPropagation();
              onChangeSelSortIndex(ele, opt, idx);
            }}
          />
        </div>
      </Tooltip>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type SelectFilesOfMachineDialogProps = {
  machineId: string;
  currentDrawings: MachineDrawing[];
  hidden: boolean;
  onClose: () => void;
  onSubmit: (drawings: MachineDrawing[]) => void;
};
type ItemsSelected = File & { key: string } & { sortIndex?: number };

const SelectFilesDrawingsOfMachine = ({
  machineId,
  currentDrawings,
  hidden,
  onClose,
  onSubmit,
}: SelectFilesOfMachineDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<ItemsSelected[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddMoreFiles, setShowAddMoreFiles] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    refreshFiles(machineId, currentDrawings, setLocalFiles);
    setIsLoading(false);

    return;
  }, [currentDrawings, machineId]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-files-dialog-machine-drawings",
        version: 1,
      },
      items: localFiles,
      v8Columns: getColumns({
        t,
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
        items: localFiles,
        options: uniqueOptions,
        onChangeSelSortIndex(ele, opt, idx) {
          const newArr = localFiles.slice(0);
          newArr[idx] = {
            ...ele,
            sortIndex: opt.optionValue !== "" ? (opt.optionValue as number) : undefined,
          };

          newArr.forEach((ele, idx) => {
            if (opt.optionValue < ele.sortIndex) {
              newArr[idx] = {
                ...ele,
                sortIndex: undefined,
              };
            }
          });

          setLocalFiles(newArr);
        },
      }),
      perPage: localFiles?.length,
      hidePerPage: true,
    }),
    [localFiles, t],
  );

  return (
    <>
      <BaseDialog
        open={!hidden}
        onOpenChange={() => {
          onClose();
          setLocalFiles(undefined);
        }}
      >
        <BaseDialogTitle>{t("Select Drawings Files")}</BaseDialogTitle>
        <DialogContent>
          {!localFiles ? (
            <Spinner size='small' />
          ) : localFiles?.length > 0 ? (
            <>
              <Table {...tableProps} />

              <Button
                onClick={() => {
                  setShowAddMoreFiles(true);
                }}
              >
                {t("Add more image files")}
              </Button>
              <DialogActions>
                <Button
                  appearance='primary'
                  disabled={
                    localFiles.filter((ele) => (ele.sortIndex !== undefined ? true : false))
                      .length === 0
                  }
                  icon={isSubmitting ? <Spinner size='extra-tiny' /> : null}
                  onClick={() => {
                    setIsSubmitting(true);
                    onSubmit(
                      localFiles
                        .filter((ele) => (ele.sortIndex !== undefined ? true : false))
                        .map((ele) => {
                          return {
                            fileId: ele.id,
                            sortIndex: ele.sortIndex,
                          };
                        }),
                    );
                    setLocalFiles(undefined);
                    onClose();
                    setIsSubmitting(false);
                  }}
                >
                  {t("Assign Machine Drawings")}
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    setLocalFiles(undefined);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </DialogActions>
            </>
          ) : (
            <NoFilesToSelect
              machineId={machineId}
              onClose={() => onClose()}
              onSubmit={() => refreshFiles(machineId, currentDrawings, setLocalFiles)}
            />
          )}
        </DialogContent>
      </BaseDialog>
      {showAddMoreFiles && (
        <AddDocumentDialog
          hidden={!showAddMoreFiles}
          accept={".jpg,.jpeg,.png"}
          onClose={() => setShowAddMoreFiles(false)}
          onSubmit={(data) => {
            const filesToCreate: CreateFile[] = data.map((ele) => {
              return {
                description: ele.description,
                file: ele.file,
                machineId: machineId,
                name: ele.name,
              };
            });
            addBulkFiles(filesToCreate)
              .then((resp) => console.log({ resp }))
              .finally(() => refreshFiles(machineId, currentDrawings, setLocalFiles));
          }}
        />
      )}
    </>
  );
};

const uniqueOptions = (selectedItems: ItemsSelected[], selectedItem: ItemsSelected) => {
  const auxItems = selectedItems.filter((ele) => (ele.sortIndex !== undefined ? true : false));

  const aaa: any[] = [{ key: "", text: "" }];
  auxItems.forEach((ele) =>
    aaa.push({
      key: ele.sortIndex,
      text: (ele.sortIndex + 1).toString(),
      disabled: true,
    }),
  );
  selectedItem.sortIndex === undefined
    ? aaa.push({
        key: auxItems.length,
        text: (auxItems.length + 1).toString(),
      })
    : aaa.push({
        key: auxItems.length,
        text: (auxItems.length + 1).toString(),
        disabled: true,
      });
  return aaa;
};

export default SelectFilesDrawingsOfMachine;
