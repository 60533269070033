import { useTranslation } from "react-i18next";

import ImagesPivotContainer from "../Generic/ImagesPivotContainer";

type ParkedImagesListProps = {
  hasSearchPermissions: boolean;
};

const ParkedImagesList = ({ hasSearchPermissions }: ParkedImagesListProps) => {
  const { t } = useTranslation();

  return (
    <ImagesPivotContainer
      title={t("Parked Images")}
      hasPermissions={hasSearchPermissions}
      commandBarItemProps={[]}
      handleSearch={(_) => {}}
    />
  );
};

export default ParkedImagesList;
