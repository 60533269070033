import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useProjects } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import FormItemRow from "../Generic/FormItemRow";
import { maxRsshPort, minRsshPort } from "./AddDialog";
import { editWirelessGateway } from "./api";
import type { RequestWirelessGatewayUpdate, WirelessGateway } from "./models";

const getSchema = (serialNumbers: string[], t) =>
  z
    .object({
      id: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Serial Number must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: t("The serial number already exists"),
          },
        ),
      projectId: z.string().optional(),
      ltePlanSize: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
      active: z.boolean().optional(),
      installationLocation: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: t("Try a number between (1.0:1000.0)"),
      },
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.rsshPort);
      },
      {
        path: ["rsshPort"],
        message: t(`Try a number between (${minRsshPort}:${maxRsshPort})`, {
          minRsshPort,
          maxRsshPort,
        }),
      },
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          !isNaN(rsshPortNumber) && rsshPortNumber >= minRsshPort && rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: t(`Try a number between (${minRsshPort}:${maxRsshPort})`, {
          minRsshPort,
          maxRsshPort,
        }),
      },
    );

const getGatewayFields: (t, projects) => FormItemProps[] = (t, projects) => [
  {
    name: "projectId",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Project *") },
    options: Array.from(projects.values()).map((project: any) => {
      return { key: project?.id, text: project?.name };
    }),
  },
  {
    name: "serialNumber",
    type: FormItemType.TextField,
    groupProps: { label: t("Serial Number *") },
    disabled: true,
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: t("Lte Plan Size *") },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: t("Public Key") },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: t("Rssh Port") },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi Password For AP") },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi SSID") },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: t("IMEI") },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Sink Node Editress") },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Channel") },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Ethernet Mac Editress") },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: t("Firmware") },
  },
  {
    name: "active",
    type: FormItemType.Checkbox,
    groupProps: { label: t("Active") },
  },
  {
    name: "installationLocation",
    type: FormItemType.TextField,
    groupProps: { label: t("Installation Location") },
  },
];

type EditDialogProps = Omit<DialogProps, "children" | "open"> & {
  data: WirelessGateway;
  serialNumbers: string[];
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const EditDialog = ({
  data,
  serialNumbers,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditDialogProps) => {
  const { t } = useTranslation();
  const { projects } = useProjects();
  const schema = useMemo(
    () =>
      getSchema(
        serialNumbers.filter((item) => item !== data.serialNumber),
        t,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      serialNumber: data.serialNumber || "",
      id: data.id || "",
      projectId: data.projectId || "",
      ltePlanSize: data.ltePlanSize ? String(data.ltePlanSize) : "",
      publicKey: data.publicKey ? data.publicKey : "",
      rsshPort: data.rsshPort ? String(data.rsshPort) : "",
      wifiPasswordForAP: data.wifiPasswordForAP ? data.wifiPasswordForAP : "",
      wifiSSID: data.wifiSSID ? data.wifiSSID : "",
      iMEI: data.iMEI ? data.iMEI : "",
      wirepassSinkNodeAddress: data.wirepassSinkNodeAddress ? data.wirepassSinkNodeAddress : "",
      wirepassChannel: data.wirepassChannel ? data.wirepassChannel : "",
      ethernetMacAddress: data.ethernetMacAddress ? data.ethernetMacAddress : "",
      firmware: data.firmware ? data.firmware : "",
      active: data.active ? data.active : false,
      installationLocation: data.installationLocation ? data.installationLocation : "",
    },
  });

  // Handlers.
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    !formData.projectId && notification.warning(t("Un-parented gateway"));

    const toSend: RequestWirelessGatewayUpdate = {
      serialNumber: formData.serialNumber,
      id: formData.id,
      projectId: formData.projectId,
      ltePlanSize: Number(formData.ltePlanSize) as number,
      rsshPort: Number(formData.rsshPort) as number,
      publicKey: formData.publicKey,
      wifiPasswordForAP: formData.wifiPasswordForAP,
      wifiSSID: formData.wifiSSID,
      iMEI: formData.iMEI,
      wirepassSinkNodeAddress: formData.wirepassSinkNodeAddress,
      wirepassChannel: formData.wirepassChannel,
      ethernetMacAddress: formData.ethernetMacAddress,
      firmware: formData.firmware,
      active: formData.active,
      installationLocation: formData.installationLocation,
    };
    await editWirelessGateway(toSend).then((response) =>
      onSuccess("status" in response, response.serialNumber),
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{t("Edit new Gateway")}</BaseDialogTitle>
      <DialogContent>
        {watch()?.projectId && (
          <form onSubmit={onSubmit}>
            <FormItemRow label={t("Company")} style={{ marginBottom: "0.75em" }}>
              <Text as='p' size={300} style={{ fontWeight: 600 }}>
                {projects.get(watch().projectId)?.company?.name || ""}
              </Text>
            </FormItemRow>

            {renderFormItems(getGatewayFields(t, projects), {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading || !isValid || !isDirty}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {t("Save Changes")}
              </Button>
              <Button appearance='transparent' onClick={handleClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </BaseDialog>
  );
};
