import "./styles.scss";

import { useAppSelector } from "../../../../../../Hooks";
import { Spin } from "../../../../../common/Spin";
import { selectUserSettings } from "../../../../../Settings/selectors";
import { Stack } from "../../../../../Stack";
import { useHomeOverviewQuery } from "../../../hooks/useHomeOverviewQuery";
import Conditions from "./Panels/Conditions";
import LastDataUpdate from "./Panels/LastDataUpdate";
import Machines from "./Panels/Machines";

export const OVERVIEW_SECTIONS = {
  machinesCounter: <Machines key='machinesCounter' />,
  conditions: <Conditions key='conditions' />,
  lastDataUpdate: <LastDataUpdate key='lastDataUpdate' />,
};

export default function Overview() {
  const { dashboardSettings } = useAppSelector(selectUserSettings);
  const { isLoading } = useHomeOverviewQuery();

  if (isLoading) {
    return <Spin />;
  }

  function renderSetSection(sectionKey: string) {
    return dashboardSettings?.[sectionKey] ? OVERVIEW_SECTIONS[sectionKey] : null;
  }

  return (
    <Stack
      horizontal
      style={{
        gap: "8px",
      }}
    >
      {Object.keys(OVERVIEW_SECTIONS).map(renderSetSection)}
    </Stack>
  );
}
