import { ChevronDownRegular } from "@fluentui/react-icons";
import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React, { useState } from "react";

type LazyCollapseProps = {
  title?: string;
  isCollapsed?: boolean;
  onToggle?: (isExpanded: boolean) => void;
  placeholder?: React.ReactNode;
};

const LazyCollapse = ({
  title = "Trigger 0",
  children,
  isCollapsed: isCollapsedProp = true,
  placeholder,
}: PropsWithChildren<LazyCollapseProps>) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedProp);

  const handleToggle = async () => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  return (
    <div className='collapse-wrapper'>
      <div className='collapse-header-wrapper'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames("collapse-title-container", {
            "cursor-not-allowed": false,
            "opacity-50": false,
          })}
          onClick={handleToggle}
        >
          <ChevronDownRegular
            className={classNames({
              chevron: true,
              collapseOpen: !isCollapsed,
              "opacity-50": false,
            })}
          />

          <span className='collapse-title'>{title}</span>
        </div>
      </div>
      {placeholder && <div className='collapse-content-wrapper'>{placeholder}</div>}
      {!isCollapsed && <div className='collapse-content-wrapper'>{children}</div>}
    </div>
  );
};

export default LazyCollapse;
