export const headerTitle = {
  fontWeight: 600,
  marginLeft: 10,
  display: "inline-flex",
  alignItems: "center",
};

export const headerTitleText = {
  wrapper: { height: 25, marginLeft: 10 },
};

export const headerContainer = {
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "1fr 250px",
  marginBottom: 20,
};

export const maximizeButton = {
  root: {
    justifySelf: "flex-end",
    marginRight: 20,
  },
  flexContainer: {
    display: "grid",
    gridTemplateColumns: "20px 1fr",
  },
};

export const maximizeButtonIcon = {
  minWidth: 15,
  height: 15,
  marginRight: 4,
};

export const buttonsContainer = {
  alignItems: "center",
  justifyContent: "end",
};

export const timestampText = {
  whiteSpace: "nowrap" as const,
  marginRight: 20,
};

export const exportElement = {
  marginRight: 10,
};
