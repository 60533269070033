import { Link } from "@fluentui/react-components";
import type { CSSProperties } from "react";

import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/v9";

export const linkStyle: CSSProperties = {
  color: "#0078d4",
  cursor: "pointer",
  textDecoration: "underline",
};

type GetColumnsOpts = {
  t: any;
  // users: User[];
  locationSearch: string;
};

export const getColumns = ({ t, locationSearch }: GetColumnsOpts): Column[] => [
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: t("Date"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => item.createdAt && format(new Date(item.createdAt)),
  },
  {
    key: "createdBy",
    fieldName: "createdBy",
    name: t("Created By"),
    minWidth: 100,
    isSortable: true,
    isExportable: false,
    // onRender: (item) => getUserById(users, item.createdBy),
  },
  {
    key: "text",
    fieldName: "text",
    name: t("Thread"),
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (item) => {
      return (
        <Link href={`threads/${item.id}${locationSearch}`} style={linkStyle}>
          {item.text}
        </Link>
      );
    },
  },
];
