import "./ClusterList.scss";

import { SearchBox } from "@fluentui/react-components";
import { groupBy } from "lodash-es";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useSearch } from "../../../../../../../../Hooks";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../../../../modules/machine-cv/constants";
import { NoData } from "../../../../../../../common/NoData";
import { Stack } from "../../../../../../../Stack";

function renderMachine({ name, machineId, color }: any) {
  return (
    <Link
      key={machineId}
      to={`/machine-cv/summary?id=${machineId}`}
      className='cluster-list-machine'
    >
      <Stack horizontal style={{ gap: 8 }} verticalAlign='center'>
        <div className='condition-dot' style={{ background: color }} />
        {name}
      </Stack>
    </Link>
  );
}

export default function ClusterList({ clusterPoints }) {
  const { t } = useTranslation();
  const { data, handleSearch } = useSearch({
    data: clusterPoints as any,
    keys: ["name", "projectName"],
  });

  const [conditionFilter, setConditionFilter] = useState("All");

  const dataFilteredByCondtion = useMemo(() => {
    if (conditionFilter === "All") return data;

    return data.filter(({ condition }: any) => condition === conditionFilter);
  }, [data, conditionFilter]);

  const machinesGroupedByProjects = useMemo(
    () => groupBy(dataFilteredByCondtion, "projectName"),
    [dataFilteredByCondtion],
  );

  const machinesGroupedByCondition = useMemo(
    () => groupBy([{ condition: "All", color: "#fff" }, ...data], "condition"),
    [data],
  );

  return (
    <div className='cluster-list'>
      <Stack
        horizontal
        wrap
        style={{
          padding: 4,
          cursor: "pointer",
          gap: 4,
        }}
      >
        {Object.keys(machinesGroupedByCondition).map((condition) => {
          const conditionKey = condition.replaceAll(" ", "");
          const content =
            condition === "All" ? (
              <strong>{t(condition)}</strong>
            ) : (
              <>
                {getConditionFormat(t)[condition]}{" "}
                <strong>{`${machinesGroupedByCondition[condition].length}`}</strong>
              </>
            );

          return (
            <Stack.Item
              key={condition}
              className='condition-pill'
              style={{
                border: condition === "All" ? "1px solid" : "none",
                background: conditionColor[conditionKey],
                opacity: ["All", conditionKey].includes(conditionFilter) ? 1 : 0.3,
              }}
              onClick={() => setConditionFilter(conditionKey)}
            >
              {content}
            </Stack.Item>
          );
        })}
      </Stack>

      {clusterPoints.length > 5 && (
        <SearchBox
          style={{ margin: "4px 0", width: "100%" }}
          placeholder={t("Search")}
          onChange={(_, data) => handleSearch(data.value ?? "")}
        />
      )}

      {!dataFilteredByCondtion.length ? (
        <NoData />
      ) : (
        <div style={{ overflow: "auto", maxHeight: "160px" }}>
          {Object.keys(machinesGroupedByProjects).map((project) => {
            const machines = machinesGroupedByProjects[project];

            return (
              <div key={project}>
                <div className='project-name'>{project}</div>
                {machines.map(renderMachine)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
