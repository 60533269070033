import { Button } from "@fluentui/react-components";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import Breadcrumb from "../../../../Components/common/Breadcrumb";
import { getProjectElementId } from "../../../../Components/MachineCV/MachineCVHomePage/selectors";
import { Stack } from "../../../../Components/Stack";
import BreadcrumbItemMachine from "./BreadcrumbItemMachine";
import BreadcrumbItemProject from "./BreadcrumbItemProject";

const MachineCVBreadcrumb = ({ data, width }: { width: number; data: any }) => {
  const navigate = ReactRouterDOM.useNavigate();
  const { corporation, company, project, machine } = data;
  const [selectedProject, setSelectedProject] = useState(project);
  useEffect(() => {
    setSelectedProject(project);
  }, [data]);
  const state = getProjectElementId({
    corporation: corporation?.name || "",
    company: company?.name || "",
    project: selectedProject?.name || "",
  });
  const goBack = () => navigate("/machine-cv", { state });

  const breadcrumbItems = [
    { key: `corporation-${corporation?.name}`, text: corporation?.name },
    { key: `company-${company?.name}`, text: company?.name },
    {
      key: `selectedProject-${selectedProject?.name}`,
      text: selectedProject?.name,
      onRenderContent: () => (
        <BreadcrumbItemProject
          companyId={company?.id}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      ),
    },
    {
      key: `machine-${machine?.name}`,
      text: machine?.name,
      onRenderContent: () => (
        <BreadcrumbItemMachine selectedProject={selectedProject} machine={machine} />
      ),
    },
  ];

  return (
    <Stack horizontal verticalAlign='center' style={{ width: `calc(100% - ${width}px - 32px)` }}>
      <Button appearance='transparent' icon={<ArrowLeftRegular />} onClick={goBack} />

      <Breadcrumb items={breadcrumbItems} />
    </Stack>
  );
};

export default MachineCVBreadcrumb;
