import sortBy from "lodash-es/sortBy";

import type {
  Company,
  CompanyList,
  Corporation,
  CorporationList,
  MachineDetails,
  MachineList,
  Project,
  ProjectList,
  ResponseSimplifiedSignalList,
} from "../../../../types";
import { getApiClient } from "../../../core/apiClient/useApiStore";

export const getCorporationList = () =>
  getApiClient()
    .get<CorporationList>("/meta/read/v1/corporations")
    .then(({ data }) => sortBy(data, ({ name }) => name?.toLowerCase()));

export const getCorporation = (id: string) =>
  getApiClient()
    .get<Corporation>(`/meta/read/v1/corporations/${id}`)
    .then(({ data }) => data);

export const getCompanyList = () =>
  getApiClient()
    .get<CompanyList>("/meta/read/v1/companies")
    .then(({ data }) => sortBy(data, ({ name }) => name?.toLowerCase()));

export const getCompany = (id: string) =>
  getApiClient()
    .get<Company>(`/meta/read/v1/companies/${id}`)
    .then(({ data }) => data);

export const getProjectList = () =>
  getApiClient()
    .get<ProjectList>("/meta/read/v1/projects")
    .then(({ data }) => sortBy(data, ({ name }) => name?.toLowerCase()));

export const getProject = (id: string) =>
  getApiClient()
    .get<Project>(`/meta/read/v1/projects/${id}`)
    .then(({ data }) => data);

export const getMachineList = () =>
  getApiClient()
    .get<MachineList>("/meta/read/v1/machines")
    .then(({ data }) => sortBy(data, ({ name }) => name?.toLowerCase()))
    .catch(() => []);

export const getMachine = (id: string) =>
  getApiClient()
    .get<MachineDetails>(`/meta/read/v1/machines/${id}`)
    .then(({ data }) => data);

export const getSignalsList = (machineId: string) =>
  getApiClient()
    .get<ResponseSimplifiedSignalList>(`/meta/read/v1/machines/${machineId}/signals`)
    .then(({ data }) => {
      if (!Array.isArray(data)) {
        return [];
      }

      return sortBy(data, [
        ({ sensorNo }) => {
          /**
           * Sort priorities:
           * 1 - sensorNo is number
           * 2 - sensorNo is a string value
           * 3 - sensorNo is null
           */
          if (!sensorNo) return Number.MAX_SAFE_INTEGER;

          const parsed = parseInt(sensorNo, 10);

          return isNaN(parsed) ? Number.MAX_SAFE_INTEGER - 1 : parsed;
        },
        ({ signalDescription }) => signalDescription?.toLowerCase(),
        ({ name }) => name?.toLowerCase(),
      ]);
    })
    .catch(() => []);
