import { Controller } from "react-hook-form";

import { MarkdownEditor } from "../../MarkdownEditor";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormTextAreaProps = BaseFormFieldProps &
  any & {
    name: string;
  };

export const FormTextEditor = ({
  name,
  groupProps,
  hookProps: { control },
  placeholder,
}: FormTextAreaProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <MarkdownEditor value={value} placeholder={placeholder} onChange={onChange} />
      )}
    />
  </FormGroup>
);
