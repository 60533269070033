import { Tooltip } from "@fluentui/react-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as ReactRouterDOM from "react-router-dom";

import FluentSchedule from "../../../../assets/svg/FluentSchedule";
import MachineCvSensors from "../../../../assets/svg/MachineCvSensors";
import MachineCVSummary from "../../../../assets/svg/MachineCVSummary";
import MultipleComments from "../../../../assets/svg/MultipleComments";
import RawDataIcon from "../../../../assets/svg/RawDataIcon";
import TaskIcon from "../../../../assets/svg/TaskIcon";
import TrendsIcon from "../../../../assets/svg/TrendsIcon";
import type { MachineCvOverviewProject } from "../../../../types";
import { format } from "../../../../utils";
import type { Column as TableColumn } from "../../../common/Table/v9";
import type { TableProps } from "../../../common/Table/v9/Table";
import Table from "../../../common/Table/v9/Table";
import type { Column, CompactItem } from "../hooks/useOverviewStore";
import useOverviewStore from "../hooks/useOverviewStore";

type CompactColumn = Omit<TableColumn, "key"> & {
  key: Column;
};

const getColumns: (t) => CompactColumn[] = (t) => [
  {
    key: "dalogId",
    fieldName: "dalogId",
    name: t("Dalog Id"),
    isSortable: true,
    minWidth: 100,
  },
  {
    key: "machineName",
    fieldName: "machineName",
    name: t("Machine"),
    isSortable: true,
    minWidth: 100,
    onRender: (item) => (
      <ReactRouterDOM.Link to={`/machine-cv/summary?id=${item.id}`}>
        {item.machineName}
      </ReactRouterDOM.Link>
    ),
  },
  {
    key: "customerCode",
    fieldName: "customerCode",
    name: t("Code"),
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "conditionName",
    fieldName: "conditionName",
    name: t("Condition"),
    minWidth: 100,
    isSortable: true,
    getCellClassName: (item) =>
      `Compact-DataGridCell Home-DataGridCell Home-DataGridCell--${item?.conditionName}`,
  },
  {
    key: "conditionComment",
    fieldName: "conditionComment",
    name: t("Condition Comment"),
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "activeTasks",
    fieldName: "activeTasks",
    name: t("Active tasks"),
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "taskDescription",
    fieldName: "taskDescription",
    name: t("Task description"),
    minWidth: 200,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "taskResponsible",
    fieldName: "taskResponsible",
    name: t("Task Responsible"),
    minWidth: 200,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "taskDueDate",
    fieldName: "taskDueDate",
    name: t("Next Due Date"),
    minWidth: 100,
    isSortable: true,
    onRender: (item: CompactItem) => (item.taskDueDate ? format(new Date(item.taskDueDate)) : ""),
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 180,
    onRender: (item: CompactItem) => (
      <div style={{ display: "flex" }}>
        {item.actions.map(({ name, link, icon }, idx) => (
          <Tooltip key={idx} withArrow content={name} relationship='label'>
            <ReactRouterDOM.Link to={link} className='router-link' style={{ margin: "0 5px" }}>
              {icon}
            </ReactRouterDOM.Link>
          </Tooltip>
        ))}
      </div>
    ),
  },
];

type CompactLayoutProps = {
  project: MachineCvOverviewProject;
};

const CompactLayout = ({ project }: CompactLayoutProps) => {
  const { t } = useTranslation();
  const _columns = useOverviewStore((state) => state.columns);
  const v8Columns = getColumns(t).filter(({ key }) => _columns.includes(key));

  const items = project.machines.map(({ tasks, condition, metaData }) => {
    return {
      id: metaData.id,
      dalogId: metaData.dalogId,
      customerCode: metaData.customerCode,
      machineName: metaData.name,
      conditionName: condition,
      conditionComment: metaData.comment,
      activeTasks: tasks.activeTasksCount,
      taskDescription: tasks.nextDueDescription,
      taskResponsible: tasks.nextAssignedToDisplayName,
      taskDueDate: tasks.nextDueDate,
      actions: [
        {
          name: t("Summary"),
          link: `/machine-cv/summary?id=${metaData.id}`,
          icon: <MachineCVSummary />,
        },
        {
          name: t("Sensors"),
          link: `./sensors?id=${metaData.id}`,
          icon: <MachineCvSensors />,
        },
        {
          name: t("Tasks"),
          link: `./tasks?id=${metaData.id}`,
          icon: <TaskIcon />,
        },
        {
          name: t("Comments"),
          link: `./comments?id=${metaData.id}`,
          icon: <MultipleComments />,
        },
        {
          name: t("Reports"),
          link: `./reports?id=${metaData.id}`,
          icon: <FluentSchedule />,
        },
        {
          name: t("Trend"),
          link: `/trend-view?id=${metaData.id}`,
          icon: <TrendsIcon fill='#2c529f' />,
        },
        {
          name: t("Raw data"),
          link: `/raw-data?id=${metaData.id}`,
          icon: <RawDataIcon />,
        },
      ],
    };
  });

  const persistOpts = useMemo(
    () => ({
      sortKey: project.metaData.id,
      key: "table-overview",
      version: 5,
    }),
    [project.metaData.id],
  );

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts,
      items,
      hidePerPage: true,
      v8Columns,
    }),
    [persistOpts, items, v8Columns],
  );

  return (
    <>
      <Table {...tableProps} />
    </>
  );
};

export default CompactLayout;
