/* eslint-disable react-hooks/exhaustive-deps */

import { DialogContent, Spinner, Text } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../common/Dialog";
import { notification } from "../../common/Notification";
import { Stack } from "../../Stack";
import { ImagesAPI } from "../api";
import type { ImageType, UploadImageAdditionalFormData, UploadImageFormData } from "../models";

type ImagesUploadProgressDialogProps = {
  imageType: ImageType;
  files: File[];
  onClose: (dataChanged: boolean) => void;
  additionalData?: UploadImageAdditionalFormData;
};

/**
 * Gets the Images upload progress dialog component.
 * @param imageType the Image(s) type.
 * @param files The files list.
 * @param additionalData Any additional data required in the HTTP request body.
 * @param onClose Method called when this dialog is being closed.
 * @returns The Images upload progress dialog component.
 */
const ImagesUploadProgressDialog = ({
  imageType,
  files,
  additionalData,
  onClose,
}: ImagesUploadProgressDialogProps) => {
  const { t } = useTranslation();
  const [imagesProcessed, setImagesProcessed] = useState<number>(0);
  const [dataUploaded, setDataUploaded] = useState<boolean>(false);

  // Uploads all images (one by one).
  useEffect(() => {
    if (!files || files.length === 0) {
      onClose?.(false);
      return;
    }

    if (imagesProcessed === files.length) {
      onClose?.(dataUploaded);
      return;
    }

    const body: UploadImageFormData = {
      file: files[imagesProcessed],
      ...additionalData,
    };

    ImagesAPI.uploadImage(imageType, body).then((response) => {
      if (response.status !== 202) {
        notification.error(
          t(`Failure uploading file '{{fileName}}': {{statusText}}.`, {
            fileName: body.file.name,
            statusText: response.statusText,
          }),
        );
      } else {
        notification.success(
          t(`Success uploading file '{{fileName}}'`, {
            fileName: body.file.name,
          }),
        );
        !dataUploaded && setDataUploaded(true);
      }

      setImagesProcessed(imagesProcessed + 1);
    });
  }, [imagesProcessed]);

  return (
    <BaseDialog
      open={true}
      surfaceStyle={{ width: DialogSize.AUTO }}
      onOpenChange={() => onClose(true)}
    >
      <BaseDialogTitle></BaseDialogTitle>
      <DialogContent>
        <Stack
          horizontalAlign='center'
          verticalAlign='center'
          style={{ minHeight: "8em", minWidth: "8em", gap: 20, padding: 10 }}
        >
          <Spinner size='small' />

          <Text size={300} style={{ fontWeight: "600" }}>
            {t(`Uploading {{processedFiles}} of {{totalFiles}} images...`, {
              processedFiles: imagesProcessed + 1,
              totalFiles: files?.length ?? 0,
            })}
          </Text>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default ImagesUploadProgressDialog;
