import { Dialog, DialogBody, DialogSurface, mergeClasses } from "@fluentui/react-components";

import { DraggableSurface } from "./components";
import { useStyles } from "./components/useStyles";
import type { BaseDialogProps } from "./utils";

const BaseDialog = ({
  hasResize,
  isDraggble,
  open,
  children,
  surfaceStyle,
  ...rest
}: BaseDialogProps & { hasResize?: boolean; isDraggble?: boolean }) => {
  const classes = useStyles();
  const modalType = isDraggble ? "non-modal" : "modal";
  const Surface = isDraggble ? DraggableSurface : DialogSurface;

  let surfaceClassNames = classes.surface;

  if (hasResize) {
    surfaceClassNames = mergeClasses(classes.resize, classes.surface);
  }

  return (
    <Dialog open={open} modalType={modalType} {...rest}>
      <Surface
        className={surfaceClassNames}
        style={surfaceStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogBody onClick={(e) => e.stopPropagation()}>{children}</DialogBody>
      </Surface>
    </Dialog>
  );
};

export default BaseDialog;
