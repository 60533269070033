/* eslint-disable react-hooks/exhaustive-deps */

import { DialogContent, Spinner, Text } from "@fluentui/react-components";
import saveAs from "file-saver";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";

import { convertToZipFileName } from "../../../../schema/Utils";
import type { BaseDialogProps } from "../../../common/Dialog";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { downloadImage } from "../../../Machines/MachineDetails/Images/api";
import type { Image } from "../../../Machines/models";
import { Stack } from "../../../Stack";

type DownloadImagesProgressDialogProps = Omit<BaseDialogProps, "children"> & {
  images: Image[];
  onClose: () => void;
};

const containerStyles: CSSProperties = {
  minHeight: "8em",
  minWidth: "8em",
  padding: "1em",
  rowGap: "2em",
};

export const DownloadImagesProgressDialog = ({
  images,
  onClose,
  ...rest
}: DownloadImagesProgressDialogProps) => {
  const [imagesProcessed, setImagesProcessed] = useState<number>(0);

  // Downloads the images.
  useEffect(() => {
    if (images.length === 0 || imagesProcessed === images.length) {
      onClose();
      return;
    }

    let mount = true;
    const image = images.at(imagesProcessed);
    downloadImage(image.id)
      .then(
        (response) =>
          mount === true &&
          saveAs(
            new Blob([response], { type: "application/zip" }),
            convertToZipFileName(image.fileName),
          ),
      )
      .then(() => mount === true && setImagesProcessed(imagesProcessed + 1));

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [imagesProcessed]);

  return (
    <BaseDialog
      {...rest}
      open={true}
      surfaceStyle={{ width: DialogSize.AUTO }}
      onOpenChange={onClose}
    >
      <BaseDialogTitle></BaseDialogTitle>
      <DialogContent>
        <Stack
          role='presentation'
          horizontalAlign='center'
          verticalAlign='center'
          style={containerStyles}
        >
          <Stack.Item>
            <Spinner size='small' />
          </Stack.Item>
          <Text
            size={300}
            style={{
              fontWeight: "600",
            }}
          >{`Downloading ${imagesProcessed + 1} of ${images.length ?? 0} images...`}</Text>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};
