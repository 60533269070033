import { Spinner } from "@fluentui/react-components";
// eslint-disable-next-line import/no-named-as-default
import Tree from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import { NoData } from "../../../../Components/common/NoData";
import CustomSelectNode from "../CustomSelectNode";
import useTrendViewSelectSignals from "./useTrendViewSelectSignals";

export const PAGE_TYPE_TREND = "TREND";

const SelectSignals = () => {
  const { t } = useTranslation();
  const { required, handlers, isLoading, hasData } = useTrendViewSelectSignals();

  if (isLoading) {
    return <Spinner size='small' label={t("Loading signal selection...")} />;
  }

  if (required.data.length === 0) {
    return (
      <NoData
        text={t("There is no data!")}
        style={{
          width: "100%",
        }}
      />
    );
  }

  if (!hasData && !isLoading) {
    return <NoData text={t("There is no data!")} style={{ width: "100%" }} />;
  }

  return (
    <Tree
      {...required}
      {...handlers}
      disableLines
      depthGap={10}
      renderNode={(props) => (
        <CustomSelectNode {...props} handlers={handlers} treeId='trend-signals-tree' />
      )}
    />
  );
};

export default SelectSignals;
