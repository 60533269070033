import { t } from "i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

interface RefreshStore {
  refreshInterval: any | undefined;
  setRefreshInterval: (refreshInterval: any | undefined) => void;
}

const useRefreshStore = createWithEqualityFn<RefreshStore>(
  (set) => ({
    refreshInterval: { key: "off", text: t("Off") },
    setRefreshInterval: (refreshInterval: any | undefined) => set({ refreshInterval }),
  }),
  shallow,
);

export default useRefreshStore;
