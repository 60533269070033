import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import { Status } from "../../../../schema/status";
import TextField from "../../../common/TextField";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../../../Companies/reducer";
import { useValidation } from "../hooks/useValidation";
import type { CompanySubmission } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";

interface CompanyFieldsProps {
  context: SecondPanelContext;
}

const companySchema = z.object({
  name: z.string().min(1, "Company name is required"),
});

export const CompanyFields: React.FC<CompanyFieldsProps> = ({ context }) => {
  const { t } = useTranslation();
  const companies = useAppSelector(selectCompanies);
  const companyStatus = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [companySub, setCompanySub] = useState<CompanySubmission>(context.initialValues.comp);

  useEffect(() => {
    if (companyStatus === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, companyStatus]);

  const {
    errors: validationErrors,
    validateField,
    setValue,
  } = useValidation({
    schema: companySchema,
    customValidators: {
      name: (value) => {
        if (!value?.trim()) {
          return "Company name is required";
        }
        if (companies.some((company) => company.name === value)) {
          return "Company name already exists";
        }
        return undefined;
      },
    },
    mode: "onChange",
    defaultValues: companySub,
  });

  const handleFieldChange = async (newValue: string) => {
    const trimmedValue = newValue.trim();
    const compNew: CompanySubmission = {
      name: trimmedValue,
      uniqueId: companySub.uniqueId,
    };

    await validateField("name", trimmedValue);
    setValue("name", trimmedValue);
    setCompanySub((prev) => ({ ...prev, ...compNew }));
    context.updateFunction(compNew, companySub.uniqueId);
  };

  return (
    <TextField
      value={companySub.name || ""}
      disabled={!!context.initialValues.comp.id || context.context !== "Company"}
      label={`${t("Company")}:`}
      fieldWrapperProps={{
        validationMessage: validationErrors.name?.message,
      }}
      onChange={(newVal) => handleFieldChange(newVal || "")}
    />
  );
};
