import {
  BreadcrumbItem,
  Button,
  Menu,
  MenuItem as FluentUiMenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from "@fluentui/react-components";
import { MoreHorizontalRegular } from "@fluentui/react-icons";
import { useState } from "react";

const MenuItem = (props) => {
  const { item, id } = props;
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  return (
    <FluentUiMenuItem>
      {item?.onRenderContent ? item?.onRenderContent() : item.text}
    </FluentUiMenuItem>
  );
};

function renderMenuItem(item) {
  return <MenuItem key={item.key} id={item.key.toString()} item={item} />;
}

export const OverflowMenu = ({ id, overflowItems, startDisplayedItems, endDisplayedItems }) => {
  const [open, setOpen] = useState(false);
  const onOpenChange = () => {
    setOpen(!open);
  };
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>(id);

  if (!isOverflowing && overflowItems && overflowItems.length === 0) {
    return null;
  }

  const overflowItemsCount = overflowItems ? overflowItems.length + overflowCount : overflowCount;

  return (
    <BreadcrumbItem>
      <Menu open={open}>
        {overflowItemsCount > 0 && (
          <MenuTrigger>
            <Tooltip withArrow content={`${overflowItemsCount} items`} relationship='label'>
              <Button
                ref={ref}
                id='menu'
                appearance='subtle'
                icon={<MoreHorizontalRegular />}
                aria-label={`${overflowItemsCount} more items`}
                role='button'
                onClick={onOpenChange}
              />
            </Tooltip>
          </MenuTrigger>
        )}
        <MenuPopover>
          <MenuList>
            {isOverflowing && startDisplayedItems.map(renderMenuItem)}
            {overflowItems && overflowItems.map(renderMenuItem)}
            {isOverflowing && endDisplayedItems && endDisplayedItems.map(renderMenuItem)}
          </MenuList>
        </MenuPopover>
      </Menu>
    </BreadcrumbItem>
  );
};
