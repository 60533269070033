import { Switch } from "@fluentui/react-components";
import classnames from "classnames";
import { isEmpty } from "lodash-es";
import type { CSSProperties } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useLocationSearch,
  useMachineCVBreadcrumb,
  useMaximize,
  useUniqueId,
} from "../../../Hooks";
import { DataPanel } from "../../../modules/common/components/DataPanel/DataPanel";
import { breadcrumbDefaultResponse } from "../../../modules/common/services/api/MachineCVBreadcrumb";
import type { DriveLoadEnriched, ResponseSimplifiedSignalWithConditionKey } from "../../../types";
import Dropdown, { useDropdownStyles } from "../../common/Dropdown";
import { Stack } from "../../Stack";
import { useSignalsList } from "../MachineCVSummaryPage/hooks/useSignalsList";
import ColoredChart from "./components/ColoredChart";
import EventsChart from "./components/Events/EventsChart";
import EventsTable from "./components/Events/EventsTable";
import EventsTabs from "./components/Events/EventsTabs";
import EventsHeader from "./components/Events/Header";
import LoadAndPerformance from "./components/LoadAndPerformance";
import MopCharts from "./components/MopCharts";
import useMopMaximizeStore from "./components/MopCharts/MopChartContainer/useMopMaximizeStore";
import TorqueChart from "./components/TorqueChart";
import { useMachineDriveLoad } from "./hooks/useMachineDriveLoad";
import { prepareColorChartSignals, prepareTorqueSignals } from "./methods";

enum DriveLoadLayoutTypes {
  DriveLoad = "Torque & Drive Load",
  EventsAndMOP = "Torque Events and MOP",
}

const headerStyles = {
  marginBottom: "0.75em",
};

const contentStyles = {
  display: "flex",
  justifyContent: "stretch",
  alignItems: "stretch",
  columnGap: "1em",
  rowGap: "1em",
  height: "100%",
};

const containerStyle: CSSProperties = {
  padding: "1em",
  height: "calc(100% - 65px - 2em)",
};

const columnStyles = {
  minWidth: "30em",
  flexGrow: 1,
};

const driveLoadChartStyles = {
  width: "100%",
  minWidth: "25em",
  minHeight: "35em",
  height: "100%",
};

const columnWidthsDriveLoad = {
  long: "calc(51% - 2em)",
  short: "calc(49% - 2em)",
};

const columnWidthsEventsAndMOP = {
  long: "calc(40% - 2em)",
  short: "calc(60% - 2em)",
};

const MachineCVDriveLoadPage = ({ style, ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const { transparent } = useDropdownStyles();
  const [layout, setLayout] = useState<keyof typeof DriveLoadLayoutTypes>("DriveLoad");
  const [{ id: machineId }, ,]: any = useLocationSearch();
  const { signals, isLoading: isLoadingSignals } = useSignalsList(machineId as string);
  const {
    data,
    isLoading: isLoadingMachineDriveLoad,
  }: { data: DriveLoadEnriched; isLoading: boolean; isError: boolean } =
    useMachineDriveLoad(machineId);
  const { data: breadcrumbResponse = breadcrumbDefaultResponse } = useMachineCVBreadcrumb({
    machineId: machineId as string,
    options: { enabled: !!machineId, retry: 0 },
  });
  const [showEvents, setShowEvents] = useState<boolean>(true);

  const uniqueIdTorque = useUniqueId();

  const {
    isChartMaximized: isTorqueChartMaximized,
    maximizeAction: maximizeActionTorque,
    maximizeIcon: maximizeTorqueIcon,
    maximizeLabel: maximizeTorqueLabel,
  } = useMaximize({ id: uniqueIdTorque, page: "torque-chart" });

  const { isMaximized: isMopChartMaximized } = useMopMaximizeStore();

  const onLayoutChange = (_: any, option?: any) => {
    if (option) {
      setLayout(option.optionValue as keyof typeof DriveLoadLayoutTypes);
    }
  };

  const isLoading = isLoadingSignals || isLoadingMachineDriveLoad || !data.calculateDriveLoad;
  const noData =
    !isLoading && (isEmpty(data) || data.calculateDriveLoad === false || signals.length === 0);

  const layoutOptions = Object.keys(DriveLoadLayoutTypes).map((key) => ({
    key,
    text: t(DriveLoadLayoutTypes[key as keyof typeof DriveLoadLayoutTypes]),
  }));

  const selectedLayout = layoutOptions.find(({ key }) => key === layout);

  return (
    <section {...rest} style={{ ...style, ...containerStyle }}>
      <Stack horizontal verticalAlign='center' style={headerStyles}>
        <Dropdown
          button={<span style={{ fontSize: 18, fontWeight: 600 }}>{selectedLayout.text}</span>}
          className={transparent}
          options={layoutOptions}
          selectedOptions={[layout]}
          role='heading'
          onOptionSelect={onLayoutChange}
        />
        {layout === "EventsAndMOP" && (
          <>
            <Switch
              label={t("Show events")}
              style={{
                marginLeft: 10,
                fontWeight: 600,
              }}
              checked={showEvents}
              onChange={(_, { checked }) => setShowEvents(checked ?? false)}
            />
          </>
        )}
      </Stack>
      <div
        className={classnames("torque-chart", {
          "torque-chart--maximized": isTorqueChartMaximized || isMopChartMaximized,
        })}
      >
        <DataPanel
          style={{
            ...contentStyles,
            flexFlow: `${layout === "DriveLoad" ? "row-reverse" : "row"} wrap`,
          }}
          isLoading={isLoading}
          noData={noData}
          noDataText={t("Drive load page is not available for this machine")}
        >
          <Stack
            style={{
              ...columnStyles,
              gap: 10,
              width:
                layout === "DriveLoad"
                  ? columnWidthsDriveLoad.short
                  : columnWidthsEventsAndMOP.short,
            }}
          >
            <div
              style={{ height: "100%", padding: isTorqueChartMaximized ? 16 : 0 }}
              className={classnames({
                "chart-container": true,
                "chart-maximized": isTorqueChartMaximized,
              })}
            >
              <TorqueChart
                maximizeLabel={maximizeTorqueLabel}
                maximizeIcon={maximizeTorqueIcon}
                maximizeAction={maximizeActionTorque}
                signals={prepareTorqueSignals(
                  signals.filter((item: ResponseSimplifiedSignalWithConditionKey) =>
                    ["Trend", "FastTrend"].includes(item.dataType as string),
                  ),
                  data.signals,
                )}
                machine={breadcrumbResponse.machine}
                style={driveLoadChartStyles}
                isDriveLoadLayout={layout === "DriveLoad"}
              />
            </div>
            <div
              className={classnames({
                "chart-maximized": isMopChartMaximized,
              })}
            >
              {layout === "DriveLoad" && (
                <LoadAndPerformance
                  style={{
                    margin: "1em 0em 0.5em 1em",
                    width: "50%",
                    minWidth: "20em",
                  }}
                />
              )}
              {layout === "EventsAndMOP" && (
                <MopCharts machineId={machineId} signals={signals} style={driveLoadChartStyles} />
              )}
            </div>
          </Stack>

          <Stack
            style={{
              ...columnStyles,
              gap: 10,
              width:
                layout === "DriveLoad" ? columnWidthsDriveLoad.long : columnWidthsEventsAndMOP.long,
              display: layout === "DriveLoad" || showEvents ? "flex" : "none",
            }}
          >
            {layout === "DriveLoad" && (
              <ColoredChart
                driveLoadSignalsMetaData={prepareColorChartSignals(
                  signals.filter((item: ResponseSimplifiedSignalWithConditionKey) =>
                    ["Trend", "FastTrend"].includes(item.dataType as string),
                  ),
                  data,
                )}
                style={{ ...driveLoadChartStyles, height: "calc(100% - 8px)" }}
              />
            )}
            {layout === "EventsAndMOP" && !isMopChartMaximized && !isTorqueChartMaximized && (
              <div className='events-table-container'>
                <EventsTable style={driveLoadChartStyles} />
              </div>
            )}
            {layout === "EventsAndMOP" && (
              <Stack horizontalAlign='stretch' style={{ gap: 8 }}>
                <EventsHeader />
                <div className='white-container' style={driveLoadChartStyles}>
                  <EventsTabs />
                  <EventsChart style={{ height: "100%" }} />
                </div>
              </Stack>
            )}
          </Stack>
        </DataPanel>
      </div>
    </section>
  );
};

export default MachineCVDriveLoadPage;
