import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle } from "../../../../common/Dialog";
import { File } from "../../../../common/Preview";
import type { Column } from "../../../../common/Table/v9";
import DocumentsTable from "../DocumentsTable";

const getColumns = (t): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    calculatedWidth: 0,
    minWidth: 250,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ description }) => {
      return <span style={{ fontSize: 13 }}>{description}</span>;
    },
  },
  {
    key: "dateAdded",
    fieldName: "dateAdded",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ dateAdded }) => {
      return <span style={{ fontSize: 13 }}>{dateAdded}</span>;
    },
  },
];
interface DocumentsViewTableProps {
  files: any[];
  onDismiss: () => void;
}

type DocumentsViewDialogProps = Omit<DialogProps, "children"> & {
  onDismiss?: () => void;
  title: string;
} & DocumentsViewTableProps;

const DocumentsViewDialog: FC<DocumentsViewDialogProps> = ({ title, ...props }) => {
  const { t } = useTranslation();
  const { files, onDismiss } = props;

  return (
    <>
      <BaseDialog {...props} onOpenChange={onDismiss}>
        <BaseDialogTitle>{title}</BaseDialogTitle>
        <DialogContent>
          <DocumentsTable items={files} columns={getColumns(t)} />
          <DialogActions>
            <Button appearance='primary' disabled={false} onClick={onDismiss}>
              {t("Close")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    </>
  );
};

export default DocumentsViewDialog;
