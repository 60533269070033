import "./styles.scss";

import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";

import {
  useLocationSearch,
  useMachineCVBreadcrumb,
  useMachineUsersQuery,
  useSearch,
} from "../../../Hooks";
import {
  convertToTzDate,
  findLocationTimezone,
} from "../../../modules/analysis-trend-view/utils/getRangeFormat";
import { apiService } from "../../../modules/common";
import { breadcrumbDefaultResponse } from "../../../modules/common/services/api/MachineCVBreadcrumb";
import type { Project } from "../../../types";
import NoData from "../../common/NoData/NoData";
import Spin from "../../common/Spin/Spin";
import { Stack } from "../../Stack";
import { CommandBar } from "./components/CommandBar";
import { Comment } from "./components/Comment";
import { CommentDialog } from "./components/Comment/CommentDialog";
import { TagsDialog } from "./components/Tags/TagsDialog";
import { useCommentsQuery } from "./hooks/useCommentsQuery";
import useTagsStore from "./hooks/useTagsStore";

const Page = () => {
  const [{ id }, ,] = useLocationSearch();
  const [showThreadCommentDialog, setShowThreadCommentDialog] = useState(false);
  const [showTagsDialog, setShowTagsDialog] = useState(false);

  const { comments, isError, isLoading } = useCommentsQuery({
    machineId: id || "",
  });
  const { isError: isUsersQueryError, isLoading: isUsersQueryLoading } = useMachineUsersQuery({
    machineId: id as string,
  });
  const { selectedTags } = useTagsStore();

  const hideContent = isError || isUsersQueryError || isLoading || isUsersQueryLoading;

  const commentsFilteredByTags = useMemo(() => {
    if (selectedTags.length === 0) {
      return comments;
    }

    const selectedTagsNames = selectedTags.map(({ name }) => name);

    return comments.filter(({ tags }) => {
      return tags.some((tag) => selectedTagsNames.includes(tag.name));
    });
  }, [comments, selectedTags.length]);

  const { data, handleSearch } = useSearch({
    data: commentsFilteredByTags,
    keys: ["text"],
  });

  const { data: breadcrumdResponse = breadcrumbDefaultResponse } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  const { data: projectData = {} as Project } = useQuery(
    ["project-data", breadcrumdResponse?.project?.id],
    () => apiService.metaDataRead.getProject(breadcrumdResponse?.project?.id),
    {
      cacheTime: 100000,
      enabled: !!breadcrumdResponse?.project?.id,
    },
  );

  const preparedCommentsToShow = useMemo(() => {
    const projectTimezone = findLocationTimezone(projectData);
    return data.map((item: any) => {
      const convertedDate = convertToTzDate(item.createdAt, null, projectTimezone);

      return {
        ...item,
        createdAt: convertedDate,
      };
    });
  }, [data, projectData]);

  const onAdd = () => {
    setShowThreadCommentDialog(true);
  };

  const onManageTags = () => {
    setShowTagsDialog(true);
  };

  return (
    <div style={{ padding: "0 16px" }}>
      <CommandBar handleSearch={handleSearch} onAdd={onAdd} onManageTags={onManageTags} />

      {(isLoading || isUsersQueryLoading) && <Spin style={{ marginTop: "50px" }} />}
      {(isError || isUsersQueryError) && <NoData style={{ marginTop: "50px" }} />}

      {!hideContent ? (
        <Stack className='comments-wrapper'>
          {preparedCommentsToShow.map((item, idx) => (
            <Comment key={idx} comment={item} />
          ))}
        </Stack>
      ) : null}

      <CommentDialog
        open={showThreadCommentDialog}
        comment={undefined}
        onDismiss={() => setShowThreadCommentDialog(false)}
      />
      <TagsDialog open={showTagsDialog} onDismiss={() => setShowTagsDialog(false)} />
    </div>
  );
};

export default Page;
