import { ChevronDownRegular } from "@fluentui/react-icons";
import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React, { useState } from "react";

type CollapseProps = {
  title?: string;
  withChevron?: boolean;
  isCollapsed?: boolean;
  headerContent?: React.ReactElement;
  onToggle?: (isExpanded: boolean) => void;
};

const Collapse = ({
  title = "Trigger 0",
  withChevron = true,
  children,
  headerContent,
  isCollapsed: isCollapsedProp = true,
  onToggle,
}: PropsWithChildren<CollapseProps>) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedProp || false);

  const toggleIsCollapsed = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    onToggle?.(!newIsCollapsed);
  };

  return (
    <div className='collapse-wrapper'>
      <div className='collapse-header-wrapper'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className='collapse-title-container' onClick={toggleIsCollapsed}>
          {withChevron && (
            <ChevronDownRegular
              className={classNames({
                chevron: true,
                collapseOpen: !isCollapsed,
              })}
            />
          )}
          <span className='collapse-title'>{title}</span>
        </div>
        {headerContent && <>{headerContent}</>}
      </div>
      {!isCollapsed && <div className='collapse-content-wrapper'>{children}</div>}
    </div>
  );
};

export default Collapse;
