import { Button, Spinner, Tooltip } from "@fluentui/react-components";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import Breadcrumb from "../../common/Breadcrumb";
import { notification } from "../../common/Notification";
import { Stack } from "../../Stack";
import type { ValidationResult } from "./SubmissionsDetails";

interface HeaderProps {
  goBack: () => void;
  item: { id: string; sID: string };
  isNotLog: boolean;
  rejectSubmission: (id: string) => Promise<any>;
  id: string;
  feedback: ValidationResult;
  SubmitApproval: () => void;
  navigate: (path: string) => void;
  isSubmitting: boolean;
}

const Header: React.FC<HeaderProps> = ({
  goBack,
  item,
  isNotLog,
  rejectSubmission,
  id,
  feedback,
  SubmitApproval,
  navigate,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      className='breadcrumb-container'
      horizontalAlign='space-between'
      verticalAlign='center'
      style={{ padding: "8px 16px", borderBottom: "1px solid #EDEBE9" }}
    >
      <Stack horizontal verticalAlign='center' style={{ width: `calc(100% - ${50}px - 32px)` }}>
        <Button appearance='transparent' icon={<ArrowLeftRegular />} onClick={goBack} />
        <Breadcrumb
          items={[
            {
              key: `submissions-key`,
              text: t(`Submissions`),
            },
            { key: `submission-${item.id}`, text: item.sID },
          ]}
        />
      </Stack>
      {isNotLog && (
        <Stack horizontal>
          <Button
            appearance='transparent'
            style={{ width: 100, marginRight: 10 }}
            disabled={isSubmitting}
            onClick={() =>
              rejectSubmission(id).then((resp) => {
                if ("data" in resp) {
                  notification.success(
                    t("Submission {{id}} successfully rejected.", {
                      id: item.sID,
                    }),
                  );
                  navigate("/submissions");
                } else notification.error(t("Error: {{message}}", { message: resp.Error }));
              })
            }
          >
            {t("Reject")}
          </Button>
          <Tooltip
            withArrow
            relationship='label'
            content={feedback ? feedback.messages.at(0) : ""}
            positioning='above'
          >
            <Button
              appearance='primary'
              style={{ width: 100 }}
              disabled={feedback ? true : false || isSubmitting}
              onClick={SubmitApproval}
            >
              {isSubmitting ? (
                <Stack horizontal verticalAlign='center'>
                  <Spinner size='tiny' />
                  <span style={{ marginLeft: 8 }}>{t("Approving...")}</span>
                </Stack>
              ) : (
                <>{t("Approve")}</>
              )}
            </Button>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
