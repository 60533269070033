import type { BreadcrumbProps as FluentUiBreadcrumbProps } from "@fluentui/react-components";
import {
  Breadcrumb as FluentUiBreadcrumb,
  BreadcrumbDivider,
  BreadcrumbItem,
  makeStyles,
  Overflow,
  OverflowDivider,
  OverflowItem,
  partitionBreadcrumbItems,
} from "@fluentui/react-components";
import classNames from "classnames";
import { uniqueId } from "lodash-es";
import * as React from "react";

import { OverflowMenu } from "./OverflowMenu";

type BreadcrumbProps = FluentUiBreadcrumbProps & { items: any[] };

const useStyles = makeStyles({
  overflow: {
    padding: "5px",
    zIndex: 0, //stop the browser resize handle from piercing the overflow menu
    height: "fit-content",
    minWidth: "200px",
    width: "100%",
  },
});

const OverflowGroupDivider: React.FC<{
  groupId: number;
}> = (props) => {
  return (
    <OverflowDivider groupId={props.groupId.toString()}>
      <BreadcrumbDivider data-group={props.groupId} />
    </OverflowDivider>
  );
};

const renderBreadcrumbItem = ({ isLastItem, key, text, onRenderContent }) => {
  return (
    <React.Fragment key={`button-items-${key}`}>
      <OverflowItem id={key.toString()} groupId={key.toString()}>
        <BreadcrumbItem style={{ padding: "8px 4px" }}>
          {onRenderContent ? onRenderContent() : text}
        </BreadcrumbItem>
      </OverflowItem>
      {!isLastItem && <OverflowGroupDivider groupId={key} />}
    </React.Fragment>
  );
};

export default function Breadcrumb({ className, items, ...rest }: BreadcrumbProps) {
  const { overflow } = useStyles();
  const overflowMenuId = uniqueId();
  const breadcrumbItems = items.map((item, idx) => ({
    ...item,
    isLastItem: idx === items.length - 1,
  }));

  const { startDisplayedItems, overflowItems, endDisplayedItems } = partitionBreadcrumbItems({
    items: breadcrumbItems,
  });

  return (
    <div className={classNames(className, overflow)}>
      <Overflow>
        <FluentUiBreadcrumb {...rest}>
          {startDisplayedItems.map(renderBreadcrumbItem)}

          {endDisplayedItems && endDisplayedItems.map(renderBreadcrumbItem)}

          <OverflowMenu
            id={overflowMenuId}
            overflowItems={overflowItems}
            startDisplayedItems={startDisplayedItems}
            endDisplayedItems={endDisplayedItems}
          />
        </FluentUiBreadcrumb>
      </Overflow>
    </div>
  );
}
