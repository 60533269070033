/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import TextArea, { useLargeCommentField } from "../../../common/TextArea";
import { Stack } from "../../../Stack";
import { VpnPCsAPI } from "../../Schema/api";
import type { ConnectionEntryDetailed, MetadataProject, MetadataVpnPC } from "../../Schema/models";
import { AutomationStatusType, ConnectionStatusType } from "../../Schema/models";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { FormItemRow, FormItemSection } from "../generic/FormDialogComponents";
import { withLoadingPanelHOC } from "../generic/HOCs";

type ConnectionDetailsDialogProps = {
  open?: boolean;
  projects: MetadataProject[];
  item: ConnectionEntryDetailed;
  onEdit: (data: ConnectionEntryDetailed) => void;
  onClose: () => void;
};

type ConnectionDetailsDataProps = {
  t: any;
  item: ConnectionEntryDetailed;
  projects: MetadataProject[];
  vpnPCs: MetadataVpnPC[];
};

type VpnConnectionDetailsData = {
  projectName: string;
  companyName: string;
  country: string;
  vpnPcName: string;
  automationStatus: string;
  automationStatusComments: string;
  connectionStatus: string;
  connectionStatusComments: string;
  connectionDetails: string;
};

/**
 * Gets the item details data.
 * @param item The detailed VPN Connection entry to convert.
 * @param projects The projects reference list.
 * @param vpnPCs The VPN PCs reference list.
 * @returns The Connection details data object.
 */
const getDetailsData = ({
  t,
  item,
  projects,
  vpnPCs,
}: ConnectionDetailsDataProps): VpnConnectionDetailsData => {
  let result: VpnConnectionDetailsData = {
    projectName: t("Project not found."),
    companyName: "",
    country: t("Country not found."),
    vpnPcName: "",
    automationStatus: AutomationStatusType[item.automationStatus.status],
    automationStatusComments: item.automationStatus.comment || "",
    connectionStatus: ConnectionStatusType[item.connectionStatus.status],
    connectionStatusComments: item.connectionStatus.comment || "",
    connectionDetails: item.connectionDetails || "",
  };

  // Gets VPN PC information.
  const vpnPC = vpnPCs.find((pc) => pc.id === item.vpnPcId);
  result = { ...result, vpnPcName: vpnPC?.name || "" };

  // Gets project information.
  const project = projects.find((p) => p.id === item.projectId);
  if (!project) {
    return result;
  }

  result = {
    ...result,
    projectName: project.name,
    country: project.country || "",
    companyName: project.company?.name || "",
  };

  return result;
};

/**
 * Gets the connection details dialog.
 * @param companies The companies list.
 * @param projects The projects list.
 * @param item The VPN connection table item
 * @param onEdit The method called when the Edit button is clicked. Use it to display the Edit VPN Connection Dialog.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const ConnectionDetailsDialog = ({
  open = false,
  projects,
  item,
  onEdit,
  onClose,
}: ConnectionDetailsDialogProps) => {
  const { t } = useTranslation();
  const { largeArea } = useLargeCommentField();
  const axiosInstance = useContext(AxiosContext);
  const [data, setData] = useState<VpnConnectionDetailsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Gets the VPN PCs and builds the detailed item.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.list(axiosInstance).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting VPN PCs: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        onClose?.();
        return;
      }

      const dialogData = getDetailsData({
        t,
        item: item,
        projects: projects,
        vpnPCs: response.data,
      });

      setData(dialogData);
    });
  }, [item, axiosInstance]);

  return withLoadingPanelHOC(
    t,
    !isLoading,
    <BaseDialog open={open} surfaceStyle={{ width: DialogSize.S }} onOpenChange={onClose}>
      <BaseDialogTitle>{t("VPN Connection Details")}</BaseDialogTitle>

      <DialogContent>
        <Stack verticalAlign='center'>
          <FormItemSection title={t("General Details")}>
            <FormItemRow label={t("Project:")}>
              <p className='label-value-text'>{data?.projectName || ""}</p>
            </FormItemRow>
            <FormItemRow label={t("Company:")}>
              <p className='label-value-text'>{data?.companyName || ""}</p>
            </FormItemRow>
            <FormItemRow label={t("Country:")}>
              <p className='label-value-text'>{data?.country || ""}</p>
            </FormItemRow>
            <FormItemRow label={"VPN PC:"}>
              <p
                className='label-value-text'
                style={{ color: data?.vpnPcName ? "rgb(50, 49, 48)" : "red" }}
              >
                {data?.vpnPcName || t("Unassigned")}
              </p>
            </FormItemRow>
          </FormItemSection>
          <FormItemSection title={t("Automation Status")}>
            <FormItemRow label={t("Status:")}>
              <p className='label-value-text'>{data?.automationStatus || ""}</p>
            </FormItemRow>
            <FormItemRow label={t("Comments:")}>
              <p className='label-value-text'>{data?.automationStatusComments || ""}</p>
            </FormItemRow>
          </FormItemSection>
          <FormItemSection title={t("Connection Status")}>
            <FormItemRow label={t("Status:")}>
              <p className='label-value-text'>{data?.connectionStatus || ""}</p>
            </FormItemRow>
            <FormItemRow label={t("Comments:")}>
              <p className='label-value-text'>{data?.connectionStatusComments || ""}</p>
            </FormItemRow>
          </FormItemSection>
          <FormItemSection title={t("Connection Details")}>
            <TextArea className={largeArea} readOnly={true} value={data?.connectionDetails || ""} />
          </FormItemSection>
        </Stack>
        <DialogActions>
          <Button appearance='primary' onClick={onClose}>
            {t("Done")}
          </Button>
          <Button onClick={() => onEdit(item)}>{t("Edit Details")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>,
  );
};

export default ConnectionDetailsDialog;
