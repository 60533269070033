import { Button, Text, Tooltip } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { getBookmarkedIcon } from "../../../../../modules/common/components/BookmarkButton";
import useBookmark from "../../../../../modules/common/components/Bookmarks/useBookmark";
import { Stack } from "../../../../Stack";

type MachineNameFieldProps = PropsWithChildren<{
  item: any;
}>;

const MachineNameField = function ({ item, children }: MachineNameFieldProps) {
  const { t } = useTranslation();
  const { toggleBookmark, bookmarks } = useBookmark();
  const [hovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addOrRemoveBookmark = () => {
    toggleBookmark({
      bookmark: { ...item, id: item.machineId },
      onStart: () => setIsLoading(true),
      onSuccess: () => setIsLoading(false),
      onFailed: () => setIsLoading(false),
    });
  };

  const isBookmarked = !!bookmarks.find((bookmark: any) => bookmark.id === item?.machineId);

  return (
    <Stack
      horizontal
      style={{ position: "relative" }}
      className='machine-conditions-machineName'
      verticalAlign='center'
    >
      <Tooltip
        withArrow
        relationship='label'
        content={isBookmarked ? t("Bookmarked") : t("Bookmark")}
      >
        <Button
          disabled={isLoading}
          appearance='transparent'
          style={{
            color: "#2c53a0",
            padding: "0px",
            minWidth: 0,
          }}
          icon={getBookmarkedIcon({ isBookmarked, hovered })}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={(e) => {
            addOrRemoveBookmark();
            e.stopPropagation();
          }}
        />
      </Tooltip>

      <Text
        style={{
          maxWidth: "calc(100% - 22px)",
          color: "rgb(96, 94, 92)",
          fontSize: "12px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Text>
    </Stack>
  );
};

export default MachineNameField;
