import { EraseToolIcon } from "@fluentui/react-icons-mdl2";
import classnames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import HarmonicsIcon from "../../../../assets/svg/HarmonicsIcon";
import { CommandBarItemType } from "../../../../Components/common/CommandBar";
import { useMaximize, useShortTrendFFTForASingleDataPoint, useUniqueId } from "../../../../Hooks";
import useSelectedDataPointsStore from "../../../analysis-raw-data/hooks/useSelectedDataPointsStore";
import type { SpectrumChartSectionProps } from "../../../analysis-raw-data/types";
import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import useHarmonicsStoreV0 from "../../hooks/useHarmonicsStoreV0";
import ChartSectionHeader from "../ChartSectionHeader";
import { useCatalogueStore } from "../FrequencyCatalogueDialog/hooks";
import { PlotSpinner, RawDataPlotNoData } from "../PlotPlaceholders";
import SpectrumChart from "../SpectrumChart";

const SpectrumChartSection = ({
  signal,
  dataPoint,
  dataPointTimestamp,
  isSecond,
}: SpectrumChartSectionProps) => {
  const { t } = useTranslation();

  const selectedDataPoints = useSelectedDataPointsStore((store) => store.selectedDataPoints);

  const { setSelectedSignalValues, toggleIsCatalogueOpen } = useCatalogueStore((store: any) => ({
    setSelectedSignalValues: store.setSelectedSignalValues,
    toggleIsCatalogueOpen: store.toggleIsCatalogueOpen,
  }));
  const uniqueId = useUniqueId();

  const { updateHarmonics } = useHarmonicsStoreV0();

  const { isChartMaximized, isChartHidden, maximizeAction, maximizeIcon, maximizeLabel } =
    useMaximize({ id: uniqueId, page: "raw-data" });

  const {
    data = { values: [] },
    isFetching,
    isLoading,
  } = useShortTrendFFTForASingleDataPoint(
    {
      signalId: signal.id as string,
      dataPointId: dataPoint,
    },
    {
      enabled: !!selectedDataPoints.length && !!dataPoint,
      retry: 0,
    },
  );

  const isDataLoading = isFetching && isLoading;

  const { findTimestamp } = useChartTimestampStore((store) => ({
    timestamps: store.timestamps,
    findTimestamp: store.findTimestamp,
  }));

  const timestamp = findTimestamp(signal.id);

  const menuItems: any = [
    {
      key: "setharmonics",
      text: t("Freq. Cat."),
      type: CommandBarItemType.Button,
      icon: <HarmonicsIcon />,
      onClick: () => toggleIsCatalogueOpen(signal.machineId, true),
    },
    {
      key: "clearharmonics",
      type: CommandBarItemType.Button,
      icon: <EraseToolIcon />,
      onClick: () => {
        updateHarmonics(signal.machineId!, "harmonicsOne", { type: "RESET" });
        updateHarmonics(signal.machineId!, "harmonicsTwo", { type: "RESET" });
        updateHarmonics(signal.machineId!, "harmonicsThree", { type: "RESET" });
      },
    },
  ];

  useEffect(() => {
    setSelectedSignalValues(signal.id, { chartType: "Spectrum" });
  }, []);

  return (
    <div
      className={classnames("spectrum-chart-section", {
        "raw-data-chart-section--hidden": isChartHidden,
        "raw-data-chart-section--maximized": isChartMaximized,
      })}
    >
      <ChartSectionHeader
        isSpectrum
        id={uniqueId}
        title={t("Spectrum")}
        items={isSecond ? undefined : menuItems}
        {...{
          maximizeAction,
          maximizeIcon,
          maximizeLabel,
          timestamp: dataPointTimestamp || timestamp?.timestamp,
        }}
      />
      {isDataLoading && <PlotSpinner />}
      {!isDataLoading && data?.values?.length === 0 && <RawDataPlotNoData signal={signal} />}
      {!isDataLoading && data?.values?.length > 0 && (
        <SpectrumChart data={data} exportId={uniqueId} signal={signal} />
      )}
    </div>
  );
};

export default SpectrumChartSection;
