import { differenceInDays } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useMachineUsersQuery, useSearch } from "../../../../../../Hooks";
import { convertToTzDate } from "../../../../../../modules/analysis-trend-view/utils/getRangeFormat";
import { ShowMoreGrid } from "../../../../../../modules/common/components/Dashboards/ShowMoreGrid";
import type { Comment as IComment } from "../../../../../../types";
import { useTask } from "../../../hooks/useTask";
import useTasksStore from "../../../hooks/useTasksStore";
import SectionTitle from "../../SectionTitle";
import { CommandBar, commentsCreatedDropdown } from "./CommandBar";
import { Comment } from "./Comment";
import { CommentDialog } from "./Comment/CommentDialog";
import type { PeoplePickerDataItem } from "./PeoplePicker";
import { PeoplePicker } from "./PeoplePicker";

const DEFAULT_LIST_SIZE = 3;

export const filterByTime = (days: number, comments: IComment[]) => {
  if (!days) return comments;

  return comments.filter(
    (comment) => differenceInDays(new Date(), new Date(comment.createdAt)) <= days,
  );
};

const onCommentFilter = (item, comments: IComment[], selectedKeys: string[]) => {
  const filteredComments = filterByTime(item.key as number, comments);

  return filteredComments.filter(
    (comment) => !selectedKeys.length || selectedKeys.includes(comment.createdBy),
  );
};

type TaskCommentsProps = { projectTimezone?: any };

export const TaskComments = ({ projectTimezone }: TaskCommentsProps) => {
  const { t } = useTranslation();
  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));
  const [{ id }] = useLocationSearch();
  const [showTaskCommentsDialog, setShowTaskCommentsDialog] = useState(false);

  const { task } = useTask({ taskId: edit.id });

  const [filter, setFilter] = useState(commentsCreatedDropdown(t)[0]);
  const { data, handleSearch } = useSearch<IComment>({
    data: task?.comments,
    keys: ["text"],
  });
  const { usersPersonas } = useMachineUsersQuery({ machineId: id as string });
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const items: IComment[] = useMemo(
    () => onCommentFilter(filter, data, selectedKeys),
    [filter, data, selectedKeys],
  );

  const commentsToShow = useMemo(() => {
    return items.map((item: any) => {
      const convertedDate = convertToTzDate(item.createdAt, null, projectTimezone);
      return {
        ...item,
        createdAt: new Date(convertedDate),
      };
    });
  }, [items, projectTimezone]);

  const peoplePickerData: PeoplePickerDataItem[] = useMemo(() => {
    const current = data.map((item) => item.createdBy);
    return usersPersonas.filter((user) => current.includes(user.id));
  }, [usersPersonas]);

  return (
    <div>
      <SectionTitle>{t("Comments")}</SectionTitle>

      <CommandBar
        filter={filter}
        handleSearch={handleSearch}
        onAdd={() => setShowTaskCommentsDialog(true)}
        onFilter={setFilter}
      />
      <PeoplePicker
        data={peoplePickerData}
        selectedOptions={selectedKeys}
        onChange={setSelectedKeys}
      />

      <ShowMoreGrid
        data={commentsToShow}
        sizeProps={{ size: DEFAULT_LIST_SIZE, increment: DEFAULT_LIST_SIZE }}
        renderComponent={(data) => (
          <div style={{ marginTop: 16 }}>
            {data.map((comment, idx) => (
              <Comment key={idx} item={comment} />
            ))}
          </div>
        )}
      />

      <CommentDialog
        id={edit.id}
        open={showTaskCommentsDialog}
        onDismiss={() => setShowTaskCommentsDialog(false)}
      />
    </div>
  );
};
