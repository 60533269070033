import { Button, Text } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { Stack } from "../../../../../../../Stack";
import { useMachineConditionsTable } from "../../../../Machines/useMachineConditionsTable";

export default function Panel({
  dataKey = "",
  title = "",
  subTitle = "",
  content = null,
  hasClearSelection = false,
  style = {},
}) {
  const { t } = useTranslation();
  const { getFilteredState, clearFilteredDataByKey } = useMachineConditionsTable();

  return (
    <Stack className='panel-card' style={style}>
      <Stack
        horizontal
        wrap
        horizontalAlign='space-between'
        verticalAlign='center'
        style={{
          paddingBottom: "16px",
          gap: "8px",
        }}
      >
        <div>
          <Text block size={400} style={{ fontWeight: 700, lineHeight: "22px" }}>
            {title}
          </Text>
          <Text block size={200} style={{ color: "#605E5C", lineHeight: "16px" }}>
            {subTitle}
          </Text>
        </div>
        {hasClearSelection ? (
          <Button
            disabled={!getFilteredState(dataKey)}
            appearance='primary'
            onClick={() => clearFilteredDataByKey(dataKey)}
          >
            {t("Clear selection")}
          </Button>
        ) : null}
      </Stack>

      {content}
    </Stack>
  );
}
