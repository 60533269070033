import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { maxLengthType1 } from "../../../schema/Constants";
import { Status } from "../../../schema/status";
import BaseDialog, { BaseDialogTitle } from "../../common/Dialog";
import type { FormItemProps } from "../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../common/Form";
import { addCompany, editCompany } from "../../Companies/api";
import type { Company } from "../../Companies/models";
import { listAsyncCorpo, selectCorporations } from "../reducer";
import { selectCorporationDetailsStatus } from "./reducer";

type AddOrEditDialogProps = Omit<DialogProps, "children" | "open"> & {
  data: Company | null;
  items: Company[];
  show: boolean;
  onSuccess: (hasError: boolean, data: Company, context: "add" | "edit") => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data ? name.trim().toLowerCase() !== data.name.trim().toLowerCase() : true,
      ),
    [items, data],
  );

  const getSchema = useCallback(
    (companies: Company[]) =>
      z
        .object({
          id: z.string().optional(),
          name: z
            .string()
            .min(1, { message: t("This field is required") })
            .max(maxLengthType1, {
              message: t(`Name must contain at most {{maxLength}} character(s)`, {
                maxLength: maxLengthType1,
              }),
            }),
          number: z
            .string()
            .max(maxLengthType1, {
              message: t(`Number must contain at most {{maxLength}} character(s)`, {
                maxLength: maxLengthType1,
              }),
            })
            .optional(),
          corporationId: z.string().optional(),
        })
        .refine(
          (input) => {
            if (!input.name) {
              return true;
            }

            return (
              companies
                .map(({ name }) => name.trim().toLowerCase())
                .findIndex((value) => value === input.name.trim().toLowerCase()) === -1
            );
          },
          {
            path: ["name"],
            message: t("The company already exists"),
          },
        ),
    [t],
  );

  const schema = useMemo(() => getSchema(filteredItems), [filteredItems, getSchema]);

  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCorporationDetailsStatus);
  const itemsParent = useAppSelector(selectCorporations);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        corporationId: data?.corporationId || "",
        id: data.id,
        name: data.name,
        number: data.number,
      },
    }),
  });

  useEffect(() => {
    if (statusParent !== Status.idle) dispatch(listAsyncCorpo());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data ? reset(data) : reset({ id: "", name: "", number: "", corporationId: "" });
  }, [data, reset]);

  const companiesFields: FormItemProps[] = [
    {
      name: "corporationId",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Corporation *") },
      options: itemsParent.map((item) => ({ key: item.id, text: item.name })),
    },
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name *") },
    },
    {
      name: "number",
      type: FormItemType.TextField,
      groupProps: { label: t("Number") },
    },
  ];

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const mutation = data ? editCompany : addCompany;
    await mutation(formData as Company).then((response) =>
      onSuccess("status" in response, formData as Company, data ? "edit" : "add"),
    );

    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{data ? t("Edit new company") : t("Add new company")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {renderFormItems(companiesFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isValid}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
