import { Button } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DialogSize } from "../../common/Dialog";
import TextArea from "../../common/TextArea";
import FormDialog from "../../Generic/FormDialog";
import { Stack } from "../../Stack";

type UploadImageBasicProps = {
  title: string;
  accept: string;
  isLoading: boolean;
  isValid: boolean;
  onFilesSelected: (files: File[]) => void;
  onSubmit: () => void;
  onClose: (listHasChanged: boolean) => void;
};

/**
 * Gets the files names in a single string.
 * @param files The files array.
 * @returns The files names in a single string.
 */
const getFilesNames = (files: File[]): string => {
  let result = "";

  for (let i = 0; i < files.length; i++) {
    result += files[i].name;
    if (i !== files.length - 1) {
      result += "\n";
    }
  }

  return result;
};

/**
 * Gets the Upload image basic dialog component.
 * @param title The dialog title.
 * @param accept The accepted image formats.
 * @param isValid Value indicating whether the form fields is valid or not.
 * @param isLoading Value indicating whether the form is in loading state.
 * @param onFilesSelected Method called when a set of files have been selected.
 * @param onSubmit Method called when the submit button is clicked.
 * @param onClose Method called to close this dialog.
 * @returns The upload image basic dialog component.
 */
const UploadImageBasicDialog = ({
  title,
  accept,
  isValid,
  isLoading,
  onFilesSelected,
  onSubmit,
  onClose,
  children,
}: PropsWithChildren<UploadImageBasicProps>) => {
  const { t } = useTranslation();
  const htmlFileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Handlers
  const onFilesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length === 0) {
      return;
    }

    const fileList = event.target.files;
    setSelectedFiles(Array.from(fileList));
    onFilesSelected?.(Array.from(fileList));
  };

  return (
    <FormDialog
      title={title}
      submitButtonText={!isLoading ? t("Upload") : t("Uploading...")}
      isLoading={isLoading}
      isValid={selectedFiles && isValid}
      size={DialogSize.S}
      onSubmit={onSubmit}
      onClose={() => onClose(false)}
    >
      {children}
      <Stack horizontal verticalAlign='end' style={{ gap: 10 }}>
        <Stack.Item grow>
          <TextArea readOnly label={t("Image files")} value={getFilesNames(selectedFiles)} />
        </Stack.Item>
        <Button style={{ borderStyle: "none" }} onClick={() => htmlFileInputRef?.current.click()}>
          {t("Browse...")}
        </Button>
        <input
          ref={htmlFileInputRef}
          multiple
          type='file'
          accept={accept}
          style={{ display: "none" }}
          onChange={onFilesChanged}
        />
      </Stack>
    </FormDialog>
  );
};

export default UploadImageBasicDialog;
