import React, { useState } from "react";

import type { Entry, Group } from "../../../../../types";
import DocumentsViewDialog from "../dialogs/DocumentsViewDialog";
import ActionButtons from "./ActionButtons";

const ColumnName = ({ name = "", value = "", files = [] }) => {
  const [isDocumentViewDialogOpen, setIsDocumentViewDialogOpen] = useState(false);
  const toggleIsDocumentViewDialogOpen = () =>
    setIsDocumentViewDialogOpen(!isDocumentViewDialogOpen);

  const dialogTitle = [name, value].join(value ? " - " : "");

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span
        tabIndex={0}
        role='button'
        style={{ color: "#2C529F", cursor: "pointer" }}
        onClick={toggleIsDocumentViewDialogOpen}
      >
        {name}
      </span>

      <DocumentsViewDialog
        title={dialogTitle}
        open={isDocumentViewDialogOpen}
        files={files}
        onDismiss={toggleIsDocumentViewDialogOpen}
      />
    </>
  );
};

export const getColumns = ({
  t,
  group,
  hasActions,
}: {
  t: any;
  group: Group;
  hasActions: boolean;
}) => [
  {
    key: "name",
    fieldName: "name",
    name: t("Title"),
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
    minWidth: 100,
    onRender(item) {
      if (!item?.name) return "-";

      return <ColumnName {...item} />;
    },
  },
  {
    key: "value",
    fieldName: "value",
    name: t("Value"),
    minWidth: 100,
    maxWidth: 200,
    onRender({ value }) {
      if (!value) return "-";

      return <span>{value}</span>;
    },
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 50,
    maxWidth: 70,
    onRender: (item: Entry) => {
      return hasActions ? <ActionButtons item={item} group={group} /> : "-";
    },
  },
];
