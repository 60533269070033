import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { DialogSize } from "../common/Dialog";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";

export type FormDialogProps = {
  title: string;
  isLoading: boolean;
  isValid: boolean;
  size: DialogSize;
  onSubmit: () => void;
  onClose: () => void;
  submitButtonText?: string;
};

/**
 * Gets a form dialog component with the submit and cancel buttons.
 * @param title The form title.
 * @param type The form type.
 * @param size The form size.
 * @param isLoading Value indicating when the form state is loading.
 * @param isValid Value indicating whether the form data is valid or not.
 * @param onSubmit Function called when the submit button is clicked.
 * @param onClose Function called when the form dialog needs to be closed. (Insert here the function to close which will close this dialog).
 * @returns The form dialog component.
 */
const FormDialog = ({
  title,
  size,
  isLoading,
  isValid,
  onSubmit,
  onClose,
  submitButtonText,
  children,
  ...rest
}: PropsWithChildren<FormDialogProps>) => {
  const { t } = useTranslation();

  const btnText: string = useMemo(() => {
    return submitButtonText ?? t("Save Changes");
  }, [submitButtonText, t]);

  return (
    <BaseDialog {...rest} open={true} surfaceStyle={{ width: size }} onOpenChange={onClose}>
      <BaseDialogTitle>{title}</BaseDialogTitle>

      <DialogContent>
        {children}
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || !isValid}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onSubmit}
          >
            {btnText}
          </Button>
          <Button appearance='transparent' disabled={isLoading} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default FormDialog;
