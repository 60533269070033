// Original path: src/src/Components/Submissions/SubmissionDetails/SubmissionsDetails.tsx

import { Button, Text } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { Status } from "../../../schema/status";
import { notification } from "../../common/Notification";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../../Companies/reducer";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../../Corporations/reducer";
import {
  listAsyncGateways,
  selectWirelessGateways,
  selectWirelessGatewaysStatus,
} from "../../Gateways/reducer";
import { ConfirmDialog } from "../../Generic/ConfirmDialog";
import {
  listAsyncMachines,
  selectMachinesStatus,
  selectMachinesToList,
} from "../../Machines/reducer";
import { listAsyncProjs, selectProjects, selectProjectsStatus } from "../../Projects/reducer";
import {
  listAsyncSensorNodes,
  selectWirelessSensorNodes,
  selectWirelessSensorNodesStatus,
} from "../../SensorNodes/reducer";
import { Stack } from "../../Stack";
import {
  approveSubmission,
  rejectSubmission,
  sendSubmissionsComment,
  submissionsDetails,
} from "../api";
import type { RequestApproveSubmissionAdd, SubmissionSupport } from "../models";
import ChatMessageDisplay from "./ChatPanel/ChatMessageDisplay";
import ChevronItemWithChildren from "./ChevronItemWithChildren";
import DetailsPanel from "./DetailsPanel";
import Header from "./Header";
import type {
  CompanySubmission,
  CorporationSubmission,
  GatewaySubmission,
  MachineSubmission,
  ProjectSubmission,
  SensorNodesSubmission,
  SubmissionWithSId,
} from "./models";
import { SupportProvider } from "./SupportContext";
import { ThreeColumnLayout } from "./ThreeColumnLayout";
import {
  addUniqueIdsToSubmission,
  countElementsWithoutId,
  createSubmissionSupport,
  getFirstError,
  hasElementWithoutId,
  updatePartOfSubmission,
} from "./utils/submissionHelpers";
import { validateInput } from "./utils/validationUtils";

const panelClassNameGeneral: CSSProperties = {
  height: "calc(100% - 53px)",
};

const panelClassName: CSSProperties = {
  padding: 15,
  height: "100%",
  backgroundColor: "#f8f8f8",
  display: "flow",
};

const panelClassNameChat: CSSProperties = {
  padding: 5,
  height: "100%",
  backgroundColor: "#f8f8f8",
};
export interface ValidationResult {
  matchUniqueId: string;
  messages: string[];
}

export interface SubmissionToUpdateByParts {
  corpo: CorporationSubmission;
  comp?: CompanySubmission;
  proj?: ProjectSubmission;
  mach?: MachineSubmission;
  gat?: GatewaySubmission;
  sen?: SensorNodesSubmission;
}

export interface SecondPanelContext {
  context: "Corporation" | "Company" | "Project" | "Machine" | "Sensor Node" | "Gateway";
  updateFunction: (
    partToUpdate:
      | Partial<CorporationSubmission>
      | Partial<CompanySubmission>
      | Partial<ProjectSubmission>
      | Partial<GatewaySubmission>
      | Partial<MachineSubmission>
      | Partial<SensorNodesSubmission>,
    uniqueId: string,
  ) => void;
  initialValues: SubmissionToUpdateByParts;
  matchUniqueId?: string;
  index?: number;
}

interface SubmissionsDetailsProps {
  isNotLog?: boolean;
}

export const SubmissionsDetails: React.FC<SubmissionsDetailsProps> = ({ isNotLog }) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<SubmissionWithSId>();
  const [support, setSupport] = useState<SubmissionSupport>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [secondPanelContext, setSecondPanelContext] = useState<SecondPanelContext>();
  const gatsList = useAppSelector(selectWirelessGateways);
  const status = useAppSelector(selectWirelessGatewaysStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const sensors = useAppSelector(selectWirelessSensorNodes);
  const statusSensors = useAppSelector(selectWirelessSensorNodesStatus);
  const corpos = useAppSelector(selectCorporations);
  const corporationStatus = useAppSelector(selectCorporationsStatus);
  const companies = useAppSelector(selectCompanies);
  const statusCompanies = useAppSelector(selectCompaniesStatus);
  const [feedback, setFeedback] = useState<ValidationResult[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  // const { bigScreen } = useViewport();

  // Gets the machines and projects list.
  useEffect(() => {
    if (
      machineStatus !== Status.void &&
      projectStatus !== Status.void &&
      corporationStatus !== Status.void &&
      statusCompanies !== Status.void
    ) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
    projectStatus === Status.void && dispatch(listAsyncProjs());
    corporationStatus === Status.void && dispatch(listAsyncCorpo());
    statusCompanies === Status.void && dispatch(listAsyncCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStatus, projectStatus, corporationStatus, statusCompanies]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle) {
      dispatch(listAsyncGateways(projs));
    }
  }, [dispatch, projectStatus, status, projs]);

  useEffect(() => {
    if (status === Status.idle && machineStatus === Status.idle && statusSensors === Status.void) {
      dispatch(
        listAsyncSensorNodes({
          wireless: gatsList,
          machs: machines,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, machineStatus, status, statusSensors]);

  useEffect(() => {
    submissionsDetails(id).then((list) => {
      const corpoSubmission = addUniqueIdsToSubmission(list.submission);
      setItem({
        ...list,
        submission: corpoSubmission,
        messages: list.messages,
      });
    });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!statusSensors || statusSensors !== Status.idle) return;

    if (status === Status.idle && item?.submission && gatsList.length > 0) {
      const newSupport = createSubmissionSupport(item.submission, support, gatsList);
      setSupport(newSupport);
      setFeedback(
        validateInput(t, item.submission, corpos, companies, projs, machines, sensors, newSupport),
      );
    } else if (support && item?.submission) {
      setFeedback(
        validateInput(t, item.submission, corpos, companies, projs, machines, sensors, support),
      );
    }
  }, [
    status,
    statusSensors,
    item?.submission,
    gatsList,
    support,
    t,
    corpos,
    companies,
    projs,
    machines,
    sensors,
  ]);

  const goBack = () => navigate(-1);

  const updateRecall = (
    partToUpdate:
      | Partial<CorporationSubmission>
      | Partial<CompanySubmission>
      | Partial<ProjectSubmission>
      | Partial<GatewaySubmission>
      | Partial<MachineSubmission>
      | Partial<SensorNodesSubmission>,
    uniqueId: string,
  ): void => {
    setItem((ele) => {
      return {
        ...ele,
        submission: updatePartOfSubmission(ele.submission, uniqueId, partToUpdate),
      };
    });
  };

  const SubmitApproval = () => {
    setIsSubmitting(true);
    const toApprove: RequestApproveSubmissionAdd = {
      submissionToUpdate: item.submission,
      supportForSubmission: support,
    };

    approveSubmission(id, toApprove)
      .then((resp) => {
        if ("data" in resp) {
          notification.success(
            t("Submission {{id}} successfully performed.", {
              id: item.sID,
            }),
          );
          navigate("/submissions");
        } else {
          notification.error(t("Error: {{message}}", { message: resp.text }));
        }
        dispatch(listAsyncProjs());
      })
      .catch((error) => {
        notification.error(error.response.data);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleRemove = () => {
    const context = secondPanelContext.context;
    const matchUniqueId = secondPanelContext.matchUniqueId;

    setIsRemoving(true);
    setItem((prevItem) => {
      if (!prevItem) return prevItem;

      const updatedSubmission = { ...prevItem.submission };

      const removeItemByUniqueId = (submission) => {
        // For Corporation level
        if (context === "Corporation" && submission.uniqueId === matchUniqueId) {
          // Can't remove the entire corporation from the submission
          return null;
        }

        // For Company level
        if (context === "Company" && submission.companies) {
          submission.companies = submission.companies.filter(
            (company) => company.uniqueId !== matchUniqueId,
          );
        }

        // Recursively search and remove from companies
        if (submission.companies) {
          submission.companies = submission.companies
            .map((company) => {
              // For Project level
              if (context === "Project" && company.projects) {
                company.projects = company.projects.filter(
                  (project) => project.uniqueId !== matchUniqueId,
                );
              }

              // Recursively search and remove from projects
              if (company.projects) {
                company.projects = company.projects
                  .map((project) => {
                    // For Gateway level
                    if (context === "Gateway" && project.gateways) {
                      project.gateways = project.gateways.filter(
                        (gateway) => gateway.uniqueId !== matchUniqueId,
                      );
                    }

                    // For Machine level
                    if (context === "Machine" && project.machines) {
                      project.machines = project.machines.filter(
                        (machine) => machine.uniqueId !== matchUniqueId,
                      );
                    }

                    // Recursively search and remove from machines
                    if (project.machines) {
                      project.machines = project.machines
                        .map((machine) => {
                          // For Sensor Node level
                          if (context === "Sensor Node" && machine.sensors) {
                            machine.sensors = machine.sensors.filter(
                              (sensor) => sensor.uniqueId !== matchUniqueId,
                            );
                          }

                          // Check if machine has all required IDs
                          if (hasElementWithoutId(machine)) {
                            return machine;
                          }
                          return null;
                        })
                        .filter(Boolean);
                    }

                    // Check if project has all required IDs
                    if (hasElementWithoutId(project)) {
                      return project;
                    }
                    return null;
                  })
                  .filter(Boolean);
              }

              if (hasElementWithoutId(company)) {
                return company;
              }

              return null;
            })
            .filter(Boolean);
        }

        return submission;
      };

      const result = removeItemByUniqueId(updatedSubmission);

      if (result === null) return prevItem;
      // After removing, reset the second panel context
      setSecondPanelContext(undefined);

      return {
        ...prevItem,
        submission: result,
      };
    });

    setIsRemoving(false);
    setIsDialogVisible(false);
  };

  return (
    <>
      {item && (
        <>
          <div style={panelClassNameGeneral}>
            <Header
              goBack={goBack}
              item={item}
              isNotLog={isNotLog}
              rejectSubmission={rejectSubmission}
              id={id}
              feedback={feedback?.at(0)}
              SubmitApproval={SubmitApproval}
              navigate={navigate}
              isSubmitting={isSubmitting}
            />
            <ThreeColumnLayout
              leftChildren={
                <div style={panelClassName}>
                  <ChevronItemWithChildren
                    key={item?.submission?.name + "chevron"}
                    name={item?.submission?.name}
                    parentIconName='Corporations'
                    chevron={
                      item?.submission?.companies && item?.submission?.companies?.length > 0
                        ? true
                        : false
                    }
                    error={getFirstError(feedback, item.submission.uniqueId)}
                    onClick={() =>
                      setSecondPanelContext({
                        context: "Corporation",
                        updateFunction: updateRecall,
                        initialValues: { corpo: item.submission },
                        matchUniqueId: undefined,
                      })
                    }
                  >
                    {item.submission.companies && item.submission.companies.length > 0 && (
                      <>
                        {item.submission.companies.map((ele, compIndex) => (
                          <ChevronItemWithChildren
                            key={ele.name + "chevron"}
                            name={ele.name}
                            parentIconName='Companies'
                            chevron={ele.projects && ele.projects.length > 0}
                            error={getFirstError(feedback, ele.uniqueId)}
                            onClick={() =>
                              setSecondPanelContext({
                                context: "Company",
                                updateFunction: updateRecall,
                                initialValues: {
                                  corpo: item.submission,
                                  comp: ele,
                                },
                                index: compIndex,
                                matchUniqueId: ele.id ? undefined : ele.uniqueId,
                              })
                            }
                          >
                            {ele.projects && ele.projects.length > 0 && (
                              <>
                                {ele.projects.map((pro, childIndex) => (
                                  <ChevronItemWithChildren
                                    key={pro.name + "chevron"}
                                    name={pro.name}
                                    parentIconName='Projects'
                                    chevron={
                                      (pro.gateways && pro.gateways.length > 0) ||
                                      (pro.machines && pro.machines.length > 0)
                                    }
                                    error={getFirstError(feedback, pro.uniqueId)}
                                    onClick={() =>
                                      setSecondPanelContext({
                                        context: "Project",
                                        updateFunction: updateRecall,
                                        initialValues: {
                                          corpo: item.submission,
                                          comp: ele,
                                          proj: pro,
                                        },
                                        index: childIndex,
                                        matchUniqueId: pro.id ? undefined : pro.uniqueId,
                                      })
                                    }
                                  >
                                    {
                                      <>
                                        {pro.machines && pro.machines.length > 0 && (
                                          <>
                                            {pro.machines.map((mach, machineIndex) => (
                                              <ChevronItemWithChildren
                                                key={mach.name + "chevron"}
                                                name={mach.name}
                                                parentIconName='Machines'
                                                chevron={mach.sensors && mach.sensors.length > 0}
                                                error={getFirstError(feedback, mach.uniqueId)}
                                                onClick={() => {
                                                  setSecondPanelContext({
                                                    context: "Machine",
                                                    updateFunction: updateRecall,
                                                    initialValues: {
                                                      corpo: item.submission,
                                                      comp: ele,
                                                      proj: pro,
                                                      mach: mach,
                                                    },
                                                    index: machineIndex,
                                                    matchUniqueId: mach.id
                                                      ? undefined
                                                      : mach.uniqueId,
                                                  });
                                                }}
                                              >
                                                {mach.sensors && mach.sensors.length > 0 && (
                                                  <>
                                                    {mach.sensors.map((sen, itemIndex) => (
                                                      <ChevronItemWithChildren
                                                        key={sen.sensorSerialNumber + "chevron"}
                                                        name={sen.sensorSerialNumber}
                                                        parentIconName='SensorsNodes'
                                                        chevron={false}
                                                        error={getFirstError(
                                                          feedback,
                                                          sen.uniqueId,
                                                        )}
                                                        onClick={() => {
                                                          setSecondPanelContext({
                                                            context: "Sensor Node",
                                                            updateFunction: updateRecall,
                                                            initialValues: {
                                                              corpo: item.submission,
                                                              comp: ele,
                                                              proj: pro,
                                                              mach: mach,
                                                              sen: sen,
                                                            },
                                                            index: itemIndex,
                                                            matchUniqueId: sen.uniqueId,
                                                          });
                                                        }}
                                                      />
                                                    ))}
                                                  </>
                                                )}
                                              </ChevronItemWithChildren>
                                            ))}
                                          </>
                                        )}

                                        {pro.gateways && pro.gateways.length > 0 && (
                                          <>
                                            {pro.gateways.map((gat, gatIndex) => (
                                              <ChevronItemWithChildren
                                                key={gat.serialNumber + "chevron"}
                                                name={gat.serialNumber}
                                                parentIconName='Gateways'
                                                chevron={false}
                                                error={getFirstError(feedback, gat.uniqueId)}
                                                onClick={() => {
                                                  setSupport((prevSupport) => ({
                                                    ...prevSupport,
                                                    linkedGatewayUniqueId: gat.uniqueId,
                                                  }));
                                                  setSecondPanelContext({
                                                    context: "Gateway",
                                                    updateFunction: updateRecall,
                                                    initialValues: {
                                                      corpo: item.submission,
                                                      comp: ele,
                                                      proj: pro,
                                                      gat: gat,
                                                    },
                                                    matchUniqueId: gat.uniqueId,
                                                    index: gatIndex,
                                                  });
                                                }}
                                              />
                                            ))}
                                          </>
                                        )}
                                      </>
                                    }
                                  </ChevronItemWithChildren>
                                ))}
                              </>
                            )}
                          </ChevronItemWithChildren>
                        ))}
                      </>
                    )}
                  </ChevronItemWithChildren>
                </div>
              }
              centerChildren={
                <div style={panelClassName}>
                  <Stack
                    horizontal
                    verticalAlign='center'
                    horizontalAlign='space-between'
                    style={{ gap: 10 }}
                  >
                    <Text wrap={false} size={400}>
                      {secondPanelContext?.context
                        ? t(secondPanelContext.context)
                        : t("Select an item")}
                    </Text>

                    {secondPanelContext?.context &&
                      secondPanelContext?.matchUniqueId &&
                      countElementsWithoutId(item.submission) > 1 && (
                        <Button style={{ marginLeft: 10 }} onClick={() => setIsDialogVisible(true)}>
                          {t("Remove")}
                        </Button>
                      )}
                  </Stack>

                  <Stack>
                    {secondPanelContext && (
                      <SupportProvider support={support} setSupport={setSupport}>
                        <DetailsPanel
                          key={secondPanelContext.context + secondPanelContext.index}
                          sensorNodes={sensors}
                          context={secondPanelContext}
                        />
                      </SupportProvider>
                    )}
                  </Stack>
                </div>
              }
              rightChildren={
                <div style={panelClassNameChat}>
                  <ChatMessageDisplay
                    messages={item.messages}
                    creatorName={item.creatorName}
                    onSent={(mess) =>
                      sendSubmissionsComment(id, mess).then((resp) =>
                        !("data" in resp)
                          ? notification.error(t("Some issue sending the submission comment"))
                          : submissionsDetails(id).then((list) => {
                              const corpoSubmission = addUniqueIdsToSubmission(list.submission);
                              setItem({
                                ...list,
                                submission: corpoSubmission,
                                messages: list.messages,
                              });
                            }),
                      )
                    }
                  />
                </div>
              }
            />
            {isDialogVisible && (
              <ConfirmDialog
                title={t("Confirm Removal")}
                message={t("Are you sure you want to remove this item?")}
                isLoading={isRemoving}
                onConfirm={handleRemove}
                onDismiss={() => setIsDialogVisible(false)}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
