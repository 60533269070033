import { Spinner } from "@fluentui/react-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { renderFormFieldArrayItems } from "../../../../common/Form/FormItems/helpers";
import { useMeasurementSettings } from "../../../hooks/useSensorRequests";
import {
  measurementAxesFormField,
  measurementRangeFormFields,
  measurementSampleFormField,
  measurementSelectedCalculationsFormField,
} from "./config";

const SettingsContent = ({ form, index, settingName, sensorNodeId, handleCollapseToggle }: any) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    setValue,
  } = form;

  const { data, isLoading } = useMeasurementSettings(sensorNodeId, settingName);

  useEffect(() => {
    handleCollapseToggle(settingName, true);
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setValue(`measurement.${index}.gRange`, data.gRange);
      setValue(`measurement.${index}.dataAxis`, data.dataAxis);
      setValue(`measurement.${index}.dataSampleCount`, data.dataSampleCount);
      setValue(`measurement.${index}.selectedCalculations`, data.selectedCalculations);
    }
  }, [data, isLoading]);

  if (isLoading) return <Spinner size='tiny' />;

  return (
    <div className='settings-collapse-wrapper'>
      <div className='settings-input-container'>
        <div className='settings-title-container'>
          <span className='settings-title'>{t("Range")}</span>
        </div>
        <div className='settings-checkbox-container'>
          {renderFormFieldArrayItems(
            measurementRangeFormFields,
            { control, errors },
            "measurement",
            index,
          )}
        </div>
      </div>
      <div className='settings-input-container'>
        <div className='settings-title-container'>
          <span className='settings-title'>{t("Axes")}</span>
        </div>
        <div className='settings-checkbox-container'>
          {renderFormFieldArrayItems(
            measurementAxesFormField,
            { control, errors },
            "measurement",
            index,
          )}
        </div>
      </div>
      <div className='settings-input-container'>
        <div className='settings-title-container'>
          <span className='settings-title'>{t("Calculations")}</span>
        </div>
        <div className='settings-title-container'>
          {renderFormFieldArrayItems(
            measurementSelectedCalculationsFormField,
            { control, errors },
            "measurement",
            index,
          )}
        </div>
      </div>
      <div className='settings-input-container'>
        <div className='settings-title-container'>
          <span className='settings-title'>{t("Number of samples")}</span>
        </div>
        <div className='settings-title-container'>
          {renderFormFieldArrayItems(
            measurementSampleFormField,
            { control, errors },
            "measurement",
            index,
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
