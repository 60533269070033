import { Dialog, DialogBody, DialogSurface, Label, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { Stack } from "../../../Stack";

/**
 * Gets the loading Dialog component.
 * @returns The loading dialog component.
 */
const LoadingPanel = () => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <DialogSurface style={{ width: "170px" }}>
        <DialogBody style={{ display: "flex" }}>
          <Stack
            horizontalAlign='center'
            verticalAlign='center'
            role='loading panel'
            style={{
              gap: 5,
              padding: 5,
              height: "5em",
              marginTop: "auto",
              marginBottom: "auto",
              width: "100%",
            }}
          >
            <Stack.Item>
              <Spinner size='small' />
            </Stack.Item>
            <Stack.Item>
              <Label>{t("Loading data...")}</Label>
            </Stack.Item>
          </Stack>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default LoadingPanel;
