import type { TextareaProps } from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import { Controller } from "react-hook-form";

import TextArea from "../../TextArea";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormTextAreaProps = BaseFormFieldProps &
  TextareaProps & {
    name: string;
    fieldProps?: any;
  };

export const FormTextArea = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  fieldProps,
  ...rest
}: FormTextAreaProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Field
            style={{ width: "100%" }}
            validationMessage={errors?.[name]?.message}
            {...fieldProps}
          >
            <TextArea
              value={value || ""}
              style={{ width: "100%" }}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          </Field>
        );
      }}
    />
  </FormGroup>
);
