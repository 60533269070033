import { SearchBox } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { CommandBar } from "./CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "./methods";

export type BaseCommandBarProps = {
  onSearch?: (value: string) => void;
  items: any[];
  farItems?: any[];
  wrapperStyle?: CSSProperties;
};

const BaseCommandBar = ({ onSearch, items, farItems: _farItems, ...rest }: BaseCommandBarProps) => {
  const { t } = useTranslation();
  const innerFarItems = [
    ...(_farItems ?? []),
    ...(onSearch
      ? [
          {
            key: "search",
            onRender: () => (
              <SearchBox
                placeholder={t("Search")}
                style={{ width: 300 }}
                onChange={(_, { value: newValue }) => onSearch(newValue ?? "")}
              />
            ),
          },
        ]
      : []),
  ].filter(Boolean);

  return <CommandBar items={items} innerFarItems={innerFarItems} {...rest} />;
};

export default BaseCommandBar;

export { CommandBarItemType, computeCommandBarItems };
