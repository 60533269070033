import "./style.scss";

import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTrendMeasuredDataInAPeriod } from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import { XYChart } from "../../../../common/XYChart";
import useSummaryStore from "../../hooks/useSummaryStore";
import { getOperationView } from "./config";
import { initializeOperationChart, prepareDataForChart, prepareRequestPeriod } from "./methods";

export const OperationsTab = () => {
  const { t } = useTranslation();
  const chartRef: any = useRef({});
  const [chartInstance, setChartInstance]: any = useState({});

  const { operations } = useSummaryStore((state) => ({
    operations: state.operations,
  }));

  const { signal: operationSignal = {}, loadingSignals, year, month, view } = operations;

  const { startDate, endDate }: any = prepareRequestPeriod(t, view, year, month);
  const {
    isLoading,
    data = [],
    refetch,
  } = useTrendMeasuredDataInAPeriod(
    {
      startDate,
      endDate,
      machineId: operationSignal.machineId,
      signalId: operationSignal.id,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: operationSignal?.dataType,
      onlyZip: true,
      refreshInterval: { key: "off", text: t("Off") },
    },
    {
      enabled: false,
    },
  );

  const noData =
    !loadingSignals &&
    (!isLoading || (isLoading && isEmpty(operationSignal))) &&
    (isEmpty(operationSignal) || (!isLoading && data?.length === 0));

  const isDataLoading = loadingSignals || (isLoading && !isEmpty(operationSignal));
  const chartHidden = loadingSignals || isLoading || data?.length === 0;

  useEffect(() => {
    if (year && month && !isEmpty(operationSignal)) {
      refetch();
    }
  }, [year, month, view, operationSignal]);

  // Initialize the chart
  useEffect(() => {
    const { root, chart } = chartInstance;
    if (!chart || !root || (chart && !isEmpty(chartRef.current))) return;
    const { series, xAxis, yAxis, yAxisLabel } = initializeOperationChart({
      chart,
      root,
      view,
    });
    chartRef.current = { series, xAxis, yAxis, yAxisLabel };
  }, [chartInstance]);

  // Update the chart
  useEffect(() => {
    const { chart } = chartInstance;
    if (!chart || !data.length) return;

    const { series, xAxis, yAxisLabel } = chartRef.current;

    const preparedData = prepareDataForChart({ t, data, view, month, year });

    yAxisLabel.set(
      "text",
      view.key === getOperationView(t)[0].key ? t("Hours / Month") : t("Hours / Day"),
    );

    xAxis.data.setAll(preparedData);
    series.data.setAll(preparedData);
    series.appear(500);
  }, [chartInstance, data.length, t]);

  const chartStyles: any = {
    root: {
      display: !noData && !isDataLoading ? "block" : "none",
    },
    chart: {
      height: "100%",
      width: "100%",
    },
  };

  return (
    <div
      className={classNames("operations-tab-content", {
        "chart-hidden": chartHidden,
      })}
    >
      {noData && <NoData style={{ height: "100%" }} />}
      {isDataLoading && <Spin style={{ height: "100%" }} />}

      <XYChart
        className='operation-tab-chart'
        exportSelector='operations-export'
        styles={chartStyles}
        customSettings={{
          onReady: (instance: any) => setChartInstance(instance),
        }}
      />
    </div>
  );
};
