import { Text } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import type { Report } from "../../../../../types";
import { MarkdownText } from "../../../../common/MarkdownEditor";
import { File } from "../../../../common/Preview";
import type { UploadedFile } from "../../../../common/Uploader/types";
import { Stack } from "../../../../Stack";

type ReportTextProps = {
  report: Report;
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
  marginTop: 10,
  marginBottom: 3,
};

const ReportText = ({ report }: ReportTextProps) => {
  const { t } = useTranslation();
  return (
    <Stack style={{ position: "relative", gap: 10 }}>
      <MarkdownText text={report.text} />
      {report.files && report.files.length > 0 && (
        <Stack style={{ gap: 5 }}>
          <Text size={400} as='h5' style={titleStyle}>
            {t("Attachments")}
          </Text>
          <div className='files-list'>
            {report.files.map((value: UploadedFile, index: number) => (
              <File key={index} file={value} />
            ))}
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default ReportText;
