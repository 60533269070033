import "./style.scss";

import { Spinner } from "@fluentui/react-components";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { format as fnsFormat } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { prefetchTrendMeasuredDataInAPeriod, useImage } from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import getPeriodFilters, {
  SHORT_FORMAT,
} from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import type { DataPoint } from "../../../../../types";
import { NoData } from "../../../../common/NoData";
import type { StackProps } from "../../../../Stack";
import { Stack } from "../../../../Stack";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import type { DriveLoadSignalsMetaData } from "../../methods";
import ColoredChartHeader from "./Header";
import { prepareColorDataChartBySelectedView } from "./methods";
import ScatterChart from "./ScatterChart";
import backgroundImage from "./ScatterChart/colored-chart-background.png";

type ColoredChartProps = StackProps & {
  driveLoadSignalsMetaData: DriveLoadSignalsMetaData;
};

const ColoredChart = ({ driveLoadSignalsMetaData, ...rest }: ColoredChartProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isFetchingSignalsData = useIsFetching({
    queryKey: ["trend-measured-data-in-a-period"],
  });
  const {
    showDates,
    driveLoad = {},
    period,
    colorChartView,
    torqueChartDates,
  } = useDriveLoadStore((store: any) => ({
    showDates: store.showDates,
    driveLoad: store.driveLoad,
    period: store.period,
    colorChartView: store.colorChartView,
    torqueChartDates: store.torqueChartDates,
  }));

  const { isLoading: isImageLoading, url } = useImage(driveLoad?.backgroundFileId);

  const isContentLoading = isFetchingSignalsData || isImageLoading;
  const chartBackground = useMemo(() => {
    if (isImageLoading) {
      return null;
    }

    if (!isImageLoading && url) {
      return url;
    }

    return backgroundImage;
  }, [isImageLoading, url]);

  const data: any[] = useMemo(() => {
    if (isContentLoading) return [];

    const signalsPlots: DataPoint[][] = [];
    const { averageSignal, dynamicSignal } = driveLoadSignalsMetaData;
    const signals = [averageSignal, dynamicSignal];

    for (const signal of signals) {
      const periodFilters = getPeriodFilters(period, signal);
      const signalPlot = queryClient.getQueryData([
        "trend-measured-data-in-a-period",
        signal.machineId,
        signal.id,
        periodFilters.startDate,
        periodFilters.endDate,
        CHART_NUMBER_OF_POINTS.toString(),
        "off",
      ]) as DataPoint[];
      signalsPlots.push(signalPlot || []);
    }

    const result: any = [];

    if (signalsPlots.length > 0) {
      for (let dataPointIndex = 0; dataPointIndex < signalsPlots[0].length; dataPointIndex++) {
        const averagePoint = signalsPlots[0][dataPointIndex];
        const dynamicPoint = signalsPlots[1][dataPointIndex];
        if (!averagePoint?.value || !averagePoint?.timeStamp || !dynamicPoint?.value) {
          continue;
        }

        result[dataPointIndex] = {
          x: averagePoint.value,
          y: dynamicPoint.value,
          date: fnsFormat(new Date(averagePoint.timeStamp), SHORT_FORMAT),
        };
      }
    }
    const toReturn = prepareColorDataChartBySelectedView({
      t,
      data: result,
      selectedView: colorChartView,
    });

    return toReturn;
  }, [isContentLoading, driveLoadSignalsMetaData, t, colorChartView, period, queryClient]);

  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    const { averageSignal, dynamicSignal } = driveLoadSignalsMetaData;
    const signals = [averageSignal, dynamicSignal];

    for (const signal of signals) {
      const periodFilters = getPeriodFilters(period, signal);
      prefetchTrendMeasuredDataInAPeriod({
        onlyZip: true,
        machineId: signal.machineId!,
        signalId: signal.id!,
        startDate: periodFilters.startDate,
        endDate: periodFilters.endDate,
        numberOfPoints: CHART_NUMBER_OF_POINTS,
        signalType: signal.dataType,
        refreshInterval: { key: "off", text: t("Off") },
      });
    }
  }, [driveLoadSignalsMetaData, period.key, period.startDate, period.endDate, t, period]);

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    if (!torqueChartDates.start || !torqueChartDates.end) {
      setFilteredData(data);
      return;
    }

    const result = data.filter((d) => {
      const dateValue = new Date(d.date);
      const startDate = new Date(torqueChartDates.start);
      const endDate = new Date(torqueChartDates.end);

      let isInRange = false;

      if (colorChartView.key === "Yearly") {
        isInRange =
          dateValue.getFullYear() >= startDate.getFullYear() &&
          dateValue.getFullYear() <= endDate.getFullYear();
      } else if (colorChartView.key === "Monthly") {
        isInRange =
          dateValue.getFullYear() >= startDate.getFullYear() &&
          dateValue.getFullYear() <= endDate.getFullYear() &&
          dateValue.getMonth() >= startDate.getMonth() &&
          dateValue.getMonth() <= endDate.getMonth();
      } else {
        isInRange = dateValue >= startDate && dateValue <= endDate;
      }

      return isInRange;
    });
    setFilteredData(result);
  }, [torqueChartDates, data, colorChartView.key]);

  const noData = !isContentLoading && (!driveLoadSignalsMetaData || data.length === 0);

  return (
    <Stack {...rest} verticalFill horizontalAlign='stretch' style={{ gap: 5 }}>
      <ColoredChartHeader />
      <div
        className={classNames("torque-drive-load colored-chart", {
          loading: !!isContentLoading,
          "no-data": noData,
        })}
      >
        <div className='white-container'>
          <div className='loading-no-data-chart-container h-100'>
            {noData && <NoData className='no-data' />}
            {isContentLoading ? (
              <Spinner className='loading-chart-data' size='small' label={t("Loading data...")} />
            ) : (
              <ScatterChart
                data={filteredData}
                showDates={showDates}
                settings={{ ...driveLoad, background: chartBackground }}
              />
            )}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default ColoredChart;
