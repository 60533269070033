import { Button, Text, Tooltip } from "@fluentui/react-components";
import { Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { BackIcon } from "@fluentui/react-icons-mdl2";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../../schema/Constants";
import { Status } from "../../../schema/status";
import Breadcrumb from "../../common/Breadcrumb";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import type { Column } from "../../common/Table/v9";
import { useTableFilters } from "../../common/Table/v9";
import Table from "../../common/Table/v9/Table";
import { listAsyncCompaDetails } from "../../Companies/CompaniesDetails/reducer";
import type { Company } from "../../Companies/models";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { Stack } from "../../Stack";
import { AddOrEditDialog } from "./AddOrEditDialog";
import { DeleteConfirm } from "./DeleteConfirm";
import {
  listAsyncCorpoDetails,
  selectCorpDetails,
  selectCorporationDetailsError,
  selectCorporationDetailsStatus,
} from "./reducer";
// --- Styles ---

// --- Columns ---

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (company: Company) => void;
  onDelete: (company: Company) => void;
};

const getColumns = ({ t, hasActions, onEdit, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Company) => (
        <Link to={"../companies/" + id?.toString()} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "number",
      name: t("Number"),
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (company: Company) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(company);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(company);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

export const CorporationDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const error = useAppSelector(selectCorporationDetailsError);
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCorpDetails);
  const status = useAppSelector(selectCorporationDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const { filters, handleSearch } = useTableFilters<Company>({
    keys: ["name", "number"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    //if (status === Status.void)
    dispatch(listAsyncCorpoDetails(id ? id : ""));
  }, [dispatch, id]);

  const [selected, setSelected] = useState<{
    data: Company | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onEdit = (company: Company) => setSelected({ data: company, context: "edit" });

  const onDelete = (company: Company) => setSelected({ data: company, context: "delete" });

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.name}</Text>,
    },
  ];

  const goBack = () => navigate(-1);
  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign='center'>
            <Button appearance='transparent' icon={<BackIcon />} onClick={goBack} />
            <Breadcrumb items={[{ key: `corporation-${items.number}`, text: items.name }]} />
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
            />

            <Table
              persistOpts={{
                key: "table-companies",
                version: 2,
              }}
              header={{
                title: t("Companies"),
              }}
              items={items.companies}
              v8Columns={getColumns({
                t,
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            <DeleteConfirm
              data={selected?.data}
              show={selected?.context === "delete"}
              onSuccess={(hasError) => {
                if (hasError) {
                  notification.error(
                    t(`Failed deleting {{companyName}} company`, {
                      companyName: selected?.data?.name,
                    }),
                  );
                } else {
                  dispatch(listAsyncCorpoDetails(id ? id : ""));
                  notification.success(
                    t(`{{companyName}} company deleted successfully`, {
                      companyName: selected?.data?.name,
                    }),
                  );
                }

                setSelected(null);
              }}
              onClose={() => setSelected(null)}
            />
            <AddOrEditDialog
              data={selected?.data}
              items={items.companies}
              show={["add", "edit"].includes(selected?.context)}
              onSuccess={(hasError, data, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating {{entity}}`, {
                          entity: data.name,
                        })
                      : t(`Failed updating {{entity}}`, {
                          entity: data.name,
                        });

                  notification.error(message);
                } else {
                  dispatch(listAsyncCompaDetails(id));

                  const message =
                    context === "add"
                      ? t(`{{entity}} created successfully`, {
                          entity: data.name,
                        })
                      : t(`{{entity}} updated successfully`, {
                          entity: data.name,
                        });

                  notification.success(message);
                }
              }}
              onClose={() => {
                setSelected(null);
              }}
            />
          </div>
        </>
      ) : (
        <NotFoundRoute />
      )}
    </>
  );
};
