import type { TFunction } from "i18next";
import { isEmpty } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, valueIncludes } from "../../../../../Hooks";
import type { Report } from "../../../../../types";
import { format } from "../../../../../utils";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import type { Column, TableProps } from "../../../../common/Table/v9";
import Table from "../../../../common/Table/v9";
import { useReportsQuery } from "../../../MachineCVReportsPage/hooks/useReportsQuery";
import AlertText from "./AlertText";

const KEYS = ["text", "createdAt", "createdBy"];

export const AlertsTab = ({ search }: { search: string }) => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const { reports, isError, isLoading } = useReportsQuery({
    machineId: id as string,
  });
  const filteredData = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();
    return reports?.filter(
      (item: any) =>
        item.category === "Alert" &&
        KEYS.reduce((valid, key) => valid || valueIncludes(item[key], normalizedSearch), false),
    );
  }, [search, reports]);

  const getColumns = (t: TFunction<"translation", undefined>): Column[] => [
    {
      key: "text",
      fieldName: "text",
      name: t("Text"),
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 300,
      isSortable: true,
      isResizable: true,
      onRender: (item: Report) => <AlertText report={item} />,
    },
    {
      key: "createdAt",
      fieldName: "createdAt",
      name: t("Created At"),
      minWidth: 100,
      isSortable: true,
      isResizable: false,
      onRender: (item) => format(new Date(item.createdAt)),
    },
    {
      key: "createdBy",
      fieldName: "createdBy",
      name: t("Created By"),
      minWidth: 150,
      isSortable: true,
      isResizable: false,
      isExportable: false,
    },
  ];

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: "table-summary-reports",
        version: 2,
      },
      items: filteredData,
      perPage: 99999,
      hidePerPage: true,
      isLoading: isLoading,
      isError,
      v8Columns: getColumns(t),
    }),
    [isLoading, isError, filteredData, t],
  );

  return (
    <>
      {isLoading && <Spin style={{ height: "100%" }} />}
      {!isLoading && isEmpty(reports) && <NoData style={{ height: "100%" }} />}
      {!isEmpty(reports) && <Table {...tableProps} />}
    </>
  );
};
