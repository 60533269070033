import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

function getStartEnd({ factor = 0, offset = 0 }: { factor: number; offset: number }): {
  start: number;
  end: number;
} {
  const P = 0.005 * offset + 0.5;
  let V,
    D,
    I = -1;
  if (factor >= -100 && factor <= 0) {
    V = 0.02 * factor + 2;
    D = -0.02 * factor + 1;
  } else {
    I = 0.01 * factor - 1;
    V = -0.011 * factor + 2;
    D = -0.009 * factor + 1;
  }
  const start = I + P * V;
  const end = start + D;
  return { start, end };
}

export function setAxesFactorAndOffset({
  chartAxes,
  factor,
  offset,
}: {
  chartAxes: any;
  factor: number;
  offset: number;
}) {
  const range = getStartEnd({ factor, offset });
  if (range === undefined) return;
  const { start, end } = range;

  if (Array.isArray(chartAxes)) {
    chartAxes?.forEach((axis: any) => {
      axis.zoom(start, end);
    });
    return;
  }

  chartAxes.zoom(start, end);
}

type IncrementDecrementArgs = {
  setting: string;
  changeValue: (newValue: number) => void;
} & { max: number } & { min: number };

type LayoutSettingsTypes = {
  yAxes: any[];
  seriesNames: any[];
  yAxesSettings: any;
  step: number;
  isChartLayoutOpen: boolean;
  getSelectedAxisStartEnd: (index?: number) => any;
  updateStore: (updatedStoreProperties: any) => void;
  updateYAxisSliderSetting: (value: number, setting: string) => void;
  increment: (value: IncrementDecrementArgs) => void;
  decrement: (value: IncrementDecrementArgs) => void;
};

const useLayoutSettingsStore = createWithEqualityFn<LayoutSettingsTypes>(
  (set, get: any) => ({
    yAxes: [],
    seriesNames: [],
    isChartLayoutOpen: false,
    selectedYAxisIndex: 0,
    yAxesSettings: {},
    syncSettingsMode: "normal",
    step: 1,
    getSelectedAxisStartEnd(index) {
      const syncSettingsMode = get().syncSettingsMode;
      const axisIndex = syncSettingsMode === "syncAll" ? get()?.selectedYAxisIndex : index;
      const axisOffsetFactor = get()?.yAxesSettings?.[axisIndex];

      if (!axisOffsetFactor) {
        return {
          start: 0,
          end: 1,
        };
      }

      return getStartEnd(axisOffsetFactor);
    },
    updateStore: (updatedStoreProperties: any = {}) =>
      set((store) => {
        return {
          ...store,
          ...updatedStoreProperties,
        };
      }),

    updateYAxisSliderSetting: (value, setting) => {
      const yAxisIndex = get()?.selectedYAxisIndex;
      const yAxes = get()?.yAxes;
      const syncSettingsMode = get()?.syncSettingsMode;
      const settings = get().yAxesSettings?.[yAxisIndex];

      const chartAxes = syncSettingsMode === "syncAll" ? yAxes : yAxes?.[yAxisIndex];

      setAxesFactorAndOffset({ chartAxes, ...settings, [setting]: value });

      set((store) => {
        return {
          ...store,
          yAxesSettings: {
            ...store.yAxesSettings,
            [yAxisIndex]: {
              ...store.yAxesSettings[yAxisIndex],
              [setting]: value,
            },
          },
        };
      });
    },
    resetSeries() {
      return null;
    },
    resetSettings(resetType = "selected") {
      const yAxes = get()?.yAxes;

      const factor = 0;
      const offset = 0;

      if (resetType === "all") {
        yAxes?.forEach((yAxis: any, index: number) => {
          setAxesFactorAndOffset({ chartAxes: yAxis, factor, offset });
          set((store) => {
            return {
              ...store,
              step: 1,
              yAxesSettings: {
                ...store.yAxesSettings,
                [index]: {
                  factor,
                  offset,
                },
              },
            };
          });
        });
        return;
      }

      const syncSettingsMode = get().syncSettingsMode;
      const yAxisIndex = get()?.selectedYAxisIndex;
      const chartAxes = syncSettingsMode === "syncAll" ? yAxes : yAxes?.[yAxisIndex];

      setAxesFactorAndOffset({ chartAxes, factor, offset });

      set((store) => {
        return {
          ...store,
          step: 1,
          yAxesSettings: {
            ...store.yAxesSettings,
            [yAxisIndex]: {
              factor,
              offset,
            },
          },
        };
      });
    },
    increment({ setting, changeValue, max }: IncrementDecrementArgs) {
      const step = get().step;
      const yAxisIndex = get()?.selectedYAxisIndex;
      const selectedAxis = get()?.yAxesSettings?.[yAxisIndex];

      const selectedAxisSetting = selectedAxis?.[setting];

      const newValue = selectedAxisSetting + step;

      if (newValue > max) {
        return;
      }

      changeValue(newValue);
    },
    decrement({ setting, changeValue, min }: IncrementDecrementArgs) {
      const step = get().step;
      const yAxisIndex = get()?.selectedYAxisIndex;
      const selectedAxis = get()?.yAxesSettings?.[yAxisIndex];

      const selectedAxisSetting = selectedAxis?.[setting];

      const newValue = selectedAxisSetting - step;

      if (newValue < min) {
        return;
      }

      changeValue(newValue);
    },
  }),
  shallow,
);

export default useLayoutSettingsStore;
