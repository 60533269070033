/* eslint-disable react-hooks/exhaustive-deps */

import {
  type SelectTabData,
  type SelectTabEvent,
  type TabValue,
  Button,
  Link,
  MessageBar,
  MessageBarBody,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { MailRegular } from "@fluentui/react-icons";
import { useEffect, useReducer, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import TeamsLogo from "../../assets/svg/TeamsLogo";
import { useAppSelector } from "../../Hooks";
import { notification } from "../common/Notification";
import { withLoadingPanelHOC } from "../Generic/HOCs";
import { Stack } from "../Stack";
import { selectUserAccountMemberID } from "../UserAccount/selectors";
import { UserNotificationsAPI } from "./api";
import { paddingStyle, titleStyle } from "./globalStyles";
import type {
  UserNotificationSelectedSettings,
  UserNotificationSettings,
  UserNotificationSettingsCorporation,
} from "./models";
import { EntitiesTree, TreeNode, UserNotificationSettingsType } from "./models";
import UserNotificationDialogTab from "./UserNotificationDialogTab";
import UserNotificationsConfirmDialog from "./UserNotificationsConfirmDialog";
import { getUserNotificationSettingsTypeKey } from "./utils";

export enum EntitiesTreeAction {
  InitializeTree,
  InitializeTreeValues,
  UpdateKey,
  UpdateTree,
}

export type UserNotificationState = {
  key: string;
  tree: EntitiesTree;
  dataIsReady: boolean;
};

type ReducerActionProps = {
  action: EntitiesTreeAction;
  initialSelected?: UserNotificationSelectedSettings;
  selectedKey?: string;
  selectedNode?: TreeNode<boolean>;
  selectedTree?: EntitiesTree;
};

// The URL to download the DDP Teams App.
const ddpTeamsAppUrl = "https://github.com/DALOG-Diagnosesysteme-GmbH/teams-notifications";

/**
 * Gets the entities tree.
 * @param entities The corporation entities list.
 * @returns The entities tree.
 */
const getEntitiesTree = (entities: UserNotificationSettingsCorporation[]): EntitiesTree => {
  const result = new EntitiesTree();
  for (const corporation of entities) {
    // Corporations
    const corporationNode = new TreeNode<boolean>(corporation.id, corporation.name, false);

    // Companies
    for (const company of corporation?.companies) {
      const companyNode = new TreeNode<boolean>(company.id, company.name, false);

      // Projects
      for (const project of company?.projects) {
        const projectNode = new TreeNode<boolean>(project.id, project.name, false);

        // Machines
        for (const machine of project?.machines) {
          const machineNode = new TreeNode<boolean>(machine.id, machine.name, false);

          projectNode.addNode(machineNode);
        }

        companyNode.addNode(projectNode);
      }

      corporationNode.addNode(companyNode);
    }

    result.corporations.set(corporationNode.id, corporationNode);
  }

  return result;
};

/**
 * Entities tree reducer function.
 * @param state The current state.
 * @param props The entities reducer props.
 * @returns The new state.
 */
const reducer = (state: UserNotificationState, props: ReducerActionProps) => {
  // Initialize tree.
  if (props.selectedTree && props.action === EntitiesTreeAction.InitializeTree) {
    return { ...state, key: state.key, tree: props.selectedTree };
  }

  // Initializes the values
  if (props.action === EntitiesTreeAction.InitializeTreeValues) {
    const newState: UserNotificationState = { ...state, dataIsReady: true };
    newState.key = props.selectedKey
      ? props.selectedKey
      : getUserNotificationSettingsTypeKey(UserNotificationSettingsType.Unknown);

    if (props.initialSelected) {
      for (const corporationId of props.initialSelected.corporationIds) {
        newState.tree.corporations.get(corporationId)?.changeValue(true);
      }

      for (const companyId of props.initialSelected.companyIds) {
        newState.tree.getCompanies()?.get(companyId)?.changeValue(true);
      }

      for (const projectId of props.initialSelected.projectIds) {
        newState.tree.getProjects()?.get(projectId)?.changeValue(true);
      }

      for (const machineId of props.initialSelected.machineIds) {
        newState.tree.getMachines()?.get(machineId)?.changeValue(true);
      }
    }

    return { ...newState };
  }

  // Updates the values.
  switch (props.action) {
    case EntitiesTreeAction.UpdateKey:
      state.key = props.selectedKey || "";
      break;

    case EntitiesTreeAction.UpdateTree:
      /**
       * Checks parent nodes instead of selected one if the node has no siblings,
       * or they all have the same value as the desired one.
       */
      let node: TreeNode<boolean> = props.selectedNode;
      let hasNoSiblings: boolean = node.getSiblingsCount() === 0;
      let siblingsHaveSameValue: boolean = node.allSiblingsHaveValue(props.selectedNode.value);
      while (hasNoSiblings || siblingsHaveSameValue) {
        node = node.parent;
        hasNoSiblings = node.getSiblingsCount() === 0;
        siblingsHaveSameValue = node.allSiblingsHaveValue(props.selectedNode.value);
      }

      // Changes the value of the node and all related nodes.
      state.tree.corporations
        .get(node.getRootNode()?.id)
        ?.tryChangeChildValue(node.id, props.selectedNode.value);
      break;
  }

  return { ...state };
};

/**
 * Gets the Teams App message bar component.
 * @returns The App message bar component.
 */
const TeamsAppMessage = () => {
  return (
    <MessageBar intent='warning'>
      <MessageBarBody>
        <Trans>
          To receive Teams notifications you need to install the DALOG Data Platform Teams
          Notifications app. Click{" "}
          <Link href={ddpTeamsAppUrl} target='_blank' role='link to the DDP Teams App.'>
            here
          </Link>{" "}
          for more information.
        </Trans>
      </MessageBarBody>
    </MessageBar>
  );
};

/**
 * Gets the edit user notification page component.
 * @param onClose Method called when the dismiss/button to close the dialog is clicked.
 * @returns The Edit user notification page component.
 */
const EditUserNotificationPage = () => {
  const { t } = useTranslation();
  const userId = useAppSelector(selectUserAccountMemberID);

  const [onConfirm, setOnConfirm] = useState<boolean>(false);
  const [emailState, dispatchEmail] = useReducer(reducer, {
    key: "",
    tree: new EntitiesTree(),
    dataIsReady: false,
  });

  const [teamsState, dispatchTeams] = useReducer(reducer, {
    key: "",
    tree: new EntitiesTree(),
    dataIsReady: false,
  });

  // Gets the selectable items and builds the entities tree.
  useEffect(() => {
    if (!userId) {
      return;
    }

    UserNotificationsAPI.listSelectableEntities(userId).then((response) => {
      if (response.status !== 200) {
        notification.error(t("Failure: Getting selectable user notification entities."));

        return;
      }

      const selectableEntities = response.data as UserNotificationSettingsCorporation[];
      if (!selectableEntities) {
        return;
      }

      dispatchEmail({
        action: EntitiesTreeAction.InitializeTree,
        selectedTree: getEntitiesTree([...selectableEntities]),
      });
      dispatchTeams({
        action: EntitiesTreeAction.InitializeTree,
        selectedTree: getEntitiesTree([...selectableEntities]),
      });
    });
  }, [userId]);

  // Gets initial values
  useEffect(() => {
    if (!userId) {
      return;
    }

    if (emailState.tree.corporations.size === 0 || teamsState.tree.corporations.size === 0) {
      return;
    }

    UserNotificationsAPI.readUserNotificationSettings(userId).then((response) => {
      if (response.status !== 200) {
        notification.error(t("Failure: Getting current user notification settings."));

        return;
      }

      const data = response.data as UserNotificationSettings;
      if (!data) {
        return;
      }

      dispatchEmail({
        action: EntitiesTreeAction.InitializeTreeValues,
        selectedKey: data.email,
        initialSelected: data.emailSelected,
      });
      dispatchTeams({
        action: EntitiesTreeAction.InitializeTreeValues,
        selectedKey: data.teams,
        initialSelected: data.teamsSelected,
      });
    });
  }, [userId, teamsState.tree.corporations.size, emailState.tree.corporations.size]);

  // Handlers
  const onSubmitHandler = () => {
    if (!emailState.key || !teamsState.key) {
      return;
    }

    setOnConfirm(true);
  };

  const [selectedValue, setSelectedValue] = useState<TabValue>("email");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <Stack verticalAlign='start' style={{ padding: 8 }}>
      <Stack.Item>
        <h3 style={{ ...paddingStyle, ...titleStyle }}>{t("Update User Notifications")}</h3>
      </Stack.Item>
      <Stack.Item>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("User notification update tabs.")}
          onTabSelect={onTabSelect}
        >
          <Tab value='email' icon={<MailRegular color='#2c529f' />}>
            {t("Email")}
          </Tab>
          <Tab value='tab2' icon={<TeamsLogo fill='#2c529f' />}>
            Microsoft Teams
          </Tab>
        </TabList>
        <div style={paddingStyle}>
          {selectedValue === "email" &&
            withLoadingPanelHOC(
              t,
              emailState.dataIsReady,
              <UserNotificationDialogTab
                typeKey={emailState.key}
                tree={emailState.tree}
                onKeyChanged={(key: string) =>
                  dispatchEmail({
                    action: EntitiesTreeAction.UpdateKey,
                    selectedKey: key,
                  })
                }
                onTreeNodeChange={(node: TreeNode<boolean>) => {
                  dispatchEmail({
                    action: EntitiesTreeAction.UpdateTree,
                    selectedNode: node,
                  });
                }}
              />,
            )}
          {selectedValue === "tab2" && (
            <Stack verticalAlign='center' style={{ gap: 5 }}>
              <Stack.Item>
                <TeamsAppMessage />
              </Stack.Item>
              <Stack.Item>
                {withLoadingPanelHOC(
                  t,
                  teamsState.dataIsReady,
                  <UserNotificationDialogTab
                    typeKey={teamsState.key}
                    tree={teamsState.tree}
                    onKeyChanged={(key: string) =>
                      dispatchTeams({
                        action: EntitiesTreeAction.UpdateKey,
                        selectedKey: key,
                      })
                    }
                    onTreeNodeChange={(node: TreeNode<boolean>) =>
                      dispatchTeams({
                        action: EntitiesTreeAction.UpdateTree,
                        selectedNode: node,
                      })
                    }
                  />,
                )}
              </Stack.Item>
            </Stack>
          )}
        </div>
      </Stack.Item>
      <Stack.Item align='start'>
        <Stack horizontal horizontalAlign='center' style={paddingStyle}>
          <Button
            appearance='primary'
            disabled={!emailState.dataIsReady || !teamsState.dataIsReady}
            onClick={onSubmitHandler}
          >
            {t("Continue")}
          </Button>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {onConfirm && (
          <UserNotificationsConfirmDialog
            userId={userId}
            emailsState={emailState}
            teamsState={teamsState}
            onClose={() => {
              setOnConfirm(false);
            }}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default EditUserNotificationPage;
