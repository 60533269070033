import { t } from "i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { ObjectWithKey, ResponseSimplifiedSignalList } from "../../../../types";
import { getOperationView } from "../DetailsSection/OperationsTab/config";
import { getStatusOptions } from "../SignalsSection/methods";

export interface SummaryStore {
  operations: any;
  selectedSignals: ResponseSimplifiedSignalList;
  isGroupedEverything: boolean;
  setIsGroupedEverything: (checked: boolean | undefined) => void;
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (checked: boolean | undefined) => void;
  setSelectedSignals: (selectedSignals: ObjectWithKey[]) => void;
  addToSelectedSignals: (signals: ObjectWithKey[]) => void;
  removeFromSelectedSignals: (signals: ObjectWithKey[]) => void;
  signalsStatus: any;
  setSignalsStatus: (signalsStatus: any) => void;
  updateOperations: (operations: any) => void;
}

const useSummaryStore = createWithEqualityFn<SummaryStore>(
  (set) => ({
    selectedSignals: [],
    signalsStatus: getStatusOptions(t)
      .map((item) => item.key)
      .filter(
        (option) => !["NotInstalled", "NoStatus", "Faulty", "selectAll"].includes(option as string),
      ),
    operations: {
      loadingSignals: true,
      signal: undefined,
      view: getOperationView(t)[0],
      year: undefined,
      month: undefined,
    },
    isGroupedEverything: false,
    setIsGroupedEverything: (checked) => set(() => ({ isGroupedEverything: checked })),
    isWarningModalOpen: false,
    setIsWarningModalOpen: (checked) => set(() => ({ isWarningModalOpen: checked })),
    updateOperations: (operations) =>
      set((store) => ({ operations: { ...store.operations, ...operations } })),
    setSelectedSignals: (selectedSignalsInput: ObjectWithKey[]) => {
      set(() => ({
        selectedSignals: selectedSignalsInput as ResponseSimplifiedSignalList,
      }));
    },
    addToSelectedSignals: (signals: ObjectWithKey[]) =>
      set((store) => {
        const existingKeys = new Set(store.selectedSignals.map((signal) => signal.id));
        const newSignals = signals.filter((signal) => !existingKeys.has(signal.key as string));
        return {
          selectedSignals: [
            ...store.selectedSignals,
            ...newSignals,
          ] as ResponseSimplifiedSignalList,
        };
      }),
    removeFromSelectedSignals: (signals: ObjectWithKey[]) =>
      set((store) => ({
        selectedSignals: store.selectedSignals.filter(
          (signal) => !signals.some((s) => s.key === signal.id),
        ) as ResponseSimplifiedSignalList,
      })),
    setSignalsStatus: (signalsStatus: any) => set(() => ({ signalsStatus })),
  }),
  shallow,
);

export default useSummaryStore;
