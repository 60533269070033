import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { ObjectWithKey } from "../../../../types";

export type Edit = { id: string };

type Selection = {
  itemsKey: string;
  items: ObjectWithKey[];
};

type Actions =
  | { type: "SET_SELECTION"; payload: Selection }
  | { type: "SHOW_DELETE"; payload: boolean }
  | { type: "SHOW_ADD"; payload: boolean }
  | { type: "SET_EDIT"; payload: Edit }
  | { type: "SET_RELOAD" };

export interface TasksStore {
  selectedItems: {
    others: ObjectWithKey[];
    done: ObjectWithKey[];
  };
  reload: boolean;
  showAdd: boolean;
  edit: Edit;
  showDelete: boolean;
  dispatch: (action: Actions) => void;
}

const reducer = (state: TasksStore, action: Actions): TasksStore => {
  switch (action.type) {
    case "SET_SELECTION": {
      const selectedItems = {
        ...state.selectedItems,
        [action.payload.itemsKey]: action.payload.items,
      };
      return {
        ...state,
        selectedItems,
      };
    }
    case "SET_RELOAD": {
      return {
        ...state,
        reload: !state.reload,
      };
    }
    case "SHOW_DELETE": {
      return {
        ...state,
        showDelete: action.payload,
      };
    }
    case "SHOW_ADD": {
      return {
        ...state,
        showAdd: action.payload,
      };
    }
    case "SET_EDIT": {
      return {
        ...state,
        edit: action.payload,
      };
    }
  }
};

const useTasksStore = createWithEqualityFn<TasksStore>(
  (set) => ({
    selectedItems: {
      others: [],
      done: [],
    },
    reload: true,
    showDelete: false,
    showAdd: false,
    edit: { id: "" },
    dispatch: (action) => set((state) => reducer(state, action)),
  }),
  shallow,
);

export default useTasksStore;
