import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  getLastDataUpdateFormat,
  lastDataUpdateColors,
} from "../../../../../../../modules/machine-cv/constants";
import { Stack } from "../../../../../../Stack";
import { useMachineConditionsTable } from "../../../Machines/useMachineConditionsTable";
import Legend from "./components/Legend";
import Panel from "./components/Panel";
import PieChart from "./components/PieChart";
import { getSlicesDataByKey } from "./components/PieChart/utils";

const DATA_KEY = "lastDataUpdate";

function LastDataUpdatePanel() {
  const { t } = useTranslation();
  const { filterRegistry, machines, filterMachinesByKeyAndValue } = useMachineConditionsTable();

  const slices = getSlicesDataByKey({
    data: machines,
    key: DATA_KEY,
    colors: lastDataUpdateColors,
  });

  const actionableSlices = slices.map((slice) => ({
    ...slice,
    onClick: () => filterMachinesByKeyAndValue(DATA_KEY, slice[DATA_KEY]),
    isActive:
      typeof filterRegistry?.[DATA_KEY] !== "undefined"
        ? filterRegistry?.[DATA_KEY] === slice.dataValue
        : true,
  }));

  const legendItems = useMemo(
    () =>
      Object.keys(lastDataUpdateColors).map((conditionKey) => {
        const item = actionableSlices.find(({ dataValue }) => dataValue === conditionKey);

        return {
          text: getLastDataUpdateFormat(t)[conditionKey],
          number: item?.text || 0,
          color: lastDataUpdateColors[conditionKey],
          onClick: item?.onClick,
        };
      }),
    [actionableSlices, t],
  );

  return (
    <Stack
      horizontal
      verticalAlign='center'
      horizontalAlign='space-between'
      style={{
        gap: 16,
        flexGrow: 1,
      }}
    >
      <PieChart slices={actionableSlices} />
      <Legend legendItems={legendItems} />
    </Stack>
  );
}

export default function LastDataUpdate() {
  const { t } = useTranslation();

  return (
    <Panel
      dataKey={DATA_KEY}
      title={t("Last Data Update")}
      hasClearSelection={true}
      content={<LastDataUpdatePanel />}
      style={{ width: "100%", maxWidth: "520px" }}
    />
  );
}
