import { Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import type { Corporation } from "../Corporations/models";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../Corporations/reducer";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { AddOrEditDialog } from "./AddOrEditDialog";
import { DeleteConfirm } from "./DeleteConfirm";
import type { Company, CompanyToList } from "./models";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesError,
  selectCompaniesStatus,
} from "./reducer";

// --- Columns ---

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (company: Company) => void;
  onDelete: (company: Company) => void;
  t: any;
};

const getColumns = ({ hasActions, onEdit, onDelete, t }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Company) => (
        <Link to={id} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "number",
      name: t("Number"),
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "corporation",
      name: t("Corporation"),
      fieldName: "corporation",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (company: Company) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(company);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(company);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

export const CompaniesList: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCompanies);
  const status = useAppSelector(selectCompaniesStatus);
  const corporationsStatus = useAppSelector(selectCorporationsStatus);
  const corpos = useAppSelector(selectCorporations);
  const [itemsWithParent, setItemsWithParent] = useState<CompanyToList[]>([]);
  const error = useAppSelector(selectCompaniesError);
  const { t } = useTranslation();

  useEffect(() => {
    if (status === Status.error && items.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, items.length]);

  useEffect(() => {
    dispatch(listAsyncCompanies());
  }, [dispatch]);

  useEffect(() => {
    const toListAux: CompanyToList[] = items.map((com) => {
      const corpName = corpos.find((cor) => cor.id === com.corporationId)?.name;
      return { ...com, corporation: corpName ? corpName : "" };
    });
    setItemsWithParent(toListAux);
  }, [corpos, items]);

  useEffect(() => {
    if (corporationsStatus === Status.void) dispatch(listAsyncCorpo());
  }, [corporationsStatus, dispatch]);

  const hasWritePermission = auth.metaDataContributor;

  const { filters, handleSearch } = useTableFilters<CompanyToList>({
    keys: ["name", "number", "corporation"],
  });

  const [selected, setSelected] = useState<{
    data: CompanyToList | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (company: CompanyToList) => setSelected({ data: company, context: "edit" });

  const onDelete = (company: CompanyToList) => setSelected({ data: company, context: "delete" });

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Company")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            icon: <AddRegular />,
            onClick: onAdd,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />

      <Table
        persistOpts={{
          key: "table-companies",
          version: 2,
        }}
        header={{
          title: t("Company"),
        }}
        items={itemsWithParent}
        v8Columns={getColumns({
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
          t,
        })}
        filters={filters}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{companyName}} company`, {
                companyName: selected?.data?.name,
              }),
            );
          } else {
            dispatch(listAsyncCompanies());
            notification.success(
              t(`{{companyName}} company deleted successfully`, {
                companyName: selected?.data?.name,
              }),
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />

      {["add", "edit"].includes(selected?.context) && (
        <AddOrEditDialog
          options={corpos.map((corp: Corporation) => ({
            key: corp.id,
            text: corp.name,
          }))}
          data={selected?.data}
          items={itemsWithParent}
          show={["add", "edit"].includes(selected?.context)}
          onSuccess={(hasError, data, context) => {
            if (hasError) {
              const message =
                context === "add"
                  ? t(`Failed creating {{entity}}`, {
                      entity: data.name,
                    })
                  : t(`Failed updating {{entity}}`, {
                      entity: data.name,
                    });

              notification.error(message);
            } else {
              dispatch(listAsyncCompanies());

              const message =
                context === "add"
                  ? t(`{{entity}} creating successfully`, {
                      entity: data.name,
                    })
                  : t(`{{entity}} updating successfully`, {
                      entity: data.name,
                    });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
