import type { MenuProps } from "@fluentui/react-components";
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { sortBy } from "lodash-es";
import { useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import { useCompanyData } from "../../../../Components/Dashboards/DashboardIFramePage/hooks/useCompanyData";
import { apiService } from "../../../common";

const BreadcrumbItemProject = ({
  companyId,
  selectedProject,
  setSelectedProject,
}: {
  companyId: string;
  selectedProject: any;
  setSelectedProject: any;
}) => {
  const [open, setOpen] = useState(false);
  const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
    setOpen(data.open);
  };

  const navigate = ReactRouterDOM.useNavigate();
  const location = ReactRouterDOM.useLocation();
  const { pathname } = location;
  const { company, isLoading, isFetched } = useCompanyData({
    id: companyId as string,
  });

  if (isLoading || !isFetched) {
    return <Spinner size='tiny' />;
  }

  const dropdownProjectOptions =
    sortBy(company?.projects, ({ name }) => name?.toLowerCase()).map((m) => ({
      key: m.id,
      text: m.name,
    })) ?? [];

  const handleDropdownChange = ({ key, text }) => {
    setSelectedProject({
      id: key as string,
      name: text as string,
    });

    if (key) {
      apiService.metaDataRead
        .getProject(key)
        .then((data) => {
          if (data && data?.machines && data?.machines.length > 0) {
            const sortedMachines = sortBy(data?.machines, ({ name }) => name?.toLowerCase());

            if (sortedMachines.length > 0) {
              const firstMachineId = sortedMachines[0].id;

              navigate(`${pathname}?id=${firstMachineId}`);
            }
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  };

  return (
    <Menu open={open} onOpenChange={onOpenChange}>
      <MenuTrigger disableButtonEnhancement>
        <Button
          style={{ minWidth: 0, padding: 0, fontWeight: 400 }}
          icon={<ChevronDownRegular />}
          iconPosition='after'
          appearance='transparent'
        >
          {selectedProject?.name}
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {dropdownProjectOptions.map(({ key, text }) => (
            <MenuItem key={key} onClick={() => handleDropdownChange({ key, text })}>
              {text}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
export default BreadcrumbItemProject;
