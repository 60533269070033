import type { CSSProperties, MemoExoticComponent } from "react";
import { useEffect, useRef, useState } from "react";

import ReactMapGL from "react-map-gl";
import type { UseSuperclusterArgument } from "use-supercluster";
import useSupercluster from "use-supercluster";

import { useMap } from "./MapProvider";
import MarkerMap, { MapMarkerProps } from "./MarkerMap";
import { useTranslation } from "react-i18next";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

type _AnyProps = { [prop: string]: unknown };

type _Internal = UseSuperclusterArgument<_AnyProps, _AnyProps>;

export type Point = _Internal["points"][0];

type Bounds = _Internal["bounds"];

type _Marker = ({ cluster, supercluster }: MapMarkerProps) => JSX.Element;

type MapType = {
  points: Point[];
  onChange?: (points: Point[]) => void;
  style?: CSSProperties;
  onLoad?: ({ target }: any) => void;
  Marker?: MemoExoticComponent<_Marker> | _Marker;
  mapStyle?: string;
  minZoom?: number;
};

export const Map = ({
  points,
  onChange,
  style,
  Marker = MarkerMap,
  ...rest
}: MapType) => {
  const mapRef = useRef(null);
  const { i18n } = useTranslation();
  const [bounds, setBounds] = useState<Bounds>();
  const {
    state: { latitude, longitude, zoom },
    actions: { changeViewport },
  } = useMap();

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 },
  });

  useEffect(() => {
    if (!mapRef?.current?.setLanguage) return;

    mapRef?.current?.setLanguage(i18n.resolvedLanguage);
  }, [mapRef.current, i18n.resolvedLanguage]);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    const pointsInView = clusters
      ?.map((cluster) => {
        if (cluster.properties.cluster) {
          return supercluster?.getLeaves(cluster.id as number, Infinity);
        }
        return cluster;
      })
      .flat(Infinity) as Point[];

    onChange(pointsInView);
  }, [clusters]);

  return (
    <ReactMapGL
      ref={mapRef}
      doubleClickZoom={false}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/alexmatei/clee6dffh000z01mq981wm9eu"
      attributionControl={false}
      dragRotate={false}
      pitchWithRotate={false}
      style={{
        width: "100%",
        height: "60vh",
        ...style,
      }}
      zoom={zoom}
      latitude={latitude}
      longitude={longitude}
      onRender={({ target }) => {
        setBounds(target?.getBounds()?.toArray()?.flat() as Bounds);
      }}
      onMove={({ viewState }) => {
        changeViewport(viewState);
      }}
      {...rest}
    >
      {clusters.map((cluster) => (
        <Marker
          key={cluster.properties.cluster ? cluster.id : cluster.properties.id}
          cluster={cluster}
          supercluster={supercluster}
        />
      ))}
    </ReactMapGL>
  );
};
