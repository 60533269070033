import "./styles.scss";

import {
  Button,
  Divider,
  Link,
  makeStyles,
  SearchBox,
  Text,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import type { FormEvent, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import ClearIcon from "../../../../assets/svg/ClearIcon";
import Checkbox from "../../../../Components/common/Checkbox";
import Dropdown from "../../../../Components/common/Dropdown";
import { NoData } from "../../../../Components/common/NoData";
import SpinButton, { sideBySide } from "../../../../Components/common/SpinButton";
import { Stack } from "../../../../Components/Stack";
import { queryClient } from "../../../core";
import useSearchGroupsAndItems from "../../hooks/useSearchGroupsAndItems";
import { useCatalogueStore } from "./hooks";

const schemaNumber = z.number().int().nonnegative();
const schemaFloat = z.number().nonnegative();

const validateNumber = (value: string, type: "float" | "int"): number | undefined => {
  const result =
    type === "float"
      ? schemaFloat.safeParse(parseFloat(value))
      : schemaNumber.safeParse(parseInt(value, 10));

  return result.success ? parseFloat(result.data.toFixed(4)) : undefined;
};

const handleChange = (value: string, type: "float" | "int", action: (value: number) => void) => {
  const result = validateNumber(value, type);
  if (result !== undefined) {
    action(result);
  }
};

const handleInput = (
  event: FormEvent<HTMLDivElement> | SyntheticEvent<HTMLElement, Event>,
  type: "float" | "int",
  action: (value: number) => void,
) => {
  const result = validateNumber((event.target as HTMLInputElement).value, type);
  if (result !== undefined) {
    action(result);
  }
};

function getGuidString(
  items: any[],
  firstFrequency: number,
  secondFrequency: number,
  currentOption?: string,
): string {
  const uncertainty = 0.04;

  if (firstFrequency === 0) return "";

  // Filter items by first frequency
  const firstItems = items.filter(
    (item) => Math.abs(item.frequencyInHz - firstFrequency) <= uncertainty,
  );

  // Filter sidebands by second frequency
  const secondItems = items
    .flatMap((item) => item.sidebands)
    .filter((item) => Math.abs(item.frequencyInHz - secondFrequency) <= uncertainty);

  // Construct potential matches
  const potentialMatches = firstItems.flatMap((firstItem) => {
    if (secondFrequency === 0) {
      return [firstItem.id];
    }
    return secondItems.map((secondItem) => `${firstItem.id}:${secondItem.id}`);
  });

  // Check if currentOption matches any potential match
  if (currentOption && potentialMatches.includes(currentOption)) {
    return currentOption;
  }

  // If no direct match, construct the option string based on filtered items
  const firstGuid = firstItems.length > 0 ? firstItems[0].id : "";
  const secondGuid = secondItems.length > 0 ? secondItems[0].id : "";
  return secondFrequency === 0 ? firstGuid : `${firstGuid}:${secondGuid}`;
}

function getFrequencies(items: any[], guidString: string): [number, number] {
  const guids = guidString.split(":");
  const firstGuid = guids[0];
  const secondGuid = guids[1] || "";

  const firstItem = items.find((item) => item.id === firstGuid);
  if (!firstItem) {
    return [0, 0];
  }

  const firstFrequency = firstItem.frequencyInHz;

  if (!secondGuid) {
    return [firstFrequency, 0];
  }

  const secondItem = items.flatMap((item) => item.sidebands).find((item) => item.id === secondGuid);
  const secondFrequency = secondItem ? secondItem.frequencyInHz : 0;

  return [firstFrequency, secondFrequency];
}

function multiplyFrequencies(data: any, multiplier: number | undefined) {
  if (!multiplier) {
    return data;
  }
  return data?.map((item: any) => {
    const { sidebands, ...rest } = item;
    return {
      ...rest,
      frequencyInHz: parseFloat((item.frequencyInHz * multiplier).toFixed(2)),
      sidebands: sidebands.map((sideband: any) => {
        return {
          ...sideband,
          frequencyInHz: parseFloat((sideband.frequencyInHz * multiplier).toFixed(2)),
        };
      }),
    };
  });
}

const useStyles = makeStyles({
  tree: {
    "& .freq-group": {
      borderTop: "1px solid rgb(228 228 228 / 88%)",

      "&:last-of-type": {
        borderBottom: "1px solid rgb(228 228 228 / 88%)",
      },
    },

    "& .freq-group-title": {
      fontSize: "16px",
      fontWeight: 600,
    },

    "& .freq-header-row": {
      "& .fui-Text": {
        color: "#000",
        fontSize: "14px",
        fontWeight: 600,
      },
    },

    "& .freq-row": {
      padding: "8px 24px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      gap: "12px",

      "& .fui-TreeItemLayout__main": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "12px",
      },
    },

    "& .freq-link": {
      flexGrow: 1,
    },

    "& .freq-text": {
      width: "70px",
      fontSize: "12px",
      color: "rgb(96, 94, 92)",
    },

    "& .freq-dropdown": {
      width: "120px",
    },
  },
});

function FrequenciesTable({
  data,
  catalogueData,
  onSelectedOption,
  selectedOption,
  setSelectedOption,
}: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  function renderDropdown(item?: any) {
    const mappedSidebands = (item?.sidebands || []).map(
      ({ id, itemName }: { id: string; itemName: string }) => ({
        key: item.id + ":" + id,
        text: itemName,
      }),
    );

    const options = [{ key: item.id, text: t("None") }, ...mappedSidebands];

    const selectedSideband = options.find((opt) => opt.key === selectedOption);

    return (
      <Dropdown
        className='freq-dropdown'
        style={{ minWidth: "120px" }}
        selectedOptions={[selectedSideband?.text || ""]}
        options={options}
        onOptionSelect={(_, option) => {
          if (option) {
            const selectedOptionKey = option.optionValue as string;
            item.options = selectedOptionKey;
            setSelectedOption(selectedOptionKey);
            onSelectedOption(selectedOptionKey);
          }
        }}
      />
    );
  }

  return (
    <Tree aria-label='frequency-catalogue' className={classes.tree}>
      <TreeItemLayout className='freq-row freq-header-row'>
        <Text className='freq-link'>{t("Name")}</Text>
        <Text style={{ width: "100px" }}>{t("Frequency")}</Text>
        <Text className='freq-dropdown'>{t("Sidebands")}</Text>
      </TreeItemLayout>
      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
        {catalogueData.groups.map((group) => (
          <TreeItem key={group.key} value={group.key} itemType='branch' className='freq-group'>
            <TreeItemLayout className='freq-group-title'>{group.name}</TreeItemLayout>
            <Tree>
              {data.slice(group.startIndex, group.startIndex + group.count).map((item) => (
                <TreeItem key={item.id} itemType='leaf'>
                  <TreeItemLayout className='freq-row'>
                    <Link
                      className='freq-link'
                      onClick={() => {
                        setSelectedOption(item.id);
                        onSelectedOption(item.id);
                      }}
                    >
                      {item.itemName}
                    </Link>
                    <Text className='freq-text'>{item.frequencyInHz}</Text>
                    {renderDropdown(item)}
                  </TreeItemLayout>
                </TreeItem>
              ))}
            </Tree>
          </TreeItem>
        ))}
      </div>
    </Tree>
  );
}

export default function FrequenciesCatalogue({ machineId, inputs, inputSpeedReference }: any) {
  const { t } = useTranslation();

  const { keyedCatalogueData } = useCatalogueStore((store: any) => ({
    keyedCatalogueData: store.keyedCatalogueData,
    setSelectedSignalValues: store.setSelectedSignalValues,
    removeXAxisRange: store.removeXAxisRange,
    selectedSignalsStores: store.selectedSignalsStores,
  }));

  const referenceSpeedSignal: any = queryClient.getQueryData([
    "frequency-catalogue-reference-speed-signal",
    machineId,
  ]);

  const catalogueData = keyedCatalogueData[machineId]?.groupsAndItems
    ? keyedCatalogueData[machineId].groupsAndItems
    : [];

  const { data, handleSearch } = useSearchGroupsAndItems(catalogueData);

  const [dataWithVelocityFactor, setDataWithVelocityFactor] = useState<any>(
    multiplyFrequencies(data.items, inputSpeedReference.ratioSpeedFromCustomValueRPM),
  );

  const [selectedOption, setSelectedOption] = React.useState<string>(
    getGuidString(dataWithVelocityFactor, inputs.freqHzInput.value, inputs.sbsHzInput.value),
  );

  useEffect(() => {
    setDataWithVelocityFactor(
      multiplyFrequencies(data.items, inputSpeedReference.ratioSpeedFromCustomValueRPM),
    );
    return;
  }, [data.items]);

  useEffect(() => {
    setSelectedOption(
      getGuidString(
        dataWithVelocityFactor,
        inputs.freqHzInput.value,
        inputs.sbsHzInput.value,
        selectedOption,
      ),
    );
    return;
  }, [inputs.freqHzInput.value, dataWithVelocityFactor]);

  if (catalogueData?.length === 0) {
    return <NoData style={{ height: "100%" }} />;
  }
  const handleSelectedOption = (selectedOption: string) => {
    let freq1 = 0;
    let freqSB = 0;
    [freq1, freqSB] = getFrequencies(dataWithVelocityFactor, selectedOption);
    handleChange(freq1.toString(), inputs.freqHzInput.type, inputs.freqHzInput.action);
    handleChange(freqSB.toString(), inputs.sbsHzInput.type, inputs.sbsHzInput.action);
  };

  const commonStyle = { minWidth: "400px" };

  return (
    <Stack className='catalogue-content' style={{ rowGap: 16, height: "100%" }}>
      <Stack horizontal wrap style={{ paddingLeft: "40px", gap: 10 }}>
        <Stack horizontal horizontalAlign='space-between' style={commonStyle}>
          <Stack.Item>{t("Reference Speed Signal")}</Stack.Item>
          <Stack.Item>
            {referenceSpeedSignal?.name
              ? referenceSpeedSignal.name
              : t("The Reference Speed signal is undefined")}
          </Stack.Item>
        </Stack>
        {referenceSpeedSignal?.rpm && (
          <>
            <Stack horizontal horizontalAlign='space-between' style={commonStyle}>
              <Stack.Item>{t("Nominal speed value (RPM)")}</Stack.Item>
              <Stack.Item>{referenceSpeedSignal?.rpm}</Stack.Item>
            </Stack>

            <Stack style={commonStyle}>
              <Checkbox
                label={t("Use manual input")}
                defaultChecked={inputSpeedReference.manualInput}
                onChange={(checked) => {
                  inputSpeedReference.setManualInput(machineId, checked);
                  checked
                    ? setDataWithVelocityFactor(
                        multiplyFrequencies(
                          data.items,
                          inputSpeedReference.ratioSpeedFromCustomValueRPM,
                        ),
                      )
                    : setDataWithVelocityFactor(multiplyFrequencies(data.items, 1));
                  handleChange("0", inputs.freqHzInput.type, inputs.freqHzInput.action);
                  handleChange("0", inputs.sbsHzInput.type, inputs.sbsHzInput.action);
                }}
              />
            </Stack>
            <Stack style={commonStyle}>
              <SpinButton
                fieldStyle={sideBySide}
                min={0}
                value={
                  inputSpeedReference.ratioSpeedFromCustomValueRPM
                    ? inputSpeedReference.ratioSpeedFromCustomValueRPM * referenceSpeedSignal?.rpm
                    : referenceSpeedSignal?.rpm
                }
                label={t("Custom value (RPM)")}
                disabled={!inputSpeedReference.manualInput}
                onChange={(newValue) => {
                  inputSpeedReference.setRatioSpeedFromCustomValueRPM(
                    machineId,
                    newValue
                      ? parseInt(newValue) / referenceSpeedSignal?.rpm
                      : referenceSpeedSignal?.rpm,
                  );
                  newValue &&
                    setDataWithVelocityFactor(
                      multiplyFrequencies(
                        data.items,
                        parseInt(newValue) / referenceSpeedSignal?.rpm,
                      ),
                    );
                  handleChange("0", inputs.freqHzInput.type, inputs.freqHzInput.action);
                  handleChange("0", inputs.sbsHzInput.type, inputs.sbsHzInput.action);
                }}
              />
            </Stack>
          </>
        )}
        <Stack style={commonStyle}>
          <SpinButton
            fieldStyle={sideBySide}
            value={inputs.freqNumberInput.value}
            min={0}
            step={1}
            label={t("Freq. Number")}
            onChange={(newValue) =>
              handleChange(newValue, inputs.freqNumberInput.type, inputs.freqNumberInput.action)
            }
          />
        </Stack>
        <Stack style={commonStyle}>
          <SpinButton
            fieldStyle={sideBySide}
            value={inputs.sbsNumberInput.value}
            min={0}
            step={1}
            label={t("SBs Number")}
            onChange={(newValue) =>
              handleChange(newValue, inputs.sbsNumberInput.type, inputs.sbsNumberInput.action)
            }
          />
        </Stack>
      </Stack>

      <Divider />

      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <Button
          appearance='transparent'
          style={{
            minWidth: 125,
            height: 40,
            marginRight: 16,
            gap: 4,
          }}
          onClick={() => {
            handleChange("0", inputs.freqHzInput.type, inputs.freqHzInput.action);
            handleChange("0", inputs.sbsHzInput.type, inputs.sbsHzInput.action);
          }}
        >
          <ClearIcon />
          {t("Clear selection")}
        </Button>

        <SearchBox
          placeholder={t("Search")}
          style={{ width: 300 }}
          onChange={(_, { value: newValue }) => handleSearch(newValue ?? "")}
        />
      </Stack>

      <FrequenciesTable
        data={dataWithVelocityFactor}
        catalogueData={data}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        onSelectedOption={handleSelectedOption}
      />
    </Stack>
  );
}
