import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { apiService } from "../../modules/common";
import type { BreadcrumbInterface } from "../../types";

interface UseMachineCVBreadcrumbArgs {
  machineId: string;
  options?:
    | (Omit<
        UseQueryOptions<BreadcrumbInterface, unknown, BreadcrumbInterface, string[]>,
        "initialData" | "queryFn" | "queryKey"
      > & { initialData?: (() => undefined) | undefined })
    | undefined;
}

const useMachineCVBreadcrumb = ({ machineId, options }: UseMachineCVBreadcrumbArgs) =>
  useQuery(
    ["breadcrumb", machineId],
    () => apiService.MachineCVBreadcrumb.getMachineCVBreadcrumb({ machineId }),
    options,
  );

export default useMachineCVBreadcrumb;
