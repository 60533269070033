import "../../../MachineCVTasksPage/components/TaskEdit/style.scss";

import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useSearch } from "../../../../../Hooks";
import BaseDialog, { BaseDialogTitle } from "../../../../common/Dialog";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import { useTagsQuery } from "../../hooks/useTagsQuery";
import AddTagDialog from "./AddTagDialog";
import { CommandBar } from "./CommandBar";
import { Tag } from "./Tag";

type TagsDialogProps = Omit<DialogProps, "children"> & {
  onDismiss?: () => void;
};

export const TagsDialog = (props: TagsDialogProps) => {
  const { t } = useTranslation();
  const [{ id: machineId }, ,] = useLocationSearch();

  const { tags, isError, isLoading } = useTagsQuery({
    machineId: machineId as string,
  });
  const [showAddTagsDialog, setShowAddTagsDialog] = useState(false);

  const { data, handleSearch } = useSearch({
    data: tags,
    keys: ["name"],
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog {...props} onOpenChange={onClose}>
        <BaseDialogTitle>{t("Manage tags")}</BaseDialogTitle>
        <DialogContent>
          {isLoading && <Spin style={{ marginTop: "50px" }} />}
          {isError && <NoData style={{ marginTop: "50px" }} />}
          <CommandBar handleSearch={handleSearch} onAdd={() => setShowAddTagsDialog(true)} />
          <div className='tags-dialog-container'>
            {data.map((tag, idx) => (
              <Tag key={idx} hasDelete name={tag.name} id={tag.id as string} />
            ))}
          </div>
          <DialogActions>
            <Button appearance='transparent' onClick={onClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
      <AddTagDialog open={showAddTagsDialog} onDismiss={() => setShowAddTagsDialog(false)} />
    </>
  );
};
