import { createContext } from "react";

import type { MyAccountInfo } from "../UsersRBCA/models";

export interface LocalAccountInfoProps {
  val: MyAccountInfo | undefined;
  set: React.Dispatch<React.SetStateAction<MyAccountInfo | undefined>>;
}

export const IsClickedAccountIconContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const IsClickedUpdateUserContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const IsClickedUpdateProfilePhotoContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const IsClickedUpdateUserNotificationsContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const LocalAccountInfo = createContext<LocalAccountInfoProps | undefined>(undefined);
