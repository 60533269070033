import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import NoDataIcon from "../../../assets/svg/NoDataIcon";
import type { StackProps } from "../../Stack";
import { Stack } from "../../Stack";

const containerStyle: CSSProperties = {
  height: "100%",
  minHeight: "10em",
};

const iconStyles = { width: "100%", height: 24, fill: "#2c529f" };

const labelStyles = {
  margin: "8px 0px 0px",
  fontSize: 14,
  fontWeight: 600,
  color: "#3B3A39",
};

export type NoDataProps = StackProps & {
  text?: string;
};

const NoData = ({ text, style = {}, ...rest }: NoDataProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      {...rest}
      verticalAlign='center'
      horizontalAlign='center'
      style={{ ...containerStyle, ...style }}
    >
      <NoDataIcon viewBox='0 0 16 18' style={iconStyles} />
      <div style={labelStyles}>{text ?? t("No Data")}</div>
    </Stack>
  );
};

export default NoData;
