import { useTranslation } from "react-i18next";

import { useLocationSearch } from "../../../../../Hooks";
import Table from "../../../../common/Table/v9";
import type { ListProps } from "./columns";
import { getColumns } from "./columns";

const List = ({ listItems, onAddTask, onAddDocument }: ListProps) => {
  const [{ search }] = useLocationSearch();
  const { t } = useTranslation();

  const filteredItems = listItems.filter(({ id }) => id.toLowerCase() !== "none");
  const hasColumnData = (fieldName: string) =>
    filteredItems.every((item: any) => item?.[fieldName]);

  const columns = getColumns({
    t,
    onAddDocument,
    onAddTask,
    search,
  }).filter(({ fieldName }) => fieldName === "actions" || hasColumnData(fieldName));

  return (
    <div style={{ margin: "0 16px" }}>
      <Table
        persistOpts={{
          key: "table-machineCV-sensors",
          version: 2,
        }}
        v8Columns={columns}
        items={filteredItems}
      />
    </div>
  );
};

export default List;
