import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { textStyle } from "../../../schema/Constants";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../common/Dialog";
import TextField from "../../common/TextField";
import { deleteCompany } from "../../Companies/api";
import type { Company } from "../../Companies/models";

type DeleteDialogProps = Omit<DialogProps, "children" | "open"> & {
  data: Company;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const DeleteConfirm = ({ data, show, onSuccess, onClose, ...rest }: DeleteDialogProps) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!isValid) {
      return;
    }

    setLoading(true);

    await deleteCompany(data).then((response) => onSuccess("status" in response));

    handleClose();
  };

  const validate = (value) => {
    const parsedValue = value.trim().toLowerCase();

    setValid(data.name.toLowerCase() === parsedValue);
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    setValid(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{ width: DialogSize.M }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>
      <DialogContent>
        <div style={textStyle}>{t("You are about to delete selected company.")}</div>
        <TextField required label={t("Please confirm typing the name:")} onChange={validate} />
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || !isValid}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleDelete}
          >
            {t("Delete")}
          </Button>
          <Button onClick={handleClose}>{t("Close")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
