import {
  makeStyles,
  Text,
  Tooltip,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { Add20Regular, Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FrequencyCatalogueGroupsAndItemsResponse } from "./models";

const useStyles = makeStyles({
  tree: {
    "& .freq-group": {
      borderTop: "1px solid rgb(228 228 228 / 88%)",

      "&:first-of-type": {
        borderTop: "unset",
      },

      "&:last-of-type": {
        borderBottom: "1px solid rgb(228 228 228 / 88%)",
      },
    },

    "& .freq-group-title": {
      fontSize: "14px",
      color: "rgb(36, 36, 36)",
    },

    "& .freq-header-row": {
      background: "transparent",

      "& .fui-Text": {
        color: "#000",
        fontSize: "14px",
        fontWeight: 600,
      },
    },

    "& .freq-row": {
      padding: "8px 24px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      gap: "12px",
      background: "#fff",

      "& .fui-TreeItemLayout__main": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "12px",
      },
    },

    "& .freq-name": {
      minWidth: "100px",
      width: "calc((100% - 100px - 50px) / 2)",
    },

    "& .freq-text": {
      width: "100px",
      fontSize: "12px",
      color: "rgb(96, 94, 92)",
    },

    "& .freq-description": {
      minWidth: "100px",
      width: "calc((100% - 100px - 50px) / 2)",
    },

    "& .freq-actions": {
      width: "50px",
    },
  },
});

interface FrequencyCatalogueGroupTableProps {
  groupsAndItems: FrequencyCatalogueGroupsAndItemsResponse;
  columns: any[];
  onAddItem: (groupName: string) => void;
  onDeleteGroup: (groupName: string) => void;
  onEditGroup: (groupName: string) => void;
}

export const FrequencyCatalogueGroupTable: React.FC<FrequencyCatalogueGroupTableProps> = ({
  groupsAndItems,
  columns,
  onAddItem,
  onDeleteGroup,
  onEditGroup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const groups = useMemo(
    () =>
      groupsAndItems.groups.map((group) => {
        return {
          ...group,
          key: group.id,
        };
      }),
    [groupsAndItems.groups],
  );

  return (
    <>
      <Tree aria-label='frequency-group-table' className={classes.tree}>
        <div>
          {groups.map((group) => (
            <TreeItem key={group.key} value={group.key} itemType='branch' className='freq-group'>
              <TreeItemLayout className='freq-group-title'>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {group.name + " (" + group.description + ")"}
                  <Tooltip withArrow relationship='label' content={t("Add")}>
                    <Add20Regular
                      style={{ color: "#2c529f", cursor: "pointer", marginLeft: "8px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onAddItem(group.name);
                      }}
                    />
                  </Tooltip>
                  <Tooltip withArrow relationship='label' content={t("Edit")}>
                    <Edit20Regular
                      style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onEditGroup(group.name);
                      }}
                    />
                  </Tooltip>
                  <Tooltip withArrow relationship='label' content={t("Delete")}>
                    <Delete20Regular
                      style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDeleteGroup(group.name);
                      }}
                    />
                  </Tooltip>
                </div>
              </TreeItemLayout>
              <Tree>
                {groupsAndItems.items
                  .slice(group.startIndex, group.startIndex + group.count)
                  .map((item) => (
                    <TreeItem key={item.id} itemType='leaf'>
                      <TreeItemLayout className='freq-row'>
                        <Text className='freq-name'>{item.itemName}</Text>
                        <Text className='freq-text'>{item.frequencyInHz}</Text>
                        <Text className='freq-description'>{item.description}</Text>
                        <div className='freq-actions'>{columns[3].onRender(item)}</div>
                      </TreeItemLayout>
                    </TreeItem>
                  ))}
              </Tree>
            </TreeItem>
          ))}
        </div>
      </Tree>
    </>
  );
};

export default FrequencyCatalogueGroupTable;
