import type { ComboboxProps as FluentUIComboboxProps } from "@fluentui/react-components";
import { Combobox as FluentUICombobox } from "@fluentui/react-components";
import { useState } from "react";

import { defaultOnRenderOption } from "../Option";

export type ComboboxProps = FluentUIComboboxProps & {
  options: any[];
  renderOption?: ({ key, text }: { key: any; text: string }) => any;
  allowFreeform?: boolean;
  multiSelect?: boolean;
  onChange?: any;
};

function getMultiSelecteValue(options, selectedOptions) {
  return options
    .filter(({ key }) => selectedOptions.includes(key))
    .map(({ text }) => text)
    .join(", ");
}

export default function Combobox({
  options = [],
  onChange,
  onBlur,
  value,
  allowFreeform = false,
  multiSelect = false,
  renderOption = defaultOnRenderOption,
  ...rest
}: ComboboxProps) {
  const selectedOptions = multiSelect ? [...value] : [value];
  const inputValue = getMultiSelecteValue(options, selectedOptions);

  const [text, setText] = useState(inputValue ?? "");

  function onInput(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }

  function onOptionSelect(_, data) {
    const key = data.optionValue;
    const selectedOptions = data?.selectedOptions || [];

    if (multiSelect) {
      onChange(selectedOptions);
      const inputValue = getMultiSelecteValue(options, selectedOptions);

      setText(inputValue ?? "");
    } else {
      onChange(key);
      setText(data?.optionText ?? "");
    }
  }

  return (
    <FluentUICombobox
      style={{ width: "100%" }}
      freeform={allowFreeform}
      multiselect={multiSelect}
      selectedOptions={selectedOptions}
      value={text}
      onInput={onInput}
      onBlur={onBlur}
      onOptionSelect={onOptionSelect}
      {...rest}
    >
      {options.map(renderOption)}
    </FluentUICombobox>
  );
}
