import { isEmpty } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useMachineCVBreadcrumb, valueIncludes } from "../../../../../Hooks";
import { breadcrumbDefaultResponse } from "../../../../../modules/common/services/api/MachineCVBreadcrumb";
import type { TaskOverview } from "../../../../../types";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import type { TableProps } from "../../../../common/Table/v9/Table";
import Table from "../../../../common/Table/v9/Table";
import { TaskEditDialog } from "../../../MachineCVTasksPage/components/TaskEdit";
import { useTaskQuery } from "../../../MachineCVTasksPage/hooks/useTaskQuery";
import type { Edit } from "../../../MachineCVTasksPage/hooks/useTasksStore";
import useTasksStore from "../../../MachineCVTasksPage/hooks/useTasksStore";
import { getColumns } from "./columns";

const KEYS = ["subject", "status", "assignedTo"];

export const TasksTab = ({ search }: { search: string }) => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();

  const { edit, dispatch, reload } = useTasksStore((state) => ({
    edit: state.edit,
    dispatch: state.dispatch,
    reload: state.reload,
  }));

  const { items, isError, isLoading } = useTaskQuery({
    reload,
    machineId: id as string,
  });

  const filteredData = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();

    return items?.filter((item) =>
      KEYS.reduce(
        (valid, key) => valid || valueIncludes(item[key as keyof TaskOverview], normalizedSearch),
        false,
      ),
    );
  }, [search, items]);

  const { data: breadcrumdResponse = breadcrumbDefaultResponse } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: "table-summary-tasks",
        version: 1,
      },
      items: filteredData,
      perPage: 99999,
      hidePerPage: true,
      isLoading: isLoading,
      isError,
      v8Columns: getColumns({
        t,
        showEdit: (payload: Edit) => dispatch({ type: "SET_EDIT", payload }),
      }),
    }),
    [filteredData, isLoading, isError, t, dispatch],
  );

  return (
    <>
      {isLoading && <Spin style={{ height: "100%" }} />}
      {!isLoading && isEmpty(items) && <NoData style={{ height: "100%" }} />}
      {!isEmpty(items) && <Table {...tableProps} />}
      <TaskEditDialog
        open={Boolean(edit.id)}
        breadcrumb={breadcrumdResponse}
        onDismiss={() => dispatch({ type: "SET_EDIT", payload: { id: "" } })}
      />
    </>
  );
};
