/* eslint-disable react-hooks/exhaustive-deps */
import "react-toastify/dist/ReactToastify.css";

import * as React from "react";
import { createContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import { LeftMenuAlt } from "../LeftMenuAlt/LeftMenuAlt";
import { TopMenu } from "../TopMenu/TopMenu";
import { AppNotificationMessage } from "./AppNotificationMessage";
import useFluentComponentsStyleOverrides from "./componentsStyleOverrides/useFluentComponentsStyleOverrides";
import { Content } from "./Content";

export interface ToContextIsExpandedLeftMenu {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

export const leftMenuIsExpandContext = createContext<ToContextIsExpandedLeftMenu | undefined>(
  undefined,
);

export const Layout: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const componentsStyleOverrides = useFluentComponentsStyleOverrides();

  const [leftMenu, setLeftMenu] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["notificationSeen"]);
  const notificationExpired: boolean = useMemo(() => {
    const expirationDate = new Date(process.env.REACT_APP_NOTIFICATION_EXPIRATION_DATE);

    return expirationDate.getTime() <= Date.now();
  }, []);

  // Removes the cookie if the notification message is expired.
  useEffect(() => {
    if (!notificationExpired) {
      return;
    }

    removeCookie("notificationSeen");
  }, [notificationExpired]);

  return (
    <div className={componentsStyleOverrides.root}>
      <leftMenuIsExpandContext.Provider value={{ value: leftMenu, setValue: setLeftMenu }}>
        <TopMenu />
        <LeftMenuAlt>
          <Content />
        </LeftMenuAlt>
      </leftMenuIsExpandContext.Provider>
      <AppNotificationMessage
        title={process.env.REACT_APP_NOTIFICATION_TITLE}
        message={process.env.REACT_APP_NOTIFICATION_MESSAGE}
        expired={notificationExpired}
        visited={cookies.notificationSeen}
        onVisited={() => setCookie("notificationSeen", true)}
      />
    </div>
  );
};
