import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../Hooks";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import TextField from "../common/TextField";
import { Stack } from "../Stack";
import { deleteMachine } from "./api";
import type { MachineToList } from "./models";
import { listAsyncMachines } from "./reducer";

type DeleteConfirmProps = Omit<DialogProps, "children"> & {
  data: MachineToList;
  onClose: () => void;
};

export const DeleteConfirm = ({ data, onClose, ...rest }: DeleteConfirmProps) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    setLoading(true);
    deleteMachine(data.id).then((response) => {
      setLoading(false);
      if (response.status !== 200) {
        notification.error(t("Failure: Deleting a machine."));
        return;
      }

      dispatch(listAsyncMachines());
      notification.success(t("Success: Deleting a machine."));
      onClose?.();
    });
  };

  const onDalogIdChange = (value) => {
    const parsedValue = value.trim().toLowerCase();
    setValid(data.dalogId === parsedValue);
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    setValid(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      open={true}
      surfaceStyle={{ width: DialogSize.M }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>
      <DialogContent>
        <Stack style={{ gap: 10 }}>
          <Text size={300}>{t("You are about to delete a machine.")}</Text>

          <TextField
            required
            label={t("Please confirm typing the dalog Id:")}
            onChange={onDalogIdChange}
          />
        </Stack>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || !isValid}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleDelete}
          >
            {t("Delete")}
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            {t("Close")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
