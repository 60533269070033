import { EraseToolIcon } from "@fluentui/react-icons-mdl2";
import classnames from "classnames";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { CommandBarItemType } from "../../../../Components/common/CommandBar";
import {
  useMaximize,
  useShortTrendRawDataPointsForOneDataPointAsZip,
  useUniqueId,
} from "../../../../Hooks";
import useSelectedDataPointsStore from "../../../analysis-raw-data/hooks/useSelectedDataPointsStore";
import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import type { WaveformChartSectionProps } from "../../types/index";
import ChartSectionHeader from "../ChartSectionHeader";
import { PlotSpinner, RawDataPlotNoData } from "../PlotPlaceholders";
import WaveformChart from "../WaveformChart";

const WaveformChartSection = ({
  signal,
  dataPoint,
  dataPointTimestamp,
  isSecond,
}: WaveformChartSectionProps) => {
  const { t } = useTranslation();
  const chartRef = useRef<any>(null);

  const selectedDataPoints = useSelectedDataPointsStore((store) => store.selectedDataPoints);

  const uniqueId = useUniqueId();

  const {
    data = { data: [] },
    isFetching,
    isLoading,
  } = useShortTrendRawDataPointsForOneDataPointAsZip(
    {
      signalId: signal.id as string,
      dataPointId: dataPoint,
    },
    {
      enabled: !!selectedDataPoints.length && !!dataPoint,
      // cacheTime: 0,
    },
  );

  const isDataLoading = isFetching && isLoading;

  const { isChartMaximized, isChartHidden, maximizeAction, maximizeIcon, maximizeLabel } =
    useMaximize({
      id: uniqueId,
      page: "raw-data",
    });

  const { findTimestamp } = useChartTimestampStore((store) => ({
    timestamps: store.timestamps,
    findTimestamp: store.findTimestamp,
  }));

  const timestamp = findTimestamp(signal.id);

  const menuItems = [
    {
      key: "clearCursors",
      type: CommandBarItemType.Button,
      icon: <EraseToolIcon />,
      style: { marginLeft: 16, height: 36 },
      onClick: () => {
        if (chartRef.current) {
          chartRef.current.clearRangeLines();
        }
      },
    },
  ];

  return (
    <div
      className={classnames("waveform-chart-section", {
        "raw-data-chart-section--hidden": isChartHidden,
        "raw-data-chart-section--maximized": isChartMaximized,
      })}
    >
      <ChartSectionHeader
        id={uniqueId}
        title={t("Waveform")}
        items={isSecond ? undefined : menuItems}
        {...{
          maximizeAction,
          maximizeIcon,
          maximizeLabel,
          timestamp: dataPointTimestamp || timestamp?.timestamp,
        }}
      />

      {isDataLoading && <PlotSpinner />}
      {!isDataLoading && data?.data?.length === 0 && <RawDataPlotNoData signal={signal} />}
      {!isDataLoading && data?.data?.length > 0 && (
        <WaveformChart
          ref={chartRef}
          data={data}
          exportId={uniqueId}
          signal={signal}
          style={{ height: "calc(100% - 61px - 53px)" }}
        />
      )}
    </div>
  );
};

export default WaveformChartSection;
