import "./ChevronItemWithChildren.css";

import { Button, Tooltip } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronRightRegular } from "@fluentui/react-icons";
import * as React from "react";

import Icon from "../../CustomIcons";

interface ChevronItemWithChildrenProps {
  name: string;
  parentIconName: string;
  chevron?: boolean;
  onClick?: () => void;
  error?: string;
}

const ChevronItemWithChildren = ({
  name,
  parentIconName,
  chevron = true,
  onClick,
  children,
  error,
}: React.PropsWithChildren<ChevronItemWithChildrenProps>) => {
  const [expanded, setExpanded] = React.useState(true);

  const handleToggleExpand = () => {
    onClick();
    setExpanded(!expanded);
  };

  const buttonElement = (
    <Button
      appearance='transparent'
      className={`toggle-header ${expanded ? "expanded" : "collapsed"}`}
      onClick={handleToggleExpand}
    >
      <Icon
        name={parentIconName}
        style={{ marginRight: "8px" }}
        color={error ? "Coral" : "black"}
      />
      <span>{name}</span>
      {chevron &&
        (expanded ? (
          <ChevronDownRegular style={{ marginLeft: "auto" }} />
        ) : (
          <ChevronRightRegular style={{ marginLeft: "auto" }} />
        ))}
    </Button>
  );

  return (
    <div key={name + "sub-chevron"}>
      {error ? (
        <Tooltip withArrow relationship='label' content={error}>
          {buttonElement}
        </Tooltip>
      ) : (
        buttonElement
      )}
      <div className={`toggle-content ${expanded ? "expanded" : "collapsed"}`}>
        {expanded && (
          <>
            <div style={{ marginLeft: 20 }}>{children}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChevronItemWithChildren;
