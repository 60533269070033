import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  surface: {
    "& .fui-DialogActions": {
      marginTop: "16px",
    },
  },
  title: {
    "& .Dialog-Subtext": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "0px 0px 24px",
      lineHeight: 1.5,
      overflowWrap: "break-word",
    },
  },
  resize: {
    resize: "both",
    overflow: "auto",
    maxWidth: "90vw",
    maxHeight: "90vh",
    height: "min-content",
    width: "max-content",

    "& .fui-DialogBody": {
      height: "100%",
      maxHeight: "calc(90vh - 50px)",
    },
  },
});
