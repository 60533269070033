import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";
import { makeStyles, Spinner, Tab, TabList, Text } from "@fluentui/react-components";
import { RefreshIcon } from "@fluentui/react-icons-mdl2";
import cn from "classnames";
import type { CSSProperties } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { formatWitHrs } from "../../schema/Utils";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { notification } from "../common/Notification";
import type { Column, Filter } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import { withLoadingPanelHOC } from "../Generic/HOCs";
import { Stack } from "../Stack";
import {
  listAsyncUsersPendingRBAC,
  selectUsersPendingRBAC,
  selectUsersPendingRBACError,
  selectUsersPendingRBACStatus,
} from "../UsersPendingRBAC/reducer";
import { detailsUsersRBAC } from "./api";
import { DetailsDialog } from "./DetailsDialog";
import { DetailsDialogPending } from "./DetailsDialogPending";
import type {
  UserDetails,
  UserSimple,
  UserSimpleWithIssuer,
  WithOutPermissionsUserExtendedProperties,
} from "./models";
import {
  listAsyncUsersRBAC,
  selectUsersRBACError,
  selectUsersRBACPlain,
  selectUsersRBACStatus,
  selectUsersRBACStringFilter,
  setStringFilter,
} from "./reducer";

type ToLoadItem = {
  load: boolean;
  memberId: string;
};

type GetColumnsOpts = {
  t: any;
  isLoading: ToLoadItem;
  setIsLoading: React.Dispatch<React.SetStateAction<ToLoadItem>>;
  onSuccess: (hasError: boolean, displayName: string) => void;
  hasActions: boolean;
  onDetails?: (userDet: UserDetails, usrSim: UserSimple) => void;
  onEdit?: (userDet: UserDetails, usrSim: UserSimple) => void;
  onDetailsPending?: (user: WithOutPermissionsUserExtendedProperties) => void;
};

type UserTabsProps = React.HTMLAttributes<HTMLElement> & {
  issuers: UserSimpleWithIssuer[];
  tableColumns: Column[];
  tableFilters: Filter[];
  isLoading: boolean;
  isError: boolean;
};

const pageStyle: CSSProperties = {
  padding: 8,
  marginBottom: 50,
};

const getIssuerName = (name: string) => {
  if (name.includes("ddp-dev")) return "Email";
  if (name.includes("ddp-uat")) return "Email";
  if (name.includes("ddp-prd")) return "Email";

  if (name.includes("Username-Password-Authentication")) return "{Email}";

  if (name.includes("dalog-dev")) return "OAuth2 DALOG";
  if (name.includes("dalog-uat")) return "OAuth2 DALOG";
  if (name.includes("dalog-prd")) return "OAuth2 DALOG";

  if (name.includes("google-oauth2")) return "OAuth2 Google";

  if (name.includes("ExternalAzureAD")) return "{ExternalAzureAD}";

  if (name.includes("DalogAzureAd")) return "{DalogAzureAd}";

  if (name.includes("https://login.microsoftonline.com/feb1e02d-7cfb-42ff-9911-58dc6756c00e/v2.0"))
    return "{DALOG}";

  if (name.includes("https://login.microsoftonline.com/6037dfc3-a75b-49de-b32c-620396c0239e/v2.0"))
    return "{DALOG}";

  if (name.includes("google.com")) return "{Google}";

  if (name.includes("kingsblue")) return "{Kingsblue}";

  if (name.includes("auth.dev-dexp.net/auth/realms/tk-mincloud")) return "{TK V2}";

  if (name.includes("https://auth.dev-dexp.net/auth/realms/tk-mincloud")) return "{TK V2}";

  if (name.includes("auth.digitalizedexpertise.network/auth/realms/tk-mincloud")) return "{TK V1}";

  return name;
};

const sortByName = (a: UserSimpleWithIssuer, b: UserSimpleWithIssuer) => {
  const aName = getIssuerName(a.name);
  const bName = getIssuerName(b.name);
  if (aName > bName) return 1;
  if (aName < bName) return -1;

  return 0;
};

const getColumns = ({
  t,
  isLoading,
  setIsLoading,
  hasActions,
  onDetails,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 200,
      isSortable: true,
      onRender: (data: UserSimple) => {
        return (
          <Stack horizontal>
            {isLoading.load && isLoading.memberId === data.memberId ? (
              <Spinner size='extra-tiny' />
            ) : null}
            <Link
              to={"./"}
              style={linkStyle}
              onClick={async () => {
                setIsLoading({ load: true, memberId: data.memberId });
                await detailsUsersRBAC(data.memberId ? data.memberId : data.id).then(
                  (resp: UserDetails) => {
                    onDetails(
                      resp.properties
                        ? resp
                        : {
                            ...resp,
                            properties: { ...resp.properties, memberId: data.id },
                          },
                      data,
                    );
                  },
                );
                setIsLoading({ load: false, memberId: data.memberId });
              }}
            >
              {data.name}
            </Link>
          </Stack>
        );
      },
    },
    {
      key: "lastLoginDate",
      name: t("Last login date"),
      fieldName: "lastLoginDate",
      minWidth: 200,
      isSortable: true,
      onRender: ({ lastLoginDate }) => lastLoginDate && formatWitHrs(new Date(lastLoginDate)),
    },
    {
      key: "email",
      name: t("E-mail"),
      fieldName: "email",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "firstName",
      name: t("First Name"),
      fieldName: "firstName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "lastName",
      name: t("Last Name"),
      fieldName: "lastName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "jobTitle",
      name: t("Job Title"),
      fieldName: "jobTitle",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company"),
      fieldName: "companyName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "department",
      name: t("Department"),
      fieldName: "department",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "city",
      name: t("City"),
      fieldName: "city",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "countryOrRegion",
      name: t("Country Or Region"),
      fieldName: "countryOrRegion",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "mobilePhone",
      name: t("Mobile Phone"),
      fieldName: "mobilePhone",
      minWidth: 100,
      isSortable: true,
    },
  ];

  return columns;
};

const getColumnsPending = ({ t, onDetailsPending }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "memberId",
      name: t("Member Id"),
      fieldName: "memberId",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "displayName",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 200,
      isSortable: true,
      onRender: (data: WithOutPermissionsUserExtendedProperties) => (
        <Link
          to={"./"}
          style={linkStyle}
          onClick={() => {
            onDetailsPending(data);
          }}
        >
          {data.displayName}
        </Link>
      ),
    },
    {
      key: "email",
      name: t("Email"),
      fieldName: "email",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "lastName",
      name: t("Last Name"),
      fieldName: "lastName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "firstName",
      name: t("First Name"),
      fieldName: "firstName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "jobTitle",
      name: t("Job Title"),
      fieldName: "jobTitle",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company Name"),
      fieldName: "companyName",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "department",
      name: t("Department"),
      fieldName: "department",
      minWidth: 200,
      isSortable: true,
    },
  ];

  return columns;
};

// const onCopy = (
//   item: WithPutPermissionsUserExtendedProperties,
//   index: number,
//   column: IColumn
// ) => {
//   const pass = item[column.key];
//   return (
//     <>
//       <Stack horizontal key={item.id + column.key}>
//         <TextField value={pass || ""} disabled={true} />
//         <CopyToClipboard
//           text={pass}
//           onCopy={(text) =>
// /            !text &&
//             notification.warning("You are trying to copy an empty string.")
//           }
//         >
//           <Button appearance="transparent" icon={<CopyIcon/>} />

//         </CopyToClipboard>
//       </Stack>
//     </>
//   );
// };

const UsersPivot = ({
  issuers,
  tableColumns,
  tableFilters,
  isLoading,
  isError,
  ...rest
}: UserTabsProps) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = React.useState<TabValue>(
    `issuer-${[...issuers].sort(sortByName)[0].name}`,
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  if (issuers.length === 0) {
    return null;
  }

  return (
    <>
      <TabList
        selectedValue={selectedValue}
        aria-label={t("Pivots of user issuers (Google, DALOG, TK, etc)")}
        style={{
          marginTop: "12px",
          display: "flex",
          justifyContent: "center",
        }}
        onTabSelect={onTabSelect}
      >
        {[...issuers].sort(sortByName).map((issuer) => (
          <Tab key={`tab-issuer-${issuer.name}`} value={`issuer-${issuer.name}`}>
            <Text>{getIssuerName(issuer.name)}</Text>
          </Tab>
        ))}
      </TabList>
      <div>
        {[...issuers].sort(sortByName).map((issuer, index) => (
          <React.Fragment key={`${issuer.name}-${index}`}>
            {selectedValue === `issuer-${issuer.name}` && (
              <Table
                key={`table-issuer-${issuer.name}`}
                persistOpts={{
                  key: "table-usersRBCA",
                  version: 2,
                }}
                header={{
                  title: `${getIssuerName(issuer.name)} ${t("Users")}`,
                }}
                items={issuer.users}
                v8Columns={tableColumns}
                filters={tableFilters}
                isLoading={isLoading}
                isError={isError}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const useStylesUsersRBCA = makeStyles({
  tabs: {
    display: "flex",
    justifyContent: "center",
    columnGap: "2em",
  },
  tabLabel: {
    fontSize: "18px",
    fontWeight: 400,
  },
  tabLabelActive: {
    fontSize: "18px",
    fontWeight: 600,
  },
});

export const UsersRBCA = () => {
  const classes = useStylesUsersRBCA();
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const itemsPending = useAppSelector(selectUsersPendingRBAC);
  const statusPending = useAppSelector(selectUsersPendingRBACStatus);
  const errorPending = useAppSelector(selectUsersPendingRBACError);
  const items = useAppSelector(selectUsersRBACPlain);
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);
  const hasWritePermission = true;
  const stringFilter = useAppSelector(selectUsersRBACStringFilter);
  const [isLoading, setIsLoading] = useState<ToLoadItem>({
    load: false,
    memberId: "",
  });
  const [selected, setSelected] = useState<{
    data?: UserSimple;
    dataDet?: UserDetails;
    dataPending?: WithOutPermissionsUserExtendedProperties;
    context: "details" | "detailsPending";
  } | null>(null);
  const { filters, handleSearch, search } = useTableFilters<UserSimple>({
    keys: [
      "name",
      "memberId",
      "lastName",
      "firstName",
      "email",
      "companyName",
      "city",
      "mobilePhone",
      "lastLoginDate",
    ],
  });

  const selectedTab = searchParams.get("tab") || "users";

  useEffect(() => {
    if (id && items.length > 0) {
      //const sel = items.find((ele) => ele?.ddpProperties?.id === id);
      setIsLoading({ load: true, memberId: id });
      detailsUsersRBAC(id).then((resp) => {
        setIsLoading({ load: false, memberId: id });
        resp.status === 404 ? onNotFound() : onDetails(resp);
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, items]);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  useEffect(() => {
    if (statusPending === Status.error) notification.error(errorPending);
    return () => {};
  }, [errorPending, statusPending]);

  useEffect(() => {
    if (search !== "") dispatch(setStringFilter(search));
  }, [dispatch, search]);

  useEffect(() => {
    handleSearch?.(stringFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringFilter]);

  useEffect(() => {
    dispatch(listAsyncUsersRBAC());
    dispatch(listAsyncUsersPendingRBAC());
  }, [dispatch]);

  // Handlers
  const onNotFound = () => {
    navigate("../users/");
    notification.warning(t("The item does not exist."));
  };

  const onDetails = (userDet: UserDetails) =>
    setSelected({
      data: undefined,
      dataDet: userDet,
      context: "details",
    });

  const onDetailsPending = (user: WithOutPermissionsUserExtendedProperties) =>
    setSelected({
      dataPending: user,
      context: "detailsPending",
    });

  const onSuccess = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = t(`Failed updating {{displayName}}`, { displayName });
      notification.error(message);
    } else {
      dispatch(listAsyncUsersRBAC());
      const message = t(`{{displayName}} updated successfully`, {
        displayName,
      });
      notification.success(message);
    }
  };

  // Table components
  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Users")}</Text>,
    },

    {
      key: "refresh",
      text: t("Refresh"),
      type: CommandBarItemType.Button,
      icon: <RefreshIcon />,
      onClick: () => {
        dispatch(listAsyncUsersRBAC());
        dispatch(listAsyncUsersPendingRBAC());
      },
    },
  ];

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    if (data) {
      navigate(`/users?tab=${data.value}`);
    }
  };

  return (
    <Stack style={pageStyle}>
      <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />
      <div
        style={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {withLoadingPanelHOC(
          t,
          status === Status.idle,
          <>
            <TabList
              selectedValue={selectedTab}
              aria-label={t("Pivots to edit service account")}
              className={classes.tabs}
              onTabSelect={onTabSelect}
            >
              <Tab value='users'>
                <Text
                  className={cn({
                    [classes.tabLabel]: selectedTab !== "users",
                    [classes.tabLabelActive]: selectedTab === "users",
                  })}
                >
                  {t("Users")}
                </Text>
              </Tab>
              <Tab value='pending'>
                <Text
                  className={cn({
                    [classes.tabLabel]: selectedTab !== "pending",
                    [classes.tabLabelActive]: selectedTab === "pending",
                  })}
                >
                  {t("Without Permissions")}
                </Text>
              </Tab>
            </TabList>
            <div>
              {selectedTab === "users" && (
                <UsersPivot
                  issuers={items}
                  tableColumns={getColumns({
                    t,
                    onSuccess: onSuccess,
                    hasActions: hasWritePermission,
                    onDetails: onDetails,
                    isLoading: isLoading,
                    setIsLoading: setIsLoading,
                  })}
                  tableFilters={filters}
                  isLoading={status === Status.loading || !items}
                  isError={status === Status.error}
                  style={{ paddingTop: "1em" }}
                />
              )}
              {selectedTab === "pending" && (
                <Table
                  key={"usersPendingRBCA"}
                  persistOpts={{
                    key: "table-usersPendingRBCA",
                    version: 2,
                  }}
                  header={{
                    title: t("Users without Permissions"),
                  }}
                  items={itemsPending}
                  v8Columns={getColumnsPending({
                    t,
                    onSuccess: onSuccess,
                    hasActions: false,
                    onDetailsPending: onDetailsPending,
                    isLoading: isLoading,
                    setIsLoading: setIsLoading,
                  })}
                  filters={filters}
                  isLoading={itemsPending === undefined}
                  isError={statusPending === Status.error}
                />
              )}
            </div>
          </>,
        )}
        {selected?.context === "details" && (
          <DetailsDialog
            data={selected?.dataDet}
            show={selected?.context === "details"}
            onSuccess={onSuccess}
            onClose={() => {
              setSelected(null);
            }}
          />
        )}
        {selected?.context === "detailsPending" && (
          <DetailsDialogPending
            data={selected?.dataPending}
            show={selected?.context === "detailsPending"}
            onSuccess={onSuccess}
            onClose={() => {
              setSelected(null);
            }}
          />
        )}
      </div>
    </Stack>
  );
};
