import {
  Button,
  makeStyles,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { memo, useEffect, useState } from "react";

import { conditionColor } from "../../../../../../../modules/machine-cv/constants";
import {
  getSlicesDataByKey,
  renderPieChartSlices,
} from "../../Overview/Panels/components/PieChart/utils";
import ClusterList from "./ClusterList";

const useStyles = makeStyles({
  clusterMarker: {
    padding: 0,
    minWidth: "48px",
  },
});

function ClusterMarker({ clusterPoints, clusterProps }) {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { clusterMarker } = useStyles();

  useEffect(() => {
    if (isPopupVisible) {
      setPopupVisible(false);
    }
  }, [clusterProps]);

  const slices = getSlicesDataByKey({
    data: clusterPoints,
    key: "condition",
    colors: conditionColor,
  });

  return (
    <Menu>
      <MenuTrigger>
        <Button
          appearance='transparent'
          className={clusterMarker}
          onClick={() => setPopupVisible(!isPopupVisible)}
        >
          <svg
            style={{ border: "2px solid white", borderRadius: "50%" }}
            id='pieChart'
            viewBox='0 0 200 200'
            xmlns='http://www.w3.org/2000/svg'
          >
            {renderPieChartSlices(slices)}
          </svg>
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <ClusterList clusterPoints={clusterPoints} />
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}

export default memo(ClusterMarker);
