import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Spinner,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { AddRegular, Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkA } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { formatFileSize } from "../../schema/Utils";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import { useTableFilters } from "../common/Table/v9";
import type { TableProps } from "../common/Table/v9/Table";
import Table from "../common/Table/v9/Table";
import type { AcceptedFile } from "../common/Uploader/Uploader";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { metaDataImport } from "../MetaData/api";
import { AddOrEditDialog } from "./AddOrEditDialog";
import { DeleteConfirm } from "./DeleteConfirm";
import type { Corporation } from "./models";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsError,
  selectCorporationsStatus,
} from "./reducer";

// --- Columns ---

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (corporation: Corporation) => void;
  onDelete: (corporation: Corporation) => void;
};

const getColumns = ({ t, hasActions, onEdit, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Corporation) => (
        <LinkA to={id} style={linkStyle}>
          {name}
        </LinkA>
      ),
    },
    {
      key: "number",
      name: t("Number"),
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (corporation: Corporation) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(corporation);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(corporation);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

type GetColumnsFileOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumnsFile = ({ t, onRemove }: GetColumnsFileOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      return (
        <Link href='#' style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => {
      return (
        <span style={{ fontSize: 13, color: item?.isValid ? "currentColor" : "#ff6a66" }}>
          {item?.isValid ? formatFileSize(item?.size) : t("Too large")}
        </span>
      );
    },
  },
];

export const CorporationsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCorporations);
  const status = useAppSelector(selectCorporationsStatus);
  const error = useAppSelector(selectCorporationsError);
  const hasWritePermission = auth.metaDataContributor;
  const [file, setFile] = useState<AcceptedFile>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (status === Status.error && items.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, items.length]);

  const { filters, handleSearch } = useTableFilters<Corporation>({
    keys: ["name", "number"],
  });

  const [selected, setSelected] = useState<{
    data: Corporation | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  useEffect(() => {
    dispatch(listAsyncCorpo());
  }, [dispatch]);

  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (corporation: Corporation) => setSelected({ data: corporation, context: "edit" });

  const onDelete = (corporation: Corporation) =>
    setSelected({ data: corporation, context: "delete" });

  const onImport = (files: AcceptedFile[]) => {
    setFile(files.at(0));
  };

  const getCommandBarItems = (title: string, onChange?: (files: AcceptedFile[]) => void): any => {
    const commandBarItems: any = [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <Text style={titleStyle}>{title}</Text>,
      },
      ...(hasWritePermission
        ? [
            {
              key: "add",
              text: t("Add"),
              type: CommandBarItemType.Button,
              icon: <AddRegular />,
              onClick: onAdd,
            },
            {
              key: "import",
              type: CommandBarItemType.Upload,
              onRenderProps: {
                accept: ".csv",
                multiple: false,
                onChange,
              },
            },
          ]
        : []),
    ];

    return commandBarItems;
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: [file],
      perPage: 5,
      v8Columns: getColumnsFile({
        t,
        onRemove: (fileId) => {
          setFile(undefined);
        },
      }),
    }),
    [file],
  );

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItems(t("Corporation"), onImport))}
        onSearch={handleSearch}
      />

      <Table
        persistOpts={{
          key: "table-corporations",
          version: 2,
        }}
        filters={filters}
        header={{
          title: t("Corporation"),
        }}
        items={items}
        v8Columns={getColumns({
          t,
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        style={{ minWidth: "550px" }}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{corporationName}} corporation`, {
                corporationName: selected?.data?.name,
              }),
            );
          } else {
            dispatch(listAsyncCorpo());
            notification.success(
              t(`{{corpoartionName}} corporation deleted successfully`, {
                corpoartionName: selected?.data?.name,
              }),
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      <AddOrEditDialog
        data={selected?.data}
        items={items}
        show={["add", "edit"].includes(selected?.context)}
        onSuccess={(hasError, data, context) => {
          if (hasError) {
            const message =
              context === "add"
                ? t(`Failed creating {{entity}}`, { entity: data.name })
                : t(`Failed updating {{entity}}`, { entity: data.name });

            notification.error(message);
          } else {
            dispatch(listAsyncCorpo());

            const message =
              context === "add"
                ? t(`{{entity}} created successfully`, { entity: data.name })
                : t(`{{entity}} updated successfully`, { entity: data.name });

            notification.success(message);
          }
        }}
        onClose={() => {
          setSelected(null);
        }}
      />
      <BaseDialog open={Boolean(file)} onOpenChange={() => setSelected(undefined)}>
        <BaseDialogTitle>{t("Import all Metadata")}</BaseDialogTitle>
        <DialogContent>
          <Table {...tableProps} />
          <DialogActions>
            <Button
              appearance='primary'
              disabled={isLoading}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              onClick={() => {
                setLoading(true);
                metaDataImport(file.file).then((res) => {
                  dispatch(listAsyncCorpo);

                  setSelected(undefined);
                  res.status === 200
                    ? notification.success(t("Added data successfully."))
                    : notification.error(`${t("Something went wrong")}.`);
                });
                setLoading(false);
              }}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={() => setFile(undefined)}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    </div>
  );
};
