import type {
  CheckboxProps,
  ComboboxProps,
  SpinButtonProps,
  SwitchProps,
} from "@fluentui/react-components";

import type { BaseFormFieldProps, FormHookProps } from "../types";
import { FormCheckbox } from "./FormCheckbox";
import { FormDatePicker } from "./FormDatePicker";
import { FormDropdown } from "./FormDropdown";
import { FormNumberField } from "./FormNumberField";
import { FormRadio } from "./FormRadio";
import { FormTextArea } from "./FormTextArea";
import { FormTextEditor } from "./FormTextEditor";
import { FormTextField } from "./FormTextField";
import { FormTimePicker } from "./FormTimePicker";
import { FormToggle } from "./FormToggle";

export enum FormItemType {
  TextField = "TEXT_FIELD",
  NumberField = "NUMBER_FIELD",
  TextArea = "TEXT_AREA",
  TextEditor = "TEXT_EDITOR",
  DatePicker = "DATE_PICKER",
  Dropdown = "DROPDOWN",
  Toggle = "TOGGLE",
  Checkbox = "CHECKBOX",
  TimePicker = "TIME_PICKER",
  Radio = "RADIO",
}

type FormFieldProps = Omit<BaseFormFieldProps, "hookProps"> & { name: string };

type FormTextFieldProps = FormFieldProps &
  any & {
    type: FormItemType.TextField | FormItemType.TextArea | FormItemType.TextEditor;
  };

type FormNumberFieldProps = FormFieldProps &
  SpinButtonProps & {
    type: FormItemType.NumberField;
  };

type FormDatePickerProps = any & {
  type: FormItemType.DatePicker;
  name: string;
  defaultValue?: any;
  isRequired?: boolean;
};

type FormTimePickerProps = {
  type: FormItemType.TimePicker;
} & FormFieldProps;

type FormDropdownProps = {
  type: FormItemType.Dropdown;
  options: any;
  renderOption?: ({ key, text }: { key: any; text: string }) => any;
  allowFreeform?: boolean;
  multiSelect?: boolean;
  onChange?: any;
  selectedKey?: any;
} & FormFieldProps &
  ComboboxProps;

type FormToggleProps = {
  type: FormItemType.Toggle;
} & FormFieldProps &
  SwitchProps;

type FormCheckboxProps = {
  type: FormItemType.Checkbox;
} & FormFieldProps &
  CheckboxProps;

type FormRadioProps = {
  type: FormItemType.Radio;
} & FormFieldProps;

export type FormItemProps =
  | FormTextFieldProps
  | FormNumberFieldProps
  | FormDropdownProps
  | FormDatePickerProps
  | FormTimePickerProps
  | FormToggleProps
  | FormCheckboxProps
  | FormRadioProps;

export const renderFormItems = (fields: FormItemProps[], hookProps: FormHookProps) => {
  return fields.map((props: FormItemProps) => {
    switch (props.type) {
      case FormItemType.TextField: {
        const { name, ...rest } = props;
        return <FormTextField key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.NumberField: {
        const { name, ...rest } = props;
        return <FormNumberField key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.DatePicker: {
        // Type not equivalent to TYPE prop in original DatePicker component
        const { name, type, ...rest } = props;
        return <FormDatePicker key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.TextArea: {
        const { name, ...rest } = props;
        return <FormTextArea key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.TextEditor: {
        const { name, ...rest } = props;
        return <FormTextEditor key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.Dropdown: {
        const { name, ...rest } = props;
        return <FormDropdown key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.Toggle: {
        const { name, ...rest } = props;
        return <FormToggle key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.Checkbox: {
        const { name, ...rest } = props;
        return <FormCheckbox key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.Radio: {
        const { name, ...rest } = props;
        return <FormRadio key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      case FormItemType.TimePicker: {
        const { name, ...rest } = props;
        return <FormTimePicker key={name} name={name} hookProps={hookProps} {...rest} />;
      }
      default:
        return null;
    }
  });
};

export const renderFormFieldArrayItems = (
  formFieldsTemplate: FormItemProps[],
  hookProps: FormHookProps,
  name: string,
  index: number | string,
) =>
  renderFormItems(
    formFieldsTemplate.map((formField) => ({
      ...formField,
      name: `${name}.${index}.${formField.name}`,
      // name: 'measurement.0.ranges[0]',
    })),
    hookProps,
  );

export const getDayPickerStrings = (t) => ({
  months: [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ],
  shortMonths: [
    t("Jan"),
    t("Feb"),
    t("Mar"),
    t("Apr"),
    t("May"),
    t("Jun"),
    t("Jul"),
    t("Aug"),
    t("Sep"),
    t("Oct"),
    t("Nov"),
    t("Dec"),
  ],
  days: [
    t("Sunday"),
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  goToToday: t("Go to today"),
  prevMonthAriaLabel: t("Go to previous month"),
  nextMonthAriaLabel: t("Go to next month"),
  prevYearAriaLabel: t("Go to previous year"),
  nextYearAriaLabel: t("Go to next year"),
});
