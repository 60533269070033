import { Spinner } from "@fluentui/react-components";

import React from "react";

import { useSensorDetails } from "../../hooks/useSensorDetails";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";
import SensorTabs from "./SensorTabs";
import { NoData } from "../../../common/NoData";
import { useTranslation } from "react-i18next";

const Sensor = () => {
  const { t } = useTranslation();
  const { sensorNodeId } = useWSUrlParams();
  const { isLoading, sensor } = useSensorDetails({ sensorNodeId });

  return (
    <div className='sensor-container'>
      {isLoading ? (
        <Spinner style={{ marginTop: 30 }} size='small' />
      ) : (
        <>
          {sensor ? (
            <SensorTabs key={sensorNodeId} sensor={sensor} />
          ) : (
            <NoData style={{ marginTop: 30 }} text={t("The sensor was not found")} />
          )}
        </>
      )}
    </div>
  );
};

export default Sensor;
