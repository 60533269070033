import { useEffect, useMemo, useState } from "react";
import { treeHandlers, useTreeState } from "react-hyper-tree";
import { SCROLL_OFFSET_TOP } from "../../../../config/constants";
import {
  useLocationSearch,
  useMachineList,
  useMachineCVBreadcrumb,
  useLastSelectedSignal,
  useLocationQueryParams,
} from "../../../../Hooks";
import { PAGE_TYPE_TREND } from "./TrendViewSelectSignals";
import { breadcrumbDefaultResponse } from "../../../common/services/api/MachineCVBreadcrumb";
import { findTreePath, getTreeData } from "./utils";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export type SearchBy = 'corporation' | 'company' | 'project';
export interface TrenViewSignals{
  search: string;  
  searchBy: SearchBy; 
  changeSearch: (search: string) => void;
  changeSearchBy: (searchBy: SearchBy) => void;
}

const useTrenViewSignalsStore = createWithEqualityFn<TrenViewSignals>()(
  persist(
    (set) => ({
      search: '',      
      searchBy: 'company',
      changeSearch: (search: string) => set({ search }),    
      changeSearchBy: (searchBy: SearchBy) => set({ searchBy }),
    }),
    {
      name: 'trend-view-signals-list-search-store',
      partialize: (state) => ({
        searchBy: state.searchBy,
        search: state.search,
      }),
    },
  ),
  shallow
);

const useTrendViewSelectSignals = () => {
  const [locationData, , isValid] = useLocationSearch();
  const [sensorNodeId, setSensorNodeId]: any = useState();
  const urlSignalId = useLocationQueryParams("signal");
  const { getLastSelectedSignal } = useLastSelectedSignal({
    page: PAGE_TYPE_TREND,
  });
  const lastSelectedSignal = useMemo(() => getLastSelectedSignal(), []);
  const openedMachineId = lastSelectedSignal.machineId;
  const [openedMachineNode, setOpenedMachineNode]: any = useState();
  const {
    data: breadcrumbResponse = breadcrumbDefaultResponse,
    isLoading: isBreadcrumbLoading,
  } = useMachineCVBreadcrumb({
    machineId: locationData.id as string,
    options: { enabled: !!locationData.id, retry: 0 },
  });

  const { corporation, company, project, machine } = breadcrumbResponse;
  const { data: list, isLoading } = useMachineList();
  const {
    search,
    searchBy,    
    changeSearch,
    changeSearchBy,   
  } = useTrenViewSignalsStore();

  const data = useMemo(
    () =>
      list
        ? getTreeData(list, (sensors: any) => {
            if (locationData?.sensorNo && sensors?.length > 0) {
              const sensorId = (
                sensors.filter(
                  (item: any) => item.name === locationData.sensorNo
                )[0] || {}
              ).id;

              setSensorNodeId(sensorId);
            }

            return sensors;
          })
        : [],
    [list]
  );

  const [matchingNodeIds, setMatchingNodeIds] = useState<string[]>([]);
  const filteredData = useMemo(() => {
    if (!data || !search) return data;    
    const searchLower = search.toLowerCase();
    const matchingIds: string[] = [];
    const excludedParentIds: Set<string> = new Set();    
    
    const isNodeExcluded = (nodeId: string) => excludedParentIds.has(nodeId);

    data.forEach((corporation) => {
      corporation.children?.forEach((company) => {
        company.children?.forEach((project) => {
          const projectMatches = project.name.toLowerCase().includes(searchLower);
          if (projectMatches) {
            matchingIds.push(project.id);
            excludedParentIds.add(company.id);
          }
        });
      });
    });
    
    data.forEach((corporation) => {
      corporation.children?.forEach((company) => {
        if (!isNodeExcluded(company.id)) {
          const companyMatches = company.name.toLowerCase().includes(searchLower);
          if (companyMatches) {
            matchingIds.push(company.id);
            excludedParentIds.add(corporation.id);
          }
        }
      });
    });
    
    data.forEach((corporation) => {
      if (!isNodeExcluded(corporation.id)) {
        const corporationMatches = corporation.name.toLowerCase().includes(searchLower);
        if (corporationMatches) {
          matchingIds.push(corporation.id);
        }
      }
    });
    
    setMatchingNodeIds(matchingIds);

    const filteredCorps = data.map(corporation => {

      if (matchingIds.includes(corporation.id)) {
        return corporation;
      }
      const filteredCompanies = corporation.children?.map(company => {

        if (matchingIds.includes(company.id)) {
          return company;
        }

        const filteredProjects = company.children?.filter(project => 
          matchingIds.includes(project.id)
        );

        return filteredProjects && filteredProjects.length > 0
          ? { ...company, children: filteredProjects }
          : null;
      }).filter(Boolean);

      return filteredCompanies && filteredCompanies.length > 0
        ? { ...corporation, children: filteredCompanies }
        : null;
    }).filter(Boolean);
    
    return filteredCorps;
  }, [data, search]);
  

  const defaultOpened = useMemo<string[] | undefined>(
    () =>
      isValid && filteredData.length > 0
        ? findTreePath({
            data:filteredData,
            ...locationData,
            corporation: corporation.name,
            company: company.name,
            project: project.name,
            machine: machine.name,
          })
        : undefined,
    [filteredData, locationData, isValid]
  );

  const { required, handlers, instance } = useTreeState({
    id: "trend-view-signal-tree",
    multipleSelect: true,
    data:filteredData,
    defaultOpened,
  });

  const defaultSelected = useMemo(() => {
    if (isLoading || !defaultOpened || isBreadcrumbLoading) {
      return null;
    }
    const machine = instance.getNodeById(defaultOpened[3]);
    if (!machine || machine.isLoading()) {
      return null;
    }
    return machine;
  }, [isLoading, defaultOpened, instance]);

  useEffect(() => {
    if (matchingNodeIds.length > 0 && handlers && search) { 
      matchingNodeIds.forEach((nodeId) => {
        const node = handlers.getNode(nodeId); 
        if (node) {
          let parentNode = node.getParent();
          while (parentNode) {           
            if(!parentNode.isOpened()) handlers.setOpen(parentNode, true);             
            parentNode = parentNode.getParent(); 
          }
        } 
      });
    } else if (!search && handlers) { 
      const closeAllNodes = (nodes: any[]) => {
        nodes.forEach((node) => {
          if (node.isOpen) {
            handlers.setOpen(node, false);
          }
          if (node.children) {
            closeAllNodes(node.children);
          }
        });
      }; 
      closeAllNodes(instance.data);
    }
  }, [matchingNodeIds, handlers, search, instance]); 

  useEffect(() => {
    if (!defaultSelected?.asyncDataLoaded) {
      return;
    }
    let signalNodeId;
    if (urlSignalId) {
      const treeSignalNode = instance.getNodeById(urlSignalId);
      if (treeSignalNode) {
        signalNodeId = urlSignalId;
        treeSignalNode.getParent()?.setOpened(true);
        treeSignalNode.setOpened(true);
      }
    }
    const container =
      document.querySelector<HTMLDivElement>(".sidebar-scrollbar");
    if (!container) {
      return;
    }
    const node = container.querySelector(
      `[data-node-id="${signalNodeId || defaultSelected.id}"]`
    );
    if (!node) {
      return;
    }
    if (signalNodeId) {
      node.querySelector("button")?.click();
    }
    container.scrollTo({
      behavior: "smooth",
      top: node.getBoundingClientRect().top - SCROLL_OFFSET_TOP,
    });
  }, [defaultSelected?.asyncDataLoaded]);

  useEffect(() => {
    if (sensorNodeId) {
      const sensorNode = handlers.getNode(sensorNodeId);
      if (sensorNode) {
        handlers.setOpen(sensorNode);
      }
    }
  }, [defaultSelected?.asyncDataLoaded, sensorNodeId]);

  // if you navigate from raw data view page: open sidebar to the last selected signal from raw data
  // machine level
  useEffect(() => {
    if (openedMachineId && instance?.data?.length > 0) {
      const machineNode = instance.getNodeById(openedMachineId);

      if (machineNode) {
        treeHandlers.trees["trend-view-signal-tree"].handlers.setOpenByPath(
          machineNode.getPath()
        );
        setOpenedMachineNode(machineNode);
      }
    }
  }, [instance, openedMachineId]);

 
  useEffect(() => {
    if (openedMachineNode?.asyncDataLoaded) {
      const sensorNode = openedMachineNode.children.filter((child: any) => {
        return child.data.name === lastSelectedSignal.sensorNo;
      })[0];

      if (sensorNode) {
        sensorNode?.setOpened(true);
      }
    }
  }, [openedMachineNode, openedMachineNode?.asyncDataLoaded]);

  return {
    required,
    handlers,
    isLoading,
    hasData: !!data?.length,
    searchBy, 
    search, 
    changeSearchBy, 
    changeSearch, 
    };
};

export default useTrendViewSelectSignals;
