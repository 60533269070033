import "./styles.scss";

import { Text } from "@fluentui/react-components";
import classNames from "classnames";
import React from "react";

import { formatDateHuman } from "../../../../schema/Utils";

interface MessageItemProps {
  message: {
    name: string;
    date: string;
    role?: string;
    message: string;
    isMe: boolean;
  };
}

export const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  const firstName = message.name.split(" ")[0];
  const lastName = message.name.split(" ")[1] || "";

  return (
    <div className={classNames("message-item", { "container-active": message.isMe })}>
      <div className={"userSection"}>
        <div className={"userInfo"}>
          <div className={classNames("avatar", { "avatar-active": message.isMe })}>
            <Text className={"initials"}>
              {firstName[0]?.toUpperCase()}
              {lastName[0]?.toUpperCase()}
            </Text>
          </div>
          <div className={"nameDate"}>
            <Text className={"name"}>{message.name}</Text>
            <Text className={"date"}>{formatDateHuman(new Date(message.date), false)}</Text>
          </div>
        </div>
        <div className={classNames("role", { "role-active": message.isMe })}>
          <Text>{message.role}</Text>
        </div>
      </div>
      <Text className={classNames("message", { "message-active": message.isMe })}>
        {message.message}
      </Text>
    </div>
  );
};
