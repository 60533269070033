import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import type { TableProps } from "../../../../common/Table/v9";
import Table from "../../../../common/Table/v9";
import { useMachineSensorsProfiles } from "../../../hooks/useMachineSensorsProfiles";
import { getColumns } from "../../Gateway/columns";

const TriggerStatusTab = () => {
  const { t } = useTranslation();
  const machineId = useParams()?.machineId || "";
  const { data: sensorsProfiles, isLoading, isError } = useMachineSensorsProfiles({ machineId });

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "machine-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: t("Machine Sensor Trigger Status"),
      },
      columns: getColumns(t),
      items: sensorsProfiles || [],
    }),
    [isLoading, isError, sensorsProfiles, t],
  );

  return <Table {...tableProps} />;
};

export default TriggerStatusTab;
