import type { TableColumnDefinition } from "@fluentui/react-components";
import { useEffect, useState } from "react";

interface UseReorderingOpts {
  columns: TableColumnDefinition<any>[];
  persistOpts: {
    key: string;
    version: number;
  };
}

function getColumnsOrder(columns, order) {
  return order.map((id) => columns.find(({ columnId }) => columnId === id));
}

const useReordering = ({ columns, persistOpts }: UseReorderingOpts) => {
  const storageKey = `${persistOpts.key}-${persistOpts.version}-reordering`;
  const [currentColumns, setCurrentColumns] = useState([]);

  useEffect(() => {
    const columnOrder = JSON.parse(localStorage.getItem(storageKey));

    if (!columnOrder) {
      const order = columns.map(({ columnId }) => columnId);
      localStorage.setItem(storageKey, JSON.stringify(order));
      setCurrentColumns(columns);
    } else {
      const newColumns = getColumnsOrder(columns, columnOrder);
      setCurrentColumns(newColumns);
    }
  }, [persistOpts.key, persistOpts.version, storageKey]);

  useEffect(() => {
    const columnOrder = JSON.parse(localStorage.getItem(storageKey));

    if (!!columnOrder?.length && columns.length !== columnOrder?.length) {
      const order = columns.map(({ columnId }) => columnId);
      localStorage.setItem(storageKey, JSON.stringify(order));
      setCurrentColumns(columns);
    }
  }, [columns.length, storageKey]);

  const handleDragStart = (e, columnIndex) => {
    e.dataTransfer.setData("columnIndex", columnIndex);
  };

  const allowDrop = (e, columnIndex) => {
    e.preventDefault();
  };

  const handleDragOver = (e, columnIndex) => {
    e.preventDefault();

    const dragIndex = e.dataTransfer.getData("columnIndex");

    if (dragIndex !== columnIndex) {
      const newColumns = [...currentColumns];
      const [draggedColumn] = newColumns.splice(dragIndex, 1);
      newColumns.splice(columnIndex, 0, draggedColumn);

      const newOrder = newColumns.map((col) => col.columnId);

      localStorage.setItem(storageKey, JSON.stringify(newOrder));
      setCurrentColumns(newColumns);
    }
  };

  const getColumnIndex = (column: TableColumnDefinition<any>) => {
    return currentColumns.findIndex((col) => col.columnId === column.columnId);
  };

  return {
    orderedColumns: currentColumns,
    getColumnIndex,
    handleDragStart,
    allowDrop,
    handleDragOver,
  };
};

export default useReordering;
