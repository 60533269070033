import { Globe20Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Dropdown, { useDropdownStyles } from "../common/Dropdown";

export default function LanguageMenu() {
  const { i18n } = useTranslation();
  const { transparent } = useDropdownStyles();
  const currentLanguage = i18n.resolvedLanguage || "en";

  const [langKey, setLangKey] = useState(currentLanguage);

  const options = [
    {
      key: "en",
      data: "EN",
      text: "English (EN)",
    },
    {
      key: "es",
      data: "ES",
      text: "Español (ES)",
    },
    {
      key: "de",
      data: "DE",
      text: "Deutsch (DE)",
    },
    {
      key: "fr",
      data: "FR",
      text: "Français (FR)",
    },
    {
      key: "pt",
      data: "PT",
      text: "Português (PT)",
    },
  ];

  const selectedOption = options.find(({ key }) => key === langKey);

  return (
    <Dropdown
      button={
        <div style={{ display: "flex", gap: 4, color: "#fff", alignItems: "center" }}>
          <Globe20Regular />
          {selectedOption.data}
        </div>
      }
      positioning='below-end'
      className={transparent}
      selectedOptions={[selectedOption.data]}
      expandIcon={null}
      options={options}
      onOptionSelect={(_, option) => {
        i18n.changeLanguage(option.optionValue as string);
        setLangKey(option.optionValue as string);
      }}
    />
  );
}
