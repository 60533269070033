import { Tooltip } from "@fluentui/react-components";
import { Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch } from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import type { Entry, Group } from "../../../../../types";
import { Stack } from "../../../../Stack";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import { FormType } from "../CommandBar";
import EntryDialog from "../dialogs/EntryDialog/EntryDialog";

interface ActionButtonsProps {
  item: Entry;
  group: Group;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, group }) => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const [isDialogHidden, setIsDialogHidden] = useState(true);
  const toggleIsDialogHidden = () => setIsDialogHidden(!isDialogHidden);

  const [isDeleteEntryDialogHidden, setIsDeleteEntryDialogHidden] = useState(true);
  const toggleIsDeleteEntryDialogHidden = () =>
    setIsDeleteEntryDialogHidden(!isDeleteEntryDialogHidden);

  return (
    <Stack horizontal>
      <Tooltip withArrow relationship='label' content={t("Edit entry")}>
        <Edit20Regular
          style={{ color: "#2c529f", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            toggleIsDialogHidden();
          }}
        />
      </Tooltip>
      <Tooltip withArrow relationship='label' content={t("Delete entry")}>
        <Delete20Regular
          style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
          onClick={(e) => {
            e.stopPropagation();
            toggleIsDeleteEntryDialogHidden();
          }}
        />
      </Tooltip>
      <EntryDialog
        open={!isDialogHidden}
        formType={FormType.Edit}
        entry={item}
        group={group}
        onDismiss={toggleIsDialogHidden}
      />
      <DeleteDialog
        open={!isDeleteEntryDialogHidden}
        data={{ ids: [item.id as string], name: item.name }}
        route={"meta/machinecv/v1/infos"}
        onDismiss={toggleIsDeleteEntryDialogHidden}
        onSuccess={refetch}
      />
    </Stack>
  );
};

export default ActionButtons;
