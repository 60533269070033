import type { UserNotificationState } from "./EditUserNotificationPage";
import type {
  EntitiesTree,
  TreeNode,
  UserNotificationSelectedSettings,
  UserNotificationSettings,
} from "./models";
import { UserNotificationSettingsType } from "./models";

/**
 * Gets the user notification settings type key.
 * @param type The user notification settings type
 * @returns The key string.
 */
export const getUserNotificationSettingsTypeKey = (type: UserNotificationSettingsType): string => {
  const index: number = Object.values(UserNotificationSettingsType).indexOf(type);
  if (index < 0) {
    return "";
  }

  return Object.keys(UserNotificationSettingsType)[index];
};

/**
 * Builds the user notification settings object.
 * @param emailsState The user emails notifications state.
 * @param teamsState The user Teams notifications state.
 * @returns The user notification settings.
 */
export const buildUserNotificationSettings = (
  emailsState: UserNotificationState,
  teamsState: UserNotificationState,
): UserNotificationSettings => {
  const result: UserNotificationSettings = {
    email: emailsState.key,
    emailSelected: null,
    teams: teamsState.key,
    teamsSelected: null,
  };

  // Builds the selected settings.
  const emailSettingsType = UserNotificationSettingsType[emailsState.key];
  if (emailSettingsType === UserNotificationSettingsType.Selected) {
    result.emailSelected = getUserSelectedSettings(emailsState.tree);
  }

  const teamsSettingsType = UserNotificationSettingsType[teamsState.key];
  if (teamsSettingsType === UserNotificationSettingsType.Selected) {
    result.teamsSelected = getUserSelectedSettings(teamsState.tree);
  }

  return result;
};

/**
 * Method to compare tree nodes
 * @param a The tree node A.
 * @param b The tree node B.
 * @returns -1, 0 or 1.
 */
export const compareTreeNodes = <T = any>(a: TreeNode<T>, b: TreeNode<T>) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }

  return 0;
};

/**
 * Gets the user notifications selected settings.
 * @param tree The entities tree.
 * @returns The user notification selected
 */
const getUserSelectedSettings = (tree: EntitiesTree): UserNotificationSelectedSettings => {
  const result: UserNotificationSelectedSettings = {
    corporationIds: [],
    companyIds: [],
    projectIds: [],
    machineIds: [],
  };

  result.corporationIds = Array.from(tree.corporations.values())
    .filter((n) => n.value)
    .map((n) => n.id);

  result.companyIds = Array.from(tree.getCompanies().values())
    .filter((n) => n.value && !result.corporationIds.includes(n.parent.id))
    .map((n) => n.id);

  result.projectIds = Array.from(tree.getProjects().values())
    .filter(
      (n) =>
        n.value &&
        !result.companyIds.includes(n.parent.id) &&
        !result.corporationIds.includes(n.parent.parent.id),
    )
    .map((n) => n.id);

  result.machineIds = Array.from(tree.getMachines().values())
    .filter(
      (n) =>
        n.value &&
        !result.projectIds.includes(n.parent.id) &&
        !result.companyIds.includes(n.parent.parent.id) &&
        !result.corporationIds.includes(n.parent.parent.parent.id),
    )
    .map((n) => n.id);

  return result;
};
