import {
  Button,
  DialogActions,
  DialogContent,
  Label,
  SpinButton,
} from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle } from "../../../../Components/common/Dialog";
import useWaterfallStore from "../../hooks/useWaterfallStore";

const SPIN_BUTTON_LIMITS = {
  min: 1,
  max: 1000,
};

interface ReductionDialogProps {
  hideDialog: boolean;
  toggleDialog: () => void;
}

const ReductionDialog = ({ hideDialog, toggleDialog }: ReductionDialogProps) => {
  const { t } = useTranslation();

  const { reduction, setReduction } = useWaterfallStore((state) => ({
    reduction: state.reduction,
    setReduction: state.setReduction,
  }));

  const [value, setValue] = useState(reduction);

  const onSetReduction = (redutionValue) => {
    setReduction(redutionValue);
    toggleDialog();
  };

  function onInputChange(event, data) {
    let inputValue = data?.value;

    if (!data?.value) {
      inputValue = Number(data.displayValue) || 1;
    }

    if (Number(data.displayValue) >= SPIN_BUTTON_LIMITS.max) {
      inputValue = SPIN_BUTTON_LIMITS.max;
    }

    if (Number(data.displayValue) <= SPIN_BUTTON_LIMITS.min) {
      inputValue = SPIN_BUTTON_LIMITS.min;
    }

    setValue(inputValue);
  }
  return (
    <BaseDialog surfaceStyle={{ width: 310 }} open={!hideDialog} onOpenChange={toggleDialog}>
      <BaseDialogTitle style={{ display: "flex", flexDirection: "column" }}>
        <div>{t("Reduction")}</div>
        <p className='Dialog-Subtext'>{t("Please enter the reduction amount.")}</p>
      </BaseDialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Label htmlFor='reduction-amount'>{t("Reduction amount")}</Label>
          <SpinButton
            id='reduction-amount'
            style={{ width: 84 }}
            value={value}
            min={SPIN_BUTTON_LIMITS.min}
            max={SPIN_BUTTON_LIMITS.max}
            step={1}
            onChange={onInputChange}
          />
        </div>

        <DialogActions>
          <Button appearance='primary' onClick={() => onSetReduction(value)}>
            {t("Set reduction")}
          </Button>
          <Button onClick={toggleDialog}>{t("Cancel")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default ReductionDialog;
