/* eslint-disable react-hooks/exhaustive-deps */

import { Button, DialogActions, DialogContent, Tooltip } from "@fluentui/react-components";
import { InfoRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import type { Column } from "../../../common/Table/v9";
import V9Table from "../../../common/Table/v9";
import { VpnConnectionsAPI } from "../../Schema/api";
import type {
  ConnectionTableItem,
  HistoricalChangesItemBasic,
  HistoricalChangesItemDetailed,
} from "../../Schema/models";
import { Utils } from "../../Utils/utils";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { withLoadingPanelHOC } from "../generic/HOCs";
import LoadingPanel from "../generic/LoadingPanel";
import HistoricalChangesDetailsDialog from "./HistoricalChangesDetailsDialog";

type HistoricalChangesTableItem = {
  id?: string;
  date: string;
  time: string;
  changedBy: string;
};

type HistoricalChangesDialogProps = {
  open?: boolean;
  item: ConnectionTableItem;
  onClose: () => void;
};

/**
 * Gets the connection historical changes table columns.
 * @param onDetails Method called when the details action button is clicked.
 * @returns The IColumns list.
 */
const getColumns = (t, onDetails: (id: string) => void): Column[] => {
  const dataColumns: Column[] = [
    {
      name: t("Date"),
      fieldName: "date",
      key: "date",
      minWidth: 150,
      isSortable: true,
    },
    {
      name: t("Time"),
      fieldName: "time",
      key: "time",
      minWidth: 100,
      isSortable: true,
    },
    {
      name: t("Changed By"),
      fieldName: "changedBy",
      key: "changedBy",
      minWidth: 150,
      isSortable: true,
    },
  ];

  const actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 100,
    onRender: (item: HistoricalChangesTableItem) => (
      <Tooltip withArrow relationship='label' content={t("Show changes")}>
        <Button
          key={item.id + "show"}
          appearance='transparent'
          className='table-icon-button'
          icon={<InfoRegular />}
          onClick={(e) => {
            e.stopPropagation();
            onDetails(item.id!);
          }}
        />
      </Tooltip>
    ),
  };

  return [...dataColumns, actionsColumn];
};

/**
 * Gets the table items, from the historical changes basic items list.
 * @param items the historical changes basic items list.
 * @returns The connection historical changes table items list.
 */
const getTableItems = (items: HistoricalChangesItemBasic[]): HistoricalChangesTableItem[] => {
  const sortedItems = items.sort((a, b) => {
    if (a.timeStamp < b.timeStamp) {
      return 1;
    }

    if (b.timeStamp < a.timeStamp) {
      return -1;
    }

    return 0;
  });

  const result: HistoricalChangesTableItem[] = sortedItems.map((item) => {
    const timeStamp = new Date(item.timeStamp);
    return {
      id: item.id,
      date: Utils.getDate(timeStamp),
      time: Utils.getTime(timeStamp),
      changedBy: item.changedBy || "",
    };
  });

  return result;
};

/**
 * The historical changes dialog.
 * @param item The connection table item.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The historical changes dialog component.
 */
const HistoricalChangesListDialog = ({ open, item, onClose }: HistoricalChangesDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<HistoricalChangesItemDetailed | null>(null);
  const [tableItems, setTableItems] = useState<HistoricalChangesTableItem[]>([]);

  // Gets the historical changes entries.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.listHistory(axiosInstance, item.id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the historical changes list: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        onClose?.();
        return;
      }

      setTableItems(getTableItems(response.data));
    });
  }, [item]);

  // Handlers.
  const onDetailsHandler = async (id: string) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    const response = await VpnConnectionsAPI.getHistory(axiosInstance, item.id, id);

    setIsLoading(false);
    if (response.status !== 200) {
      notification.error(
        t(`Failure getting the historical changes item: {{statusText}}.`, {
          statusText: response.statusText,
        }),
      );
      return;
    }

    setSelectedItem(response.data as HistoricalChangesItemDetailed);
  };

  // Sets the component to show.
  let result: JSX.Element | null = null;
  if (isLoading) {
    result = <LoadingPanel />;
  } else if (selectedItem) {
    result = (
      <HistoricalChangesDetailsDialog item={selectedItem} onClose={() => setSelectedItem(null)} />
    );
  } else {
    result = (
      <BaseDialog open={open} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onClose}>
        <BaseDialogTitle>{t("Historical Changes")}</BaseDialogTitle>

        <DialogContent style={{ overflow: "hidden" }}>
          {withLoadingPanelHOC(
            t,
            tableItems,
            <V9Table
              className='box-shadow-panel'
              persistOpts={{
                key: `table-${item.projectName}-historical-changes`,
                version: 2,
              }}
              header={{
                title: `${Utils.firstToUpperCase(item.projectName)} changes`,
              }}
              items={tableItems}
              v8Columns={getColumns(t, onDetailsHandler)}
            />,
          )}

          <DialogActions>
            <Button appearance='primary' onClick={onClose}>
              {t("Done")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    );
  }

  return result;
};

export default HistoricalChangesListDialog;
