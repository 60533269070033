import { makeStyles } from "@fluentui/react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { TableProps } from "../../../../common/Table/v9";
import Table from "../../../../common/Table/v9";
import { useGatewaySensorsProfiles } from "../../../hooks/useGatewaySensorsProfiles";
import { getColumns } from "../columns";

const useStyles = makeStyles({
  root: {
    "& .ms-DetailsHeader": { margin: "0px !important" },
  },
});

const TriggerStatusTab = ({ gateway }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const gatewayId = gateway?.serialNumber;
  const { data: sensorsProfiles, isLoading, isError } = useGatewaySensorsProfiles({ gatewayId });

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "gateway-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: t("Gateway Sensor Trigger Status"),
      },
      v8Columns: getColumns(t),
      items: sensorsProfiles,
    }),
    [isLoading, isError, sensorsProfiles, t],
  );
  return (
    <>
      <Table {...tableProps} className={classes.root} />
    </>
  );
};

export default TriggerStatusTab;
