import { AddIcon } from "@fluentui/react-icons-mdl2";
import { useTranslation } from "react-i18next";

import BaseCommandBar from "../../../common/CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "../../../common/CommandBar/methods";

type ReportsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
};

export const CommandBar = ({ handleSearch, onAdd }: ReportsCommandBarProps) => {
  const { t } = useTranslation();

  const items = [
    {
      key: "createReport",
      text: t("Create alert"),
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      icon: <AddIcon />,
      onClick: onAdd,
    },
  ];

  return <BaseCommandBar items={computeCommandBarItems(items)} onSearch={handleSearch} />;
};
