import "./styles.scss";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTrigger,
  makeStyles,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { ArrowSyncRegular } from "@fluentui/react-icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../Components/common/Dialog";
import { Stack } from "../../../../Components/Stack";
import AxisSelector from "./components/AxisSelector";
import SyncSettings from "./components/SyncSettings";
import YAxisSettings from "./components/YAxisSettings";
import useLayoutSettingsStore from "./useLayoutSettingsStore";

const useStyles = makeStyles({
  syncButton: {
    minWidth: "0px",
    padding: "6px",

    ":hover": {
      background: "rgb(243, 242, 241)",
    },
  },
  syncButtonIcon: { fontSize: "20px", color: "rgb(44, 82, 159)" },
});

const HorizontalLine = () => <div className='horizontal-line' />;

const ChartLayoutModal = ({ series, yAxes }: any) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { isChartLayoutOpen, resetSettings, updateStore } = useLayoutSettingsStore(
    (store: any) => ({
      isChartLayoutOpen: store.isChartLayoutOpen,
      resetSettings: store.resetSettings,
      updateStore: store.updateStore,
    }),
  );

  useEffect(() => {
    if (yAxes?.length > 0) {
      const defaultYAxesSettings = yAxes.reduce((acc: any, _: any, index: number) => {
        acc[index] = {
          factor: 0,
          offset: 0,
        };
        return acc;
      }, {});

      updateStore({
        yAxes,
        yAxesSettings: defaultYAxesSettings,
      });
    }

    if (series?.length > 0) {
      updateStore({
        seriesNames: series.map((line: any) => line.get("name")),
      });
    }
  }, [yAxes?.length, series?.length]);

  function closeModal() {
    updateStore({ isChartLayoutOpen: false });
  }

  return (
    <BaseDialog
      isDraggble={true}
      open={isChartLayoutOpen}
      modalType='non-modal'
      surfaceStyle={{ maxWidth: DialogSize.S }}
      onOpenChange={closeModal}
    >
      <BaseDialogTitle>{t("Chart Layout")}</BaseDialogTitle>

      <DialogContent>
        {yAxes ? (
          <Stack className='chart-layout-modal-content'>
            <Stack className='section'>
              <div className='section-title'>{t("Selector")}</div>
              <div className='section-description'>{t("Pick the line you want to setup")}</div>
              <AxisSelector />
            </Stack>

            <HorizontalLine />

            <Stack className='section'>
              <div className='section-title'>{t("Sync Settings")}</div>
              <div className='section-description'>
                {t("Select an option from the list to assign to Y-Axis")}
              </div>
              <SyncSettings />
            </Stack>

            <HorizontalLine />

            <Stack className='section'>
              <div className='section-title'>
                {t("Y-Axis settings")}{" "}
                <Tooltip
                  withArrow
                  relationship='label'
                  content={<div>{t("Reset current axis settings")}</div>}
                  positioning='above'
                >
                  <Button
                    appearance='transparent'
                    className={classes.syncButton}
                    onClick={() => resetSettings()}
                  >
                    <ArrowSyncRegular className={classes.syncButtonIcon} />
                  </Button>
                </Tooltip>
              </div>
              <YAxisSettings />
            </Stack>
          </Stack>
        ) : (
          <Spinner size='extra-small' />
        )}

        <DialogActions>
          <DialogTrigger>
            <Button appearance='primary'>{t("Close")}</Button>
          </DialogTrigger>
          <Button appearance='transparent' onClick={() => resetSettings("all")}>
            {t("Reset all changes")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default ChartLayoutModal;
