import { makeStyles, tokens } from "@fluentui/react-components";

const useTableStyles = makeStyles({
  root: {},
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    background: "#fff",
  },
  selectionCell: {
    "&.is-disabled": {
      pointerEvents: "none",
      opacity: "0.5 !important",

      "& .fui-Checkbox__indicator": {
        background: "rgb(192 192 192)",

        "& svg": {
          display: "none",
        },
      },
    },
  },
  headerCell: {
    padding: "4px 12px",
    fontWeight: 600,
    alignItems: "center",

    cursor: "pointer", // Because of re-order
    "&.is-sortable": {
      cursor: "pointer",

      "&:hover": {
        background: tokens.colorSubtleBackgroundHover,
      },
    },

    // maxWidth: "max-content !important",

    "&.is-flex-grow": {
      maxWidth: "100% !important",
      flexGrow: 1,
    },
  },
  cell: {
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "rgb(96, 94, 92)",
    fontSize: "12px",

    // maxWidth: "max-content !important",

    "&.is-flex-grow": {
      maxWidth: "100% !important",
      flexGrow: 1,
    },
  },
  cellShell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

export default useTableStyles;
