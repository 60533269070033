/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { notification } from "../../../common/Notification";
import DeleteDialog from "../../../Generic/DeleteDialog";
import { VpnConnectionsAPI } from "../../Schema/api";
import type { ConnectionTableItem } from "../../Schema/models";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";

type DeleteConnectionDialogProps = {
  open?: boolean;
  item: ConnectionTableItem;
  onClose: (listChanged?: boolean) => void;
};

/**
 * Gets the Delete dialog component.
 * @param item The selected VPN Connection detailed item.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The delete dialog component.
 */
const DeleteConnectionDialog = ({ open, item, onClose }: DeleteConnectionDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const onSubmitHandler = (id: string) => {
    if (!axiosInstance) {
      return;
    }

    // Sends the request.
    setIsLoading(true);
    VpnConnectionsAPI.delete(axiosInstance, id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure deleting VPN connection item: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success deleting VPN connection item."));
      onClose?.(true);
    });
  };

  return (
    <DeleteDialog
      open={open}
      id={item.id!}
      identifier={"VPN Connection entry"}
      propertyName={"project name"}
      referenceString={item.projectName}
      isLoading={isLoading}
      onDelete={onSubmitHandler}
      onClose={onClose}
    />
  );
};

export default DeleteConnectionDialog;
