import "./index.css";
import "./assets/styles/web-analysis.scss";
import "./i18n";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { t } from "i18next";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import type { ErrorState } from "./schema/reducer";
import { store } from "./store";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL!,
  headers: { "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY! },
  timeout: 1000 * 60 * 10,
});

class AuthInterceptor {
  private getToken: any = undefined;

  constructor() {
    this.intercept = this.intercept.bind(this);
    this.setAuthGetter = this.setAuthGetter.bind(this);
  }

  setAuthGetter(getToken) {
    this.getToken = getToken;
  }

  async intercept(config) {
    if (!this.getToken) {
      return config;
    }

    try {
      const token = await this.getToken({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        },
      });
      config.headers["Authorization"] = `Bearer ${token}`;
    } catch (e) {
      console.log("AuthInterceptor", e);
    }
    return config;
  }
}

export const authInterceptor = new AuthInterceptor();
axiosInstance.interceptors.request.use(authInterceptor.intercept);

axiosInstance.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response ? error.response.status : null;
  const errorObj: ErrorState = {
    show: true,
    error: error.response.statusText,
    status: error.response.status,
    text: error.response.data,
  };

  if (statusCode === 401) {
    return errorObj;
  }

  if (statusCode === 403) {
    return errorObj;
  }

  if (statusCode === 404) {
    return errorObj;
  }

  if (statusCode >= 500) {
    return {
      show: true,
      error: t("Oops, something is not right"),
      status: t("Error at request"),
      text: t("Please try again later."),
    };
  }

  if (statusCode === 400) {
    return errorObj;
  }

  return Promise.reject(error);
});

function AuthInject() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    authInterceptor.setAuthGetter(getAccessTokenSilently);
    return () => authInterceptor.setAuthGetter(undefined);
  }, [getAccessTokenSilently]);

  return null;
}

const AppProvider = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <AuthInject />
          <App />
        </Auth0Provider>
      </Provider>
    </BrowserRouter>
  );
};

const rootContainer = document.getElementById("root");
const root = ReactDOM.createRoot(rootContainer);

root.render(<AppProvider />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
