import type { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
  contentWrapper: {
    width: "100%",
  },
  refContainer: {
    width: "100%",
    padding: 5,
    background: "#FAF9F8",
  },
  contentContainer: {
    height: "auto",
    background: "#ffffff",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.13)",
    borderRadius: 2,
    // marginBottom: '1rem',
    padding: 16,
  },
  checkboxTreeWrapper: {
    flexDirection: "column",
    height: "100%",
  },
  checkboxTreeContainer: {
    background: "#FAF9F8",
    overflowY: "auto",
    height: "100%",
  },
};

export default styles;
