import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";

import { File } from "../../../../common/Preview";
import type { Column } from "../../../../common/Table/v9";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumns = ({ t, onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ description }) => {
      return <span style={{ fontSize: 13 }}>{description}</span>;
    },
  },
  {
    key: "dateAdded",
    fieldName: "dateAdded",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ dateAdded }) => {
      return <span style={{ fontSize: 13 }}>{dateAdded}</span>;
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 68,
    maxWidth: 68,
    onRender: ({ id }) => (
      <Tooltip withArrow relationship='label' content={t("Delete")}>
        <Button
          appearance='transparent'
          icon={<DeleteRegular />}
          aria-label={t("Delete")}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(id);
          }}
        />
      </Tooltip>
    ),
  },
];
