import { Tooltip } from "@fluentui/react-components";
import * as ReactRouterDOM from "react-router-dom";

import AddDocumentIcon from "../../../../../assets/svg/AddDocumentIcon";
import AddTaskIcon from "../../../../../assets/svg/AddTaskIcon";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";
import type { TableColumn } from "../../../../common/Table/v9/columns";

export interface MachineCvSensor {
  machineId: string;
  sensorDescription: string;
  sensorNo: string;
  id: string;
  mountingType: string;
  mountingDirection: string;
  sensorSerialNo: string;
}

export interface ListProps {
  listItems: MachineCvSensor[];
  onAddTask: () => void;
  onAddDocument: () => void;
}

interface Column {
  t: any;
  search: string;
  onAddDocument: () => void;
  onAddTask: () => void;
}

const Action = ({ id, name, link, icon, onClick }: ReturnType<typeof getActions>[0]) => {
  const action = onClick ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      tabIndex={0}
      role='button'
      className='router-link'
      style={{ margin: "0 5px", cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {icon}
    </div>
  ) : (
    <ReactRouterDOM.Link to={link ?? ""} className='router-link' style={{ margin: "0 5px" }}>
      {icon}
    </ReactRouterDOM.Link>
  );

  return (
    <Tooltip key={id} withArrow content={name} relationship='label'>
      {action}
    </Tooltip>
  );
};

const getActions = ({
  t,
  onAddTask,
  onAddDocument,
  search,
  sensorNo,
}: Pick<ListProps, "onAddDocument" | "onAddTask"> & {
  t;
  search: string;
  sensorNo: string;
}) => {
  return [
    {
      id: "add_task",
      name: t("Add task"),
      icon: <AddTaskIcon />,
      onClick: onAddTask,
    },
    {
      id: "add_document",
      name: t("Upload document"),
      icon: <AddDocumentIcon />,
      onClick: onAddDocument,
    },
    {
      id: "trend",
      name: t("Trend"),
      icon: <TrendsIcon fill='#2c529f' />,
      link: `/trend-view${search}&sensorNo=${sensorNo}`,
    },
    {
      id: "raw_data",
      name: t("Raw data"),
      icon: <RawDataIcon />,
      link: `/raw-data${search}&sensorNo=${sensorNo}`,
    },
  ];
};

const compare: TableColumn["compare"] = (a, b) => {
  const stringA = String(a);
  const stringB = String(b);

  return stringA.localeCompare(stringB, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const getColumns = ({ t, onAddDocument, onAddTask, search }: Column) => {
  return [
    {
      key: "sensorNo",
      fieldName: "sensorNo",
      name: t("Sensor No"),
      calculatedWidth: 0,
      minWidth: 100,
      isSortable: true,
      compare,
    },
    {
      key: "sensorDescription",
      fieldName: "sensorDescription",
      name: t("Sensor Description"),
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "mountingType",
      fieldName: "mountingType",
      name: t("Sensor Mounting Type"),
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "mountingDirection",
      fieldName: "mountingDirection",
      name: t("Sensor Mounting Direction"),
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "sensorSerialNo",
      fieldName: "sensorSerialNo",
      name: t("Sensor Serial No"),
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "actions",
      fieldName: "actions",
      name: t("Actions"),
      minWidth: 120,
      onRender: (item: MachineCvSensor) => {
        const actions = getActions({
          t,
          onAddDocument,
          onAddTask,
          search,
          sensorNo: item?.sensorNo || t("No sensor"),
        });
        return (
          <div style={{ display: "flex" }}>
            {actions.map((action) => (
              <Action key={action.id} {...action} />
            ))}
          </div>
        );
      },
    },
  ];
};
