import { useMemo, useState } from "react";

import type { TableProps } from "./Table";

type TableFilters = TableProps["filters"];

export type UseTableFilters = {
  search: string;
  filters: TableFilters;
  handleSearch: (value: string) => void;
};

export type UseTableFiltersProps<T> = {
  keys: (keyof T)[];
};

export const useTableFilters = <T>({ keys }: UseTableFiltersProps<T>): UseTableFilters => {
  const [search, setSearch] = useState("");

  const handleSearch = (value: string) => setSearch(value);

  const filters = useMemo<TableFilters>(() => {
    const filters: TableFilters = [];

    if (search.length > 0) {
      filters.push(
        ...keys.map(
          (field) =>
            ({
              key: field,
              search,
              operator: "or",
            }) as NonNullable<TableFilters>[0],
        ),
      );
    }

    return filters;
  }, [search]);

  return {
    search,
    filters,
    handleSearch,
  };
};
