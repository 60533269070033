import { Button, Text } from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import ReferenceIcon from "../../../../assets/svg/ReferenceIcon";
import SignalsAutocomplete from "../../../common/components/SignalsAutocomplete";
import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import { buttonStyle, referenceTextStyles, signalAutocompleteStyles } from "./TrendView.styles";
import useTrendViewControls from "./useTrendViewControls";

function CustomTrigger({ onClick, text, isReferenceSignalDisabled }) {
  const { t } = useTranslation();

  return (
    <Button
      icon={<ChevronDownRegular />}
      iconPosition='after'
      style={{ padding: "5px 0px" }}
      appearance='transparent'
      disabled={isReferenceSignalDisabled}
      onClick={onClick}
    >
      <span style={{ fontWeight: "normal" }}>{t("Reference")}</span>
      <Text style={referenceTextStyles}>{text || t("None")}</Text>
    </Button>
  );
}

const ReferenceSignalDropdown = () => {
  const {
    data,
    controlsStore,
    selectedSignalsStore: { selectedSignals },
  } = useTrendViewControls();
  const { t } = useTranslation();

  const isReferenceSignalDisabled =
    selectedSignals.length === 0 ||
    controlsStore.trendsGrouping?.key !== TrendGroupingEnum.SEPARATED;

  return (
    <div
      style={signalAutocompleteStyles}
      className={classNames("secondary-dropdown", {
        disabled: isReferenceSignalDisabled,
      })}
    >
      <ReferenceIcon fill='#2c529f' style={{ marginRight: 5, width: 17, height: 17 }} />
      <SignalsAutocomplete
        key={data?.length}
        hasNoneItem
        disabled={isReferenceSignalDisabled}
        style={buttonStyle}
        items={data}
        buttonProps={{
          disabled: isReferenceSignalDisabled,
          icon: <ChevronDownRegular />,
          iconPosition: "after",
          style: { padding: "5px 0px" },
          text: (
            <>
              <span style={{ fontWeight: "normal" }}>{t("Reference")}</span>
              <Text style={referenceTextStyles}>
                {controlsStore.referenceSignal?.text || t("None")}
              </Text>
            </>
          ),
        }}
        onSelectSignal={(signal) => {
          let result = undefined;
          if (signal) {
            result = (data || []).filter((item) => item.id === signal.id)[0] || {};
          }
          const payload = signal
            ? {
                ...result,
                key: signal.id,
                text: signal.label,
              }
            : undefined;

          controlsStore.changeReferenceSignal(payload);
        }}
      />
    </div>
  );
};

export default ReferenceSignalDropdown;
