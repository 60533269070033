import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { SettingsRegular, SignOutRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Account from "./Account";

export const UserMenu = ({ handleDismiss }: { handleDismiss: (value) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <Menu>
      <MenuTrigger>
        <Button style={{ padding: 0, margin: 0, minWidth: 0 }} appearance='transparent'>
          <Account />
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuItem style={{ padding: 0 }}>
            <Button
              appearance='transparent'
              icon={<SettingsRegular />}
              onClick={() => {
                navigate("settings");
                handleDismiss(false);
              }}
            >
              {t("Settings")}
            </Button>
          </MenuItem>
          <MenuItem style={{ padding: 0 }}>
            <Button
              appearance='transparent'
              icon={<SignOutRegular />}
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              {t("Sign out")}
            </Button>
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
