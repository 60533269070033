import { Switch } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import LayoutIcon from "../../../../../assets/svg/LayoutIcon";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { onRenderLabel } from "../../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../common/Dropdown";
import { Stack } from "../../../../Stack";
import { getViewDropdownOptions } from "../../config";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";

const ColoredChartHeader = () => {
  const { t } = useTranslation();
  const { transparent } = useDropdownStyles();
  const { showDates, colorChartView, updateDriveLoadStore } = useDriveLoadStore((store: any) => ({
    showDates: store.showDates,
    colorChartView: store.colorChartView,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));

  return (
    <Stack horizontal verticalAlign='center' style={{ gap: 10 }} role='menubar'>
      <Dropdown
        className={transparent}
        button={onRenderLabel({
          label: t("Scatter Chart"),
          icon: <LayoutIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: t(colorChartView.text),
        })}
        style={{ padding: "2px 8px" }}
        selectedOptions={[colorChartView.key]}
        options={getViewDropdownOptions(t)}
        onOptionSelect={(_: any, option?: any) =>
          updateDriveLoadStore({
            colorChartView: { key: option.optionValue, text: option.optionText },
          })
        }
      />
      <Switch
        label={t("Show dates")}
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0 0 0 10px",
          fontWeight: 600,
        }}
        checked={showDates}
        onChange={(_, { checked }) => updateDriveLoadStore({ showDates: checked })}
      />
    </Stack>
  );
};

export default ColoredChartHeader;
