import type { TagPickerProps } from "@fluentui/react-components";
import {
  Avatar,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Text,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

export type PeoplePickerDataItem = {
  id: string;
  imageInitials: string;
  secondaryText: string;
  size: number;
  text: string;
};

type PeoplePickerProps = {
  data: PeoplePickerDataItem[];
  onChange: (ids: string[]) => void;
  selectedOptions: string[];
};

export const PeoplePicker = ({ data, onChange, selectedOptions }: PeoplePickerProps) => {
  const { t } = useTranslation();

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
      return;
    }

    onChange(data.selectedOptions);
  };

  return (
    <>
      <TagPicker selectedOptions={selectedOptions} onOptionSelect={onOptionSelect}>
        <TagPickerControl style={{ minWidth: "200px" }}>
          {selectedOptions?.length === 0 && (
            <Text style={{ position: "absolute" }}>{t("Filter comments by user")}</Text>
          )}
          <TagPickerGroup aria-label='Selected Employees'>
            {selectedOptions.map((id) => {
              const option = data.find((item) => item.id === id);

              return (
                <Tag
                  key={option.id}
                  shape='rounded'
                  value={option.id}
                  media={
                    <Avatar aria-hidden shape='circular' name={option.text} color='colorful' />
                  }
                >
                  {option.text}
                </Tag>
              );
            })}
          </TagPickerGroup>
          <TagPickerInput aria-label='Select Employees' />
        </TagPickerControl>
        <TagPickerList>
          {data.length > 0 ? (
            data.map((option) => (
              <TagPickerOption
                key={option.id}
                value={option.id}
                media={<Avatar aria-hidden shape='circular' name={option.text} color='colorful' />}
                style={{ display: "flex", alignItems: "center" }}
              >
                {option.text}
              </TagPickerOption>
            ))
          ) : (
            <TagPickerOption value='no-options'>{t("No options available")}</TagPickerOption>
          )}
        </TagPickerList>
      </TagPicker>
    </>
  );
};
