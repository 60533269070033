import { useEffect, useMemo, useState } from "react";
import type { DefaultValues, FieldError, Path } from "react-hook-form";
import { z } from "zod";

import { useZodForm } from "../../../common/Form";
import type { SimpleValidationError } from "../models";

// Base types
type SchemaType<T extends z.ZodType> = z.infer<T>;
type FormData<T extends z.ZodType> = T["_input"];

type FormValidationError = FieldError & {
  ref: {
    name: string;
  };
};

type ValidationErrors<T> = {
  [K in keyof T]?: FormValidationError;
};

interface ValidationOptions<T extends z.ZodType> {
  schema?: T;
  customValidators?: {
    [K in keyof FormData<T>]?: (value: any, context?: any) => string | undefined;
  };
  mode?: "onChange" | "onBlur" | "onSubmit";
  defaultValues?: DefaultValues<FormData<T>>;
}

export function simplifyValidationErrors<T>(errors: ValidationErrors<T>): SimpleValidationError {
  return Object.entries(errors).reduce(
    (acc, [key, error]: [string, FormValidationError | undefined]) => ({
      ...acc,
      [key]: error?.message || "",
    }),
    {} as SimpleValidationError,
  );
}

export function useValidation<T extends z.ZodType>({
  schema,
  customValidators = {},
  mode = "onChange",
  defaultValues,
}: ValidationOptions<T>) {
  const emptySchema = useMemo(() => z.any(), []);

  const {
    formState: { errors: zodErrors, isValid: zodIsValid },
    control,
    watch,
    trigger,
    setValue,
  } = useZodForm({
    mode,
    schema: schema || emptySchema,
    defaultValues,
  });

  // Initial trigger to show errors
  useEffect(() => {
    if (schema) {
      trigger();
    }
  }, [schema, trigger]);

  const [customErrors, setCustomErrors] = useState<ValidationErrors<FormData<T>>>({});

  const validateField = async (
    fieldName: keyof FormData<T>,
    value: any,
    context?: any,
  ): Promise<string | undefined> => {
    const customValidator = customValidators[fieldName];
    if (customValidator) {
      const customError = customValidator(value, context);
      if (customError) {
        setCustomErrors((prev) => ({
          ...prev,
          [fieldName]: {
            type: "custom",
            message: customError,
            ref: { name: String(fieldName) },
          },
        }));
        return customError;
      }
    }

    // Zod validation
    if (schema) {
      try {
        setValue(fieldName as Path<FormData<T>>, value);
        const isFieldValid = await trigger(fieldName as Path<FormData<T>>);
        if (!isFieldValid) {
          const fieldError = zodErrors[fieldName as string];
          return fieldError?.message as string | undefined;
        }
      } catch (error) {
        if (error instanceof z.ZodError) {
          const fieldError = error.errors.find((err) => err.path[0] === fieldName);
          return fieldError?.message;
        }
      }
    }

    setCustomErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fieldName];
      return newErrors;
    });
    return undefined;
  };

  const combinedErrors: ValidationErrors<FormData<T>> = schema
    ? {
        ...Object.entries(zodErrors).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: {
              type: value.type || "validation",
              message: value.message as string,
              ref: { name: key },
            },
          }),
          {},
        ),
        ...customErrors,
      }
    : customErrors;

  const isValid = (schema ? zodIsValid : true) && Object.keys(customErrors).length === 0;

  return {
    control: schema ? control : undefined,
    watch: schema ? watch : () => undefined,
    setValue: schema ? setValue : () => {},
    validateField,
    errors: combinedErrors,
    isValid,
    setCustomError: (fieldName: keyof FormData<T>, message: string) => {
      setCustomErrors((prev) => ({
        ...prev,
        [fieldName]: {
          message,
          ref: { name: String(fieldName) },
        },
      }));
    },
    clearCustomError: (fieldName: keyof FormData<T>) => {
      setCustomErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[fieldName];
        return newErrors;
      });
    },
  };
}
