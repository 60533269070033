import "./styles.scss";

import classNames from "classnames";
import type { CSSProperties } from "react";
import { useMemo } from "react";

import { StackItem } from "./StackItem";
import type { StackProps } from "./types";

export function Stack({
  horizontal = false,
  horizontalAlign,
  verticalFill = false,
  verticalAlign,
  reversed = false,
  wrap = false,
  children,
  className = "",
  style,
  ...rest
}: StackProps) {
  const customWrap = useMemo(() => (!wrap ? "unset" : "wrap"), [wrap]);
  const height = useMemo(() => (!verticalFill ? {} : { height: "100%" }), [verticalFill]);

  const alignment = useMemo(() => {
    const reverse = !reversed ? "" : "-reverse";

    return !horizontal
      ? {
          alignItems: horizontalAlign,
          justifyContent: verticalAlign,
          flexDirection: `column${reverse}` as CSSProperties["flexDirection"],
        }
      : {
          alignItems: verticalAlign,
          justifyContent: horizontalAlign,
          flexDirection: `row${reverse}` as CSSProperties["flexDirection"],
        };
  }, [horizontal, horizontalAlign, reversed, verticalAlign]);

  return (
    <div
      className={classNames("ms-Stack", "default-stack-styles", className)}
      style={{
        ...style,
        ...height,
        ...alignment,
        flexWrap: customWrap,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

Stack.Item = StackItem;
