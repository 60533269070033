import { makeStyles } from "@fluentui/react-components";

import { Button } from "./Button";

const useFluentComponentsStyleOverrides = makeStyles({
  root: {
    ...Button,
  },
});

export default useFluentComponentsStyleOverrides;
