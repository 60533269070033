import { Switch } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { MAX_GROUP_THRESHOLD } from "../../../../../../modules/analysis-trend-view/constants/controlOptions";
import usePerformanceStore from "../../../hooks/usePerformanceStore";

const Header = () => {
  const { t } = useTranslation();
  const { selectedSignals, isGroupedEverything, updateStore } = usePerformanceStore(
    (store: any) => ({
      selectedSignals: store.selectedSignals,
      isGroupedEverything: store.isGroupedEverything,
      updateStore: store.updateStore,
    }),
  );

  function handleGroupSignalsChange(_: any, checked: boolean | undefined) {
    return selectedSignals.length > MAX_GROUP_THRESHOLD
      ? updateStore({ isWarningModalOpen: true })
      : updateStore({ isGroupedEverything: checked });
  }

  return (
    <div className='mb-8 torque-header'>
      <div className='semibold-title'>{t("Statistics")}</div>

      <Switch
        label={t("Group signals")}
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: 20,
        }}
        checked={isGroupedEverything}
        onChange={(_, { checked }) => handleGroupSignalsChange(_, checked)}
      />
    </div>
  );
};

export default Header;
