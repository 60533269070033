import type { PropsWithChildren } from "react";

import { pageStyle } from "../../../schema/Constants";
import BaseCommandBar, { computeCommandBarItems } from "../../common/CommandBar";
import { Stack } from "../../Stack";

type ImagesPivotContainerProps = {
  title: string;
  hasPermissions: boolean;
  commandBarItemProps: any;
  handleSearch: (value: string) => void;
};

/**
 * Gets the images pivot container component.
 * @param title The pivot title.
 * @param hasPermissions Value indicating whether the user has permissions to see the command bar.
 * @param commandBarItemProps The command bar item props list.
 * @param handleSearch Method to handle the value input in the search text field.
 * @returns The images pivot container component.
 */
const ImagesPivotContainer = ({
  title,
  hasPermissions,
  commandBarItemProps,
  handleSearch,
  children,
}: PropsWithChildren<ImagesPivotContainerProps>) => {
  const htmlId = `${title.replaceAll(" ", "").toLocaleLowerCase()}-images-pivot`;

  return (
    <section style={pageStyle}>
      <Stack
        horizontal
        verticalAlign='center'
        horizontalAlign='stretch'
        style={{ paddingBottom: "8px" }}
      >
        <h1 id={htmlId} className='area-title'>
          {title}
        </h1>

        {hasPermissions && (
          <BaseCommandBar
            aria-labelledby={htmlId}
            items={computeCommandBarItems(commandBarItemProps)}
            onSearch={handleSearch}
          />
        )}
      </Stack>
      {children}
    </section>
  );
};

export default ImagesPivotContainer;
