import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, DialogTitle } from "@fluentui/react-components";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import BaseDialog from "../../../../common/Dialog";
import type { FormItemProps } from "../../../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../../../common/Form";
import type { CreateProjectTriggerRequest } from "../../Sensor/types";
import { TriggerFrequency } from "../../Sensor/types";

enum MeasurementSettingId {
  MeasSet0 = "MeasSet0",
  MeasSet1 = "MeasSet1",
  MeasSet2 = "MeasSet2",
  MeasSet3 = "MeasSet3",
  MeasSet4 = "MeasSet4",
  MeasSet5 = "MeasSet5",
  MeasSet6 = "MeasSet6",
  MeasSet7 = "MeasSet7",
}

interface TriggerAddDialogProps extends Omit<DialogProps, "children" | "open"> {
  projectId: string;
  hidden?: boolean;
  onDismiss?: () => any;
  onSuccess?: (data: CreateProjectTriggerRequest) => void;
}

export const TriggerAddDialog: FC<TriggerAddDialogProps> = ({
  projectId,
  hidden,
  onSuccess,
  onDismiss,
}) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        projectId: z.string().uuid(),
        name: z.string().min(1, t("Name is required")),
        frequency: z.nativeEnum(TriggerFrequency),
        hour: z.number().min(0).max(23),
        minute: z.number().min(0).max(59),
        measurementSets: z
          .array(z.nativeEnum(MeasurementSettingId))
          .min(1, t("At least one measurement set is required")),
        delayPerSensorInMinutes: z.number().min(0),
      }),
    [t],
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    schema,
    defaultValues: {
      projectId,
      hour: 3,
      minute: 0,
      delayPerSensorInMinutes: 5,
      measurementSets: [MeasurementSettingId.MeasSet1],
    },
  });

  useEffect(() => {
    if (hidden) {
      reset();
    }
  }, [hidden, reset]);

  const frequencyOptions: any[] = Object.values(TriggerFrequency).map((freq) => ({
    key: freq,
    text: freq?.replace(/([A-Z])/g, " $1").trim(),
    data: freq,
  }));

  const measurementSetOptions: any[] = Object.keys(MeasurementSettingId)
    .filter((key) => isNaN(Number(key)))
    .map((set) => ({
      key: set,
      text: set,
      data: MeasurementSettingId[set as keyof typeof MeasurementSettingId],
    }));
  const triggerFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name") },
      defaultValue: "Daily raw data",
    },
    {
      name: "frequency",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Frequency") },
      options: frequencyOptions,
      defaultValue: TriggerFrequency.EveryDay,
    },
    {
      name: "hour",
      type: FormItemType.NumberField,
      groupProps: { label: t("Hour") },
      min: 0,
      max: 23,
    },
    {
      name: "minute",
      type: FormItemType.NumberField,
      groupProps: { label: t("Minute") },
      min: 0,
      max: 59,
    },
    {
      name: "measurementSets",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Measurement Sets") },
      options: measurementSetOptions,
      multiSelect: true,
      required: true,
    },
    {
      name: "delayPerSensorInMinutes",
      type: FormItemType.NumberField,
      groupProps: { label: t("Delay per Sensor (minutes)") },
      min: 0,
      max: 30,
    },
  ];

  // TODO: This type creates a typescript error CreateProjectTriggerRequest
  const onSubmit = handleSubmit(async (data: any) => {
    console.log("Creating trigger:", data);
    try {
      onSuccess?.(data);
      onDismiss?.();
    } catch (error) {
      // Handle error
      console.error("Error creating trigger:", error);
    }
  });

  return (
    <BaseDialog open={!hidden}>
      <DialogTitle>{t("Add new trigger")}</DialogTitle>

      <DialogContent>
        <form onSubmit={onSubmit}>
          {renderFormItems(triggerFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button appearance='primary' type='submit' disabled={!isValid}>
              {t("Save")}
            </Button>
            <Button onClick={onDismiss as React.MouseEventHandler<HTMLButtonElement>}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
