import { Field } from "@fluentui/react-components";
import type { DatePickerProps } from "@fluentui/react-datepicker-compat";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";
import { getDayPickerStrings } from "./helpers";

export type FormDatePickerProps = BaseFormFieldProps &
  DatePickerProps & {
    name: string;
    defaultValue?: any;
    isRequired?: boolean;
  };

export const FormDatePicker = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  isRequired = false,
  ...rest
}: FormDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup {...groupProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            style={{ width: "100%" }}
            required={isRequired}
            validationMessage={errors?.[name]?.message || ""}
          >
            <DatePicker
              strings={getDayPickerStrings(t)}
              value={value}
              onSelectDate={(date) => {
                onChange(date);
              }}
              onBlur={onBlur}
              {...rest}
            />
          </Field>
        )}
      />
    </FormGroup>
  );
};
