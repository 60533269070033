import { DialogContent, Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, Delete20Regular, Edit20Regular, Eye20Regular } from "@fluentui/react-icons";
import { PowerBIEmbed } from "powerbi-client-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import { useTableFilters } from "../common/Table/v9";
import Table from "../common/Table/v9/Table";
import { listAsyncCompanies, selectCompanies, selectCompaniesStatus } from "../Companies/reducer";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../Corporations/reducer";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { listAsyncMachines, selectMachines, selectMachinesStatus } from "../Machines/reducer";
import { listAsyncProjs, selectProjects, selectProjectsStatus } from "../Projects/reducer";
import { detailsDashboard } from "./api";
import { DashboardAddDialog, DashboardEditDialog } from "./DashboardAddEditDialogs";
import { DeleteConfirm } from "./DeleteConfirm";
import type { DashBoardToList, PowerBIEmbedItem, ResponsePowerBIGet } from "./models";
import { PowerBIEmbedItemParentType } from "./models";
import { listAsyncDashB, selectDashboards, selectDashboardsStatus } from "./reducer";

export const dashBContext = createContext<PowerBIEmbedItem[] | undefined>(undefined);

export const selDashBDetailsContext = createContext<ResponsePowerBIGet | undefined>(undefined);

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  hasView: boolean;
  onEdit: (dBoard: DashBoardToList) => void;
  onDelete: (dBoard: DashBoardToList) => void;
  onView: (dBoard: DashBoardToList) => void;
};

const getColumns = ({
  t,
  hasActions,
  hasView,
  onEdit,
  onDelete,
  onView,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "parentName",
      name: t("Name"),
      fieldName: "parentName",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, parentName, parentType, parentId }: DashBoardToList) => (
        <Link
          to={
            parentType === "Corporation"
              ? "../corporations/" + parentId?.toString()
              : parentType === "Company"
                ? "../companies/" + parentId?.toString()
                : parentType === "Project"
                  ? "../projects/" + parentId?.toString()
                  : "../machines/" + parentId?.toString()
          }
          style={linkStyle}
        >
          {parentName}
        </Link>
      ),
    },
    {
      key: "parentType",
      name: t("Parent Type"),
      fieldName: "parentType",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (dBoard: DashBoardToList) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(dBoard);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(dBoard);
              }}
            />
          </Tooltip>
          {hasView && (
            <Tooltip withArrow content={t("View")} relationship='label'>
              <Eye20Regular
                style={{
                  color: "#2c529f",
                  cursor: "pointer",
                  marginLeft: "4px",
                }}
                onClick={() => onView(dBoard)}
              />
            </Tooltip>
          )}
        </div>
      ),
    });
  }

  return columns;
};

export const DashboardsList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const hasWritePermission = auth.powerBiContributor;
  const hasReadPermission = auth.powerBiReader;
  const dashBs = useAppSelector(selectDashboards);
  const dashboardStatus = useAppSelector(selectDashboardsStatus);
  const [status, setStatus] = useState<Status>(Status.loading);
  const corpos = useAppSelector(selectCorporations);
  const compas = useAppSelector(selectCompanies);
  const projs = useAppSelector(selectProjects);
  const machs = useAppSelector(selectMachines);
  const companiesStatus = useAppSelector(selectCompaniesStatus);
  const corporationsStatus = useAppSelector(selectCorporationsStatus);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const [dBoardsItems, setDBoardsItems] = useState<DashBoardToList[]>([]);
  const { filters, handleSearch } = useTableFilters<DashBoardToList>({
    keys: ["parentName", "parentType"],
  });
  const [selected, setSelected] = useState<{
    data: DashBoardToList | null;
    context: "add" | "edit" | "delete" | "view";
    embedToken?: string;
    embedUrl?: string;
  } | null>(null);

  const dispatch = useAppDispatch();

  // Gets the corporations, companies, projects, and machines lists if they are not loaded
  useEffect(() => {
    if (corporationsStatus === Status.void) dispatch(listAsyncCorpo());
    if (companiesStatus === Status.void) dispatch(listAsyncCompanies());
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
    if (machineStatus === Status.void) dispatch(listAsyncMachines());
    if (dashboardStatus === Status.void) dispatch(listAsyncDashB());
  }, [
    dispatch,
    machineStatus,
    projectStatus,
    dashboardStatus,
    corporationsStatus,
    companiesStatus,
  ]);

  // Builds the table items.
  useEffect(() => {
    if (
      dashboardStatus === Status.idle &&
      machineStatus === Status.idle &&
      projectStatus === Status.idle &&
      companiesStatus === Status.idle &&
      corporationsStatus === Status.idle
    ) {
      const toListAux: DashBoardToList[] = [];
      dashBs.forEach((db) => {
        let parentName = "";
        const result: DashBoardToList = {
          parentType: db.parentType,
          parentName: "",
          parentId: db.parentId,
          groupId: db.groupId,
          id: db.id,
        };

        switch (PowerBIEmbedItemParentType[db.parentType]) {
          case PowerBIEmbedItemParentType.Corporation:
            parentName = corpos.find((corp) => corp.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Company:
            parentName = compas.find((comp) => comp.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Project:
            parentName = projs.find((mach) => mach.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Machine:
            parentName = machs.find((mach) => mach.id === db.parentId)?.dalogId || "";
            break;
        }

        if (parentName) {
          result.parentName = parentName;
          toListAux.push(result);
        }
      });
      setDBoardsItems(toListAux);
      setStatus(Status.idle);
    }
  }, [
    companiesStatus,
    compas,
    corporationsStatus,
    corpos,
    dashBs,
    dashboardStatus,
    dispatch,
    machineStatus,
    machs,
    projectStatus,
    projs,
  ]);

  // Handlers
  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (dBoard: DashBoardToList) => setSelected({ data: dBoard, context: "edit" });

  const onDelete = (dBoard: DashBoardToList) => setSelected({ data: dBoard, context: "delete" });

  const onClose = () => setSelected(null);

  const onView = async (dBoard: DashBoardToList) => {
    await detailsDashboard(dBoard as PowerBIEmbedItem).then((res) => {
      if (!res) {
        notification.warning(
          t(`There is no dashboard available for the entity {{dashboardbParentName}}.`, {
            dashboardbParentName: dBoard.parentName,
          }),
        );
        return;
      }

      setSelected({
        data: dBoard,
        context: "view",
        embedToken: res.embedToken,
        embedUrl: res.embedUrl,
      });
    });
  };

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Dashboards")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            icon: <AddRegular />,
            onClick: onAdd,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />

      <Table
        persistOpts={{
          key: "table-dashboards",
          version: 2,
        }}
        header={{
          title: t("Dashboards"),
        }}
        items={dBoardsItems}
        v8Columns={getColumns({
          t,
          hasActions: hasWritePermission,
          hasView: hasReadPermission,
          onEdit,
          onDelete,
          onView,
        })}
        filters={filters}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      {selected?.context === "add" && <DashboardAddDialog onClose={onClose} />}
      {selected?.context === "edit" && (
        <DashboardEditDialog entry={selected?.data} onClose={onClose} />
      )}
      <dashBContext.Provider value={dashBs}>
        {selected?.context === "view" && (
          <BaseDialog open={true} surfaceStyle={{ width: DialogSize.AUTO }} onOpenChange={onClose}>
            <BaseDialogTitle>
              {selected?.context.charAt(0).toUpperCase() +
                selected?.context.slice(1) +
                " " +
                t("Dashboard")}
            </BaseDialogTitle>
            <DialogContent>
              {selected?.context === "view" && (
                <PowerBIEmbed
                  embedConfig={{
                    type: "report",
                    id: selected?.data.id,
                    embedUrl: selected?.embedUrl,
                    accessToken: selected?.embedToken,
                    tokenType: 1,
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false,
                        },
                        pageNavigation: {
                          visible: false,
                        },
                      },
                    },
                  }}
                  eventHandlers={
                    new Map([
                      ["loaded", () => {}],
                      ["rendered", () => {}],
                      ["error", (event: any) => {}],
                    ])
                  }
                  cssClassName={"report-style-class-view"}
                />
              )}
            </DialogContent>
          </BaseDialog>
        )}
      </dashBContext.Provider>
      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting the dashboard for : {{entity}}`, {
                entity: selected?.data?.parentName,
              }),
            );
          } else {
            dispatch(listAsyncDashB());
            notification.success(
              t(`{{entity}} dashboard related, deleted successfully.`, {
                entity: selected?.data?.parentName,
              }),
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
    </div>
  );
};
