import { Button, Divider } from "@fluentui/react-components";
import { NavigationRegular } from "@fluentui/react-icons";
import { Resizable } from "re-resizable";
import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";

import ClearIcon from "../../../../assets/svg/ClearIcon";
import { Stack } from "../../../../Components/Stack";
import { useSidebarStore } from ".";
import TrendViewSearchComponent from "./TrendViewSlideSearch";

export type SignalsSidebarProps = {
  marginTop?: number | string;
  isClearDisabled: boolean;
  clearSignals: () => void;
};

const ClearAllIcon = () => <ClearIcon />;
const TrendViewSignalsSidebar = ({
  marginTop,
  clearSignals,
  isClearDisabled,
  children,
}: PropsWithChildren<SignalsSidebarProps>) => {
  const { isExpanded, toggle } = useSidebarStore((store) => ({
    isExpanded: store.isExpanded,
    toggle: store.toggle,
  }));

  const [sidebarWidth, setSidebarWidth] = useState(300);

  useEffect(() => {
    if (isExpanded) {
      setSidebarWidth(300);
    } else {
      setSidebarWidth(50);
    }
  }, [isExpanded]);

  return (
    <Stack
      className='no-select'
      style={{
        position: "relative",
      }}
    >
      <Resizable
        minWidth={isExpanded ? 300 : 50}
        maxWidth={560}
        size={{ width: isExpanded ? sidebarWidth : 50, height: "auto" }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResizeStop={(e, direction, ref, d) => {
          setSidebarWidth(sidebarWidth + d.width);
        }}
      >
        <div
          className='sidebar-scrollbar'
          style={{
            height: `calc(100vh - 62px - ${marginTop}px)`,
            width: "100%",
            overflowY: isExpanded ? "auto" : "hidden",
            overflowX: "hidden",
            background: "#FAF9F8",
            transition: "width 0.2s linear",
            willChange: "width",
            paddingBottom: 15,
          }}
        >
          <Stack
            horizontal
            verticalAlign='center'
            horizontalAlign='center'
            style={{
              display: "grid",
              gridTemplateColumns: "36px 1fr",
              height: "44px",
              padding: "0 9px",
              background: "#FAF9F8",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Button appearance='transparent' icon={<NavigationRegular />} onClick={toggle} />

            {isExpanded ? (
              <Stack horizontal verticalAlign='center' style={{ marginLeft: 3 }}>
                <Divider
                  vertical
                  style={{
                    height: 36,
                    color: "#E1DFDD",
                    marginRight: 4,
                  }}
                />

                <Button
                  appearance='transparent'
                  style={{
                    padding: 0,
                    height: "20px",
                  }}
                  disabled={isClearDisabled}
                  icon={<ClearAllIcon />}
                  onClick={clearSignals}
                />
                <TrendViewSearchComponent />
              </Stack>
            ) : null}
          </Stack>

          <div
            style={{
              opacity: isExpanded ? 1 : 0,
              pointerEvents: isExpanded ? "auto" : "none",
              transition: "opacity 0.2s",
              marginTop: 10,
              transitionDelay: isExpanded ? "0.15s" : "0s",
            }}
          >
            {children}
          </div>
        </div>
      </Resizable>
    </Stack>
  );
};

export default TrendViewSignalsSidebar;
