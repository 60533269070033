import "./styles.scss";

import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MAX_GROUP_THRESHOLD } from "../../../../../../modules/analysis-trend-view/constants/controlOptions";
import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import type { TableProps } from "../../../../../common/Table/v9/Table";
import Table from "../../../../../common/Table/v9/Table";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import { useStatistics } from "../../../hooks/useStatistics";
import { getColumns, prepareCurrentBestValue, prepareDiffValue } from "./methods";

const KpiTable = ({
  bestPerformance = {},
  worstPerformance = {},
  tableSettings = {},
  tableStyle = {},
  tableKeyName,
  tableColumns,
  perfPeriodFilter,
  kpiSignals,
}: any) => {
  const { t } = useTranslation();
  const { updateStore, selectedSignals, isGroupedEverything } = usePerformanceStore(
    (store: any) => ({
      updateStore: store.updateStore,
      selectedSignals: store.selectedSignals,
      isGroupedEverything: store.isGroupedEverything,
    }),
  );

  const { isLoading, data: statisticsData } = useStatistics({
    trendOrFastTrendIds: kpiSignals,
    periodStart: perfPeriodFilter?.startDate,
    periodEnd: perfPeriodFilter?.endDate,
    dateTime1: bestPerformance?.timeStamp,
    dateTime2: worstPerformance?.timeStamp,
    options: {
      enabled: !!bestPerformance?.timeStamp && !!worstPerformance?.timeStamp,
    },
  });

  const data = useMemo(() => {
    return (statisticsData || []).map((statisticItem: any) => {
      const {
        signal,
        statistics = {},
        dateTime1 = {},
        dateTime2 = {},
        dateTimeMax = {},
      } = statisticItem;

      const current = perfPeriodFilter ? dateTimeMax?.value : dateTime2?.value;
      const currentBest = prepareCurrentBestValue(current, dateTime1?.value);
      const diff = prepareDiffValue(currentBest, dateTime1?.value);

      return {
        ...signal,
        average: statistics?.avererageAbsolute,
        worst: dateTime2?.value,
        best: dateTime1?.value,
        current,
        currentBest,
        diff,
      };
    });
  }, [statisticsData]);

  const tableProps: TableProps = useMemo(() => {
    return {
      persistOpts: {
        key: tableKeyName,
        version: 1,
      },
      items: data,
      perPage: 999999,
      hidePerPage: true,
      isLoading,
      isError: false,
      selectionPreservedOnEmptyClick: true,
      v8Columns: tableColumns || getColumns(t),
      selectionMaxThreshold: isGroupedEverything ? 8 : undefined,
      ...tableSettings,
    };
  }, [data, isLoading, tableKeyName, tableColumns, t, tableSettings]);

  const isSelectableTable = !["kpi-modal-table", "signals-modal-table"].includes(tableKeyName);

  useEffect(() => {
    updateStore({ selectedSignals: [] });
  }, [tableKeyName]);

  return (
    <div
      className={classNames("table-drive-load-events kpi-table", {
        "data-section": "exists data?",
      })}
    >
      {isLoading && <Spin style={{ height: "100%" }} />}
      {!isLoading && isEmpty(data) && <NoData style={{ height: "100%" }} />}
      {!isEmpty(data) && (
        <div
          className={classNames({
            "disable-select-all-button": isGroupedEverything && isSelectableTable,
            "disable-unchecked-values":
              isGroupedEverything &&
              selectedSignals.length >= MAX_GROUP_THRESHOLD &&
              isSelectableTable,
          })}
          style={{ ...tableStyle }}
        >
          <Table
            {...tableProps}
            defaultSelectedItems={selectedSignals}
            getRowId={(item) => item?.id ?? ""}
            selection={{
              selectionMode: "multiselect",
              onSelectionChanged: (items) => updateStore({ selectedSignals: items }),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KpiTable;
