/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import type { ResponseSimplifiedSignalList } from "../../../../types";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import { format } from "../../../common/utils/signalFormatter";
import useControlsStore from "../../hooks/useControlsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";

const useTrendViewControls = () => {
  const controlsStore = useControlsStore();

  const selectedSignalsStore = useSelectedSignalsStore((state) => ({
    errorMessage: state.errorMessage,
    selectedSignals: state.selectedSignals,
    isErrorMessageOpen: state.isErrorMessageOpen,
    changeErrorMessage: state.changeErrorMessage,
    toggleIsErrorMessageOpen: state.toggleIsErrorMessageOpen,
  }));

  const { data } = useQuery<ResponseSimplifiedSignalList>(["signals-list"], () => [], {
    enabled: false,
  });

  const selectedSignalsMachineIDs = [
    ...new Set(selectedSignalsStore.selectedSignals.map(({ machineId }) => machineId)),
  ];

  const items = useMemo(
    () =>
      (data || [])
        .filter(
          (child) =>
            child.dataType !== DataTypeEnum.RawData &&
            selectedSignalsMachineIDs.includes(child?.machineId),
        )
        .map((item) => ({
          ...item,
          id: item.id as string,
          name: item.name as string,
          label: format(item),
          icon: item.dataType as DataTypeEnum,
        })),
    [selectedSignalsMachineIDs.length, data],
  );

  return {
    data: items,
    controlsStore,
    selectedSignalsStore,
  };
};

export default useTrendViewControls;
