import uniqueId from "lodash-es/uniqueId";

import geoData from "../../../../../../geoData.json";
import { CONDITION_RANKING, conditionColor } from "../../../../../../modules/machine-cv/constants";
import { Map } from "../../../../../common/Map";
import type { Point } from "../../../../../common/Map/Map";
import { Spin } from "../../../../../common/Spin";
import { useMachineConditionsTable } from "../../Machines/useMachineConditionsTable";
import MapMarker from "./MapMarker";

function getMapPoints(machines) {
  return (machines || []).map(({ title, projectName, coordinates, condition, machineId }) => {
    const uniqId = uniqueId();

    return {
      type: "Feature",
      geometry: { type: "Point", coordinates },
      properties: {
        machineId,
        name: title,
        projectName,
        color: conditionColor[condition],
        cluster: false,
        type: "Project",
        id: uniqId,
        condition,
      },
    };
  });
}

export default function HomepageMap() {
  const { machines, isError, isLoading } = useMachineConditionsTable();

  const mapPoints = getMapPoints(machines);

  const conditionsByCountry = machines.reduce((acc, { country, condition }) => {
    const place = country || "Other";
    if (!acc?.[place]) {
      acc[place] = {};
    }

    if (!acc?.[place]?.[condition]) {
      acc[place][condition] = 0;
    }

    acc[place][condition] = acc[place][condition] + 1;
    return acc;
  }, {});

  const mappedGeoData = Object.keys(conditionsByCountry)
    .map((key) => {
      const countryGeoData = geoData[key];

      if (!countryGeoData) return null;

      const conditions = conditionsByCountry[key];
      const rankedConditionColor = CONDITION_RANKING.find((key) => conditions[key]);

      return {
        ...countryGeoData,
        properties: {
          ...countryGeoData.properties,
          name: key,
          color: conditionColor[rankedConditionColor],
        },
      };
    })
    .filter(Boolean);

  function onMapLoad({ target }) {
    target.addSource("geoData", {
      type: "geojson",
      data: { type: "FeatureCollection", features: mappedGeoData },
    });
    target.addLayer(
      {
        id: "colore-countries",
        type: "fill",
        source: "geoData",
        paint: {
          "fill-color": ["get", "color"],
        },
      },
      "landuse",
    );
  }

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Spin style={{ height: "50vh" }} />;
  }

  return (
    <Map
      points={mapPoints as Point[]}
      style={{ marginBottom: 80 }}
      Marker={MapMarker}
      minZoom={1}
      mapStyle='mapbox://styles/dirkwoldt/cm6hmoj4p002q01sd3dy62wyj'
      onLoad={onMapLoad}
    />
  );
}
