import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { maxLengthType1, maxLengthType2, textStyle } from "../../../../schema/Constants";
import BaseDialog, { BaseDialogTitle } from "../../../common/Dialog";
import type { FormItemProps } from "../../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../../common/Form";
import {
  addFrequencyCatalogueGroup,
  deleteFrequencyCatalogueGroup,
  editFrequencyCatalogueGroup,
} from "./api";
import type {
  CreateFrequencyCatalogueGroup,
  FrequencyCatalogueGroupPageDetails,
  UpdateFrequencyCatalogueGroup,
} from "./models";

const getSchema = (t, context: "add" | "edit" | "delete") =>
  z.object({
    groupName: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType1, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    description: z
      .string()
      .max(maxLengthType1, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType1,
        }),
      })
      .optional(),
  });

function modifyFieldsBasedOnContext(t, context: "add" | "edit" | "delete"): FormItemProps[] {
  const fields: FormItemProps[] = [
    {
      name: "groupName",
      type: FormItemType.TextField,
      groupProps: { label: t("Group Name") },
      disabled: context === "edit",
    },
    {
      name: "description",
      type: FormItemType.TextField,
      groupProps: { label: t("Description") },
    },
  ];

  return fields;
}

type AddOrEditDialogProps = Omit<DialogProps, "children"> & {
  machineId: string;
  data: undefined | string | FrequencyCatalogueGroupPageDetails;

  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialogGroup = ({
  machineId,
  data,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t, context), [t, context]);

  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && data && typeof data !== "string"
      ? {
          defaultValues: {
            groupName: data.name,
            description: data.description,
          },
        }
      : {
          defaultValues: {
            groupName: data as string,
          },
        }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          groupName: data?.name,
          description: data?.description || "",
        })
      : typeof data === "string"
        ? reset({
            groupName: data,
            description: "",
          })
        : reset({});
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: CreateFrequencyCatalogueGroup | UpdateFrequencyCatalogueGroup =
      context === "add"
        ? ({
            groupName: formData.groupName,
            description: formData.description ?? "",
          } as CreateFrequencyCatalogueGroup)
        : ({
            description: formData.description ?? "",
          } as UpdateFrequencyCatalogueGroup);

    switch (context) {
      case "add":
        await addFrequencyCatalogueGroup(machineId, toSend as CreateFrequencyCatalogueGroup).then(
          (response) => onSuccess("status" in response, context),
        );
        break;
      case "edit":
        await editFrequencyCatalogueGroup(
          typeof data !== "string" && "id" in data ? data.id : "",
          machineId,
          typeof data !== "string" && "id" in data && { ...toSend },
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteFrequencyCatalogueGroup(typeof data === "string" && data, machineId).then(
          (response) => onSuccess("status" in response, context),
        );
        break;
    }

    handleClose();
  });

  const handleDelete = async () => {
    setLoading(true);

    await deleteFrequencyCatalogueGroup(typeof data === "string" && data, machineId).then(
      (response) => onSuccess("status" in response, context),
    );

    handleClose();
  };

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog {...rest} onOpenChange={handleClose}>
          <BaseDialogTitle>
            {context.charAt(0).toUpperCase() +
              context.slice(1) +
              " " +
              t("Frequency Catalogue Group")}
          </BaseDialogTitle>
          <DialogContent>
            {["add", "edit"].includes(context) ? (
              <form onSubmit={onSubmit}>
                {renderFormItems(modifyFieldsBasedOnContext(t, context), {
                  control,
                  errors: errors as { [schemaProp: string]: FieldError },
                })}
                <DialogActions>
                  <Button
                    appearance='primary'
                    type='submit'
                    disabled={isLoading || !isValid}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                  >
                    {t("Save Changes")}
                  </Button>
                  <Button appearance='transparent' onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                </DialogActions>
              </form>
            ) : ["delete"].includes(context) ? (
              <>
                <div style={textStyle}>
                  {t("You are about to delete selected Frequency Catalogue Group.")}
                </div>

                <DialogActions>
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                    onClick={handleDelete}
                  >
                    {t("Delete")}
                  </Button>
                  <Button onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
              </>
            ) : null}
          </DialogContent>
        </BaseDialog>
      )}
    </>
  );
};
