/* eslint-disable react-hooks/exhaustive-deps */
import "../CSS/index.css";

import type { AxiosInstance } from "axios";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { notification } from "../../common/Notification";
import VpnConnectionsSection from "../internal/VpnConnectionsSection";
import { VpnConnectionsAPI } from "../Schema/api";
import type { MetadataProject } from "../Schema/models";
import type { VpnConnectionsManagerProps } from "./VpnConnectionsManager.types";

// Contexts
export const AxiosContext = createContext<AxiosInstance | null>(null);

/**
 * Gets the VPN Connections Manager component.
 * @param hasAdminPermissions value determining whether there is administrator permissions.
 * @param axiosInstance The axios instance.
 * @returns The VPN Connections manager component
 */
const VpnConnectionsManager = ({
  hasAdminPermissions,
  axiosInstance,
  onClose,
}: VpnConnectionsManagerProps) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<MetadataProject[]>([]);

  // Gets the projects list.
  useEffect(() => {
    if (!axiosInstance) {
      notification.error(t("No action instance object detected."));
      onClose?.();
      return;
    }

    VpnConnectionsAPI.listProjects(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the projects list: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );

        onClose?.();
        return;
      }

      if (response.data.length === 0) {
        notification.error(
          t(
            `No projects found. Please check whether you have the appropriate permissions to access to this page.`,
          ),
        );

        onClose?.();
        return;
      }

      setProjects(response.data);
    });
  }, []);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {projects?.length > 0 && (
        <VpnConnectionsSection
          hasAdminPermissions={hasAdminPermissions}
          projects={projects}
          onClose={() => onClose?.()}
        />
      )}
    </AxiosContext.Provider>
  );
};

export default VpnConnectionsManager;
