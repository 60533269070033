import { Button, Checkbox, makeStyles, Tree, TreeItem } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronRightRegular } from "@fluentui/react-icons";
import type { CSSProperties } from "react";
import { useState } from "react";

type treeDataBranch = {
  id: string;
  label: string;
  check?: boolean;
};

type treeDataItem = treeDataBranch & {
  children?: treeDataBranch[];
};

type SelectionZoneProps = {
  treeData: treeDataItem[];
  style?: CSSProperties;
  onSelectionChange?: any;
};

const useStyles = makeStyles({
  branchCheckBox: {
    "& .fui-Label": {
      fontWeight: 700,
      padding: 0,
    },
  },
});

const CheckBoxLabel = ({ item, openBranches, setOpenBranches }) => {
  const onClick = () => {
    setOpenBranches((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
  };
  return (
    <Button
      style={{ minWidth: 0, padding: 0 }}
      appearance='transparent'
      icon={openBranches[item.id] ? <ChevronDownRegular /> : <ChevronRightRegular />}
      onClick={onClick}
    >{`${item.label} (${item.children.length})`}</Button>
  );
};

export const SelectionZone = ({ treeData = [], style, onSelectionChange }: SelectionZoneProps) => {
  const { branchCheckBox } = useStyles();
  const [openBranches, setOpenBranches] = useState(() =>
    treeData.reduce((acc, curr) => {
      acc[curr.id] = false;
      return acc;
    }, {}),
  );

  return (
    <Tree aria-label='root-tree' style={style}>
      {treeData.map((item) => {
        const childrenIds = item.children.map(({ id }) => id);
        const everyChildIsChecked = item.children.every(({ check }) => !!check);
        const firstCheckedChild = item.children.find(({ check }) => !!check);
        const checkedBranch = everyChildIsChecked ? true : firstCheckedChild ? "mixed" : false;

        return (
          <TreeItem
            key={item.id}
            open={openBranches[item.id]}
            itemType='branch'
            onClick={(e) => e.preventDefault()}
          >
            <Checkbox
              className={branchCheckBox}
              label={
                <CheckBoxLabel
                  item={item}
                  openBranches={openBranches}
                  setOpenBranches={setOpenBranches}
                />
              }
              checked={checkedBranch}
              onClick={(e) => e.stopPropagation()}
              onChange={(e, data) => {
                return onSelectionChange((items) =>
                  items.map((item) => {
                    if (childrenIds.includes(item.key)) {
                      return { ...item, check: data.checked };
                    }
                    return item;
                  }),
                );
              }}
            />
            {item?.children?.length > 0 && (
              <Tree aria-label='children-tree' style={{ paddingLeft: 8 }}>
                {item.children.map((child) => (
                  <TreeItem key={child.id} itemType='leaf'>
                    <Checkbox
                      label={child.label}
                      checked={child.check}
                      onChange={(e, data) => {
                        const childId = child.id;

                        onSelectionChange((items) =>
                          items.map((item) => {
                            if (childId === item.key) {
                              return { ...item, check: data.checked };
                            }
                            return item;
                          }),
                        );
                      }}
                    />
                  </TreeItem>
                ))}
              </Tree>
            )}
          </TreeItem>
        );
      })}
    </Tree>
  );
};
