import { Text } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../Hooks";
import { notification } from "../common/Notification";
import { ConfirmDialog } from "../Generic/ConfirmDialog";
import { Stack } from "../Stack";
import { DataloggersAPI } from "./api";
import { DModels } from "./models";
import { listDataloggersAsync } from "./reducer";
import type {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "./tableItemModels";

type DeleteMessageProps = {
  model: DModels;
  entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
};

export type DataloggerDeleteDialogProps = DeleteMessageProps & {
  onClose: () => void;
};

const DeleteMessage = ({ model, entry }: DeleteMessageProps) => {
  const { t } = useTranslation();
  const style: CSSProperties = {
    marginLeft: "3em",
  };

  let messageData: any = { Model: model, Project: entry.projectName };
  switch (model) {
    case DModels.D325:
      const d325Entry = entry as D325TableItem;
      messageData = {
        MacAddress: d325Entry.macAddress,
        Firmware: d325Entry.firmwareVersion,
        ...messageData,
      };

      break;
    case DModels.D555:
    case DModels.D650:
      const dBasicEntry = entry as DBasicTableItem;
      messageData = {
        MacAddress: dBasicEntry.macAddress,
        Firmware: dBasicEntry.firmwareVersion,
        IpAddress: dBasicEntry.ipAddress,
        SubnetMask: dBasicEntry.subnetMask,
        Gateway: dBasicEntry.gateway,
        ...messageData,
      };

      break;

    case DModels.D850Eco:
      const d850EcoEntry = entry as D850EcoTableItem;
      messageData = {
        BasisCardMacAddress: d850EcoEntry.macAddress,
        ...messageData,
      };

      break;

    case DModels.D850:
      const d850Entry = entry as D850TableItem;
      messageData = {
        BasisCardMacAddress: d850Entry.macAddress,
        OperativeSystem: d850Entry.operativeSystem,
        AdjustmentCardType: d850Entry.adjustmentCardType,
        ...messageData,
      };

      break;
  }

  return (
    <Stack>
      <Text>{t("The following datalogger will be permanently deleted:")}</Text>
      <br />
      {React.Children.map(Object.keys(messageData), (key) => {
        return <Text style={style}>{`${key}: ${messageData[key]}`}</Text>;
      })}
      <br />
      <Text as='p'>{t("Do you want to continue?")}</Text>
    </Stack>
  );
};

const DataloggerDeleteDialog = ({ model, entry, onClose }: DataloggerDeleteDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    DataloggersAPI.delete(model, entry.id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure: Deleting a {{model}} datalogger. Please try again later.`, { model }),
        );
        return;
      }

      notification.success(t(`Success: Deleting a {{model}} datalogger.`, { model }));
      dispatch(listDataloggersAsync(model)());
      onClose?.();
    });
  };

  return (
    <ConfirmDialog
      title={"Delete Datalogger"}
      isLoading={isLoading}
      onConfirm={handleConfirm}
      onDismiss={() => {
        onClose?.();
      }}
    >
      <DeleteMessage model={model} entry={entry} />
    </ConfirmDialog>
  );
};

export default DataloggerDeleteDialog;
