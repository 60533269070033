/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, makeStyles, Text } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import { getObjectUrlFromBase64 } from "../../../../schema/Utils";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";
import type { AcceptedFile } from "../../../common/Uploader/Uploader";
import { Stack } from "../../../Stack";
import { updateProfilePciture } from "../../../UserAccount/reducer";
import {
  selectUserAccountInformationProperties,
  selectUserAccountProfilePicture,
} from "../../../UserAccount/selectors";

const useStyles = makeStyles({
  persona: {
    width: "120px",
    height: "120px",

    "& .fui-Avatar__initials": {
      width: "120px",
      height: "120px",
      fontSize: "40px",
    },
  },
});

/**
 * Gets the command bar item props
 * @param onUpload
 * @param onDelete
 * @returns
 */
const getCommandBarItemProps = (
  t,
  onUpload: (files: AcceptedFile[]) => void,
  onDelete: () => void,
): any => {
  const result: any = [
    {
      key: "upload-photo",
      text: t("Upload image"),
      ariaLabel: "Upload photo",
      type: CommandBarItemType.Upload,
      onRenderProps: {
        maxSize: 100000000,
        accept: "image/*",
        multiple: false,
        onChange: onUpload,
      },
    },
    {
      key: "delete-photo",
      text: t("Remove image"),
      ariaLabel: "Delete photo",
      type: CommandBarItemType.Button,
      icon: <DeleteRegular />,
      onClick: () => onDelete(),
    },
  ];

  return result;
};

/**
 * Gets the update user photo dialog component.
 * @param onClose Method called to close this dialog.
 * @returns The update user photo dialog component.
 */
const UpdateAvatar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const profilePicture = useAppSelector(selectUserAccountProfilePicture);
  const accountDetails = useAppSelector(selectUserAccountInformationProperties);

  const dispatch = useAppDispatch();

  const onDeleteClick = () => {
    dispatch(updateProfilePciture(""));
  };

  const onUploadFileClick = (files: AcceptedFile[]) => {
    const file = files[0]?.file;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target?.result;
      dispatch(updateProfilePciture(btoa(bstr as string)));
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Stack
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Text block>
        <Text block className='section-container-header-title'>
          {t("Profile Image")}
        </Text>
        <Text
          block
          style={{
            color: "#605E5C",
            marginTop: "8px",
            lineHeight: "20px",
            maxWidth: "600px",
          }}
        >
          {t(
            "This is your account image. Note that if no image is selected, the initials of your name will be displayed instead.",
          )}
        </Text>
      </Text>

      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItemProps(t, onUploadFileClick, onDeleteClick))}
      />

      <Avatar
        name={accountDetails?.displayName}
        image={{
          src: profilePicture ? getObjectUrlFromBase64(profilePicture) : undefined,
        }}
        color='brand'
        size={120}
        className={classes.persona}
      />
    </Stack>
  );
};

export default UpdateAvatar;
