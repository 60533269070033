import { Divider } from "@fluentui/react-components";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { Status } from "../../../schema/status";
import { listAsyncGateways, selectWirelessGatewaysStatus } from "../../Gateways/reducer";
import { listAsyncMachines, selectMachinesStatus } from "../../Machines/reducer";
import { listAsyncProjs, selectProjects, selectProjectsStatus } from "../../Projects/reducer";
import type { WirelessSensorNodeResponse } from "../../SensorNodes/models";
import { Stack } from "../../Stack";
import { CompanyFields } from "./Companies/CompanyFields";
import { CorporationFields } from "./Corporation/CorporationFields";
import GatewaySupportInput from "./Gateways/GatewaySupportInput";
import { MachineSupportInput } from "./Machines/MachineSupportInput";
import { ProjectFields } from "./Projects/ProjectFields";
import { SensorFields } from "./SensorNodes/SensorFields";
import type { SecondPanelContext } from "./SubmissionsDetails";

const scrollableContentStyles: React.CSSProperties = {
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
};

interface DetailsPanelProps {
  context: SecondPanelContext;
  sensorNodes: WirelessSensorNodeResponse[];
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({ context, sensorNodes }: DetailsPanelProps) => {
  const { t } = useTranslation();
  const status = useAppSelector(selectWirelessGatewaysStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);

  // Gets the machines and projects list.
  useEffect(() => {
    if (machineStatus !== Status.void) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStatus]);

  useEffect(() => {
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
  }, [dispatch, projectStatus]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle) dispatch(listAsyncGateways(projs));
  }, [dispatch, projectStatus, status, projs]);

  return (
    <Stack style={{ minWidth: "350px", maxWidth: "500px", gap: 10 }}>
      <Divider style={{ marginTop: "8px" }} />
      <Stack verticalFill>
        <Stack.Item grow={1} style={scrollableContentStyles}>
          <CorporationFields context={context} />
          {context.initialValues.comp && (
            <>
              <CompanyFields context={context} />

              {context.initialValues.proj && (
                <>
                  <ProjectFields context={context} />
                  {context.initialValues.gat ? (
                    <>
                      <GatewaySupportInput context={context} />
                    </>
                  ) : (
                    context.initialValues.mach && (
                      <>
                        <MachineSupportInput
                          context={context}
                          matchUniqueId={context.initialValues.mach.uniqueId}
                        />

                        {context.initialValues.sen && (
                          <SensorFields
                            key={context.initialValues.sen?.uniqueId}
                            context={context}
                            sensorNodes={sensorNodes}
                          />
                        )}
                      </>
                    )
                  )}
                </>
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default DetailsPanel;
