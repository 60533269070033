import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import countries from "../../schema/country-by-continent";
import { Status } from "../../schema/status";
import Combobox from "../common/Combobox";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { listAsyncCompanies, selectCompaniesStatus } from "../Companies/reducer";
import FormItemRow from "../Generic/FormItemRow";
import { addProject, editProject } from "./api";
import type { ProjectUpdate } from "./models";

type AddOrEditDialogProps = Omit<DialogProps, "children" | "open"> & {
  options: any[];
  data: ProjectUpdate | null;
  items: ProjectUpdate[];
  show: boolean;
  onSuccess: (hasError: boolean, data: ProjectUpdate, context: "add" | "edit") => void;
  onClose: () => void;
};

const getSchema = (t, projects: ProjectUpdate[]) =>
  z
    .object({
      id: z.string().optional(),
      name: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maximumCharacterLength}} character(s)`, {
            maximumCharacterLength: maxLengthType1,
          }),
        }),
      city: z
        .string()
        .max(maxLengthType1, {
          message: t(`City must contain at most {{maximumCharacterLength}} character(s)`, {
            maximumCharacterLength: maxLengthType1,
          }),
        })
        .optional(),
      country: z
        .string()
        .max(maxLengthType1, {
          message: t(`Country must contain at most {{maximumCharacterLength}} character(s)`, {
            maximumCharacterLength: maxLengthType1,
          }),
        })
        .optional(),
      continent: z
        .string()
        .max(maxLengthType1, {
          message: t(`Continent must contain at most {{maximumCharacterLength}} character(s)`, {
            maximumCharacterLength: maxLengthType1,
          }),
        })
        .optional(),
      companyId: z.string().optional(),
      latitude: z
        .union([
          z.string().length(0, { message: t("Try a number between (-90.0:90.0)") }),
          z.string().regex(/^[-]?\d+[.]?\d*$/),
        ])
        .optional()
        .transform((e) => (e === "" ? "" : e)),
      longitude: z
        .union([
          z.string().length(0, { message: t("Try a number between (-180.0:180.0)") }),
          z.string().regex(/^[-]?\d+[.]?\d*$/),
        ])
        .optional()
        .transform((e) => (e === "" ? "" : e)),
    })
    .refine(
      (input) => {
        if (!input.name) {
          return true;
        }

        return (
          projects
            .map(({ name }) => name.trim().toLowerCase())
            .findIndex((value) => value === input.name.trim().toLowerCase()) === -1
        );
      },
      {
        path: ["name"],
        message: t("The project already exists"),
      },
    )
    .refine(
      (input) => {
        if (!input.latitude) {
          return true;
        }

        return Number(input.latitude) >= -90.0 && Number(input.latitude) <= 90.0;
      },
      {
        path: ["latitude"],
        message: t("Try between (-90.0:90.0)"),
      },
    )
    .refine(
      (input) => {
        if (!input.longitude) {
          return true;
        }

        return Number(input.longitude) >= -180.0 && Number(input.longitude) <= 180.0;
      },
      {
        path: ["longitude"],
        message: t("Try between (-180.0:180.0)"),
      },
    );

export function getContinentOptions(countries: { country: string; continent: string }[]): any[] {
  const depArray = countries.map((count) => count.continent);

  const continents = depArray.filter(function (elem, pos) {
    return depArray.indexOf(elem) === pos;
  });

  const options: any[] = continents.map((cont) => {
    return { key: cont, text: cont };
  });

  return options;
}

export function getCountriesOptions(continent: string): any[] {
  const options: any[] = countries
    .filter((cont) => cont.continent === continent)
    .map((ele) => {
      return { key: ele.country, text: ele.country };
    });

  return options;
}

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data ? name.trim().toLowerCase() !== data.name.trim().toLowerCase() : true,
      ),
    [items, data],
  );

  const schema = useMemo(() => getSchema(t, filteredItems), [t, filteredItems]);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [companyId, setCompanyId] = useState<string>(data ? data.companyId : undefined);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      companyId: data?.companyId || "",
      id: data?.id || "",
      name: data?.name || "",
      continent: data?.continent || "",
      country: data?.country || "",
      city: data?.city || "",
      longitude: data?.longitude?.toString() || "0",
      latitude: data?.latitude?.toString() || "0",
    },
  });

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    const toSend: ProjectUpdate = {
      companyId: companyId,
      id: data && data.id,
      name: formData.name,
      continent: formData?.continent,
      country: formData.country,
      city: formData.city,
      latitude: Number(formData.latitude) as number,
      longitude: Number(formData.longitude) as number,
    };

    const mutation = data ? editProject : addProject;
    await mutation(toSend).then((response) =>
      onSuccess("status" in response, formData as ProjectUpdate, data ? "edit" : "add"),
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  const projectsFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name *") },
    },
    {
      name: "continent",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Continent") },
      options: getContinentOptions(countries),
    },
    {
      name: "country",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Country") },
      options: getCountriesOptions(watch().continent as string),
    },
    {
      name: "city",
      type: FormItemType.TextField,
      groupProps: { label: t("City") },
    },
    {
      name: "latitude",
      type: FormItemType.TextField,
      groupProps: { label: t("Latitude") },
    },
    {
      name: "longitude",
      type: FormItemType.TextField,
      groupProps: { label: t("Longitude") },
    },
  ];

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{data ? t("Edit new project") : t("Add new project")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormItemRow label={t("Company *")}>
            <Combobox
              options={options}
              value={companyId}
              onChange={(key) => setCompanyId(`${key}`)}
            />
          </FormItemRow>
          {renderFormItems(projectsFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isValid || !companyId || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
