import { Image } from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useApiStore from "../../../../../modules/core/apiClient/useApiStore";
import { NoData } from "../../../../common/NoData";
import { TriggeringStatsGroupingTypes, useLoadingHOC, useTriggeringStats } from "../hooks";

const TriggeringStatistics: React.FC = () => {
  const { t } = useTranslation();
  const { projectId }: any = useParams();
  const apiStore = useApiStore();
  const { stats: gatewaysStats, statsLoading: gatewayStatsLoading } = useTriggeringStats(
    apiStore.apiClient,
    projectId,
    TriggeringStatsGroupingTypes.gateways,
  );
  const { stats: sensorsStats, statsLoading: sensorsStatsLoading } = useTriggeringStats(
    apiStore.apiClient,
    projectId,
    TriggeringStatsGroupingTypes.sensors,
  );
  return (
    <div className='triggering-statistics'>
      <span className='subtitle'>{t("Sensor nodes view")}</span>
      <span className='description'>{t("Statistics from sensor nodes results")}</span>
      {useLoadingHOC(
        !sensorsStatsLoading,
        sensorsStats !== undefined ? (
          <Image src={sensorsStats} fit='contain' />
        ) : (
          <NoData
            text={t("There is no data")}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        ),
      )}
      <span className='subtitle'>{t("Gateway view")}</span>
      {useLoadingHOC(
        !gatewayStatsLoading,
        gatewaysStats !== undefined ? (
          <Image src={gatewaysStats} fit='contain' />
        ) : (
          <NoData
            text={t("There is no data")}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        ),
      )}
    </div>
  );
};

export default TriggeringStatistics;
