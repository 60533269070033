import { Text } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import FastTrendSignalIcon from "../../../assets/svg/FastTrendSignalIcon";
import RawDataSignalIcon from "../../../assets/svg/RawDataSignalIcon";
import TrendSignalIcon from "../../../assets/svg/TrendSignalIcon";
import { Stack } from "../../../Components/Stack";

const TrendViewSelectSignalsInfo = () => {
  const { t } = useTranslation();

  return (
    <Stack style={{ background: "#fff", padding: "1rem" }}>
      <Stack horizontal verticalAlign='center' style={{ marginBottom: "8px" }}>
        <TrendSignalIcon />
        <Text style={{ marginLeft: "8px" }}>{t("Trend")}</Text>
      </Stack>
      <Stack horizontal verticalAlign='center' style={{ marginBottom: "8px" }}>
        <FastTrendSignalIcon />
        <Text style={{ marginLeft: "8px" }}>{t("Fast Trend")}</Text>
      </Stack>
      <Stack horizontal verticalAlign='center'>
        <RawDataSignalIcon />
        <Text style={{ marginLeft: "8px" }}>{t("Raw Data")}</Text>
      </Stack>
    </Stack>
  );
};

export default TrendViewSelectSignalsInfo;
