import { MessageBar, MessageBarBody, Text } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import FormDialog from "../Generic/FormDialog";
import FormItemRow from "../Generic/FormItemRow";
import { Stack } from "../Stack";
import { UserNotificationsAPI } from "./api";
import type { UserNotificationState } from "./EditUserNotificationPage";
import { paddingStyle, subtitleStyle, textFieldStyle } from "./globalStyles";
import type { TreeNode } from "./models";
import { UserNotificationSettingsType } from "./models";
import { buildUserNotificationSettings } from "./utils";

type UserNotificationsConfirmDialogProps = {
  userId: string;
  emailsState: UserNotificationState;
  teamsState: UserNotificationState;
  onClose: () => void;
};

type DialogSectionProps = {
  title: string;
  state: UserNotificationState;
};

/**
 * Gets the entities list as string.
 * @param entitiesList The entities map.
 * @returns The entities as string, separated by commas.
 */
const getEntitiesString = (entitiesList: Map<string, TreeNode<boolean>>): string => {
  let result = "";

  const selectedEntities: string[] = Array.from(entitiesList.values())
    .filter((e) => e.value === true)
    ?.map((e) => e.name);

  while (selectedEntities?.length > 0) {
    const entityName = selectedEntities.pop();
    result += selectedEntities.length === 0 ? `${entityName}.` : `${entityName}, `;
  }

  return result;
};

/**
 * Gets a color string, depending on the user notification settings type.
 * @param settingsType The user notification settings type.
 * @returns The string, indicating a CSS color.
 */
const getStateColorString = (settingsType: UserNotificationSettingsType): string => {
  switch (settingsType) {
    case UserNotificationSettingsType.Disabled:
      return "rgb(246, 63, 77)";
    case UserNotificationSettingsType.Enabled:
      return "rgb(102, 205, 125)";
    case UserNotificationSettingsType.Selected:
      return "rgb(255, 126, 13)";
  }
  return "rgb(50, 49, 48)";
};

/**
 * Gets a dialog section component.
 * @param title The dialog title.
 * @param state The user notification state.
 * @returns The dialog section component.
 */
const DialogSection = ({ title, state }: DialogSectionProps) => {
  const { t } = useTranslation();

  const settingsType = UserNotificationSettingsType[state.key];

  return (
    <Stack>
      <Stack.Item>
        <h3 style={subtitleStyle}>{title}</h3>
      </Stack.Item>
      <Stack.Item style={paddingStyle}>
        <FormItemRow label={t("State:")}>
          <Text
            size={300}
            style={{
              fontWeight: "600",
              color: getStateColorString(settingsType),
            }}
          >
            {settingsType}
          </Text>
        </FormItemRow>
      </Stack.Item>

      {settingsType === UserNotificationSettingsType.Selected && (
        <Stack.Item style={paddingStyle}>
          <Stack>
            <FormItemRow label={t("Corporations:")}>
              <Stack style={textFieldStyle} verticalAlign='center'>
                <Text size={300}>{getEntitiesString(state.tree.corporations)}</Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label={t("Companies:")}>
              <Stack style={textFieldStyle}>
                <Text size={300}>{getEntitiesString(state.tree.getCompanies())}</Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label={t("Projects:")}>
              <Stack style={textFieldStyle}>
                <Text size={300}>{getEntitiesString(state.tree.getProjects())}</Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label={t("Machines:")}>
              <Stack style={textFieldStyle}>
                <Text size={300}>{getEntitiesString(state.tree.getMachines())}</Text>
              </Stack>
            </FormItemRow>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

/**
 * Gets the user notifications confirm dialog.
 * @param userId The logged user ID
 * @param emailsState The emails notification settings state.
 * @param teamsState The Microsoft Teams notification settings state.
 * @param onClose Method called when this dialog needs to be closed.
 * @returns The user notifications confirm dialog.
 */
const UserNotificationsConfirmDialog = ({
  userId,
  emailsState,
  teamsState,
  onClose,
}: UserNotificationsConfirmDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handlers
  const onSubmit = () => {
    if (!emailsState.key || !teamsState.key) {
      return;
    }

    setIsLoading(true);
    const settings = buildUserNotificationSettings(emailsState, teamsState);
    UserNotificationsAPI.updateUserNotificationSettings(userId, settings).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(t("Failure: Updating user notifications settings."));
        return;
      }

      notification.success(t("Success: Updating user notifications settings."));
      onClose?.();
    });
  };

  return (
    <FormDialog
      title={t("Confirm Settings")}
      isLoading={isLoading}
      isValid={true}
      size={DialogSize.M}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Stack style={{ gap: 10 }}>
        <MessageBar intent='info'>
          <MessageBarBody>
            {t("Please check and confirm whether these are the settings you want to submit.")}
          </MessageBarBody>
        </MessageBar>
        <DialogSection title={t("Notifications by Email")} state={emailsState} />
        <DialogSection title={t("Notifications by Microsoft Teams")} state={teamsState} />
      </Stack>
    </FormDialog>
  );
};

export default UserNotificationsConfirmDialog;
