import { useMemo, useState } from "react";

export type UseSearch<T> = {
  data: T;
  search: string;
  handleSearch: (value: string) => void;
};

export type UseSearchProps<T> = {
  data: T[];
  keys: Array<keyof T>;
};

export const valueIncludes = (value: unknown, search: string): boolean =>
  value?.toString().trim().toLowerCase().includes(search) || false;

export const useSearch = <T>({ data, keys }: UseSearchProps<T>): UseSearch<T[]> => {
  const [search, setSearch] = useState("");

  const handleSearch = (value: string) => setSearch(value);

  const filteredData = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();

    return data?.filter((item) =>
      keys.reduce((valid, key) => valid || valueIncludes(item[key], normalizedSearch), false),
    );
  }, [search, data]);

  return {
    data: filteredData,
    handleSearch,
    search,
  };
};
