import type { TagPickerProps } from "@fluentui/react-components";
import {
  Field,
  makeStyles,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Text,
  useTagPickerFilter,
} from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch } from "../../../../../Hooks";
import { Stack } from "../../../../Stack";
import { useTagsQuery } from "../../hooks/useTagsQuery";
import useTagsStore from "../../hooks/useTagsStore";

const useStyles = makeStyles({
  primaryText: {
    "& .fui-Tag__primaryText": {
      padding: 0,
    },
  },
});

export default function SearchByTags({ ...rest }) {
  const { t } = useTranslation();
  const { primaryText } = useStyles();

  const [{ id: machineId }, ,] = useLocationSearch();
  const { tags } = useTagsQuery({ machineId: machineId as string });

  const [query, setQuery] = useState<string>("");
  const { selectedTags, updateStore } = useTagsStore((store: any) => ({
    selectedTags: store.selectedTags,
    updateStore: store.updateStore,
  }));

  const selectedOptions = selectedTags.map((p) => p.name);

  const tagPickerOptions = tags
    .map((p) => p.name)
    .filter((option) => !selectedOptions.includes(option));

  const children = useTagPickerFilter({
    query,
    options: tagPickerOptions,
    noOptionsElement: (
      <TagPickerOption value='no-matches'>{t("No options available")}</TagPickerOption>
    ),
    renderOption: (option) => (
      <TagPickerOption key={option} value={option}>
        {option}
      </TagPickerOption>
    ),

    filter: (option) =>
      !selectedOptions.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
  });

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-matches") {
      return;
    }

    updateStore({ selectedTags: data.selectedOptions.map((p) => ({ key: p, name: p })) });
    setQuery("");
  };

  return (
    <Stack verticalAlign='center' style={{ marginRight: 8, width: "max-content" }} {...rest}>
      <Field>
        <TagPicker selectedOptions={selectedOptions} onOptionSelect={onOptionSelect}>
          <TagPickerControl style={{ minWidth: "200px" }}>
            {selectedTags?.length === 0 && query.length === 0 ? (
              <Text style={{ position: "absolute" }}>{t("Pick a tag")}</Text>
            ) : null}
            <TagPickerGroup aria-label='Selected Employees'>
              {selectedOptions.map((option) => (
                <Tag key={option} className={primaryText} shape='rounded' value={option}>
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput
              aria-label='Select Employees'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </TagPickerControl>

          <TagPickerList>{children}</TagPickerList>
        </TagPicker>
      </Field>
    </Stack>
  );
}
