import type { AxiosInstance } from "axios";
import * as ReactRouterDOM from "react-router-dom";

import { useLocationSearch } from "../../../Hooks";
import AppLayout from "../../../templates/AppLayout";
import MachineCVDriveLoadPage from "./MachineCVDriveLoadPage";

interface DriveLoadProps {
  axiosInstance: AxiosInstance;
}

const DriveLoad = ({ axiosInstance }: DriveLoadProps) => {
  const [, , isValid] = useLocationSearch();

  if (!isValid) {
    return <ReactRouterDOM.Navigate replace to='/error' />;
  }

  return (
    <AppLayout axiosInstance={axiosInstance}>
      <MachineCVDriveLoadPage />
    </AppLayout>
  );
};

export default DriveLoad;
