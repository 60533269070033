import type { AxiosInstance } from "axios";
import * as ReactRouterDOM from "react-router-dom";

import { useLocationSearch } from "../../../Hooks";
import AppLayout from "../../../templates/AppLayout";
import Page from "./Page";

interface ThreadsProps {
  axiosInstance: AxiosInstance;
}

const Threads = ({ axiosInstance }: ThreadsProps) => {
  const [, , isValid] = useLocationSearch();

  if (!isValid) {
    return <ReactRouterDOM.Navigate replace to='/error' />;
  }

  return (
    <AppLayout axiosInstance={axiosInstance}>
      <Page />
    </AppLayout>
  );
};

export default Threads;
