import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTrendMeasuredDataInAPeriod } from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import getPeriodFilters from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import usePerformanceStore from "../../hooks/usePerformanceStore";
import { ChartSection } from "./ChartSection";
import Header from "./Header";
import KpiTable from "./KpiTable";
import { getWorstBestCurrentPerformance } from "./methods";
import StatisticsTabs from "./Tabs";
import { STATISTICS_TAB_1 } from "./Tabs/config";

const Statistics = ({ signals, performance }: any) => {
  const { t } = useTranslation();
  const { statisticsActiveTab, period } = usePerformanceStore((store: any) => ({
    statisticsActiveTab: store.statisticsActiveTab,
    updateStore: store.updateStore,
    period: store.period,
  }));

  const perfSignal = useMemo(
    () => signals.find(({ id }: any) => id === performance?.perfSignalId),

    [signals.length, performance?.perfSignalId],
  );

  const perfPeriodFilter = useMemo(
    () => getPeriodFilters(period, perfSignal),
    [period?.key, perfSignal],
  );

  const { data: perfSignalData, isLoading } = useTrendMeasuredDataInAPeriod(
    {
      startDate: perfPeriodFilter.startDate,
      endDate: perfPeriodFilter.endDate,
      queryKey: [
        "performance-signal",
        perfSignal?.id,
        perfPeriodFilter?.startDate,
        perfPeriodFilter?.endDate,
        "off",
      ],
      machineId: perfSignal?.machineId,
      signalId: perfSignal?.id,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: perfSignal?.dataType,
      onlyZip: true,
      refreshInterval: { key: "off", text: t("Off") },
    },
    {
      enabled: !!perfSignal?.id,
      retry: false,
    },
  );

  const { bestPerformance, worstPerformance } = useMemo(
    () => getWorstBestCurrentPerformance(perfSignalData),
    [perfSignalData],
  );

  const tableProps = {
    bestPerformance,
    worstPerformance,
    perfPeriodFilter,
    signals,
    tableKeyName: statisticsActiveTab,
  };

  const loading = isLoading && !!perfSignal;
  const noData = !isLoading && !perfSignal;

  const kpiSignals = useMemo(
    () =>
      statisticsActiveTab === STATISTICS_TAB_1
        ? performance.performancePageParamSignals
        : performance.performancePageKpiSignals,
    [
      performance.performancePageKpiSignals,
      performance.performancePageParamSignals,
      statisticsActiveTab,
    ],
  );

  return (
    <div className='events-container'>
      {loading && <Spin style={{ marginTop: 100 }} />}
      {noData && <NoData text={t("Data performance missing")} />}
      {!loading && !noData && (
        <>
          <Header />
          <div className='two-columns'>
            <div className='white-container'>
              <StatisticsTabs />
              <KpiTable {...tableProps} kpiSignals={kpiSignals} />
            </div>
            <div className='white-container'>
              <ChartSection />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Statistics;
