import { Spinner } from "@fluentui/react-components";

import { useGatewayDetails } from "../../hooks/useGatewayDetails";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";
import { NoData } from "../../../common/NoData";
import { useTranslation } from "react-i18next";
import GatewayTabs from "./GatewayTabs";

const Sensor = () => {
  const { t } = useTranslation();
  const { serialNumber } = useWSUrlParams();
  const { gateway, isLoading } = useGatewayDetails({ serialNumber });

  return (
    <>
      {isLoading ? (
        <Spinner style={{ marginTop: 30 }} size='small' />
      ) : (
        <>
          {gateway ? (
            <GatewayTabs key={serialNumber} gateway={gateway} />
          ) : (
            <NoData style={{ marginTop: 30 }} text={t("The gateway was not found")} />
          )}
        </>
      )}
    </>
  );
};

export default Sensor;
