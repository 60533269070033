import "./style.scss";

import type { DialogProps } from "@fluentui/react-components";
import { DialogContent } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PencilIcon from "../../../../../assets/svg/PencilIcon";
import useWebAnalysisPermissions from "../../../../../Hooks/useWebAnalysisPermissions";
import { findLocationTimezone } from "../../../../../modules/analysis-trend-view/utils/getRangeFormat";
import { apiService } from "../../../../../modules/common";
import type { BreadcrumbInterface, Project } from "../../../../../types";
import Breadcrumb from "../../../../common/Breadcrumb";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../common/Dialog";
import { notification } from "../../../../common/Notification";
import { Spin } from "../../../../common/Spin";
import { useTask } from "../../hooks/useTask";
import useTasksStore from "../../hooks/useTasksStore";
import { TaskComments } from "./TaskComments";
import { TaskDetails } from "./TaskDetails";
import { TaskDetailsDialog } from "./TaskDetails/TaskDetailsDialog";
import { TaskDocuments } from "./TaskDocuments";

type TaskEditDialogProps = Omit<DialogProps, "children"> & { onDismiss?: () => void } & {
  breadcrumb: BreadcrumbInterface;
};

export const TaskEditDialog: FC<TaskEditDialogProps> = (props) => {
  const { t } = useTranslation();
  const { onDismiss, breadcrumb } = props;

  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));

  const { project, machine } = breadcrumb;

  const { data: projectData = {} as Project } = useQuery(
    ["project-data", project?.id],
    () => apiService.metaDataRead.getProject(project?.id),
    {
      cacheTime: 100000,
      enabled: !!project?.id,
    },
  );

  const { task, getTaskDetails, isLoading, isError } = useTask({
    taskId: edit.id,
  });

  const { useReadyPermission, checkCreatorUserIDMatch } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  const hasEditDeleteActions =
    hasMachineCvAdministratorPermission || checkCreatorUserIDMatch(task?.createdBy || "");

  const [showTaskDetailsDialog, setShowTaskDetailsDialog] = useState(false);

  const onClose = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (edit.id) {
      getTaskDetails();
    }
  }, [edit.id]);

  useEffect(() => {
    if (isError) {
      onClose();
      notification.error(t("Error loading task"));
    }
  }, [isError]);

  const titleElement = (
    <>
      {task && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <div className='titleContainer'>
            <span className='title'>{task?.subject}</span>
            {hasEditDeleteActions ? (
              <PencilIcon className='pencilEdit' onClick={() => setShowTaskDetailsDialog(true)} />
            ) : null}
          </div>

          <Breadcrumb
            items={[
              { key: `project-${project.name}`, text: project.name },
              { key: `machine-${machine.name}`, text: machine.name },
            ]}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <BaseDialog
        {...props}
        hasResize
        surfaceStyle={{ minWidth: DialogSize.L }}
        onOpenChange={onClose}
      >
        <BaseDialogTitle>{titleElement}</BaseDialogTitle>
        <DialogContent>
          {isLoading && <Spin style={{ height: "100%" }} />}
          {task && (
            <>
              <TaskDetails onClose={onClose}>
                <TaskDocuments />
                <TaskComments projectTimezone={findLocationTimezone(projectData)} />
              </TaskDetails>
            </>
          )}
        </DialogContent>
      </BaseDialog>
      <TaskDetailsDialog
        id={edit.id}
        open={showTaskDetailsDialog}
        onDismiss={() => setShowTaskDetailsDialog(false)}
      />
    </>
  );
};
