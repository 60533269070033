import type { CSSProperties } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CommandBar, { CommandBarItemType } from "../../../../common/CommandBar";
import type { TableProps } from "../../../../common/Table/v9";
import Table, { useTableFilters } from "../../../../common/Table/v9";
import { Stack } from "../../../../Stack";
import { getColumns } from "./columns";

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  alignSelf: "center",
};

export interface SensorError {
  gatewayId: string;
  sensorNodeId: string;
  timestamp: string;
  selfTestErrorCodes: string[];
}

type SensorErrors = SensorError[];

const sensorErrors: SensorErrors = [
  {
    gatewayId: "gateway1",
    sensorNodeId: "sensorNode1",
    timestamp: "2022-01-01T00:00:00Z",
    selfTestErrorCodes: ["error1", "error2"],
  },
  {
    gatewayId: "gateway2",
    sensorNodeId: "sensorNode2",
    timestamp: "2022-01-02T00:00:00Z",
    selfTestErrorCodes: ["error3", "error4"],
  },
  {
    gatewayId: "gateway3",
    sensorNodeId: "sensorNode3",
    timestamp: "2022-01-03T00:00:00Z",
    selfTestErrorCodes: ["error5", "error6"],
  },
  {
    gatewayId: "gateway4",
    sensorNodeId: "sensorNode4",
    timestamp: "2022-01-04T00:00:00Z",
    selfTestErrorCodes: ["error7", "error8"],
  },
];

const ErrorsAndIncidents = () => {
  const { t } = useTranslation();
  const { filters, handleSearch } = useTableFilters<SensorError>({
    keys: ["gatewayId", "sensorNodeId", "timestamp", "selfTestErrorCodes"],
  });

  const headerItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <div style={containerTitleStyle}>
          {t("Errors and Incidents (This is temporally mock Data)")}
        </div>
      ),
    },
  ];

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: sensorErrors,
      perPage: 5,
      hidePerPage: true,
      v8Columns: getColumns(t),
      filters,
    }),
    [filters, t],
  );

  return (
    <Stack
      className='no-select'
      style={{
        position: "relative",
      }}
    >
      <CommandBar items={headerItems} onSearch={handleSearch} />
      <Table {...tableProps} />
    </Stack>
  );
};

export default ErrorsAndIncidents;
