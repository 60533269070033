import {
  type SelectTabData,
  type SelectTabEvent,
  type TabValue,
  Tab,
  TabList,
} from "@fluentui/react-components";
import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import type { AskResponse } from "../../Api";
import { SupportingContent } from "../SupportingContent/SupportingContent";
import styles from "./AnalysisPanel.module.css";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

interface Props {
  className: string;
  activeTab: AnalysisPanelTabs;
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
  activeCitation: string | undefined;
  citationHeight: string;
  answer: AskResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({
  answer,
  activeTab,
  activeCitation,
  citationHeight,
  className,
  onActiveTabChanged,
}: Props) => {
  const { t } = useTranslation();
  const isDisabledThoughtProcessTab = !answer.thoughts;
  const isDisabledSupportingContentTab = !answer.data_points.length;
  const isDisabledCitationTab = !activeCitation;

  const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

  const [selectedValue, setSelectedValue] = useState<TabValue>();

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);

    data.value && onActiveTabChanged(data.value as AnalysisPanelTabs);
  };

  return (
    <>
      <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
        <Tab value={AnalysisPanelTabs.ThoughtProcessTab} disabled={isDisabledThoughtProcessTab}>
          {t("Thought process")}
        </Tab>
        <Tab
          value={AnalysisPanelTabs.SupportingContentTab}
          disabled={isDisabledSupportingContentTab}
        >
          {t("Supporting content")}
        </Tab>
        <Tab value={AnalysisPanelTabs.CitationTab} disabled={isDisabledCitationTab}>
          {t("Citation")}{" "}
        </Tab>
      </TabList>
      <div>
        {selectedValue === AnalysisPanelTabs.ThoughtProcessTab && (
          <div
            className={styles.thoughtProcess}
            dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}
          ></div>
        )}
        {selectedValue === AnalysisPanelTabs.SupportingContentTab && (
          <SupportingContent supportingContent={answer.data_points} />
        )}
        {selectedValue === AnalysisPanelTabs.CitationTab && (
          <iframe title='Citation' src={activeCitation} width='100%' height={citationHeight} />
        )}
      </div>
    </>
  );
};
