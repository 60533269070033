import { Label, Spinner, Text } from "@fluentui/react-components";

import { Stack } from "../Stack";

/**
 * HOC to add a loading panel to a wrapped component when the data parameter is not available
 * @param condition The data
 * @param wrappedComponent The wrapped component.
 * @returns The loading panel, when the data is null. Otherwise the wrapped component.
 */
export const withLoadingPanelHOC = (
  t,
  condition: boolean,
  wrappedComponent: JSX.Element,
): JSX.Element => {
  let result: JSX.Element = (
    <Stack
      horizontalAlign='center'
      verticalAlign='center'
      style={{ minHeight: "10em", height: "90%", gap: 5, padding: 5 }}
    >
      <Spinner size='small' />
      <Label style={{ fontWeight: 600 }}>{t("Loading data...")}</Label>
    </Stack>
  );
  if (condition) {
    result = wrappedComponent;
  }

  return result;
};

/**
 * HOC to add a text to a wrapped component when the count parameter is zero
 * @param data The data
 * @param wrappedComponent The wrapped component.
 * @returns The text, when the count is zero. Otherwise the wrapped component.
 */
export const withNoItemsPanelHOC = (
  t,
  count: number,
  wrappedComponent: JSX.Element,
): JSX.Element => {
  let result: JSX.Element = (
    <Text size={300} block={true}>
      {t("No items.")}
    </Text>
  );

  if (count > 0) {
    result = wrappedComponent;
  }

  return result;
};
