import { Spinner, Text } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import type { StackProps } from "../../Stack";
import { Stack } from "../../Stack";

export type SpinProps = StackProps & {
  text?: string;
};

const Spin = ({ text, style, ...rest }: SpinProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      {...rest}
      verticalAlign='center'
      horizontalAlign='center'
      style={{ minHeight: "10em", gap: 8, ...style }}
    >
      <Spinner size='small' />
      <Text size={300} style={{ fontWeight: 600 }}>
        {text ?? t("Loading data...")}
      </Text>
    </Stack>
  );
};

export default Spin;
