import { AddRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import BaseCommandBar from "../../../../../common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../../common/CommandBar/methods";

type TaskDocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onUpload: () => void;
};

export const CommandBar = ({ handleSearch, onUpload }: TaskDocumentsCommandBarProps) => {
  const { t } = useTranslation();

  const items: any = [
    {
      key: "addDocuments",
      text: t("Add documents"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onUpload,
    },
  ];

  return <BaseCommandBar items={computeCommandBarItems(items)} onSearch={handleSearch} />;
};
