import { useCallback, useState } from "react";

import { FilePreviewModal } from "../components";
import type { FileType } from "../utils";

export const useFilePreview = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState<{
    id: string;
    name: string;
    fileType: FileType;
  } | null>(null);

  const openPreview = useCallback((id: string, name: string, fileType: FileType) => {
    setPreviewData({ id, name, fileType });
    setIsOpen(true);
  }, []);

  const closePreview = useCallback(() => {
    setIsOpen(false);
    setPreviewData(null);
  }, []);

  const PreviewModal = useCallback(() => {
    if (!previewData) return null;

    return (
      <FilePreviewModal
        isOpen={isOpen}
        id={previewData.id}
        name={previewData.name}
        fileType={previewData.fileType}
        onClose={closePreview}
      />
    );
  }, [previewData, isOpen, closePreview]);

  return {
    openPreview,
    closePreview,
    PreviewModal,
  };
};
