import { Text, Tooltip } from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Collapse from "../../../../common/Collapse";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType, renderFormFieldArrayItems } from "../../../../common/Form/FormItems/helpers";
import { Stack } from "../../../../Stack";
import { TIMED_SETTINGS } from "../../../hooks/useSensorRequests";
import { measurementSettingOptions } from "./ManualTriggerSettings";

const getTimeFormField: (t) => FormItemProps[] = (t) => [
  {
    name: "intervalInMinutes",
    type: FormItemType.TextField,
    description: t("Time in minutes"),
  },
];

const getMeasurementFormField: (t) => FormItemProps[] = (t) => [
  {
    name: "enabledMeasurementSettings",
    placeholder: t("Select measurements"),
    type: FormItemType.Dropdown,
    options: measurementSettingOptions,
    multiSelect: true,
  },
];

const toggleFormField: FormItemProps[] = [
  {
    name: "enabled",
    type: FormItemType.Toggle,
  },
];

const AutomaticTriggerSettings = ({ form }: any) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = form;

  const { fields } = useFieldArray({
    control,
    name: "triggers",
  });

  return (
    <>
      <div className='tab-subtitle'>{t("Automatic Trigger Settings")}</div>
      {fields.map((field, index) => (
        <Collapse
          key={index}
          title={TIMED_SETTINGS[index]}
          isCollapsed={false}
          headerContent={
            <>
              {renderFormFieldArrayItems(toggleFormField, { control, errors }, "triggers", index)}
            </>
          }
        >
          <div className='triggers-collapse-wrapper'>
            <div className='triggers-input-container'>
              {renderFormFieldArrayItems(
                getTimeFormField(t),
                { control, errors },
                "triggers",
                index,
              )}
            </div>
            <div className='triggers-input-container'>
              {renderFormFieldArrayItems(
                getMeasurementFormField(t),
                { control, errors },
                "triggers",
                index,
              )}
              <Tooltip
                withArrow
                relationship='description'
                content={{
                  children: (
                    <Stack>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                      >
                        {t("Measurement type")}
                      </Text>
                      <Text>
                        {t(
                          "Measurement ID 0 = Trend data | Triaxial | Acceleration and Vibration Velocity",
                        )}
                        {t("Measurement ID 1 = Raw data | Triaxial | Acceleration | 36864 samples")}
                      </Text>
                    </Stack>
                  ),
                  style: { maxWidth: "600px", margin: "12px 0 0 10px" },
                }}
              >
                <span style={{ height: "20px" }}>
                  <Info20Regular style={{ color: "rgb(44, 82, 159)" }} />
                </span>
              </Tooltip>
            </div>
          </div>
        </Collapse>
      ))}
    </>
  );
};

export default AutomaticTriggerSettings;
