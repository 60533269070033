import type { DataGridContextValue, TableColumnDefinition } from "@fluentui/react-components";
import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import type { FC } from "react";

type ResizableTableHeaderCellProps = {
  children: JSX.Element;
  column: TableColumnDefinition<any>;
  dataGridContextValue: DataGridContextValue;
};

const ResizableTableHeaderCell: FC<ResizableTableHeaderCellProps> = ({
  column: { columnId },
  dataGridContextValue: dataGrid,
  children,
}) => {
  if (!dataGrid.resizableColumns) {
    return children;
  }

  return (
    <Menu openOnContext>
      <MenuTrigger>{children}</MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}>
            Keyboard Column Resizing
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default ResizableTableHeaderCell;
