import { AddRegular } from "@fluentui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../../../assets/styles";
import FilterIcon from "../../../../../../assets/svg/FilterIcon";
import BaseCommandBar from "../../../../../common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../../common/CommandBar/methods";

enum CommentCreated {
  AllTime = "All Time",
  PastWeek = "Past Week",
  PastMonth = "Past Month",
  PastYear = "Past Year",
}

export const commentsCreatedDropdown = (t) => [
  { key: 0, text: t(CommentCreated.AllTime) },
  { key: 7, text: t(CommentCreated.PastWeek) },
  { key: 30, text: t(CommentCreated.PastMonth) },
  { key: 365, text: t(CommentCreated.PastYear) },
];

type TaskCommentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
  onFilter: (item: any) => void;
  filter: any;
};

export const CommandBar = ({
  handleSearch,
  onAdd,
  onFilter,
  filter,
}: TaskCommentsCommandBarProps) => {
  const { t } = useTranslation();
  const items: any = [
    {
      key: "newComment",
      text: t("New comment"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAdd,
    },
    {
      key: "filter",
      text: t("Created"),
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: filter.key,
        options: commentsCreatedDropdown(t),
        onChange: (_: never, { data, text }) => {
          onFilter({ key: data, text });
        },
        onRenderLabelProps: {
          label: t("Created"),
          icon: <FilterIcon fill='rgb(44, 82, 159)' style={dropdownIconStyle} />,
        },
      },
    },
  ];

  return <BaseCommandBar items={computeCommandBarItems(items)} onSearch={handleSearch} />;
};
