/* eslint-disable react-hooks/exhaustive-deps */

import { Field, Label } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import Combobox from "../common/Combobox";
import { Stack } from "../Stack";
import CheckboxesListPanel from "./CheckboxNodesList";
import type { EntitiesTree, TreeNode } from "./models";
import { UserNotificationSettingsType } from "./models";

type UserNotificationSettingsTabProps = {
  typeKey: string;
  tree: EntitiesTree;
  onKeyChanged: (key: string) => void;
  onTreeNodeChange: (node: TreeNode<boolean>) => void;
};

/**
 * Gets the user notification settings tab component.
 * @param typeKey The user notification status key.
 * @param tree The entities tree.
 * @param onKeyChanged Method called when the user notification status key is changed.
 * @param onTreeNodeChange Method called when a tree node status is changed.
 * @returns The user notification settings tab.
 */
const UserNotificationDialogTab = ({
  typeKey,
  tree,
  onKeyChanged,
  onTreeNodeChange,
}: UserNotificationSettingsTabProps) => {
  const { t } = useTranslation();

  return (
    <Stack style={{ gap: 10 }}>
      <Stack.Item align='start'>
        <Field>
          <Label style={{ fontWeight: 600 }}>{t("Select state:")}</Label>
          <Combobox
            options={Object.keys(UserNotificationSettingsType)
              .filter(
                (key) => UserNotificationSettingsType[key] !== UserNotificationSettingsType.Unknown,
              )
              .map((type) => {
                return { key: type, text: UserNotificationSettingsType[type] };
              })}
            value={typeKey}
            style={{ minWidth: "25em" }}
            onChange={(key) => onKeyChanged(key.toString())}
          />
        </Field>
      </Stack.Item>
      <Stack.Item>
        {UserNotificationSettingsType[typeKey] === UserNotificationSettingsType.Selected && (
          <Stack horizontal style={{ gap: 0 }}>
            <Stack.Item>
              <CheckboxesListPanel
                title={t("Corporations")}
                nodes={tree?.corporations}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title={t("Companies")}
                nodes={tree?.getCompanies()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title={t("Projects")}
                nodes={tree?.getProjects()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
            <Stack.Item>
              <CheckboxesListPanel
                title={t("Machines")}
                nodes={tree?.getMachines()}
                onCheckedChange={onTreeNodeChange}
              />
            </Stack.Item>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default UserNotificationDialogTab;
