import { isNaN } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../../../Components/common/Table/v9";
import { Stack } from "../../../../Components/Stack";
import type { TrendViewRawDetails } from "../../types";

interface TrendViewInfoProps {
  rawDetails: TrendViewRawDetails;
  referenceSignalRawDetails: TrendViewRawDetails | undefined;
}

const getAllDataColumns = (t) => [
  {
    key: "All data",
    fieldName: "All data",
    name: t("All data"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Signal",
    fieldName: "Signal",
    name: t("Signal"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Reference",
    fieldName: "Reference",
    name: t("Reference"),
    minWidth: 100,
    maxWidth: 100,
  },
];

const getStatisticsDataColumns = (t) => [
  {
    key: "Current view",
    fieldName: "Current view",
    name: t("Current view"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Signal",
    fieldName: "Signal",
    name: t("Signal"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Reference",
    fieldName: "Reference",
    name: t("Reference"),
    minWidth: 100,
    maxWidth: 100,
  },
];

const TrendViewInfo: React.FC<TrendViewInfoProps> = ({ rawDetails, referenceSignalRawDetails }) => {
  const { t } = useTranslation();

  return (
    <Stack
      style={{
        gap: 24,
        margin: "16px 0",
      }}
    >
      <Table
        v8Columns={getAllDataColumns(t)}
        persistOpts={{
          key: "signal-specific-data-keys",
          version: 2,
        }}
        items={[
          {
            "All data": t("Last"),
            Signal: rawDetails.all.last,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.last : "-",
          },
          {
            "All data": t("Min"),
            Signal: rawDetails.all.minimum,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.minimum : "-",
          },
          {
            "All data": t("Max"),
            Signal: rawDetails.all.maximum,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.maximum : "-",
          },
          {
            "All data": t("Avg"),
            Signal: rawDetails.all.average,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.average : "-",
          },
          {
            "All data": t("StDev"),
            Signal: rawDetails.all.stDev,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.stDev : "-",
          },
          {
            "All data": "R Corr",
            Signal: isNaN(rawDetails.all.rCorr) ? "-" : rawDetails.all.rCorr,
            Reference: "-",
          },
        ]}
      />
      <Table
        v8Columns={getStatisticsDataColumns(t)}
        persistOpts={{
          key: "signal-specific-data-values",
          version: 2,
        }}
        items={[
          {
            "Current view": t("Last"),
            Signal: rawDetails.selectedRange.last,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.last
              : "-",
          },
          {
            "Current view": t("Min"),
            Signal: rawDetails.selectedRange.minimum,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.minimum
              : "-",
          },
          {
            "Current view": t("Max"),
            Signal: rawDetails.selectedRange.maximum,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.maximum
              : "-",
          },
          {
            "Current view": t("Avg"),
            Signal: rawDetails.selectedRange.average,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.average
              : "-",
          },
          {
            "Current view": t("StDev"),
            Signal: rawDetails.selectedRange.stDev,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.stDev
              : "-",
          },
          {
            "Current view": "R Corr",
            Signal: isNaN(rawDetails.selectedRange.rCorr) ? "-" : rawDetails.selectedRange.rCorr,
            Reference: "-",
          },
        ]}
      />
    </Stack>
  );
};

export default TrendViewInfo;
