import type { DialogProps } from "@fluentui/react-components";
import type { CSSProperties } from "react";

export enum DialogSize {
  S = 450,
  M = 640,
  L = 800,
  AUTO = "auto",
}

export type BaseDialogProps = DialogProps & {
  surfaceStyle?: CSSProperties;
};
