import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { BooleanDefault, ConditionStatus } from "../../../schema/status";
import { getOptionFromEnum } from "../../../schema/Utils";
import BaseDialog, { BaseDialogTitle } from "../../common/Dialog";
import type { FormItemProps } from "../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../common/Form";
import { updateMachineFixedCondition } from "../api";
import type { MachineFixedCondition } from "../models";

const getSchema = () =>
  z.object({
    useFixedCondition: z.nativeEnum(BooleanDefault),
    fixedCondition: z.nativeEnum(ConditionStatus),
    fixedConditionComment: z.string().optional().nullable(),
  });

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "useFixedCondition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Fix Condition") },
    options: getOptionFromEnum(t, BooleanDefault),
  },
  {
    name: "fixedCondition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition") },
    options: getOptionFromEnum(t, ConditionStatus),
  },
  {
    name: "fixedConditionComment",
    type: FormItemType.TextArea,
    groupProps: { label: t("Comment") },
  },
];

type AddOrEditDialogProps = Omit<DialogProps, "children"> & {
  machineId: string;
  data: MachineFixedCondition;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
  open: boolean;
};

export const EditCondition = ({
  machineId,
  data,

  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(), []);

  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        useFixedCondition: data.useFixedCondition ? BooleanDefault.True : BooleanDefault.False,
        fixedCondition: data.fixedCondition,
        fixedConditionComment: data.fixedConditionComment,
      },
    }),
  });

  useEffect(() => {
    data
      ? reset({
          useFixedCondition: data.useFixedCondition ? BooleanDefault.True : BooleanDefault.False,
          fixedCondition: data.fixedCondition,
          fixedConditionComment: data.fixedConditionComment ? data.fixedConditionComment : "",
        })
      : reset({
          useFixedCondition: BooleanDefault.False,
          fixedCondition: ConditionStatus.NoStatus,
          fixedConditionComment: data.fixedConditionComment ? data.fixedConditionComment : "",
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: MachineFixedCondition = {
      id: data?.id,
      useFixedCondition: formData.useFixedCondition === "True" ? true : false,
      fixedCondition: formData.fixedCondition,
      fixedConditionComment: formData.fixedConditionComment,
    };

    await updateMachineFixedCondition(machineId, toSend).then((response) =>
      onSuccess("status" in response),
    );
    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {
        <BaseDialog {...rest} onOpenChange={handleClose}>
          <BaseDialogTitle>{t("Edit machine condition")}</BaseDialogTitle>
          <DialogContent>
            <form onSubmit={onSubmit}>
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}

              <DialogActions>
                <Button
                  appearance='primary'
                  type='submit'
                  disabled={isLoading || !isValid}
                  icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                >
                  {t("Save Changes")}
                </Button>
                <Button appearance='transparent' onClick={handleClose}>
                  {t("Cancel")}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </BaseDialog>
      }
    </>
  );
};
