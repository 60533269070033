import type { CheckboxProps as FluentUiCheckBoxProps } from "@fluentui/react-components";
import { Checkbox as FluentUiCheckBox } from "@fluentui/react-components";

export type CheckboxProps = Omit<FluentUiCheckBoxProps, "onChange"> & { onChange: any };

export default function Checkbox({ checked, onChange, ...rest }: CheckboxProps) {
  return (
    <FluentUiCheckBox
      checked={checked}
      onChange={(_, data) => {
        onChange(data.checked);
      }}
      {...rest}
    />
  );
}
