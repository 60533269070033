import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

type tagsStoreTypes = {
  selectedTags: any[];
  addSelectedTag: (tag: any) => void;
};

const useTagsStore = createWithEqualityFn<tagsStoreTypes>(
  (set) => ({
    selectedTags: [],
    addSelectedTag: (tag) => {
      set((store) => {
        const selectedTagsNames = store.selectedTags.map(({ name }) => name);

        if (selectedTagsNames.includes(tag?.name)) {
          return store;
        }

        return {
          ...store,
          selectedTags: [...store.selectedTags, tag],
        };
      });
    },
    updateStore: (updatedStoreProperties: any = {}) =>
      set((store) => {
        return {
          ...store,
          ...updatedStoreProperties,
        };
      }),
  }),
  shallow,
);

export default useTagsStore;
