/* eslint-disable @typescript-eslint/no-explicit-any */
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import DalogLogo from "../../../../../../assets/images/dalog-logo.svg";
import { watermarkLogos } from "../../../../../../modules/analysis-raw-data/constants/chartWatermarkLogos";
import useChartLabels from "../../../../../../modules/analysis-raw-data/hooks/useChartLabels";
import type { DataBlockPoint } from "../../../../../../types";
import { Spin } from "../../../../../common/Spin";
import type { XYChartRef } from "../../../../../common/XYChart";
import { XYChart } from "../../../../../common/XYChart";
import { createGroupedTooltip } from "../../../../../common/XYChart/tooltip";
import type { MopSignalData } from "../../../config";
import { setLegendMarkers, updateSeries } from "../methods";
import type { BaseChartProps } from "../types";

type SignalsRawChartProps = BaseChartProps & {
  data: Map<string, MopSignalData<DataBlockPoint>>;
};

const spinStyles: any = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
  background: "rgb(255, 255, 255)",
  zIndex: 2,
};

const X_AXIS_LABEL_KEY = "Time in (s)";

const SignalsRawChart = React.forwardRef<XYChartRef, SignalsRawChartProps>(
  ({ data, refresh, onDrawFinished, styles, ...rest }: SignalsRawChartProps, ref: any) => {
    const { t } = useTranslation();
    const [contentRef] = useMeasure<HTMLDivElement>();
    const shouldShowChartLabels = useChartLabels();
    const rootRef = useRef<am5.Root | null>(null);
    const chartRef = useRef<am5xy.XYChart | null>(null);
    const xAxisRef = useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null);
    const legendRef = useRef<am5.Legend | null>(null);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);

    // Instantiates the chart.
    useEffect(() => {
      if (!ref?.current) return;

      const root = ref.current.root;
      const chart = ref.current.chart;

      // === Padding ===
      chart.set("paddingRight", 10);

      // === Sending grid lines behind the chart series lines ===

      chart.gridContainer.toBack();

      // === Setting 0 base grid line opacity ===
      const myTheme = am5.Theme.new(root);

      myTheme.rule("Grid", ["base"]).setAll({
        strokeOpacity: 0.1,
      });

      root.setThemes([myTheme]);

      // === X Axis ===

      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          maxDeviation: 0,
          strictMinMax: true,
          marginTop: 16,
          extraTooltipPrecision: 2,
          tooltip: am5.Tooltip.new(root, {
            labelText: undefined,
            forceHidden: true,
            animationDuration: 0,
          }),
        }),
      );

      // === X Axis Label ===

      xAxis.children.push(
        am5.Label.new(root, {
          text: t("Time in (s)"),
          x: am5.p50,
          centerX: am5.p50,
          fontSize: 10,
        }),
      );

      // === X Axis grid opacity ===

      const xRenderer = xAxis.get("renderer");

      xRenderer.grid.template.setAll({
        strokeOpacity: 0.04,
      });

      xRenderer.labels.template.set("fontSize", 10);

      // === Legend ===

      const legend: am5.Legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(0),
          x: 0,
          paddingTop: 15,
          paddingBottom: 5,
          layout: root.gridLayout,
        }),
      );

      // === Lower marker opacity when disabled

      legend.markers.template.setup = (marker) => {
        marker.states.create("disabled", {
          opacity: 0.5,
        });
      };

      // === Legend marker label font size

      legend.labels.template.setAll({
        fontSize: 12,
      });

      // === Remove default legend marker

      legend.markerRectangles.template.setAll({
        visible: false,
        width: 0,
        height: 0,
      });

      // === Footer Label ===

      watermarkLogos.forEach((logoData) => {
        const logo = am5.Picture.new(root, {
          src: DalogLogo,
          height: logoData.height,
          opacity: logoData.opacity,
          x: logoData.x,
          y: logoData.y,
          centerX: logoData.centerX,
          centerY: logoData.centerY,
        });

        chart.plotContainer.children.push(logo);
      });

      createGroupedTooltip({
        ref: ref.current,
        type: "number",
      });

      rootRef.current = root;
      chartRef.current = chart;
      xAxisRef.current = xAxis;
      legendRef.current = legend;

      // Clears all resources.
      return () => {
        ref?.current?.chart.dispose();
        ref?.current?.root.dispose();
        ref.current = null;
      };
    }, []);

    useEffect(() => {
      if (!xAxisRef?.current) return;
      const xAxis = xAxisRef.current;

      const xAxisLabel = xAxis.children.values.find((item) => item.isType("Label"));

      xAxisLabel.set("text" as keyof am5.ISpriteSettings, t(X_AXIS_LABEL_KEY));
    }, [t]);

    // Starts refreshing the data.
    useEffect(() => {
      if (!refresh || !chartRef?.current || !xAxisRef?.current) {
        return;
      }

      // Updates the data.
      chartRef.current.hide(500).then(() => setIsDrawing(true));
    }, [refresh]);

    // Updates the data.
    useEffect(() => {
      if (
        !isDrawing ||
        !rootRef?.current ||
        !chartRef?.current ||
        !legendRef?.current ||
        !xAxisRef?.current
      ) {
        return;
      }

      // Updates the series.
      updateSeries(
        rootRef.current,
        chartRef.current,
        xAxisRef.current,
        legendRef.current,
        data,
        "date",
        "value",
      ).then((series) => {
        legendRef?.current?.data.setAll(series);
        onDrawFinished?.();
        setIsDrawing(false);
      });
    }, [isDrawing]);

    // Changes the legend markers when data is validated.
    useEffect(() => {
      if (!legendRef?.current) {
        return;
      }

      // Event handler
      const onDataValidated = () => {
        if (!rootRef?.current || !chartRef?.current || !legendRef?.current || !xAxisRef?.current) {
          return;
        }

        setLegendMarkers(rootRef.current, legendRef.current);
        chartRef?.current?.show();
      };

      legendRef.current.events.once("datavalidated", onDataValidated);
    });

    return (
      <div {...rest} ref={contentRef}>
        {isDrawing && <Spin text={t("Refreshing...")} style={spinStyles} />}
        <XYChart
          ref={ref}
          exportSelector={""}
          isMinimized={!shouldShowChartLabels}
          customSettings={{
            hideYscrollbar: true,
          }}
          styles={{
            root: {
              height: "100%",
              minHeight: "200px",
              ...styles?.root,
            },
            chart: {
              height: "100%",
              ...styles?.chart,
            },
          }}
        />
      </div>
    );
  },
);

SignalsRawChart.displayName = "SignalsRawChart";

export default SignalsRawChart;
