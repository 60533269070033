import "rc-time-picker/assets/index.css";
import "./formTimePicker.scss";

import classNames from "classnames";
import TimePicker from "rc-time-picker";
import { Controller } from "react-hook-form";

import { FormGroup } from "./FormGroup";

export const FormTimePicker = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  required,
  ...rest
}: any) => (
  <FormGroup {...groupProps}>
    <div className={classNames("form-time-picker", { error: !!errors?.[name]?.message })}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, onBlur } }) => (
          <TimePicker
            name={name}
            value={value}
            allowEmpty={required}
            defaultValue={defaultValue}
            onChange={(date: any) => {
              onChange(date);
            }}
            onBlur={onBlur}
            {...rest}
          />
        )}
      />
      {errors?.[name]?.message && <div className='error'>{errors[name].message}</div>}
    </div>
  </FormGroup>
);
