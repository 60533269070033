import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { getApiClient } from "../modules/core/apiClient/useApiStore";
import { ValidSizes } from "../Components/FIles/api";

type UseImage = {
  url: string | null;
  isLoading: boolean;
};

const useImage = (id: string | null, size?: ValidSizes): UseImage => {
  const [url, setUrl] = useState<null | string>(null);

  const { data, isError, isLoading } = useQuery(
    ["machine-cv-image", id, size],
    () =>
      getApiClient()
        .get(`/files/v1/files/${id}${size ? `?size=${size}` : ""}`, {
          responseType: "arraybuffer",
        })
        .then(({ data }) => data || {}),
    {
      enabled: !!id,
      cacheTime: 0,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }

    const imageUrl = URL.createObjectURL(new Blob([data]));
    setUrl(imageUrl);

    return () => {
      URL.revokeObjectURL(imageUrl);
      setUrl(null);
    };
  }, [isLoading, isError]);

  return {
    isLoading: !!id && (isLoading || !url) && !isError,
    url,
  };
};

export default useImage;
