import { Stack } from "../../../../../../../Stack";

function renderCondition({ text, number, color, onClick }) {
  return (
    <Stack key={text} horizontal style={{ gap: 8 }} verticalAlign='center'>
      <div className='legend-circle' style={{ background: color }} onClick={onClick} />

      <Stack>
        <Stack.Item style={{ fontWeight: 700 }}>{number}</Stack.Item>
        <Stack.Item style={{ fontSize: 10 }}>{text}</Stack.Item>
      </Stack>
    </Stack>
  );
}

export default function Legend({ legendItems }) {
  return (
    <Stack style={{ gap: 16, minWidth: "128px" }}>
      <Stack style={{ gap: 8 }}>{legendItems.map(renderCondition)}</Stack>
    </Stack>
  );
}
