import { Stack } from "../../../../../../../../Stack";
import type { SliceType } from "./utils";
import { renderPieChartSlices } from "./utils";

const PieChart = ({ slices }: { slices: SliceType[] }) => {
  return (
    <Stack
      horizontalAlign='center'
      verticalAlign='center'
      style={{
        position: "relative",
      }}
    >
      <svg
        id='pieChart'
        width='256'
        height='256'
        viewBox='0 0 200 200'
        xmlns='http://www.w3.org/2000/svg'
      >
        {renderPieChartSlices(slices)}
        <circle cx='100' cy='100' r='60' fill='white' />
      </svg>
    </Stack>
  );
};

export default PieChart;
