import {
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from "@fluentui/react-components";
import { MoreHorizontalRegular } from "@fluentui/react-icons";

const OverflowMenuItem = ({ id, onRender }) => {
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  // As an union between button props and div props may be conflicting, casting is required
  return <div style={{ padding: "4px 0" }}>{onRender()}</div>;
};

export default function OverflowMenu({ items }) {
  const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton ref={ref} icon={<MoreHorizontalRegular />} appearance='transparent' />
      </MenuTrigger>

      <MenuPopover>
        <MenuList style={{ display: "flex", flexDirection: "column", gap: 4, padding: 4 }}>
          {items.map(({ key, onRender }) => {
            return <OverflowMenuItem key={key} id={key} onRender={onRender} />;
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}
