import { EventIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { onRenderLabel } from "../../../../../common/CommandBar/methods";
import { TransparentDropdown } from "../../../../../common/Dropdown";
import { handleComboBoxChange } from "../../../../MachineCVSummaryPage/SignalsSection/methods";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";

const EventNameFilter = () => {
  const { t } = useTranslation();

  const { updateDriveLoadStore, eventsTableData, selectedFilterEventsNames } = useDriveLoadStore(
    (store: any) => ({
      eventsTableData: store.eventsTableData,
      selectedFilterEventsNames: store.selectedFilterEventsNames,
      updateDriveLoadStore: store.updateDriveLoadStore,
    }),
  );

  const [eventsNamesOptions, setEventsNamesOptions]: any = useState([]);

  useEffect(() => {
    const eventsNamesList: any = [];
    (eventsTableData || []).forEach(({ name }: any) => {
      if (!eventsNamesList.includes(name)) {
        eventsNamesList.push(name);
      }
    });
    updateDriveLoadStore({
      selectedFilterEventsNames: ["selectAll", ...(eventsNamesList || [])],
    });
    setEventsNamesOptions([
      {
        key: "selectAll",
        text: t("Select All"),
      },
      ...eventsNamesList.map((name: any) => ({ key: name, text: name })),
    ]);
  }, [eventsTableData]);

  if (eventsNamesOptions.length <= 1) return null;

  const text = eventsNamesOptions
    .filter(({ key }) => selectedFilterEventsNames.includes(key))
    .map(({ text }) => text)
    .join(", ");

  return (
    <TransparentDropdown
      button={onRenderLabel({
        label: t("Event"),
        icon: <EventIcon />,
        text,
        textStyle: { overflow: "hidden", whiteSpace: "nowrap", width: 100 },
      })}
      selectedOptions={selectedFilterEventsNames}
      options={eventsNamesOptions}
      multiselect={true}
      onOptionSelect={(_, data) => {
        handleComboBoxChange({
          selectedOption: data.optionValue,
          selectedOptions: data.selectedOptions,
          options: eventsNamesOptions,
          onChange: (values: any) => {
            updateDriveLoadStore({ selectedFilterEventsNames: values || [] });
          },
        });
      }}
    />
  );
};

export default EventNameFilter;
