import { Controller } from "react-hook-form";

import type { CheckboxProps } from "../../Checkbox";
import Checkbox from "../../Checkbox";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormCheckboxProps = BaseFormFieldProps &
  CheckboxProps & {
    name: string;
  };

export const FormCheckbox = ({
  name,
  groupProps,
  checked,
  hookProps: { control },
  ...rest
}: FormCheckboxProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={checked}
      render={({ field: { onChange, value } }) => {
        const checked = value || false;
        return <Checkbox checked={checked} onChange={onChange} {...rest} />;
      }}
    />
  </FormGroup>
);
