import { useTranslation } from "react-i18next";

import type { TabItemProps } from "../../../common/TabMenu";
import { TabMenu } from "../../../common/TabMenu";
import { TriggersList } from "./ListTab/TriggersList";
import TriggeringStatistics from "./StatisticsTab/TriggeringStatistics";

enum TAB_TITLES {
  Table = "Table",
  Statistics = "Statistics",
}

const SensorTriggering = () => {
  const { t } = useTranslation();
  const tabItems: TabItemProps[] = [
    {
      key: TAB_TITLES.Table,
      title: t("Table"),
      content: <TriggersList />,
    },
    {
      key: TAB_TITLES.Statistics,
      title: t("Statistics"),
      content: <TriggeringStatistics />,
    },
  ];

  return (
    <>
      <div className='sensor-triggering-container'>
        <TabMenu tabs={tabItems} defaultValue={TAB_TITLES.Table} />
      </div>
    </>
  );
};

export default SensorTriggering;
