import type { TreeNode } from "react-hyper-tree/dist/helpers/node";

import type { ResponseSimplifiedSignal } from "../../../types";

export const format = (item: ResponseSimplifiedSignal): string => {
  if (item?.signalDescription) {
    return item?.sensorNo
      ? `(${item.sensorNo}) ${item.signalDescription} (${item?.name})${
          item?.noAverageTrend ? "*" : ""
        }`
      : `${item.signalDescription} (${item?.name})${item?.noAverageTrend ? "*" : ""}`;
  }

  return item?.sensorNo
    ? `(${item.sensorNo}) ${item?.name}${item?.noAverageTrend ? "*" : ""}`
    : `${item?.name}${item?.noAverageTrend ? "*" : ""}`;
};

export const formatNode = (node: TreeNode): string => {
  if (!node?.options?.leaf) {
    return node?.data?.name;
  }

  return format(node?.data as ResponseSimplifiedSignal);
};
