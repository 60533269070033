import { Spinner, Text, Tooltip } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { pageStyle, titleStyle } from "../../schema/Constants";
import { Personas } from "../../schema/personas";
import { Status } from "../../schema/status";
import { camelCaseToSpacedString, extract4Initials } from "../../schema/Utils";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import FacePile from "../common/FacePile";
import { NoData } from "../common/NoData";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import type { CustomItemsType } from "../Roles/ScopesOfRole/reducer";
import { Stack } from "../Stack";
import { AddDialog } from "./AddDialogNew";
import { DeleteConfirm } from "./DeleteConfirm";
import type {
  EntityItemWithAccountRolesToMapInTable,
  ResponseUserOrServiceAccount,
} from "./models";
import { listAsyncPermi, selectPermissionsStatus, selectPermissionsTable } from "./reducer";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

type GetColumnsOpts = {
  t: any;
  isLoading?: string;
};

const headerTooltip = (text: string) => {
  return (
    <div style={{ justifyContent: "center" }}>
      <Tooltip withArrow relationship='label' content={camelCaseToSpacedString(text)}>
        <span style={{ justifyContent: "center" }}>
          {extract4Initials(camelCaseToSpacedString(text))}
        </span>
      </Tooltip>
    </div>
  );
};

const checkItem = () => {
  return <Stack horizontalAlign='center'>X</Stack>;
};

const getColumns = ({ t, isLoading }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "issuer",
      name: t("Issuer"),
      fieldName: "issuer",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "displayName",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "email",
      name: t("Email"),
      fieldName: "email",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "type",
      name: t("Type"),
      fieldName: "type",
      minWidth: 50,
      isSortable: true,
    },
    {
      key: "corporationName",
      name: t("Corporation Name"),
      fieldName: "corporationName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company Name"),
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "projectName",
      name: t("Project Name"),
      fieldName: "projectName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "machineName",
      name: t("Machine Name"),
      fieldName: "machineName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "persona",
      name: t("Persona"),
      fieldName: "persona",
      minWidth: 150,
      isSortable: true,
      onRender: (item: EntityItemWithAccountRolesToMapInTable) => {
        const persona = Personas.GetUserPersona(item);
        const personas = [];
        if (persona) {
          personas.push({
            personaName: persona.name,
            imageInitials: persona.imageInitials,
          });
        } else {
          personas.push(Personas.undetermined);
        }

        return <FacePile personas={personas} />;
      },
    },
    {
      key: "userAdministrator",
      name: extract4Initials(camelCaseToSpacedString("userAdministrator")),
      fieldName: "userAdministrator",
      minWidth: 50,
      isSortable: true,
      onRender: ({ userAdministrator }) => (userAdministrator ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("userAdministrator"),
    },
    {
      key: "metaDataReader",
      name: extract4Initials(camelCaseToSpacedString("metaDataReader")),
      fieldName: "metaDataReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ metaDataReader }) => (metaDataReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("metaDataReader"),
    },

    {
      key: "metaDataContributor",
      name: t("Meta Data Contributor"),
      fieldName: "metaDataContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ metaDataContributor }) => (metaDataContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("metaDataContributor"),
    },
    {
      key: "auditReader",
      name: t("Audit Reader"),
      fieldName: "auditReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ auditReader }) => (auditReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("auditReader"),
    },
    {
      key: "measuredDataReader",
      name: t("Measured Data Reader"),
      fieldName: "measuredDataReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataReader }) => (measuredDataReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("measuredDataReader"),
    },
    {
      key: "measuredDataContributor",
      name: t("Measured Data Contributor"),
      fieldName: "measuredDataContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataContributor }) => (measuredDataContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("measuredDataContributor"),
    },
    {
      key: "machineCvAdministrator",
      name: t("Machine Cv Administrator"),
      fieldName: "machineCvAdministrator",
      minWidth: 50,
      isSortable: true,
      onRender: ({ machineCvAdministrator }) => (machineCvAdministrator ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("machineCvAdministrator"),
    },
    {
      key: "machineCvReader",
      name: t("Machine Cv Reader"),
      fieldName: "machineCvReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ machineCvReader }) => (machineCvReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("machineCvReader"),
    },
    {
      key: "machineCvContributor",
      name: t("Machine Cv Contributor"),
      fieldName: "machineCvContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ machineCvContributor }) => (machineCvContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("machineCvContributor"),
    },
    {
      key: "powerBiReader",
      name: t("Power Bi Reader"),
      fieldName: "powerBiReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ powerBiReader }) => (powerBiReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("powerBiReader"),
    },
    {
      key: "powerBiContributor",
      name: t("Power Bi Contributor"),
      fieldName: "powerBiContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ powerBiContributor }) => (powerBiContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("powerBiContributor"),
    },
    {
      key: "imageContributor",
      name: t("Image Contributor"),
      fieldName: "imageContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ imageContributor }) => (imageContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("imageContributor"),
    },
    {
      key: "imageAdministrator",
      name: t("Image Administrator"),
      fieldName: "imageAdministrator",
      minWidth: 50,
      isSortable: true,
      onRender: ({ imageAdministrator }) => (imageAdministrator ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("imageAdministrator"),
    },
    {
      key: "wirelessSensorReader",
      name: t("Wireless Sensor Reader"),
      fieldName: "wirelessSensorReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ wirelessSensorReader }) => (wirelessSensorReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("wirelessSensorReader"),
    },
    {
      key: "wirelessSensorContributor",
      name: t("Wireless Sensor Contributor"),
      fieldName: "wirelessSensorContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ wirelessSensorContributor }) => (wirelessSensorContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("wirelessSensorContributor"),
    },
    {
      key: "mobileAppUser",
      name: t("Mobile App User"),
      fieldName: "mobileAppUser",
      minWidth: 50,
      isSortable: true,
      onRender: ({ mobileAppUser }) => (mobileAppUser ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("mobileAppUser"),
    },
    {
      key: "WirelessSensorTriggerContributor",
      name: t("Wireless Sensor Trigger Contributor"),
      fieldName: "WirelessSensorTriggerContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ WirelessSensorTriggerContributor }) =>
        WirelessSensorTriggerContributor ? checkItem() : "",
      onRenderHeader: () => headerTooltip("WirelessSensorTriggerContributor"),
    },
    {
      key: "vpnReader",
      name: t("Vpn Reader"),
      fieldName: "vpnReader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ vpnReader }) => (vpnReader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("vpnReader"),
    },
    {
      key: "vpnContributor",
      name: t("Vpn Contributor"),
      fieldName: "vpnContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ vpnContributor }) => (vpnContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("vpnContributor"),
    },
    {
      key: "submissionAdministrator",
      name: t("Submission Administrator"),
      fieldName: "submissionAdministrator",
      minWidth: 50,
      isSortable: true,
      onRender: ({ submissionAdministrator }) => (submissionAdministrator ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("submissionAdministrator"),
    },
    {
      key: "submissionContributor",
      name: t("Submission Contributor"),
      fieldName: "submissionContributor",
      minWidth: 50,
      isSortable: true,
      onRender: ({ submissionContributor }) => (submissionContributor ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("submissionContributor"),
    },
    {
      key: "measuredDataTier5Reader",
      name: t("Measured Data Tier 5 Reader"),
      fieldName: "measuredDataTier5Reader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataTier5Reader }) => (measuredDataTier5Reader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("measuredDataTier5Reader"),
    },
    {
      key: "measuredDataTier2Reader",
      name: t("Measured Data Tier 2 Reader"),
      fieldName: "measuredDataTier2Reader",
      minWidth: 50,
      isSortable: true,
      onRender: ({ measuredDataTier2Reader }) => (measuredDataTier2Reader ? checkItem() : ""),
      onRenderHeader: () => headerTooltip("measuredDataTier2Reader"),
    },
  ];
  return columns;
};

export const ResponseRolesList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const permissions = useAppSelector(selectPermissionsTable);
  const status = useAppSelector(selectPermissionsStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAsyncPermi());
  }, [dispatch]);

  const [selected, setSelected] = useState<{
    data: ResponseUserOrServiceAccount | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const { filters, handleSearch } = useTableFilters<EntityItemWithAccountRolesToMapInTable>({
    keys: [
      "issuer",
      "displayName",
      "email",
      "type",
      "corporationName",
      "companyName",
      "projectName",
      "machineName",
    ],
  });

  const addOnclick = (ev): boolean | void => {
    setSelected({ data: undefined, context: "add" });
  };

  const commandBarItems: any = useMemo(() => {
    return [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <Text style={titleStyle}>{t("Permissions")}</Text>,
      },
      ...(auth.userAdministrator
        ? [
            {
              key: "add",
              text: t("Add"),
              type: CommandBarItemType.Button,
              icon: <AddRegular />,
              onClick: addOnclick,
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loading, t]);

  return (
    <>
      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{memberName}}: {{memberType}}`, {
                memberName: selected?.data?.displayName,
                memberType: selected?.data?.memberType,
              }),
            );
          } else {
            dispatch(listAsyncPermi());
            notification.success(
              t(`{{memberName}}: {{memberType}} deleted successfully`, {
                memberName: selected?.data?.displayName,
                memberType: selected?.data?.memberType,
              }),
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />

      {selected?.context === "add" && (
        <AddDialog
          show={selected?.context === "add"}
          onClose={() => {
            setSelected(null);
          }}
          onSuccess={() => {
            dispatch(listAsyncPermi());
            setSelected(null);
          }}
        />
      )}

      {status === Status.idle ? (
        <div style={pageStyle}>
          <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />

          <Table
            items={permissions}
            v8Columns={getColumns({ t, isLoading: "" })}
            persistOpts={{
              key: "table-permissions",
              version: 2,
            }}
            filters={filters}
          />
        </div>
      ) : status === Status.loading || auth.loading ? (
        <div style={{ height: "80vh" }}>
          <Stack verticalFill verticalAlign='center'>
            <Spinner size='small' label={t("Loading...")}></Spinner>
          </Stack>
        </div>
      ) : (
        <Stack style={{ height: "100vh" }} verticalAlign='space-around'>
          <NoData />
        </Stack>
      )}
    </>
  );
};
