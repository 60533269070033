import dayjs from "dayjs";

import type { Column } from "../../../../../common/Table/v9";

const prepareSnapshotColumn = (snapshot: any) => {
  if (!snapshot) return [];
  const snapshotArr = snapshot.split("];[") || [];
  return snapshotArr.map((text: any) => {
    return (text || "")?.replace(/\[|\]/g, "")?.replace(/, $/, "");
  });
};

export const getColumns = (t, selectedTableEventTimeStamp): Column[] => [
  {
    key: "event-time-stamp",
    fieldName: "timeStamp",
    name: t("Date/Time"),
    minWidth: 130,
    isSortable: true,
    isResizable: true,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (item) => dayjs(item?.timeStamp).format("YYYY-MM-DD HH:mm:ss"),
    getCellClassName: (item) =>
      selectedTableEventTimeStamp === item?.timeStamp ? "Events-DataGridCell--white" : "",
  },
  {
    key: "event-name",
    fieldName: "name",
    name: t("Event"),
    minWidth: 80,
    isSortable: true,
    isResizable: true,
    calculatedWidth: 0,
    flexGrow: 1,
    getCellClassName: (item) =>
      selectedTableEventTimeStamp === item?.timeStamp ? "Events-DataGridCell--white" : "",
  },
  {
    key: "event-duration",
    fieldName: "duration",
    name: t("Duration"),
    minWidth: 90,
    calculatedWidth: 0,
    isSortable: true,
    isResizable: true,
    flexGrow: 1,
    getCellClassName: (item) =>
      selectedTableEventTimeStamp === item?.timeStamp ? "Events-DataGridCell--white" : "",
  },
  {
    key: "event-snapshot",
    fieldName: "snapshot",
    name: t("Snapshot"),
    minWidth: 250,
    isSortable: true,
    isResizable: false,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (item) => {
      const snapshots = prepareSnapshotColumn(item.snapshot);
      if (!snapshots?.length) return "";
      return (
        <div className='snapshot-column'>
          {snapshots.map((text: any) => (
            <div key={text}>{text}</div>
          ))}
        </div>
      );
    },
    getCellClassName: (item) =>
      selectedTableEventTimeStamp === item?.timeStamp ? "Events-DataGridCell--white" : "",
  },
];
