import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CalendarIcon from "../../../../../assets/svg/CalendarIcon";
import LayoutIcon from "../../../../../assets/svg/LayoutIcon";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { onRenderLabel } from "../../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../common/Dropdown";
import useSummaryStore from "../../hooks/useSummaryStore";
import { getOperationView } from "./config";
import { generateMonthsOptions, generateYearsOptions } from "./methods";

const OperationCommandBar = () => {
  const { t } = useTranslation();
  const { transparent } = useDropdownStyles();
  const { updateOperations, operations } = useSummaryStore((state) => ({
    updateOperations: state.updateOperations,
    operations: state.operations,
  }));

  const { month, signal: operationSignal = {}, view, year } = operations;
  const [yearsOptions, setYearsOptions]: any = useState([]);
  const [monthsOptions, setMonthsOptions]: any = useState([]);

  useEffect(() => {
    const years = generateYearsOptions(operationSignal.dataFrom, operationSignal.dataUntil) || [];
    let months: any = [];

    if (years.length > 0) {
      months = generateMonthsOptions(years[0].start, years[0].end);
      if (!year) {
        updateOperations({
          year: { key: years[0].year, text: years[0].year, ...years[0] },
          month: { key: months[0].month, text: months[0].month, ...months[0] },
        });
      } else {
        const isAvailableYear = years.filter((yearItem: any) => yearItem.year === year.year)[0];
        if (isAvailableYear) {
          const isAvailableMonth = month
            ? months.filter((monthItem: any) => monthItem.month === month.month)[0]
            : false;
          updateOperations({
            year: { key: years[0].year, text: years[0].year, ...years[0] },
            month: isAvailableMonth
              ? { ...month }
              : { key: months[0].month, text: months[0].month, ...months[0] },
          });
        } else {
          updateOperations({
            year: { key: years[0].year, text: years[0].year, ...years[0] },
            month: {
              key: months[0].month,
              text: months[0].month,
              ...months[0],
            },
          });
        }
      }
    }

    setYearsOptions(years);
    setMonthsOptions(months);
  }, [operationSignal.dataFrom, operationSignal.dataUntil]);

  const onYearChange = (ev: any, item: any) => {
    const key = item.optionValue;
    const text = item.optionText;

    const selectedOption = yearsOptions.filter(({ year }: any) => year === key)[0] || {};
    const yearMonths = generateMonthsOptions(selectedOption.start, selectedOption.end);
    const selectedYearMonth = month
      ? yearMonths.filter((yearMonth) => yearMonth.month === month.key)[0] || yearMonths[0]
      : yearMonths[0];
    updateOperations({
      year: { key, text, ...selectedOption },
      month: {
        key: selectedYearMonth.month,
        text: selectedYearMonth.month,
        ...selectedYearMonth,
      },
    });
    setMonthsOptions(yearMonths);
  };

  const onMonthChange = (ev: any, item: any) => {
    const key = item.optionValue;
    const text = item.optionText;
    const selectedOption =
      monthsOptions.filter(({ month }: any) => month === item.optionValue)[0] || {};
    updateOperations({ month: { key, text, ...selectedOption } });
  };

  const isDisabled = isEmpty(operationSignal);

  return (
    <div className='operation-command-bar'>
      <Dropdown
        disabled={isDisabled}
        style={{ minWidth: "max-content", padding: "2px 8px" }}
        className={transparent}
        button={onRenderLabel({
          label: t("View"),
          icon: <LayoutIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: t(view?.text),
        })}
        options={getOperationView(t)}
        selectedOptions={[view.key]}
        onOptionSelect={(ev: any, item: any) =>
          updateOperations({ view: { key: item.optionValue, text: item.optionText } })
        }
      />
      <Dropdown
        disabled={isDisabled}
        button={onRenderLabel({
          label: t("Year"),
          icon: <CalendarIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: year?.text,
        })}
        className={classNames(transparent, {
          disabled: isDisabled,
        })}
        style={{ minWidth: "max-content", padding: "2px 8px" }}
        options={yearsOptions.map(({ year }: any) => ({
          key: year,
          text: year,
        }))}
        selectedOptions={[year?.key]}
        onOptionSelect={onYearChange}
      />
      <Dropdown
        button={onRenderLabel({
          label: t("Month"),
          icon: <CalendarIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: t(month?.text),
        })}
        style={{ minWidth: "max-content", padding: "2px 8px" }}
        disabled={view?.key === getOperationView(t)[0].key || isDisabled}
        className={classNames(transparent, {
          disabled: view?.key === getOperationView(t)[0].key || isDisabled,
        })}
        options={monthsOptions.map(({ month }: any) => ({
          key: month,
          text: t(month),
        }))}
        selectedOptions={[month?.key]}
        onOptionSelect={onMonthChange}
      />
    </div>
  );
};

export default OperationCommandBar;
