import { Label } from "@fluentui/react-components";
import type { CSSProperties, PropsWithChildren } from "react";

import type { StackProps } from "../Stack";
import { Stack } from "../Stack";

type FormItemRowProps = Omit<StackProps, "horizontal" | "horizontalAlign" | "verticalAlign"> & {
  label: string;
  htmlFor?: string;
};

const containerStyles: CSSProperties = {
  marginTop: "8px",
  marginBottom: "8px",
};

const labelContainerStyles: CSSProperties = {
  minWidth: "160px",
  marginRight: "8px",
};

const FormItemRow = ({
  label,
  htmlFor,
  children,
  style,
  ...rest
}: PropsWithChildren<FormItemRowProps>) => {
  return (
    <Stack
      {...rest}
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
      style={{ ...containerStyles, ...style }}
    >
      <Stack.Item style={labelContainerStyles}>
        <Label
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
          htmlFor={htmlFor}
        >
          {label}
        </Label>
      </Stack.Item>
      <Stack.Item style={{ width: "100%" }}>{children}</Stack.Item>
    </Stack>
  );
};

export default FormItemRow;
