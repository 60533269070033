/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type * as am5xy from "@amcharts/amcharts5/xy";
import {
  Button,
  Checkbox,
  makeStyles,
  SearchBox,
  Switch,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { NavigationRegular } from "@fluentui/react-icons";
import type { CSSProperties, PropsWithChildren, SVGProps } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CharLayoutIcon from "../../../../../../assets/svg/ChartLayoutIcon";
import ChartLayoutModal from "../../../../../../modules/analysis-trend-view/components/ChartLayoutModal";
import useLayoutSettingsStore from "../../../../../../modules/analysis-trend-view/components/ChartLayoutModal/useLayoutSettingsStore";
import { format as signalFormatter } from "../../../../../../modules/common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../../../types";
import { Stack } from "../../../../../Stack";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import useMopMaximizeStore from "./useMopMaximizeStore";

type BaseProps = any & {
  signals: ResponseSimplifiedSignal[];
  selectedSignals: ResponseSimplifiedSignal[];
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  onSelectedChanged?: (signals: ResponseSimplifiedSignal[]) => void;
  floatingText?: string;
};

type MopChartContainerProps = BaseProps & {
  chart?: am5xy.XYChart;
};

type SignalsCheckboxPanelProps = BaseProps & {
  show: boolean;
  max: number;
};

const backgroundStyle: CSSProperties = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
  content: "''",
  opacity: "0.4",
  background: "rgb(255, 255, 255)",
};

const signalsPanelStyles: CSSProperties = {
  position: "absolute",
  top: "43px",
  left: "-0.5em",
  width: "100%",
  maxWidth: "25em",
  height: "calc(100% - 36px)",
  background: "white",
  padding: "1em",
  boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.1)",
  zIndex: "3",
};

const floatingPanelStyles: any = {
  position: "absolute",
  top: "0em",
  left: "0em",
  zIndex: "4",
  background: "transparent",
  width: "100%",
};

const useStyles = makeStyles({
  checkbox: {
    "& .fui-Checkbox__label": {
      padding: 0,
    },
  },
});

const SignalsCheckboxPanel = ({
  show,
  signals,
  selectedSignals,
  max,
  icon,
  onSelectedChanged,
  ...rest
}: SignalsCheckboxPanelProps) => {
  const { checkbox } = useStyles();
  const [searchText, setSearchText] = useState<string>("");

  // Handlers
  const onCheckedChange = (ev, data) => {
    const checked = data.checked;
    const signalId = ev?.target.getAttribute("name");
    if (!signalId) {
      return;
    }

    let currentSignals = [...selectedSignals];
    if (checked === true) {
      const signal = signals!.find((s) => s.id === signalId);
      signal && currentSignals.push(signal);
    } else {
      currentSignals = currentSignals.filter((s) => s.id !== signalId);
    }

    onSelectedChanged?.(currentSignals);
  };

  return (
    <Stack {...rest} style={{ ...signalsPanelStyles, display: show ? "flex" : "none" }}>
      <SearchBox
        style={{ marginTop: "2em" }}
        onChange={(_, { value: newValue }) => setSearchText(newValue ?? "")}
      />
      <div style={{ height: "100%", overflow: "auto", marginTop: "8px" }}>
        <Stack style={{ gap: 10 }}>
          {signals?.map((signal) => {
            const checked = selectedSignals.map((s) => s.id).includes(signal.id);
            const visible =
              searchText.length === 0 ||
              signal.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
            return (
              <Checkbox
                key={signal.id}
                className={checkbox}
                name={signal.id}
                label={
                  <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                    {icon?.({ style: { minWidth: "16px", height: "16px" } })}
                    {signalFormatter(signal)}
                  </div>
                }
                checked={checked}
                disabled={!checked && selectedSignals.length === max}
                style={{
                  display: visible ? "flex" : "none",
                }}
                onChange={onCheckedChange}
              />
            );
          })}
        </Stack>
      </div>
    </Stack>
  );
};

const MopChartContainer = ({
  signals,
  selectedSignals,
  onSelectedChanged,
  floatingText,
  chart,
  children,
  icon,
  ...rest
}: PropsWithChildren<MopChartContainerProps>) => {
  const { t } = useTranslation();
  const [isSignalsDialogOpened, setIsSignalsDialogOpened] = useState<boolean>(false);
  const { isChartLayoutOpen, updateStore } = useLayoutSettingsStore((store: any) => ({
    isChartLayoutOpen: store.isChartLayoutOpen,
    updateStore: store.updateStore,
  }));
  const { mopAverageTrend, updateDriveLoadStore } = useDriveLoadStore((store: any) => ({
    mopAverageTrend: store.mopAverageTrend,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));
  const { isMaximized, maximizeAction, maximizeIcon, maximizeLabel } = useMopMaximizeStore();

  return (
    <Stack
      {...rest}
      verticalFill
      style={{ position: "relative", height: isMaximized ? "80vh" : "100%" }}
    >
      <Stack horizontal style={{ ...floatingPanelStyles, gap: 5 }}>
        <Tooltip withArrow content={t("Select signals")} relationship='label'>
          <Button
            appearance='subtle'
            icon={<NavigationRegular />}
            onClick={() => setIsSignalsDialogOpened(!isSignalsDialogOpened)}
          />
        </Tooltip>

        <Button
          appearance='transparent'
          icon={<CharLayoutIcon />}
          onClick={() => updateStore({ isChartLayoutOpen: true })}
        >
          <span style={{ marginLeft: 8 }}>{t("Chart Layout")}</span>
        </Button>

        {!floatingText && (
          <Switch
            style={{ margin: 0 }}
            checked={mopAverageTrend}
            label={t("Average trend")}
            onChange={(_, { checked }) => updateDriveLoadStore({ mopAverageTrend: checked })}
          />
        )}

        {floatingText && (
          <Stack
            horizontal
            horizontalAlign='center'
            verticalAlign='center'
            style={{
              display: isSignalsDialogOpened ? "none" : "flex",
            }}
          >
            <Text size={300} as='span'>
              {floatingText}
            </Text>
          </Stack>
        )}
        <Stack horizontal style={{ flexGrow: 1 }} horizontalAlign='end'>
          <Button appearance='transparent' onClick={() => maximizeAction()}>
            {maximizeIcon}
            <span>{t(maximizeLabel)}</span>
          </Button>
        </Stack>
      </Stack>
      <SignalsCheckboxPanel
        signals={signals}
        selectedSignals={selectedSignals}
        show={isSignalsDialogOpened}
        icon={icon}
        max={6}
        onSelectedChanged={onSelectedChanged}
      />
      {chart && isChartLayoutOpen && (
        <ChartLayoutModal
          series={chart.series.values}
          yAxes={chart.yAxes.values}
          onDismiss={() => updateStore({ isChartLayoutOpen: false })}
        />
      )}
      <div style={{ paddingTop: "2em", height: "100%" }}>{children}</div>
    </Stack>
  );
};

export default MopChartContainer;
