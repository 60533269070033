import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";

const textStyle: CSSProperties = {
  fontSize: 14,
  lineHeight: "20px",
  marginBottom: 16,
};

type SimpleDeleteProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  handleOk: () => void;
  onClose: () => void;
  text: string;
};

export const SimpleDeleteConfirm = ({
  show,
  handleOk,
  onClose,
  text,
  ...rest
}: SimpleDeleteProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    handleOk();
    handleClose();
  };

  const handleClose = () => {
    setLoading(false);
    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{ width: DialogSize.M }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>
      <DialogContent>
        <div style={textStyle}>{t("You are about to delete {{text}}", { text })}</div>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleDelete}
          >
            {t("Delete")}
          </Button>
          <Button onClick={handleClose}>{t("Close")} </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
