import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import { MoreVerticalRegular } from "@fluentui/react-icons";

type FacePileProps = {
  personas: any[];
  maxPersonas?: number;
};

function renderPersona({ personaName, imageInitials, onClick }, index) {
  const onButtonClick = onClick ? onClick : undefined;
  return (
    <Tooltip key={`${personaName}-${index}`} withArrow relationship='label' content={personaName}>
      <Button appearance='transparent' style={{ minWidth: 0, padding: 0 }} onClick={onButtonClick}>
        <Avatar color='colorful' name={personaName} initials={imageInitials} />
      </Button>
    </Tooltip>
  );
}

export default function FacePile({ personas, maxPersonas }: FacePileProps) {
  const maximumPersonas = maxPersonas || personas.length;
  const personasToShow = personas.slice(0, maximumPersonas);
  const personasToHide = personas.slice(maximumPersonas);

  return (
    <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
      {personasToShow.map(renderPersona)}

      {personasToHide.length > 0 ? (
        <Menu>
          <MenuTrigger>
            <Button appearance='subtle' icon={<MoreVerticalRegular />} />
          </MenuTrigger>
          <MenuPopover style={{ minWidth: 0 }}>
            <MenuList>
              {personasToHide.map((item, index) => (
                <MenuItem key={index}>{renderPersona(item, index)}</MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      ) : null}
    </div>
  );
}
