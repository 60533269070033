import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { AxiosResponse } from "axios";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  getFileIds,
  useCurrentUser,
  useFileDelete,
  useFileUpload,
  useLocationSearch,
} from "../../../../Hooks";
import { FORMAT } from "../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import AddDocumentDialog from "../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import type { Report } from "../../../../types";
import { format as formatDate, ISOStringToDate } from "../../../../utils";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../common/Form";
import type { FormItemProps } from "../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../common/Form/FormItems/helpers";
import { notification } from "../../../common/Notification";
import type { TableProps } from "../../../common/Table/v9";
import Table from "../../../common/Table/v9";
import TextArea from "../../../common/TextArea";
import type { UploadedFile } from "../../../common/Uploader/types";
import UploaderButton from "../../../common/Uploader/UploaderButton";
import { Stack } from "../../../Stack";
import { getColumns } from "../../MachineCVInfoPage/components/DocumentsTable/columns";
import type { ReportAddProps } from "../hooks/useAlertsMutations";
import { useReportAdd, useReportUpdate } from "../hooks/useAlertsMutations";

type SubmitData = { category?: string; createdAt: Date | string };

type AlertDocumentDialogProps = React.HTMLAttributes<HTMLElement> & {
  onClose: (refresh: boolean) => void;
  report?: Report;
};

type AlertReduced = {
  text: string;
  category?: string;
  createdAt: string;
};

const schema = z.object({
  createdAt: z.date(),
});

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "createdAt",
    type: FormItemType.DatePicker,
    formatDate: (date) => formatDate(date ?? new Date()),
    groupProps: { label: t("Created at") },
  },
];

const getInitialValues = (report: AlertReduced) => ({
  text: report?.text,
  category: report?.category,
  ...(report?.createdAt ? { createdAt: ISOStringToDate(report?.createdAt) } : {}),
});

export const AlertDocumentDialog = ({ report, onClose, ...rest }: AlertDocumentDialogProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFile[]>(report?.files ? [...report.files] : []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState<boolean>(true);
  const loggedUser = useCurrentUser();
  const [text, setText] = useState<string>(report?.text ?? "");
  const [{ id }] = useLocationSearch();
  const { addReportAsync } = useReportAdd();
  const { updateReportAsync } = useReportUpdate(report?.id ?? "");
  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useZodForm({
    ...(!!report && {
      defaultValues: getInitialValues(report),
    }),
    schema,
  });

  const fileTableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-files-reports",
        version: 1,
      },
      items: files,
      perPage: 5,
      hidePerPage: true,
      v8Columns: getColumns({
        t,
        onRemove: (fileId) => {
          setFiles((prev) => [...prev.filter((file: UploadedFile) => file?.id !== fileId)]);
        },
      }),
    }),
    [files, t],
  );

  const toggleIsDocumentsDialogHidden = () => setIsDocumentsDialogHidden(!isDocumentsDialogHidden);

  // Sets the report markdown text.
  useEffect(() => {
    if (!report) {
      return;
    }

    setText(report.text);
  }, [report]);

  // Handlers
  const onFinish = (
    uploadResponse: PromiseSettledResult<AxiosResponse>[],
    successIds: string[],
  ) => {
    if (uploadResponse.length === successIds.length) {
      notification.success(
        report ? t("Alert updated successfully") : t("Alert created successfully"),
      );
    } else {
      notification.success(
        report
          ? t(`Alert updated successfully with {{updatedFiles}} out of {{totalFiles}} files`, {
              updatedFiles: successIds.length,
              totalFiles: uploadResponse.length,
            })
          : t(`Alert created successfully with {{createdFiles}} out of {{totalFiles}} files`, {
              createdFiles: successIds.length,
              totalFiles: uploadResponse.length,
            }),
      );
    }

    onClose?.(true);
  };

  const onAdd = async (data: SubmitData) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    await addReportAsync({
      text,
      machineId: id as string,
      createdBy: loggedUser.displayName || "no-name-available",
      fileIds: successIds,
      category: "Alert",
      createdAt: data.createdAt.toLocaleString(),
    })
      .then(() => {
        onFinish(uploadResponse, successIds);
      })
      .catch(() => {
        notification.error(t("Error posting report"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = async (data: SubmitData) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    const newReport: ReportAddProps = {
      text,
      machineId: report?.machineId ?? "",
      createdBy: report?.createdBy ?? "",
      createdAt: data.createdAt.toLocaleString(),
      category: "Alert",
      fileIds: [...files.filter((file) => !file.isValid).map((file) => file.id), ...successIds],
    };

    await updateReportAsync(newReport)
      .then(() => {
        onFinish(uploadResponse, successIds);
      })
      .catch(() => {
        notification.error(t("Error updating report"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = handleSubmit(async (data) => {
    const submitData = {
      ...data,
      createdAt: format(data.createdAt, FORMAT),
    };

    if (report) {
      onEdit(submitData as SubmitData);
    } else {
      onAdd(submitData as SubmitData);
    }
  });

  return (
    <React.Fragment>
      <BaseDialog
        {...rest}
        open={true}
        surfaceStyle={{ width: DialogSize.L }}
        onOpenChange={() => onClose?.(false)}
      >
        <BaseDialogTitle>{report ? t("Edit Alert") : t("Create Alert")}</BaseDialogTitle>

        <DialogContent>
          <form style={{ height: "100%" }} onSubmit={onSubmit}>
            <div style={{ height: "calc(100% - 48px)", overflow: "auto" }}>
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              <Stack style={{ gap: 5 }}>
                <TextArea
                  label={t("Text:")}
                  aria-labelledby={`report-text-label-${report?.id}`}
                  value={text}
                  style={{ height: "20em" }}
                  onChange={(newValue) => setText(newValue ?? "")}
                />
              </Stack>
              <UploaderButton onClick={toggleIsDocumentsDialogHidden} />
              <Table {...fileTableProps} />
            </div>

            <DialogActions>
              <Button
                type='submit'
                appearance='primary'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='tiny' /> : null}
              >
                {report ? t("Save") : t("Create")}
              </Button>
              <Button appearance='transparent' onClick={() => onClose?.(false)}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </React.Fragment>
  );
};
