import type { SortDirection, TableColumnId } from "@fluentui/react-components";
import { useMemo } from "react";
import { useLocalStorage } from "react-use";

type UseSortingOpts = {
  items: any[];
  persistOpts: {
    key: string;
    version: number;
  };
};

const useSorting = ({ items, persistOpts }: UseSortingOpts) => {
  // Custom multi-column sorting state
  const [multiSort, setMultiSort] = useLocalStorage<
    { columnId: TableColumnId; direction: SortDirection }[]
  >(`${persistOpts.key}-${persistOpts.version}-sorting`, []);

  const getColumnSortDirection = (columnId: TableColumnId) => {
    const sortItem = multiSort.find((item) => item.columnId === columnId);

    if (typeof sortItem === "undefined") {
      return undefined;
    }

    return sortItem.direction;
  };

  // Function to update sorting order
  const toggleSort = (columnId: TableColumnId) => {
    const prevSorts = multiSort;

    const existingSort = prevSorts.find((s) => s.columnId === columnId);

    if (existingSort) {
      if (existingSort.direction === "ascending") {
        setMultiSort(
          prevSorts.map((s) => (s.columnId === columnId ? { ...s, direction: "descending" } : s)),
        );
      } else {
        setMultiSort(prevSorts.filter((s) => s.columnId !== columnId)); // Remove column if already descending
      }
    } else {
      setMultiSort([...prevSorts, { columnId, direction: "ascending" }]); // Add new column to sorting
    }
  };

  // Apply multi-column sorting logic
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      for (const sort of multiSort) {
        const aValue = a[sort.columnId] ?? "";
        const bValue = b[sort.columnId] ?? "";

        const comparison = aValue
          .toString()
          .localeCompare(bValue.toString(), undefined, { numeric: true });

        if (comparison !== 0) {
          return sort.direction === "ascending" ? comparison : -comparison;
        }
      }

      return 0;
    });
  }, [multiSort, items]);

  return { sortedItems, toggleSort, getColumnSortDirection };
};

export default useSorting;
