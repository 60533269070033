/* eslint-disable jsx-a11y/click-events-have-key-events */

import "../styles.scss";

import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { EditIcon } from "@fluentui/react-icons-mdl2";
import { useTranslation } from "react-i18next";

import TaskAddIcon from "../../../../assets/svg/TaskAddIcon";
import { useWebAnalysisPermissions } from "../../../../Hooks";
import type { Report } from "../../../../types";
import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/v9";
import { Stack } from "../../../Stack";
import ReportText from "../../MachineCVSummaryPage/DetailsSection/ReportsTab/ReportText";
import { REPORT_CATEGORIES } from "../config";

type GetColumnsProps = {
  t?: any;
  onEdit: (report: Report) => void;
  onDelete: (report: Report) => void;
  onAddTask: (report: Report) => void;
};

type DeleteReportData = Report & {
  createdById: string;
};

type ActionsMenuProps = GetColumnsProps & {
  report: DeleteReportData;
};

const ActionsMenu = ({ report, onEdit, onDelete, onAddTask }: ActionsMenuProps) => {
  const { t } = useTranslation();
  const isDeleteVisible = Object.values(REPORT_CATEGORIES)
    .map((s) => s.toString())
    .includes(report.category);

  const { useReadyPermission, checkCreatorUserIDMatch } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const hasEditDeleteActions =
    hasMachineCvAdministratorPermission || checkCreatorUserIDMatch(report.createdById ?? "");

  return (
    <Stack horizontal verticalAlign='center'>
      <Tooltip withArrow relationship='label' content={t("Add task")}>
        <Button
          appearance='transparent'
          icon={<TaskAddIcon width='20' height='20' />}
          aria-label={t("Add task")}
          onClick={(e) => {
            e.stopPropagation();
            onAddTask(report);
          }}
        />
      </Tooltip>

      {hasEditDeleteActions && (
        <Tooltip withArrow relationship='label' content={t("Edit")}>
          <Button
            appearance='transparent'
            icon={<EditIcon />}
            aria-label={t("Edit")}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(report);
            }}
          />
        </Tooltip>
      )}
      {hasEditDeleteActions && isDeleteVisible && (
        <Tooltip withArrow relationship='label' content={t("Delete")}>
          <Button
            appearance='transparent'
            icon={<DeleteRegular />}
            aria-label={t("Delete")}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(report);
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export const getColumns = ({ t, onEdit, onDelete, onAddTask }: GetColumnsProps): Column[] => {
  return [
    {
      key: "text",
      fieldName: "text",
      name: t("Text"),
      flexGrow: 2,
      calculatedWidth: 0,
      minWidth: 200,
      isSortable: true,
      onRender: (report) => <ReportText report={report} />,
    },
    {
      key: "category",
      fieldName: "category",
      name: t("Category"),
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "createdBy",
      fieldName: "createdBy",
      name: t("Created By"),
      minWidth: 100,
      isSortable: true,
      isExportable: false,
    },
    {
      key: "createdAt",
      fieldName: "createdAt",
      name: t("Created At"),
      minWidth: 100,
      isSortable: true,
      onRender: (report) => report?.createdAt && format(new Date(report?.createdAt)),
    },
    {
      key: "actions",
      fieldName: "actions",
      name: t("Actions"),
      minWidth: 100,
      onRender: (report) => (
        <ActionsMenu report={report} onEdit={onEdit} onDelete={onDelete} onAddTask={onAddTask} />
      ),
    },
  ];
};
