/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { ChevronDown20Regular, ChevronUp20Regular } from "@fluentui/react-icons";
import React from "react";
import { treeHandlers } from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import CheckboxEmptyIcon from "../../../../../assets/svg/CheckboxEmptyIcon";
import CheckboxFullIcon from "../../../../../assets/svg/CheckboxFullIcon";
import ClockIcon from "../../../../../assets/svg/ClockIcon";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../../Components/common/Dialog";
import { monthNames } from "../../../../../Components/MachineCV/MachineCVSummaryPage/DetailsSection/OperationsTab/config";
import { useBoolean } from "../../../../../Hooks";
import useLayoutTypes from "../../../hooks/useLayoutTypes";
import useSelectedDataPointsStore from "../../../hooks/useSelectedDataPointsStore";
import type { CustomSelectNodeProps, DataPoint } from "../../../types";

const styles = {
  nodeButton: {
    border: "unset",
    minWidth: "30px",
    padding: "0px",
    background: "transparent",
  },
};

const CustomSelectNode = ({
  node,
  signalId,
  handlers,
  onToggle,
  onSelect,
  onSelectedNode,
  selectedSignals,
}: CustomSelectNodeProps) => {
  const {
    selectedDataPoints,
    updateSelectedDataPoint,
    addSelectedDataPoint,
    addDataPointBySignal,
    removeDataPointById,
    clearSelectedDataPoints,
    setTrendChartRequestValues,
    setSelectedDataPointsTimestamps,
  } = useSelectedDataPointsStore((store) => ({
    selectedDataPoints: store.selectedDataPoints,
    updateSelectedDataPoint: store.updateSelectedDataPoint,
    addSelectedDataPoint: store.addSelectedDataPoint,
    addDataPointBySignal: store.addDataPointBySignal,
    removeDataPointById: store.removeDataPointById,
    clearSelectedDataPoints: store.clearSelectedDataPoints,
    setTrendChartRequestValues: store.setTrendChartRequestValues,
    setSelectedDataPointsTimestamps: store.setSelectedDataPointsTimestamps,
  }));

  const { t } = useTranslation();

  const { isCompareLayout } = useLayoutTypes();

  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);

  const treeHandler = treeHandlers.trees[`datapoints_tree_${signalId}`].handlers;

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!node.hasChildren() && !node.asyncNode) {
      onSelect(e);

      if (node.isSelected()) {
        const selectedTimestamp = `${node.getParent()?.getData().id} ${node.getData().name}`;

        if (onSelectedNode) {
          onSelectedNode({
            nodeId: node.getData().id,
            timestamp: selectedTimestamp,
          });
        }

        setTrendChartRequestValues({
          month: handlers.getNode(node.getData().id)?.getParent()?.getParent()?.data?.month,
          year: handlers.getNode(node.getData().id)?.getParent()?.getParent()?.getParent()?.data
            ?.id,
        });

        const nodeItem = {
          signal: signalId,
          dataPoints: [node.getData().id],
        };

        if (selectedDataPoints.find((x) => x.signal === signalId)) {
          updateSelectedDataPoint(nodeItem as DataPoint);
        } else {
          addSelectedDataPoint(nodeItem as DataPoint);
        }

        setSelectedDataPointsTimestamps(node.getData().id, selectedTimestamp);
      } else {
        clearSelectedDataPoints();
        setSelectedDataPointsTimestamps(node.getData().id);
      }
    }
  };

  const handleSelectCompare = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!node.hasChildren() && !node.asyncNode) {
      if (selectedSignals && selectedSignals.length > 1) {
        // alert('You can only select 2 signals to compare');
        showPopup();
        return;
      }

      onSelect(e);

      if (node.isSelected()) {
        setTrendChartRequestValues({
          month: handlers.getNode(node.getData().id)?.getParent()?.getParent()?.data?.month,
          year: handlers.getNode(node.getData().id)?.getParent()?.getParent()?.getParent()?.data
            ?.id,
        });

        const signalDataPoints = selectedDataPoints.find((x) => x.signal === signalId)?.dataPoints;

        if (isCompareLayout && signalDataPoints?.length === 2) {
          treeHandler.setSelected(signalDataPoints[signalDataPoints.length - 1], false);
          signalDataPoints.splice(signalDataPoints.length - 1, 1);
        }

        const nodeItem = {
          signal: signalId,
          dataPoints: [node.getData().id],
        };

        const matchingSignal = selectedDataPoints.find((x) => x.signal === signalId);

        if (matchingSignal) {
          addDataPointBySignal(nodeItem as DataPoint);
        } else {
          addSelectedDataPoint(nodeItem as DataPoint);
        }

        setSelectedDataPointsTimestamps(
          node.getData().id,
          `${node.getParent()?.getData().id} ${node.getData().name}`,
        );
      } else {
        // onSelect(e);
        setSelectedDataPointsTimestamps(node.getData().id);
        removeDataPointById(signalId, node.getData().id);
        // treeHandler.setSelected(node.getData().id, false);
        // const nodeItem = {
        //   signal: signalId,
        //   dataPoints: [node.getData().id],
        // };
        // clearSelectedDataPoints();
      }
    }
  };

  const nodeTitle = monthNames.includes(node.data.name) ? t(node.data.name) : node.data.name;

  const isLeafNode = !node.hasChildren() && !node.asyncNode;
  const showEmptyIcon = isLeafNode && !node.isSelected();
  const showFullIcon = isLeafNode && node.isSelected();
  const showCloseIcon = node.options.opened && node.hasChildren();
  const showOpenIcon = !node.options.opened && (node.hasChildren() || node.asyncNode);

  const handleSwitch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isCompareLayout) {
      handleSelectCompare(e);
    } else {
      handleSelect(e);
    }
  };

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isLeafNode) {
      handleSwitch(e);
    } else {
      e.stopPropagation();
      onToggle(e);
    }
  };

  return (
    <div>
      <BaseDialog
        open={isPopupVisible}
        surfaceStyle={{ width: DialogSize.S }}
        onOpenChange={hidePopup}
      >
        <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>

        <DialogContent>
          {t("To select another data point, you need to deselect a signal first.")}
          <DialogActions>
            <Button onClick={hidePopup}>{t("Close")}</Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
      <div
        key={`${node.data.name} ${node.data.id}`}
        className='tree-node'
        role='button'
        tabIndex={0}
        style={{ cursor: "pointer" }}
        onClick={onToggle}
      >
        {showFullIcon && (
          <Button
            appearance='transparent'
            style={styles.nodeButton}
            onClick={(e) => handleSwitch(e)}
          >
            <CheckboxFullIcon />
          </Button>
        )}
        {showEmptyIcon && (
          <Button
            appearance='transparent'
            style={styles.nodeButton}
            onClick={(e) => handleSwitch(e)}
          >
            <CheckboxEmptyIcon />
          </Button>
        )}

        <div className='node-content-wrapper'>
          <div className='titles' role='button' tabIndex={0} onClick={handleItemClick}>
            {node.getData().last && <ClockIcon fill='currentColor' style={{ marginRight: 5 }} />}
            <div className='node-title'>{nodeTitle}</div>
          </div>
          {node.isLoading() && <Spinner />}
        </div>

        {showCloseIcon && (
          <Button appearance='transparent' style={styles.nodeButton} onClick={(e) => onToggle(e)}>
            <ChevronUp20Regular />
          </Button>
        )}
        {showOpenIcon && (
          <Button appearance='transparent' style={styles.nodeButton} onClick={(e) => onToggle(e)}>
            <ChevronDown20Regular />
          </Button>
        )}
      </div>
    </div>
  );
};

export default CustomSelectNode;
