import type { CreateTableColumnOptions, DataGridProps } from "@fluentui/react-components";
import { createTableColumn } from "@fluentui/react-components";

import type { Column } from "./types";

const DEFAULT_CELL_PAD = 20;

export type TableColumn = CreateTableColumnOptions<any> & {
  name?: string;
  minWidth?: number;
  maxWidth?: number;
  flexGrow?: number;
  getCellClassName?: (item: any) => DataGridProps["className"];
  // defaultWidth?: number;
  // idealWidth?: number;
  isSortable?: boolean;
  isExportable?: boolean;
};

export const getTableColumns = (columns: Column[]): TableColumn[] => {
  return columns.map((column, index) => ({
    columnId: column.key,
    renderHeaderCell: () => (column?.onRenderHeader ? column?.onRenderHeader() : column.name),
    renderCell: (item) => {
      if (column.onRender) {
        return column.onRender(item, index, column);
      }

      return item[column.fieldName];
    },
    compare: (a, b) => {
      if (!column.isSortable) {
        return undefined;
      }

      if (column.compare) {
        return column.compare(a, b);
      }

      const aValue = a[column.fieldName] ?? "";
      const bValue = b[column.fieldName] ?? "";

      return aValue.localeCompare(bValue);

      // return column.isSortedDescending
      //   ? bValue.localeCompare(aValue)
      //   : aValue.localeCompare(bValue);
    },
    name: column?.name,
    minWidth: column?.minWidth ? column.minWidth + DEFAULT_CELL_PAD : undefined,
    maxWidth: column?.maxWidth ? column.maxWidth + DEFAULT_CELL_PAD : undefined,
    flexGrow: column?.flexGrow,
    getCellClassName: column?.getCellClassName,
    isSortable: column?.isSortable,
    isExportable: column?.isExportable,
  }));
};

export const getDataGridColumns = (columns: TableColumn[]): DataGridProps["columns"] =>
  columns.map((column) =>
    createTableColumn({
      columnId: column.columnId,
      renderHeaderCell: column.renderHeaderCell,
      renderCell: column.renderCell,
      compare: column.compare,
    }),
  );
