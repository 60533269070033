import "./styles.scss";

import { Text, Tooltip } from "@fluentui/react-components";
import { type CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FluentSchedule from "../../../../../assets/svg/FluentSchedule";
import MachineCvSensors from "../../../../../assets/svg/MachineCvSensors";
import MachineCVSummary from "../../../../../assets/svg/MachineCVSummary";
import MultipleComments from "../../../../../assets/svg/MultipleComments";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import TaskIcon from "../../../../../assets/svg/TaskIcon";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";
import { useSearch } from "../../../../../Hooks";
import { getConditionFormat } from "../../../../../modules/machine-cv/constants";
import type { ConditionStatus } from "../../../../../types";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar";
import V9Table from "../../../../common/Table/v9";
import { Stack } from "../../../../Stack";
import MachineNameField from "./MachineNameField";
import { useMachineConditionsTable } from "./useMachineConditionsTable";

export type MachineCardType = {
  id: string;
  title: string;
  subtitle?: string;
  iconId?: string;
  query: string;
  condition: ConditionStatus;
  isBookmarked?: boolean;
  projectName?: string;
};

type MachinesProps = {
  isLoading: boolean;
  sizeProps?: any;
};

export const machinesGridStyle: CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",
  gap: 16,
  marginTop: 10,
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
};

const getCommandBarItems = (t) => [
  {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => <div style={containerTitleStyle}>{t("Machines Conditions")}</div>,
  },
];

const ACTION_OPTIONS = [
  { name: "Summary", link: `/machine-cv/summary`, icon: <MachineCVSummary /> },
  {
    name: "Sensors",
    link: `/machine-cv/sensors`,
    icon: <MachineCvSensors />,
  },
  {
    name: "Tasks",
    link: `/machine-cv/tasks`,
    icon: <TaskIcon />,
  },
  {
    name: "Comments",
    link: `/machine-cv/comments`,
    icon: <MultipleComments />,
  },
  {
    name: "Reports",
    link: `/machine-cv/reports`,
    icon: <FluentSchedule />,
  },
  {
    name: "Trend",
    link: `/trend-view`,
    icon: <TrendsIcon fill='#2c529f' />,
  },
  {
    name: "Raw data",
    link: `/raw-data`,
    icon: <RawDataIcon />,
  },
];

const getColumnsV9 = (t) => [
  {
    key: "title",
    fieldName: "title",
    name: t("Machine Name"),
    minWidth: 200,
    maxWidth: 200,
    isSortable: true,
    onRender: (item) => <MachineNameField item={item}>{item["title"]}</MachineNameField>,
  },
  {
    key: "projectName",
    fieldName: "projectName",
    name: t("Project"),
    minWidth: 200,
    maxWidth: 200,
    isSortable: true,
    onRender: (item) => (
      <Tooltip
        key={item["projectName"]}
        withArrow
        relationship='label'
        content={item["projectName"]}
      >
        <Text
          style={{
            maxWidth: "100%",
            color: "rgb(96, 94, 92)",
            fontSize: "12px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {item["projectName"]}
        </Text>
      </Tooltip>
    ),
  },
  {
    key: "condition",
    fieldName: "condition",
    name: t("Condition"),
    minWidth: 200,
    maxWidth: 200,
    isSortable: true,
    getCellClassName: (item) => `Home-DataGridCell Home-DataGridCell--${item?.condition}`,
    onRender: (item) => {
      const conditionText = getConditionFormat(t)[item.condition];

      return conditionText;
    },
  },
  {
    key: "trendDataUntil",
    fieldName: "trendDataUntil",
    name: t("Last Data Update"),
    minWidth: 200,
    maxWidth: 200,
    isSortable: true,
    getCellClassName: (item) =>
      `Home-DataGridCell Home-DataGridCell--${(item?.lastDataUpdate ?? "").split(" ").join("-")}`,
    onRender: (item) => {
      return item.trendDataUntil?.toString();
    },
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 200,
    maxWidth: 200,
    isSortable: false,
    onRender: (item) => (
      <Stack horizontal>
        {ACTION_OPTIONS.map(({ name, link, icon }, idx) => (
          <Tooltip key={idx} withArrow relationship='label' content={t(name)}>
            <Link to={`${link}?id=${item.machineId}`} style={{ margin: "0 5px" }}>
              {icon}
            </Link>
          </Tooltip>
        ))}
      </Stack>
    ),
  },
];

export const Machines = ({ isLoading }: MachinesProps) => {
  const { t } = useTranslation();

  const { filteredMachines } = useMachineConditionsTable();
  const { data, handleSearch } = useSearch<MachineCardType>({
    data: filteredMachines,
    keys: ["title", "projectName", "condition"],
  });

  return (
    <div
      className='machines-container'
      style={{
        marginTop: 16,
        marginBottom: 80,
      }}
    >
      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItems(t))}
        onSearch={handleSearch}
      />

      <V9Table
        v8Columns={getColumnsV9(t)}
        items={data}
        isLoading={isLoading}
        persistOpts={{ key: "table-homepage-conditions", version: 2 }}
      />
    </div>
  );
};
