import { useEffect, useRef, useState } from "react";

import useTableStore from "./useTableStore";

type UsePaginationOpts = {
  total: number;
  perPage?: number;
  isLoading: boolean;
  resetPagination: boolean;
};

export type UsePagination = {
  total: number;
  current: number;
  perPage: number;
  onChanged: (page: number) => void;
  setPerPage: (page: number) => void;
};

export const usePagination = ({
  total,
  isLoading,
  resetPagination,
  perPage: _perPage,
}: UsePaginationOpts): UsePagination => {
  const totalRef = useRef(0);
  const [props, setProps] = useState({ current: 0, total: 0 });

  const { tablePerPage: perPage, setTablePerPage: setPerPage } = useTableStore((store) => ({
    tablePerPage: store.tablePerPage,
    setTablePerPage: store.setTablePerPage,
  }));

  const onSetPerPage = (value: any) => {
    localStorage.setItem("rows-per-page", value);
    setPerPage(value);
  };

  useEffect(() => {
    totalRef.current = 0;
    setProps({ ...props, current: 0, total: 0 });
  }, [resetPagination]);

  useEffect(() => {
    if (!isLoading) {
      if (totalRef.current !== total) {
        setProps({ ...props, current: 0, total });
      }

      totalRef.current = total;
    }
  }, [isLoading, total]);

  return {
    perPage: _perPage || perPage,
    setPerPage: onSetPerPage,
    total: props.total,
    current: props.current,
    onChanged: (page) => setProps({ ...props, current: page }),
  };
};
