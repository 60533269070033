import { Button } from "@fluentui/react-components";
import { ChevronDown20Regular, ChevronUp20Regular } from "@fluentui/react-icons";
import type { CSSProperties } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NoData } from "../../../../Components/common/NoData";

export type SizeProps = {
  size: number;
  increment: number;
};

type ShowMoreProps<T> = {
  data: T[];
  sizeProps?: SizeProps;
  renderComponent: (data: T[]) => React.ReactElement;
};

const buttonsContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  gap: 16,
  margin: "16px auto",
};

export const ShowMoreGrid = <T,>({
  data,
  sizeProps = { size: 6, increment: 3 },
  renderComponent,
}: ShowMoreProps<T>) => {
  const { t } = useTranslation();
  const { size, increment } = sizeProps;
  const [listSize, setListSize] = useState(size);

  // Updates the list size
  useEffect(() => {
    setListSize(size);
  }, [size]);

  return (
    <>
      {renderComponent(data.slice(0, listSize))}
      <div style={buttonsContainerStyle}>
        {listSize < data.length && (
          <Button
            appearance='primary'
            style={{ fontWeight: 600 }}
            onClick={() => setListSize(Math.min(listSize + increment, data.length))}
          >
            <div>{t("Show more")}</div>
            <ChevronDown20Regular style={{ marginLeft: 16 }} />
          </Button>
        )}
        {listSize > size && data.length > size && (
          <Button
            appearance='transparent'
            style={{ background: "#fff", fontWeight: 600 }}
            onClick={() => setListSize(size)}
          >
            <div>{t("Hide")}</div>
            <ChevronUp20Regular style={{ marginLeft: 16 }} />
          </Button>
        )}
      </div>

      {data.length === 0 && (
        <NoData
          style={{
            marginBottom: 16,
            marginTop: 16,
          }}
        />
      )}
    </>
  );
};
