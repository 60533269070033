import { Button, DialogActions, Text } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { addBulkFiles } from "../FIles/api";
import type { CreateFile } from "../FIles/models";
import AddDocumentDialog from "./AddDocumentDialog";

type NoFilesToSelectDialogProps = {
  machineId: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const NoFilesToSelect = ({ machineId, onClose, onSubmit }: NoFilesToSelectDialogProps) => {
  const { t } = useTranslation();

  const [hiddenFilesManagement, setHiddenFilesManagement] = useState(true);
  return (
    <>
      <Text block size={400}>
        {t("Seems, there is no files. Please add some files.")}
      </Text>
      <DialogActions>
        <Button
          appearance='primary'
          onClick={() => {
            setHiddenFilesManagement(false);
          }}
        >
          {t("Add image files")}
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
      <AddDocumentDialog
        hidden={hiddenFilesManagement}
        accept={".jpg,.jpeg,.png"}
        onClose={() => setHiddenFilesManagement(true)}
        onSubmit={(data) => {
          const filesToCreate: CreateFile[] = data.map((ele) => {
            return {
              description: ele.description,
              file: ele.file,
              machineId: machineId,
              name: ele.name,
            };
          });
          addBulkFiles(filesToCreate)
            .then((resp) => console.log({ resp }))
            .finally(() => onSubmit());
        }}
      />
    </>
  );
};
