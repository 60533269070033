import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import type { Group } from "../../../../../types";
import BaseDialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";
import { useInfoAdd, useUpdateGroupName } from "../../hooks/useInfoMutations";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import { FormType } from "../CommandBar";

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "group",
    type: FormItemType.TextField,
    groupProps: { label: t("Group name") },
    placeholder: t("Group name"),
  },
];

interface EditGroupDialogProps {
  formType: FormType.Edit;
  group: Group;
}

interface AddGroupDialogProps {
  formType: FormType.New;
  group?: Group;
}

type GroupDialogProps = Omit<DialogProps, "children"> & {
  onDismiss?: () => void;
  open: boolean;
} & (EditGroupDialogProps | AddGroupDialogProps) & { allGroups: Group[] };

const GroupDialog: React.FC<GroupDialogProps> = (props) => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const [isLoading, setIsLoading] = useState(false);

  const { formType, allGroups } = props;

  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const schema = useMemo(
    () =>
      z
        .object({
          group: z.string().min(1, { message: t("This field is required") }),
        })
        .refine(
          ({ group }) => {
            const refinedGroupName = props?.group?.name.toLowerCase().trim();
            const refinedInputName = group.toLowerCase().trim();

            if (refinedInputName === refinedGroupName) {
              return true;
            }

            const nameList = allGroups.map((group) => group.name.toLowerCase().trim());
            return !nameList.includes(refinedInputName);
          },
          { path: ["group"], message: t("Group name already exists") },
        ),

    [allGroups, t],
  );

  useEffect(() => {
    if (formType === FormType.Edit) {
      reset({ group: props.group.name });
    }
  }, [props.open]);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useZodForm({
    ...(props.formType === FormType.Edit && {
      defaultValues: {
        group: props.group.name,
      },
    }),
    schema,
  });

  const onFinish = (formType: FormType) => {
    refetch();
    onClose();
    notification.success(
      formType === FormType.New ? t("Group created successfully") : t("Group updated successfully"),
    );
  };

  const { addInfoAsync } = useInfoAdd();
  const { updateGroupNameAsync } = useUpdateGroupName();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    if (formType === FormType.New) {
      addInfoAsync({ ...data, machineId: id as string })
        .then(() => {
          onFinish(FormType.New);
        })
        .catch(() => notification.error(t("Error creating group")))
        .finally(() => setIsLoading(false));
      return;
    }

    updateGroupNameAsync({
      machineId: id as string,
      newGroup: data.group,
      oldGroup: props.group.name,
    })
      .then(() => {
        onFinish(FormType.Edit);
      })
      .catch(() => notification.error(t("Error updating group")))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  return (
    <BaseDialog {...props}>
      <DialogTitle>{formType === FormType.Edit ? t("Edit group") : t("Create group")}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {renderFormItems(getFields(t), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save")}
            </Button>
            <Button appearance='transparent' onClick={onClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default GroupDialog;
