import { Spinner } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimelineRegular } from "@fluentui/react-icons";
import { RefreshIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CommandBarItemType } from "../../common/CommandBar";
import Dropdown from "../../common/Dropdown";
import { getDayPickerStrings } from "../../common/Form";
import { notification } from "../../common/Notification";
import { useTableFilters } from "../../common/Table/v9";
import { Stack } from "../../Stack";
import { ImagesAPI } from "../api";
import ImagesPivotContainer from "../Generic/ImagesPivotContainer";
import type { ImageDataExtractionDuration, ImagesStatisticsResponse, TimeRange } from "../models";
import { ImagesTimeRanges } from "../models";
import ImagesDataExtractionTable from "./ImagesDataExtractionTable";
import ImagesStatisticsTable from "./ImagesStatisticsTable";

const getTimeRange = (key: keyof typeof ImagesTimeRanges) => {
  const result: TimeRange = {
    dateFrom: new Date(),
    dateTo: new Date(),
  };

  switch (ImagesTimeRanges[key]) {
    case ImagesTimeRanges.LastHour:
      result.dateFrom.setHours(result.dateFrom.getHours() - 1);
      break;
    case ImagesTimeRanges.Last3Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 3);
      break;
    case ImagesTimeRanges.Last6Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 6);
      break;
    case ImagesTimeRanges.Last12Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 12);
      break;
    case ImagesTimeRanges.LastDay:
      result.dateFrom.setDate(result.dateFrom.getDate() - 1);
      break;
    case ImagesTimeRanges.LastMonth:
      result.dateFrom.setMonth(result.dateFrom.getMonth() - 1);
      break;
  }

  return result;
};

/**
 * Gets the time range command bar items props.
 * @param selectedKey The selected time range key.
 * @param onTimeRangeChanged Method called when the time range selected has changed.
 * @returns The Command bar item props list.
 */
const getTimeRangeBarItems = (
  t,
  selectedKey: keyof typeof ImagesTimeRanges,
  timeRange: TimeRange,
  isRefreshing: boolean,
  onTimeRangeChanged: (option) => void,
  onDateFromSelected: (date: Date) => void,
  onDateToSelected: (date: Date) => void,
  onRefresh: () => void,
): any => {
  const result: any = [
    {
      key: "timeRange",
      type: CommandBarItemType.Custom,
      onRender: () => {
        const options = Object.keys(ImagesTimeRanges).map((key) => {
          return {
            key,
            text: t(ImagesTimeRanges[key as keyof typeof ImagesTimeRanges]),
          };
        });

        const dropdownTitle = options.find(({ key }) => key === selectedKey);

        return (
          <Stack.Item align='center'>
            <Dropdown
              options={options}
              selectedOptions={[selectedKey]}
              style={{
                minWidth: "11em",
                textAlign: "center",
              }}
              button={
                <Stack horizontal verticalAlign='center' style={{ gap: 10 }}>
                  <TimelineRegular style={{ color: "rgb(44, 83, 160)" }} />
                  <span>{dropdownTitle.text}</span>
                </Stack>
              }
              onOptionSelect={(_, option) => onTimeRangeChanged(option.optionValue)}
            />
          </Stack.Item>
        );
      },
    },
  ];

  const timePickers: any = [
    {
      key: "dateFrom",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Stack.Item align='end'>
            <DatePicker
              strings={getDayPickerStrings(t)}
              placeholder={t("Date From...")}
              maxDate={timeRange.dateTo}
              value={timeRange.dateFrom ?? undefined}
              style={{ minWidth: "9em" }}
              onSelectDate={onDateFromSelected}
            />
          </Stack.Item>
        );
      },
    },
    {
      key: "dateTo",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Stack.Item align='end'>
            <DatePicker
              strings={getDayPickerStrings(t)}
              placeholder={t("Date To...")}
              minDate={timeRange.dateFrom}
              maxDate={new Date()}
              value={timeRange.dateTo ?? undefined}
              style={{ minWidth: "9em" }}
              onSelectDate={onDateToSelected}
            />
          </Stack.Item>
        );
      },
    },
  ];

  const refreshButton: any = {
    key: "refresh",
    text: isRefreshing ? t("Refreshing...") : t("Refresh"),
    disabled: isRefreshing,
    type: CommandBarItemType.Button,
    icon: isRefreshing ? <Spinner size='extra-tiny' /> : <RefreshIcon />,
    onClick: onRefresh,
  };

  ImagesTimeRanges[selectedKey] === ImagesTimeRanges.Custom && result.push(...timePickers);
  result.push(refreshButton);

  return result;
};

/**
 * Gets the Images statistics tables component.
 * @returns The Images statistics tables component.
 */
const ImagesStatisticsTables = () => {
  const { t } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [data, setData] = useState<ImagesStatisticsResponse>(null);
  const [timeRangeKey, setTimeRangeKey] = useState<keyof typeof ImagesTimeRanges>(
    Object.keys(ImagesTimeRanges).at(0) as keyof typeof ImagesTimeRanges,
  );
  const [timeRange, setTimeRange] = useState<TimeRange>({
    dateFrom: null,
    dateTo: null,
  });
  const { filters, handleSearch } = useTableFilters<ImageDataExtractionDuration>({
    keys: ["fileName"],
  });

  // Sets the time range from time range key
  useEffect(() => {
    let newTimeRange: TimeRange = {
      dateFrom: new Date(),
      dateTo: new Date(),
    };

    if (ImagesTimeRanges[timeRangeKey] !== ImagesTimeRanges.Custom) {
      newTimeRange = getTimeRange(timeRangeKey);
    } else {
      newTimeRange.dateFrom.setHours(newTimeRange.dateFrom.getHours() - 1);
    }

    setTimeRange(newTimeRange);
  }, [timeRangeKey]);

  // Initializes the tables' data.
  useEffect(() => {
    if (!timeRange.dateFrom || !timeRange.dateTo || data) {
      return;
    }

    onRefresh();
  }, [timeRange]);

  // Handlers
  const onRefresh = () => {
    let newTimeRange = { ...timeRange };
    if (ImagesTimeRanges[timeRangeKey] !== ImagesTimeRanges.Custom) {
      newTimeRange = getTimeRange(timeRangeKey);
    }

    setIsRefreshing(true);
    ImagesAPI.getStatistics(newTimeRange.dateFrom, newTimeRange.dateTo).then((response) => {
      setIsRefreshing(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting images statistics: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      setData(response.data);
    });
  };

  return (
    <ImagesPivotContainer
      title={t("Images Statistics")}
      hasPermissions={true}
      commandBarItemProps={getTimeRangeBarItems(
        t,
        timeRangeKey,
        timeRange,
        isRefreshing,
        (option) => setTimeRangeKey(option.key as keyof typeof ImagesTimeRanges),
        (date) => setTimeRange({ ...timeRange, dateFrom: date }),
        (date) => setTimeRange({ ...timeRange, dateTo: date }),
        onRefresh,
      )}
      handleSearch={handleSearch}
    >
      <ImagesStatisticsTable tableItems={data?.statistics ?? []} isLoading={isRefreshing} />
      <ImagesDataExtractionTable
        tableItems={data?.dataExtractionDuration ?? []}
        filters={filters}
        isLoading={isRefreshing}
      />
    </ImagesPivotContainer>
  );
};

export default ImagesStatisticsTables;
