import type { SwitchProps } from "@fluentui/react-components";
import { makeStyles, Switch } from "@fluentui/react-components";
import { Controller } from "react-hook-form";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export const useStyles = makeStyles({
  root: {
    "& .fui-Switch__indicator": {
      margin: 0,
    },
    "& .fui-Switch__label": {
      padding: 0,
      marginLeft: "8px",
    },
  },
});

export type FormToggleProps = BaseFormFieldProps &
  SwitchProps & {
    name: string;
  };

export const FormToggle = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control },
}: FormToggleProps) => {
  const classes = useStyles();

  return (
    <>
      <FormGroup {...groupProps} label={undefined}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <>
                <Switch
                  style={{ margin: 0 }}
                  checked={value}
                  label={groupProps?.label}
                  className={classes.root}
                  onChange={(_, { checked }) => {
                    onChange(checked);
                  }}
                  onBlur={onBlur}
                />
              </>
            );
          }}
        />
      </FormGroup>
    </>
  );
};
