import type { DataGridProps } from "@fluentui/react-components";
import type { MutableRefObject } from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import type { TableColumn } from "./columns";
import useEventListener from "./useEventListener";

type UseResizingOpts = {
  hasSelection: boolean;
  tableRef: MutableRefObject<HTMLDivElement>;
  tableColumns: TableColumn[];
  columnSizingOptions: DataGridProps["columnSizingOptions"];
  persistOpts: any;
  resizableColumns: DataGridProps["resizableColumns"];
};

function getColumnsSize({ tableColumns, realTableWidth, selectionWidth }) {
  let totalMinWidth = 0,
    fixedItemsMinWidth = 0,
    totalFlexGrow = 0;

  for (const tableColumn of tableColumns) {
    totalMinWidth += tableColumn?.minWidth ?? 0;
    totalFlexGrow += tableColumn?.flexGrow ?? 0;

    if (typeof tableColumn?.flexGrow === "undefined") {
      fixedItemsMinWidth += tableColumn?.minWidth ?? 0;
    }
  }

  const widths = tableColumns.map((tableColumn) => {
    let width = tableColumn?.minWidth ?? 0;

    if (typeof tableColumn?.flexGrow !== "undefined") {
      width =
        ((realTableWidth - selectionWidth - fixedItemsMinWidth) / totalFlexGrow) *
        tableColumn.flexGrow;
    }

    return { columnId: tableColumn.columnId, width, minWidth: tableColumn?.minWidth };
  });

  return widths;
}

function getRealTableWidth({ tableRef, tableWidth }) {
  if (!tableRef?.current) {
    return tableWidth;
  }

  const offsetWidth = (tableRef?.current as any).offsetWidth;

  if (tableWidth === 0) {
    return offsetWidth;
  }

  if (offsetWidth < tableWidth) {
    return offsetWidth;
  } else {
    return tableWidth;
  }
}

const useResizing = ({
  hasSelection,
  tableRef,
  tableColumns,
  persistOpts,
  columnSizingOptions: columnSizingOptionsProps,
  resizableColumns: resizableColumnsProp,
}: UseResizingOpts) => {
  const [tableWidth, setTableWidth] = useState(0);

  const selectionWidth = useMemo(() => (hasSelection ? 44 : 0), [hasSelection]);
  const storageKey = useMemo(
    () => `${persistOpts.key}-${persistOpts.version}-resizing`,
    [persistOpts.key, persistOpts.version],
  );
  const columnSizeRef = useRef({});
  const [columnSizeState, setColumnSizeState] = useState({});

  useEventListener("resize", () => {
    if (tableRef?.current) {
      setTableWidth((tableRef.current as any).offsetWidth);
    }
  });

  const columnSizingOptions = useMemo(() => {
    if (columnSizingOptionsProps) {
      return columnSizingOptionsProps;
    }

    if (!tableColumns) {
      return undefined;
    }

    const realTableWidth = getRealTableWidth({ tableRef, tableWidth });

    const columnsSizes = getColumnsSize({ tableColumns, realTableWidth, selectionWidth });

    const hasMinWidth = tableColumns.some((column) => typeof column.minWidth !== "undefined");

    if (!hasMinWidth) {
      return undefined;
    }

    const columnSizing: DataGridProps["columnSizingOptions"] = {};
    for (const column of tableColumns) {
      const columnSize = columnsSizes.find((item) => item.columnId === column.columnId);

      columnSizing[column.columnId] = {
        minWidth: columnSize.minWidth,
        defaultWidth: columnSize.minWidth,
        idealWidth:
          columnSize.width >= columnSize.minWidth ? columnSize.width : columnSize.minWidth,
        padding: 4,
      };
    }

    return columnSizing;
  }, [columnSizingOptionsProps, tableColumns, tableRef, tableWidth, selectionWidth]);

  useEffect(() => {
    const columnsSize = localStorage.getItem(storageKey);

    if (!columnsSize) {
      localStorage.setItem(storageKey, JSON.stringify(columnSizingOptions));
      columnSizeRef.current = columnSizingOptions;
      setColumnSizeState(columnSizingOptions);
    } else {
      columnSizeRef.current = JSON.parse(columnsSize);
      setColumnSizeState(columnSizeRef.current);
    }
  }, [JSON.stringify(columnSizingOptions), persistOpts.key, persistOpts.version, storageKey]);

  const resizableColumns = useMemo(() => {
    if (typeof columnSizingOptions !== "undefined") {
      return true;
    }

    return resizableColumnsProp;
  }, [resizableColumnsProp, columnSizingOptions]);

  function handleColumnResize(_, data) {
    const { columnId, width } = data;

    if (!columnSizeRef.current) {
      return;
    }

    columnSizeRef.current = {
      ...columnSizeRef.current,
      [columnId]: { ...columnSizeRef.current[columnId], idealWidth: width },
    };

    localStorage.setItem(storageKey, JSON.stringify(columnSizeRef.current));
    setColumnSizeState(columnSizeRef.current);
  }

  return { columnSizingOptions: columnSizeState, resizableColumns, handleColumnResize };
};

export default useResizing;
