import { Text } from "@fluentui/react-components";
import classNames from "classnames";
import { endOfDay, format as fnsFormat, startOfDay } from "date-fns";
import { isEmpty } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { GroupedSignalsPeriod } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import type { TableProps } from "../../../../../common/Table/v9";
import Table from "../../../../../common/Table/v9";
import type { StackProps } from "../../../../../Stack";
import { Stack } from "../../../../../Stack";
import { getPeriodDropdownOptions } from "../../../config";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { getColumns } from "./columns";

const EventsTable = ({ ...rest }: StackProps) => {
  const { t } = useTranslation();
  const {
    eventsTableData: data,
    eventsTableLoading: isLoading,
    updateDriveLoadStore,
    selectedTableEvent,
    selectedFilterEventsNames,
    period,
  } = useDriveLoadStore((store: any) => ({
    eventsTableData: store.eventsTableData,
    eventsTableLoading: store.eventsTableLoading,
    updateDriveLoadStore: store.updateDriveLoadStore,
    selectedTableEvent: store.selectedTableEvent,
    selectedFilterEventsNames: store.selectedFilterEventsNames,
    period: store.period,
  }));
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const [latestPeriod, setLatestPeriod] = useState<GroupedSignalsPeriod | undefined>(period);

  const handleRowClick = useCallback(
    (rowData: any) => {
      const eventToSave = isEmpty(selectedTableEvent)
        ? rowData
        : rowData.timeStamp === selectedTableEvent.timeStamp
          ? {}
          : rowData;

      if (isEmpty(eventToSave)) {
        updateDriveLoadStore({ selectedTableEvent: {}, period: latestPeriod });
        return;
      }

      const timeStampToDate = new Date(eventToSave.timeStamp);
      const startOfDayDate = startOfDay(timeStampToDate.setDate(timeStampToDate.getDate() - 1));
      const endOfDayDate = endOfDay(timeStampToDate.setDate(timeStampToDate.getDate() + 1));

      updateDriveLoadStore({
        selectedTableEvent: eventToSave,
        period: {
          ...getPeriodDropdownOptions(t)[6],
          startDate: fnsFormat(new Date(startOfDayDate), FORMAT),
          endDate: fnsFormat(new Date(endOfDayDate), FORMAT),
        },
      });
    },
    [selectedTableEvent, period],
  );

  const tableProps: TableProps = useMemo(() => {
    return {
      persistOpts: {
        key: `table-drive-load-events`,
        version: 1,
      },
      items: filteredData,
      perPage: 99999,
      hidePerPage: true,
      isLoading,
      isError: false,
      v8Columns: getColumns(t, selectedTableEvent?.timeStamp),
      onRowClick: (item) => {
        handleRowClick(item);
      },
      getRowStyle: (item) =>
        selectedTableEvent?.timeStamp === item?.timeStamp
          ? { background: "#2c529f", color: "#fff" }
          : {},
    };
  }, [filteredData, isLoading, t, selectedTableEvent?.timeStamp, handleRowClick]);

  // Updates the filtered data.
  useEffect(() => {
    if (selectedFilterEventsNames.includes("selectAll")) {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(({ name }: any) => selectedFilterEventsNames.includes(name)));
    }
    return;
  }, [selectedFilterEventsNames, data]);

  useEffect(() => {
    if (!isEmpty(selectedTableEvent)) {
      return;
    }

    setLatestPeriod(period);
  }, [period]);

  let content: JSX.Element | null = null;
  if (isLoading) {
    content = <Spin style={{ height: "440px" }} />;
  } else if (isEmpty(filteredData)) {
    content = <NoData style={{ height: "440px" }} />;
  } else {
    content = <Table {...tableProps} />;
  }

  return (
    <Stack {...rest} horizontalAlign='stretch' style={{ gap: 5 }}>
      <Stack
        key='events-table-header'
        horizontal
        verticalAlign='center'
        role='menubar'
        style={{ margin: "0em 0em 0.4em 1em", gap: 20 }}
      >
        <Stack horizontalAlign='center' verticalAlign='center' style={{ height: "2.15em" }}>
          <Text size={400} as='h2' style={{ fontWeight: 600 }}>
            {t("Events Table")}
          </Text>
        </Stack>
      </Stack>
      <div
        key='events-table-content'
        className={classNames("table-drive-load-events", {
          "data-section": !isEmpty(filteredData),
        })}
      >
        {content}
      </div>
    </Stack>
  );
};

export default EventsTable;
