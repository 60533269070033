import type { CSSProperties } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentUser, useMachineCvOverviewList, useScreenSize } from "../../../Hooks";
import Bookmarks from "../../../modules/common/components/Bookmarks";
import useBookmark from "../../../modules/common/components/Bookmarks/useBookmark";
import { dashboardGridStyle, Dashboards } from "../../../modules/common/components/Dashboards";
import Dashboard from "./components/Dashboard";
import { Machines, machinesGridStyle } from "./components/Machines";
import { useMachineConditionsTable } from "./components/Machines/useMachineConditionsTable";
import { MyTasks } from "./components/MyTasks";
import QuickSettings from "./components/QuickSettings";
import { useHomeOverviewQuery } from "./hooks/useHomeOverviewQuery";
import { getMachines } from "./utils";

const gridItemsCountBigScreen = 10;

const styles: Record<string, CSSProperties> = {
  wrapper: {
    padding: 32,
  },
  header: {
    marginBottom: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 600,
    fontSize: 32,
    lineHeight: "40px",
    marginBottom: 16,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    marginBottom: 20,
  },
};

const Page = ({ settings }: any) => {
  const { t } = useTranslation();
  const username = useCurrentUser().displayName;
  const screenSize = useScreenSize();
  const { bookmarks, isLoading: bookmarksIsLoading } = useBookmark();
  const {
    data: { dashboards, tasks },
    isLoading,
    isError,
  } = useHomeOverviewQuery();

  const {
    data,
    isLoading: isMachineCVLoading,
    isError: isMachineCVError,
  } = useMachineCvOverviewList();
  const { setMachinesData } = useMachineConditionsTable();

  useEffect(() => {
    const machines = getMachines(data);

    if (machines.length === 0) {
      return;
    }

    setMachinesData({
      machines,
      isLoading: isMachineCVLoading,
      isError: isMachineCVError,
    });
  }, [data, isMachineCVLoading, isMachineCVError, setMachinesData]);

  const gridItemsCount =
    screenSize.width > 1920 ? gridItemsCountBigScreen : (settings?.gridItemsCount ?? 6);
  const commonSizeProps = { size: gridItemsCount, increment: gridItemsCount };

  const SECTIONS: any = {
    machines: (
      <Machines key='machines' isLoading={isMachineCVLoading} sizeProps={commonSizeProps} />
    ),
    bookmarks: (
      <div key={"Bookmarks"}>
        <Bookmarks
          key='Bookmarked Dashboards'
          gridStyle={dashboardGridStyle}
          isLoading={bookmarksIsLoading}
          bookmarks={bookmarks}
          title={t("Bookmarked Dashboards")}
          transformer={(data: any) => (data || []).filter((item: any) => item.isDashboard)}
          sizeProps={commonSizeProps}
        />
        <Bookmarks
          key='Bookmarked Machines'
          gridStyle={machinesGridStyle}
          isLoading={bookmarksIsLoading}
          bookmarks={bookmarks}
          title={t("Bookmarked Machines")}
          transformer={(data: any) => (data || []).filter((item: any) => item.isMachine)}
          sizeProps={commonSizeProps}
        />
      </div>
    ),
    dashboards: (
      <Dashboards
        key='dashboards'
        items={dashboards}
        isLoading={isLoading}
        sizeProps={commonSizeProps}
      />
    ),
    tasks: <MyTasks key='myTasks' items={tasks} isLoading={isLoading} isError={isError} />,
  };

  function renderSetSection(sectionKey: string) {
    return settings?.[sectionKey] ? SECTIONS[sectionKey] : null;
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.header}>
        <div>
          <div style={styles.title}>{t("Hey {{username}}", { username })}</div>
          <div style={styles.subtitle}>
            {t(
              "On Home you can see an overview of your corporations, machines and other useful information at a glance",
            )}
          </div>
        </div>

        <QuickSettings />
      </div>

      <Dashboard />

      {Object.keys(SECTIONS).map(renderSetSection)}
    </div>
  );
};

export default Page;
