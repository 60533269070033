import { difference } from "lodash-es";

import { getConditionFormat } from "../../../../modules/machine-cv/constants";

export const getStatusOptions: (t) => any[] = (t) => [
  {
    key: "selectAll",
    text: t("Select All"),
  },
  { key: "Ok", text: t("Ok"), data: "Ok" },
  { key: "Indication", text: t("Indication"), data: "Indication" },
  { key: "Alert", text: t("Alert"), data: "Alert" },
  { key: "Danger", text: t("Danger"), data: "Danger" },
  {
    key: "NotInstalled",
    text: getConditionFormat(t)["NotInstalled"],
    data: "NotInstalled",
  },
  {
    key: "NoStatus",
    text: getConditionFormat(t)["NoStatus"],
    data: "NoStatus",
  },
  { key: "Faulty", text: t("Faulty"), data: "Faulty" },
];

export const conditionOrder: { [key: string]: number } = {
  Faulty: 0,
  Danger: 1,
  Alert: 2,
  Indication: 3,
  Ok: 4,
  NotInstalled: 5,
  NoStatus: 6,
};

const SELECTED_ALL = "selectAll";

export const handleComboBoxChange = ({
  selectedOption,
  selectedOptions,
  options,
  onChange,
}: any) => {
  const optionsKeys = options.map(({ key }) => key);
  const filteredKeys = optionsKeys.filter((key) => key !== SELECTED_ALL);
  const keyDifferences = difference(filteredKeys, selectedOptions);

  if (selectedOption === SELECTED_ALL) {
    const newSelectedOption = !selectedOptions.includes(SELECTED_ALL) ? [] : optionsKeys;
    return onChange(newSelectedOption);
  }

  if (!keyDifferences.length) {
    return onChange(optionsKeys);
  }

  return onChange(selectedOptions.filter((key) => key !== SELECTED_ALL));
};
