import { Button } from "@fluentui/react-components";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import { Stack } from "../../../Stack";
import styles from "./Answer.module.css";

interface Props {
  error: string;
  onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack className={styles.answerContainer} verticalAlign='space-between'>
      <ErrorCircle24Regular aria-hidden='true' aria-label='Error icon' primaryFill='red' />

      <Stack.Item grow>
        <p className={styles.answerText}>{error}</p>
      </Stack.Item>

      <Button appearance='primary' className={styles.retryButton} onClick={onRetry}>
        {t("Retry")}
      </Button>
    </Stack>
  );
};
