import type {
  DialogProps,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { DialogContent, Tab, TabList } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import { detailsUsersRBAC } from "./api";
import type { WithOutPermissionsUserExtendedProperties } from "./models";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

type DetailsDialogProps = Omit<DialogProps, "children" | "open"> & {
  data?: WithOutPermissionsUserExtendedProperties;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialogPending = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const { t } = useTranslation();
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const handleClose = () => {
    onClose?.();
  };

  const [selectedValue, setSelectedValue] = useState<TabValue>("roles");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{
        width: DialogSize.L,
        height: "65vh",
      }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Details User Account")}</BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("Pivots to edit service account")}
          onTabSelect={onTabSelect}
        >
          <Tab value='roles'>{t("Roles")}</Tab>
        </TabList>
        <div>
          {selectedValue === "roles" && (
            <RolesComponentDetails
              data={undefined}
              memberId={data?.memberId}
              detailsFunction={detailsUsersRBAC}
              onSuccess={() => {
                handleClose();
                onSuccess(false, "");
              }}
            />
          )}
        </div>
      </DialogContent>
    </BaseDialog>
  );
};
