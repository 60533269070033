/* eslint-disable @typescript-eslint/no-unused-vars */

import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import { RefreshIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle } from "../../common/Dialog";
import type { TableProps } from "../../common/Table/v9";
import Table from "../../common/Table/v9";
import { Stack } from "../../Stack";
import { getColumnsListQueue } from "./columnsHelper";
import { queuedImageList } from "./Images/api";
import type { ImageQueued } from "./Images/models";

type AddOrEditDialogProps = Omit<DialogProps, "children"> & {
  onClose: () => void;
};

export const ListQueue = ({ onClose, ...rest }: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose?.();
  };

  const [items, setItems] = useState<ImageQueued[]>();

  useEffect(() => {
    queuedImageList().then((res) => setItems(res));

    return () => {};
  }, []);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-list-queued",
        version: 1,
      },
      items: items,
      perPage: 5,
      v8Columns: getColumnsListQueue(t),
      hidePerPage: items && items.length < 5,
    }),
    [items, t],
  );

  const dynamicTitle = (
    <Stack horizontal verticalAlign='center' style={{ gap: 8 }}>
      <div>{t("Queued list")}</div>
      <Button
        appearance='transparent'
        icon={<RefreshIcon />}
        onClick={() => queuedImageList().then((res) => setItems(res))}
      />
    </Stack>
  );

  return (
    <>
      {
        <BaseDialog {...rest} onOpenChange={handleClose}>
          <BaseDialogTitle>{dynamicTitle}</BaseDialogTitle>
          <DialogContent>
            <Table {...tableProps}></Table>
            <DialogActions>
              <Button appearance='transparent' onClick={handleClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </DialogContent>
        </BaseDialog>
      }
    </>
  );
};
