import { Text } from "@fluentui/react-components";
import { ClockIcon } from "@fluentui/react-icons-mdl2";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "../Stack";

export const WelcomeMessage = () => {
  const { t } = useTranslation();

  const cardStyles: CSSProperties = {
    background: "#fff",
    boxShadow: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    borderRadius: "8px",
    padding: "24px",
    maxWidth: "450px",
    width: "90%",
    margin: "20px auto",
    textAlign: "center",
  };

  return (
    <Stack horizontalAlign='center' verticalAlign='center' style={cardStyles}>
      <ClockIcon
        style={{
          fontSize: 48,
          color: "#2c529f",
          marginBottom: "16px",
        }}
      />
      <Text
        style={{
          fontSize: "24px",
          fontWeight: 600,
          color: "#323130",
          marginBottom: "16px",
        }}
      >
        {t("Welcome aboard!")}
      </Text>
      <Text
        style={{
          fontSize: "14px",
          color: "#605e5c",
          marginBottom: "16px",
          lineHeight: "20px",
        }}
      >
        {t(
          "Your account is all set up, but you don't have any permissions assigned yet. Our team has been notified and will set up your access shortly.",
        )}
      </Text>
      <Text
        style={{
          fontSize: "12px",
          color: "#605e5c",
          lineHeight: "16px",
        }}
      >
        {t(
          "Please check back in a few minutes. If you need immediate access, please contact your DALOG representative.",
        )}
      </Text>
    </Stack>
  );
};
