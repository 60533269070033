import {
  Field,
  MessageBar,
  MessageBarBody,
  ProgressBar,
  Spinner,
  Text,
} from "@fluentui/react-components";
import type { CSSProperties } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "../../Stack";

interface ProgressSpinnerProps {
  totalPromises: number;
  completedPromises: number;
  errors: Array<{ timeId: string; message: string }>;
}

const containerStyles: CSSProperties = {
  width: "80vw",
  height: "80vh",
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
};

const contentStyles: CSSProperties = {
  width: "500px",
  padding: "24px",
  backgroundColor: "white",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  borderRadius: "4px",
  gap: 16,
};

const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({
  totalPromises,
  completedPromises,
  errors,
}) => {
  const { t } = useTranslation();
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    const progress = totalPromises > 0 ? completedPromises / totalPromises : 0;
    setPercentComplete(progress);
  }, [completedPromises, totalPromises]);

  return (
    <Stack style={containerStyles}>
      <Stack style={contentStyles}>
        <Stack horizontalAlign='center' style={{ gap: 12 }}>
          <Spinner size='small' label={t("Processing operations...")} labelPosition='below' />

          <Text block size={400}>
            {t("Saving automatic triggers data")}
          </Text>

          <Field
            validationMessage={t(`{{operationsNum}} of {{totalOperations}} operations completed`, {
              operationsNum: completedPromises,
              totalOperations: totalPromises,
            })}
            validationState='none'
          >
            <ProgressBar value={percentComplete / 100} />
          </Field>
        </Stack>

        {errors.length > 0 && (
          <Stack style={{ gap: 8 }}>
            <Text block size={400} style={{ fontWeight: 600 }}>
              {t("Errors encountered:")}
            </Text>
            {errors.map((error, index) => (
              <MessageBar key={index} intent='error'>
                <MessageBarBody>
                  {t("Error in {{timeId}}: {{message}}", {
                    timeId: error.timeId,
                    message: error.message,
                  })}
                </MessageBarBody>
              </MessageBar>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ProgressSpinner;
