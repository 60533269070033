/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useZodWatch } from "../../../../Hooks/useZodWatch";
import { DialogSize } from "../../../common/Dialog";
import type { FormItemProps } from "../../../common/Form";
import { FormItemType, renderFormItems } from "../../../common/Form";
import { useZodForm } from "../../../common/Form/useZodForm";
import { notification } from "../../../common/Notification";
import FormDialog from "../../../Generic/FormDialog";
import { HaagMappingApi } from "./api";
import type { CreateHaagSignalMapping, HaagSignalMapping } from "./models";
import { OpcUaAccessMode, OpcUaDataType, SignalSource } from "./models";

type FormData = {
  source: string;
  sourceIdentifier: string;
  nodeId: string;
  namespaceIndex: string;
  deviceName: string;
  componentName: string;
  metricName: string;
  accessMode: string;
  periodSec: string;
  unit: string;
  dataType: string;
  plantId: string;
};

type HaagMappingAddEditDialogProps = {
  machineId: string;
  item?: HaagSignalMapping;
  onClose: (refresh: boolean) => void;
};

const getFormSchema = (t) =>
  z.object({
    source: z.string().min(1, t("This field is required")),
    sourceIdentifier: z.string().min(1, t("This field is required")),
    nodeId: z.string().min(1, t("This field is required")),
    namespaceIndex: z.string().refine((value) => !isNaN(Number(value)), {
      message: t("This value must be a number"),
    }),
    deviceName: z.string().min(1, t("This field is required")),
    componentName: z.string().min(1, t("This field is required")),
    metricName: z.string().min(1, t("This field is required")),
    accessMode: z.string(),
    periodSec: z.string().refine((value) => !isNaN(Number(value)), {
      message: t("This value must be a number"),
    }),
    unit: z.string().min(1, t("This field is required")),
    dataType: z.string(),
    plantId: z.string().min(1, t("This field is required")),
  });

const getFormItemProps: (t) => FormItemProps[] = (t) => [
  {
    name: "source",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Source *") },
    options: Object.keys(SignalSource).map((key) => ({
      key,
      text: SignalSource[key],
    })),
  },
  {
    name: "sourceIdentifier",
    type: FormItemType.TextField,
    groupProps: { label: t("Source Identifier *") },
  },
  {
    name: "nodeId",
    type: FormItemType.TextField,
    groupProps: { label: t("Node ID *") },
  },
  {
    name: "namespaceIndex",
    type: FormItemType.TextField,
    groupProps: { label: t("Namespace Index *") },
  },
  {
    name: "accessMode",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Access Mode") },
    options: Object.keys(OpcUaAccessMode).map((key) => ({
      key,
      text: OpcUaAccessMode[key],
    })),
  },
  {
    name: "dataType",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Data Type") },
    options: Object.keys(OpcUaDataType).map((key) => ({
      key,
      text: OpcUaDataType[key],
    })),
  },
  {
    name: "deviceName",
    type: FormItemType.TextField,
    groupProps: { label: t("Device Name *") },
  },
  {
    name: "componentName",
    type: FormItemType.TextField,
    groupProps: { label: t("Component Name *") },
  },
  {
    name: "metricName",
    type: FormItemType.TextField,
    groupProps: { label: t("Metric Name *") },
  },

  {
    name: "periodSec",
    type: FormItemType.TextField,
    groupProps: { label: t("Period (sec) *") },
  },
  {
    name: "unit",
    type: FormItemType.TextField,
    groupProps: { label: t("Unit *") },
  },
  {
    name: "plantId",
    type: FormItemType.TextField,
    groupProps: { label: t("Plant ID *") },
  },
];

const getFormData = (item?: HaagSignalMapping): FormData => {
  return {
    source: item?.source ?? "Unknown",
    sourceIdentifier: item?.sourceIdentifier ?? "",
    nodeId: item?.nodeId ?? "",
    namespaceIndex: item?.namespaceIndex.toString() ?? "0",
    deviceName: item?.deviceName ?? "",
    componentName: item?.componentName ?? "",
    metricName: item?.metricName ?? "",
    accessMode: OpcUaAccessMode[item?.accessMode ?? "Unknown"],
    periodSec: item?.periodSec.toString() ?? "0",
    unit: item?.unit ?? "",
    dataType: OpcUaDataType[item?.dataType ?? "Boolean"],
    plantId: item?.plantId ?? "",
  };
};

const getCreateHaagSignalMapping = (formData: FormData): CreateHaagSignalMapping => {
  return {
    source: formData.source as keyof typeof SignalSource,
    sourceIdentifier: formData.sourceIdentifier,
    nodeId: formData.nodeId,
    namespaceIndex: Number(formData.namespaceIndex),
    deviceName: formData.deviceName,
    componentName: formData.componentName,
    metricName: formData.metricName,
    accessMode: formData.accessMode as keyof typeof OpcUaAccessMode,
    periodSec: Number(formData.periodSec),
    unit: formData.unit,
    dataType: formData.dataType as keyof typeof OpcUaDataType,
    plantId: formData.plantId,
  };
};

const HaagMappingAddEditDialog = ({ machineId, item, onClose }: HaagMappingAddEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getFormSchema(t), [t]);
  const [newItem, setNewItem] = useState<CreateHaagSignalMapping | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: getFormData(item),
  });

  const { dataHasChanged } = useZodWatch<FormData>(
    control._defaultValues,
    control._formValues,
    watch,
    item === undefined,
  );

  // Adds or updates the HAAG mapping
  useEffect(() => {
    if (!newItem || !machineId) {
      return;
    }

    let mount = true;
    setIsLoading(true);
    if (item?.id) {
      HaagMappingApi.update(machineId, item.id, newItem).then((response) => {
        if (!mount) {
          return;
        }

        setIsLoading(false);
        if (response.status !== 200) {
          notification.error(
            response["text"]?.message
              ? t(`Error updating HAAG mapping item: {{errorMessage}}`, {
                  errorMessage: response["text"]?.message,
                })
              : t(`Error updating HAAG mapping item: Unknown error}`),
          );
          return;
        }

        notification.success(t("The HAAG mapping item was successfully updated"));
        onClose?.(true);
      });
    } else {
      HaagMappingApi.create(machineId, newItem).then((response) => {
        if (!mount) {
          return;
        }

        setIsLoading(false);
        if (response.status !== 201) {
          notification.error(
            response["text"]?.message
              ? t(`Error creating HAAG mapping item: {{errorMessage}}`, {
                  errorMessage: response["text"]?.message,
                })
              : t(`Error creating HAAG mapping item: Unknown error`),
          );
          return;
        }

        notification.success(t("The HAAG mapping item was successfully created"));
        onClose?.(true);
      });
    }

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [newItem]);

  // Handlers
  const onSubmitClick = handleSubmit((formData: FormData) => {
    setNewItem(getCreateHaagSignalMapping(formData));
  });

  return (
    <FormDialog
      title={!item ? t("Add HAAG Mapping") : t("Edit HAAG Mapping")}
      submitButtonText={!item ? t("Add") : t("Save Changes")}
      isLoading={isLoading}
      isValid={isValid && dataHasChanged}
      size={DialogSize.M}
      onSubmit={onSubmitClick}
      onClose={() => onClose?.(false)}
    >
      {renderFormItems(getFormItemProps(t), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};

export default HaagMappingAddEditDialog;
