/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  makeStyles,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarGroup,
  MessageBarTitle,
  Text,
} from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import type { HTMLAttributes } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type AppNotificationMessageProps = Omit<
  HTMLAttributes<HTMLElement>,
  "role" | "className" | "onAnimationEnd"
> & {
  title: string;
  message: string;
  visited: boolean;
  expired: boolean;
  onVisited: () => void;
};

const useStyles = makeStyles({
  container: {
    position: "fixed",
    top: "0.5em",
    right: "0.5em",
    width: "25em",

    zIndex: 999,
  },
  bar: {
    alignItems: "flex-start",
    padding: "8px 12px",
    boxShadow:
      "rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 0,
  },
  closeButton: {
    padding: 0,
  },
});

export const AppNotificationMessage = ({
  title,
  message,
  visited,
  expired,
  onVisited: onMessageSeen,
}: AppNotificationMessageProps) => {
  const { t } = useTranslation();
  const { container, bar, body, actions, closeButton } = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(!visited);

  const onClose = () => {
    setIsVisible(false);
    onMessageSeen?.();
  };

  return (
    <div className={container}>
      <MessageBarGroup animate='both'>
        {isVisible && !expired ? (
          <MessageBar className={bar}>
            <MessageBarBody className={body}>
              <MessageBarTitle>{title}</MessageBarTitle>

              <Text size={300} as='p'>
                {message}
              </Text>

              <MessageBarActions className={actions}>
                <Button
                  className={closeButton}
                  appearance='transparent'
                  icon={<Dismiss12Regular />}
                  onClick={onClose}
                >
                  {t("Close")}
                </Button>
              </MessageBarActions>
            </MessageBarBody>
          </MessageBar>
        ) : null}
      </MessageBarGroup>
    </div>
  );
};
