import { Stack } from "../../Stack";
import ImagesStatisticsTables from "./ImagesStatisticsTables";
import ImagesTable from "./ImagesTable";

type ImagesListProps = {
  hasAddPermissions: boolean;
};

/**
 * Gets the Images pivot section component.
 * @param hasAddPermissions Value determining whether the user has permissions to add images.
 * @returns The images pivot section component.
 */
const ImagesPivotSection = ({ hasAddPermissions }: ImagesListProps) => {
  return (
    <Stack style={{ gap: 10 }}>
      <ImagesTable hasAddPermissions={hasAddPermissions} />
      <ImagesStatisticsTables />
    </Stack>
  );
};

export default ImagesPivotSection;
