/* eslint-disable */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { notification } from "../common/Notification";

import type { MachineToList } from "../Machines/models";
import type { ResponseProjectDetails } from "../Projects/models";
import D850CfgUploadDialog from "./D850ConfigDialog";
import { DataloggerEditDialog } from "./DataloggerAddEditDialogs";
import DataloggerDeleteDialog from "./DataloggerDeleteDialog";
import DataloggerSelectMachinesDialog from "./DataloggerSelectMachinesDialog";
import { getDataloggerColumns } from "./DataloggerTableColumns";
import GenerateConfigDialog from "./GenerateConfigs/GenerateConfigDialog";
import type { D325, D850, D850Eco, DBasic } from "./models";
import { AdjustmentCardTypes, D850OS, DModels } from "./models";
import { listDataloggersAsync, selectDataloggers, selectDataloggersStatus } from "./reducer";
import type {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "./tableItemModels";
import { Utils } from "./utils";
import Table from "../common/Table/v9/Table";
import { Stack } from "../Stack";

enum TableItemAction {
  None,
  Edit,
  Delete,
  SelectMachines,
  D850LinuxConfig,
  GenerateDdpConfig,
}

type DataloggersTableProps = {
  model: DModels;
  isMetaDataContributor: boolean;
  projectsRef: ResponseProjectDetails[];
  machinesRef: MachineToList[];
  searchText: string;
  onDataloggersRead?: (count: number) => void;
};

type TableDialogsProps = {
  model: DModels;
  action: TableItemAction;
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  items: D325[] | DBasic[] | D850Eco[] | D850[];
  onClose: () => void;
};

/**
 * Gets a datalogger table item from a datalogger entry.
 * @param entry The datalogger entry.
 * @returns The datalogger table item, depending on the datalogger model.
 */
const getTableItem = (
  model: DModels,
  entry: D325 | DBasic | D850Eco | D850,
): D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem | null => {
  switch (model) {
    case DModels.D325:
      const d325Entry = entry as D325;
      const d325Item: D325TableItem = {
        id: d325Entry.id,
        macAddress: d325Entry.macAddress,
        projectId: d325Entry.projectId,
        projectName: "",
        machineIds: d325Entry.machineIds,
        machines: [],
        firmwareVersion: d325Entry.firmwareVersion,
      };

      return d325Item;

    case DModels.D555:
    case DModels.D650:
      const dBasicEntry = entry as DBasic;
      const dBasicItem: DBasicTableItem = {
        id: dBasicEntry.id,
        macAddress: dBasicEntry.macAddress,
        projectId: dBasicEntry.projectId,
        projectName: "",
        machineIds: dBasicEntry.machineIds,
        machines: [],
        firmwareVersion: dBasicEntry.firmwareVersion,
        ipAddress: dBasicEntry.ipAddress,
        subnetMask: dBasicEntry.subnetMask,
        gateway: dBasicEntry.gateway,
      };

      return dBasicItem;

    case DModels.D850Eco:
      const d850EcoEntry = entry as D850Eco;
      const d850EcoItem: D850EcoTableItem = {
        id: d850EcoEntry.id,
        macAddress: d850EcoEntry.basisCard.macAddress,
        projectId: d850EcoEntry.projectId,
        projectName: "",
        machineIds: d850EcoEntry.machineIds,
        machines: [],
        adjustmentCardType: AdjustmentCardTypes[d850EcoEntry.adjustmentCard.adjustmentCardType],
      };

      return d850EcoItem;

    case DModels.D850:
      const d850Entry = entry as D850;
      const d850Item: D850TableItem = {
        id: d850Entry.id,
        macAddress: d850Entry.basisCard.macAddress,
        projectId: d850Entry.projectId,
        projectName: "",
        machineIds: d850Entry.machineIds,
        machines: [],
        adjustmentCardType: AdjustmentCardTypes[d850Entry.adjustmentCard.adjustmentCard],
        operativeSystem: D850OS[d850Entry.operativeSystem],
        ipAddress: d850Entry.ipAddress,
        subnetMask: d850Entry.subnetMask,
        gateway: d850Entry.gateway,
      };

      return d850Item;
  }
};

/**
 * Gets the table items
 * @param model The datalogger model.
 * @param entries The dataloggers list
 * @returns The dataloggers table items list.
 */
function getTableItems<T = D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem>(
  model: DModels,
  entries: D325[] | DBasic[] | D850Eco[] | D850[],
): T[] {
  const result: T[] = [];

  for (const entry of entries) {
    const item = getTableItem(model, entry) as T;
    if (!item) {
      continue;
    }

    result.push(item);
  }

  return result;
}

/**
 * Gets and item from a determined datalogger items list.
 * @param model The datalogger model
 * @param id The item Id
 * @param items The datalogger items list.
 * @returns The datalogger item.
 */
const getItem = (model: DModels, id: string, items: D325[] | DBasic[] | D850Eco[] | D850[]) => {
  let result: D325 | DBasic | D850Eco | D850 = undefined;
  switch (model) {
    case DModels.D325:
      result = (items as D325[]).find((item) => item.id === id);
      break;
    case DModels.D555:
    case DModels.D650:
      result = (items as DBasic[]).find((item) => item.id === id);
      break;
    case DModels.D850Eco:
      result = (items as D850Eco[]).find((item) => item.id === id);
      break;
    case DModels.D850:
      result = (items as D850[]).find((item) => item.id === id);
      break;
  }

  return result;
};

/**
 * Gets the Table Item dialog component.
 * @param model The datalogger model.
 * @param action The table action
 * @param tableItem The selected table item
 * @param items The dataloggers items list
 * @param onClose Function called when the dialog needs to be closed. It must be implemented.
 * @returns The table item dialog component.
 */
const TableItemDialog = ({ model, action, tableItem, items, onClose }: TableDialogsProps) => {
  let result: JSX.Element = null;
  if (!tableItem) {
    return result;
  }

  const itemSelectMachines = getItem(model, tableItem.id, items);

  switch (action) {
    case TableItemAction.Edit:
      if (!itemSelectMachines) {
        break;
      }

      result = <DataloggerEditDialog model={model} entry={itemSelectMachines} onClose={onClose} />;
      break;
    case TableItemAction.Delete:
      result = <DataloggerDeleteDialog model={model} entry={tableItem} onClose={onClose} />;
      break;
    case TableItemAction.SelectMachines:
      if (!itemSelectMachines) {
        break;
      }

      result = (
        <DataloggerSelectMachinesDialog
          model={model}
          entry={itemSelectMachines}
          onClose={onClose}
        />
      );

      break;

    case TableItemAction.D850LinuxConfig:
      result = <D850CfgUploadDialog tableItem={tableItem} onClose={onClose} />;
      break;

    case TableItemAction.GenerateDdpConfig:
      result = <GenerateConfigDialog tableItem={tableItem} onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the dataloggers Table Component.
 * @param model The datalogger model.
 * @param isMeasuredDataContributor A value indicating whether the current user is measured data contributor.
 * @param projectsRef The reference project list to complete the dataloggers project information.
 * @param machinesRef The reference machines list, to complete the dataloggers machines information.
 * @param searchText The search text key to filtered the dataloggers table. If empty, all dataloggers will be shown.
 * @param onDataloggersRead Method called when the dataloggers data has been successfully read.
 * @returns The datalogger table component.
 */
export const DataloggersTable = ({
  model,
  isMetaDataContributor,
  projectsRef,
  machinesRef,
  searchText,
  onDataloggersRead,
}: DataloggersTableProps) => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const items = useAppSelector(selectDataloggers(model));
  const dataloggersStatus = useAppSelector(selectDataloggersStatus(model));
  const [tableItems, setTableItems] = useState<
    D325TableItem[] | DBasicTableItem[] | D850EcoTableItem[] | D850TableItem[]
  >([]);
  const [filteredTableItems, setFilteredTableItems] = useState<
    D325TableItem[] | DBasicTableItem[] | D850EcoTableItem[] | D850TableItem[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [itemToEdit, setItemToEdit] = useState<
    D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem | null
  >(null);
  const [itemActionClicked, setTableItemAction] = useState<TableItemAction>(TableItemAction.None);

  // Gets the dataloggers list.
  useEffect(() => {
    if (dataloggersStatus !== Status.void) {
      return;
    }

    setLoading(true);
    dispatch(listDataloggersAsync(model)());
  }, [dispatch]);

  // Builds the datalogger table items
  useEffect(() => {
    // In case of any errors in the response.
    if (!items) {
      navigate(-1);
      notification.error(t(`Failure: Getting dataloggers {{model}} list.`, { model }));
      return;
    }

    if (items.length === 0) {
      return;
    }

    // Gets the dataloggers information
    let result: D325TableItem[] | DBasicTableItem[] | D850EcoTableItem[] | D850TableItem[];
    switch (model) {
      case DModels.D325:
        result = getTableItems<D325TableItem>(DModels.D325, items as D325[]);
        break;
      case DModels.D555:
      case DModels.D650:
        result = getTableItems<DBasicTableItem>(model, items as DBasic[]);
        break;
      case DModels.D850Eco:
        result = getTableItems<D850EcoTableItem>(DModels.D850Eco, items as D850Eco[]);
        break;
      case DModels.D850:
        result = getTableItems<D850TableItem>(DModels.D850, items as D850[]);
        break;
    }

    // Gets projects and machines information.
    let tableItemsWithProjectsAndMachines = [];
    if (result.length > 0) {
      tableItemsWithProjectsAndMachines = result?.map((item) => {
        const newItem = { ...item };
        const project = projectsRef.find((p) => p.id === item.projectId);
        if (project) {
          newItem.projectName = project.name;
        }

        const machines = Utils.getDataloggerMachines(item, machinesRef);
        if (machines?.length > 0) {
          newItem.machines = machines;
        }

        return newItem;
      });
    }

    setTableItems(tableItemsWithProjectsAndMachines);
    setLoading(false);
  }, [projectsRef, machinesRef, items]);

  // Filters the dataloggers items.
  useEffect(() => {
    let result: D325TableItem[] | DBasicTableItem[] | D850EcoTableItem[] | D850TableItem[] = [];
    switch (model) {
      case DModels.D325:
        result = Utils.getFilteredItems<D325TableItem>(
          DModels.D325,
          tableItems as D325TableItem[],
          searchText,
        );
        break;
      case DModels.D555:
      case DModels.D650:
        result = Utils.getFilteredItems<DBasicTableItem>(
          model,
          tableItems as DBasicTableItem[],
          searchText,
        );
        break;
      case DModels.D850Eco:
        result = Utils.getFilteredItems<D850EcoTableItem>(
          DModels.D850Eco,
          tableItems as D850EcoTableItem[],
          searchText,
        );
        break;
      case DModels.D850:
        result = Utils.getFilteredItems<D850TableItem>(
          DModels.D850,
          tableItems as D850TableItem[],
          searchText,
        );
        break;
    }

    onDataloggersRead?.(result.length);
    setFilteredTableItems(result);
  }, [tableItems, searchText]);

  // * Event handlers.
  const onEditHandler = (
    tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => {
    setItemToEdit(tableItem);
    setTableItemAction(TableItemAction.Edit);
  };

  const onDeleteHandler = (
    tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => {
    setItemToEdit(tableItem);
    setTableItemAction(TableItemAction.Delete);
  };

  const onSelectMachinesHandler = (
    tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => {
    setItemToEdit(tableItem);
    setTableItemAction(TableItemAction.SelectMachines);
  };

  const onD850LinuxConfigHandler = (
    tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => {
    setItemToEdit(tableItem);
    setTableItemAction(TableItemAction.D850LinuxConfig);
  };

  const onGenerateConfigHandler = (
    tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => {
    setItemToEdit(tableItem);
    setTableItemAction(TableItemAction.GenerateDdpConfig);
  };

  const onCloseHandler = () => {
    setItemToEdit(null);
    setTableItemAction(TableItemAction.None);
  };

  return (
    <Stack>
      <Table
        items={filteredTableItems}
        v8Columns={getDataloggerColumns({
          t,
          model: model,
          isMetaDataContributor: isMetaDataContributor,
          onEdit: onEditHandler,
          onDelete: onDeleteHandler,
          onSelectMachines: onSelectMachinesHandler,
          onD850LinuxConfig: onD850LinuxConfigHandler,
          onGenerateConfig: onGenerateConfigHandler,
        })}
        persistOpts={{
          key: `table-dataloggers-${
            model === DModels.D555 || model === DModels.D650 ? "basic" : model
          }`,
          version: 2,
        }}
        isLoading={loading}
      />
      <TableItemDialog
        model={model}
        action={itemActionClicked}
        tableItem={itemToEdit}
        items={items}
        onClose={onCloseHandler}
      />
    </Stack>
  );
};
