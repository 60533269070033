import {
  Button,
  DialogActions,
  DialogContent,
  Spinner,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { Delete20Regular } from "@fluentui/react-icons";
import { DeleteTableIcon } from "@fluentui/react-icons-mdl2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { textStyle, titleStyle } from "../../schema/Constants";
import { Personas } from "../../schema/personas";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import FacePile from "../common/FacePile";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table from "../common/Table/v9";
import { SimpleDeleteConfirm } from "../Generic/SimpleDeleteConfirm";
import {
  addManyPermissionsApi,
  deletePermission,
  removePermissionsOfMember,
} from "../Permissions/api";
import type { RequestRolesAddMany } from "../Permissions/models";
import type { personaProps, ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import type {
  PermissionExpanded,
  ServiceAccountAddRole,
  ServiceAccountDetails,
} from "../ServiceAccount/models";
import { Stack } from "../Stack";
import type { UserDetails } from "../UsersRBCA/models";
import { AddRole } from "./AddRole";
import { AddRolePersona } from "./AddRolePersona";

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onDelete: (serviceAccount: ServiceAccountAddRole) => void;
};

type GetColumnsCurrentRolesOpts = {
  t: any;
  hasActions: boolean;
  handleRoleDeletion: (role: PermissionExpanded) => void;
};

const getColumnsCurrent = ({
  t,
  hasActions,
  handleRoleDeletion,
}: GetColumnsCurrentRolesOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: t("Role"),
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scope",
      name: t("Scope"),
      fieldName: "scope",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "scopeResource",
      name: t("Scope Resource"),
      fieldName: "scopeResource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: t("Expires At Utc"),
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];
  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: PermissionExpanded) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                handleRoleDeletion(role);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }
  return columns;
};

const getColumns = ({ t, hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: t("Role"),
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scopeLevel",
      name: t("Scope Level"),
      fieldName: "scopeLevel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "resource",
      name: t("Resource"),
      fieldName: "resource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: t("Expires at UTC"),
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: ServiceAccountAddRole) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(role);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

type RolesComponentDetProps = {
  data?: ServiceAccountDetails | UserDetails;
  memberId: string;
  detailsFunction: (id: string) => Promise<any>;
  onSuccess?: () => void;
};

export const RolesComponentDetails = ({
  data,
  memberId,
  detailsFunction,
  onSuccess,
}: RolesComponentDetProps) => {
  const { t } = useTranslation();
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>([]);
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddRolePersona, setShowAddRolePersona] = useState(false);

  const [showDialogRoles, setShowDialogRoles] = useState(false);

  const [persona, setPersona] = useState<personaProps>();
  const [dataNew, setDataNew] = useState<ServiceAccountDetails | UserDetails>(data);
  const [showBaseDialog, setShowBaseDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<{
    data: PermissionExpanded | undefined;
    context: "deleteSingleRole";
  }>();

  const onClickPersona = (data) => {
    setShowAddRolePersona((prv) => !prv);
    setPersona(data);
  };

  const onDeleteCurrentRole = async (role: PermissionExpanded) => {
    await deletePermission(role.id);
    await detailsFunction(memberId).then((resp) => {
      if ("permissions" in resp) setDataNew(resp);
      else notification.warning(t("Problem reaching the response, please try it again later."));
    });

    notification.success(t("Permission deleted successfully."));
  };

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId,
      ),
    );
  };

  const personas = Personas.allPersonas.map((eleF) => {
    return {
      personaName: eleF.name,
      imageInitials: eleF.imageInitials,
      onClick: () => onClickPersona({ persona: eleF.personaRoles, root: eleF.root }),
    };
  });

  return (
    <div style={{ marginTop: "50px" }}>
      <Stack verticalFill>
        <Stack horizontal style={{ gap: 20 }} horizontalAlign='start'>
          <Text style={titleStyle}>{t("Current")}</Text>
          <Tooltip
            withArrow
            relationship='label'
            content={
              !dataNew?.permissions || dataNew?.permissions.length < 1
                ? t("No current values to delete")
                : t("Delete all current roles")
            }
          />

          <Stack horizontal>
            <Button
              appearance='transparent'
              disabled={!dataNew?.permissions || dataNew?.permissions.length < 1}
              style={{ marginRight: "20px" }}
              onClick={() => setShowBaseDialog(true)}
            >
              {t("Remove All Roles")}
            </Button>
            <Button appearance='primary' onClick={() => setShowDialogRoles(true)}>
              {t("Append")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Table
        persistOpts={{
          key: "table-roles",
          version: 2,
        }}
        header={{
          title: "",
        }}
        items={dataNew?.permissions ? dataNew.permissions : []}
        v8Columns={getColumnsCurrent({
          t,
          hasActions: true,
          handleRoleDeletion(role) {
            setSelected({ context: "deleteSingleRole", data: role });
          },
        })}
      />

      <BaseDialog
        open={showDialogRoles}
        surfaceStyle={{ maxWidth: DialogSize.L }}
        onOpenChange={() => {
          setShowDialogRoles(false);
          if (rolesToAdd.length > 0)
            notification.open({
              type: "warning",
              message: t("Info: Some roles to add were not updated."),
            });
        }}
      >
        <BaseDialogTitle>{t("Append")}</BaseDialogTitle>
        <DialogContent>
          <div style={{ minWidth: "300px", padding: "40px" }}>
            <AddRole
              show={showAddRole}
              rolesToAdd={rolesToAdd}
              setRolesToAdd={setRolesToAdd}
              onClose={() => setShowAddRole((prv) => !prv)}
            ></AddRole>
            {persona && (
              <AddRolePersona
                key={Math.random()}
                show={showAddRolePersona}
                rolesToAdd={rolesToAdd}
                setRolesToAdd={setRolesToAdd}
                persona={persona}
                onClose={() => setShowAddRolePersona((prv) => !prv)}
              />
            )}

            <Stack verticalFill>
              <Stack horizontal style={{ gap: 20 }} horizontalAlign='start'>
                <Text style={titleStyle}>{t("To Append")}</Text>
                <Tooltip
                  withArrow
                  relationship='label'
                  content={
                    !rolesToAdd || rolesToAdd.length < 1
                      ? t("No values to delete")
                      : t("Delete all roles to add.")
                  }
                >
                  <Button
                    icon={
                      <DeleteTableIcon
                        style={{ fontSize: "16px", color: "#2c529f", cursor: "pointer" }}
                      />
                    }
                    disabled={!rolesToAdd || rolesToAdd.length < 1}
                    onClick={() => setRolesToAdd([])}
                  />
                </Tooltip>
                <Button appearance='primary' onClick={() => setShowAddRole((prv) => !prv)}>
                  {t("Single role")}
                </Button>
                <FacePile personas={personas} maxPersonas={3} />

                <Stack horizontal horizontalAlign='end'>
                  <Button
                    appearance='primary'
                    disabled={!rolesToAdd || rolesToAdd.length < 1}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                    onClick={async () => {
                      setIsLoading(true);
                      const addRolesPerUser: RequestRolesAddMany = {
                        memberAndOrServiceAccountIds: [memberId],
                        roles: rolesToAdd,
                      };
                      await addManyPermissionsApi(addRolesPerUser).then((res) => {
                        if (!(res.status < 200 && res.status > 210))
                          notification.success(t("Updated roles successfully"));
                        else notification.error(t("Something went wrong"));
                      });

                      await detailsFunction(memberId).then((resp) => {
                        if ("permissions" in resp) {
                          setDataNew(resp);
                          setRolesToAdd([]);
                        } else
                          notification.warning(
                            t("Problem reaching the response, please try it again later."),
                          );
                        setShowDialogRoles(false);
                        setIsLoading(false);
                        onSuccess && onSuccess();
                      });
                    }}
                  >
                    {t("Append")}
                  </Button>
                </Stack>
              </Stack>
              <Table
                persistOpts={{
                  key: "table-rolesAndScopes",
                  version: 2,
                }}
                header={{
                  title: "",
                }}
                items={rolesToAdd}
                v8Columns={getColumns({
                  t,
                  hasActions: true,
                  onDelete,
                })}
                isLoading={showAddRole}
                isError={rolesToAdd.length === 0}
              />
            </Stack>
          </div>
        </DialogContent>
      </BaseDialog>
      <BaseDialog
        open={showBaseDialog}
        surfaceStyle={{ width: DialogSize.M }}
        onOpenChange={() => setShowBaseDialog(false)}
      >
        <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>
        <DialogContent>
          <div style={textStyle}>
            {t("You are about to delete all permissions of the selected user.")}
          </div>
          <DialogActions>
            <Button
              appearance='primary'
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              onClick={async () => {
                setIsLoading(true);
                await removePermissionsOfMember(memberId).then((res) => {
                  if (!(res.status < 200 && res.status > 210)) {
                    notification.success(t("Deleted successfully"));
                  } else notification.error(t("Something went wrong"));
                  setIsLoading(false);
                  setShowBaseDialog(false);
                });
                await detailsFunction(memberId).then((resp) => {
                  if ("permissions" in resp) setDataNew(resp);
                  else
                    notification.warning(
                      t("Problem reaching the response, please try it again later."),
                    );
                });
              }}
            >
              {t("Delete")}
            </Button>
            <Button
              onClick={() => {
                setShowBaseDialog(false);
              }}
            >
              {t("Close")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>

      {selected?.context === "deleteSingleRole" && (
        <SimpleDeleteConfirm
          show={selected?.context === "deleteSingleRole"}
          text={t("the role selected")}
          handleOk={() => onDeleteCurrentRole(selected.data)}
          onClose={() => setSelected(undefined)}
        />
      )}
    </div>
  );
};
