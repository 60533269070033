import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useSearch } from "../../../Hooks";
import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import type { Report } from "../../../types";
import Table from "../../common/Table/v9";
import { AlertDocumentDialog } from "./components/AlertDocumentDialog";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import { useReportsQuery } from "./hooks/useAlertsQuery";
import type { UserActionState } from "./models";
import { UserActions } from "./models";

type ActionDialogProps = {
  state: UserActionState;
  onClose: (refresh: boolean) => void;
};

const ActionDialog = ({ state, onClose }: ActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (state.action) {
    case UserActions.ReportAdd:
    case UserActions.ReportEdit:
      result = <AlertDocumentDialog report={state.report} onClose={onClose} />;
      break;
    case UserActions.ReportDelete:
      if (!state.report) {
        break;
      }
      result = (
        <DeleteDialog
          open={true}
          data={{
            ids: [state.report.id],
            name: "1 alert",
          }}
          route={"/meta/machinecv/v1/logEntries"}
          onClose={() => onClose?.(false)}
          onSuccess={() => onClose?.(true)}
        />
      );
      break;
  }
  return result;
};

const Page = () => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const [state, setState] = useState<UserActionState>({
    action: UserActions.None,
  });
  const { reports, isError, isLoading, refetch } = useReportsQuery({
    machineId: id as string,
  });
  const filteredReports = reports?.filter((item) => item.category === "Alert") || [];

  const { data, handleSearch } = useSearch<Report>({
    data: filteredReports,
    keys: ["text"],
  });

  // Handlers
  const onClose = (refresh: boolean) => {
    setState({ action: UserActions.None });
    refresh && refetch();
  };

  return (
    <div className='machine-cv-reports-table'>
      <CommandBar
        handleSearch={handleSearch}
        onAdd={() => setState({ action: UserActions.ReportAdd })}
      />
      <Table
        persistOpts={{
          key: "table-reports",
          version: 1,
        }}
        header={{
          title: t("Alerts"),
        }}
        items={data}
        isLoading={isLoading}
        isError={isError}
        v8Columns={getColumns({
          t,
          onEdit: (report) => setState({ action: UserActions.ReportEdit, report }),
          onDelete: (report) => setState({ action: UserActions.ReportDelete, report }),
        })}
      />
      <ActionDialog state={state} onClose={onClose} />
    </div>
  );
};

export default Page;
