import "./styles.scss";

import { Button } from "@fluentui/react-components";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import classNames from "classnames";
import { isEmpty } from "lodash-es";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ClearIcon from "../../../../assets/svg/ClearIcon";
import { useLocationSearch, useMachineCVBreadcrumb, useSearch } from "../../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { onRenderLabel } from "../../../common/CommandBar/methods";
import { TransparentDropdown } from "../../../common/Dropdown";
import NoData from "../../../common/NoData/NoData";
import { Spin } from "../../../common/Spin";
import Table from "../../../common/Table/v9";
import useSummaryStore from "../hooks/useSummaryStore";
import { useTrendSignalsList } from "../hooks/useTrendSignalsList";
import type { SectionCommandBarItem } from "../SectionContainer";
import SectionContainer from "../SectionContainer";
import { getColumns } from "./columns";
import { getStatusOptions, handleComboBoxChange } from "./methods";

type CommandBarHeaderProps = {
  signalsStatus: any;
  onSignalStatusChange: (signalsStatus: any) => void;
  onSelectionClear: () => void;
  t?: any;
};

const noDataStyles = {
  marginTop: 16,
  marginBottom: 32,
};

const getCommandBarItems = ({
  signalsStatus,
  onSignalStatusChange,
  onSelectionClear,
  t,
}: CommandBarHeaderProps): SectionCommandBarItem[] => {
  const result: SectionCommandBarItem[] = [
    {
      key: "signals-status-combobox",
      onRender: () => {
        const text = getStatusOptions(t)
          .filter(({ key }) => signalsStatus.includes(key))
          .map(({ text }) => text)
          .join(", ");

        return (
          <TransparentDropdown
            style={{ marginRight: 8 }}
            button={onRenderLabel({
              label: t("Status"),
              icon: <InfoIcon />,
              text,
              textStyle: { overflow: "hidden", whiteSpace: "nowrap", width: 100 },
            })}
            selectedOptions={signalsStatus}
            options={getStatusOptions(t)}
            multiselect={true}
            onOptionSelect={(_, data) => {
              handleComboBoxChange({
                selectedOption: data.optionValue,
                selectedOptions: data.selectedOptions,
                options: getStatusOptions(t),
                onChange: onSignalStatusChange,
              });
            }}
          />
        );
      },
    },
    {
      key: "clear-selection-button",
      onRender: () => (
        <Button
          appearance='transparent'
          style={{
            minWidth: "max-content",
            marginRight: 16,
            gap: "4px",
          }}
          onClick={onSelectionClear}
        >
          <ClearIcon />
          {t("Clear selection")}
        </Button>
      ),
    },
  ];

  return result;
};

const tableGetRowId = (item) => item?.id;

export const SignalsSection = ({ className, ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();
  const { signals, isLoading, isError } = useTrendSignalsList(id as string);
  const { data, handleSearch } = useSearch({
    data: signals.map((signal: ResponseSimplifiedSignal) => ({
      ...signal,
      key: signal?.id,
    })),
    keys: ["name", "signalDescription", "condition", "sensorNo"],
  });

  const { data: breadcrumbData } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  useEffect(() => {
    setSelectedSignals([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbData]);

  const {
    selectedSignals,
    isGroupedEverything,
    signalsStatus,
    setSignalsStatus,
    updateOperations,
    setSelectedSignals,
    removeFromSelectedSignals,
    addToSelectedSignals,
  } = useSummaryStore((state) => ({
    selectedSignals: state.selectedSignals,
    signalsStatus: state.signalsStatus,
    isGroupedEverything: state.isGroupedEverything,
    setSignalsStatus: state.setSignalsStatus,
    updateOperations: state.updateOperations,
    setSelectedSignals: state.setSelectedSignals,
    removeFromSelectedSignals: state.removeFromSelectedSignals,
    addToSelectedSignals: state.addToSelectedSignals,
  }));

  // Use useMemo to create filtered items
  const items = useMemo(() => {
    return signalsStatus.includes("selectAll")
      ? data
      : data.filter((e) => signalsStatus.includes(e.condition));
  }, [data, signalsStatus]);

  useEffect(() => {
    updateOperations({ loadingSignals: isLoading });
  }, [isLoading, updateOperations]);

  useEffect(() => {
    updateOperations({ loadingSignals: isLoading });
  }, [isLoading]);

  useEffect(() => {
    const operationSignal = signals.filter((signal: any) => {
      return signal.name === "operation_hours_OP";
    })[0];
    updateOperations({ signal: operationSignal });
  }, [signals]);

  // Handlers
  const onSelectionClear = useCallback(() => {
    setSelectedSignals([]);
  }, []);

  const handleSelectionChanged = useCallback((newSelection: any[], selectableItems: any[]) => {
    removeFromSelectedSignals(selectableItems);
    addToSelectedSignals(newSelection);
  }, []);

  const onSearchValueChanged = (_: any, newValue?: string) => {
    handleSearch(newValue ?? "");
  };

  const onSignalStatusChange = (signalsStatus: any) => {
    setSignalsStatus(signalsStatus);
  };

  const defaultSelectedIds = useMemo(() => {
    return selectedSignals.map((item) => tableGetRowId(item));
  }, [selectedSignals]);

  let content: JSX.Element | null = null;
  if (isLoading) {
    content = <Spin />;
  } else if (isError) {
    content = <NoData className='table-no-data' text={t("Data error")} style={noDataStyles} />;
  } else {
    content = (
      <>
        <Table
          persistOpts={{
            key: "table-signalSelection",
            version: 2,
          }}
          perPage={99999}
          hidePerPage={true}
          items={items}
          v8Columns={getColumns(t)}
          getRowId={tableGetRowId}
          defaultSelectedItems={defaultSelectedIds}
          selectionMaxThreshold={isGroupedEverything ? 8 : undefined}
          className='signals-section-table'
          onSelectionChanged={handleSelectionChanged}
        />
      </>
    );
  }

  return (
    <SectionContainer
      title={t("Signals")}
      commandBarItems={getCommandBarItems({
        signalsStatus,
        onSignalStatusChange,
        onSelectionClear,
        t,
      })}
      isLoading={isLoading}
      noData={isEmpty(signals)}
      searchBoxProps={{
        hidden: isEmpty(signals),
        onChange: (e, { value: newValue }) => onSearchValueChanged(e, newValue),
      }}
      className={classNames(className, "signals-section")}
      {...rest}
    >
      {content}
    </SectionContainer>
  );
};
