import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
import { BackIcon } from "@fluentui/react-icons-mdl2";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { useHaagMapping } from "../../../Hooks/useHaagMapping";
import { pageStyle } from "../../../schema/Constants";
import type { FilesExtensions, ImageUploadExtensions } from "../../../schema/status";
import { Status } from "../../../schema/status";
import Breadcrumb from "../../common/Breadcrumb";
import BaseDialog, { BaseDialogTitle } from "../../common/Dialog";
import { notification } from "../../common/Notification";
import type { TableProps } from "../../common/Table/v9";
import Table from "../../common/Table/v9";
import type { TabItemProps } from "../../common/TabMenu";
import { TabMenu } from "../../common/TabMenu";
import type { AcceptedFile } from "../../common/Uploader/Uploader";
import { ConfirmDialog } from "../../Generic/ConfirmDialog";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { list } from "../../SensorNodes/api";
import type { WirelessSensorNode } from "../../SensorNodes/models";
import { CUDDialog as CUDDialogSens } from "../../Sensors/CUDDialog";
import { CUDDialog as CUDDialogSig } from "../../Signals/CUDDialog";
import { EditCondition } from "../../Signals/EditCondition";
import type { UpdateSignalCondition } from "../../Signals/models";
import { Stack } from "../../Stack";
import { getMachineFixedCondition, machineImport } from "../api";
import type {
  MachineFixedCondition,
  ResponseSimplifiedSignal,
  Sensor,
  StandstillSignal,
} from "../models";
import {
  listAsyncMachines,
  selectMachinesError,
  selectMachineSiblings,
  selectMachinesStatus,
} from "../reducer";
import { alertLevelsImport, alertLevelsList } from "./AlertLevels/api";
import { CUDDialog as CUDDialogALev } from "./AlertLevels/CUDDialog";
import type { AlertLevelPlus } from "./columnsHelper";
import { getColumns } from "./columnsHelper";
import type { UpdateConfiguration } from "./Configs/api";
import { ConfigurationEditor } from "./Configs/ConfigurationEditor";
import AlertLevelsContent from "./Contents/AlertLevelsContent";
import ConfigsContent from "./Contents/ConfigsContent";
import EventsContent from "./Contents/EventsContent";
import GeneralConfigsContent from "./Contents/GeneralConfigsContent";
import ImagesContent from "./Contents/ImagesContent";
import SensorsContent from "./Contents/SensorsContent";
import SignalContent from "./Contents/SignalContent";
import { EditCondition as EditFixCondition } from "./EditCondition";
import { EditStandstill } from "./EditStandstill";
import {
  deleteAllFrequencyCatalogueGroups,
  frequencyCatalogueGroupDetails,
  frequencyCatalogueImport,
} from "./FrequencyCatalogue/api";
import { CUDDialog as CUDDialogFrequencyCatalogue } from "./FrequencyCatalogue/CUDDialog";
import { CUDDialogGroup as CUDDialogFrequencyCatalogueGroup } from "./FrequencyCatalogue/CUDDialogGroup";
import type {
  FrequencyCatalogueDbEntry,
  FrequencyCatalogueGroupPageDetails,
  FrequencyCatalogueGroupsAndItemsResponse,
} from "./FrequencyCatalogue/models";
import HaagMappingSection from "./HaagMapping/HaagMappingSection";
import type { InputUploadImage } from "./Images/api";
import { uploadImagesGeneral } from "./Images/api";
import { ListQueue } from "./ListQueue";
import { listAsyncMachDetails, selectMachDetails, selectMachineDetailsStatus } from "./reducer";

export type ItemsSelected = ResponseSimplifiedSignal & any;

export const MachineDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const machine = useAppSelector(selectMachDetails);
  const haagData = useHaagMapping(id);
  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const machines = useAppSelector((state) => selectMachineSiblings(state, id));

  const [isLoading, setLoading] = useState(false);

  const [selected, setSelected] = useState<{
    file: AcceptedFile;
    context: "signals" | "sensors" | "events" | "alert Levels" | "images" | "frequency catalogue";
  }>();
  const [actionAlertLevel, setActionAlertLevel] = useState<{
    data: AlertLevelPlus | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionImages, setActionImages] = useState<{
    data: undefined;
    context: "queue";
  }>();
  const [actionSignals, setActionSignals] = useState<{
    data: ResponseSimplifiedSignal | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSensors, setActionSensors] = useState<{
    data: Sensor | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSignalCondition, setActionSignalsCondition] = useState<{
    data: UpdateSignalCondition;
    context: "edit";
  }>();
  const [actionFixCondition, setActionFixCondition] = useState<{
    data: MachineFixedCondition;
    context: "edit";
  }>();
  const [actionFrequencyCatalogue, setActionFrequencyCatalogue] = useState<{
    data: undefined | string | FrequencyCatalogueDbEntry;
    context: "add" | "edit" | "delete" | "addWithGroup";
  }>();
  const [actionFrequencyCatalogueGroup, setActionFrequencyCatalogueGroup] = useState<{
    data: undefined | FrequencyCatalogueGroupPageDetails | string;
    context: "add" | "edit" | "delete";
  }>();
  const [actionFrequencyCatalogueMachine, setActionFrequencyCatalogueMachine] = useState<{
    context: "delete";
  }>();

  const [actionEditConfiguration, setActionEditConfiguration] = useState<{
    data: UpdateConfiguration;
    context: "edit";
  }>();

  const [showEditStandstill, setShowEditStandstill] = useState(false);
  const [listAlertLevels, setListAlertLevels] = useState<AlertLevelPlus[]>([]);
  const [sensorNodes, setSensorNodes] = useState<WirelessSensorNode[]>();
  const [standStillSignals, setStandStillSignals] = useState<{
    primary: { signal: ResponseSimplifiedSignal & StandstillSignal };
    secondary: { signal: ResponseSimplifiedSignal & StandstillSignal };
  }>();
  const [fixedCondition, setFixedCondition] = useState<MachineFixedCondition[]>();
  const [frequencyCatalogue, setFrequencyCatalogue] =
    useState<FrequencyCatalogueGroupsAndItemsResponse>();
  const statusListMachs = useAppSelector(selectMachinesStatus);
  const error = useAppSelector(selectMachinesError);

  useEffect(() => {
    if (statusListMachs === Status.error && machines?.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, machines?.length]);

  useEffect(() => {
    statusListMachs === Status.void && dispatch(listAsyncMachines());
  }, [dispatch, statusListMachs]);

  useEffect(() => {
    list().then((resp) =>
      !("status" in resp)
        ? setSensorNodes(resp.filter((sens) => sens.machineId === id))
        : setSensorNodes([]),
    );
  }, [id]);

  useEffect(() => {
    machine &&
      machine.signals.length > 0 &&
      setStandStillSignals({
        primary: {
          signal: {
            ...machine?.signals?.find(
              (ele) => ele.id === machine?.standstillSettings?.primary?.signalId,
            ),
            ...machine?.standstillSettings?.primary,
          },
        },
        secondary: {
          signal: {
            ...machine?.signals?.find(
              (ele) => ele.id === machine?.standstillSettings?.secondary?.signalId,
            ),
            ...machine?.standstillSettings?.secondary,
          },
        },
      });

    machine &&
      alertLevelsList(id).then((response) => {
        "status" in response
          ? notification.error(t("Alert levels fetching went wrong."))
          : setListAlertLevels(
              response.map((ele) => {
                const signal = machine?.signals?.find((sig) => sig.id === ele.signalId);
                return {
                  ...ele,
                  signalName: signal?.name,
                  sensorSerialNo: signal?.sensorSerialNo,
                  sensorNo: signal?.sensorNo,
                };
              }),
            );
      });

    machine &&
      frequencyCatalogueGroupDetails(id).then((response) => {
        "status" in response
          ? notification.error(t("Frequency catalogue fetching went wrong."))
          : setFrequencyCatalogue(response);
      });

    return () => {};
  }, [id, machine, t]);

  useEffect(() => {
    getMachineFixedCondition(id).then((resp) =>
      !("status" in resp)
        ? setFixedCondition([resp])
        : notification.warning(t("Machine fixed condition is not reachable.")),
    );

    dispatch(listAsyncMachDetails(id ? id : ""));

    return () => {};
  }, [id, dispatch, t]);

  const goBack = () => navigate(-1);
  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: [selected?.file],
      perPage: 5,
      v8Columns: getColumns(t),
      hidePerPage: true,
    }),
    [selected?.file, t],
  );

  // Handlers

  const onUploadImagesClick = () => {
    const body: InputUploadImage = {
      machineId: id,
      dalogId: machine.dalogId,
      file: selected?.file.file,
    };
    const type = selected?.file.name.split(".").slice(-1).at(0) as ImageUploadExtensions;

    setLoading(true);
    uploadImagesGeneral(body, type).then((response) => {
      setLoading(false);
      setSelected(undefined);
      if (response.status !== 202) {
        notification.error(response.text);
        return;
      }

      notification.success(t("Added data successfully."));
      notification.warning(
        t("Image processing: It may takes some minutes to display it on the list."),
      );
    });
  };

  // Contents

  const arrayPivotItems: TabItemProps[] = [
    {
      key: "generalConfigs",
      title: t("General Configs"),
      content: (
        <GeneralConfigsContent
          fixedCondition={fixedCondition}
          setActionFixCondition={setActionFixCondition}
          standStillSignals={standStillSignals}
          setShowEditStandstill={setShowEditStandstill}
          frequencyCatalogue={frequencyCatalogue}
          setActionFrequencyCatalogue={setActionFrequencyCatalogue}
          setActionFrequencyCatalogueMachine={setActionFrequencyCatalogueMachine}
          setSelected={setSelected}
          setActionFrequencyCatalogueGroup={setActionFrequencyCatalogueGroup}
        />
      ),
      show: auth.metaDataContributor,
    },
    {
      key: "signals",
      title: t("Signals [{{signalsNumber}}]", {
        signalsNumber: machine?.signals?.length?.toString(),
      }),
      content: (
        <SignalContent
          setSelected={setSelected}
          setActionSignals={setActionSignals}
          setActionSignalsCondition={setActionSignalsCondition}
        />
      ),
      show: true,
    },
    {
      key: "events",
      title: t("Events [{{eventsNumber}}]", {
        eventsNumber: machine?.events?.length.toString(),
      }),
      content: <EventsContent setSelected={setSelected} />,
      show: true,
    },
    {
      key: "sensors",
      title: t("Sensors [{{sensorsNumber}}]", {
        sensorsNumber: machine?.sensors?.length.toString(),
      }),
      content: <SensorsContent setSelected={setSelected} setActionSensors={setActionSensors} />,
      show: true,
    },
    {
      key: "alertLevels",
      title: listAlertLevels
        ? t("Alert Levels [{{alertsNumber}}]", {
            alertsNumber: listAlertLevels.length,
          })
        : t("Alert Levels [0]"),
      content: (
        <AlertLevelsContent
          listAlertLevels={listAlertLevels}
          setSelected={setSelected}
          setActionAlertLevel={setActionAlertLevel}
        />
      ),
      show: true,
    },
    {
      key: "images",
      title: t("Images [{{imagesNumber}}]", {
        imagesNumber: machine?.images?.length.toString(),
      }),
      content: <ImagesContent setSelected={setSelected} setActionImages={setActionImages} />,
      show: auth.metaDataContributor,
    },
    {
      key: "configs",
      title: t("Configs [{{configsNumber}}]", {
        configsNumber: machine?.configurations?.length.toString(),
      }),
      content: <ConfigsContent setActionEditConfiguration={setActionEditConfiguration} />,
      show: auth.metaDataContributor,
    },
    {
      key: "haag",
      title: `HAAG [${haagData.payload?.size ?? "..."}]`,
      content: <HaagMappingSection machine={machine} data={haagData} />,
      show: auth.metaDataContributor,
    },
  ];

  return (
    <>
      {machine ? (
        <>
          <Stack horizontal verticalAlign='center'>
            <Button appearance='transparent' icon={<BackIcon />} onClick={goBack} />
            <Breadcrumb
              items={[
                {
                  key: `corporation-${machine?.corporation?.number}`,
                  text: machine?.corporation?.name,
                },
                {
                  key: `company-${machine?.company?.number}`,
                  text: machine?.company?.name,
                },
                {
                  key: `project-${machine?.project?.name}`,
                  text: machine?.project?.name,
                },
                {
                  key: `machine-${machine?.dalogId}`,
                  text: machine?.dalogId,
                },
              ]}
            />
          </Stack>
          <div style={pageStyle}>
            <TabMenu tabs={arrayPivotItems} />
          </div>
          {showEditStandstill && (
            <EditStandstill
              standstillSettings={machine?.standstillSettings}
              signals={machine?.signals}
              show={showEditStandstill}
              machineId={id}
              onClose={() => {
                setShowEditStandstill(false);
              }}
              onSuccess={() => {
                notification.success(t("Successfully updated"));
                dispatch(listAsyncMachDetails(id || ""));
              }}
            />
          )}
          <BaseDialog
            open={[
              "signals",
              "sensors",
              "events",
              "alert Levels",
              "images",
              "frequency catalogue",
            ].includes(selected?.context)}
            onOpenChange={() => setSelected(undefined)}
          >
            <BaseDialogTitle>
              {t("Import {{entity}}", { entity: selected?.context })}
            </BaseDialogTitle>
            <DialogContent>
              <Table {...tableProps}></Table>
              <DialogActions>
                {["signals", "sensors", "events"].includes(selected?.context) ? (
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='tiny' /> : null}
                    onClick={() => {
                      setLoading(true);
                      machineImport(id, selected?.context, selected?.file.file).then((res) => {
                        dispatch(listAsyncMachDetails(id));

                        setSelected(undefined);
                        res.status === 200
                          ? notification.success(t("Added data successfully."))
                          : notification.error(res.text);
                      });
                      setLoading(false);
                    }}
                  >
                    {t("Save Changes")}
                  </Button>
                ) : ["alert Levels"].includes(selected?.context) ? (
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='tiny' /> : null}
                    onClick={() => {
                      setLoading(true);
                      alertLevelsImport(
                        id,
                        selected?.file.name.split(".").slice(-1).at(0) as FilesExtensions,
                        selected?.file.file,
                      ).then(async (res) => {
                        await alertLevelsList(id).then((response) => {
                          "status" in response
                            ? notification.error(t("Alert levels fetching went wrong."))
                            : setListAlertLevels(
                                response.map((ele) => {
                                  return {
                                    ...ele,
                                    signalName: machine?.signals?.find(
                                      (sig) => sig.id === ele.signalId,
                                    ).name,
                                  };
                                }),
                              );
                        });
                        setLoading(false);
                        setSelected(undefined);
                        res.status === 200
                          ? notification.success(t("Added data successfully."))
                          : notification.error(`${t("Something went wrong")}.`);
                      });
                      //setLoading(false);
                    }}
                  >
                    {t("Save Changes")}
                  </Button>
                ) : ["frequency catalogue"].includes(selected?.context) ? (
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='tiny' /> : null}
                    onClick={() => {
                      setLoading(true);
                      frequencyCatalogueImport(id, selected?.file.file).then((res) => {
                        if (res.status === 200) {
                          frequencyCatalogueGroupDetails(id).then((response) => {
                            if ("status" in response) {
                              notification.error(t("Frequency catalogue fetching went wrong."));
                            } else {
                              setFrequencyCatalogue(response);
                              dispatch(listAsyncMachDetails(id));
                            }
                          });
                          notification.success(t("Added data successfully."));
                        } else notification.error(res.text);
                        setSelected(undefined);
                        setLoading(false);
                      });
                    }}
                  >
                    {t("Save Changes")}
                  </Button>
                ) : (
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='tiny' /> : null}
                    onClick={onUploadImagesClick}
                  >
                    {t("Save Changes")}
                  </Button>
                )}

                <Button onClick={() => setSelected(undefined)}>{t("Cancel")}</Button>
              </DialogActions>
            </DialogContent>
          </BaseDialog>
          {actionAlertLevel && (
            <CUDDialogALev
              machineId={id}
              data={actionAlertLevel?.data}
              signals={
                ["add"].includes(actionAlertLevel?.context)
                  ? machine?.signals.filter(
                      (sig) => !listAlertLevels.some((alert) => alert.signalId === sig.id),
                    )
                  : machine?.signals
              }
              open={["add", "edit", "delete"].includes(actionAlertLevel?.context)}
              context={actionAlertLevel?.context}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Alert level`)
                      : t(`Failed updating Alert level`);

                  notification.error(message);
                } else {
                  alertLevelsList(id).then((response) => {
                    "status" in response
                      ? notification.error(t("Alert levels fetching went wrong."))
                      : setListAlertLevels(
                          response.map((ele) => {
                            return {
                              ...ele,
                              signalName: machine?.signals?.find((sig) => sig.id === ele.signalId)
                                .name,
                            };
                          }),
                        );
                  });
                  const message = t(`Alert level {{context}} successfully`, {
                    context,
                  });

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionAlertLevel(undefined);
              }}
            />
          )}
          {actionSignals && (
            <CUDDialogSig
              machineId={id}
              data={actionSignals?.data}
              sensors={machine?.sensors}
              sensorNodes={sensorNodes}
              show={["add", "edit", "delete"].includes(actionSignals?.context)}
              context={actionSignals?.context}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  let message = t(`Failed deleting (It may not be a custom signal.) Signal`);

                  if (context === "add") {
                    message = t("Failed creating Signal");
                  }

                  if (context === "edit") {
                    message = t("Failed updating Signal");
                  }

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Signal {{context}} successfully`, {
                    context,
                  });

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignals(undefined);
              }}
            />
          )}

          {actionImages && (
            <ListQueue
              open={["queue"].includes(actionImages?.context)}
              onClose={function (): void {
                setActionImages(undefined);
              }}
            />
          )}
          {actionSignalCondition && (
            <EditCondition
              machineId={id}
              data={actionSignalCondition?.data}
              show={["edit"].includes(actionSignalCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating Signal condition`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Signal condition updated successfully`);

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsCondition(undefined);
              }}
            />
          )}

          {actionEditConfiguration && (
            <ConfigurationEditor
              machineId={id}
              data={actionEditConfiguration?.data}
              open={["edit"].includes(actionEditConfiguration?.context)}
              show={["edit"].includes(actionEditConfiguration?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating Configuration`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Configuration updated successfully`);
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionEditConfiguration(undefined);
              }}
            />
          )}
          {actionFixCondition && (
            <EditFixCondition
              machineId={id}
              data={actionFixCondition?.data}
              open={["edit"].includes(actionFixCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating condition`);

                  notification.error(message);
                } else {
                  const message = t(`Condition updated successfully`);
                  notification.success(message);
                  getMachineFixedCondition(id).then((resp) =>
                    !("status" in resp)
                      ? setFixedCondition([resp])
                      : notification.warning(t("Machine condition is not reachable.")),
                  );
                }
              }}
              onClose={() => {
                setActionFixCondition(undefined);
              }}
            />
          )}
          {actionSensors && (
            <CUDDialogSens
              machineId={id}
              data={actionSensors?.data}
              machines={machines}
              show={["add", "edit", "delete"].includes(actionSensors?.context)}
              context={actionSensors?.context}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add" ? t(`Failed creating Sensor`) : t(`Failed updating Sensor`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Sensor {{context}} successfully`, {
                    context,
                  });
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSensors(undefined);
              }}
            />
          )}
          {actionFrequencyCatalogue && (
            <CUDDialogFrequencyCatalogue
              machineId={id}
              data={actionFrequencyCatalogue?.data}
              open={["add", "edit", "delete", "addWithGroup"].includes(
                actionFrequencyCatalogue?.context,
              )}
              context={actionFrequencyCatalogue?.context}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Frequency Catalogue item`)
                      : t(`Failed updating Frequency Catalogue item`);

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(t("Frequency Catalogue fetching went wrong."))
                      : setFrequencyCatalogue(response);
                  });
                  const message = t(`Frequency Catalogue item {{context}} successfully`, {
                    context,
                  });
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogue(undefined);
              }}
            />
          )}
          {actionFrequencyCatalogueGroup && (
            <CUDDialogFrequencyCatalogueGroup
              machineId={id}
              data={actionFrequencyCatalogueGroup?.data}
              open={["add", "edit", "delete"].includes(actionFrequencyCatalogueGroup?.context)}
              context={actionFrequencyCatalogueGroup?.context}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Frequency Catalogue Group`)
                      : t(`Failed updating Frequency Catalogue Group`);

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(t("Frequency Catalogue fetching went wrong."))
                      : setFrequencyCatalogue(response);
                  });
                  const message = t(`Frequency Catalogue item {{context}} successfully`, {
                    context,
                  });
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogueGroup(undefined);
              }}
            />
          )}
          {["delete"].includes(actionFrequencyCatalogueMachine?.context) && (
            <ConfirmDialog
              title={t("Delete all items of Machine")}
              isLoading={isLoading}
              onConfirm={() => {
                deleteAllFrequencyCatalogueGroups(id).then((response) => {
                  if (response.status === 200) {
                    notification.success(t("All items deleted successfully"));
                    setFrequencyCatalogue(undefined);
                  } else {
                    notification.error(t("Something went wrong"));
                  }
                });
                setActionFrequencyCatalogueMachine(undefined);
              }}
              onDismiss={() => {
                setActionFrequencyCatalogueMachine(undefined);
              }}
            >
              <Text as='p' size={300}>
                {t("You are about to delete all frequency catalogue items of the current machine.")}
              </Text>
            </ConfirmDialog>
          )}
          {/* {actionSignalsBulk && (
            <EditBulkDialog
              signalsNotCommon={selectedItems}
              data={selectedItems}
              sensors={items.sensors}
              show={["editB"].includes(actionSignalsBulk?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating signals`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Signals updated successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsBulk(undefined);
              }}
            />
          )} */}
        </>
      ) : status === Status.idle || status === Status.error ? (
        <NotFoundRoute />
      ) : (
        <Spinner size='large' />
      )}
    </>
  );
};
