/* eslint-disable jsx-a11y/click-events-have-key-events */

import "../styles.scss";

import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { EditIcon } from "@fluentui/react-icons-mdl2";
import { useTranslation } from "react-i18next";

import { useWebAnalysisPermissions } from "../../../../Hooks";
import type { Report } from "../../../../types";
import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/v9";
import ReportText from "../../MachineCVSummaryPage/DetailsSection/ReportsTab/ReportText";
import { REPORT_CATEGORIES } from "../config";

type GetColumnsProps = {
  t?: any;
  onEdit: (report: Report) => void;
  onDelete: (report: Report) => void;
};

type DeleteReportData = Report & {
  createdById: string;
};

type ActionsMenuProps = GetColumnsProps & {
  report: DeleteReportData;
};

const ActionsMenu = ({ report, onEdit, onDelete }: ActionsMenuProps) => {
  const { t } = useTranslation();
  const isDeleteVisible = Object.values(REPORT_CATEGORIES)
    .map((s) => s.toString())
    .includes(report.category);

  const { useReadyPermission, checkCreatorUserIDMatch } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const hasEditDeleteActions =
    hasMachineCvAdministratorPermission || checkCreatorUserIDMatch(report.createdById ?? "");

  return (
    <div style={{ display: "flex" }}>
      {hasEditDeleteActions && (
        <Tooltip withArrow relationship='label' content={t("Edit")}>
          <Button
            icon={<EditIcon />}
            aria-label={t("Edit")}
            appearance='transparent'
            onClick={(e) => {
              e.stopPropagation();
              onEdit(report);
            }}
          />
        </Tooltip>
      )}
      {hasEditDeleteActions && isDeleteVisible && (
        <Tooltip withArrow relationship='label' content={t("Delete")}>
          <Button
            icon={<DeleteRegular />}
            aria-label={t("Delete")}
            appearance='transparent'
            onClick={(e) => {
              e.stopPropagation();
              onDelete(report);
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export const getColumns = ({ t, onEdit, onDelete }: GetColumnsProps): Column[] => {
  return [
    {
      key: "text",
      fieldName: "text",
      name: t("Text"),
      flexGrow: 2,
      calculatedWidth: 0,
      minWidth: 200,
      isSortable: true,
      onRender: (report) => <ReportText report={report} />,
    },
    {
      key: "createdBy",
      fieldName: "createdBy",
      name: t("Created By"),
      minWidth: 150,
      isSortable: true,
      isExportable: false,
    },
    {
      key: "createdAt",
      fieldName: "createdAt",
      name: t("Created At"),
      minWidth: 150,
      isSortable: true,
      onRender: (report) => format(new Date(report?.createdAt || 0)),
    },
    {
      key: "actions",
      fieldName: "actions",
      name: t("Actions"),
      minWidth: 100,
      onRender: (report) => <ActionsMenu report={report} onEdit={onEdit} onDelete={onDelete} />,
    },
  ];
};
