import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { axiosInstance } from "../..";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Analysis } from "../Analysis/Analysis";
import { RawViewStyled } from "../Analysis/RawViewStyled";
import { TrendViewStyled } from "../Analysis/TrendViewStyled";
import useTableStore from "../common/Table/v9/useTableStore";
import { CompanyDetails } from "../Companies/CompaniesDetails/CompanyDetails";
import { CompaniesList } from "../Companies/CompaniesList";
import { CorporationDetails } from "../Corporations/CorporationDetails/CorporationsDetails";
import { CorporationsList } from "../Corporations/CorporationsList";
import { DashboardIFrame } from "../Dashboards/Dashboard";
import { DashboardsList } from "../DashboardsMetadata/DashboardsList";
import { DataloggersList } from "../Dataloggers/DataloggersList";
import { WirelessGatewaysList } from "../Gateways/GatewaysList";
import { WirelessGatewayDetails } from "../Gateways/WirelessGatewaysDetails/WirelessGatewaysDetails";
import { NotFoundRoute } from "../Generic/NotFoundRoute";
import { Home } from "../Home/Home";
import ImagesSection from "../Images/ImagesSection";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import {
  Alerts,
  Comments,
  Documents,
  DriveLoad,
  Home as MachineCVHome,
  Info,
  Performance,
  Reports,
  Sensors,
  Summary,
  Tasks,
  Threads,
} from "../MachineCV";
import { MachineDetails } from "../Machines/MachineDetails/MachineDetails";
import MachinesList from "../Machines/MachinesList";
import OneShot from "../OpenAI/OneShot/OneShot";
import { ResponseRolesList } from "../Permissions/Permissions";
import { ProjectDetails } from "../Projects/ProjectsDetails/ProjectDetails";
import { ProjectsList } from "../Projects/ProjectsList";
import { WirelessSensorNodesList } from "../SensorNodes/SensorNodesList";
import { SensorNodeDetails } from "../SensorNodes/WirelessSensorNodeDetails/SensorNodeDetails";
import { SensorDetails } from "../Sensors/SensorDetails/SensorDetails";
import { ServiceAccounts } from "../ServiceAccount/ServiceAccounts";
import { listAsyncSettingsUsersRBAC } from "../Settings/reducer";
import { selectUserSettings } from "../Settings/selectors";
import Settings from "../Settings/Settings";
import { SignalDetails } from "../Signals/SignalDetails/SignalDetails";
import { SubmissionsDetails } from "../Submissions/SubmissionDetails/SubmissionsDetails";
import { SubmissionsList } from "../Submissions/SubmissionsList";
import { getAsyncUserAccountDetails } from "../UserAccount/reducer";
import { UsersRBCA } from "../UsersRBCA/UsersRBCA";
import VpnConnectionsManager from "../Vpn/VpnConnectionsManager/VpnConnectionsManager";
import {
  ErrorsAndIncidentsPage,
  SensorConfiguratorPage,
  SensorGatewayPage,
  SensorPage,
  SensorTriggeringPage,
  SensorTriggersPage,
  WirelessSensorsLayoutPage,
  WirelessSensorsPlaceholderComp,
} from "../WirelessSensors";
import Page from "./Page";

const wrapperStyle: React.CSSProperties = {
  width: "100%",
  height: "calc(100vh - 50px)",
  overflowY: "auto",
};

const viewportContext = React.createContext({ width: 0, height: 0 });

interface IViewportProviderProps {
  children?: JSX.Element;
}

const ViewportProvider = (props: IViewportProviderProps) => {
  const [width, setWidth] = React.useState(
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  );
  const [height, setHeight] = React.useState(
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  );

  const handleWindowResize = () => {
    setWidth(
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    );
    setHeight(
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    );
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>{props.children}</viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  const landscape = height < width;
  const smallSize = width < 620 || height < 620;
  const bigScreen = height > 800;
  return { landscape, smallSize, bigScreen, width };
};

export const Content: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectUserSettings);
  const { tablePerPage, setTablePerPage } = useTableStore();

  // Get the logged in event once the user is logged in to setup notifications through SignalR

  const acc = sessionStorage.getItem("accountInfoMin");
  const accObj = JSON.parse(acc || "{}");
  const accountId = accObj.id;

  React.useEffect(() => {
    dispatch(listAsyncSettingsUsersRBAC(accountId));
    dispatch(getAsyncUserAccountDetails(accountId));
  }, [accountId, dispatch]);

  //Update web-analysis store when localStorage is not synced with it
  React.useEffect(() => {
    if (settings?.list?.rowsPerTable !== tablePerPage) {
      setTablePerPage(settings?.list?.rowsPerTable);
    }
  }, [settings?.list?.rowsPerTable, tablePerPage, setTablePerPage]);

  // Configures the Hub connection via SignalR for React-Toastify notifications.
  // React.useEffect(() => {
  //   const callbackId = instance.addEventCallback((message) => {
  //     if (
  //       message.eventType === EventType.HANDLE_REDIRECT_END &&
  //       message.error === null
  //     ) {
  //       const connection = new HubConnectionBuilder()
  //         .withUrl(process.env.REACT_APP_SIGNALRHUB!, {
  //           accessTokenFactory: acquireTokenSilent,
  //         })
  //         .configureLogging(LogLevel.Warning)
  //         .withAutomaticReconnect()
  //         .build();

  //       connection.on(
  //         "notifications",
  //         (_type: NotificationType, jsonData: string) => {
  //           let notification: SignalRNotificationMessage = JSON.parse(jsonData);
  //           if (!notification?.model) {
  //             return;
  //           }

  //           toast(ToastifyNotificationDialog(notification.model));
  //         }
  //       );
  //       connection.start();
  //     }
  //   });

  //   return () => {
  //     // This will be run on component unmount
  //     if (callbackId) {
  //       instance.removeEventCallback(callbackId);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div style={wrapperStyle}>
      <ViewportProvider>
        <Routes>
          {true ? (
            <>
              <Route path='/' element={<Home />} />

              <Route path='/settings' element={<Settings />} />

              {auth.powerBiReader ? (
                <Route path='dashboard'>
                  <Route path=':type/:id' element={<DashboardIFrame />} />
                </Route>
              ) : null}

              {auth.measuredDataReader ? (
                <>
                  <Route
                    path='analysis'
                    element={
                      <Page title={t("Analysis")}>
                        <Analysis />
                      </Page>
                    }
                  />
                  <Route path='trend-view' element={<TrendViewStyled />} />
                  <Route path='raw-data' element={<RawViewStyled />} />
                </>
              ) : null}

              {auth.wirelessSensorReader && (
                <>
                  {/* Start: Wireless Sensors Dev */}
                  <Route element={<WirelessSensorsLayoutPage />}>
                    <Route path='sensor-configurator'>
                      <Route index element={<WirelessSensorsPlaceholderComp />} />
                      <Route
                        path='project/:projectId/gateway/:serialNumber'
                        element={<SensorGatewayPage />}
                      />
                      <Route path='machine/:machineId' element={<SensorTriggersPage />} />
                      <Route
                        path='machine/:machineId/sensor/:sensorNodeId'
                        element={<SensorPage />}
                      />
                      <Route path='project/:projectId'>
                        <Route index element={<SensorConfiguratorPage />} />
                        <Route path='sensor-triggering' element={<SensorTriggeringPage />} />
                      </Route>
                      <Route path='errors-incidents' element={<ErrorsAndIncidentsPage />} />
                    </Route>
                  </Route>
                  {/* End: Wireless Sensors Dev */}
                  <Route path='sensor-configurator' element={<SensorConfiguratorPage />} />
                  <Route
                    path='sensor-configurator/sensor-triggering'
                    element={<SensorTriggeringPage />}
                  />
                  <Route path='sensor-configurator/gateway' element={<SensorGatewayPage />} />
                  <Route path='sensor-configurator/sensor' element={<SensorPage />} />
                  <Route path='sensor-configurator/triggers' element={<SensorTriggersPage />} />
                  <Route
                    path='wirelessSensors'
                    element={
                      <Page title={t("Wireless Sensors")}>
                        <h2>{t("Wireless sensors")}</h2>
                      </Page>
                    }
                  />
                  <Route path='gateways' element={<WirelessGatewaysList />} />
                  <Route path='gateways/:id' element={<WirelessGatewayDetails />} />
                  <Route path='sensorNode' element={<WirelessSensorNodesList />} />
                  <Route path='sensorNode/:id' element={<SensorNodeDetails />} />
                </>
              )}
              {auth.metaDataContributor ? (
                <>
                  <Route path='corporations' element={<CorporationsList />} />
                  <Route path='corporations/:id' element={<CorporationDetails />} />
                  <Route path='companies' element={<CompaniesList />} />
                  <Route path='companies/:id' element={<CompanyDetails />} />
                  <Route path='projects' element={<ProjectsList />} />
                  <Route path='projects/:id' element={<ProjectDetails />} />
                  <Route path='dataloggers' element={<DataloggersList />} />
                  <Route path='machines' element={<MachinesList />} />
                  <Route path='machines/:id' element={<MachineDetails />} />
                  <Route path='machines/:machineId/signal/:id' element={<SignalDetails />} />
                  <Route path='machines/:machineId/sensor/:id' element={<SensorDetails />} />
                </>
              ) : null}
              {auth.imageAdministrator && (
                <Route path='images' element={<ImagesSection hasWritePermissions={true} />} />
              )}
              {auth.vpnReader && (
                <Route
                  path='vpnConnections'
                  element={
                    <VpnConnectionsManager
                      hasAdminPermissions={auth.vpnContributor}
                      axiosInstance={axiosInstance}
                    />
                  }
                />
              )}

              {auth.submissionAdministrator && (
                <>
                  <Route path='submissions' element={<SubmissionsList />} />
                  <Route path='submissions/:id' element={<SubmissionsDetails isNotLog={true} />} />
                  <Route
                    path='submissions/:id/log'
                    element={<SubmissionsDetails isNotLog={false} />}
                  />
                </>
              )}

              {auth.machineCvReader ? (
                <Route path='machine-cv'>
                  <Route index element={<MachineCVHome />} />
                  <Route path='summary' element={<Summary />} />
                  <Route path='drive-load' element={<DriveLoad />} />
                  <Route path='performance' element={<Performance />} />
                  <Route path='sensors' element={<Sensors />} />
                  <Route path='tasks' element={<Tasks />} />
                  <Route path='comments' element={<Comments />} />
                  <Route path='info' element={<Info />} />
                  <Route path='documents' element={<Documents />} />
                  <Route path='threads' element={<Threads />} />
                  <Route path='reports' element={<Reports />} />
                  <Route path='alerts' element={<Alerts />} />
                </Route>
              ) : null}

              {auth.powerBiContributor ? (
                <Route path='dashboardsMetaData' element={<DashboardsList />} />
              ) : null}

              {auth.userAdministrator ? (
                <>
                  <Route path='permissions' element={<ResponseRolesList />} />
                  <Route path='roles' element={<ResponseRolesList />} />
                  <Route path='services' element={<ServiceAccounts />} />
                  <Route path='services/:id' element={<ServiceAccounts />} />
                  <Route path='users' element={<UsersRBCA />} />
                  <Route path='users/:id' element={<UsersRBCA />} />
                </>
              ) : null}

              {auth.userAdministrator ? <Route path='assistant' element={<OneShot />} /> : null}

              <Route path='*' element={<NotFoundRoute />} />
            </>
          ) : (
            <Route path='*' element={<NotFoundRoute />} />
          )}
        </Routes>
      </ViewportProvider>
    </div>
  );
};
