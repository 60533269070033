/* eslint-disable react-hooks/exhaustive-deps */
import type { DialogProps } from "@fluentui/react-components";
import { DialogContent, makeStyles } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkA } from "react-router-dom";

import { linkStyle } from "../../schema/Constants";
import { format } from "../../schema/Utils";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import type { Column } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import { submissionsListLog } from "./api";
import type { ListLogWithSubType } from "./models";

const getColumns = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "sID",
      name: t("SID"),
      fieldName: "sID",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sID }: ListLogWithSubType) => (
        <LinkA to={id + "/log"} style={linkStyle}>
          {sID}
        </LinkA>
      ),
    },
    {
      key: "approvedAt",
      name: t("Approved At"),
      fieldName: "approvedAt",
      minWidth: 200,
      isSortable: true,
      onRender: (item: ListLogWithSubType) =>
        item?.approvedAt ? format(new Date(item.approvedAt)) : "",
    },
    {
      key: "approvedBy",
      name: t("Approved By"),
      fieldName: "approvedBy",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "status",
      name: t("Status"),
      fieldName: "status",
      minWidth: 200,
      isSortable: true,
    },
  ];

  return columns;
};

type LogSubmissionsListProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  onClose: () => void;
};

const useStyles = makeStyles({
  table: {
    "& .fui-DataGrid": {
      height: "50vh",
    },
  },
});

export const LogSubmissionsList = ({ show, onClose, ...rest }: LogSubmissionsListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<ListLogWithSubType[]>();
  const { filters, handleSearch } = useTableFilters<ListLogWithSubType>({
    keys: ["sID", "submissionType", "approvedBy", "status", "submissionType"],
  });

  useEffect(() => {
    setLoading(true);
    submissionsListLog()
      .then((list) => {
        setItems(list);
        setLoading(false);
      })
      .catch(() => setItems([]));

    return;
  }, []);

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{
        height: "78vh",
        width: DialogSize.L,
      }}
      onOpenChange={onClose}
    >
      <BaseDialogTitle>{t("Submission Logs")}</BaseDialogTitle>
      <DialogContent>
        <div
          style={{
            overflowY: "auto",
            height: "60vh",
            position: "relative",
            overscrollBehavior: "contain",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Table
            persistOpts={{
              key: "table-submissions-logs",
              version: 2,
            }}
            header={{
              title: t("Logs"),
            }}
            items={items ? items : []}
            v8Columns={getColumns(t)}
            filters={filters}
            isLoading={loading}
            isError={false}
          />
        </div>
      </DialogContent>
    </BaseDialog>
  );
};
