/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DialogActions, DialogContent, Tooltip } from "@fluentui/react-components";
import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import { EditIcon } from "@fluentui/react-icons-mdl2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import type { Column } from "../../../common/Table/v9";
import Table from "../../../common/Table/v9";
import { Stack } from "../../../Stack";
import { VpnPCsAPI } from "../../Schema/api";
import type { MetadataVpnPC } from "../../Schema/models";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { withLoadingPanelHOC } from "../generic/HOCs";
import { AddVpnPcDialog, EditVpnPcDialog } from "./AddEditVpnPcDialog";
import DeleteVpnPcDialog from "./DeleteVpnPcDialog";

enum VpnPCsActionList {
  None,
  Add,
  Edit,
  Delete,
}

type VpnPCsActionDialogProps = {
  item: MetadataVpnPC | null;
  action: VpnPCsActionList;
  onClose: (listChanged?: boolean) => void;
};

type VpnPCsListDialogProps = {
  onClose: () => void;
};

const getCommandBarItems = (t, onAdd: () => void): any => {
  const result: any = [
    {
      key: "addVpnPCItem",
      text: t("Add"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAdd,
    },
  ];

  return result;
};

/**
 * Gets the VPN PCs List columns
 * @param onEdit Method called when the Edit action button is clicked.
 * @param onDelete Method called when the Delete action button is clicked.
 * @returns The IColumns list.
 */
const getColumns = (
  t,
  onEdit: (item: MetadataVpnPC) => void,
  onDelete: (item: MetadataVpnPC) => void,
): Column[] => {
  const actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 150,
    onRender: (item: MetadataVpnPC) => (
      <Stack horizontal>
        <Tooltip relationship='label' content={t("Edit VPN PC")}>
          <Button
            key={item.id + "edit"}
            appearance='transparent'
            className='table-icon-button'
            aria-label={t("Edit VPN PC")}
            icon={<EditIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(item);
            }}
          />
        </Tooltip>
        <Tooltip relationship='label' content={t("Delete VPN PC")}>
          <Button
            key={item.id + "delete"}
            appearance='transparent'
            className='table-icon-button'
            aria-label={t("Delete VPN PC")}
            icon={<DeleteRegular />}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(item);
            }}
          />
        </Tooltip>
      </Stack>
    ),
  };
  return [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 100,
      data: "string",
    },
    actionsColumn,
  ];
};

/**
 * Gets the VPN PCs action dialog component.
 * @param item The VPN PC item.
 * @param action The VPN PC action.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const ActionDialog = ({ item, action, onClose }: VpnPCsActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (action) {
    case VpnPCsActionList.Add:
      result = <AddVpnPcDialog open={true} onClose={onClose} />;
      break;

    case VpnPCsActionList.Edit:
      result = item && <EditVpnPcDialog open={true} item={item} onClose={onClose} />;
      break;

    case VpnPCsActionList.Delete:
      result = item && <DeleteVpnPcDialog open={true} item={item} onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the VPN PCs list dialog component
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The VPN PCs details dialog component.
 */
const VpnPCsListDialog = ({ onClose }: VpnPCsListDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [vpnPCs, setVpnPCs] = useState<MetadataVpnPC[]>([]);
  const [selectedItem, setSelectedItem] = useState<MetadataVpnPC | null>(null);
  const [actionDialog, setActionDialog] = useState<VpnPCsActionList>(VpnPCsActionList.None);
  const [listChanged, setListChanged] = useState<boolean>(true);

  // Gets the VPN PCs list.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    if (!listChanged) {
      return;
    }

    VpnPCsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the VPN PCs list: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        onClose?.();
        return;
      }

      setVpnPCs(response.data);
      setListChanged(false);
    });
  }, [listChanged]);

  // Handlers.
  const onAddHandler = () => {
    setActionDialog(VpnPCsActionList.Add);
  };

  const onEditHandler = (item: MetadataVpnPC) => {
    setSelectedItem(item);
    setActionDialog(VpnPCsActionList.Edit);
  };

  const onDeleteHandler = (item: MetadataVpnPC) => {
    setSelectedItem(item);
    setActionDialog(VpnPCsActionList.Delete);
  };

  const onCloseActionDialog = (listChanged?: boolean) => {
    listChanged && setListChanged(true);
    setSelectedItem(null);
    setActionDialog(VpnPCsActionList.None);
  };

  // Sets the current dialog to show.
  let dialog: JSX.Element;
  if (actionDialog === VpnPCsActionList.None) {
    dialog = (
      <BaseDialog open={true} surfaceStyle={{ width: DialogSize.S }} onOpenChange={onClose}>
        <BaseDialogTitle>{t("VPN PCs Details")}</BaseDialogTitle>

        <DialogContent>
          <BaseCommandBar items={computeCommandBarItems(getCommandBarItems(t, onAddHandler))} />
          {withLoadingPanelHOC(
            t,
            vpnPCs,
            <Table
              className='box-shadow-panel'
              persistOpts={{
                key: "table-vpn-pcs",
                version: 2,
              }}
              header={{ title: "VPN PCs" }}
              items={vpnPCs}
              v8Columns={getColumns(t, onEditHandler, onDeleteHandler)}
              // perPage={25}
              hidePerPage={true}
            />,
          )}
          <DialogActions>
            <Button appearance='primary' onClick={onClose}>
              {t("Done")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    );
  } else {
    dialog = (
      <ActionDialog item={selectedItem} action={actionDialog} onClose={onCloseActionDialog} />
    );
  }
  return dialog;
};

export default VpnPCsListDialog;
