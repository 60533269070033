import type { TextareaProps } from "@fluentui/react-components";
import { Field, Label, makeStyles, Textarea as FluentUiTextArea } from "@fluentui/react-components";
import type { CSSProperties } from "react";

type CustomSpinButtonProps = Omit<TextareaProps, "onChange"> & {
  label?: string;
  onChange?: any;
  labelStyle?: CSSProperties;
  fieldStyle?: CSSProperties;
};

export const useCommentTextAreaStyles = makeStyles({
  textarea: {
    "& .fui-Textarea__textarea": {
      minHeight: "130px",
      maxHeight: "unset",
    },
  },
});
export const useLargeCommentField = makeStyles({
  largeArea: {
    width: "100%",

    "& .fui-Textarea__textarea": {
      minHeight: "300px",
      maxHeight: "unset",
    },
  },
});

export default function TextArea({
  onChange,
  fieldStyle,
  labelStyle,
  label,
  ...rest
}: CustomSpinButtonProps) {
  function onTextAreaChange(_: any, data) {
    onChange(data.value);
  }
  if (label) {
    return (
      <Field style={{ gap: "4px", marginTop: "8px", ...fieldStyle }}>
        <Label style={{ fontWeight: 600, ...labelStyle }}>{label}</Label>
        <FluentUiTextArea onChange={onTextAreaChange} {...rest} />
      </Field>
    );
  }

  return <FluentUiTextArea onChange={onTextAreaChange} {...rest} />;
}
