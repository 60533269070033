import { Text, Tooltip } from "@fluentui/react-components";
import {
  CloudFlow20Regular,
  Delete20Regular,
  DocumentSettings20Filled,
  Edit20Regular,
  Link20Regular,
} from "@fluentui/react-icons";
import React from "react";

import type { Column } from "../common/Table/v9";
import { Stack } from "../Stack";
import { D850OS, DModels } from "./models";
import type {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "./tableItemModels";

export type DataloggerColumnsProps = {
  t: any;
  model: DModels;
  isMetaDataContributor?: boolean;
  onEdit: (entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem) => void;
  onDelete: (entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem) => void;
  onSelectMachines: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => void;
  onD850LinuxConfig: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => void;

  onGenerateConfig: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
  ) => void;
};

/**
 * Gets the base columns.
 * @param macAddressHeader The MAC address header name.
 * @returns The base columns array.
 */
const getBaseColumns = (macAddressHeader: string, t): Column[] => {
  const result: Column[] = [
    {
      key: "macAddress",
      name: macAddressHeader,
      fieldName: "macAddress",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "projectName",
      name: t("Project name"),
      fieldName: "projectName",
      minWidth: 200,
      isSortable: true,
      onRender: (
        datalogger: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
      ) => {
        return (
          <Tooltip withArrow relationship='label' content={datalogger.projectName}>
            <Text size={300}>{datalogger.projectName}</Text>
          </Tooltip>
        );
      },
    },
    {
      key: "machines",
      name: t("Machines"),
      fieldName: "machines",
      minWidth: 150,
      isSortable: false,
      onRender: (
        datalogger: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
      ) => {
        return (
          datalogger.machines?.length > 0 && (
            <Stack verticalAlign='center'>
              {React.Children.map(datalogger.machines, (machine) => {
                return <Text size={300}>{machine}</Text>;
              })}
            </Stack>
          )
        );
      },
    },
  ];

  return result;
};

/**
 * * COLUMNS
 */

const getD325Columns: (t) => Column[] = (t) => [
  ...getBaseColumns(t("MAC Address"), t),
  {
    key: "firmwareVersion",
    name: t("Firmware Version"),
    fieldName: "firmwareVersion",
    minWidth: 150,
    isSortable: true,
    onRender: ({ firmwareVersion }: D325TableItem) => (
      <Stack horizontalAlign='center'>
        <Text size={300}>{firmwareVersion}</Text>
      </Stack>
    ),
  },
];

const getDBasicColumns: (t) => Column[] = (t) => [
  ...getD325Columns(t),
  {
    key: "ipAddress",
    name: t("IP Address"),
    fieldName: "ipAddress",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "subnetMask",
    name: t("Subnet Mask"),
    fieldName: "subnetMask",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "gateway",
    name: t("Gateway"),
    fieldName: "gateway",
    minWidth: 150,
    isSortable: true,
  },
];

const getD850EcoColumns: (t) => Column[] = (t) => [
  ...getBaseColumns(t("Basis Card MAC Address"), t),
  {
    key: "adjustmentCardType",
    name: t("Adjustment Card Type"),
    fieldName: "adjustmentCardType",
    minWidth: 150,
    isSortable: true,
    onRender: ({ adjustmentCardType }: D850EcoTableItem) => (
      <Stack horizontalAlign='center'>
        <Text size={300}>{adjustmentCardType}</Text>
      </Stack>
    ),
  },
];

const getD850Columns: (t) => Column[] = (t) => [
  ...getD850EcoColumns(t),
  {
    key: "operativeSystem",
    name: t("Operative System"),
    fieldName: "operativeSystem",
    minWidth: 150,
    isSortable: true,
    onRender: ({ operativeSystem }: D850TableItem) => (
      <Stack horizontalAlign='center'>
        <Text size={300}>{operativeSystem}</Text>
      </Stack>
    ),
  },
  {
    key: "ipAddress",
    name: t("IP Address"),
    fieldName: "ipAddress",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "subnetMask",
    name: t("Subnet Mask"),
    fieldName: "subnetMask",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "gateway",
    name: t("Gateway"),
    fieldName: "gateway",
    minWidth: 150,
    isSortable: true,
  },
];

/**
 * Gets the basic datalogger table basic actions.
 * @param model The datalogger model.
 * @param onEdit The onEdit click event handler.
 * @param onDelete The onDelete click event handler
 * @param onSelectMachines The onSelectMachines click event handler
 * @param onD850LinuxConfig The on D-850 CFG Config click event handler
 * @param onGenerateDdpConfig The DDP configuration generation click event handler
 * @returns The basic item actions table columns array.
 */
const getColumnItemActions = ({
  t,
  model,
  onEdit,
  onDelete,
  onSelectMachines,
  onD850LinuxConfig,
  onGenerateConfig,
}: DataloggerColumnsProps): Column[] => {
  const result: Column[] = [
    {
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 200,
      isSortable: false,
      isExportable: false,
      onRender: (
        datalogger: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem,
      ) => {
        let activateLinuxConfigAction = false;
        let activateGenerateConfig = false;

        // Filtrates the actions by items properties
        switch (model) {
          case DModels.D650:
            activateGenerateConfig = true;
            break;

          case DModels.D850:
            if (datalogger.machineIds?.length === 1) {
              const d850tableItem = datalogger as D850TableItem;
              activateLinuxConfigAction = d850tableItem.operativeSystem === D850OS.Linux;
              activateGenerateConfig = !activateLinuxConfigAction;
            }

            break;
        }

        return (
          <div key={datalogger.id + "actions"} style={{ display: "flex" }}>
            <Tooltip withArrow relationship='label' content={t("Edit")}>
              <Edit20Regular
                style={{ color: "#2c529f", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(datalogger);
                }}
              />
            </Tooltip>
            <Tooltip withArrow relationship='label' content={t("Delete")}>
              <Delete20Regular
                style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(datalogger);
                }}
              />
            </Tooltip>
            <Tooltip withArrow relationship='label' content={t("Select machines")}>
              <Link20Regular
                style={{
                  color: "#2c529f",
                  cursor: "pointer",
                  marginLeft: "5px",
                  transform: "rotate(-45deg)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectMachines(datalogger);
                }}
              />
            </Tooltip>
            {activateLinuxConfigAction && (
              <Tooltip withArrow relationship='label' content={t("D-850 CFG Convert")}>
                <DocumentSettings20Filled
                  style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onD850LinuxConfig(datalogger);
                  }}
                />
              </Tooltip>
            )}
            {activateGenerateConfig && (
              <Tooltip withArrow relationship='label' content={t("Generate configuration")}>
                <CloudFlow20Regular
                  style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onGenerateConfig(datalogger);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return result;
};

/**
 * Gets the datalogger table columns.
 * @param model The datalogger model
 * @param onEdit The onEdit click event handler.
 * @param onDelete The onDelete click event handler
 * @param onSelectMachines The onSelectMachines click event handler
 * @returns The table columns array.
 */
export const getDataloggerColumns = ({
  t,
  model,
  isMetaDataContributor,
  onEdit,
  onDelete,
  onSelectMachines,
  onD850LinuxConfig,
  onGenerateConfig,
}: DataloggerColumnsProps): Column[] => {
  const result: Column[] = [];
  switch (model) {
    case DModels.D325:
      result.push(...getD325Columns(t));
      break;

    case DModels.D555:
    case DModels.D650:
      result.push(...getDBasicColumns(t));
      break;

    case DModels.D850Eco:
      result.push(...getD850EcoColumns(t));
      break;

    case DModels.D850:
      result.push(...getD850Columns(t));
      break;
  }

  if (!isMetaDataContributor) {
    return result;
  }

  // Adds the actions.
  const actions = getColumnItemActions({
    t,
    model,
    onEdit,
    onDelete,
    onSelectMachines,
    onD850LinuxConfig,
    onGenerateConfig,
  });
  result.push(...actions);

  return result;
};
