import type {
  DialogProps,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Spinner,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import { SkypeCheckIcon } from "@fluentui/react-icons-mdl2";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getUserFieldsReq, maxLengthType2, maxLengthType3 } from "../../schema/Constants";
import type { RoleType, ScopeLevelType } from "../../schema/status";
import { Status } from "../../schema/status";
import ChoiceGroup from "../common/ChoiceGroup";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import { RolesComponentToAdd } from "../Roles/RolesComponentToAdd";
import { Stack } from "../Stack";
import { addServiceAccount } from "./api";
import type { ServiceAccountAdd, ServiceAccountAddRole } from "./models";
import { selectServiceAccountsError, selectServiceAccountsStatus } from "./reducer";
import { listAsyncServiceAccountProducts, selectServiceAccountsProducts } from "./reducerProducts";

export interface ServiceAccountAddRoleToShow extends ServiceAccountAddRole {
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  resource?: string;
}

export type personaProps = {
  persona: RoleType[];
  root: boolean;
};

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: t("Invalid email address") })
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    lastName: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    firstName: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    purpose: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType3, {
        message: t(`Name must contain at most ${maxLengthType3} character(s)`, {
          maxLength: maxLengthType3,
        }),
      }),
    jobTitle: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    companyName: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    department: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    streetAddress: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    city: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    stateOrProvince: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    zipOrPostalCode: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    countryOrRegion: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: t("Invalid number") }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
  });

type AddDialogProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const AddDialog = ({ show, onSuccess, onClose, ...rest }: AddDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectServiceAccountsStatus);
  const error = useAppSelector(selectServiceAccountsError);
  const items = useAppSelector(selectServiceAccountsProducts);
  const [isLoading, setLoading] = useState(false);
  const [productIdToAdd, setProductIdToAdd] = useState<string>();
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>([]);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
  }, [error, status]);

  useEffect(() => {
    dispatch(listAsyncServiceAccountProducts());
  }, [dispatch]);

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId,
      ),
    );
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...{
      defaultValues: {
        email: "",
        lastName: "",
        firstName: "",
        purpose: "",
        jobTitle: "",
        companyName: "",
        department: "",
        streetAddress: "",
        city: "",
        stateOrProvince: "",
        zipOrPostalCode: "",
        countryOrRegion: "",
        mobilePhone: "",
        memberId: "",
      },
    },
  });

  useEffect(() => {
    reset({
      email: "",
      lastName: "",
      firstName: "",
      purpose: "",
      jobTitle: "",
      companyName: "",
      department: "",
      streetAddress: "",
      city: "",
      stateOrProvince: "",
      zipOrPostalCode: "",
      countryOrRegion: "",
      mobilePhone: "",
      memberId: "",
    });
  }, [reset]);

  const handleClose = () => {
    setLoading(false);
    setProductIdToAdd(undefined);
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    const toSubmit: ServiceAccountAdd = {
      ...(formData as ServiceAccountAdd),
      productId: productIdToAdd,
      roles: rolesToAdd as ServiceAccountAddRole[],
    };
    await addServiceAccount(toSubmit).then((res) => {
      onSuccess(!("apimProperties" in res), toSubmit.firstName);
    });
    setLoading(true);
    handleClose();
  });

  function onChange(ev, option): void {
    const optionValue = option?.value || "";

    if (
      optionValue.toLowerCase().includes("webapp") === true ||
      optionValue.toLowerCase().includes("wireless") === true
    )
      notification.warning(t("Remind: The selected product is for internal users."));

    setProductIdToAdd(optionValue);
  }

  const [selectedValue, setSelectedValue] = useState<TabValue>("properties");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{ width: DialogSize.L, maxWidth: "100vw" }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Add Service Account")}</BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("Pivots to edit service account")}
          onTabSelect={onTabSelect}
        >
          <Tab value='properties'>
            {isValid && productIdToAdd && <SkypeCheckIcon />}
            <Text style={{ fontSize: "18px", fontWeight: 600 }}>{t("Properties")}</Text>
          </Tab>
          <Tab value='roles'>
            {rolesToAdd.length > 0 && <SkypeCheckIcon />}
            <Text style={{ fontSize: "18px", fontWeight: 600 }}>{t("Roles")}</Text>
          </Tab>
        </TabList>
        {selectedValue === "properties" && (
          <Stack horizontalAlign='space-evenly' verticalAlign='center'>
            <Text style={{ padding: "16px", fontSize: "18px", fontWeight: 600 }}>General</Text>
            <Stack
              wrap
              horizontal
              style={{ padding: "16px", gap: 8 }}
              horizontalAlign='stretch'
              verticalAlign='center'
            >
              {renderFormItems(
                getUserFieldsReq({
                  t,
                  withServiceAccountFieldsReqExtra: true,
                  withUserFieldsNonReq: true,
                }) as FormItemProps[],

                {
                  control,
                  errors: errors as { [schemaProp: string]: FieldError },
                },
              ).map((ele) => (
                <Stack.Item key={ele.key}> {ele}</Stack.Item>
              ))}
            </Stack>
            <Text style={{ padding: "16px", fontSize: "18px", fontWeight: 600 }}>
              {t("Product")}
            </Text>
            <Stack
              horizontal
              style={{ gap: 8 }}
              horizontalAlign='space-evenly'
              verticalAlign='center'
            >
              <ChoiceGroup
                options={items?.map((ele) => ({
                  key: ele.id,
                  text:
                    ele.displayName.includes("WebApp") || ele.displayName.includes("Wireless")
                      ? t("{{name}} (for internal users)", {
                          name: ele.displayName,
                        })
                      : ele.displayName,
                }))}
                defaultValue={productIdToAdd}
                onChange={onChange}
              />
            </Stack>
          </Stack>
        )}
        {selectedValue === "roles" && (
          <RolesComponentToAdd
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
            onDelete={onDelete}
          />
        )}
        <DialogActions>
          <Button
            disabled={isLoading || !isValid || !productIdToAdd}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onSubmit}
          >
            {t("Save Changes")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
