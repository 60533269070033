import { Label } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import SpinButton, { sideBySide } from "../../../../Components/common/SpinButton";
import { Stack } from "../../../../Components/Stack";

const schemaNumber = z.number().int().nonnegative();

const validateNumber = (value: string, type: "float" | "int"): number | undefined => {
  if (type === "float") {
    // if (value === "" || value === "." || value.endsWith(".")) return undefined;

    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value)) return undefined;

    const numValue = parseFloat(value);
    return isNaN(numValue) ? undefined : numValue;
  }

  const result = schemaNumber.safeParse(parseInt(value, 10));
  return result.success ? result.data : undefined;
};

export default function CustomFrequencies({ inputs }: any) {
  const { t } = useTranslation();

  return (
    <Stack style={{ rowGap: 16 }}>
      <Stack style={{ rowGap: 8 }}>
        <Label style={{ padding: 0, fontWeight: 600 }}>{t("Set 1st Harmonics")}</Label>

        {inputs.harmonicsOne.map(({ label, value, type, action }: any) => {
          return (
            <Stack key={`harmonics1-${label}`} horizontal style={{ rowGap: 8, width: "100%" }}>
              <SpinButton
                fieldStyle={sideBySide}
                value={value}
                min={0}
                step={type === "float" ? 0.01 : 1}
                label={label}
                onChange={(newValue) => action(newValue)}
              />
            </Stack>
          );
        })}
      </Stack>

      <hr style={{ background: "#e1dfdd", border: "none", height: "1px" }} />

      <Stack style={{ rowGap: 8 }}>
        <Label style={{ padding: 0, fontWeight: 600 }}> {t("Set 2nd Harmonics")}</Label>

        {inputs.harmonicsTwo.map(({ label, value, type, action }: any) => (
          <Stack key={`harmonics2-${label}`} horizontal style={{ rowGap: 8 }}>
            <SpinButton
              fieldStyle={sideBySide}
              value={value}
              min={0}
              step={type === "float" ? 0.01 : 1}
              label={label}
              onChange={(newValue) => action(newValue)}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
