/* eslint-disable react-hooks/exhaustive-deps */
import {
  type SelectTabData,
  type SelectTabEvent,
  type TabValue,
  Tab,
  TabList,
} from "@fluentui/react-components";
import {
  DataConnectionLibraryIcon,
  ExploreDataIcon,
  NotePinnedIcon,
} from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { pageStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { listAsyncMachines, selectMachinesStatus } from "../Machines/reducer";
import { listAsyncProjs, selectProjectsStatus } from "../Projects/reducer";
import CfgPinningPivotSection from "./CfgPinning/CfgPinningPivotSection";
import ImagesPivotSection from "./Images/ImagesPivotSection";
import ParkedImagesList from "./ParkedImages/ParkedImagesList";

type ImagesSectionProps = {
  hasWritePermissions: boolean;
};

/**
 * Gets the images section component.
 * @param hasWritePermissions Value determining whether the user has permissions to write this data
 * @returns The images section component
 */
const ImagesSection = ({ hasWritePermissions }: ImagesSectionProps) => {
  const { t } = useTranslation();
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const dispatch = useAppDispatch();

  // Gets the projects and machines list.
  useEffect(() => {
    projectStatus === Status.void && dispatch(listAsyncProjs());
    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, []);

  const [selectedValue, setSelectedValue] = useState<TabValue>();

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <section style={pageStyle}>
      <TabList
        defaultValue='images'
        selectedValue={selectedValue}
        aria-label={t("Images pivot")}
        onTabSelect={onTabSelect}
      >
        <Tab value='images'>
          <span
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "flex-start",
              alignContent: "center",
              columnGap: "0.5em",
            }}
          >
            <ExploreDataIcon style={{ color: "rgb(44, 82, 159)" }} />
            {t("Images")}
          </span>
        </Tab>
        <Tab value='cfg_pinning'>
          <span
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "flex-start",
              alignContent: "center",
              columnGap: "0.5em",
            }}
          >
            <NotePinnedIcon style={{ color: "rgb(44, 82, 159)" }} />
            {t("CFG Pinning")}
          </span>
        </Tab>
        <Tab value='parked_images'>
          <span
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "flex-start",
              alignContent: "center",
              columnGap: "0.5em",
            }}
          >
            <DataConnectionLibraryIcon style={{ color: "rgb(44, 82, 159)" }} />
            {t("Parked Images")}
          </span>
        </Tab>
      </TabList>
      <div>
        {selectedValue === "images" && (
          <ImagesPivotSection hasAddPermissions={hasWritePermissions} />
        )}
        {selectedValue === "cfg_pinning" && (
          <CfgPinningPivotSection hasWritePermissions={hasWritePermissions} />
        )}
        {selectedValue === "parked_images" && (
          <ParkedImagesList hasSearchPermissions={hasWritePermissions} />
        )}
      </div>
    </section>
  );
};

export default ImagesSection;
