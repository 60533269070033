import { difference } from "lodash-es";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { DialogSize } from "../common/Dialog";
import Dropdown from "../common/Dropdown";
import { notification } from "../common/Notification";
import FormDialog from "../Generic/FormDialog";
import type { MachineToList } from "../Machines/models";
import { selectMachinesToList } from "../Machines/reducer";
import { DataloggersAPI } from "./api";
import type { DataloggerDialogsProps } from "./formModels";
import type { D325, D850, D850Eco, DBasic } from "./models";
import { DModels } from "./models";
import { listDataloggersAsync } from "./reducer";

type MachineKeysDifferencesProps = {
  keysToDelete: string[];
  keysToAdd: string[];
};

/**
 * Gets a dropdown item option from a machine's information.
 * @param machine The machine item.
 * @returns The drop down option.
 */
const getDropDownItem = (machine: MachineToList) => {
  return {
    key: machine.id,
    text: `${machine.dalogId} - ${machine.name}`,
  };
};

/**
 * Calculates and gets the machine keys that should be added and removed to a determined datalogger.
 * @param currentMachineKeys The current machine keys linked to the datalogger.
 * @param newMachineKeys The desired new machine keys linked to the datalogger.
 * @returns An object with the keys to add and delete.
 */
const getMachineKeysDifferencesProps = (
  currentMachineKeys: string[],
  newMachineKeys: string[],
): MachineKeysDifferencesProps => {
  const result: MachineKeysDifferencesProps = {
    keysToAdd: [],
    keysToDelete: [],
  };

  // Gets the keys to add
  newMachineKeys.forEach((newKey) => {
    const existingKey = currentMachineKeys.indexOf(newKey);
    if (existingKey >= 0) {
      return;
    }

    result.keysToAdd.push(newKey);
  });

  // Gets the keys to delete.
  currentMachineKeys.forEach((currentKey) => {
    const existingKey = newMachineKeys.indexOf(currentKey);
    if (existingKey >= 0) {
      return;
    }

    result.keysToDelete.push(currentKey);
  });

  return result;
};

/**
 * Builds the datalogger entry with the new machine Ids information.
 * @param model The model
 * @param entry The current datalogger entry.
 * @param machineIds The selected machine Ids.
 * @returns The updated datalogger entry.
 */
const buildDataloggerEntry = (
  model: DModels,
  entry: D325 | DBasic | D850Eco | D850,
  machineIds: string[],
): D325 | DBasic | D850Eco | D850 => {
  let result: D325 | DBasic | D850Eco | D850 = undefined;
  switch (model) {
    case DModels.D325:
      result = { ...entry, machineIds: machineIds } as D325;
      break;
    case DModels.D555:
    case DModels.D650:
      result = { ...entry, machineIds: machineIds } as DBasic;
      break;
    case DModels.D850Eco:
      result = { ...entry, machineIds: machineIds } as D850Eco;
      break;
    case DModels.D850:
      result = { ...entry, machineIds: machineIds } as D850;
      break;
  }

  return result;
};

/**
 * Gets the datalogger select machines dialog.
 * @param model The datalogger model.
 * @param entry The datalogger table item
 * @param onClose Function called when the dialog needs to be closed (must be implemented).
 * @returns The datalogger select machines dialog.
 */
const DataloggerSelectMachinesDialog = ({ model, entry, onClose }: DataloggerDialogsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const machines = useAppSelector(selectMachinesToList);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [options, setOptions] = useState([]);
  const [newSelectedKeys, setNewSelectedKeys] = useState<string[]>([]);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<string[]>([]);

  // Gets the machines information.
  useEffect(() => {
    const projectMachines: MachineToList[] = machines.filter(
      (machine) => machine.projectId === entry.projectId,
    );

    if (projectMachines.length <= 0) {
      notification.error(
        t(
          "The associated project does not have any attached machines. Please add a machine to the project before selecting it to a datalogger.",
        ),
      );
      onClose?.();
      return;
    }

    // Sets the available options.
    setOptions(
      projectMachines.map((machine) => {
        return getDropDownItem(machine);
      }),
    );

    // Sets the selected keys.
    const initialSelectedKeys = [];
    for (const id of entry.machineIds) {
      const machine = projectMachines.find((machine) => machine.id === id);
      if (!machine) {
        continue;
      }

      initialSelectedKeys.push(id);
    }

    setCurrentSelectedKeys(initialSelectedKeys);
    setNewSelectedKeys(initialSelectedKeys);
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (_event, item) => {
    setNewSelectedKeys(item.selectedOptions);
    const currentSelectedDiff = difference(currentSelectedKeys, item.selectedOptions)?.length;
    const selectedCurrentDiff = difference(item.selectedOptions, currentSelectedKeys)?.length;
    const isValid = Boolean(currentSelectedDiff || selectedCurrentDiff);

    setIsValid(isValid);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const newEntry = buildDataloggerEntry(model, entry, newSelectedKeys);
    if (!newEntry) {
      return;
    }

    DataloggersAPI.update(model, newEntry).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure: Updating machines for a {{model}} datalogger. Please try again later.`, {
            model,
          }),
        );
        return;
      }

      notification.success(t(`Success: Updating machines for a {{model}} datalogger.`, { model }));
      dispatch(listDataloggersAsync(model)());
      onClose();
    });
  };

  const value = options
    .filter((opt) => newSelectedKeys.includes(opt.key))
    .map(({ text }) => text)
    .join(", ");

  return (
    <FormDialog
      title={t("Select machines")}
      isLoading={isLoading}
      isValid={isValid}
      size={DialogSize.S}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Dropdown
        multiselect={true}
        placeholder={t("Select machines...")}
        options={options}
        selectedOptions={newSelectedKeys}
        value={value}
        onOptionSelect={onChange}
      />
    </FormDialog>
  );
};

export default DataloggerSelectMachinesDialog;
