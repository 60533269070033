import { Field, Label } from "@fluentui/react-components";
import type { CSSProperties, PropsWithChildren } from "react";

type LabeledFieldProps = PropsWithChildren & {
  label: any;
  fieldStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  fieldWrapperProps?: any;
};

export function LabeledField({
  children,
  label,
  fieldStyle,
  fieldWrapperProps,
  labelStyle,
}: LabeledFieldProps) {
  return (
    <Field style={{ gap: "4px", marginTop: "8px", ...fieldStyle }} {...fieldWrapperProps}>
      <Label style={{ fontWeight: 600, ...labelStyle }}>{label}</Label>
      {children}
    </Field>
  );
}
