import { Field, Label } from "@fluentui/react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Dropdown from "../../../common/Dropdown";
import type { WirelessSensorNode } from "../../../SensorNodes/models";
import { useValidation } from "../hooks/useValidation";
import type { SensorNodesSubmission } from "../models";
import { MountingDirection, MountingType } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";

const getSensorSchema = () =>
  z.object({
    sensorSerialNumber: z.string().min(1, "Sensor Serial Number is Required"),
    sensorNo: z.string().min(1, "Sensor No is required"),
    sensorDescription: z.string().min(1, "Sensor description is required"),
    mountingType: z.nativeEnum(MountingType),
    mountingDirection: z.nativeEnum(MountingDirection),
  });

interface SensorFieldsProps {
  context: SecondPanelContext;
  sensorNodes: WirelessSensorNode[];
}

export const SensorFields: React.FC<SensorFieldsProps> = ({ context, sensorNodes }) => {
  const { t } = useTranslation();
  const { initialValues, updateFunction } = context;
  const [sensorSub, setSensorSub] = useState<SensorNodesSubmission>({
    ...initialValues.sen,
    mountingType: initialValues.sen.mountingType || MountingType.Screwed,
    mountingDirection: initialValues.sen.mountingDirection || MountingDirection.Axial,
  });
  const sensorSchema = React.useMemo(() => getSensorSchema(), []);
  const customValidators = React.useMemo(
    () => ({
      sensorSerialNumber: (value: string) => {
        const exists = sensorNodes.some((n) => n.sensorNodeId === value.trim());
        return !exists ? t("Sensor Node Id does not exists") : undefined;
      },
    }),
    [sensorNodes, t],
  );

  const { errors, validateField, setValue } = useValidation({
    schema: sensorSchema,
    customValidators,
    defaultValues: sensorSub,
  });

  const handleFieldChange = async (
    field: keyof SensorNodesSubmission,
    newValue: string | MountingType | MountingDirection,
  ) => {
    const partial: Partial<SensorNodesSubmission> = {
      [field]: newValue,
      uniqueId: sensorSub.uniqueId,
    };

    if (field === "sensorSerialNumber" || field === "sensorNo" || field === "sensorDescription") {
      await validateField(field, newValue.toString());
      setValue(field, newValue.toString());
    }

    setSensorSub((prev) => ({ ...prev, ...partial }));
    updateFunction(partial, sensorSub.uniqueId);
  };

  return (
    <>
      <TextFieldWithDocuments
        fieldName='sensorSerialNumber'
        value={sensorSub.sensorSerialNumber || ""}
        disabled={context.context !== "Sensor Node"}
        label={t("Sensor Serial Number")}
        files={sensorSub.files}
        errorMessage={errors.sensorSerialNumber?.message && t(errors.sensorSerialNumber.message)}
        onChange={(v) => handleFieldChange("sensorSerialNumber", v.trim())}
      />

      <TextFieldWithDocuments
        fieldName='sensorNo'
        value={sensorSub.sensorNo || ""}
        disabled={context.context !== "Sensor Node"}
        label={t("Sensor No: ( {{location}} )", {
          location: sensorSub.locationOfInstallationDescription,
        })}
        files={sensorSub.files}
        errorMessage={errors.sensorNo?.message && t(errors.sensorNo.message)}
        onChange={(v) => handleFieldChange("sensorNo", v.trim())}
      />

      <TextFieldWithDocuments
        fieldName='sensorDescription'
        value={sensorSub.sensorDescription || ""}
        disabled={context.context !== "Sensor Node"}
        label={t("Sensor Description")}
        files={sensorSub.files}
        errorMessage={errors.sensorDescription?.message && t(errors.sensorDescription.message)}
        onChange={(v) => handleFieldChange("sensorDescription", v.trim())}
      />

      <Field style={{ width: "100%" }}>
        <Label>{t("Mounting Type")}</Label>
        <Dropdown
          options={Object.values(MountingType).map((v) => ({
            key: v,
            text: v,
          }))}
          selectedOptions={[sensorSub.mountingType]}
          onOptionSelect={(_, opt) => {
            handleFieldChange("mountingType", opt.optionValue as MountingType);
          }}
        />
      </Field>

      <Field style={{ width: "100%" }}>
        <Label>{t("Mounting Direction")}</Label>
        <Dropdown
          options={Object.values(MountingDirection).map((v) => ({
            key: v,
            text: v,
          }))}
          selectedOptions={[sensorSub.mountingDirection]}
          onOptionSelect={(_, opt) => {
            handleFieldChange("mountingDirection", opt.optionValue as MountingDirection);
          }}
        />
      </Field>
    </>
  );
};
