import { useTranslation } from "react-i18next";

import type { ValidSizes } from "../../../Components/FIles/api";
import { useImage } from "../../../Hooks";
import ImagePlaceholder from "../ImagePlaceholder";
import { Spin } from "../Spin";

type Props = {
  id?: string | null;
  size?: ValidSizes;
};

type NativeAttrs = Omit<React.ImgHTMLAttributes<unknown>, keyof Props | "src">;

export type ImageProps = Props & NativeAttrs;

const Image = ({ id, size, ...rest }: ImageProps) => {
  const { t } = useTranslation();
  const { url, isLoading } = useImage(id as string, size ? size : undefined);

  if (isLoading) return <Spin text={t("Loading image...")} style={{ height: "90%" }} />;

  if (!url) return <ImagePlaceholder />;

  return <img src={url} alt='MachineCVImage' {...rest} />;
};

export default Image;
