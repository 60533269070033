import { useCallback, useEffect, useMemo, useState } from "react";
import { treeHandlers, useTreeState } from "react-hyper-tree";
import {
  useLocationSearch,
  useMachineList,
  useLastSelectedSignal,
} from "../../../../Hooks";
import { PAGE_TYPE_RAW_DATA } from "./SignalsList";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import { getTreeData } from "./utils";
import { useQuery } from "@tanstack/react-query";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export type SearchBy = 'corporation' | 'company' | 'project';
export interface SignalsList {
  search: string;
  searchBy: SearchBy;
  changeSearch: (search: string) => void;
  changeSearchBy: (searchBy: SearchBy) => void;
}

const useSignalsListStore = createWithEqualityFn<SignalsList>()(
  persist(
    (set) => ({
      search: '',
      searchBy: 'project',
      changeSearch: (search: string) => set({ search }),
      changeSearchBy: (searchBy: SearchBy) => set({ searchBy }),
    }),
    {
      name: 'signals-list-search-store',
      partialize: (state) => ({
        searchBy: state.searchBy,
        search: state.search,
      }),
    },
  ),
  shallow
);


const useSignalsList = () => {
  const [locationData] = useLocationSearch();
  const [sensorNodeId, setSensorNodeId] = useState<string | undefined>();
  


  const { getLastSelectedSignal } = useLastSelectedSignal({
    page: PAGE_TYPE_RAW_DATA,
  });
  const lastSelectedSignal = useMemo(
    () => getLastSelectedSignal(),
    [getLastSelectedSignal]
  );

  const [openedMachineNode, setOpenedMachineNode] = useState<any>(null);
  const { isLoading: isSignalListLoading, data: signalsList } = useQuery(
    ["signals-list"],
    () => [],
    {
      enabled: false,
    }
  );

  const {
    search,
    searchBy,

    changeSearch,
    changeSearchBy,

  } = useSignalsListStore();
  

  const hasSignals = !isSignalListLoading && signalsList.length > 0;

  const signalId = locationData?.id || lastSelectedSignal?.signalId;
  const machineId = locationData?.id || lastSelectedSignal?.machineId;

  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );

  const { data: list, isLoading: isMachineListLoading } = useMachineList();

  const data = useMemo(() => {
    if (!list) return [];
    return getTreeData(list, (sensors: any) => {
      if (locationData?.sensorNo && sensors?.length > 0) {
        const sensor = sensors.find(
          (item: any) => item.name === locationData.sensorNo
        );
        if (sensor) setSensorNodeId(sensor.id);
      }
      return sensors;
    });
  }, [list, locationData]);
  const [matchingNodeIds, setMatchingNodeIds] = useState<string[]>([]);

  const filteredData = useMemo(() => {
    if (!data || !search) return data;    
    const searchLower = search.toLowerCase();
    const matchingIds: string[] = [];
    const excludedParentIds: Set<string> = new Set();    
    
    const isNodeExcluded = (nodeId: string) => excludedParentIds.has(nodeId);

    data.forEach((corporation) => {
      corporation.children?.forEach((company) => {
        company.children?.forEach((project) => {
          const projectMatches = project.name.toLowerCase().includes(searchLower);
          if (projectMatches) {
            matchingIds.push(project.id);
            excludedParentIds.add(company.id);
          }
        });
      });
    });
    
    data.forEach((corporation) => {
      corporation.children?.forEach((company) => {
        if (!isNodeExcluded(company.id)) {
          const companyMatches = company.name.toLowerCase().includes(searchLower);
          if (companyMatches) {
            matchingIds.push(company.id);
            excludedParentIds.add(corporation.id);
          }
        }
      });
    });
    
    data.forEach((corporation) => {
      if (!isNodeExcluded(corporation.id)) {
        const corporationMatches = corporation.name.toLowerCase().includes(searchLower);
        if (corporationMatches) {
          matchingIds.push(corporation.id);
        }
      }
    });
    
    setMatchingNodeIds(matchingIds);

    const filteredCorps = data.map(corporation => {

      if (matchingIds.includes(corporation.id)) {
        return corporation;
      }
      const filteredCompanies = corporation.children?.map(company => {

        if (matchingIds.includes(company.id)) {
          return company;
        }

        const filteredProjects = company.children?.filter(project => 
          matchingIds.includes(project.id)
        );

        return filteredProjects && filteredProjects.length > 0
          ? { ...company, children: filteredProjects }
          : null;
      }).filter(Boolean);

      return filteredCompanies && filteredCompanies.length > 0
        ? { ...corporation, children: filteredCompanies }
        : null;
    }).filter(Boolean);
    
    return filteredCorps;
  }, [data, search]);

  const { required, handlers, instance } = useTreeState({
    id: "raw-data-signal-tree",
    multipleSelect: true,
    data: filteredData,
  });

  const defaultSelected = useMemo(() => {
    if (isMachineListLoading || !data.length || !hasSignals) return null;

    const machine = instance.getNodeById(signalId);

    return machine && !machine.isLoading() ? machine : null;
  }, [isMachineListLoading, instance, signalId, data.length, hasSignals]);

  const scrollToNode = useCallback((nodeId: string | number) => {
    const container =
      document.querySelector<HTMLDivElement>(".sidebar-scrollbar");
    if (!container) return;

    const node = container.querySelector(`[data-node-id="${nodeId}"]`);
    if (!node) return;

    container.scrollTo({
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    if (matchingNodeIds.length > 0 && handlers && search) { 
      matchingNodeIds.forEach((nodeId) => {
        const node = handlers.getNode(nodeId); 
        if (node) {
          let parentNode = node.getParent();
          while (parentNode) {
            if(!parentNode.isOpened()) handlers.setOpen(parentNode, true);      
            parentNode = parentNode.getParent(); 
          }
        } 
      });
    } else if (!search && handlers) { 
      const closeAllNodes = (nodes: any[]) => {
        nodes.forEach((node) => {
          if (node.isOpen) {
            handlers.setOpen(node, false);
          }
          if (node.children) {
            closeAllNodes(node.children);
          }
        });
      }; 
      closeAllNodes(instance.data);
    }
  }, [matchingNodeIds, handlers, search]); 

  useEffect(() => {
    if (!hasSignals) return;

    if (signalId) {
      const treeSignalNode = instance.getNodeById(signalId);
      if (treeSignalNode) {
        const parent = treeSignalNode.getParent();
        if (parent) parent.setOpened(true);
        treeSignalNode.setOpened(true);
      }
    }

    const nodeId = signalId || defaultSelected?.id;

    scrollToNode(nodeId);
  }, [hasSignals, signalId, instance, scrollToNode, defaultSelected]);

  useEffect(() => {
    if (sensorNodeId) {
      const sensorNode = handlers.getNode(sensorNodeId);
      if (sensorNode) handlers.setOpen(sensorNode);
    }
  }, [defaultSelected?.asyncDataLoaded, sensorNodeId, handlers]);

  useEffect(() => {
    if (machineId && instance?.data?.length > 0 && !openedMachineNode) {
      const machineNode = instance.getNodeById(machineId);
      if (machineNode) {
        treeHandlers.trees["raw-data-signal-tree"].handlers.setOpenByPath(
          machineNode.getPath()
        );
        setOpenedMachineNode(machineNode);
      }
    }
  }, [instance, machineId, selectedSignals, openedMachineNode]);

  useEffect(() => {
    if (openedMachineNode?.asyncDataLoaded) {
      const sensorNode = openedMachineNode.children.find(
        (child: any) => child.data.name === lastSelectedSignal.sensorNo
      );
      if (sensorNode) sensorNode.setOpened(true);
    }
  }, [
    openedMachineNode,
    openedMachineNode?.asyncDataLoaded,
    lastSelectedSignal.sensorNo,
  ]);
  return {
    required,
    handlers,
    hasData: !!data.length,
    isLoading: isMachineListLoading || isSignalListLoading,
    searchBy, 
    search,
    changeSearchBy, 
    changeSearch,

  };
};

export default useSignalsList;
