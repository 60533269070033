// Original path: src/src/Components/WirelessSensors/components/Tree/ChartCommandBar.tsx
import { RefreshIcon, Trending12Icon } from "@fluentui/react-icons-mdl2";
import { useEffect } from "react";

import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";

type ChartCommandBarProps = {
  title: string;
  onRefresh: () => void;
  onTrigger?: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const refreshInterval: ReturnType<typeof setInterval> = null;
const ChartCommandBar = ({ title, onRefresh, onTrigger }: ChartCommandBarProps) => {
  useEffect(() => {
    return () => clearInterval(refreshInterval);
  }, []);

  const items: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <div className='chart-header-title'>{title}</div>,
    },
    {
      key: "refresh",
      text: "",
      type: CommandBarItemType.Button,
      icon: <RefreshIcon />,
      onClick: onRefresh,
    },
    {
      key: "trigger",
      text: "",
      type: CommandBarItemType.Button,
      icon: <Trending12Icon />,
      onClick: onTrigger,
    },
  ];

  return (
    <div className='chart-header'>
      <BaseCommandBar items={computeCommandBarItems(items)} />
    </div>
  );
};

export default ChartCommandBar;
