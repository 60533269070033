import type {
  DialogProps,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Tab,
  TabList,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { ContactInfoIcon } from "@fluentui/react-icons-mdl2";
import type { CSSProperties } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AzureServiceEndpointIcon from "../../assets/svg/AzureServiceEndpoint";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import Checkbox from "../common/Checkbox";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table from "../common/Table/v9";
import TextField from "../common/TextField";
import { RolesComponentToAdd } from "../Roles/RolesComponentToAdd";
import type { ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import type { ServiceAccountAddRole } from "../ServiceAccount/models";
import {
  listAsyncServiceAccount,
  selectServiceAccountsDdpProperties,
  selectServiceAccountsStatus,
} from "../ServiceAccount/reducer";
import { Stack } from "../Stack";
import type { UserSimple } from "../UsersRBCA/models";
import {
  listAsyncUsersRBAC,
  selectUsersPlain,
  selectUsersRBACPlain,
  selectUsersRBACStatus,
} from "../UsersRBCA/reducer";
import { addManyPermissionsApi } from "./api";
import type { RequestRolesAddMany } from "./models";

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const title2Style: CSSProperties = {
  fontSize: 14,
  fontWeight: 600,
  padding: "8px 0",
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

export interface CheckOptionsUsersOrServices extends UserSimple {
  type: string;
}

type AddDialogProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const AddDialog = ({ show, onClose, onSuccess, ...rest }: AddDialogProps) => {
  const { t } = useTranslation();
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>([]);

  const dispatch = useAppDispatch();
  const [selectToggle, setSelectToggle] = useState<"user" | "service" | "table">("user");
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<CheckOptionsUsersOrServices[]>([]);

  const [addManyPermissions, setAddManyPermissions] = useState<RequestRolesAddMany>();

  const usersPlain = useAppSelector(selectUsersPlain);
  const usersRBACPlain = useAppSelector(selectUsersRBACPlain);
  const usersStatus = useAppSelector(selectUsersRBACStatus);

  const serviceAccounts = useAppSelector(selectServiceAccountsDdpProperties);
  const serviceAccountsStatus = useAppSelector(selectServiceAccountsStatus);
  const [filteringTextUser, setFilteringTextUser] = useState("");

  useEffect(() => {
    setAddManyPermissions({
      ...addManyPermissions,
      roles: rolesToAdd as ServiceAccountAddRole[],
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesToAdd]);

  useEffect(() => {
    if (usersStatus === Status.void) dispatch(listAsyncUsersRBAC());
    if (serviceAccountsStatus === Status.void) dispatch(listAsyncServiceAccount());
    return () => {};
  }, [dispatch, serviceAccountsStatus, usersStatus]);

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId,
      ),
    );
  };

  const handleChangeUserFilter = (value) => {
    setFilteringTextUser(value.toLocaleLowerCase());
  };

  const membersPivot = useCallback(() => {
    const onChangeCheckbox = (checked: boolean, id: string, type: string) => {
      if (checked) {
        setSelectedUsersIds([...selectedUsersIds, id]);
        setAddManyPermissions({
          ...addManyPermissions,
          memberAndOrServiceAccountIds: [...selectedUsersIds, id],
        });

        setSelectedMembers([
          ...selectedMembers,
          type === "User"
            ? { ...usersPlain.find((mem) => mem.id === id), type: type }
            : {
                id: serviceAccounts.find((mem) => mem.memberId === id).memberId,
                name: serviceAccounts.find((mem) => mem.memberId === id).displayName,
                type: type,
              },
        ]);
      } else {
        setSelectedUsersIds((prev) => prev.filter((x) => x !== id));
        setAddManyPermissions({
          ...addManyPermissions,
          memberAndOrServiceAccountIds: selectedUsersIds.filter((x) => x !== id),
        });

        setSelectedMembers(selectedMembers.filter((mem) => mem.id !== id));
      }
    };
    const getColumnsMembers = () => {
      const columns: Column[] = [
        {
          key: "name",
          name: t("Name"),
          fieldName: "name",
          minWidth: 130,
          isSortable: true,
        },
        {
          key: "type",
          name: t("Type"),
          fieldName: "type",
          minWidth: 130,
          isSortable: true,
        },
        {
          key: "id",
          name: "Id",
          fieldName: "id",
          minWidth: 250,
          isSortable: true,
        },
        {
          key: "typeIcon",
          name: "",
          fieldName: "typeIcon",
          minWidth: 50,
          isSortable: false,
          isExportable: false,
          onRender: (userOrService: CheckOptionsUsersOrServices) => (
            <div style={{ display: "flex" }}>
              <Tooltip withArrow relationship='label' content={t("Type")}>
                <span>
                  {userOrService.type === "User" ? (
                    <ContactInfoIcon
                      style={{ fontSize: "16px", color: "#2c529f", cursor: "pointer" }}
                    />
                  ) : (
                    <AzureServiceEndpointIcon
                      style={{ width: "20px", fill: "#2c529f", cursor: "pointer" }}
                    />
                  )}
                </span>
              </Tooltip>
            </div>
          ),
        },
      ];
      return columns;
    };

    return (
      <>
        <TabList
          selectedValue={selectToggle}
          appearance='filled-circular'
          onTabSelect={(e, data) => setSelectToggle(data.value as "user" | "service" | "table")}
        >
          <Tab value='user'>{t("Select Users")}</Tab>
          <Tab value='service'>{t("Select Services")}</Tab>
          <Tab value='table'>{t("Show All Selected")}</Tab>
        </TabList>
        <div>
          {selectToggle === "table" && (
            <Stack horizontal>
              <Stack.Item style={{ width: "100%" }}>
                <Table
                  persistOpts={{
                    key: "table-members",
                    version: 2,
                  }}
                  header={{
                    title: t("Members selection"),
                  }}
                  items={selectedMembers}
                  v8Columns={getColumnsMembers()}
                  //filters={filters}
                  isLoading={false}
                />
              </Stack.Item>
            </Stack>
          )}
          {selectToggle === "user" && (
            <Stack.Item style={{ padding: 20 }}>
              <Stack style={{ gap: 4, marginBottom: 8 }}>
                <Text style={titleStyle}>{t("Select Users")}</Text>
                <TextField
                  label={t("Filter:")}
                  value={filteringTextUser}
                  placeholder={t("Type to filter")}
                  onChange={handleChangeUserFilter}
                />
              </Stack>

              {usersRBACPlain.map((idProv) => {
                const aux = idProv.users.filter(
                  (us) =>
                    us?.name?.toLowerCase()?.indexOf(filteringTextUser) > -1 ||
                    us?.issuer?.toLowerCase()?.indexOf(filteringTextUser) > -1,
                );
                return (
                  <Stack key={idProv.name}>
                    {aux.length > 0 && <Text style={title2Style}>{idProv.name}</Text>}
                    {aux.map((usr) => {
                      return (
                        <Checkbox
                          key={usr.id}
                          label={usr.name}
                          style={{ padding: 2 }}
                          checked={!!selectedUsersIds.find((id) => id === usr.id)}
                          onChange={(checked) => onChangeCheckbox(checked, usr.id, "User")}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack.Item>
          )}
          {selectToggle === "service" && (
            <Stack.Item style={{ padding: 20 }}>
              <Stack style={{ gap: 4, marginBottom: 8 }}>
                <Text style={titleStyle}>{t("Select Services")}</Text>
                <TextField
                  label={t("Filter:")}
                  value={filteringTextUser}
                  placeholder={t("Type to filter")}
                  onChange={handleChangeUserFilter}
                />
              </Stack>

              <Stack>
                {serviceAccounts
                  .filter((us) => us?.displayName?.toLowerCase()?.indexOf(filteringTextUser) > -1)
                  .map((usr) => {
                    return (
                      <Checkbox
                        key={usr.memberId}
                        label={usr.displayName}
                        style={{ padding: 2 }}
                        checked={!!selectedUsersIds.find((id) => id === usr.memberId)}
                        onChange={(checked) => onChangeCheckbox(checked, usr.memberId, "Account")}
                      />
                    );
                  })}
              </Stack>
            </Stack.Item>
          )}
        </div>
      </>
    );
  }, [
    addManyPermissions,
    filteringTextUser,
    selectToggle,
    selectedMembers,
    selectedUsersIds,
    serviceAccounts,
    usersPlain,
    usersRBACPlain,
    t,
  ]);

  const handleClose = () => {
    onClose?.();
  };

  const [selectedValue, setSelectedValue] = useState<TabValue>("members");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <>
      <BaseDialog
        {...rest}
        open={show}
        surfaceStyle={{ width: DialogSize.L }}
        onOpenChange={handleClose}
      >
        <BaseDialogTitle>{t("Add Permissions")}</BaseDialogTitle>
        <DialogContent>
          <TabList
            selectedValue={selectedValue}
            aria-label={t("Pivots to add permission")}
            style={{ marginBottom: "8px" }}
            onTabSelect={onTabSelect}
          >
            <Tab value='members'>{t("Members")}</Tab>
            <Tab value='role'>{t("Role")}</Tab>
          </TabList>
          <div>
            {selectedValue === "members" && (
              <div
                style={{
                  overflow: "auto",
                  height: "52vh",
                  position: "relative",
                }}
              >
                {membersPivot()}
              </div>
            )}
            {selectedValue === "role" && (
              <div
                style={{
                  height: "52vh",
                  position: "relative",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <RolesComponentToAdd
                  rolesToAdd={rolesToAdd}
                  setRolesToAdd={setRolesToAdd}
                  onDelete={onDelete}
                />
              </div>
            )}
          </div>
          <DialogActions>
            <Button
              appearance='primary'
              onClick={async () => {
                await addManyPermissionsApi(addManyPermissions)
                  .then((res) => {
                    if (res.statusText === "Created") {
                      notification.success(t("Permissions added"));
                      //dispatch(listAsyncPermi());
                    } else notification.error(t("Something went wrong"));

                    onSuccess();
                  })
                  .catch(console.error);
              }}
            >
              {t("Assign")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    </>
  );
};
