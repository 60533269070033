import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { maxLengthType1 } from "../../../../schema/Constants";
import { isValidIMEI, isValidMacAddress } from "../../../../schema/Utils";
import TextField from "../../../common/TextField";
import { maxRsshPort, minRsshPort } from "../../../Gateways/AddDialog";
import { Stack } from "../../../Stack";
import { useValidation } from "../hooks/useValidation";
import { SupportContext } from "../SupportContext";

const getSchema = (t) =>
  z.object({
    adminPassword: z.string().min(1, t("This field is required")).max(maxLengthType1),
    userPassword: z.string().min(1, t("This field is required")).max(maxLengthType1),
    ltePlanSize: z
      .string()
      .min(1, t("This field is required"))
      .refine((val) => /^\d*\.?\d*$/.test(val), t("Try a number between (1.0:1000.0)")),
    publicKey: z.string().optional(),
    rsshPort: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val) return true;
          const port = Number(val);
          return Number.isInteger(port) && port >= minRsshPort && port <= maxRsshPort;
        },
        (val) => ({
          message: t(`rsshPort must be an integer between ${minRsshPort} and ${maxRsshPort}`),
        }),
      ),
    wifiPasswordForAP: z.string().optional(),
    wifiSSID: z.string().optional(),
    iMEI: z.string().optional(),
    wirepassSinkNodeAddress: z.string().optional(),
    wirepassChannel: z.string().optional(),
    ethernetMacAddress: z.string().optional(),
    firmware: z.string().optional(),
  });

export const AddDialog = () => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support.gatewaysSupportToAdd.find(
    (gateway) => gateway.matchUniqueId === support.linkedGatewayUniqueId,
  );

  const { errors, validateField, isValid, setValue } = useValidation({
    schema,
    customValidators: {
      ethernetMacAddress: (value) => {
        if (!value) return undefined;
        if (!isValidMacAddress(value)) {
          return t("Invalid MAC address format. Expected: 00:1A:2B:3C:4D:5E");
        }
        return undefined;
      },
      iMEI: (value) => {
        if (!value) return undefined;
        if (!isValidIMEI(value)) {
          return t("Invalid IMEI format. Expected: 15 digits (e.g. 123456789012345)");
        }
        return undefined;
      },
    },
    defaultValues: {
      ...toUpdate,
      ltePlanSize: toUpdate?.ltePlanSize?.toString() || "",
      rsshPort: toUpdate?.rsshPort?.toString() || "",
    },
  });

  type FormFields = z.infer<ReturnType<typeof getSchema>>;

  const handleFieldChange = async (field: keyof FormFields, value: string) => {
    const trimmedValue = value.trim();
    setValue(field, trimmedValue);
    await validateField(field, trimmedValue);

    const updatedValue =
      field === "ltePlanSize" || field === "rsshPort"
        ? isNaN(Number(trimmedValue))
          ? trimmedValue
          : Number(trimmedValue)
        : trimmedValue;

    updateSupportEle({
      [field]: updatedValue,
      matchUniqueId: toUpdate.matchUniqueId,
      isValid,
    });
  };

  return (
    <Stack>
      <TextField
        label={t("Admin Password *")}
        value={toUpdate?.adminPassword || ""}
        fieldWrapperProps={{
          validationMessage: errors.adminPassword?.message,
        }}
        onChange={(newValue) => handleFieldChange("adminPassword", newValue || "")}
      />

      <TextField
        label={t("User Password *")}
        value={toUpdate?.userPassword || ""}
        fieldWrapperProps={{
          validationMessage: errors.userPassword?.message,
        }}
        onChange={(newValue) => handleFieldChange("userPassword", newValue || "")}
      />

      <TextField
        label={t("LTE Plan Size *")}
        value={toUpdate?.ltePlanSize?.toString() || ""}
        fieldWrapperProps={{
          validationMessage: errors.ltePlanSize?.message,
        }}
        onChange={(newValue) => handleFieldChange("ltePlanSize", newValue || "")}
      />

      <TextField
        label={t("Public Key")}
        value={toUpdate?.publicKey || ""}
        onChange={(newValue) => handleFieldChange("publicKey", newValue || "")}
      />

      <TextField
        label={t("RSSH Port")}
        value={toUpdate?.rsshPort?.toString() || ""}
        fieldWrapperProps={{
          validationMessage: errors.rsshPort?.message,
        }}
        onChange={(newValue) => handleFieldChange("rsshPort", newValue || "")}
      />

      <TextField
        label={t("WiFi Password for AP")}
        value={toUpdate?.wifiPasswordForAP || ""}
        onChange={(newValue) => handleFieldChange("wifiPasswordForAP", newValue || "")}
      />

      <TextField
        label={t("WiFi SSID")}
        value={toUpdate?.wifiSSID || ""}
        onChange={(newValue) => handleFieldChange("wifiSSID", newValue || "")}
      />

      <TextField
        label={t("IMEI")}
        value={toUpdate?.iMEI || ""}
        fieldWrapperProps={{
          validationMessage: errors.iMEI?.message,
        }}
        onChange={(newValue) => handleFieldChange("iMEI", newValue || "")}
      />

      <TextField
        label={t("Wirepass Sink Node Address")}
        value={toUpdate?.wirepassSinkNodeAddress || ""}
        onChange={(newValue) => handleFieldChange("wirepassSinkNodeAddress", newValue || "")}
      />

      <TextField
        label={t("Wirepass Channel")}
        value={toUpdate?.wirepassChannel || ""}
        onChange={(newValue) => handleFieldChange("wirepassChannel", newValue || "")}
      />

      <TextField
        label={t("Ethernet MAC Address")}
        value={toUpdate?.ethernetMacAddress || ""}
        fieldWrapperProps={{
          validationMessage: errors.ethernetMacAddress?.message,
        }}
        onChange={(newValue) => handleFieldChange("ethernetMacAddress", newValue || "")}
      />

      <TextField
        label={t("Firmware")}
        value={toUpdate?.firmware || ""}
        onChange={(newValue) => handleFieldChange("firmware", newValue || "")}
      />
    </Stack>
  );
};
