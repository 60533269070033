import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Spinner,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import { MailIcon } from "@fluentui/react-icons-mdl2";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getUserFieldsReq, maxLengthType3, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { areObjectsEqual } from "../../schema/Utils";
import ChoiceGroup from "../common/ChoiceGroup";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import { Stack } from "../Stack";
import {
  detailsServiceAccount,
  sendsConfirmationPassServiceAccount,
  updateServiceAccount,
} from "./api";
import type { ServiceAccountDetails, ServiceAccountUpdate, UserExtendedProperties } from "./models";
import { selectServiceAccountsError, selectServiceAccountsStatus } from "./reducer";
import { listAsyncServiceAccountProducts, selectServiceAccountsProducts } from "./reducerProducts";
import { SubscriptionKeys } from "./SubscriptionKeys";

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: t("Invalid email address") })
      .min(1, { message: t("This field is required") }),
    lastName: z.string().min(1, { message: t("This field is required") }),
    firstName: z.string().min(1, { message: t("This field is required") }),
    purpose: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      }),
    jobTitle: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    companyName: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    department: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    streetAddress: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    city: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    stateOrProvince: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    zipOrPostalCode: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    countryOrRegion: z
      .string()
      .max(maxLengthType3, {
        message: t(`Name must contain at most {{maxLength}} character(s)`, {
          maxLength: maxLengthType3,
        }),
      })
      .optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: t("Invalid number") }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
  });

type DetailsDialogProps = {
  data?: ServiceAccountDetails;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialog = ({ data, show, onSuccess, onClose, ...rest }: DetailsDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectServiceAccountsStatus);
  const error = useAppSelector(selectServiceAccountsError);
  const items = useAppSelector(selectServiceAccountsProducts);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [productIdToAdd, setProductIdToAdd] = useState<string>(
    data?.apimSubscriptions?.at(0).scope,
  );

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  useEffect(() => {
    dispatch(listAsyncServiceAccountProducts());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        email: data.ddpProperties.email ? data.ddpProperties.email : "",
        lastName: data.ddpProperties.lastName ? data.ddpProperties.lastName : "",
        firstName: data.ddpProperties.firstName ? data.ddpProperties.firstName : "",
        purpose: data.ddpProperties.purpose ? data.ddpProperties.purpose : "",
        jobTitle: data.ddpProperties.jobTitle ? data.ddpProperties.jobTitle : "",
        companyName: data.ddpProperties.companyName ? data.ddpProperties.companyName : "",
        department: data.ddpProperties.department ? data.ddpProperties.department : "",
        streetAddress: data.ddpProperties.streetAddress ? data.ddpProperties.streetAddress : "",
        city: data.ddpProperties.city ? data.ddpProperties.city : "",
        stateOrProvince: data.ddpProperties.stateOrProvince
          ? data.ddpProperties.stateOrProvince
          : "",
        zipOrPostalCode: data.ddpProperties.zipOrPostalCode
          ? data.ddpProperties.zipOrPostalCode
          : "",
        countryOrRegion: data.ddpProperties.countryOrRegion
          ? data.ddpProperties.countryOrRegion
          : "",
        mobilePhone: data.ddpProperties.mobilePhone ? data.ddpProperties.mobilePhone : "",
        memberId: data.ddpProperties.memberId ? data.ddpProperties.memberId : "",
      },
    }),
  });

  useEffect(() => {
    setIsFormChanged(
      !areObjectsEqual(control._formValues, control._defaultValues) ||
        data?.apimSubscriptions?.at(0).scope !== productIdToAdd,
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    data
      ? reset({
          email: data.ddpProperties.email ? data.ddpProperties.email : "",
          lastName: data.ddpProperties.lastName ? data.ddpProperties.lastName : "",
          firstName: data.ddpProperties.firstName ? data.ddpProperties.firstName : "",
          purpose: data.ddpProperties.purpose ? data.ddpProperties.purpose : "",
          jobTitle: data.ddpProperties.jobTitle ? data.ddpProperties.jobTitle : "",
          companyName: data.ddpProperties.companyName ? data.ddpProperties.companyName : "",
          department: data.ddpProperties.department ? data.ddpProperties.department : "",
          streetAddress: data.ddpProperties.streetAddress ? data.ddpProperties.streetAddress : "",
          city: data.ddpProperties.city ? data.ddpProperties.city : "",
          stateOrProvince: data.ddpProperties.stateOrProvince
            ? data.ddpProperties.stateOrProvince
            : "",
          zipOrPostalCode: data.ddpProperties.zipOrPostalCode
            ? data.ddpProperties.zipOrPostalCode
            : "",
          countryOrRegion: data.ddpProperties.countryOrRegion
            ? data.ddpProperties.countryOrRegion
            : "",
          mobilePhone: data.ddpProperties.mobilePhone ? data.ddpProperties.mobilePhone : "",
          memberId: data.ddpProperties.memberId ? data.ddpProperties.memberId : "",
        })
      : reset({
          email: "",
          lastName: "",
          firstName: "",
          purpose: "",
          jobTitle: "",
          companyName: "",
          department: "",
          streetAddress: "",
          city: "",
          stateOrProvince: "",
          zipOrPostalCode: "",
          countryOrRegion: "",
          mobilePhone: "",
          memberId: "",
        });
  }, [data, reset]);

  const handleClose = () => {
    setLoading(false);
    setProductIdToAdd("");
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const aux: ServiceAccountUpdate = {
      ...(formData as UserExtendedProperties),
      productId: productIdToAdd,
    };
    await updateServiceAccount(aux.memberId, aux).then((response) =>
      onSuccess("status" in response, ""),
    );

    handleClose();
  });

  function onChange(ev, option): void {
    if (option.text.includes("WebApp") === true || option.text.includes("Wireless") === true)
      notification.warning(t("Remind: The selected product is for internal users."));

    setProductIdToAdd(option.key);
  }

  const [selectedValue, setSelectedValue] = useState<TabValue>("properties");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{
        width: DialogSize.L,
        maxWidth: "100vw",
      }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle style={{ marginBottom: "24px" }}>
        {t("Details Service Account")}
      </BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("Pivots to edit service account")}
          style={{ marginBottom: "12px" }}
          onTabSelect={onTabSelect}
        >
          <Tab value='properties'>{t("Properties")}</Tab>
          <Tab value='subscriptions'>{t("Subscriptions")}</Tab>
          <Tab value='roles'>{t("Roles")}</Tab>
        </TabList>
        {selectedValue === "properties" && (
          <Stack horizontalAlign='baseline' verticalAlign='center'>
            <Text style={{ fontSize: "18px", fontWeight: 600, marginTop: "20px" }}>
              {t("General")}
            </Text>
            <form onSubmit={onSubmit}>
              <Stack
                wrap
                horizontal
                style={{ maxWidth: "70vw", padding: "35px", gap: 8 }}
                horizontalAlign='stretch'
                verticalAlign='center'
              >
                {renderFormItems(
                  getUserFieldsReq({
                    t,
                    isEmailDisabled: true,
                    withMemberIdRead: true,
                    withServiceAccountFieldsReqExtra: true,
                    withUserFieldsNonReq: true,
                  }) as FormItemProps[],
                  {
                    control,
                    errors: errors as { [schemaProp: string]: FieldError },
                  },
                ).map((ele) => (
                  <Stack.Item key={ele.key}>{ele}</Stack.Item>
                ))}
              </Stack>
              <Text style={titleStyle}>{t("Product")}</Text>
              <Stack
                horizontal
                style={{ maxWidth: "70vw", gap: 8 }}
                horizontalAlign='space-evenly'
                verticalAlign='center'
              >
                <ChoiceGroup
                  options={items?.map((ele) => {
                    return {
                      key: ele.id,
                      text:
                        ele.displayName.includes("WebApp") || ele.displayName.includes("Wireless")
                          ? ele.displayName + " (for internal users)"
                          : ele.displayName,
                    };
                  })}
                  defaultValue={productIdToAdd}
                  onChange={onChange}
                />
              </Stack>
              <Text style={titleStyle}>{t("Confirmation Password Send")}</Text>
              <Stack
                horizontal
                style={{ maxWidth: "70vw", gap: 8 }}
                horizontalAlign='space-evenly'
                verticalAlign='center'
              >
                <Button
                  icon={<MailIcon />}
                  onClick={() =>
                    sendsConfirmationPassServiceAccount(data.ddpProperties.memberId).then(
                      (resp) => {
                        resp.status > 199 && resp.status < 300
                          ? notification.success(t("Performed action successfully"))
                          : notification.error(`${t("Something went wrong")}.`);
                      },
                    )
                  }
                >
                  {t("Send")}
                </Button>
              </Stack>
              <DialogActions>
                <Button
                  type='submit'
                  icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                  disabled={isLoading || !isValid || !isFormChanged}
                >
                  {t("Save Changes")}
                </Button>
              </DialogActions>
            </form>
          </Stack>
        )}
        {selectedValue === "subscriptions" && (
          <div>
            {data.apimSubscriptions?.map((sub) => (
              <div key={sub.id}>
                <Stack style={{ gap: 20 }}>
                  <SubscriptionKeys
                    memberId={data.ddpProperties.memberId}
                    subscriptionId={sub.id.split("/").at(-1)}
                  />
                </Stack>
              </div>
            ))}
          </div>
        )}
        {selectedValue === "roles" && (
          <RolesComponentDetails
            data={data}
            memberId={data.ddpProperties.memberId}
            detailsFunction={detailsServiceAccount}
          />
        )}
      </DialogContent>
    </BaseDialog>
  );
};
