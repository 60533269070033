import { WirelessGateway } from "../../../Gateways/models";
import { SubmissionSupport } from "../../models";
import {
  CompanySubmission,
  CorporationSubmission,
  GatewaySubmission,
  MachineSubmission,
  ProjectSubmission,
  SensorNodesSubmission,
} from "../models";
import { ValidationResult } from "../SubmissionsDetails";

const generateUniqueId = (): string => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

export const addUniqueIdsToSubmission = (
  corporationData: CorporationSubmission
): CorporationSubmission => {
  const updatedCompanies = corporationData?.companies?.map((company) => ({
    ...company,
    uniqueId: generateUniqueId(),
    projects: company.projects?.map((project) => ({
      ...project,
      uniqueId: generateUniqueId(),
      machines: project.machines?.map((machine) => ({
        ...machine,
        uniqueId: generateUniqueId(),
        sensors: machine.sensors?.map((sensor) => ({
          ...sensor,
          uniqueId: generateUniqueId(),
        })),
      })),
      gateways: project.gateways?.map((gateway) => ({
        ...gateway,
        uniqueId: generateUniqueId(),
      })),
    })),
  }));
  return {
    ...corporationData,
    companies: updatedCompanies,
    uniqueId: generateUniqueId(),
  };
};

function isObject(item: unknown): item is Record<string, unknown> {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}

export function intelligentMerge<T extends object>(
  original: T,
  updates: Partial<T>
): T {
  if (!updates) return original;

  const result = { ...original };

  Object.keys(updates).forEach((key) => {
    const updateValue = updates[key];
    const originalValue = original[key];

    if (isObject(updateValue) && isObject(originalValue)) {
      result[key] = intelligentMerge(originalValue, updateValue);
    } else if (updateValue !== undefined && updateValue !== originalValue) {
      result[key] = updateValue;
    }
  });

  return result;
}

export function updatePartOfSubmission(
  corporationSub: CorporationSubmission,
  uniqueId: string,
  partToUpdate:
    | Partial<CorporationSubmission>
    | Partial<CompanySubmission>
    | Partial<ProjectSubmission>
    | Partial<GatewaySubmission>
    | Partial<MachineSubmission>
    | Partial<SensorNodesSubmission>
): CorporationSubmission {
  if (corporationSub.uniqueId === uniqueId) {
    return intelligentMerge(corporationSub, partToUpdate);
  }

  const updateCompany = (company: CompanySubmission): CompanySubmission => {
    if (company.uniqueId === uniqueId) {
      return intelligentMerge(company, partToUpdate as CompanySubmission);
    } else {
      const updatedProjects = company.projects?.map(updateProject);
      return updatedProjects
        ? { ...company, projects: updatedProjects }
        : company;
    }
  };

  const updateProject = (project: ProjectSubmission): ProjectSubmission => {
    if (project.uniqueId === uniqueId) {
      return intelligentMerge(project, partToUpdate as ProjectSubmission);
    } else {
      const updatedMachines = project.machines?.map(updateMachine);
      const updatedGateways = project.gateways?.map(updateGateway);

      if (!updatedMachines && !updatedGateways) return project;

      return {
        ...project,
        ...(updatedMachines && { machines: updatedMachines }),
        ...(updatedGateways && { gateways: updatedGateways }),
      };
    }
  };

  const updateMachine = (machine: MachineSubmission): MachineSubmission => {
    if (machine.uniqueId === uniqueId) {
      return intelligentMerge(machine, partToUpdate as MachineSubmission);
    } else {
      const updatedSensors = machine.sensors?.map(updateSensor);
      return updatedSensors ? { ...machine, sensors: updatedSensors } : machine;
    }
  };

  const updateSensor = (
    sensor: SensorNodesSubmission
  ): SensorNodesSubmission => {
    if (sensor.uniqueId === uniqueId) {
      return intelligentMerge(sensor, partToUpdate as SensorNodesSubmission);
    }
    return sensor;
  };

  const updateGateway = (gateway: GatewaySubmission): GatewaySubmission => {
    return gateway.uniqueId === uniqueId
      ? intelligentMerge(gateway, partToUpdate as GatewaySubmission)
      : gateway;
  };

  const updatedCompanies = corporationSub?.companies?.map(updateCompany);
  return updatedCompanies
    ? { ...corporationSub, companies: updatedCompanies }
    : corporationSub;
}

export function createSubmissionSupport(
  corporationSubmission: CorporationSubmission,
  submissionSupport: SubmissionSupport | null,
  wirelessGateways: WirelessGateway[]
): SubmissionSupport {
  if (!submissionSupport) {
    submissionSupport = {
      gatewaysSupportToAdd: [],
      gatewaysSupportToUpdate: [],
      machinesSupport: [],
    };
  }
  // Step 1: Process MachineSubmissions
  if (corporationSubmission?.companies) {
    for (const company of corporationSubmission?.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.machines) {
            for (const machine of project.machines) {
              if (!machine.id) {
                const existingMachineIndex =
                  submissionSupport.machinesSupport.findIndex(
                    (support) => support.matchUniqueId === machine.uniqueId
                  );
                if (existingMachineIndex !== -1) {
                  submissionSupport.machinesSupport[existingMachineIndex] = {
                    dalogId:
                      submissionSupport.machinesSupport[existingMachineIndex]
                        .dalogId,
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  };
                } else {
                  submissionSupport.machinesSupport.push({
                    dalogId: "",
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  // Step 2: Process GatewaySubmissions
  if (corporationSubmission?.companies) {
    for (const company of corporationSubmission?.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.gateways) {
            for (const gateway of project.gateways) {
              const gatewayExists = wirelessGateways.some(
                (wirelessGateway) =>
                  wirelessGateway.serialNumber === gateway.serialNumber
              );

              if (gatewayExists) {
                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );
                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd.splice(
                    existingAddIndex,
                    1
                  );
                }

                const myGat = wirelessGateways.find(
                  (wirelessGateway) =>
                    wirelessGateway.serialNumber === gateway.serialNumber
                );
                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate[
                    existingUpdateIndex
                  ] = {
                    ...myGat,
                    ...submissionSupport.gatewaysSupportToUpdate[
                      existingUpdateIndex
                    ],
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToUpdate.push({
                    ...myGat,
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              } else {
                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );

                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate.splice(
                    existingUpdateIndex,
                    1
                  );
                }

                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd[existingAddIndex] = {
                    ...submissionSupport.gatewaysSupportToAdd[existingAddIndex],
                    serialNumber: gateway.serialNumber,
                    projectId: undefined,
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToAdd.push({
                    serialNumber: gateway.serialNumber,
                    projectId: "123e4567-e89b-12d3-a456-426614174000",
                    ltePlanSize: 0,
                    adminPassword: "",
                    userPassword: "",
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  return submissionSupport;
}

export const getFirstError = (
  feedback: ValidationResult[],
  uniqueId: string
): string | undefined =>
  feedback?.find((ele) => ele.matchUniqueId === uniqueId)?.messages[0];

export function hasElementWithoutId(
  submission:
    | CorporationSubmission
    | CompanySubmission
    | ProjectSubmission
    | MachineSubmission
    | GatewaySubmission
    | SensorNodesSubmission
): boolean {
  // Base case: check if this object itself doesn't have an ID
  if ("id" in submission && !submission.id) {
    return true;
  }

  // Special handling for SensorNodesSubmission which uses sensorNo instead of id
  if ("sensorNo" in submission && !submission.sensorNo) {
    return true;
  }

  // Check CorporationSubmission
  if ("companies" in submission && submission.companies) {
    for (const company of submission.companies) {
      if (!company.id || hasElementWithoutId(company)) {
        return true;
      }
    }
  }

  // Check CompanySubmission
  if ("projects" in submission && submission.projects) {
    for (const project of submission.projects) {
      if (!project.id || hasElementWithoutId(project)) {
        return true;
      }
    }
  }

  // Check ProjectSubmission
  if ("machines" in submission && submission.machines) {
    for (const machine of submission.machines) {
      if (!machine.id || hasElementWithoutId(machine)) {
        return true;
      }
    }
  }

  if ("gateways" in submission && submission.gateways) {
    return true;
  }

  if ("sensors" in submission && submission.sensors) {
    return true;
  }

  // If we reach here, all elements have IDs in this branch
  return false;
}

export function countElementsWithoutId(
  submission:
    | CorporationSubmission
    | CompanySubmission
    | ProjectSubmission
    | MachineSubmission
    | GatewaySubmission
    | SensorNodesSubmission
): number {
  let count = 0;

  // Check CorporationSubmission
  if ("companies" in submission && submission.companies) {
    for (const company of submission.companies) {
      count += countElementsWithoutId(company);
    }
  }

  // Check CompanySubmission
  if ("projects" in submission && submission.projects) {
    for (const project of submission.projects) {
      count += countElementsWithoutId(project);
    }
  }

  // Check ProjectSubmission
  if ("machines" in submission && submission.machines) {
    for (const machine of submission.machines) {
      count += countElementsWithoutId(machine);
    }
  }

  if ("gateways" in submission && submission.gateways) {
    count += submission.gateways.length;
  }

  if ("sensors" in submission && submission.sensors) {
    count += submission.sensors.length;
  }

  return count;
}

export function filterGatewaySupport(
  submissionSupport: SubmissionSupport,
  corporationSubmission: CorporationSubmission
): SubmissionSupport {
  const validGatewayIds = new Set<string>();

  // Collect all valid gateway uniqueIds
  corporationSubmission?.companies?.forEach((company) => {
    company.projects?.forEach((project) => {
      project.gateways?.forEach((gateway) => {
        validGatewayIds.add(gateway.uniqueId);
      });
    });
  });

  // Filter gatewaysSupportToAdd
  const filteredGatewaysSupportToAdd =
    submissionSupport.gatewaysSupportToAdd.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  // Filter gatewaysSupportToUpdate
  const filteredGatewaysSupportToUpdate =
    submissionSupport.gatewaysSupportToUpdate.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  return {
    ...submissionSupport,
    gatewaysSupportToAdd: filteredGatewaysSupportToAdd,
    gatewaysSupportToUpdate: filteredGatewaysSupportToUpdate,
  };
}
