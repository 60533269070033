import { makeStyles } from "@fluentui/react-components";

export const useDropdownStyles = makeStyles({
  transparent: {
    minWidth: "unset",
    background: "transparent",
    border: "none",
    display: "flex",
    "& .fui-Dropdown__expandIcon": {
      width: "20px",
    },

    "& .fui-Dropdown__button": {
      padding: 0,
    },

    "::after": {
      border: "none",
    },
  },
});
