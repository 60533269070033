import { Text } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";

import { Stack } from "../../../Stack";
import type { FormGroupProps } from "../types";

export const FormGroup = ({
  children,
  label,
  stackProps,
  labelProps,
}: PropsWithChildren<FormGroupProps>) => {
  return (
    <Stack
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
      style={{ marginTop: 8, marginBottom: 8 }}
      {...stackProps}
    >
      {label && (
        <Text style={{ minWidth: 160, marginRight: 8 }} {...labelProps}>
          {label}
        </Text>
      )}
      {children}
    </Stack>
  );
};
