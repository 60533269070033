import { Button, Spinner, Text, Tooltip } from "@fluentui/react-components";
import { ArrowSyncRegular, CopyRegular } from "@fluentui/react-icons";
import type { CSSProperties } from "react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { notification } from "../common/Notification";
import TextField from "../common/TextField";
import { Stack } from "../Stack";
import {
  RegeneratePrimaryKeyServiceAccount,
  RegenerateSecondaryKeyServiceAccount,
  subscriptionKeysServiceAccount,
} from "./api";

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "20px",
  marginBottom: "auto",
};

interface SubscriptionKeysInput {
  memberId: string;
  subscriptionId: string;
}

interface keysInfoMain {
  key1: keysInfoManage;
  key2: keysInfoManage;
}

interface keysInfoManage {
  show: boolean;
  key: string;
}

const OnPass = ({ value }: { value: string }) => {
  const { t } = useTranslation();

  return (
    <Stack horizontal>
      <CopyToClipboard
        text={value}
        onCopy={(text) =>
          !text && notification.warning(t("You are trying to copy an empty string."))
        }
      >
        <Button appearance='transparent' icon={<CopyRegular />} />
      </CopyToClipboard>
    </Stack>
  );
};

export const SubscriptionKeys = ({ memberId, subscriptionId }: SubscriptionKeysInput) => {
  const { t } = useTranslation();
  const [keysInfo, setKeysInfo] = useState<keysInfoMain>({
    key1: { key: "********************************", show: false },
    key2: { key: "********************************", show: false },
  });
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);

  const [isLoadingReg1, setIsLoadingReg1] = useState<boolean>(false);
  const [isLoadingReg2, setIsLoadingReg2] = useState<boolean>(false);

  function getHideButtonText(show) {
    return show ? t("Hide") : t("Show");
  }

  return (
    <>
      <Text style={titleStyle}>{t("Subscription of: {{subscriptionId}}", { subscriptionId })}</Text>
      <Stack horizontalAlign='center' verticalAlign='center' style={{ gap: 20 }}>
        <Stack
          horizontal
          style={{ minWidth: "150px", width: "100%", gap: 8 }}
          horizontalAlign='center'
          verticalAlign='end'
        >
          <TextField
            readOnly
            value={keysInfo.key1.key}
            fieldStyle={{ width: "100%" }}
            label={
              <Stack horizontal horizontalAlign='start'>
                <Stack verticalAlign='end'>{t("Primary Key")}</Stack>
                <Tooltip relationship='label' content={t("Regenerate primary key")}>
                  <Button
                    style={{ minWidth: 0, padding: 0, marginLeft: 4 }}
                    appearance='transparent'
                    icon={
                      isLoadingReg1 ? <Spinner size='tiny' /> : <ArrowSyncRegular fontSize={20} />
                    }
                    onClick={async () => {
                      setIsLoadingReg1(true);
                      await RegeneratePrimaryKeyServiceAccount(memberId, subscriptionId).then(
                        (response) => {
                          if ("error" in response) {
                            setKeysInfo({
                              ...keysInfo,
                              key1: {
                                key: "--------",
                                show: false,
                              },
                            });
                            notification.warning(t("The key couldn't be regenerated"));
                          } else {
                            setKeysInfo({
                              ...keysInfo,
                              key1: {
                                key: response.primaryKey,
                                show: true,
                              },
                            });
                            notification.success(t("Regenerated Key!"));
                          }
                        },
                      );
                      setIsLoadingReg1(false);
                    }}
                  />
                </Tooltip>
              </Stack>
            }
            contentAfter={<>{keysInfo.key1.show ? <OnPass value={keysInfo.key1.key} /> : null}</>}
          />
          <div style={{ paddingRight: 10 }}>
            <Button
              appearance='primary'
              onClick={async () => {
                setIsLoading1(true);
                !keysInfo.key1.show
                  ? await subscriptionKeysServiceAccount(memberId, subscriptionId).then(
                      (response) => {
                        setKeysInfo({
                          ...keysInfo,
                          key1: {
                            key: response.primaryKey,
                            show: !keysInfo.key1.show,
                          },
                        });
                      },
                    )
                  : setTimeout(() => {
                      setKeysInfo({
                        ...keysInfo,
                        key1: {
                          key: "********************************",
                          show: !keysInfo.key1.show,
                        },
                      });
                    }, 50);
                setIsLoading1(false);
              }}
            >
              {!isLoading1 ? getHideButtonText(keysInfo.key1.show) : <Spinner size='tiny' />}
            </Button>
          </div>
        </Stack>
        <Stack
          horizontal
          style={{ minWidth: "150px", width: "100%", gap: 8 }}
          horizontalAlign='center'
          verticalAlign='end'
        >
          <TextField
            readOnly
            fieldStyle={{ width: "100%" }}
            value={keysInfo.key2.key}
            label={
              <Stack horizontal horizontalAlign='start'>
                <Stack verticalAlign='end'>{t("Secondary Key")}</Stack>
                <Tooltip relationship='label' content={t("Regenerate secondary key")}>
                  <Button
                    style={{ minWidth: 0, padding: 0, marginLeft: 4 }}
                    appearance='transparent'
                    icon={isLoadingReg2 ? <Spinner size='tiny' /> : <ArrowSyncRegular />}
                    onClick={async () => {
                      setIsLoadingReg2(true);
                      await RegenerateSecondaryKeyServiceAccount(memberId, subscriptionId).then(
                        (response) => {
                          if ("error" in response) {
                            setKeysInfo({
                              ...keysInfo,
                              key2: {
                                key: "--------",
                                show: false,
                              },
                            });
                            notification.warning(t("The key couldn't be regenerated"));
                          } else {
                            setKeysInfo({
                              ...keysInfo,
                              key2: {
                                key: response.secondaryKey,
                                show: true,
                              },
                            });
                            notification.success(t("Regenerated Key!"));
                          }
                        },
                      );
                      setIsLoadingReg2(false);
                    }}
                  />
                </Tooltip>
              </Stack>
            }
            contentAfter={<>{keysInfo.key2.show ? <OnPass value={keysInfo.key2.key} /> : null}</>}
          />
          <div style={{ paddingRight: 10 }}>
            <Button
              appearance='primary'
              onClick={async () => {
                setIsLoading2(true);
                !keysInfo.key2.show
                  ? await subscriptionKeysServiceAccount(memberId, subscriptionId).then(
                      (response) => {
                        setKeysInfo({
                          ...keysInfo,
                          key2: {
                            key: response.secondaryKey,
                            show: !keysInfo.key2.show,
                          },
                        });
                      },
                    )
                  : setTimeout(() => {
                      setKeysInfo({
                        ...keysInfo,
                        key2: {
                          key: "********************************",
                          show: !keysInfo.key2.show,
                        },
                      });
                    }, 50);
                setIsLoading2(false);
              }}
            >
              {!isLoading2 ? getHideButtonText(keysInfo.key2.show) : <Spinner size='tiny' />}
            </Button>
          </div>
        </Stack>
      </Stack>
    </>
  );
};
