export interface FileSubmission {
  id: string;
  type: string;
  fieldName: string;
}

export interface SimpleValidationError {
  [key: string]: string;
}

export interface SensorNodesSubmission {
  sensorSerialNumber: string;
  uniqueId: string;
  locationOfInstallationDescription: string;
  mountingType: MountingType;
  mountingDirection: MountingDirection;
  sensorDescription?: string | null;
  sensorNo?: string | null;
  files?: FileSubmission[];
  errors?: SimpleValidationError;
}

export enum MountingType {
  Screwed = "Screwed (Thread mounted)",
  Epoxy = "Epoxy + Magnet",
}

export enum MountingDirection {
  Radial = "Radial",
  Axial = "Axial",
}

export interface GearboxSubmission {
  manufacturer?: string;
  notation?: string;
  power?: number;
  rotationalSpeed?: number;
  serialnumber?: string;
  files?: FileSubmission[];
}

export interface MachineSubmission {
  name: string;
  id?: string;
  manufacturer?: string;
  customerCode?: string;
  process?: string;
  gearbox: GearboxSubmission;
  sensors?: SensorNodesSubmission[];
  uniqueId: string;
  files?: FileSubmission[];
}

export interface GatewaySubmission {
  serialNumber: string;
  uniqueId: string;
  locationOfInstallationDescription: string;
  installationLocation?: string;
  id?: string;
  files?: FileSubmission[];
}

export interface ProjectSubmission {
  name?: string;
  city?: string;
  continent?: string;
  country?: string;
  id?: string;
  machines?: MachineSubmission[];
  gateways?: GatewaySubmission[];
  uniqueId: string;
}

export interface CompanySubmission {
  name: string;
  id?: string;
  projects?: ProjectSubmission[];
  uniqueId: string;
}

export interface CorporationSubmission {
  name: string;
  id?: string;
  companies?: CompanySubmission[];
  uniqueId: string;
}

export interface Message {
  text: string;
  createdAt: Date;
  createdBy: string;
}

export enum FileSubmissionType {
  NoStatus,
  GearboxManufacturer,
  GearboxPower,
  GearboxRotationalSpeed,
  GearboxSerialNumber,
  GearboxNotation,
  SensorNodes,
  MachineName,
  MachineManufacturer,
  MachineCustomerCode,
  MachineProcess,
  Gateway,
}

export interface Submission {
  submission: CorporationSubmission;
  id: string;
  createdAt: Date;
  createdBy: string;
  creatorName?: string;
  messages?: Message[];
}

export interface SubmissionWithSId extends Submission {
  sID: string;
}

export interface RequestSubmissionComment {
  text: string;
}
