import { Spinner } from "@fluentui/react-components";
import React from "react";
// eslint-disable-next-line import/no-named-as-default
import Tree from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import { NoData } from "../../../../Components/common/NoData";
import CustomSelectNode from "./CustomSelectNode";
import useSignalsList from "./useSignalsList";

export const PAGE_TYPE_RAW_DATA = "RAW_DATA";

const SignalsList: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { required, handlers, isLoading, hasData } = useSignalsList();

  if (isLoading) {
    return <Spinner size='medium' label={t("Loading signal selection...")} />;
  }

  if (!hasData && !isLoading) {
    return (
      <NoData
        text={t("There is no data!")}
        style={{
          width: "100%",
        }}
      />
    );
  }

  if (required.data.length === 0) {
    return (
      <NoData
        text={t("There is no data!")}
        style={{
          width: "100%",
        }}
      />
    );
  }

  return (
    <Tree
      {...required}
      {...handlers}
      disableLines
      depthGap={10}
      renderNode={(props) => (
        <CustomSelectNode {...props} handlers={handlers} treeId='raw-data-signals-tree' />
      )}
    />
  );
});

SignalsList.displayName = "SignalsList";

export default SignalsList;
