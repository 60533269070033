import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { SignalCondition } from "../../schema/status";
import { getOptionFromEnum } from "../../schema/Utils";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { editSignalCondition } from "./api";
import type { UpdateSignalCondition } from "./models";

const getSchema = (t) =>
  z.object({
    condition: z.nativeEnum(SignalCondition),
  });

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "condition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition") },
    options: getOptionFromEnum(t, SignalCondition),
  },
];

type AddOrEditDialogProps = Omit<DialogProps, "children" | "open"> & {
  machineId: string;
  data: UpdateSignalCondition;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const EditCondition = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        condition: data.condition,
      },
    }),
  });

  useEffect(() => {
    data
      ? reset({
          condition: data.condition,
        })
      : reset({
          condition: SignalCondition.NoStatus,
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: UpdateSignalCondition = {
      id: data?.id,
      condition: formData.condition,
    };

    await editSignalCondition(machineId, data?.id, toSend).then((response) =>
      onSuccess("status" in response),
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {
        <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
          <BaseDialogTitle>{t("Edit signal condition")}</BaseDialogTitle>
          <DialogContent>
            <form onSubmit={onSubmit}>
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}

              <DialogActions>
                <Button
                  appearance='primary'
                  type='submit'
                  disabled={isLoading || !isValid}
                  icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                >
                  {t("Save Changes")}
                </Button>
                <Button appearance='transparent' onClick={handleClose}>
                  {t("Cancel")}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </BaseDialog>
      }
    </>
  );
};
