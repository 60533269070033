import { Spinner, Text } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "../../../../Components/Stack";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { format } from "../../../common/utils/signalFormatter";

const PlotContainer = ({ children }: PropsWithChildren) => (
  <Stack
    verticalAlign='center'
    horizontalAlign='center'
    style={{
      height: 511,
      background: "#FFF",
      borderRadius: 2,
    }}
  >
    {children}
  </Stack>
);

export const PlotSpinner = () => {
  const { t } = useTranslation();

  return (
    <PlotContainer>
      <Spinner size='small' label={t("Loading chart...")} />
      <Text style={{ fontSize: 10, display: "block" }}>
        {t("loading time depends on the amount of data")}
      </Text>
    </PlotContainer>
  );
};

export const RawDataPlotNoData = ({ signal }: { signal: ResponseSimplifiedSignal }) => {
  const formattedSignal = format(signal);

  return (
    <PlotContainer>
      <span>
        <Trans i18nKey={"<0>{{formattedSignal}}</0> has no data"} values={{ formattedSignal }}>
          <b>{formattedSignal}</b> has no data
        </Trans>
      </span>
    </PlotContainer>
  );
};
