import { Button, Text, Tooltip } from "@fluentui/react-components";
import { Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { BackIcon } from "@fluentui/react-icons-mdl2";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../../schema/Constants";
import { Status } from "../../../schema/status";
import Breadcrumb from "../../common/Breadcrumb";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import type { Column } from "../../common/Table/v9";
import { useTableFilters } from "../../common/Table/v9";
import Table from "../../common/Table/v9/Table";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { selectProject } from "../../Projects/reducer";
import { EditDialog } from "../../SensorNodes/EditDialog";
import type { WirelessSensorNode } from "../../SensorNodes/models";
import { resetState } from "../../SensorNodes/reducer";
import { Stack } from "../../Stack";
import { DeleteConfirm } from "./DeleteConfirm";
import {
  listAsyncWirelessDetails,
  selectWirelessDetails,
  selectWirelessGatewayDetailsStatus,
} from "./reducer";

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (sensorNode: WirelessSensorNode) => void;
  onDelete: (sensorNode: WirelessSensorNode) => void;
};

const getColumns = ({ t, hasActions, onEdit, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "sensorNodeId",
      name: t("Sensor Node Id"),
      fieldName: "sensorNodeId",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sensorNodeId }: WirelessSensorNode) => (
        <Link to={"../sensorNode/" + id?.toString()} style={linkStyle}>
          {sensorNodeId}
        </Link>
      ),
    },
    {
      key: "deviceClass",
      name: t("Device Class"),
      fieldName: "deviceClass",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "deviceModel",
      name: t("Device Model"),
      fieldName: "deviceModel",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (sensorNode: WirelessSensorNode) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(sensorNode);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(sensorNode);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

export const WirelessGatewayDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const items = useAppSelector(selectWirelessDetails);
  const status = useAppSelector(selectWirelessGatewayDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;
  const proj = useAppSelector(selectProject(items.projectId));

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listAsyncWirelessDetails(id ? id : ""));
  }, [dispatch, id]);

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.serialNumber}</Text>,
    },
  ];
  const { filters, handleSearch } = useTableFilters<WirelessSensorNode>({
    keys: ["deviceClass", "deviceModel", "sensorNodeId"],
  });
  const goBack = () => navigate(-1);

  const [selected, setSelected] = useState<{
    data: WirelessSensorNode | null;
    context: "edit" | "delete";
  } | null>(null);

  const onEdit = (sensorNode: WirelessSensorNode) => {
    setSelected({ data: sensorNode, context: "edit" });
  };

  const onDelete = (sensorNode: WirelessSensorNode) =>
    setSelected({ data: sensorNode, context: "delete" });

  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign='center'>
            <Button appearance='transparent' icon={<BackIcon />} onClick={goBack} />
            {proj ? (
              <>
                <Breadcrumb
                  items={[
                    {
                      key: `corporation-${proj?.corporation.number}`,
                      text: proj?.corporation.name,
                    },
                    {
                      key: `company-${proj?.company.number}`,
                      text: proj?.company.name,
                    },
                    { key: `project-${proj?.name}`, text: proj?.name },
                    {
                      key: `gateway-${items?.serialNumber}`,
                      text: items?.serialNumber,
                    },
                  ]}
                />
              </>
            ) : (
              <Breadcrumb
                items={[
                  {
                    key: `un-parented-gateway`,
                    text: t("Un-parented Gateway"),
                  },
                ]}
              />
            )}
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
            />

            <Table
              persistOpts={{
                key: "table-gatewaysDet",
                version: 2,
              }}
              header={{
                title: t("Sensors"),
              }}
              items={items.sensorNodes}
              v8Columns={getColumns({
                t,
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            <DeleteConfirm
              data={selected?.data}
              show={selected?.context === "delete"}
              onSuccess={(hasError) => {
                if (hasError) {
                  notification.error(
                    t(`Failed deleting {{sensorId}} Sensor node`, {
                      sensorId: selected?.data?.sensorNodeId,
                    }),
                  );
                } else {
                  dispatch(resetState);
                  dispatch(listAsyncWirelessDetails(id ? id : ""));
                  notification.success(
                    t(`{{sensorId}} sensor node deleted successfully`, {
                      sensorId: selected?.data?.sensorNodeId,
                    }),
                  );
                }

                setSelected(null);
              }}
              onClose={() => setSelected(null)}
            />

            {selected?.context === "edit" && selected?.data && (
              <EditDialog
                data={selected?.data}
                show={true}
                onSuccess={(hasError) => {
                  if (hasError) {
                    notification.error(
                      t("Failed when updating sensor node. Please try again later."),
                    );
                  } else {
                    notification.success(t("Sensor node updated successfully."));
                    dispatch(listAsyncWirelessDetails(id ? id : ""));
                  }
                }}
                onClose={function (): void {
                  setSelected(null);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <NotFoundRoute />
      )}
    </>
  );
};
