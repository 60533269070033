import type { DialogProps } from "@fluentui/react-components";
import { DialogContent, DialogTitle, Spinner } from "@fluentui/react-components";
import * as React from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { DialogSize } from "../../../../Components/common/Dialog";

type DownloadDialogProps = Omit<DialogProps, "children"> & {
  onDismiss?: () => void;
  open: boolean;
};

const DownloadDialog: React.FC<DownloadDialogProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <BaseDialog {...rest} surfaceStyle={{ width: DialogSize.M }}>
      <DialogTitle>{t("Downloading")}</DialogTitle>
      <DialogContent>
        <>
          <span>{t("Files are being downloaded...")}</span>
          <Spinner />
        </>
      </DialogContent>
    </BaseDialog>
  );
};

export default DownloadDialog;
