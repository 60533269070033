import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import type { Control, FieldError, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ZodType } from "zod";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { maxLengthType1, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import Combobox from "../common/Combobox";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import { listAsyncCompanies, selectCompaniesStatus } from "../Companies/reducer";
import { selectGatewaysProProjectOptions } from "../Gateways/reducer";
import FormItemRow from "../Generic/FormItemRow";
import { selectMachines } from "../Machines/reducer";
import { addWirelessSensorNode, editWirelessSensorNode } from "./api";
import type {
  RequestWirelessSensorNodeAdd,
  WirelessSensorNode,
  WirelessSensorNodeResponse,
} from "./models";

type AddOrEditDialogProps = Omit<DialogProps, "children" | "open"> & {
  options: any[];
  data: WirelessSensorNodeResponse | null;
  items: WirelessSensorNodeResponse[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: WirelessSensorNodeResponse | RequestWirelessSensorNodeAdd,
    context: "add" | "edit",
  ) => void;
  onClose: () => void;
};

type WirelessAddOrEditDialogFormData = {
  sensorNodeId?: string;
  id?: string;
  deviceModel?: string;
  deviceClass?: string;
  sensorNo?: string;
  sensorDescription?: string;
  mountingType?: string;
  mountingDirection?: string;
  isActive?: boolean;
};

const defaultUuid = "00000000-0000-0000-0000-000000000000";

const getProjectsFields: (t) => FormItemProps[] = (t) => [
  {
    name: "sensorNodeId",
    type: FormItemType.TextField,
    groupProps: { label: t("Sensor Node Id / Sensor Serial No *") },
  },
  {
    name: "deviceModel",
    type: FormItemType.TextField,
    groupProps: { label: t("Device Model *") },
  },
  {
    name: "deviceClass",
    type: FormItemType.TextField,
    groupProps: { label: t("Device Class *") },
  },
  {
    name: "isActive",
    type: FormItemType.Checkbox,
    groupProps: { label: t("Is Active") },
  },
  {
    name: "sensorNo",
    type: FormItemType.TextField,
    groupProps: { label: t("Sensor No") },
  },
  {
    name: "sensorDescription",
    type: FormItemType.TextField,
    groupProps: { label: t("Description") },
  },
  {
    name: "mountingType",
    type: FormItemType.TextField,
    groupProps: { label: t("Mounting Type") },
  },
  {
    name: "mountingDirection",
    type: FormItemType.TextField,
    groupProps: { label: t("Mounting Direction") },
  },
];

const getFiledToRender = (
  t,
  data: WirelessSensorNodeResponse,
  control: Control<WirelessAddOrEditDialogFormData, any>,
  errors: FieldErrors<WirelessSensorNodeResponse>,
): JSX.Element[] => {
  return renderFormItems(data ? getProjectsFields(t).slice(0, 4) : getProjectsFields(t), {
    control,
    errors: errors as { [schemaProp: string]: FieldError },
  });
};

const getSchema = (t, sensorNodes: WirelessSensorNodeResponse[]) =>
  z
    .object({
      id: z.string().optional(),
      sensorNodeId: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Sensor node Id must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      deviceModel: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Device Model must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .optional(),
      deviceClass: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Device Class must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .optional(),
      sensorNo: z.string().optional().nullable(),
      sensorDescription: z.string().optional().nullable(),
      mountingType: z.string().optional().nullable(),
      mountingDirection: z.string().optional().nullable(),
      isActive: z.boolean().optional(),
    })
    .refine(
      (input) => {
        if (!input.sensorNodeId) {
          return true;
        }

        return (
          sensorNodes
            .map(({ sensorNodeId }) => sensorNodeId.trim().toLowerCase())
            .findIndex((value) => value === input.sensorNodeId.trim().toLowerCase()) === -1
        );
      },
      {
        path: ["sensorNodeId"],
        message: t("The Sensor Node Id already exists"),
      },
    );

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ sensorNodeId }) =>
        data ? sensorNodeId.trim().toLowerCase() !== data.sensorNodeId.trim().toLowerCase() : true,
      ),
    [items, data],
  );
  const machines = useAppSelector(selectMachines);
  const schema = useMemo(() => getSchema(t, filteredItems), [t, filteredItems]);

  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [idGatewaySelected, setIdGatewaySelected] = useState<string>(
    data?.wirelessGatewayId || defaultUuid,
  );
  const [idMachSelected, setIdMachSelected] = useState<string>(data?.machineId || defaultUuid);
  const [projectId, setProjectId] = useState<string>(data?.projectId);
  const optionsGateways = useAppSelector(selectGatewaysProProjectOptions(projectId));
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    reset,
  } = useZodForm<ZodType<any, z.ZodTypeDef, WirelessAddOrEditDialogFormData>>({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        id: data.id ? data.id : " ",
      },
    }),
  });

  useEffect(() => {
    const mach = machines?.find((mac) => mac.id === idMachSelected);
    setProjectId(mach?.projectId && mach?.projectId !== defaultUuid ? mach?.projectId : undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMachSelected]);

  useEffect(() => {
    optionsGateways?.some((gat) => gat.key === idGatewaySelected) &&
      setIdGatewaySelected(idGatewaySelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsGateways]);

  useEffect(() => {
    if (!projectId) {
      setIdGatewaySelected(data?.wirelessGatewayId || defaultUuid);
    } else if (projectId !== data?.projectId) {
      setIdGatewaySelected(data?.wirelessGatewayId || defaultUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data
      ? reset({
          id: data.id,
          sensorNodeId: data.sensorNodeId,
          deviceClass: data.deviceClass.toString(),
          deviceModel: data.deviceModel.toString(),
          sensorNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
          isActive: data.isActive,
        })
      : reset({
          id: "",
          deviceClass: "6000",
          sensorNodeId: "",
          deviceModel: "2111",
          sensorNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
          isActive: true,
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: WirelessAddOrEditDialogFormData) => {
    setLoading(true);

    if (data) {
      const toSend: WirelessSensorNode = {
        id: data.id,
        deviceClass: Number(formData.deviceClass),
        deviceModel: Number(formData.deviceModel),
        machineId: idMachSelected,
        sensorNodeId: formData.sensorNodeId,
        isActive: formData.isActive,
      };

      if (idGatewaySelected) {
        toSend.wirelessGatewayId = idGatewaySelected;
      }

      await editWirelessSensorNode(toSend).then((response) =>
        onSuccess("status" in response, toSend, data ? "edit" : "add"),
      );
    } else {
      const toSend: RequestWirelessSensorNodeAdd = {
        wirelessSensorNode: {
          deviceClass: Number(formData.deviceClass),
          deviceModel: Number(formData.deviceModel),
          machineId: idMachSelected,
          sensorNodeId: formData.sensorNodeId,
          isActive: formData.isActive,
        },
        sensor: {
          machineId: idMachSelected,
          sensorSerialNo: formData.sensorNodeId,
          sensorNo: formData.sensorNo,
          sensorDescription: formData.sensorDescription,
          mountingType: formData.mountingType,
          mountingDirection: formData.mountingDirection,
        },
      };

      await addWirelessSensorNode(toSend).then((response) =>
        onSuccess("status" in response, toSend, data ? "edit" : "add"),
      );
    }

    !idGatewaySelected && !idMachSelected
      ? notification.warning(t("Un-parented sensor node"))
      : !idMachSelected && notification.warning(t("Sensor node, non machine linked yet."));
    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{data ? t("Edit Sensor Node") : t("Add Sensor Node")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormItemRow label='Machine'>
            <Combobox
              options={options}
              value={idMachSelected}
              onChange={(key) => setIdMachSelected(`${key}`)}
            />
          </FormItemRow>
          {optionsGateways.length > 0 && (
            <FormItemRow label='Gateway'>
              <Combobox
                options={optionsGateways}
                value={idGatewaySelected}
                onChange={(key) => setIdGatewaySelected(`${key}`)}
              />
            </FormItemRow>
          )}
          {getFiledToRender(t, data, control, errors)
            .slice(0, 3)
            .map((ele) => ele)}
          {!data && <Text style={titleStyle}>{t("Sensor")}:</Text>}
          {getFiledToRender(t, data, control, errors)
            .slice(3)
            .map((ele) => ele)}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isValid || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
