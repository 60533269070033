import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";

import { useLocationSearch } from "../../../../Hooks";
import type { Condition } from "../../../../modules/machine-cv/constants";
import { conditionColor } from "../../../../modules/machine-cv/constants";
import { Stack } from "../../../Stack";
import SectionContainer from "../SectionContainer";
import { SummaryBadge } from "../SummaryBadge";
import useConditionsOverview from "./hooks";

const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  width: "100%",
  borderRadius: "4px",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export const ConditionsSection = ({ ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();

  const { data, isLoading } = useConditionsOverview({ machineId: id as string }, { cacheTime: 0 });

  const hasNoConditionItems = isEmpty(data?.conditions);

  return (
    <SectionContainer
      {...rest}
      title={t("Conditions")}
      isLoading={isLoading}
      noData={hasNoConditionItems}
    >
      <div className='condition-content-badge-container'>
        {data?.conditions?.map((currentCondition) => (
          <Stack
            key={currentCondition.id}
            horizontal
            horizontalAlign='center'
            verticalAlign='center'
            style={{
              ...badgeContainerStyles,
              backgroundColor: conditionColor[currentCondition.value as Condition],
            }}
          >
            <SummaryBadge description={currentCondition.name} value={currentCondition.value} />
          </Stack>
        ))}
      </div>
    </SectionContainer>
  );
};
