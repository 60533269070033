import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMultipleTrendMeasuredDataInAPeriod } from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import getPeriodFilters from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import type { DataPoint } from "../../../../../types";
import usePerformanceStore from "../../hooks/usePerformanceStore";
import { getWorstBestCurrentPerformance } from "../Statistics/methods";
import PerformanceChart from "./PerformanceChart";
import PerformanceIndices from "./PerformanceIndices";
import PerformanceModal from "./PerformanceModal";

const LoadAndPerformance = ({ signals, performance }: any) => {
  const { t } = useTranslation();
  const { period } = usePerformanceStore((store: any) => ({
    period: store.period,
  }));

  const allPerformanceSignals = useMemo(() => {
    const perfSignal = signals.find(({ id }: any) => id === performance.perfSignalId);
    const perfTendSignal = signals.find(({ id }: any) => id === performance.perfTendSignalId);

    if (!perfSignal && !perfTendSignal) {
      return [];
    }

    return [perfSignal, perfTendSignal].map((signal) => ({
      ...signal,
      unit: "(%)",
    }));
  }, [signals, performance.perfSignalId, performance.perfTendSignalId]);

  const signalsToRequest = useMemo(
    () =>
      allPerformanceSignals.map((signal) => {
        const periodFilters = getPeriodFilters(period, signal);

        return {
          queryKey: [
            "performance-indicator-trend",
            signal?.id,
            periodFilters.startDate,
            periodFilters.endDate,
            "off",
          ],
          machineId: signal?.machineId,
          signalId: signal?.id,
          startDate: periodFilters.startDate,
          endDate: periodFilters.endDate,
          numberOfPoints: CHART_NUMBER_OF_POINTS,
          signalType: signal?.dataType,
          refreshInterval: { key: "off", text: t("Off") },
          options: {
            enabled: !!signal?.id,
            retry: false,
          },
        };
      }),
    [allPerformanceSignals, period, t],
  );

  const signalsResponses = useMultipleTrendMeasuredDataInAPeriod(signalsToRequest);

  const { isLoading, hasData } = useMemo(() => {
    const isLoading = signalsResponses.some((resp) => resp?.isLoading && resp?.isFetching);
    const hasData = signalsResponses.filter(({ data }) => !!data).length > 0;

    return {
      isLoading,
      hasData,
    };
  }, [signalsResponses]);

  const data = useMemo(() => {
    return signalsResponses.map(
      (resp) =>
        ((resp?.data as DataPoint[]) || []).map((item: DataPoint) => ({
          ...item,
          timeStamp: new Date(item.timeStamp).getTime(),
        })) || [],
    );
  }, [hasData, isLoading, period?.key]);

  const { bestPerformance, worstPerformance } = getWorstBestCurrentPerformance(data[0]);

  (data[0] || []).forEach((signal: any) => {
    if (signal?.value === bestPerformance?.value) {
      signal.isBest = true;
    }

    if (signal?.value === worstPerformance?.value) {
      signal.isWorst = true;
    }
  });

  const machineOverallPerformance = getWorstBestCurrentPerformance(data[0]);
  const indicesProps = {
    ...machineOverallPerformance,
    isLoading,
    noData: !isLoading && !hasData,
  };

  const chartProps = {
    data,
    signals: allPerformanceSignals,
    isLoading,
    noData: !isLoading && !hasData,
  };

  const performanceModalProps = {
    isLoading,
    data: data[0],
    bestPerformance,
    performance,
  };

  return (
    <div className='load-and-performance' style={{ height: "calc(50vh - 70px)", minHeight: 420 }}>
      <PerformanceIndices {...indicesProps} />
      <PerformanceChart {...chartProps} />
      <PerformanceModal {...performanceModalProps} />
    </div>
  );
};

export default LoadAndPerformance;
