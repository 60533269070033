import { Button, Spinner } from "@fluentui/react-components";
import { BroomIcon, RefreshIcon, Trending12Icon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";

import { useWebAnalysisPermissions } from "../../../../Hooks";
import { notification } from "../../../common/Notification";
import { useClearSensorState } from "../../hooks/useSensorRequests";
import { useSensorManualTriggering } from "../../hooks/useSensorTriggering";
import useSensorStore from "./hooks/useSensorStore";
import { TRIGGERS_TAB } from "./SensorTabs";
import ManualTriggerSettings from "./TriggersTab/ManualTriggerSettings";

const SensorHeader = ({ sensor, activeTab }: any) => {
  const { t } = useTranslation();
  const [isManualTriggerOpen, setIsManualTriggerOpen] = useState(false);
  const sensorNodeId = sensor ? sensor.sensorNodeId : null;

  const { updateSensorManualTriggering } = useSensorManualTriggering({
    sensorId: sensor?.sensorNodeId,
  });

  const { triggers, settings } = useSensorStore(
    (store: any) => ({
      triggers: store.triggers,
      settings: store.settings,
    }),
    shallow,
  );

  const {
    clearState,
    isLoading: isLoadingClearSensorState,
    isError,
  } = useClearSensorState({ sensorNodeId });

  const { useReadyPermissionGeneral } = useWebAnalysisPermissions();
  const hasWirelessSensorReaderPermissions = useReadyPermissionGeneral({
    permissionRoles: ["WirelessSensorReader", "WirelessSensorContributor"],
  });

  useEffect(() => {
    if (isError) {
      notification.error(t("There was a problem with the request."));
    }
  }, [isError, t]);

  const { loading: triggersLoading, refetch: triggersRefetch }: any = sensorNodeId
    ? triggers[sensorNodeId] || {}
    : {};
  const { loading: settingsLoading, refetch: settingsRefetch }: any = sensorNodeId
    ? settings[sensorNodeId] || {}
    : {};

  const isDisabled = activeTab === TRIGGERS_TAB ? triggersLoading : settingsLoading;

  const refresh = () => {
    if (isDisabled) return;

    if (activeTab === TRIGGERS_TAB) {
      triggersRefetch && triggersRefetch();
    } else {
      settingsRefetch && settingsRefetch();
    }
  };

  const broom = () => {
    if (isDisabled) return;
    clearState();
    if (activeTab === TRIGGERS_TAB) {
      triggersRefetch && triggersRefetch();
    } else {
      settingsRefetch && settingsRefetch();
    }
  };

  const handleManualTrigger = async (selectedKeys: string[]) => {
    try {
      await updateSensorManualTriggering(selectedKeys);
      notification.success(t("Sensor has been triggered successfully"));
    } catch (error) {
      notification.error(t("Manual trigger error"));
      throw error; // Re-throw to let ManualTriggerSettings handle the error state
    }
  };

  return (
    <>
      <div className='sensor-header'>
        <span className='sensor-title'>
          {sensor
            ? sensor.sensorNo && sensor.sensorNo.trim() !== "" && hasWirelessSensorReaderPermissions
              ? sensor.sensorNo + " (" + sensor.sensorNodeId + ") "
              : sensor.sensorNodeId
            : ""}
        </span>
        <Button
          style={{ marginLeft: 8 }}
          appearance='subtle'
          disabled={isDisabled}
          icon={<RefreshIcon />}
          onClick={refresh}
        />
        {isLoadingClearSensorState ? (
          <Spinner size='tiny' />
        ) : (
          <>
            <Button
              appearance='subtle'
              disabled={isDisabled}
              icon={<BroomIcon />}
              onClick={broom}
            />
            <Button
              appearance='subtle'
              disabled={isDisabled}
              icon={<Trending12Icon />}
              onClick={() => setIsManualTriggerOpen(true)}
            />
          </>
        )}
      </div>

      <ManualTriggerSettings
        isOpen={isManualTriggerOpen}
        trendAndRaw={true}
        onDismiss={() => setIsManualTriggerOpen(false)}
        onSubmit={handleManualTrigger}
      />
    </>
  );
};

export default SensorHeader;
