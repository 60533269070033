import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

type ErrorDialogProps = {
  open: boolean;
  content: string;
  onDismiss: () => void;
};

export const ErrorDialog = ({ open, content, onDismiss }: ErrorDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("Attention!")}</DialogTitle>
          <DialogContent>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </DialogContent>

          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance='secondary' onClick={onDismiss}>
                {t("Close")}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
