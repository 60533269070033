import { Button, Spinner } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useBoolean } from "../../../../../Hooks";
import { getApiClient } from "../../../../../modules/core/apiClient/useApiStore";
import { useZodForm } from "../../../../common/Form";
import { notification } from "../../../../common/Notification";
import { Stack } from "../../../../Stack";
import {
  useSensorMultipleSettingsUpdate,
  useSensorSettings,
  useSensorSettingsUpdate,
} from "../../../hooks/useSensorRequests";
import { WS_SIDEBAR_QUERY_KEY } from "../../../hooks/useWSSidebarData";
import useSensorStore from "../hooks/useSensorStore";
import SensorSelectDialog from "../SensorSelectDialog";
import { mapToProjectSelectableByMachineId } from "../TriggersTab";
import type { ResponseWirelessOverview } from "../types";
import { generateDefaultMeasurementSettingsValues, measurementSchema } from "./config";
import SettingsForm from "./SettingsForm";

const SettingsTab = ({ sensor }: any) => {
  const { t } = useTranslation();
  const { updateSettings } = useSensorStore((store: any) => ({
    updateSettings: store.updateSettings,
  }));
  const [expandedSettings, setExpandedSettings] = useState<string[]>([]);
  const [activeSettings] = useState(["MeasSet0", "MeasSet1", "MeasSet4"]);
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);

  const defaultValues = generateDefaultMeasurementSettingsValues(activeSettings);
  const { sensorNodeId, serialNumber } = sensor;
  const form = useZodForm({
    schema: measurementSchema,
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const {
    data: dataSettings,
    syncUpdatedData,
    refetch,
  } = useSensorSettings({
    activeSettings,
    sensorNodeId,
    settingsToLoad: expandedSettings,
    options: {
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  });

  const { updateSensorSettings, isLoading: isUpdating } = useSensorSettingsUpdate({
    sensorNodeId,
    serialNumber,
  });
  const { updateMultipleSensorSettings, isLoading: loadingMultiple } =
    useSensorMultipleSettingsUpdate();

  const { data: overview = {} as ResponseWirelessOverview } = useQuery([WS_SIDEBAR_QUERY_KEY], () =>
    getApiClient()
      .get(`/meta/read/internal/v1/wireless/overview`)
      .then(({ data }) => data as ResponseWirelessOverview),
  );

  const [options] = useState(
    mapToProjectSelectableByMachineId(t, overview.corporations || [], sensor.machine.id),
  );

  const onSubmit = handleSubmit((data) => {
    if (isUpdating) return;
    const preparedDataToSend: any = {};
    data.measurement.forEach((item, idx) => {
      preparedDataToSend[activeSettings[idx]] = {
        ...item,
        shouldBeUpdated:
          expandedSettings.includes(activeSettings[idx]) &&
          JSON.stringify(item) !== JSON.stringify(dataSettings[activeSettings[idx]]),
      };
    });

    updateSensorSettings({
      sensorNodeId,
      serialNumber,
      data: preparedDataToSend,
    })
      .then(
        () => {
          syncUpdatedData(data.measurement);
          notification.success(
            t(`The settings changes have been saved on {{sensorNodeId}}`, {
              sensorNodeId,
            }),
          );
        },
        () => {
          notification.error(
            t(`The settings changes could not be saved on {{sensorNodeId}}`, {
              sensorNodeId,
            }),
          );
        },
      )
      .finally(() => refetch());
  });

  const handleMultipleSensorsSave = (selectedSensors: string[]) => {
    handleSubmit((data) => {
      if (loadingMultiple) return;
      const preparedDataToSend: any = {};
      data.measurement.forEach((item, idx) => {
        preparedDataToSend[activeSettings[idx]] = {
          ...item,
          shouldBeUpdated: expandedSettings.includes(activeSettings[idx]),
        };
      });

      updateMultipleSensorSettings({
        sensorNodeIds: selectedSensors,
        data: preparedDataToSend,
      }).then(
        () => {
          notification.success(
            t(`The settings changes have been saved on {{sensorNodeId}}`, {
              sensorNodeId,
            }),
          );
        },
        () => {
          notification.error(
            t(`The settings changes could not be saved on {{sensorNodeId}}`, {
              sensorNodeId,
            }),
          );
        },
      );
      hidePopup();
    })();
  };

  const unsavedChanges = isDirty;

  useEffect(() => {
    updateSettings({ sensorNodeId, updates: { refetch } });
  }, [refetch, sensorNodeId]);

  const handleCollapseToggle = (setting: string, isExpanded: boolean) => {
    setExpandedSettings((prev) => {
      if (isExpanded) {
        if (!prev.includes(setting)) {
          return [...prev, setting];
        }
      } else {
        return prev.filter((s) => s !== setting);
      }
      return prev;
    });
  };
  return (
    <>
      <div className={classNames("tab-wrapper", { loading: isUpdating })}>
        {isUpdating && <Spinner size='small' label={t("Saving settings data")} />}
        {unsavedChanges && (
          <div className='sensor-alert-label' style={{ marginBottom: 20 }}>
            {t("Please save the changes before leaving the settings tab")}
          </div>
        )}
        <div className='tab-subtitle'>{t("Measurement Settings")}</div>
        <SettingsForm
          handleCollapseToggle={handleCollapseToggle}
          activeSettings={activeSettings}
          expandedSettings={expandedSettings}
          form={form}
          sensorNodeId={sensorNodeId}
          onSubmit={handleSubmit}
        />
        <div className='sensor-save-button'>
          <Stack horizontal style={{ gap: 20 }}>
            <Button appearance='primary' disabled={isUpdating} onClick={onSubmit}>
              {t("Save to sensor")}
            </Button>
            <Button appearance='primary' onClick={showPopup}>
              {t("Select sensors for saving to")}
            </Button>
          </Stack>
        </div>
      </div>
      {options && (
        <SensorSelectDialog
          isVisible={isPopupVisible}
          options={options}
          isLoading={loadingMultiple}
          sensorNodeId={sensorNodeId}
          onDismiss={hidePopup}
          onSave={handleMultipleSensorsSave}
        />
      )}
    </>
  );
};

export default SettingsTab;
