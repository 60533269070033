import {
  Button,
  DialogActions,
  DialogContent,
  Field,
  Label,
  Spinner,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import Combobox from "../common/Combobox";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import { notification } from "../common/Notification";
import { listAsyncCompanies, selectCompaniesStatus } from "../Companies/reducer";
import { selectGatewaysProProjectOptions } from "../Gateways/reducer";
import { selectMachines } from "../Machines/reducer";
import type { wirelessBulkNotCommon, wirelessBulkProps } from "./api";
import { editBulkWirelessSensorNode } from "./api";
import type { WirelessSensorNodeResponse } from "./models";

type EditBDialogProps = {
  sensorNodeIds: wirelessBulkNotCommon[];
  options: any[];
  data: WirelessSensorNodeResponse | null;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const EditBulkDialog = ({
  sensorNodeIds,
  options,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditBDialogProps) => {
  const { t } = useTranslation();
  const machines = useAppSelector(selectMachines);
  const [keyModal1, setKeyModal1] = useState<number>(100);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [idGatewaySelected, setIdGatewaySelected] = useState<any>(
    data && data.wirelessGatewayId !== "00000000-0000-0000-0000-000000000000"
      ? data.wirelessGatewayId
      : undefined,
  );
  const [idMachSelected, setIdMachSelected] = useState<any>(
    data && data.machineId !== "00000000-0000-0000-0000-000000000000" ? data.machineId : undefined,
  );
  const [ProjId, setProjId] = useState<string>(data?.projectId);
  const optionsGateways = useAppSelector(selectGatewaysProProjectOptions(ProjId));

  useEffect(() => {
    const mach = machines?.find((mac) => mac.id === idMachSelected);
    setProjId(
      mach?.projectId && mach?.projectId !== "00000000-0000-0000-0000-000000000000"
        ? mach?.projectId
        : undefined,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMachSelected]);

  useEffect(() => {
    optionsGateways?.some((gat) => gat.key === idGatewaySelected) &&
      setIdGatewaySelected(idGatewaySelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsGateways]);

  useEffect(() => {
    setKeyModal1(keyModal1 + 1);
    !ProjId && setIdGatewaySelected(undefined);
    ProjId !== data?.projectId && setIdGatewaySelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProjId]);

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  const handleSubmit = async () => {
    setLoading(true);
    const toSend: wirelessBulkProps = {
      sensorIds: sensorNodeIds,
      editWireless: {
        wirelessGatewayId: idGatewaySelected,
        machineId: idMachSelected,
      },
    };

    await editBulkWirelessSensorNode(toSend).then((response) => onSuccess("status" in response));
    !idGatewaySelected && !idMachSelected
      ? notification.warning(t("Un-parented sensor nodes"))
      : !idMachSelected && notification.warning(t("Sensor nodes, non machine linked yet."));
    handleClose();
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{data ? t("Edit sensor Node") : t("Add sensor Node")}</BaseDialogTitle>
      <DialogContent>
        <Field>
          <Label>{t("Select machine:")}</Label>
          <Combobox
            options={options}
            value={idMachSelected}
            onChange={(key) => setIdMachSelected(key)}
          />
        </Field>

        {optionsGateways.length > 0 && (
          <Field>
            <Label>{t("Select Gateway:")}</Label>
            <Combobox
              options={optionsGateways}
              value={data?.wirelessGatewayId || ""}
              onChange={(key) => setIdGatewaySelected(key)}
            />
          </Field>
        )}

        <DialogActions>
          <Button
            appearance='primary'
            type='submit'
            disabled={isLoading}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleSubmit}
          >
            {t("Save Changes")}
          </Button>
          <Button appearance='transparent' onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
