import type { Column } from "../../../../common/Table/v9";

import type { SensorError } from ".";

// type GetColumnsOpts = {
//   onRemove?: (id: string) => void;
// };

export const getColumns = (t): Column[] => [
  {
    key: "gatewayId",
    fieldName: "gatewayId",
    name: t("Gateway Id"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "sensorNodeId",
    fieldName: "sensorNodeId",
    name: t("Sensor Node Id"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "timestamp",
    fieldName: "timestamp",
    name: t("Time stamp"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "selfTestErrorCodes",
    fieldName: "selfTestErrorCodes",
    name: t("Error Codes"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ selfTestErrorCodes }: SensorError) => {
      return <div>{selfTestErrorCodes.join("; ")}</div>;
    },
  },
];
