import { Text } from "@fluentui/react-components";
import type { CSSProperties, PropsWithChildren } from "react";
import React from "react";

import { Stack } from "../../../Stack";

export type ItemPropertySectionProps = {
  title: string;
};

export type FormItemRowProps = {
  label: string;
};

/**
 * Gets a form item section component
 * @param title The section title.
 * @param children The children components.
 * @returns The Item section component.
 */
export const FormItemSection = ({
  title,
  children,
}: PropsWithChildren<ItemPropertySectionProps>) => {
  return (
    <section>
      <h3 className='section-title' style={{ padding: "0.6em 0em 0.2em 0em" }}>
        {title}
      </h3>
      <div style={{ paddingLeft: "0.25em" }}>{children}</div>
    </section>
  );
};

/**
 * Gets a form item row component
 * @param label The label text.
 * @param children The input element(s)
 * @returns The form item row component.
 */
export const FormItemRow = ({ label, children }: PropsWithChildren<FormItemRowProps>) => {
  const containerStyle: CSSProperties = {
    marginTop: "8px",
    marginBottom: "8px",
  };

  const labelStyle: CSSProperties = {
    minWidth: "160px",
    marginRight: "8px",
  };

  const inputElementStyle: CSSProperties = { width: "100%" };

  return (
    <Stack horizontal horizontalAlign='space-between' style={containerStyle}>
      <Stack.Item style={labelStyle}>
        <Text size={300}>{label}</Text>
      </Stack.Item>
      <Stack.Item style={inputElementStyle}>{children}</Stack.Item>
    </Stack>
  );
};
