import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { BookAnswersIcon, EditIcon, MailIcon } from "@fluentui/react-icons-mdl2";
import type { AxiosInstance } from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { notification } from "../../../common/Notification";
import type { Column, Filter } from "../../../common/Table/v9";
import Table from "../../../common/Table/v9";
import { Stack } from "../../../Stack";
import { VpnConnectionsAPI } from "../../Schema/api";
import type {
  ConnectionEntryDetailed,
  ConnectionTableItem,
  MetadataProject,
} from "../../Schema/models";
import type {
  ConnectionActionDialogBaseProps,
  ConnectionItemDialogProps,
} from "../../Schema/viewModels";
import { ConnectionsActionsList } from "../../Schema/viewModels";
import { Utils } from "../../Utils/utils";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import LoadingPanel from "../generic/LoadingPanel";
import HistoricalChangesListDialog from "../historicalChanges/HistoricalChangesListDialog";
import { EditConnectionDialog } from "./AddEditConnectionDialog";
import ConnectionDetailsDialog from "./ConnectionDetailsDialog";
import DeleteConnectionDialog from "./DeleteConnectionDialog";
import EmailDetailsDialog from "./EmailDetailsDialog";

type TableColumnProps = {
  t: any;
  hasAdminPermissions: boolean;
  onEmailDetailsHandler: (entry: ConnectionsTableItem) => void;
  onDetailsHandler: (entry: ConnectionsTableItem) => void;
  onEditHandler: (entry: ConnectionsTableItem) => void;
  onDeleteHandler: (entry: ConnectionsTableItem) => void;
  onHistoricalChangesHandler: (entry: ConnectionsTableItem) => void;
};

export type ConnectionsTableItem = {
  id: string;
  projectName: string;
  companyName: string;
  automationStatus: string;
  connectionStatus: string;
  comments?: string;
};

export type ConnectionsListProps = {
  hasAdminPermissions: boolean;
  projects: MetadataProject[];
  filters: Filter[] | undefined;
  tableItems: ConnectionsTableItem[];
  onConnectionDataChanged: () => void;
};

type ColoredCellProps = {
  text: string;
  color: string;
};

/**
 * Gets a colored cell component
 * @param text the displayed text.
 * @param color the color text.
 * @returns The colored cell component.
 */
const ColoredCell = ({ text, color }: ColoredCellProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        margin: "auto",
        backgroundColor: color,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className='table-item-text'>{text}</span>
    </div>
  );
};

/**
 * Gets the basic columns for the table.
 * @param onDetails Method called when the project action button is clicked.
 * @returns The columns array.
 */
const getTableDataColumns = (t, onDetails: (item: ConnectionsTableItem) => void): Column[] => {
  const result: Column[] = [
    {
      key: "projectName",
      name: t("Project"),
      fieldName: "projectName",
      minWidth: 200,
      isSortable: true,
      isPadded: true,
      onRender: (item: ConnectionsTableItem) => (
        <Tooltip
          withArrow
          relationship='label'
          content={t(`Show VPN connection details for the {{projectName}} project`, {
            projectName: item.projectName,
          })}
        >
          <Button
            key={item.id + "projectNameButton"}
            appearance='transparent'
            className='table-action-button'
            onClick={() => onDetails(item)}
          >
            {item.projectName}
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "companyName",
      name: t("Company"),
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "automationStatus",
      name: t("Automation Status"),
      fieldName: "automationStatus",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "connectionStatus",
      name: t("Connection Status"),
      fieldName: "connectionStatus",
      minWidth: 120,
      isSortable: true,
      onRender: (item: ConnectionTableItem) => (
        <ColoredCell
          text={item.connectionStatus}
          color={Utils.getConnectionStatusColor(item.connectionStatus)}
        />
      ),
    },
    {
      key: "comments",
      name: t("Comments"),
      fieldName: "comments",
      minWidth: 300,
      isSortable: false,
      isMultiline: true,
      isPadded: true,
    },
  ];

  return result;
};

/**
 * Gets the connections table columns.
 * @param onEmailDetailsHandler Method called when the email details action button is clicked.
 * @param onDetailsHandler Method called when the details action button is clicked.
 * @param onEditHandler Method called when the edit action button is clicked.
 * @param onDeleteHandler Method called when the delete action button is clicked.
 * @param onHistoricalChangesHandler Method called when the historical changes action button is clicked.
 * @returns
 */
const getTableColumns = ({
  t,
  hasAdminPermissions,
  onEmailDetailsHandler,
  onDetailsHandler,
  onEditHandler,
  onDeleteHandler,
  onHistoricalChangesHandler,
}: TableColumnProps): Column[] => {
  const result: Column[] = getTableDataColumns(t, onDetailsHandler);
  const actionColumns: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 150,
    onRender: (item: ConnectionsTableItem) => (
      <Stack horizontal>
        <Tooltip withArrow relationship='label' content={t("Show customer email contacts")}>
          <Button
            key={item.id + "customerEmailContacts"}
            appearance='transparent'
            className='table-icon-button'
            aria-label={t("Show customer email contacts")}
            icon={<MailIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onEmailDetailsHandler(item);
            }}
          />
        </Tooltip>

        <Tooltip withArrow relationship='label' content={t("Edit VPN connection")}>
          <Button
            key={item.id + "edit"}
            appearance='transparent'
            className='table-icon-button'
            aria-label={t("Edit VPN connection")}
            icon={<EditIcon />}
            onClick={() => onEditHandler(item)}
          />
        </Tooltip>
        {hasAdminPermissions && (
          <Tooltip withArrow relationship='label' content={t("Delete VPN connection")}>
            <Button
              key={item.id + "delete"}
              appearance='transparent'
              className='table-icon-button'
              aria-label={t("Delete VPN connection")}
              icon={<DeleteRegular />}
              onClick={() => onDeleteHandler(item)}
            />
          </Tooltip>
        )}
        {hasAdminPermissions && (
          <Tooltip
            withArrow
            relationship='label'
            content={t("Show VPN connection details historical changes")}
          >
            <Button
              key={item.id + "historicalChanges"}
              appearance='transparent'
              className='table-icon-button'
              aria-label={t("Show VPN connection details historical changes")}
              icon={<BookAnswersIcon />}
              onClick={() => onHistoricalChangesHandler(item)}
            />
          </Tooltip>
        )}
      </Stack>
    ),
  };

  result.push(actionColumns);
  return result;
};

/**
 * Gets a VPN connection entry details.
 * @param axiosInstance The axios instance.
 * @param id The VPN Connection id.
 * @returns The connection detailed entry if exists. Otherwise, null.
 */
const getItemDetails = async (
  t,
  axiosInstance: AxiosInstance,
  id: string,
): Promise<ConnectionEntryDetailed | null> => {
  const response = await VpnConnectionsAPI.get(axiosInstance, id);
  if (response.status !== 200) {
    notification.error(
      t(`Failure getting the VPN connection details: {{statusText}}.`, {
        statusText: response.statusText,
      }),
    );

    return null;
  }

  return response.data as ConnectionEntryDetailed;
};

/**
 * Gets the item action dialog component, according to an action selection
 * @param item The selected VPN Connections detailed item.
 * @param action The selected action.
 * @param companies The companies list.
 * @param projects The projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The current dialog component. Or null if there is no selected item.
 */
const ItemActionDialog = ({ item, action, projects, onClose }: ConnectionItemDialogProps) => {
  const [selectedItem, setSelectedItem] = useState<ConnectionEntryDetailed | null>(item || null);
  const [selectedAction, setSelectedAction] = useState<ConnectionsActionsList>(action);

  // Sets the selected item and action.
  useEffect(() => {
    if (!item) {
      return;
    }

    setSelectedItem(item);
    if (action === ConnectionsActionsList.None) {
      return;
    }

    setSelectedAction(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  // Selects the dialog.
  let result: JSX.Element | null = null;
  if (!selectedItem || selectedAction === ConnectionsActionsList.None) {
    return result;
  }

  // Function called when the Edit button on the Connection details dialog is clicked.
  const onEditEntryHandler = (data: ConnectionEntryDetailed) => {
    if (!data) {
      return;
    }

    setSelectedItem(data);
    setSelectedAction(ConnectionsActionsList.Edit);
  };

  switch (selectedAction) {
    case ConnectionsActionsList.EmailDetails:
      result = (
        <EmailDetailsDialog open={true} item={selectedItem} projects={projects} onClose={onClose} />
      );
      break;

    case ConnectionsActionsList.ConnectionDetails:
      result = (
        <ConnectionDetailsDialog
          open={true}
          item={selectedItem}
          projects={projects}
          onEdit={onEditEntryHandler}
          onClose={onClose}
        />
      );
      break;

    case ConnectionsActionsList.Edit:
      result = (
        <EditConnectionDialog
          open={true}
          item={selectedItem}
          projects={projects}
          onClose={onClose}
        />
      );
      break;
  }

  return result;
};

/**
 * Gets the table item action dialog component.
 * @param item The selected connection table item.
 * @param action The selected action.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The table item action dialog component.
 */
const TableItemActionDialog = ({
  item,
  action,
  onClose,
}: ConnectionActionDialogBaseProps<ConnectionTableItem>) => {
  let result: JSX.Element | null = null;
  if (!item) {
    return result;
  }

  switch (action) {
    case ConnectionsActionsList.HistoricalChanges:
      result = <HistoricalChangesListDialog open={true} item={item} onClose={onClose} />;
      break;

    case ConnectionsActionsList.Delete:
      result = <DeleteConnectionDialog open={true} item={item} onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the Vpn connection list component
 * @param entries The VPN connections entries list.
 * @returns The VPN connection list component.
 */
const ConnectionsList = ({
  hasAdminPermissions,
  projects,
  filters,
  tableItems,
  onConnectionDataChanged,
}: ConnectionsListProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState<ConnectionTableItem | null>(null);
  const [selectedItem, setSelectedItem] = useState<ConnectionEntryDetailed | null>(null);
  const [selectedAction, setSelectedAction] = useState<ConnectionsActionsList>(
    ConnectionsActionsList.None,
  );

  const onEmailDetailsHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    const item = await getItemDetails(t, axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(t("Error getting Email details. Please try again later."));
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.EmailDetails);
  };

  const onDetailsHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    const item = await getItemDetails(t, axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(t("Error getting connection details. Please try again later."));
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.ConnectionDetails);
  };

  const onDeleteHandler = async (entry: ConnectionsTableItem) => {
    setSelectedTableItem(entry);
    setSelectedAction(ConnectionsActionsList.Delete);
  };

  const onEditHandler = async (entry: ConnectionsTableItem) => {
    setIsLoading(true);
    const item = await getItemDetails(t, axiosInstance!, entry.id);
    setIsLoading(false);
    if (!item) {
      notification.error(t("Error getting connection details. Please try again later."));
      return;
    }

    setSelectedItem(item);
    setSelectedAction(ConnectionsActionsList.Edit);
  };

  const onHistoricalChangesHandler = (entry: ConnectionsTableItem) => {
    setSelectedTableItem(entry);
    setSelectedAction(ConnectionsActionsList.HistoricalChanges);
  };

  const onCloseHandler = (listChanged?: boolean) => {
    listChanged && onConnectionDataChanged?.();
    setSelectedAction(ConnectionsActionsList.None);
    setSelectedItem(null);
    setSelectedTableItem(null);
  };

  return (
    <Stack style={{ padding: 8 }}>
      <Table
        persistOpts={{
          key: "table-vpn-connections",
          version: 2,
        }}
        header={{ title: t("VPN Connections") }}
        items={tableItems}
        v8Columns={getTableColumns({
          t,
          hasAdminPermissions,
          onEmailDetailsHandler,
          onDetailsHandler,
          onEditHandler,
          onDeleteHandler,
          onHistoricalChangesHandler,
        })}
        filters={filters}
      />
      {selectedItem && (
        <ItemActionDialog
          item={selectedItem}
          action={selectedAction}
          projects={projects}
          onClose={onCloseHandler}
        />
      )}
      {selectedTableItem && (
        <TableItemActionDialog
          item={selectedTableItem}
          action={selectedAction}
          onClose={onCloseHandler}
        />
      )}
      {isLoading && <LoadingPanel />}
    </Stack>
  );
};

export default ConnectionsList;
