import { Button, Text } from "@fluentui/react-components";
import { BackIcon, RefreshIcon } from "@fluentui/react-icons-mdl2";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { pageStyle, titleStyle } from "../../../schema/Constants";
import { Status } from "../../../schema/status";
import { format } from "../../../schema/Utils";
import Breadcrumb from "../../common/Breadcrumb";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import type { Column } from "../../common/Table/v9";
import Table, { useTableFilters } from "../../common/Table/v9";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import type { ResponseSimplifiedSignal } from "../../Machines/models";
import { selectProject } from "../../Projects/reducer";
import { Stack } from "../../Stack";
import {
  listAsyncSensorNodeDetails,
  selectSensorNodeDetails,
  selectSensorNodeDetailsStatus,
} from "./reducer";

const getColumns = (): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 100,
      isSortable: true,
      onRender: ({ dataFrom }: ResponseSimplifiedSignal) => dataFrom && format(new Date(dataFrom)),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 100,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseSimplifiedSignal) =>
        dataUntil && format(new Date(dataUntil)),
    },
    {
      key: "sampleRateInHz",
      name: t("Sample Rate In Hz"),
      fieldName: "sampleRateInHz",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "unit",
      name: t("unit"),
      fieldName: "unit",
      minWidth: 50,
      isSortable: true,
    },
    {
      key: "configName",
      name: t("Config Name"),
      fieldName: "configName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorNo",
      name: t("Sensor No"),
      fieldName: "sensorNo",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "signalDescription",
      name: t("Signal Description"),
      fieldName: "signalDescription",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalGroup",
      name: t("Signal Group"),
      fieldName: "signalGroup",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalType",
      name: t("Signal Type"),
      fieldName: "signalType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "feature",
      name: t("Feature"),
      fieldName: "feature",
      minWidth: 150,
      isSortable: true,
    },
  ];

  return columns;
};

export const SensorNodeDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listAsyncSensorNodeDetails(id ? id : ""));
  }, [id, dispatch]);
  const navigate = useNavigate();
  const items = useAppSelector(selectSensorNodeDetails);
  const status = useAppSelector(selectSensorNodeDetailsStatus);
  const proj = useAppSelector(selectProject(items.wirelessGateway?.projectId));
  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.sensorNodeId}</Text>,
    },
    {
      key: "refresh",
      text: t("Refresh"),
      type: CommandBarItemType.Button,
      icon: <RefreshIcon />,
      onClick: () => {
        dispatch(listAsyncSensorNodeDetails(id ? id : ""));
      },
    },
  ];
  const { filters, handleSearch } = useTableFilters<ResponseSimplifiedSignal>({
    keys: [
      "name",
      "dataFrom",
      "dataUntil",
      "configName",
      "feature",
      "sensorNo",
      "signalDescription",
      "signalGroup",
      "signalType",
      "unit",
    ],
  });
  const goBack = () => navigate(-1);

  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign='center'>
            <Button appearance='transparent' icon={<BackIcon />} onClick={goBack} />
            {items.wirelessGateway ? (
              <Breadcrumb
                items={[
                  {
                    key: `corporation-${proj?.corporation?.number}`,
                    text: proj?.corporation?.name,
                  },
                  {
                    key: `company-${proj?.company?.number}`,
                    text: proj?.company?.name,
                  },
                  { key: `project-${proj?.name}`, text: proj?.name },
                  {
                    key: `gateway-${items.wirelessGateway?.serialNumber}`,
                    text: items.wirelessGateway?.serialNumber,
                  },
                  {
                    key: `sensorNode-${items.sensorNodeId}`,
                    text: items.sensorNodeId,
                  },
                ]}
              />
            ) : (
              <Breadcrumb
                items={[
                  {
                    key: `un-parented-sensorNode`,
                    text: "Un-parented sensorNode",
                  },
                ]}
              />
            )}
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
            />

            <Table
              persistOpts={{
                key: "table-Signals",
                version: 2,
              }}
              header={{
                title: t("Signals"),
              }}
              items={items.signals}
              v8Columns={getColumns()}
              filters={filters}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />
          </div>
        </>
      ) : (
        <>
          <NotFoundRoute />
        </>
      )}
    </>
  );
};
