import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import CommandBar, { CommandBarItemType } from "../../../../Components/common/CommandBar";
import { Spin } from "../../../../Components/common/Spin";
import { useSearch } from "../../../../Hooks";
import type { IDashboardCard } from "./DashboardCard";
import { DashboardCard } from "./DashboardCard";
import { ShowMoreGrid } from "./ShowMoreGrid";

type DashboardsProps = {
  title?: string;
  isLoading?: boolean;
  items: IDashboardCard[];
  sizeProps?: any;
};

export const dashboardGridStyle: CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(330px, 1fr))",
  gap: 16,
  marginTop: 10,
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  alignSelf: "center",
};

export const Dashboards = ({ title, items, isLoading = false, sizeProps }: DashboardsProps) => {
  const { t } = useTranslation();
  const headerItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <div style={containerTitleStyle}>{title || t("Dashboards")}</div>,
    },
  ];

  const { data, handleSearch } = useSearch({
    data: items,
    keys: ["name", "subtitle"],
  });

  if (!items?.length) return null;

  const content = isLoading ? (
    <Spin />
  ) : (
    <ShowMoreGrid
      data={data}
      sizeProps={sizeProps}
      renderComponent={(data) => (
        <div style={dashboardGridStyle}>
          {data.map((item) => (
            <DashboardCard key={item.uniqId} data={item} />
          ))}
        </div>
      )}
    />
  );

  return (
    <div
      className='dashboards-container'
      style={{
        marginBottom: 80,
      }}
    >
      <CommandBar items={headerItems} onSearch={handleSearch} />
      {content}
    </div>
  );
};
