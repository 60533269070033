/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Switch, Text, Tooltip } from "@fluentui/react-components";
import { NextIcon, PreviousIcon } from "@fluentui/react-icons-mdl2";
import { format as fnsFormat } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { treeHandlers } from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../assets/styles";
import InfoIcon from "../../../../assets/svg/InfoIcon";
import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import RefreshIcon from "../../../../assets/svg/RefreshIcon";
import PeriodIcon from "../../../../assets/svg/TasksIcon";
import BaseCommandBar from "../../../../Components/common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../Components/common/CommandBar/methods";
import { Stack } from "../../../../Components/Stack";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { TrendViewSelectSignalsInfo } from "../../../analysis-trend-view";
import {
  getPeriodOptions,
  PeriodOptionsEnum,
} from "../../../analysis-trend-view/constants/controlOptions";
import { FORMAT } from "../../../analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../common/components/CustomDateDialog";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import { getRefreshOptions } from "../../../common/constants/refreshOptions";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import useSpectrumStore from "../../components/SpectrumChart/useSpectrumStore";
import { getLayoutOptions } from "../../constants/controlOptions";
import useHeaderControlsStore from "../../hooks/useHeaderControlsStore";
import useLayoutStore from "../../hooks/useLayoutStore";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useSelectedDataPointsStore from "../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import type { HeaderControlsProps } from "../../types";
import {
  baseCommandBarWrapper,
  dropdownIcon,
  headerText,
  topControls,
} from "./HeaderControls.styles";

interface CustomLabelProps {
  label?: string;
  text: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  disabled: boolean;
}

export const renderCustomLabel = ({
  label,
  text,
  icon: CustomIcon,
  disabled,
}: CustomLabelProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CustomIcon fill={disabled ? "#c8c6c4" : "#2c529f"} style={dropdownIcon} />
      <span style={{ color: disabled ? "#c8c6c4" : "", marginRight: 5 }}>{text}</span>
      <div style={{ fontWeight: 600 }}>{label}</div>
    </div>
  );
};

const HeaderControls = ({ title }: HeaderControlsProps) => {
  const { t } = useTranslation();
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] = React.useState(false);
  const selectedDataPoints = useSelectedDataPointsStore((store) => store.selectedDataPoints);

  const { isTrendLayout, isCompareLayout, isWaterfallLayout } = useLayoutTypes();

  const { selectedSignals, removeSelectedSignal } = useSelectedSignalsStore((store) => ({
    selectedSignals: store.selectedSignals,
    removeSelectedSignal: store.removeSelectedSignal,
  }));

  const { resetSpectrumStore } = useSpectrumStore((state) => ({
    resetSpectrumStore: state.resetSpectrumStore,
  }));

  const {
    setPrevTimestamp,
    setNextTimestamp,
    period,
    changePeriod,
    setActiveRawDataPoints,
    isRawDataPointsActive,
  } = useHeaderControlsStore((store) => ({
    setPrevTimestamp: store.setPrevTimestamp,
    setNextTimestamp: store.setNextTimestamp,
    period: store.period,
    changePeriod: store.changePeriod,
    setActiveRawDataPoints: store.setActiveRawDataPoints,
    isRawDataPointsActive: store.isRawDataPointsActive,
  }));

  const { refreshInterval, setRefreshInterval } = useRefreshStore((store) => ({
    refreshInterval: store.refreshInterval,
    setRefreshInterval: store.setRefreshInterval,
  }));

  const { layout, changeLayout } = useLayoutStore((store) => ({
    layout: store.layout,
    changeLayout: store.changeLayout,
  }));

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (wrapperRef.current) {
      // CommandBar needs a wrapper with a width of 100%
      wrapperRef.current.children?.[0]?.setAttribute("style", "width: 100%;");
    }
  }, []);

  const hasSelectedDataPoints = Object.values(selectedDataPoints).filter(
    (datapoint) => datapoint?.dataPoints.length > 0,
  );

  const handleDropdownChange = (item: any | undefined) => {
    const treeHandler = treeHandlers.trees["raw-data-signal-tree"].handlers;
    const trendItemKey = "trend-waveform-spectrum";
    const compareItemKey = "compare-waveform-spectrum";

    // reset spectrum chart store
    resetSpectrumStore();

    if (isTrendLayout && item?.key !== trendItemKey) {
      const itemToRemove = selectedSignals.find((item) => item.dataType === DataTypeEnum.Trend);
      if (itemToRemove) {
        treeHandler.setSelected(itemToRemove.id, false);
        removeSelectedSignal(itemToRemove as ResponseSimplifiedSignal);
      }
    }

    if (isCompareLayout && item?.key !== compareItemKey) {
      const itemToRemove = selectedSignals.filter((item) => item.dataType === DataTypeEnum.RawData);
      if (itemToRemove.length > 1) {
        itemToRemove.slice(0, -1).forEach((item) => {
          treeHandler.setSelected(item.id, false);
          removeSelectedSignal(item as ResponseSimplifiedSignal);
        });
      }
    }

    changeLayout(item);
  };

  useEffect(() => {
    if (isWaterfallLayout) {
      setActiveRawDataPoints(false);
    }
  }, [isWaterfallLayout]);

  function onPeriodChange(_: never, { data, text }) {
    if (data === "Custom date") {
      setIsCustomPeriodVisible(true);
    } else {
      changePeriod({ key: data, text });
    }
  }

  const onCustomDateChange = ({ start, end }: any) => {
    changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: t(PeriodOptionsEnum.CustomDate),
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  const items: any[] = [
    {
      key: "layout",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        disabled: selectedSignals.length === 0 || hasSelectedDataPoints.length === 0,
        defaultSelectedKey: layout?.key || undefined,
        title: "layout",
        options: getLayoutOptions(t),
        onChange: (_: never, { data, text }) => handleDropdownChange({ key: data, text }),
        onRenderLabelProps: () =>
          renderCustomLabel({
            text: t("Layout"),
            icon: LayoutIcon,
            label: t(layout.text),
            disabled: selectedSignals.length === 0 || hasSelectedDataPoints.length === 0,
          }),
      },
    },
    {
      key: "prev",
      text: t("Previous timestamp"),
      type: CommandBarItemType.Button,
      icon: <PreviousIcon />,
      disabled:
        selectedSignals.length === 0 || hasSelectedDataPoints.length === 0 || isWaterfallLayout,
      style: { marginLeft: 16 },
      onClick: () => setNextTimestamp(),
    },
    {
      key: "next",
      text: t("Next timestamp"),
      type: CommandBarItemType.Button,
      icon: <NextIcon />,
      disabled:
        selectedSignals.length === 0 || hasSelectedDataPoints.length === 0 || isWaterfallLayout,
      style: { marginLeft: 16, marginRight: 26 },
      onClick: () => setPrevTimestamp(),
    },
    {
      key: "period",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: getPeriodOptions(t),
        defaultSelectedKey: period?.key || undefined,
        onChange: onPeriodChange,
        onRenderLabelProps: {
          label: t("Period"),
          icon: <PeriodIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "refresh",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: getRefreshOptions(t),
        defaultSelectedKey: refreshInterval?.key || undefined,
        onChange: (_: never, { data, text }) => setRefreshInterval({ key: data, text }),
        onRenderLabelProps: {
          text: t(refreshInterval.text),
          icon: <RefreshIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "toggle",
      type: CommandBarItemType.Custom,
      onRender() {
        return (
          <Switch
            label={t("Data points")}
            disabled={selectedSignals.length === 0 || isWaterfallLayout}
            checked={isRawDataPointsActive}
            style={{ marginLeft: "20px" }}
            onChange={(_, { checked }) => setActiveRawDataPoints(checked)}
          />
        );
      },
    },
  ];

  return (
    <Stack
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
      style={topControls}
      id='top-controls'
    >
      <Stack horizontal className='sidebar-header'>
        <Text style={headerText}>{title}</Text>
        <Tooltip
          withArrow
          content={<TrendViewSelectSignalsInfo />}
          showDelay={0}
          hideDelay={0}
          positioning='below-start'
          relationship='description'
        >
          <span style={{ cursor: "pointer", marginLeft: "8px" }}>
            <InfoIcon />
          </span>
        </Tooltip>
      </Stack>

      <div ref={wrapperRef} style={baseCommandBarWrapper}>
        <BaseCommandBar items={computeCommandBarItems(items)} />
      </div>

      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </Stack>
  );
};
export default HeaderControls;
