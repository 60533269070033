import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Field,
  Label,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { DialogSize } from "../../../common/Dialog";
import { getDayPickerStrings } from "../../../common/Form";
import { notification } from "../../../common/Notification";
import MachineFormItems from "../../../Generic/MachineFormItems";
import { getMachineDetails } from "../../../Machines/MachineDetails/api";
import type { Image, MachineToList } from "../../../Machines/models";
import { DownloadImagesProgressDialog } from "./DownloadImagesProgressDialog";

type FormData = {
  machine?: MachineToList;
  from?: Date;
  to?: Date;
};

type DateLimitations = {
  minDate?: Date;
  maxDate?: Date;
};

type DownloadImagesDialogProps = Omit<
  { open?: boolean; onClose: () => void },
  "title" | "isLoading" | "isValid" | "type" | "size" | "onSubmit" | "submitButtonText"
>;

const sortByAscending = (a: Image, b: Image) => {
  if (a.dataUntil > b.dataUntil) return 1;
  else if (a.dataUntil < b.dataUntil) return -1;

  return 0;
};

const DownloadImagesDialog = ({ open = false, onClose }: DownloadImagesDialogProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({});
  const [limitations, setLimitations] = useState<DateLimitations>({
    maxDate: new Date(Date.now()),
  });
  const [images, setImages] = useState<Image[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDownload, setStartDownload] = useState<boolean>(false);
  const disabled: boolean = useMemo(() => {
    return isLoading || !images || images.length === 0;
  }, [isLoading, images]);

  const isValid: boolean = useMemo(() => {
    return (
      formData.machine !== undefined &&
      formData.from !== undefined &&
      formData.to !== undefined &&
      images?.length > 0 &&
      disabled === false
    );
  }, [formData, images, disabled]);

  // Gets the machine details, to set the dates limitations
  useEffect(() => {
    if (!formData.machine) {
      return;
    }

    let mount = true;
    setIsLoading(true);
    getMachineDetails(formData.machine.id).then((response) => {
      if (!mount) {
        return;
      }

      const newImages = response.images?.sort(sortByAscending);
      if (!newImages || newImages.length === 0) {
        return;
      }

      const maxDate = new Date(newImages.at(newImages.length - 1).dataUntil);
      const minDate = new Date(newImages.at(0).dataUntil);
      const now = new Date(Date.now());

      setIsLoading(false);
      setFormData({
        ...formData,
        from: minDate,
        to: maxDate <= now ? maxDate : now,
      });
      setLimitations({ minDate, maxDate });
      setImages(newImages);
    });

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [formData.machine?.id]);

  // Handlers
  const onMachineSelected = (machine?: MachineToList) => setFormData({ ...formData, machine });

  const onFromChange = (date: Date | null | undefined) => {
    if (!date) {
      return;
    }

    setFormData({ ...formData, from: date });
  };

  const onToChange = (date: Date | null | undefined) => {
    if (!date) {
      return;
    }

    setFormData({ ...formData, to: date });
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    const imagesToDownload = images.filter(
      (i) => new Date(i.dataUntil) >= formData.from && new Date(i.dataUntil) <= formData.to,
    );
    if (imagesToDownload.length === 0) {
      notification.warning("There are no images available for the selected time range.");
      return;
    }

    setImages(imagesToDownload);
    setStartDownload(true);
  };

  return (
    <>
      <BaseDialog open={open} surfaceStyle={{ width: DialogSize.S }} onOpenChange={onClose}>
        <DialogTitle>{t("Download Images")}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",

            flexDirection: "column",
            gap: 8,
          }}
        >
          <MachineFormItems onMachineSelected={onMachineSelected} />
          <Field>
            <Label>{t("From")}</Label>
            <DatePicker
              disabled={disabled}
              aria-label={t("Select a date")}
              placeholder={t("Select a date...")}
              strings={getDayPickerStrings(t)}
              value={formData?.from || new Date()}
              minDate={limitations?.minDate}
              maxDate={formData?.to}
              onSelectDate={onFromChange}
            />
          </Field>
          <Field>
            <Label>{t("To")}</Label>
            <DatePicker
              disabled={disabled}
              aria-label={t("Select a date")}
              placeholder={t("Select a date...")}
              strings={getDayPickerStrings(t)}
              value={formData?.to || new Date()}
              minDate={formData?.from}
              maxDate={limitations?.maxDate}
              onSelectDate={onToChange}
            />
          </Field>

          <DialogActions style={{ justifyContent: "flex-end" }}>
            <Button appearance='primary' onClick={onSubmit}>
              {t("Start Download")}
            </Button>
            <Button appearance='transparent' onClick={onClose}>
              {t("Close")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
      {!!startDownload && (
        <DownloadImagesProgressDialog images={images} onClose={() => setStartDownload(false)} />
      )}
    </>
  );
};

export default DownloadImagesDialog;
