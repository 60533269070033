import type { MenuProps } from "@fluentui/react-components";
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash-es";
import { useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import type { Project } from "../../../../types";
import { apiService } from "../../../common";

const BreadcrumbItemMachine = ({ machine, selectedProject }: any) => {
  const [open, setOpen] = useState(false);
  const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
    setOpen(data.open);
  };
  const navigate = ReactRouterDOM.useNavigate();
  const location = ReactRouterDOM.useLocation();

  const { pathname } = location;
  const { data: projectData = {} as Project, isLoading: isLoadingInternal } = useQuery(
    ["project-data", selectedProject?.id],
    () => apiService.metaDataRead.getProject(selectedProject?.id),
    {
      enabled: !!selectedProject?.id,
    },
  );

  if (isLoadingInternal) {
    return <Spinner size='tiny' />;
  }

  const dropdownMachineOptions =
    sortBy(projectData?.machines ?? [], ({ name }) => name?.toLowerCase()).map((m) => ({
      key: m.id,
      text: m.name,
      selected: m.id === machine?.id || m.id,
    })) ?? [];

  const handleDropdownChange = ({ key }) => {
    navigate(`${pathname}?id=${key}`);
  };

  return (
    <Menu open={open} onOpenChange={onOpenChange}>
      <MenuTrigger disableButtonEnhancement>
        <Button
          style={{ minWidth: 0, padding: 0, fontWeight: 400 }}
          icon={<ChevronDownRegular />}
          iconPosition='after'
          appearance='transparent'
        >
          {machine?.name}
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {dropdownMachineOptions.map(({ key, text }) => (
            <MenuItem key={key} onClick={() => handleDropdownChange({ key })}>
              {text}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
export default BreadcrumbItemMachine;
