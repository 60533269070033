import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFileUpload, useLocationSearch, useMachineUsersQuery } from "../../../Hooks";
import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import AddDocumentDialog from "../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import type { ObjectWithKey } from "../../../types";
import { useTableFilters } from "../../common/Table/v9";
import type { TableProps } from "../../common/Table/v9/Table";
import Table from "../../common/Table/v9/Table";
import type { UploadedFile } from "../../common/Uploader/types";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import type { DocumentResponse } from "./hooks/useDocumentsQuery";
import { useDocumentsQuery } from "./hooks/useDocumentsQuery";
import useDocumentsStore from "./hooks/useDocumentsStore";

const tableGetRowId = (item) => item?.id;

const Page = () => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();

  const { queryDocuments, isError, isLoading, refetch } = useDocumentsQuery({
    machineId: id || "",
  });

  const { usersList, isLoading: usersLoading } = useMachineUsersQuery({
    machineId: id as string,
  });

  const { filters, handleSearch } = useTableFilters<DocumentResponse>({
    keys: ["name", "description", "type"],
  });

  const { selectedItems, showDelete, showUpload, dispatch } = useDocumentsStore((state) => ({
    showDelete: state.showDelete,
    selectedItems: state.selectedItems as DocumentResponse[],
    showUpload: state.showUpload,
    dispatch: state.dispatch,
  }));

  const pageLoading = useMemo(() => isLoading || usersLoading, [isLoading, usersLoading]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents",
        version: 1,
      },
      header: {
        title: t("Documents"),
      },
      items: queryDocuments,
      filters,
      isLoading: pageLoading,
      isError,
      v8Columns: getColumns({ t, users: usersList }),
      defaultSelectedItems: selectedItems.map((item) => tableGetRowId(item)),
      getRowId: tableGetRowId,
      onSelectionChanged: (items: ObjectWithKey[]) => {
        dispatch({ type: "SET_SELECTION", payload: items });
      },
    }),
    [t, queryDocuments, filters, pageLoading, isError, usersList, dispatch],
  );

  const { uploadFilesAsyncWithNotifications } = useFileUpload();

  const onUpload = async (files: UploadedFile[]) => {
    await uploadFilesAsyncWithNotifications({ files, machineId: id || "" });
    refetch();
  };

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <CommandBar handleSearch={handleSearch} />

        <Table {...tableProps} />
      </div>
      <DeleteDialog
        open={showDelete}
        data={{
          ids: selectedItems.map((item) => item?.id),
          name: selectedItems.length === 1 ? selectedItems[0]?.name : "items",
        }}
        route={"/files/v1/files"}
        onDismiss={() => dispatch({ type: "SHOW_DELETE", payload: false })}
        onSuccess={refetch}
      />
      <AddDocumentDialog
        hidden={!showUpload}
        onSubmit={onUpload}
        onClose={() => dispatch({ type: "SHOW_UPLOAD", payload: false })}
      />
    </>
  );
};

export default Page;
