import "./SectionContainer.css";

import { Spinner, Text } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

type SectionContainerProps = {
  headerTitle?: string;
  isLoading?: boolean;
  children: React.ReactNode;
};

const SectionContainer = ({ headerTitle, isLoading, children }: SectionContainerProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Spinner
            size='small'
            style={{
              marginBottom: 5,
            }}
          />
          <Text
            style={{
              fontSize: 12,
            }}
          >
            {t("Loading")}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className='section-container'>
      <div className='section-container-header'>
        <Text block className='section-container-header-title'>
          {headerTitle}
        </Text>
      </div>

      {children}
    </div>
  );
};

export default SectionContainer;
