import type { SpinButtonProps } from "@fluentui/react-components";
import { Controller } from "react-hook-form";

import SpinButton from "../../SpinButton";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormNumberFieldProps = BaseFormFieldProps &
  SpinButtonProps & {
    name: string;
  };

export const FormNumberField = ({
  name,
  groupProps,
  hookProps: { control },
  ...rest
}: FormNumberFieldProps) => {
  return (
    <FormGroup {...groupProps}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <SpinButton
              min={0}
              max={100}
              step={1}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          );
        }}
      />
    </FormGroup>
  );
};
