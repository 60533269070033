import { Button, Switch } from "@fluentui/react-components";
import { format as fnsFormat } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../../assets/styles";
import PeriodIcon from "../../../../../assets/svg/TasksIcon";
import { FORMAT } from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../../modules/common/components/CustomDateDialog";
import { onRenderLabel } from "../../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../common/Dropdown";
import { Stack } from "../../../../Stack";
import { getPeriodDropdownOptions } from "../../config";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";

type TorqueHeaderProps = React.HTMLAttributes<HTMLElement> & {
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
  maximizeAction?: () => void;
  isDriveLoadLayout: boolean;
};

const TorqueHeader = ({
  maximizeIcon,
  maximizeLabel,
  maximizeAction,
  isDriveLoadLayout,
  ...rest
}: TorqueHeaderProps) => {
  const { transparent } = useDropdownStyles();
  const { t } = useTranslation();
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] = useState<boolean>(false);
  const { period, averageTrend, updateDriveLoadStore, isConnectedTimeAxis } = useDriveLoadStore(
    (store: any) => ({
      period: store.period,
      averageTrend: store.averageTrend,
      updateDriveLoadStore: store.updateDriveLoadStore,
      isConnectedTimeAxis: store.isConnectedTimeAxis,
    }),
  );

  // Handlers
  const onPeriodChange = (ev: any, selectedItem: any) => {
    if (selectedItem.optionValue === getPeriodDropdownOptions(t)[6].key) {
      setIsCustomPeriodVisible(true);
    } else {
      updateDriveLoadStore({
        period: { key: selectedItem.optionValue, text: selectedItem.optionText },
        torqueChartDates: {},
      });
    }
  };

  const onCustomDateChange = ({ start, end }: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    updateDriveLoadStore({
      period: {
        ...getPeriodDropdownOptions(t)[6],
        startDate: fnsFormat(startDate, FORMAT),
        endDate: fnsFormat(endDate, FORMAT),
      },
      torqueChartDates: {},
    });
  };

  return (
    <React.Fragment>
      <Stack {...rest} horizontal verticalAlign='center' style={{ gap: 20 }} role='menubar'>
        <Dropdown
          className={transparent}
          button={onRenderLabel({
            label: t("Period"),
            icon: <PeriodIcon fill='#2c529f' style={dropdownIconStyle} />,
            text: t(period.text),
          })}
          options={getPeriodDropdownOptions(t)}
          selectedOptions={[period.key]}
          onOptionSelect={onPeriodChange}
        />
        <Switch
          label={t("Average trend")}
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
          }}
          checked={averageTrend}
          onChange={(_, { checked }) => updateDriveLoadStore({ averageTrend: checked })}
        />
        {!isDriveLoadLayout && (
          <Switch
            label={t("Connect time axis")}
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 600,
            }}
            checked={isConnectedTimeAxis}
            onChange={(_, { checked }) => updateDriveLoadStore({ isConnectedTimeAxis: checked })}
          />
        )}

        <Stack horizontal style={{ flexGrow: 1 }} horizontalAlign='end'>
          <Button
            appearance='transparent'
            style={{ padding: 0, minWidth: 0 }}
            onClick={maximizeAction}
          >
            {maximizeIcon}
            <span>{maximizeLabel}</span>
          </Button>
        </Stack>
      </Stack>
      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </React.Fragment>
  );
};

export default TorqueHeader;
