import { Button, DialogActions } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { Stack } from "../../Stack";

type ConfigTextProps = {
  htmlText: JSX.Element;
  onClose: () => void;
  show: boolean;
};

/**
 * Gets the config text component.
 * @param htmlText the configuration text HTML element
 * @param onClose Method called to close/hide this component.
 * @param show Value indicating whether to show this form.
 * @returns The configuration text component.
 */
const ConfigText = ({ show, htmlText, onClose }: ConfigTextProps) => {
  const { t } = useTranslation();
  if (!show) {
    return null;
  }

  // Handlers
  const onCopyToClipboard = () => {
    let configString = "";
    htmlText.props?.children?.props?.children
      ?.filter((c) => typeof c === "string")
      .forEach((c) => (configString += c.toString() + "\n"));

    navigator.clipboard.writeText(configString);
  };

  return (
    <Stack>
      {htmlText}
      <DialogActions>
        <Button appearance='primary' onClick={onCopyToClipboard}>
          {t("Copy to Clipboard")}
        </Button>
        <Button appearance='transparent' onClick={onClose}>
          {t("Done")}
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default ConfigText;
