import type { UploadedFile } from "../Components/common/Uploader/types";

export interface Corporation {
  name: string;
  number?: string;
  id?: string;
}

export type CorporationList = Corporation[];

export interface Company {
  corporationId?: string;
  name: string;
  number?: string;
  id?: string;
}

export type CompanyList = Company[];

export interface Project {
  machines?: any[];
  city?: string;
  companyId: string;
  continent?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  name: string;
  id?: string;
  corporation?: Corporation;
  company?: Company;
}

export type ProjectList = Project[];

export interface MachineListItem {
  dalogId: string;
  id: string;
  manufacturer?: string;
  name?: string;
  notation?: string;
  process?: string;
  projectId: string;
  customerCode?: string;
  type?: string;
  dataFrom?: Date;
  dataUntil?: Date;
  eventDataFrom?: Date;
  eventDataUntil?: Date;
  fastTrendDataFrom?: Date;
  fastTrendDataUntil?: Date;
  shortTermDataFrom?: Date;
  shortTermDataUntil?: Date;
  trendDataFrom?: Date;
  trendDataUntil?: Date;
  corporation: Corporation;
  company: Company;
  project: Project;
}

export type MachineList = MachineListItem[];

export interface ResponseSimplifiedSignal {
  noAverageTrend?: any;
  p12h?: any;
  dataFrom?: Date;
  dataUntil?: Date;
  feature?: string;
  id?: string;
  machineId?: string;
  name?: string;
  sampleRateInHz?: number;
  sensorNo?: string;
  signalDescription?: string;
  signalGroup?: string;
  signalType?: string;
  dataType?: string;
  unit?: string;
  configName?: string;
  condition?: string;
  sensorSerialNo?: string;
}

export interface ResponseSimplifiedSignalWithConditionKey extends ResponseSimplifiedSignal {
  conditionKey: number;
}

export type SignalAlert = {
  alertHigh: number;
  alertLow: number;
  conditionGroup: string;
  conditionManualEnabled: boolean;
  conditionManualValue: string;
  dangerHigh: number;
  dangerLow: number;
  direction: string;
  faultyHigh: number;
  faultyLow: number;
  id: string;
  indicationHigh: number;
  indicationLow: number;
  notificationType: string;
  signalId: string;
};

export type ResponseSimplifiedSignalList = ResponseSimplifiedSignal[];

export interface DataBlockPoint {
  date: number;
  value: number;
}

export interface DataPoint {
  timeStamp: string;
  value: string;
}

export interface ShortTrendDataBlockList {
  dataFrom?: Date;
  dataUntil?: Date;
  feature?: string;
  id?: string;
  machineId?: string;
  name?: string;
  sampleRateInHz?: number;
  sensorNo?: string;
  signalDescription?: string;
  signalGroup?: string;
  signalType?: string;
  dataType?: string;
  unit?: string;
  configName?: string;
}
export interface ShortDataBlock {
  dataFrom?: Date;
  dataUntil?: Date;
  feature?: string;
  id?: string;
  machineId?: string;
  name?: string;
  sampleRateInHz?: number;
  sensorNo?: string;
  signalDescription?: string;
  signalGroup?: string;
  signalType?: string;
  dataType?: string;
  unit?: string;
  configName?: string;
}
export interface Statistics {
  avererageAbsolute?: number;
  count?: number;
  feature?: string;
  id?: string;
  machineId?: string;
  name?: string;
  sampleRateInHz?: number;
  sensorNo?: string;
  signalDescription?: string;
  signalGroup?: string;
  signalType?: string;
  dataType?: string;
  unit?: string;
  configName?: string;
}

/* Task ************/

export enum TaskPriorities {
  Low = "Low",
  Medium = "Medium",
  Important = "Important",
  Urgent = "Urgent",
}

export enum TaskStatuses {
  ToDo = "ToDo",
  InProgress = "InProgress",
  Done = "Done",
  Cancelled = "Cancelled",
}

export type BaseTask = {
  id: string;
  subject: string;
  status: TaskStatuses;
  description: string;
  createdAt: string;
  dueDate: string;
  assignedBy: string; // [TaskOverview] user name; [Task]: user id
  createdBy: string; // [TaskOverview] user name; [Task]: user id
};

export type TaskOverview = BaseTask & {
  fileCount: string;
  createdById: string;
};

export type MachineCVFile = {
  id: string;
  added: string;
  machineId: string;
  userId: string;
  type: string;
  fileName: string;
  sizeInBytes: number;
  name: string;
  md5Hash: string;
  description: string;
};

export type Comment = {
  createdAt: string;
  createdBy: string;
  files: UploadedFile[];
  id: string;
  lastUpdate: string;
  taskId: string;
  text: string;
  createdByDisplayName?: string;
};

export type Task = BaseTask & {
  machineId: string;
  assignedTo: string;
  comments: string[]; // TBC
  files: MachineCVFile[];
  startDate: string;
  priority: TaskPriorities;
  lastUpdate: string | null;
};

export type TaskOverviewList = TaskOverview[];

/* Task ************/

/* ReportEntry ************/

export type Report = {
  createdBy: string;
  category: string;
  text: string;
  machineId: string;
  id: string;
  createdAt: string;
  files?: UploadedFile[];
};

/* ReportEntry ************/

/* Comments */

export type CommentTag = {
  name: string;
  id?: string;
};

export type MachineCVComment = {
  text: string;
  fileIds: string[];
  files: UploadedFile[];
  machineId: string;
  id: string;
  tags: CommentTag[];
  createdAt: string;
  createdBy: string;
  createdById?: string;
  lastUpdate: string;
};

// export type MachineCVComment = {
//   text: string;
//   files: UploadedFile[];
//   tags: any[];
//   machineId: string;
//   id: string;
//   createdAt: string;
//   createdBy: string;
//   lastUpdate: string;
// };

/* Comments */

export interface Document {
  id: string;
  documentName: string;
  documentLink: string;
}

// Machine CV Info Types

export interface InfoEntryRequest {
  group: string;
  fieldName: string;
  fieldValue: string;
  fileIds: string[];
  id?: string;
  machineId: string;
}

export interface Entry {
  id?: string;
  name: string;
  value: string;
  files: UploadedFile[];
}

export interface Group {
  id: string;
  name: string;
  entries: Entry[];
}

// Machine CV Overview

export type ConditionStatus =
  | "Ok"
  | "Indication"
  | "Alert"
  | "Danger"
  | "NotInstalled"
  | "NoStatus"
  | "Faulty";

export interface ConditionGroup {
  name: string;
  value: ConditionStatus;
}

export interface Gearbox {
  manufacturer: string;
  name: string;
  notation: string;
  power: number;
  rotationalSpeed: number;
  serialnumber: string;
}

export interface Machine {
  comment?: string;
  customerCode?: string;
  dalogId: string;
  gearbox: Gearbox;
  id: string;
  manufacturer?: string;
  name?: string;
  notation?: string;
  process?: string;
  projectId: string;
  type?: string;
  dataFrom?: Date;
  dataUntil?: Date;
  eventDataFrom?: Date;
  eventDataUntil?: Date;
  fastTrendDataFrom?: Date;
  fastTrendDataUntil?: Date;
  shortTermDataFrom?: Date;
  shortTermDataUntil?: Date;
  trendDataFrom?: Date;
  trendDataUntil?: Date;
  iconId?: string;
}

export type MachineDetails = Machine & {
  project: Project;
};

export interface MachineCvOverviewMachine {
  id?: string;
  comments?: { dateTime?: Date; text?: string };
  condition: ConditionStatus;
  conditions: ConditionGroup[];
  files?: { dateTime: Date; text: string };
  logEntries?: { dateTime: Date; text: string };
  tasks: {
    activeTasksCount: number;
    nextAssignedToDisplayName?: string;
    nextAssignedToId?: string;
    nextDueDate?: Date;
    nextDueDescription?: string;
  };
  metaData: Machine;
}
export interface MachineCvCondition {
  name: string;
  group: string;
  value: string;
  machineId: string;
  id: string;
}

export interface MachineCvOverviewProject {
  metaData: Project;
  machinesCount: number;
  commentsCount: number;
  activeTasksCount: number;
  machines: MachineCvOverviewMachine[];
}

export interface MachineCvOverviewCompany extends Company {
  projects: MachineCvOverviewProject[];
}

export interface MachineCvOverview extends Corporation {
  companies: MachineCvOverviewCompany[];
}

export type MachineCvOverviewList = MachineCvOverview[];

export type ResponseMachineCvOverviewList = {
  corporation: Corporation;
  company: Company;
  projects: MachineCvOverviewProject[];
}[];

// Power Bi

export type PowerBiParentType = "Corporation" | "Company" | "Project" | "Machine";

export type PowerBi = {
  id: string;
  groupId: string;
  parentId: string; // based on type: corporationId, companyId, projectId, machineId
  parentType: PowerBiParentType;
};

export type PowerBiList = PowerBi[];

export type PowerBiEmbed = PowerBi & {
  embedToken: string;
  embedUrl: string;
};

// Home

export type HomeOverviewMetaInformation = {
  corporationId: string;
  corporationName: string;
  companyId: string;
  companyName: string;
  projectId: string;
  projectName: string;
  machineId: string;
  machineName: string;
  title: string;
  titleParent: string;
};

export type HomeOverviewDashboard = PowerBi & {
  metaInformation: HomeOverviewMetaInformation;
};

export type HomeOverviewMachine = {
  dalogId: string;
  iconId: string;
  condition: ConditionStatus;
  metaInformation: HomeOverviewMetaInformation;
};

export type HomeOverviewTask = BaseTask & {
  documents: number;
  metaInformation: HomeOverviewMetaInformation;
};

export type HomeOverview = {
  dashboards: HomeOverviewDashboard[];
  machines: HomeOverviewMachine[];
  tasks: HomeOverviewTask[];
};

// Dashboards

export type DashboardsOverviewMetaInformation = HomeOverviewMetaInformation & {
  latitude: number;
  longitude: number;
};

export type DashboardsOverviewItem = PowerBi & {
  metaInformation: DashboardsOverviewMetaInformation;
};

export type DashboardsOverviewLocation = {
  latitude: number;
  longitude: number;
  projects: DashboardsOverviewItem[];
};

export type DashboardsOverview = {
  dashboards: DashboardsOverviewItem[];
  locations: DashboardsOverviewLocation[];
};

// Machine CV Breadcrumb

export type BreadcrumbInterface = {
  corporation: {
    name: string;
    id: string;
  };
  company: {
    name: string;
    id: string;
  };
  project: {
    name: string;
    id: string;
  };
  machine: {
    name: string;
    id: string;
  };
};

// Telemetry

export type Topology = {
  value: string;
  children: Topology[];
};

export type TelemetryReportInterval =
  | "ZeroSeconds"
  | "ThirtySeconds"
  | "OneMinute"
  | "TwoMinutes"
  | "FiveMinutes"
  | "TenMinutes"
  | "ThirtyMinutes";

export type GatewayTelemetry = {
  gatewayId: string;
  enabled: boolean;
  signalId: string;
  telemetryReportInterval: TelemetryReportInterval;
  topology: Topology;
};

export type GetProjectTelemetryResponse = {
  gatewayTelemetries: GatewayTelemetry[];
};

export type GetGatewayTelemetryResponse = {
  gatewayTelemetry: GatewayTelemetry;
};

export type GetMachineTelemetryResponse = {
  machineSensors: string[];
  gatewayTelemetries: GatewayTelemetry[];
};

export interface DriveLoadSignal {
  signalId: string;
  index: number;
}

export interface DriveLoadEnriched {
  id?: string;
  machineId: string;
  calculateDriveLoad: boolean;
  averageLoadSignalId?: string;
  dynamicLoadSignalId?: string;
  referenceValueAverageLoad?: number;
  referenceValueDynamicLoad?: number;
  eventId?: string;
  xMin?: number;
  xMax?: number;
  yMin?: number;
  yMax?: number;
  backgroundFileId?: string;
  currentAverageLoad?: string;
  currentDynamicLoad?: string;
  currentPerformance?: number;
  signals?: DriveLoadSignal[];
}

export interface ObjectWithKey {
  key?: string | number;
}
