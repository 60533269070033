import { SearchBox } from "@fluentui/react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSearch } from "../../Hooks";
import { SelectionZone } from "../common/SelectionZone";
import type { ControlledItem } from "../Roles/AddRole";
import { Stack } from "../Stack";

type ScopeSelectionsProps = {
  controlledItems: ControlledItem[];
  setControlledItems: React.Dispatch<React.SetStateAction<ControlledItem[]>>;
};

export const ScopeSelections = ({
  controlledItems = [],
  setControlledItems,
}: ScopeSelectionsProps) => {
  const { t } = useTranslation();

  const { data, handleSearch } = useSearch({
    data: controlledItems as any,
    keys: ["name"],
  });

  const selectionZoneItems = useMemo(() => {
    return data.reduce((acc, curr: any) => {
      if (!acc[curr?.scopeId]) {
        acc[curr?.scopeId] = {
          id: curr?.scopeId,
          children: [],
          label: curr?.scope,
        };
      }

      acc[curr?.scopeId].children.push({
        id: curr.key,
        label: curr.name,
        check: curr?.check || false,
      });

      return acc;
    }, []);
  }, [data]);

  const treeData = Object.values(selectionZoneItems);

  return (
    <Stack style={{ padding: "20px" }}>
      <SearchBox
        style={{ maxWidth: "unset", marginBottom: 8 }}
        placeholder={t("Search")}
        onChange={(_, data) => handleSearch(data.value ?? "")}
      />

      <SelectionZone
        treeData={treeData}
        style={{ overflow: "auto", maxHeight: "500px" }}
        onSelectionChange={setControlledItems}
      />
    </Stack>
  );
};
