import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";

import type { Column } from "../../../../../Components/common/Table/v9";
import { formatFileSize } from "../../../../../utils";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumns = ({ t, onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      return (
        <Button
          appearance='transparent'
          style={{ minWidth: 0, padding: 0, fontSize: 13, textDecoration: "underline" }}
        >
          {name}
        </Button>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}>
          {isValid ? formatFileSize(size) : t("Too large")}
        </span>
      );
    },
  },

  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 68,
    onRender: ({ id }) => (
      <Tooltip relationship='label' content={t("Delete")}>
        <Button
          appearance='transparent'
          icon={<DeleteRegular />}
          aria-label={t("Delete")}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(id);
          }}
        />
      </Tooltip>
    ),
  },
];
