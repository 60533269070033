import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import SensorNodeSelector from "./SensorNodeSelector";
import type { ProjectSelectable, SensorNodeSelectable } from "./types";

interface SensorSelectDialogProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSave: (selectedSensors: string[]) => void;
  options: ProjectSelectable;
  isLoading?: boolean;
  title?: string;
  saveButtonText?: string;
  sensorNodeId?: string;
}

const SensorSelectDialog: React.FC<SensorSelectDialogProps> = ({
  isVisible,
  onDismiss,
  onSave,
  options: projectData,
  isLoading = false,
  saveButtonText,
  sensorNodeId,
}) => {
  const { t } = useTranslation();
  const [selectedNodes, setSelectedNodes] = React.useState<string[]>([]);
  const handleSave = () => {
    if (selectedNodes.length === 0) {
      notification.error(t("Please select at least one sensor node"));
      return;
    } else {
      onSave(selectedNodes);
    }
  };

  const handleSelectionChange = (selectedNodes: SensorNodeSelectable[]) => {
    setSelectedNodes(selectedNodes.map((node) => node.sensorNodeId));
  };

  const handleDialogClose = () => {
    onDismiss();
    setSelectedNodes([]);
  };

  return (
    <BaseDialog
      open={isVisible}
      surfaceStyle={{ width: DialogSize.M }}
      onOpenChange={handleDialogClose}
    >
      <BaseDialogTitle>{t("Sensor: {{sensorNodeId}}", { sensorNodeId })}</BaseDialogTitle>
      <DialogContent>
        <SensorNodeSelector project={projectData} onSelectionChange={handleSelectionChange} />
        <DialogActions>
          <Button
            appearance='primary'
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleSave}
          >
            {saveButtonText || t("Save to sensors")}
          </Button>
          <Button onClick={handleDialogClose}>{t("Close")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default SensorSelectDialog;
