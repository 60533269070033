import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  getFileIds,
  useFileDelete,
  useFileUpload,
  useLocationSearch,
} from "../../../../../../Hooks";
import AddDocumentDialog from "../../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import type { Entry, Group } from "../../../../../../types";
import BaseDialog from "../../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../../common/Form";
import type { FormItemProps } from "../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../../common/Notification";
import UploaderButton from "../../../../../common/Uploader/UploaderButton";
import { useInfoEntryAdd, useInfoEntryUpdate } from "../../../hooks/useInfoMutations";
import { useMachineCVInfo } from "../../../hooks/useMachineCVInfo";
import { FormType } from "../../CommandBar";
import DocumentsTable from "../../DocumentsTable";

interface EditEntryDialogProps {
  formType: FormType.Edit;
  entry: Entry;
}

interface AddEntryDialogProps {
  formType: FormType.New;
  entry?: Entry;
}

type EntryDialogProps = Omit<DialogProps, "children"> & { onDismiss?: () => void } & {
  group: Group;
} & (EditEntryDialogProps | AddEntryDialogProps);

const EntryDialog: FC<EntryDialogProps> = (props) => {
  const { t } = useTranslation();
  const { formType, open, group } = props;
  const hidden = !open;
  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const groupEntryNames = group.entries.map(({ name }) => name);

  const [isLoading, setIsLoading] = useState(false);

  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState(true);
  const toggleIsDocumentsDialogHidden = () => setIsDocumentsDialogHidden(!isDocumentsDialogHidden);
  const [files, setFiles] = useState(formType === FormType.Edit ? props?.entry?.files : []);

  const getSchema = useCallback(
    (entryNames: string[], entryTitle = "") =>
      z
        .object({
          name: z.string().min(1, { message: t("This field is required") }),
          value: z.string().optional(),
        })
        .refine(
          ({ name }) => {
            const refinedInputName = name.toLowerCase().trim();
            const refinedEntryTitle = entryTitle.toLowerCase().trim();

            const nameList = entryNames.map((name) => name.toLowerCase().trim());

            if (refinedEntryTitle === refinedInputName) {
              return true;
            }

            return !nameList.includes(refinedInputName);
          },
          { path: ["name"], message: t("Entry name already exists") },
        ),
    [t],
  );

  useEffect(() => {
    if (hidden) {
      setFiles([]);
    } else {
      setFiles(formType === FormType.Edit ? props?.entry?.files : []);
    }
    if (formType === FormType.Edit) {
      reset(props.entry);
    }
  }, [hidden]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    ...(formType === FormType.Edit && {
      defaultValues: {
        name: props.entry?.name,
        value: props.entry?.value,
      },
    }),
    schema: getSchema(groupEntryNames, props?.entry?.name),
  });

  const { uploadFilesAsync } = useFileUpload();
  const { addInfoEntryAsync } = useInfoEntryAdd();
  const { updateInfoEntryAsync } = useInfoEntryUpdate();
  const { deleteFilesAsync } = useFileDelete();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    if (formType === FormType.New) {
      const submitData = {
        ...data,
        group: group?.name,
        fileIds: successIds,
        machineId: id || "",
      };
      await addInfoEntryAsync(submitData)
        .then(() => {
          if (uploadResponse.length === successIds.length) {
            notification.success(t("Entry added successfully"));
          } else {
            notification.success(
              t(`Entry added successfully with {{addedFiles}} out of {{totalFiles}} files`, {
                addedFiles: successIds.length,
                totalFiles: uploadResponse.length,
              }),
            );
          }
          onClose();
          refetch();
        })
        .catch(() => {
          notification.error(t("Failed adding entry"));
          deleteFilesAsync(successIds);
        })
        .finally(() => setIsLoading(false));
      return;
    }

    const submitData = {
      ...data,
      group: group?.name,
      machineId: id || "",
      fileIds: [...files.filter((file) => !file.isValid).map((file) => file.id), ...successIds],
    };
    await updateInfoEntryAsync({
      ...submitData,
      id: props.entry?.id as string,
    })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success(t("Entry updated successfully"));
        } else {
          notification.success(
            t(`Entry updated successfully with {{updatedFiles}} out of {{totalFiles}} files`, {
              updatedFiles: successIds.length,
              totalFiles: uploadResponse.length,
            }),
          );
        }
        onClose();
        refetch();
      })
      .catch(() => notification.error(t("Error updating entry")))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  const entryFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Title") },
      placeholder: t("Title"),
    },
    {
      name: "value",
      type: FormItemType.TextField,
      groupProps: { label: t("Value") },
      placeholder: t("Value"),
    },
  ];

  return (
    <>
      <BaseDialog {...props}>
        <DialogTitle>
          {formType === FormType.Edit ? props.entry?.name || "" : t("Add entry")}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            {renderFormItems(entryFields, {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <UploaderButton
              onClick={(e) => {
                e.stopPropagation();
                toggleIsDocumentsDialogHidden();
              }}
            />

            <DocumentsTable items={files} setItems={setFiles} />

            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {t("Save")}
              </Button>
              <Button appearance='transparent' onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </>
  );
};

export default EntryDialog;
