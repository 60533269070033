import type { CSSProperties } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { HomeOverviewTask } from "../../../../../types";
import CommandBar, { CommandBarItemType } from "../../../../common/CommandBar";
import { useTableFilters } from "../../../../common/Table/v9";
import type { TableProps } from "../../../../common/Table/v9/Table";
import Table from "../../../../common/Table/v9/Table";
import { getColumns } from "./columns";

type Task = Omit<HomeOverviewTask, "metaInformation"> & { machineName: string };

type MyTasksProps = {
  items: Task[];
  isLoading: boolean;
  isError: boolean;
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  alignSelf: "center",
};

export const MyTasks = ({ items, isLoading, isError }: MyTasksProps) => {
  const { t } = useTranslation();
  const { filters, handleSearch } = useTableFilters<Task>({
    keys: ["subject", "description", "documents", "createdAt", "dueDate", "machineName"],
  });

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: "table-my-tasks",
        version: 3,
      },
      header: {
        title: t("My Tasks"),
      },
      items,
      filters,
      isLoading,
      isError,
      v8Columns: getColumns(t),
    }),
    [items, filters, isError, isLoading, t],
  );

  const headerItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <div style={containerTitleStyle}>{t("My Tasks")}</div>,
    },
  ];

  return (
    <div
      className='my-tasks-container'
      style={{
        marginBottom: 80,
      }}
    >
      <CommandBar items={headerItems} onSearch={handleSearch} />
      <Table {...tableProps} />
    </div>
  );
};
