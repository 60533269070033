import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { getFileIds, useFileDelete, useFileUpload, useLocationSearch } from "../../../../../Hooks";
import AddDocumentDialog from "../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import type { MachineCVComment } from "../../../../../types";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";
import type { TableProps } from "../../../../common/Table/v9/Table";
import Table from "../../../../common/Table/v9/Table";
import { useCommentTextAreaStyles } from "../../../../common/TextArea";
import UploaderButton from "../../../../common/Uploader/UploaderButton";
import { getColumns } from "../../../MachineCVInfoPage/components/DocumentsTable/columns";
import { useCommentAdd, useCommentUpdate } from "../../hooks/useCommentMutations";
import { useCommentsQuery } from "../../hooks/useCommentsQuery";
import { useTagsQuery } from "../../hooks/useTagsQuery";

type CommentDialogProps = {
  isReply?: boolean;
  comment?: MachineCVComment;
  onSuccess?: () => void;
  onDismiss?: () => void;
} & Omit<DialogProps, "children">;

const schema = z.object({
  text: z.string(),
  tags: z.any(),
});

export const CommentDialog: FC<CommentDialogProps> = (props) => {
  const { t } = useTranslation();
  const { textarea } = useCommentTextAreaStyles();
  const { open, comment, isReply } = props;
  const hidden = open;
  const [{ id: machineId }, ,] = useLocationSearch();
  const [files, setFiles] = useState(!isReply ? comment?.files || [] : []);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState(true);
  const toggleIsDocumentsDialogHidden = () => setIsDocumentsDialogHidden(!isDocumentsDialogHidden);

  const { tags } = useTagsQuery({ machineId: machineId as string });
  const formTagsOptions = (comment?.tags || []).map(({ id }) => id);

  useEffect(() => {
    if (hidden) {
      setFiles([]);
    } else {
      setFiles(!isReply ? comment?.files || [] : []);
    }

    reset({
      text: comment?.text,
      tags: formTagsOptions,
    });
  }, [hidden]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    ...(!!comment && {
      defaultValues: {
        text: comment?.text,
        tags: formTagsOptions,
      },
    }),
    schema,
  });

  const taskFields: FormItemProps[] = [
    {
      name: "text",
      type: FormItemType.TextArea,
      groupProps: {
        label: t("Message"),
        labelProps: {
          style: { minWidth: 160, marginRight: 8, alignSelf: "flex-start" },
        },
      },

      placeholder: t("Your message goes in here..."),
      resize: "both",
      className: textarea,
    },
    {
      name: "tags",
      groupProps: { label: t("Tags") },
      type: FormItemType.Dropdown,
      options: tags.map((tag) => ({ text: tag.name, key: tag.id as string })),
      allowFreeform: true,
      multiSelect: true,
      placeholder: t("Tag"),
    },
  ];

  const [{ id }] = useLocationSearch();
  const { refetch } = useCommentsQuery({ machineId: id || "" });
  const { updateCommentAsync } = useCommentUpdate();
  const { addCommentAsync } = useCommentAdd();
  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();

  const onAdd = async (data) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);
    const formTags = tags.filter(({ id }) => (data?.tags || []).includes(id));

    const submitData = {
      ...data,
      tags: formTags,
      fileIds: successIds,
    };

    await addCommentAsync({ machineId: id || "", data: submitData })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success(t("Comment successfully posted"));
        } else {
          notification.success(
            t(`Comment successfully posted with {{postedComments}} out of {{totalFiles}} files`, {
              postedComments: successIds.length,
              totalFiles: uploadResponse.length,
            }),
          );
        }

        refetch();
        onClose();
      })
      .catch(() => {
        notification.error(t("Error posting comment"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = async (data) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);
    const formTags = tags.filter(({ id }) => (data?.tags || []).includes(id));

    const submitData = {
      ...data,
      machineId: id as string,
      tags: formTags,
      fileIds: [...files.filter((file) => !file.isValid).map((file) => file.id), ...successIds],
    };

    await updateCommentAsync({
      id: comment?.id || "",
      data: submitData,
    })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success(t("Comment successfully updated"));
        } else {
          notification.success(
            t(`Comment successfully updated with {{postedComments}} out of {{totalFiles}} files`, {
              postedComments: successIds.length,
              totalFiles: uploadResponse.length,
            }),
          );
        }

        refetch();
        onClose();
      })
      .catch(() => {
        notification.error(t("Error updating comment"));
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = handleSubmit(async (data) => {
    if (comment && !isReply) {
      onEdit(data);
      return;
    }

    onAdd(data);
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-task-comments",
        version: 1,
      },
      items: files,
      perPage: 5,
      hidePerPage: true,
      v8Columns: getColumns({
        t,
        onRemove: (fileId) => {
          setFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [files, t],
  );

  const isEdit = useMemo(() => comment && !isReply, [comment, isReply]);

  return (
    <>
      <BaseDialog {...props} surfaceStyle={{ width: DialogSize.AUTO }} onOpenChange={onClose}>
        <BaseDialogTitle>{isEdit ? t("Edit comment") : t("New comment")}</BaseDialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            {renderFormItems(taskFields, {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <UploaderButton onClick={toggleIsDocumentsDialogHidden} />
            <Table {...tableProps} />
            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {isEdit ? t("Update comment") : t("Post comment")}
              </Button>
              <Button appearance='transparent' onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </>
  );
};
