import "./styles.scss";

import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { setMaxNumberDecimals } from "../../../../../../modules/common/components/InteractiveColorBar/methods";
import BaseDialog, { BaseDialogTitle } from "../../../../../common/Dialog";
import { Spin } from "../../../../../common/Spin";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import PerformanceStatus from "../PerformanceStatus";
import ModalTable from "./Table";

type PerformanceModalProps = {
  performance: any;
  isLoading: any;
  data: any;
  bestPerformance: any;
};

const PerformanceModal = ({
  data = [],
  bestPerformance,
  performance,
  isLoading,
}: PerformanceModalProps) => {
  const { t } = useTranslation();
  const { selectedTimeStamp, isIndicesModalOpen, updateStore } = usePerformanceStore(
    (store: any) => ({
      isIndicesModalOpen: store.isIndicesModalOpen,
      selectedTimeStamp: store.selectedTimeStamp,
      updateStore: store.updateStore,
    }),
  );

  const { performancePageKpiSignals, performancePageParamSignals } = performance;

  const dialogTitleTimeStamp = selectedTimeStamp
    ? format(selectedTimeStamp, "yyyy-MM-dd HH:mm:ss")
    : "";

  const selectedTimeStampData = useMemo(() => {
    return data.find(({ timeStamp }: { timeStamp: number }) => timeStamp === selectedTimeStamp);
  }, [selectedTimeStamp, data]);
  const progress = setMaxNumberDecimals(selectedTimeStampData?.value || 0, 2);

  const selectedTimeStampPeriod = selectedTimeStamp ? format(selectedTimeStamp, FORMAT) : "";
  const bestPerformanceTimeStamp = bestPerformance?.timeStamp
    ? format(bestPerformance?.timeStamp, FORMAT)
    : "";

  const commonProps = {
    bestPerformance: { timeStamp: bestPerformanceTimeStamp },
    worstPerformance: { timeStamp: selectedTimeStampPeriod },
    perfPeriodFilter: undefined,
    isLoading: false,
    tableSettings: {
      hasSelection: false,
      selection: null,
    },
    tableStyle: {
      minWidth: "100%",
      height: "100%",
    },
  };

  const kpiTableProps = {
    ...commonProps,
    title: "KPIs",
    tableKeyName: "kpi-modal-table",
    kpiSignals: performancePageKpiSignals,
    tableHeight: "34%",
  };

  const signalListTableProps = {
    ...commonProps,
    title: t("Signal list"),
    tableKeyName: "signals-modal-table",
    kpiSignals: performancePageParamSignals,
    tableHeight: "53%",
  };

  return (
    <BaseDialog
      hasResize
      open={isIndicesModalOpen}
      surfaceStyle={{ width: "750px", minWidth: "750px" }}
      onOpenChange={() => updateStore({ isIndicesModalOpen: false })}
    >
      <BaseDialogTitle>
        {t(`Load and Performance {{timestamp}}`, {
          timestamp: dialogTitleTimeStamp,
        })}
      </BaseDialogTitle>
      <DialogContent style={{ height: "100%" }}>
        <div className='performance-modal-wrap' style={{ height: "calc(100% - 48px)" }}>
          {isLoading ? (
            <Spin style={{ height: "100%" }} />
          ) : (
            <div className='performance-modal'>
              <PerformanceStatus
                withTitleProgress
                progress={progress}
                style={{ display: "flex", flexFlow: "column", gap: "8px" }}
              />

              <ModalTable {...kpiTableProps} />
              <ModalTable {...signalListTableProps} />
            </div>
          )}
        </div>
        <DialogActions>
          <Button appearance='primary' onClick={() => updateStore({ isIndicesModalOpen: false })}>
            {t("Close")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PerformanceModal;
