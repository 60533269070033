import { isNumber } from "lodash-es";

import { setMaxNumberDecimals } from "../../../../../../modules/common/components/InteractiveColorBar/methods";
import type { Column } from "../../../../../common/Table/v9";

export const getColumns = (t): Column[] => [
  {
    key: "signalDescription",
    fieldName: "signalDescription",
    name: t("Signal"),
    isSortable: true,
    isResizable: true,
    minWidth: 200,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => row.signalDescription || "-",
  },
  {
    key: "unit",
    fieldName: "unit",
    name: t("Unit"),
    isSortable: true,
    isResizable: true,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
  },
  {
    key: "average",
    fieldName: "average",
    name: t("Average"),
    isSortable: true,
    isResizable: true,
    minWidth: 60,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row.average ? setMaxNumberDecimals(row.average, 3) : "-"),
  },
  {
    key: "worst",
    fieldName: "worst",
    name: t("Worst"),
    isSortable: true,
    isResizable: false,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row.worst ? setMaxNumberDecimals(row.worst, 3) : "-"),
  },
  {
    key: "best",
    fieldName: "best",
    name: t("Best"),
    isSortable: true,
    isResizable: false,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row.best ? setMaxNumberDecimals(row.best, 3) : "-"),
  },
  {
    key: "current",
    fieldName: "current",
    name: t("Current"),
    isSortable: true,
    isResizable: false,
    minWidth: 60,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row.current ? setMaxNumberDecimals(row.current, 3) : "-"),
  },
  {
    key: "currentBest",
    fieldName: "currentBest",
    name: t("Current Best"),
    isSortable: true,
    isResizable: false,
    minWidth: 70,
    calculatedWidth: 0,
    flexGrow: 1,
  },
  {
    key: "diff",
    fieldName: "diff",
    name: t("Diff %"),
    isSortable: true,
    isResizable: false,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row.diff || row.diff === 0 ? `${row.diff}%` : "-"),
  },
];

export const prepareCurrentBestValue = (current: any, best: any) => {
  const currentBest = parseFloat(current) - parseFloat(best);

  if (isNumber(currentBest) && !isNaN(currentBest)) {
    return setMaxNumberDecimals(currentBest, 3);
  }

  return "-";
};

export const prepareDiffValue = (currentBest: any, best: any) => {
  const diff = (parseFloat(currentBest) / parseFloat(best)) * 100;

  if (isNumber(diff) && !isNaN(diff)) {
    return setMaxNumberDecimals(diff, 3);
  }

  return null;
};
