import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueries, useQuery } from "@tanstack/react-query";
import { differenceInDays } from "date-fns";

import { apiService } from "../../modules/common";
import DataTypeEnum from "../../modules/common/constants/DataTypeEnum";
import { queryClient } from "../../modules/core";
import type { DataPoint } from "../../types";

const setCacheTime = 1000 * 60 * 60 * 60; // 60 minutes
interface UseTrendMeasuredDataInAPeriodArgs {
  machineId: string | undefined;
  signalId: string | undefined;
  startDate: string;
  endDate: string;
  numberOfPoints: number;
  signalType?: string;
  onlyZip?: boolean;
  queryKey?: string[];
  refreshInterval?: any | undefined;
  options?: any;
}

type UseTrendMeasuredDataInAPeriodOptions =
  | (Omit<
      UseQueryOptions<DataPoint[], unknown, DataPoint[], any>,
      "initialData" | "queryFn" | "queryKey" | "disabled"
    > & { initialData?: (() => undefined) | undefined; disabled?: boolean })
  | undefined;

const getReqFunc = (args: UseTrendMeasuredDataInAPeriodArgs) => {
  const { startDate, endDate, signalType, onlyZip, refreshInterval, signalId } = args;

  const currentTime = new Date();

  const month = currentTime.getMonth() + 1;

  const year = currentTime.getFullYear();

  let moreThanOneMonth = false;

  if (differenceInDays(new Date(endDate), new Date(startDate)) > 32) {
    moreThanOneMonth = true;
  }

  if (refreshInterval?.key !== "off") {
    if (signalId) {
      return apiService.measuredDataRead.getTrendMeasuredDataInAMonthZip({
        signalId,
        month,
        year,
      });
    }
  }

  if (!onlyZip && (moreThanOneMonth || signalType === DataTypeEnum.FastTrend)) {
    return apiService.measuredDataDownsampled.getTrendMeasuredDataInAPeriod(args);
  }

  return apiService.measuredDataDownsampled.getTrendMeasuredZipDataInAPeriod(args);
};

export const useTrendMeasuredDataInAPeriod = (
  args: UseTrendMeasuredDataInAPeriodArgs,
  options?: UseTrendMeasuredDataInAPeriodOptions,
) => {
  const queryKey = args?.queryKey || [
    "trend-measured-data-in-a-period",
    args.machineId,
    args.signalId,
    args.startDate,
    args.endDate,
    args.numberOfPoints.toString(),
    args.refreshInterval?.key,
  ];

  return useQuery(queryKey, () => getReqFunc(args), {
    staleTime: Infinity,
    cacheTime: setCacheTime,
    ...options,
  });
};

export const useMultipleTrendMeasuredDataInAPeriod = (queriesArgs: any) => {
  const queries = queriesArgs.map((args: UseTrendMeasuredDataInAPeriodArgs) => {
    const queryKey = args?.queryKey || [
      "trend-measured-data-in-a-period",
      args.machineId,
      args.signalId,
      args.startDate,
      args.endDate,
      args.numberOfPoints.toString(),
      args.refreshInterval?.key,
    ];

    return {
      queryKey,
      queryFn: () => getReqFunc(args),
      staleTime: Infinity,
      cacheTime: setCacheTime,
      ...args?.options,
    };
  });

  return useQueries({
    queries,
  });
};

export const prefetchTrendMeasuredDataInAPeriod = async (
  args: UseTrendMeasuredDataInAPeriodArgs,
) => {
  const queryKey = args?.queryKey || [
    "trend-measured-data-in-a-period",
    args.machineId,
    args.signalId,
    args.startDate,
    args.endDate,
    args.numberOfPoints.toString(),
    args.refreshInterval?.key,
  ];

  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery(queryKey, () => getReqFunc(args), {
    staleTime: Infinity,
    cacheTime: setCacheTime,
  });
};
