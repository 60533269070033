import "./Table.scss";

import type {
  DataGridProps,
  SelectionItemId,
  TableColumnId,
  TableRowId,
} from "@fluentui/react-components";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { ArrowSortDownFilled, ArrowSortUpFilled } from "@fluentui/react-icons";
import cn from "classnames";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { NoData } from "../../NoData";
import Pagination from "../../Pagination";
import { type TableColumn, getDataGridColumns, getTableColumns } from "./columns";
import { filter } from "./filter";
import Header from "./Header";
import ResizableTableHeaderCell from "./ResizableTableHeaderCell";
import useTableStyles from "./Table.styles";
import type { Column, Filter, FiltersAppliedProps } from "./types";
import { usePagination } from "./usePagination";
import useReordering from "./useReordering";
import useResizing from "./useResizing";
import useSorting from "./useSorting";

export const getRowId = (item) => item?.id;

export type TableProps = Omit<
  DataGridProps,
  "columns" | "sortable" | "sortState" | "onSortChange" | "selectedItems"
> & {
  persistOpts: {
    key: string;
    version: number;
  };
  tableColumns?: TableColumn[];
  v8Columns?: Column[];
  header?: {
    title: string;
    isExportable?: boolean;
  };
  filters?: Filter[];
  onFiltersApplied?: (p: FiltersAppliedProps) => void;
  isLoading?: boolean;
  isError?: boolean;
  perPage?: number;
  hidePerPage?: boolean;
  resetPagination?: boolean;
  getIsSelectable?: (item: any) => boolean;
  onRowClick?: (item: any) => void;
  getRowStyle?: (item: any) => DataGridProps["style"];
  selectionMaxThreshold?: number;
  onSelectionChanged?: (selectedItems: any[], selectableItems: any[]) => void;
  defaultSelectedItems?: Array<SelectionItemId> | Array<any>;
  selection?: {
    selectionMode: DataGridProps["selectionMode"];
    onSelectionChange?: DataGridProps["onSelectionChange"];
    onSelectionChanged?: (selectedItems: any[], selectableItems: any[]) => void;
  };
};

// getRowId={(item) => item.id} WHY?

const Table: FC<TableProps> = ({
  as,
  tableColumns: tableColumnsProp,
  v8Columns,
  columnSizingOptions: columnSizingOptionsProps,
  resizableColumns: resizableColumnsProp,
  items,
  filters,
  onFiltersApplied,
  isLoading = false,
  isError = false,
  perPage: _perPage,
  header: _header,
  hidePerPage = false,
  resetPagination = false,
  getIsSelectable: getIsSelectableProps = () => true,
  selection,
  selectionMode: selectionModeProp,
  onSelectionChange: onSelectionChangeProp,
  onSelectionChanged: onSelectionChangedProp,
  // selectedItems: selectedItemsProp,
  defaultSelectedItems: defaultSelectedItemsProp = [],
  getRowId,
  onRowClick,
  getRowStyle,
  persistOpts,
  selectionMaxThreshold: selectionMaxThresholdProp,
  ...props
}) => {
  const tableRef = useRef<HTMLDivElement>();

  const classes = useTableStyles();
  const refMap = useRef<Record<string, HTMLElement | null>>({});

  const tableColumns = useMemo(
    () => (v8Columns ? getTableColumns(v8Columns) : tableColumnsProp),
    [v8Columns, tableColumnsProp],
  );

  const getTableColumn = (columnId: TableColumnId) => {
    const tableColumn = tableColumns.find((item) => item.columnId === columnId);

    return tableColumn;
  };

  const columns = useMemo(() => getDataGridColumns(tableColumns), [tableColumns]);

  // === Sorting ===
  const { sortedItems, toggleSort, getColumnSortDirection } = useSorting({ items, persistOpts });

  // === Filtering ===
  const filteredItems = useMemo(() => {
    if (!filters || filters.length === 0) {
      onFiltersApplied && onFiltersApplied({ list: sortedItems, isFiltered: false });
      return sortedItems;
    }

    const filteredResult = filter([...sortedItems], filters);

    onFiltersApplied && onFiltersApplied({ list: filteredResult, isFiltered: true });

    return filteredResult;
  }, [sortedItems, filters, onFiltersApplied]);

  const hasNoItems = isError || (!isLoading && filteredItems.length === 0);

  // === Pagination ===
  const { total, current, perPage, onChanged, setPerPage } = usePagination({
    total: filteredItems.length,
    isLoading,
    resetPagination,
    perPage: _perPage,
  });

  const pageItems = useMemo(
    () => filteredItems.slice(current * perPage, (current + 1) * perPage),
    [filteredItems, current, perPage],
  );

  const dataGridItems = useMemo(
    () => (isLoading ? Array(perPage).fill({}) : pageItems),
    [pageItems, isLoading, perPage],
  );

  // === Selection ===
  const onSelectionChange = useMemo(
    () => (selection ? selection.onSelectionChange : onSelectionChangeProp),
    [selection, onSelectionChangeProp],
  );

  const onSelectionChanged = useMemo(
    () => (selection ? selection.onSelectionChanged : onSelectionChangedProp),
    [selection, onSelectionChangedProp],
  );

  const selectionMode = useMemo(() => {
    const mode = selection ? selection.selectionMode : selectionModeProp;

    if (mode) {
      return mode;
    }

    const hasSelectionHandler = Boolean(onSelectionChange) || Boolean(onSelectionChanged);

    return hasSelectionHandler ? "multiselect" : undefined;
  }, [onSelectionChange, onSelectionChanged, selection, selectionModeProp]);

  const defaultSelectedItemsRowId = useMemo<Array<SelectionItemId>>(() => {
    if (!defaultSelectedItemsProp || defaultSelectedItemsProp.length === 0) {
      return [];
    }

    return defaultSelectedItemsProp.map((item, index) => {
      // is SelectionItemId
      if (typeof item === "string" || typeof item === "number") {
        return item;
      }

      return getRowId ? getRowId(item) : index;
    });
  }, [defaultSelectedItemsProp, getRowId]);

  const [selectedRows, setSelectedRows] = useState<Set<TableRowId>>(
    new Set<SelectionItemId>(defaultSelectedItemsRowId),
  );

  useEffect(() => {
    setSelectedRows(new Set<SelectionItemId>(defaultSelectedItemsRowId));
  }, [JSON.stringify(defaultSelectedItemsRowId)]);

  const selectionMaxThreshold = useMemo(() => {
    if (selectionMaxThresholdProp) {
      return selectionMaxThresholdProp;
    }

    return dataGridItems.length + 1;
  }, [dataGridItems, selectionMaxThresholdProp]);

  const getIsSelectable = useCallback(
    (item: any) => {
      if (selectedRows.size === selectionMaxThreshold) {
        return selectedRows.has(getRowId(item));
      }

      return getIsSelectableProps(item);
    },
    [getIsSelectableProps, getRowId, selectedRows, selectionMaxThreshold],
  );

  const handleSelectionChange = useCallback<DataGridProps["onSelectionChange"]>(
    (event, data) => {
      if (data.selectedItems.size > selectionMaxThreshold) {
        return;
      }

      const selectedItemsList = [];

      for (const rowId of data.selectedItems) {
        const selectedItem = dataGridItems.find((item, index) => {
          const itemRowId = getRowId ? getRowId(item) : index;

          return rowId === itemRowId;
        });

        selectedItemsList.push(selectedItem);
      }

      const onlySelectableItems = selectedItemsList.filter((item) => getIsSelectable(item));
      const onlySelectableItemsRowId = onlySelectableItems.map((item, index) =>
        getRowId ? getRowId(item) : index,
      );

      const onlySelectableItemsSet = new Set<SelectionItemId>(onlySelectableItemsRowId);

      setSelectedRows(onlySelectableItemsSet);

      if (onSelectionChange && event) {
        onSelectionChange(event, { selectedItems: onlySelectableItemsSet });
      }

      if (onSelectionChanged) {
        onSelectionChanged(
          onlySelectableItems,
          dataGridItems.filter((item) => getIsSelectable(item)),
        );
      }
    },
    [onSelectionChange, onSelectionChanged, dataGridItems, getRowId, getIsSelectable],
  );

  useEffect(() => {
    setSelectedRows(new Set<SelectionItemId>([]));
  }, [persistOpts.key]);

  const hasSelection = useMemo(() => Boolean(selectionMode), [selectionMode]);

  const handleRowClick = (item: any) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  // === Reordering ===
  const { orderedColumns, getColumnIndex, handleDragStart, allowDrop, handleDragOver } =
    useReordering({ columns, persistOpts });

  // === Resizing ===
  const { columnSizingOptions, resizableColumns, handleColumnResize } = useResizing({
    hasSelection,
    persistOpts,
    tableRef,
    tableColumns,
    columnSizingOptions: columnSizingOptionsProps,
    resizableColumns: resizableColumnsProp,
  });

  return (
    <div ref={tableRef} className={cn(props.className, "table-root", classes.root)}>
      {!!_header && (
        <Header
          data={filteredItems}
          columns={orderedColumns}
          title={_header.title}
          isExportable={_header.isExportable}
          exportDisabled={isLoading || isError}
        />
      )}
      <div className={cn("data-grid-wrapper", classes.tableWrapper)}>
        <DataGrid
          focusMode='composite'
          {...props}
          sortable={false}
          items={dataGridItems}
          columns={orderedColumns}
          columnSizingOptions={columnSizingOptions}
          resizableColumns={resizableColumns}
          className={classes.table}
          selectionMode={selectionMode}
          selectedItems={selectedRows}
          getRowId={getRowId}
          onSelectionChange={handleSelectionChange}
          onColumnResize={handleColumnResize}
        >
          <DataGridHeader>
            <DataGridRow
              {...(hasSelection
                ? { selectionCell: { checkboxIndicator: { "aria-label": "Select all rows" } } }
                : {})}
            >
              {(column, dataGrid) => {
                const { columnId, renderHeaderCell } = column;

                return (
                  <ResizableTableHeaderCell column={column} dataGridContextValue={dataGrid}>
                    <DataGridHeaderCell
                      // Do this in ResizableTableHeaderCell and use forwardRef to DataGridHeaderCell
                      {...(dataGrid.resizableColumns
                        ? { ref: (el) => (refMap.current[columnId] = el) }
                        : {})}
                      draggable
                      className={cn(
                        {
                          "is-sortable": Boolean(getTableColumn(columnId)?.isSortable),
                        },
                        classes.headerCell,
                      )}
                      onDragStart={(e) => handleDragStart(e, getColumnIndex(column))}
                      onDragOver={(e) => allowDrop(e, getColumnIndex(column))}
                      onDrop={(e) => handleDragOver(e, getColumnIndex(column))}
                      onClick={() => {
                        toggleSort(columnId);
                      }}
                    >
                      {renderHeaderCell()}
                      {getColumnSortDirection(columnId) === "ascending" && <ArrowSortUpFilled />}
                      {getColumnSortDirection(columnId) === "descending" && <ArrowSortDownFilled />}
                    </DataGridHeaderCell>
                  </ResizableTableHeaderCell>
                );
              }}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<any>>
            {({ item, rowId }) => (
              <DataGridRow<any>
                key={rowId}
                onClick={() => handleRowClick(item)}
                {...(hasSelection
                  ? {
                      selectionCell: {
                        checkboxIndicator: { "aria-label": "Select row" },
                        className: cn(
                          { "is-disabled": !getIsSelectable(item) },
                          classes.selectionCell,
                        ),
                      },
                    }
                  : {})}
                {...(getRowStyle ? { style: getRowStyle(item) } : {})}
              >
                {({ columnId, renderCell }) => (
                  <DataGridCell
                    key={`${rowId}-${columnId}`}
                    className={cn(
                      getTableColumn(columnId)?.getCellClassName
                        ? getTableColumn(columnId)?.getCellClassName(item)
                        : "",
                      classes.cell,
                    )}
                  >
                    {isLoading ? (
                      <Skeleton aria-label='Loading Content' style={{ width: "100%" }}>
                        <SkeletonItem />
                      </Skeleton>
                    ) : (
                      <div className={classes.cellShell}>{renderCell(item)}</div>
                    )}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
          {hasNoItems && (
            <NoData
              className='table-no-data'
              style={{
                marginTop: 16,
                marginBottom: 32,
              }}
            />
          )}
        </DataGrid>
      </div>
      {!hasNoItems && (
        <Pagination
          hidePerPage={hidePerPage}
          setPerPage={setPerPage}
          current={current}
          total={total}
          perPage={perPage}
          style={{
            marginTop: 16,
          }}
          onChange={(page) => {
            // selection.setAllSelected(false);
            onChanged(page);
          }}
        />
      )}
    </div>
  );
};

export default Table;
