/* eslint-disable react-hooks/exhaustive-deps */
import { AddRegular, DocumentAddRegular } from "@fluentui/react-icons";
import {
  AddToShoppingListIcon,
  PageAddIcon,
  StreamingIcon,
  ZipFolderIcon,
} from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../Hooks";
import { format } from "../../../schema/Utils";
import { CommandBarItemType } from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import type { Column } from "../../common/Table/v9";
import { useTableFilters } from "../../common/Table/v9";
import Table from "../../common/Table/v9/Table";
import { withLoadingPanelHOC } from "../../Generic/HOCs";
import { selectMachinesToList } from "../../Machines/reducer";
import { ImagesAPI } from "../api";
import ImagesPivotContainer from "../Generic/ImagesPivotContainer";
import { ImageType } from "../models";
import {
  UploadImageFlsDialog,
  UploadImageWirelessDialog,
  UploadImageWithMachineDialog,
} from "./UploadImageDialogs";

type TableItem = {
  corporationName: string;
  companyName: string;
  projectName: string;
  machineName: string;
  fileName: string;
  cfgName: string;
  signalType: string;
  signalCount: number;
  timeStampAdded: string;
  timeStampUpdated: string;
};

type ImageTableProps = {
  hasAddPermissions: boolean;
};

type ActionDialogProps = {
  imageType: ImageType;
  onClose: (listHasChanged: boolean) => void;
};

const timeoutDelay: number = 60000 * 5;

const getTableColumns: (t) => Column[] = (t) => [
  {
    key: "corporationName",
    name: t("Corporation"),
    fieldName: "corporationName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "companyName",
    name: t("Company"),
    fieldName: "companyName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "projectName",
    name: t("Project"),
    fieldName: "projectName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "machineName",
    name: t("Machine"),
    fieldName: "machineName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "fileName",
    name: t("File Name"),
    fieldName: "fileName",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "cfgName",
    name: t("Config Name"),
    fieldName: "cfgName",
    minWidth: 100,
    isSortable: true,
  },

  {
    key: "signalType",
    name: t("Signal Type"),
    fieldName: "signalType",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "signalCount",
    name: t("Signal Count"),
    fieldName: "signalCount",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "timeStampAdded",
    name: t("Added At"),
    fieldName: "timeStampAdded",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "timeStampUpdated",
    name: t("Updated At"),
    fieldName: "timeStampUpdated",
    minWidth: 100,
  },
];

/**
 * Gets the command bar items props.
 * @param onAddImage Method called when the Add Image button is clicked.
 * @param onAddZipImage Method called when the Add Zip Image button is clicked.
 * @param onAddGZipImage Method called when the Add GZip Image button is clicked.
 * @param onAddBusyBeeImage Method called when the Add BusyBee Image button is clicked.
 * @param onAddFlsImage Method called when the Add FLS Image button is clicked.
 * @param onAddWirelessImage Method called when the Add Wireless Image button is clicked.
 * @returns The Command bar item props list.
 */
const getBarItems = (
  t,
  onAddImage: () => void,
  onAddZipImage: () => void,
  onAddGZipImage: () => void,
  onAddBusyBeeImage: () => void,
  onAddFlsImage: () => void,
  onAddWirelessImage: () => void,
): any => {
  const actionProps: any = [
    {
      key: "addImage",
      text: t("Add Image"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAddImage,
    },
    {
      key: "addZipImage",
      text: t("Add Zip Image"),
      type: CommandBarItemType.Button,
      icon: <ZipFolderIcon />,
      onClick: onAddZipImage,
    },
    {
      key: "addGzipImage",
      text: t("Add GZip Image"),
      type: CommandBarItemType.Button,
      icon: <PageAddIcon />,
      onClick: onAddGZipImage,
    },
    {
      key: "addBusyBeeImage",
      text: t("Add BusyBee Image"),
      type: CommandBarItemType.Button,
      icon: <DocumentAddRegular />,
      onClick: onAddBusyBeeImage,
    },
    {
      key: "addFlsImage",
      text: t("Add FLS Image"),
      type: CommandBarItemType.Button,
      icon: <AddToShoppingListIcon />,
      onClick: onAddFlsImage,
    },
    {
      key: "addWirelessImage",
      text: t("Add Wireless Image"),
      type: CommandBarItemType.Button,
      icon: <StreamingIcon />,
      onClick: onAddWirelessImage,
    },
  ];

  return actionProps;
};

/**
 * Gets the action dialog component.
 * @param selectedItem The selected item object.
 * @param confignames The confignames reference list used for validation
 * @param onClose Method called to close the selected action dialog
 * @returns The action dialog component.
 */
const ActionDialog = ({ imageType, onClose }: ActionDialogProps) => {
  const { t } = useTranslation();
  let result: JSX.Element = null;

  switch (imageType) {
    case ImageType.Image:
      result = (
        <UploadImageWithMachineDialog
          title={t("Add Image")}
          accept={".img, .sav"}
          imageType={ImageType.Image}
          onClose={onClose}
        />
      );
      break;
    case ImageType.Zip:
      result = (
        <UploadImageWithMachineDialog
          title={t("Add Zip Image")}
          accept={".zip"}
          imageType={ImageType.Zip}
          onClose={onClose}
        />
      );
      break;
    case ImageType.GZip:
      result = (
        <UploadImageWithMachineDialog
          title={t("Add GZip Image")}
          accept={".gz"}
          imageType={ImageType.GZip}
          onClose={onClose}
        />
      );
      break;
    case ImageType.BusyBee:
      result = (
        <UploadImageWithMachineDialog
          title={t("Add BusyBee Image")}
          accept={".sav"}
          imageType={ImageType.BusyBee}
          onClose={onClose}
        />
      );
      break;
    case ImageType.FLS:
      result = <UploadImageFlsDialog onClose={onClose} />;
      break;

    case ImageType.Wireless:
      result = <UploadImageWirelessDialog onClose={onClose} />;
      break;
  }

  return result;
};

const ImagesTable = ({ hasAddPermissions }: ImageTableProps) => {
  const { t } = useTranslation();
  const [imageType, setImageType] = useState<ImageType>(ImageType.None);
  const [listHasChanged, setListHasChanged] = useState<boolean>(true);
  const [tableItems, setTableItems] = useState<TableItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const machines = useAppSelector(selectMachinesToList);
  const { filters, handleSearch } = useTableFilters<TableItem>({
    keys: ["corporationName", "companyName", "projectName", "machineName", "fileName", "cfgName"],
  });

  // Configures a timeout to update the images table.
  useEffect(() => {
    if (listHasChanged) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setListHasChanged(true);
    }, timeoutDelay);

    return () => clearTimeout(timeoutId);
  }, [listHasChanged]);

  // Get the images information items
  useEffect(() => {
    if (machines.length === 0 || !listHasChanged) {
      return;
    }

    setIsLoading(true);
    ImagesAPI.list().then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the images list: {{statusText}}`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      const data: TableItem[] = response.data.map((item) => {
        const machine = machines?.find((m) => m.id === item.machineId);
        const tableItem: TableItem = {
          corporationName: machine?.corporationName ?? "",
          companyName: machine?.companyName ?? "",
          projectName: machine?.projectName ?? "",
          machineName: machine?.name ?? "",
          fileName: item.fileName,
          cfgName: item.cfgName,
          signalType: item.signalType,
          signalCount: item.signalCount,
          timeStampAdded: format(new Date(item.timeStampAdded)),
          timeStampUpdated: format(new Date(item.timeStampUpdated)),
        };

        return tableItem;
      });

      setTableItems(data);
    });

    setListHasChanged(false);
  }, [machines.length, listHasChanged]);

  // Handlers
  const onClose = (listHasChanged: boolean) => {
    setImageType(ImageType.None);
    listHasChanged && setListHasChanged(listHasChanged);
  };

  return (
    <ImagesPivotContainer
      title={t("Images")}
      hasPermissions={hasAddPermissions}
      commandBarItemProps={getBarItems(
        t,
        () => setImageType(ImageType.Image),
        () => setImageType(ImageType.Zip),
        () => setImageType(ImageType.GZip),
        () => setImageType(ImageType.BusyBee),
        () => setImageType(ImageType.FLS),
        () => setImageType(ImageType.Wireless),
      )}
      handleSearch={handleSearch}
    >
      {withLoadingPanelHOC(
        t,
        !isLoading,
        <Table
          items={tableItems}
          v8Columns={getTableColumns(t)}
          header={{ title: t("Images") }}
          persistOpts={{
            key: "images-table",
            version: 2,
          }}
          filters={filters}
        />,
      )}

      <ActionDialog imageType={imageType} onClose={onClose} />
    </ImagesPivotContainer>
  );
};

export default ImagesTable;
