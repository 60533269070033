import { Button, Text } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import {} from "../../../../schema/Constants";
import { Status } from "../../../../schema/status";
import { convertCSVtoXLSX } from "../../../../schema/Utils";
import BaseCommandBar, { computeCommandBarItems } from "../../../common/CommandBar";
import Table from "../../../common/Table/v9";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { Stack } from "../../../Stack";
import type { UpdateMachine } from "../../models";
import {
  getColumnsConditions,
  getColumnsFixCondition,
  getColumnsFrequencyCatalogue,
  getColumnsStandStill,
  getCommandBarItems,
} from "../columnsHelper";
import { frequencyCatalogueExport } from "../FrequencyCatalogue/api";
import { FrequencyCatalogueGroupTable } from "../FrequencyCatalogue/FrequencyCatalogueGroupTable";
import { IconAndDrawingsMachine } from "../IconAndDrawings/IconAndDrawingsMachine";
import { listAsyncMachDetails, selectMachDetails, selectMachineDetailsStatus } from "../reducer";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

export default function GeneralConfigsContent({
  fixedCondition,
  setActionFixCondition,
  standStillSignals,
  setShowEditStandstill,
  frequencyCatalogue,
  setActionFrequencyCatalogue,
  setActionFrequencyCatalogueMachine,

  setSelected,
  setActionFrequencyCatalogueGroup,
}) {
  const { t } = useTranslation();
  const { id } = useParams();

  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectMachDetails);

  return (
    <Stack style={{ margin: "50px", gap: 50 }}>
      <Stack.Item>
        <IconAndDrawingsMachine
          machine={items as UpdateMachine}
          iconId={items?.iconId}
          machineDrawings={items?.drawingIds}
          onReload={() => dispatch(listAsyncMachDetails(id))}
        />
      </Stack.Item>

      <Stack.Item>
        <Text style={titleStylePivot}>{t("Standstill settings:")}</Text>
        <Stack>
          <Table
            hidePerPage
            persistOpts={{
              key: "table-standStill",
              version: 2,
            }}
            header={{
              title: t("Primary Threshold"),
            }}
            items={standStillSignals?.primary?.signal ? [standStillSignals?.primary?.signal] : []}
            v8Columns={getColumnsStandStill(t)}
            isLoading={status === Status.loading}
            isError={status === Status.error || standStillSignals === undefined}
          />

          {items?.standstillSettings?.secondary ? (
            <Table
              hidePerPage
              persistOpts={{
                key: "table-standStill",
                version: 2,
              }}
              header={{
                title: t("Secondary Threshold"),
              }}
              items={[standStillSignals?.secondary?.signal]}
              v8Columns={getColumnsStandStill(t)}
              isLoading={standStillSignals?.secondary?.signal === undefined}
              isError={status === Status.error}
            />
          ) : null}

          <Button
            appearance='primary'
            onClick={() => {
              setShowEditStandstill(true);
            }}
          >
            {items?.standstillSettings ? t("Update") : t("Add")}
          </Button>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Text style={titleStylePivot}>{t("Machine condition:")}</Text>
        <Table
          hidePerPage
          persistOpts={{
            key: "table-condition",
            version: 2,
          }}
          header={{
            title: t("Machine condition"),
          }}
          items={fixedCondition ?? []}
          v8Columns={getColumnsFixCondition({
            t,
            onEdit(fixCondition) {
              setActionFixCondition({
                data: fixCondition,
                context: "edit",
              });
            },
          })}
          isLoading={fixedCondition === undefined}
          isError={status === Status.error}
        />
      </Stack.Item>

      <Stack.Item>
        <Text style={titleStylePivot}>{t("Machine conditions:")}</Text>
        <Table
          hidePerPage
          persistOpts={{
            key: "table-conditions",
            version: 2,
          }}
          header={{
            title: t("Conditions"),
          }}
          items={items?.conditions ?? []}
          v8Columns={getColumnsConditions(t)}
          isLoading={items?.conditions === undefined}
          isError={status === Status.error}
        />
      </Stack.Item>
      <Stack.Item>
        <Text style={titleStylePivot}>
          {t("Frequency Catalogue Reference Speed In Rpm {{referenceSpeedInRpm}}:", {
            referenceSpeedInRpm: items?.referenceSpeedInRpm || "",
          })}
        </Text>

        <BaseCommandBar
          items={computeCommandBarItems(
            getCommandBarItems({
              t,
              title: t("Frequency Catalogue Groups:"),
              xlsxOnly: true,
              addButton: auth.metaDataContributor,
              onAdd() {
                setActionFrequencyCatalogue({
                  data: undefined,
                  context: "addWithGroup",
                });
              },
              onDelete() {
                setActionFrequencyCatalogueMachine({
                  context: "delete",
                });
              },
              onChange(files) {
                setSelected({
                  context: "frequency catalogue",
                  file: files.at(0),
                });
              },
              importButton: auth.metaDataContributor,
              exportable: auth.metaDataReader,
              onExport() {
                frequencyCatalogueExport(id).then((resp) => {
                  convertCSVtoXLSX(resp, "FrequencyCatalogue_" + id + ".xlsx");
                });
              },
            }),
          )}
        />

        {frequencyCatalogue && (
          <FrequencyCatalogueGroupTable
            groupsAndItems={frequencyCatalogue}
            columns={getColumnsFrequencyCatalogue({
              t,
              hasActions: auth.metaDataContributor,
              onEdit(freq) {
                setActionFrequencyCatalogue({ data: freq, context: "edit" });
              },
              onDelete(freq) {
                setActionFrequencyCatalogue({
                  data: freq,
                  context: "delete",
                });
              },
            })}
            onAddItem={(groupName) => {
              setActionFrequencyCatalogue({
                data: groupName,
                context: "add",
              });
            }}
            onDeleteGroup={(groupName) => {
              setActionFrequencyCatalogueGroup({
                data: frequencyCatalogue.groups.find((group) => group.name === groupName)?.id,
                context: "delete",
              });
            }}
            onEditGroup={(groupName) => {
              setActionFrequencyCatalogueGroup({
                data: frequencyCatalogue.groups.find((group) => group.name === groupName),
                context: "edit",
              });
            }}
          />
        )}
      </Stack.Item>
    </Stack>
  );
}
