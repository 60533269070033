import React, { PropsWithChildren, createContext } from "react";
import {
  RequestSubmissionMachine,
  RequestWirelessGatewayAddAux,
  RequestWirelessGatewayUpdateAux,
  SubmissionSupport,
} from "../models";
import { intelligentMerge } from "./utils/submissionHelpers";

interface SupportContextValue {
  support: SubmissionSupport | undefined;
  setSupport: React.Dispatch<
    React.SetStateAction<SubmissionSupport | undefined>
  >;
  updateSupportEle: (
    partialUpdate: Partial<
      | RequestSubmissionMachine
      | RequestWirelessGatewayAddAux
      | RequestWirelessGatewayUpdateAux
    > & { matchUniqueId: string }
  ) => void;
}

export const SupportContext = createContext<SupportContextValue>({
  support: undefined,
  setSupport: () => {},
  updateSupportEle: () => {},
});

interface SupportProviderProps {
  support: SubmissionSupport | undefined;
  setSupport: React.Dispatch<
    React.SetStateAction<SubmissionSupport | undefined>
  >;
}

export const SupportProvider = ({
  children,
  support,
  setSupport,
}: PropsWithChildren<SupportProviderProps>) => {
  const updateSupportEle = (
    partialUpdate: Partial<
      | RequestSubmissionMachine
      | RequestWirelessGatewayAddAux
      | RequestWirelessGatewayUpdateAux
    > & { matchUniqueId: string }
  ) => {
    if (!support) return;

    const newSupport = { ...support };

    const machineIndex = newSupport.machinesSupport.findIndex(
      (m) => m.matchUniqueId === partialUpdate.matchUniqueId
    );
    if (machineIndex !== -1) {
      newSupport.machinesSupport[machineIndex] = intelligentMerge(
        newSupport.machinesSupport[machineIndex],
        partialUpdate
      ) as RequestSubmissionMachine;
      setSupport(newSupport);
      return;
    }

    const gatAddIndex = newSupport.gatewaysSupportToAdd.findIndex(
      (g) => g.matchUniqueId === partialUpdate.matchUniqueId
    );
    if (gatAddIndex !== -1) {
      newSupport.gatewaysSupportToAdd[gatAddIndex] = intelligentMerge(
        newSupport.gatewaysSupportToAdd[gatAddIndex],
        partialUpdate
      ) as RequestWirelessGatewayAddAux;
      setSupport(newSupport);
      return;
    }

    const gatUpdateIndex = newSupport.gatewaysSupportToUpdate.findIndex(
      (g) => g.matchUniqueId === partialUpdate.matchUniqueId
    );
    if (gatUpdateIndex !== -1) {
      newSupport.gatewaysSupportToUpdate[gatUpdateIndex] = intelligentMerge(
        newSupport.gatewaysSupportToUpdate[gatUpdateIndex],
        partialUpdate
      ) as RequestWirelessGatewayUpdateAux;
      setSupport(newSupport);
      return;
    }

    console.warn(
      "Non found the provided matchUniqueId:",
      partialUpdate.matchUniqueId
    );
  };

  const value: SupportContextValue = {
    support,
    setSupport,
    updateSupportEle,
  };

  return (
    <SupportContext.Provider value={value}>{children}</SupportContext.Provider>
  );
};
