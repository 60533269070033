import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
} from "@fluentui/react-components";
import { MoreVerticalRegular } from "@fluentui/react-icons";
import type { CSSProperties, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as ReactRouterDOM from "react-router-dom";

import { useLocationSearch } from "../../../../../Hooks";
import type { Condition } from "../../../../../modules/machine-cv/constants";
import { conditionColor, getConditionFormat } from "../../../../../modules/machine-cv/constants";
import { Stack } from "../../../../Stack";

interface StatusBadgeProps {
  onAddTask: () => void;
  onAddDocument: () => void;
  data: {
    value: string | undefined;
    id: string | null;
  };
  style?: CSSProperties;
  customBadge?: ReactElement;
}

const StatusBadge = ({ data, onAddTask, onAddDocument, customBadge }: StatusBadgeProps) => {
  const { t } = useTranslation();
  const navigate = ReactRouterDOM.useNavigate();

  const [{ search }] = useLocationSearch();

  const actionOptions = [
    {
      key: "add_task",
      text: t("Add task"),
      onClick: onAddTask,
    },
    {
      key: "add_document",
      text: t("Upload document"),
      onClick: onAddDocument,
    },
    {
      key: "view_trend",
      text: t("View Trend"),
      onClick: () => navigate(`/trend-view${search}`),
    },
    {
      key: "raw_data",
      text: t("View Raw data"),
      onClick: () => navigate(`/raw-data${search}`),
    },
  ];

  return (
    <Stack style={{ width: "100%" }}>
      <Stack key={data.id} horizontal>
        <Stack
          horizontal
          horizontalAlign='center'
          verticalAlign='center'
          style={{
            ...badgeContainerStyles,
            backgroundColor: conditionColor[data.value as Condition],
          }}
        >
          {customBadge || <Text>{getConditionFormat(t)[data.value as Condition]}</Text>}
        </Stack>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button
              style={{ minWidth: 0, height: "fit-content" }}
              appearance='transparent'
              icon={<MoreVerticalRegular />}
            />
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              {actionOptions.map((item) => (
                <MenuItem key={item.key} onClick={item.onClick}>
                  {item.text}
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </Stack>
    </Stack>
  );
};

export const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  width: "100%",
  color: "#fff",
};

export default StatusBadge;
