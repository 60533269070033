import { SearchBox } from "@fluentui/react-components";
import { debounce } from "lodash-es";
import type { CSSProperties } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar, { CommandBarItemType } from "../../../../Components/common/CommandBar";
import useTrendViewSelectSignals from "../../../analysis-trend-view/components/TrendViewSelectSignals/useTrendViewSelectSignals";

const searchBoxStyles: CSSProperties = {
  width: 185,
  margin: 0,
};

const TrendViewSearchComponent = () => {
  const { t } = useTranslation();
  const { search, changeSearch } = useTrendViewSelectSignals();
  const handleSearch = debounce((value: string) => {
    changeSearch(value.trim());
  }, 300);

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  const farItems = [
    {
      key: "search",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <SearchBox
          placeholder={`${t("Search")}`}
          defaultValue={search}
          style={searchBoxStyles}
          onChange={(_, data) => handleSearch(data?.value ?? "")}
        />
      ),
    },
  ];

  return <BaseCommandBar farItems={farItems} items={[]} />;
};

export default TrendViewSearchComponent;
