import { useTranslation } from "react-i18next";

import { Stack } from "../../../../../../Stack";
import { useMachineConditionsTable } from "../../../Machines/useMachineConditionsTable";
import Panel from "./components/Panel";

const MachinesPanel = () => {
  const { machines } = useMachineConditionsTable();

  return (
    <Stack
      className='machines-number'
      verticalAlign='center'
      horizontalAlign='center'
      style={{
        flexGrow: 1,
      }}
    >
      {machines?.length || "0"}
    </Stack>
  );
};

export default function Machines() {
  const { t } = useTranslation();

  return (
    <Panel
      title={t("Machines")}
      subTitle={t("Total monitored machines")}
      content={<MachinesPanel />}
      style={{ minWidth: "320px" }}
    />
  );
}
