import i18next from "i18next";

const countryByContinent = [
  {
    country: i18next.t("Afghanistan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Albania"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Algeria"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("American Samoa"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Andorra"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Angola"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Anguilla"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Antarctica"),
    continent: i18next.t("Antarctica"),
  },
  {
    country: i18next.t("Antigua and Barbuda"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Argentina"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Armenia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Aruba"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Australia"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Austria"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Azerbaijan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Bahamas"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Bahrain"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Bangladesh"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Barbados"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Belarus"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Belgium"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Belize"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Benin"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Bermuda"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Bhutan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Bolivia"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Bosnia and Herzegovina"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Botswana"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Bouvet Island"),
    continent: i18next.t("Antarctica"),
  },
  {
    country: i18next.t("Brazil"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("British Indian Ocean Territory"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Brunei"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Bulgaria"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Burkina Faso"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Burundi"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Cambodia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Cameroon"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Canada"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Cape Verde"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Cayman Islands"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Central African Republic"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Chad"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Chile"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("China"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Christmas Island"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Cocos (Keeling) Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Colombia"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Comoros"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Congo"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Cook Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Costa Rica"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Croatia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Cuba"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Cyprus"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Czech Republic"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Denmark"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Djibouti"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Dominica"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Dominican Republic"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("East Timor"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Ecuador"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Egypt"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("El Salvador"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("England"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Equatorial Guinea"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Eritrea"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Estonia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Ethiopia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Falkland Islands"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Faroe Islands"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Fiji Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Finland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("France"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("French Guiana"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("French Polynesia"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("French Southern territories"),
    continent: i18next.t("Antarctica"),
  },
  {
    country: i18next.t("Gabon"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Gambia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Georgia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Germany"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Ghana"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Gibraltar"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Greece"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Greenland"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Grenada"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Guadeloupe"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Guam"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Guatemala"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Guinea"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Guinea-Bissau"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Guyana"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Haiti"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Heard Island and McDonald Islands"),
    continent: i18next.t("Antarctica"),
  },
  {
    country: i18next.t("Holy See (Vatican City State)"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Honduras"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Hong Kong"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Hungary"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Iceland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("India"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Indonesia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Iran"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Iraq"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Ireland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Israel"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Italy"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Ivory Coast"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Jamaica"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Japan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Jordan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Kazakhstan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Kenya"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Kiribati"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Kuwait"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Kyrgyzstan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Laos"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Latvia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Lebanon"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Lesotho"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Liberia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Libyan Arab Jamahiriya"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Liechtenstein"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Lithuania"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Luxembourg"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Macao"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("North Macedonia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Madagascar"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Malawi"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Malaysia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Maldives"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Mali"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Malta"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Marshall Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Martinique"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Mauritania"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Mauritius"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Mayotte"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Mexico"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Federated States of Micronesia"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Moldova"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Monaco"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Mongolia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Montenegro"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Montserrat"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Morocco"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Mozambique"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Myanmar"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Namibia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Nauru"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Nepal"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Netherlands"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Netherlands Antilles"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("New Caledonia"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("New Zealand"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Nicaragua"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Niger"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Nigeria"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Niue"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Norfolk Island"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("North Korea"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Northern Ireland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Northern Mariana Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Norway"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Oman"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Pakistan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Palau"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Palestine"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Panama"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Papua New Guinea"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Paraguay"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Peru"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Philippines"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Pitcairn"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Poland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Portugal"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Puerto Rico"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Qatar"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Reunion"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Romania"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Russian Federation"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Rwanda"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Saint Helena"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Saint Kitts and Nevis"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Saint Lucia"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Saint Pierre and Miquelon"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Saint Vincent and the Grenadines"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Samoa"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("San Marino"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Sao Tome and Principe"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Saudi Arabia"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Scotland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Senegal"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Serbia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Seychelles"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Sierra Leone"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Singapore"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Slovakia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Slovenia"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Solomon Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Somalia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("South Africa"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("South Georgia and the South Sandwich Islands"),
    continent: i18next.t("Antarctica"),
  },
  {
    country: i18next.t("South Korea"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("South Sudan"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Spain"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Sri Lanka"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Sudan"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Suriname"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Svalbard and Jan Mayen"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Swaziland"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Sweden"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Switzerland"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Syria"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Tajikistan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Tanzania"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Thailand"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("The Democratic Republic of Congo"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Togo"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Tokelau"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Tonga"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Trinidad and Tobago"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Tunisia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Turkey"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Turkmenistan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Turks and Caicos Islands"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Tuvalu"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Uganda"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Ukraine"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("United Arab Emirates"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("United Kingdom"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("United States"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("United States Minor Outlying Islands"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Uruguay"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Uzbekistan"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Vanuatu"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Venezuela"),
    continent: i18next.t("South America"),
  },
  {
    country: i18next.t("Vietnam"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Virgin Islands, British"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Virgin Islands, US"),
    continent: i18next.t("North America"),
  },
  {
    country: i18next.t("Wales"),
    continent: i18next.t("Europe"),
  },
  {
    country: i18next.t("Wallis and Futuna"),
    continent: i18next.t("Oceania"),
  },
  {
    country: i18next.t("Western Sahara"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Yemen"),
    continent: i18next.t("Asia"),
  },
  {
    country: i18next.t("Zambia"),
    continent: i18next.t("Africa"),
  },
  {
    country: i18next.t("Zimbabwe"),
    continent: i18next.t("Africa"),
  },
];

export default countryByContinent;
