import { useTranslation } from "react-i18next";

import NoImageIcon from "../../../assets/svg/NoImageIcon";
import { Stack } from "../../Stack";

const styles = {
  container: {
    height: "100%",
    textAlign: "center" as const,
  },
  title: {
    fontWeight: 600,
    marginTop: 20,
  },
  subtitle: {
    color: "#C8C6C4",
  },
};

interface ImagePlaceholderProps {
  text?: string;
}

const ImagePlaceholder = ({ text }: ImagePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <Stack horizontalAlign='center' verticalAlign='center' style={styles.container}>
      <NoImageIcon />
      {text && (
        <div>
          <p style={styles.title}>{text}</p>
          <p style={styles.subtitle}>
            {t("If you consider this an error, please contact your administrator")}
          </p>
        </div>
      )}
    </Stack>
  );
};

export default ImagePlaceholder;
