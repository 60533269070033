import type { TextProps } from "@fluentui/react-components";
import type { Control, FieldError } from "react-hook-form";

import type { StackProps } from "../../Stack";

export type FormHookProps = {
  errors?: {
    [schemaProp: string]: FieldError;
  };
  // errors?: FieldErrors<any>;
  control: Control<any>;
};

export type FormGroupProps = {
  label?: string;
  labelProps?: Partial<Omit<TextProps, "as">>; // Partial<ITextProps>;
  stackProps?: Partial<StackProps>;
};

export type BaseFormFieldProps = {
  groupProps?: FormGroupProps;
  hookProps: FormHookProps;
};

export enum FormErrorMessages {
  Required = "This field is required",
}
