import { Input, Label } from "@fluentui/react-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import countryByContinent from "../../../../schema/country-by-continent";
import Dropdown from "../../../common/Dropdown";
import { Stack } from "../../../Stack";
import { useValidation } from "../hooks/useValidation";
import type { ProjectSubmission } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";

interface ProjectFieldsProps {
  context: SecondPanelContext;
}

const continents = Array.from(new Set(countryByContinent.map((c) => c.continent))).sort();

const continentOptions: any[] = continents.map((c) => ({
  key: c,
  text: c,
}));

const projectSchema = z.object({
  name: z.string().min(1, "Project name is required"),
});

export const ProjectFields: React.FC<ProjectFieldsProps> = ({ context }) => {
  const { t } = useTranslation();
  const { initialValues, updateFunction } = context;
  const [projectSub, setProjectSub] = useState<ProjectSubmission>(initialValues.proj);

  const {
    errors: validationErrors,
    validateField,
    setValue,
  } = useValidation({
    schema: projectSchema,
    mode: "onChange",
    defaultValues: projectSub,
  });

  const countryOptions = useMemo(() => {
    const filteredCountries = projectSub.continent
      ? countryByContinent.filter((c) => c.continent === projectSub.continent)
      : [];
    return filteredCountries.map((c) => ({ key: c.country, text: c.country }));
  }, [projectSub.continent]);

  const handleFieldChange = async (field: keyof ProjectSubmission, newValue: string) => {
    const trimmedValue = newValue.trim();
    const projNew: ProjectSubmission = {
      [field]: trimmedValue,
      uniqueId: projectSub.uniqueId,
    };

    if (field === "continent") {
      projNew.country = "";
    }

    if (field === "name") {
      await validateField("name", trimmedValue);
      setValue("name", trimmedValue);
    }

    setProjectSub((prev) => ({ ...prev, ...projNew }));
    updateFunction(projNew, projectSub.uniqueId);
  };

  return (
    <>
      <Stack style={{ gap: 4, margin: "6px 0px", fontWeight: 600 }}>
        <Label htmlFor='project-to-assign'>{t("Project to assign:")}</Label>
        <Input
          id='project-to-assign'
          value={projectSub.name || ""}
          disabled={!!initialValues.proj.id || context.context !== "Project"}
          onChange={(_, data) => handleFieldChange("name", data.value || "")}
        />
      </Stack>
      {!initialValues.proj.id && (
        <>
          <Stack style={{ gap: 4, marginBottom: 6, fontWeight: 600 }}>
            <Label htmlFor='continent'>{t("Continent")}:</Label>
            <Dropdown
              id='continent'
              selectedOptions={[projectSub.continent]}
              options={continentOptions}
              disabled={!!initialValues.proj.id || context.context !== "Project"}
              onOptionSelect={(_, option) =>
                handleFieldChange("continent", option?.optionValue?.toString() || "")
              }
            />
          </Stack>

          <Stack style={{ gap: 4, marginBottom: 6, fontWeight: 600 }}>
            <Label htmlFor='country'>{t("Country")}:</Label>
            <Dropdown
              id='country'
              selectedOptions={[projectSub.country]}
              options={countryOptions}
              disabled={
                !!initialValues.proj.id || context.context !== "Project" || !projectSub.continent
              }
              onOptionSelect={(_, option) =>
                handleFieldChange("country", option?.optionValue?.toString() || "")
              }
            />
          </Stack>

          <Stack style={{ gap: 4, marginBottom: 6, fontWeight: 600 }}>
            <Label htmlFor='country'>{t("City")}:</Label>
            <Input
              id='city'
              value={projectSub.city || ""}
              disabled={!!initialValues.proj.id || context.context !== "Project"}
              onChange={(_, data) => handleFieldChange("city", data.value || "")}
            />
          </Stack>
        </>
      )}
    </>
  );
};
