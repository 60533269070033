import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import { Status } from "../../schema/status";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { selectCorporationDetailsStatus } from "../Corporations/CorporationDetails/reducer";
import { listAsyncCorpo } from "../Corporations/reducer";
import { addCompany, editCompany } from "./api";
import type { Company } from "./models";

type AddOrEditDialogProps = {
  options: any[];
  data: Company | null;
  items: Company[];
  show: boolean;
  onSuccess: (hasError: boolean, data: Company, context: "add" | "edit") => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();

  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data ? name.trim().toLowerCase() !== data.name.trim().toLowerCase() : true,
      ),
    [items, data],
  );
  const getSchema = (companies: Company[]) =>
    z
      .object({
        corporationId: z.string(),
        name: z
          .string()
          .min(1, { message: t("This field is required") })
          .max(maxLengthType1, {
            message: t(`Name must contain at most {{maxLength}} character(s)`, {
              maxLength: maxLengthType1,
            }),
          }),
        number: z
          .string()
          .max(maxLengthType1, {
            message: t(`Number must contain at most ${maxLengthType1} character(s)`, {
              maxLength: maxLengthType1,
            }),
          })
          .optional(),
      })
      .superRefine((input, ctx) => {
        let issueData: z.IssueData = {
          code: z.ZodIssueCode.custom,
          path: [],
          message: "",
        };

        // Name
        if (input.name && input.name.length > 0) {
          const existingCompany = companies.find(
            (c) => c.name.trim().toLowerCase() === input.name.trim().toLowerCase(),
          );
          if (existingCompany) {
            issueData = {
              ...issueData,
              path: ["name"],
              message: t("The company name already exists."),
            };

            ctx.addIssue(issueData);
          }
        }

        // Number
        if (input.number && input.number.length > 0) {
          const existingCompany = companies.find((c) => c.number === input.number.trim());
          if (existingCompany) {
            issueData = {
              ...issueData,
              path: ["number"],
              message: "The number already exists.",
            };
            ctx.addIssue(issueData);
          }
        }
      });

  const schema = useMemo(() => getSchema(filteredItems), [filteredItems]);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCorporationDetailsStatus);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,

    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      corporationId: data?.corporationId || "",
      name: data?.name || "",
      number: data?.number || "",
    },
  });

  useEffect(() => {
    if (statusParent !== Status.idle) dispatch(listAsyncCorpo());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data ? reset(data) : reset({ name: "", number: "" });
  }, [data, reset]);

  const companiesFields: FormItemProps[] = [
    {
      name: "corporationId",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Corporation: *") },
      options,
    },
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name *") },
    },
    {
      name: "number",
      type: FormItemType.TextField,
      groupProps: { label: t("Number") },
    },
  ];

  // Handlers
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: Company = {
      corporationId: formData.corporationId,
      id: data && data.id,
      name: formData.name,
      number: formData.number,
    };
    const mutation = data ? editCompany : addCompany;
    await mutation(toSend).then((response) =>
      onSuccess("status" in response, formData as Company, data ? "edit" : "add"),
    );

    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);
    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{data ? t("Edit new company") : t("Add new company")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {renderFormItems(companiesFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              type='submit'
              appearance='primary'
              disabled={isLoading || !isValid || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
