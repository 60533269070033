import { Button, Tooltip } from "@fluentui/react-components";
import { ClipboardListIcon, LightbulbIcon } from "@fluentui/react-icons-mdl2";
import DOMPurify from "dompurify";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "../../../Stack";
import type { AskResponse } from "../../Api";
import { getCitationFilePath } from "../../Api";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import { parseAnswerToHtml } from "./AnswerParser";

interface Props {
  answer: AskResponse;
  isSelected?: boolean;
  onCitationClicked: (filePath: string) => void;
  onThoughtProcessClicked: () => void;
  onSupportingContentClicked: () => void;
  onFollowupQuestionClicked?: (question: string) => void;
  showFollowupQuestions?: boolean;
}

export const Answer = ({
  answer,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions,
}: Props) => {
  const { t } = useTranslation();
  const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

  const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

  return (
    <Stack
      className={`${styles.answerContainer} ${isSelected && styles.selected}`}
      verticalAlign='space-between'
    >
      <Stack.Item>
        <Stack horizontal horizontalAlign='space-between'>
          <AnswerIcon />
          <div>
            <Tooltip relationship='label' content={t("Show thought process")}>
              <Button
                appearance='transparent'
                icon={<LightbulbIcon />}
                aria-label={t("Show thought process")}
                disabled={!answer.thoughts}
                onClick={() => onThoughtProcessClicked()}
              />
            </Tooltip>
            <Tooltip relationship='label' content={t("Show supporting content")}>
              <Button
                appearance='transparent'
                icon={<ClipboardListIcon />}
                aria-label={t("Show supporting content")}
                disabled={!answer.data_points.length}
                onClick={() => onSupportingContentClicked()}
              />
            </Tooltip>
          </div>
        </Stack>
      </Stack.Item>

      <Stack.Item grow>
        <div
          className={styles.answerText}
          dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
        />
      </Stack.Item>

      {!!parsedAnswer.citations.length && (
        <Stack.Item>
          <Stack horizontal wrap style={{ gap: 5 }}>
            <span className={styles.citationLearnMore}>{t("Citations:")}</span>
            {parsedAnswer.citations.map((x, i) => {
              const path = getCitationFilePath(x);
              return (
                <a
                  key={i}
                  href='/#'
                  className={styles.citation}
                  title={x}
                  onClick={() => onCitationClicked(path)}
                >
                  {`${++i}. ${x}`}
                </a>
              );
            })}
          </Stack>
        </Stack.Item>
      )}

      {!!parsedAnswer.followupQuestions.length &&
        showFollowupQuestions &&
        onFollowupQuestionClicked && (
          <Stack.Item>
            <Stack
              horizontal
              wrap
              className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
              style={{ gap: 6 }}
            >
              <span className={styles.followupQuestionLearnMore}>{t("Follow-up questions:")}</span>
              {parsedAnswer.followupQuestions.map((x, i) => {
                return (
                  <a
                    key={i}
                    href='/#'
                    className={styles.followupQuestion}
                    title={x}
                    onClick={() => onFollowupQuestionClicked(x)}
                  >
                    {`${x}`}
                  </a>
                );
              })}
            </Stack>
          </Stack.Item>
        )}
    </Stack>
  );
};
