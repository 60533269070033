/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, SearchBox, Spinner, Tooltip } from "@fluentui/react-components";
import { ChevronDown20Regular, ChevronUp20Regular, SearchRegular } from "@fluentui/react-icons";
import type { CSSProperties } from "react";
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import type { DefaultNodeProps, useTreeState } from "react-hyper-tree";
import { useTranslation } from "react-i18next";

import CheckboxEmptyIcon from "../../../../assets/svg/CheckboxEmptyIcon";
import CheckboxFullIcon from "../../../../assets/svg/CheckboxFullIcon";
import ClearIcon from "../../../../assets/svg/ClearIcon";
import FastTrendSignalIcon from "../../../../assets/svg/FastTrendSignalIcon";
import NoDataIcon from "../../../../assets/svg/NoDataIcon";
import RawDataSignalIcon from "../../../../assets/svg/RawDataSignalIcon";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import TrendSignalIcon from "../../../../assets/svg/TrendSignalIcon";
import type { ResponseSimplifiedSignal } from "../../../../types";
import SignalsAutocomplete from "../../../common/components/SignalsAutocomplete";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import { formatNode } from "../../../common/utils/signalFormatter";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import useCustomSelectNode from "./useCustomSelectNode";

const ClearAllIcon = () => <ClearIcon />;

// Fix for the tooltip not showing on the node label for safari.
const labelStyles: React.CSSProperties = {
  visibility: "visible",
};

const buttonStyles: CSSProperties = {
  background: "transparent",
  border: "unset",
  minWidth: 20,
  padding: 0,
};

interface CustomSelectNodeProps extends DefaultNodeProps {
  handlers: ReturnType<typeof useTreeState>["handlers"];
  treeId?: string;
}

const CustomSelectNode: React.FC<CustomSelectNodeProps> = ({
  node,
  onToggle,
  onSelect,
  handlers,
  ...props
}) => {
  const {
    showEmptyIcon,
    showFullIcon,
    showCloseIcon,
    showOpenIcon,
    isSearchVisible,
    handleSelect,
    handleToggle,
    handleItemClick,
    handleItemSelect,
    toggleSearchVisibility,
  } = useCustomSelectNode({ node, onToggle, onSelect, handlers, ...props });

  const { t } = useTranslation();

  const { removeSelectedSignal } = useSelectedSignalsStore((state) => ({
    removeSelectedSignal: state.removeSelectedSignal,
  }));

  const tooltipId = useId();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [matchingNodeIds, setMatchingNodeIds] = useState<string[]>([]);

  const [hasMatches, setHasMatches] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const clearSelectedSignals = () => {
    node.getChildren().forEach((child) => {
      handlers.setSelected(child.id, false);
      removeSelectedSignal(child as unknown as ResponseSimplifiedSignal);
      if (child?.getChildren) {
        const subChildren = child.getChildren() || [];
        subChildren.forEach((subChild) => {
          handlers.setSelected(subChild.id, false);
          removeSelectedSignal(subChild as unknown as ResponseSimplifiedSignal);
        });
      }
    });
  };

  const renderName = () => {
    const label = formatNode(node);

    if (!node.options.leaf) {
      return <span>{label}</span>;
    }
    return (
      <>
        <Tooltip
          withArrow
          relationship='label'
          content={{
            children: label,
            style: { maxWidth: "100vw" },
          }}
        >
          <span style={labelStyles}>{label}</span>
        </Tooltip>
      </>
    );
  };

  const getNodeOptions = useCallback(() => {
    let options: any = [];
    const children = node?.getChildren ? node.getChildren() : [];
    children.forEach((item) => {
      const subChildren = item?.getChildren ? item?.getChildren() || [] : [];
      if (subChildren.length > 0) {
        options = [...options, ...subChildren];
      } else {
        options = [...options, item];
      }
    });

    return options
      .filter(
        (child: any) =>
          child.getData().dataType !== DataTypeEnum.RawData && !child.data.groupedNodeParent,
      )
      .map((child: any) => ({
        ...child,
        id: child.getData().id,
        name: child.getData().name,
        label: formatNode(child),
        icon: child.getData().dataType,
      }));
  }, [node]);

  const checkClearCheckboxAvailable = useCallback(() => {
    return !!(
      node?.getChildren &&
      node.getChildren().find((child) => {
        if (child.isSelected()) {
          return true;
        }

        if (child?.getChildren && child.getChildren().length > 0) {
          return child.getChildren().find((subChild) => {
            return subChild?.isSelected && subChild?.isSelected();
          });
        }

        return false;
      })
    );
  }, [node]);

  // local node search and visibility
  const searchBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
        toggleSearchVisibility();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSearchVisibility]);

  const getNodeDepth = useCallback((node: any): number => {
    let depth = 0;
    let currentNode = node;
    while (currentNode.getParent()) {
      depth++;
      currentNode = currentNode.getParent();
    }
    return depth;
  }, []);

  const isTopLevelNode = useMemo(() => {
    const depth = getNodeDepth(node);
    return depth === 2;
  }, [node, getNodeDepth]);

  const filterNodes = useCallback((node: any, query: string): string[] => {
    const matchingIds: string[] = [];
    node.getChildren().forEach((child: any) => {
      const childName = child.getData().name.toLowerCase();
      if (childName.includes(query?.toLowerCase())) {
        matchingIds.push(child.id);
      }
    });
    return matchingIds;
  }, []);
  useEffect(() => {
    if (searchQuery && node) {
      const matchingIds = filterNodes(node, searchQuery);
      setMatchingNodeIds(matchingIds);
    } else {
      setMatchingNodeIds([]);
    }
  }, [searchQuery, node, filterNodes]);

  useEffect(() => {
    if (matchingNodeIds.length > 0) {
      setHasMatches(true);
    } else {
      setHasMatches(false);
    }
  }, [matchingNodeIds]);

  useEffect(() => {
    const depth = getNodeDepth(node);
    if (hasMatches && handlers && !isExpanded) {
      if (depth === 0) {
        matchingNodeIds.forEach((nodeId) => {
          const matchingNode = handlers.getNode(nodeId);
          if (matchingNode) {
            let parentNode = matchingNode.getParent();
            while (parentNode) {
              handlers.setOpen(parentNode, true);
              parentNode = parentNode.getParent();
            }
          }
        });
      } else if (depth === 1) {
        handlers.setOpen(node, true);
      } else if (depth === 2) {
        handlers.setOpen(node, false);
      }
      setIsExpanded(true);
    } else if (!hasMatches && handlers && isExpanded) {
      if (depth === 0 || depth === 1 || depth === 2) {
        const syntheticEvent = {
          stopPropagation: () => {},
          preventDefault: () => {},
          target: {} as EventTarget,
          currentTarget: {} as EventTarget,
          nativeEvent: {} as MouseEvent,
        } as React.MouseEvent<HTMLDivElement, MouseEvent>;
        handleToggle(syntheticEvent);
      }

      setIsExpanded(false);
    }
  }, [hasMatches, searchQuery, matchingNodeIds, handlers, isExpanded]);

  const visibleNodes = useMemo(() => {
    if (!searchQuery) return node.getChildren();
    return node.getChildren().filter((child: any) => {
      return matchingNodeIds.includes(child.id);
    });
  }, [node, searchQuery, matchingNodeIds]);

  useEffect(() => {
    if (isTopLevelNode) {
      if (!searchQuery || !node) {
        if (node) {
          node.getChildren().forEach((childNode) => {
            const selector = `[data-tree-id="${props.treeId || "default"}"][data-node-id="${childNode.id}"]`;
            const childElements = document.querySelectorAll(selector);

            childElements.forEach((el) => {
              (el as HTMLElement).style.display = "";
            });
          });
        }
        return;
      }
      const visibleNodeIds = new Set(visibleNodes.map((node) => String(node.id)));

      node.getChildren().forEach((childNode) => {
        const isVisible = visibleNodeIds.has(String(childNode.id));
        const selector = `[data-tree-id="${props.treeId || "default"}"][data-node-id="${childNode.id}"]`;
        const childElements = document.querySelectorAll(selector);
        if (childElements.length > 0) {
          childElements.forEach((el) => {
            (el as HTMLElement).style.display = isVisible ? "" : "none";
          });
        }
      });
    }
  }, [searchQuery, node, handlers, visibleNodes, props.treeId]);

  return (
    <div
      data-node-id={node.id}
      data-tree-id={props.treeId || "default"}
      style={{
        display: node.options.leaf && node.data.dataType === "ShortTerm" ? "none" : "block",
      }}
    >
      <div key={node.data.title} className='tree-node raw-data-signals-sidebar'>
        {showFullIcon && (
          <Button appearance='transparent' style={buttonStyles} onClick={(e) => handleSelect(e)}>
            <CheckboxFullIcon />
          </Button>
        )}
        {showEmptyIcon && (
          <Button appearance='transparent' style={buttonStyles} onClick={(e) => handleSelect(e)}>
            <CheckboxEmptyIcon />
          </Button>
        )}
        <div
          className='node-content-wrapper'
          role='button'
          tabIndex={0}
          style={{ cursor: "pointer" }}
          onClick={handleItemClick}
        >
          <div className='titles' role='button' tabIndex={0}>
            {node.getData().dataType === DataTypeEnum.Trend && (
              <TrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.FastTrend && (
              <FastTrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.RawData && (
              <RawDataSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            <div className='node-title'>{renderName()}</div>
          </div>

          {isTopLevelNode && !isSearchVisible && (
            <Tooltip withArrow relationship='label' content={t("Search")}>
              <Button
                appearance='transparent'
                icon={<SearchRegular />}
                style={{
                  marginLeft: 8,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSearchVisibility();
                }}
              />
            </Tooltip>
          )}
          {isSearchVisible && isTopLevelNode && (
            <div
              ref={searchBoxRef}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SearchBox
                placeholder={t("Search")}
                style={{
                  width: 100,
                  height: "80%",
                  margin: 0,
                  padding: 0,
                }}
                onChange={(_, data) => {
                  setSearchQuery(data?.value ?? "");
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          )}

          {node.isLoading() && <Spinner size='extra-tiny' />}
          {node.asyncNode &&
            node.asyncDataLoaded &&
            (node.getChildren().length > 0 ? (
              <div style={{ display: "flex", gap: 6 }}>
                <Tooltip withArrow relationship='description' content={t("Clear Selected Signals")}>
                  <Button
                    appearance='transparent'
                    icon={<ClearAllIcon />}
                    style={{
                      padding: 0,
                      width: 20,
                      maxWidth: "unset",
                      minWidth: "unset",
                      filter: checkClearCheckboxAvailable() ? "none" : "grayscale(1)",
                      opacity: checkClearCheckboxAvailable() ? 1 : 0.7,
                    }}
                    disabled={!checkClearCheckboxAvailable()}
                    onClick={(e) => {
                      e.stopPropagation();
                      clearSelectedSignals();
                    }}
                  />
                </Tooltip>

                <SignalsAutocomplete
                  items={getNodeOptions()}
                  selectedItems={(getNodeOptions() || []).filter(
                    (item: any) => item.options.selected,
                  )}
                  onSelectSignal={(signal) => {
                    if (signal) {
                      handleItemSelect(signal.id);
                    }
                  }}
                  onRenderMenuIcon={() => <SearchIcon />}
                />
              </div>
            ) : (
              <Tooltip withArrow relationship='label' content={t("No data")}>
                <span style={{ cursor: "pointer", maxWidth: "100vw" }}>
                  <NoDataIcon />
                </span>
              </Tooltip>
            ))}
        </div>

        {showCloseIcon && (
          <Button appearance='transparent' style={buttonStyles} onClick={(e) => handleToggle(e)}>
            <ChevronUp20Regular />
          </Button>
        )}
        {showOpenIcon && (
          <Button appearance='transparent' style={buttonStyles} onClick={(e) => handleToggle(e)}>
            <ChevronDown20Regular />
          </Button>
        )}
      </div>
    </div>
  );
};

export default CustomSelectNode;
