import { Button, Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, CopyRegular, Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { ColumnVerticalSectionEditIcon } from "@fluentui/react-icons-mdl2";
import { createContext, useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import type { ObjectWithKey } from "../../types";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import { useTableFilters } from "../common/Table/v9";
import Table, { getRowId } from "../common/Table/v9/Table";
import TextField from "../common/TextField";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { listAsyncProjs, selectProjects, selectProjectsStatus } from "../Projects/reducer";
import { Stack } from "../Stack";
import { AddDialog } from "./AddDialog";
import { DeleteConfirm } from "./DeleteConfirm";
import { EditBulkDialog } from "./EditBulkDialog";
import { EditDialog } from "./EditDialog";
import type { WirelessGateway, WirelessGatewayResponse } from "./models";
import { listAsyncGateways, selectWirelessGateways, selectWirelessGatewaysStatus } from "./reducer";
import { listAsyncWirelessDetails } from "./WirelessGatewaysDetails/reducer";

export type ItemsSelected = WirelessGatewayResponse & ObjectWithKey;

export const wirelessContext = createContext([{ id: "", serialNumber: "", projectId: "" }]);

export const selWirelessContext = createContext<WirelessGatewayResponse | undefined>(undefined);

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (gateway: WirelessGatewayResponse) => void;
  onDelete: (gateway: WirelessGatewayResponse) => void;
};

const OnPass = ({ item, column }: { item: WirelessGatewayResponse; column: any }) => {
  const { t } = useTranslation();
  const pass = item[column.key];
  return (
    <Stack key={item.id + column.key} horizontal>
      <TextField
        type='password'
        value={pass || ""}
        disabled={true}
        style={{ maxWidth: "80%", paddingRight: 0 }}
      />
      <CopyToClipboard
        text={pass}
        onCopy={(text) =>
          !text && notification.warning(t("You are trying to copy an empty string."))
        }
      >
        <Button appearance='transparent' icon={<CopyRegular />} />
      </CopyToClipboard>
    </Stack>
  );
};

const getColumns = ({ t, hasActions, onEdit, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "serialNumber",
      name: t("Serial Number"),
      fieldName: "serialNumber",
      minWidth: 150,
      isSortable: true,
      onRender: ({ id, serialNumber }: WirelessGatewayResponse) => (
        <Link to={id} style={linkStyle}>
          {serialNumber}
        </Link>
      ),
    },
    {
      key: "project",
      name: t("Project"),
      fieldName: "project",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "company",
      name: t("Company"),
      fieldName: "company",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "corporation",
      name: t("Corporation"),
      fieldName: "corporation",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "adminPassword",
      name: t("Admin Password"),
      fieldName: "adminPassword",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "userPassword",
      name: t("User Password"),
      fieldName: "userPassword",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "connectionString",
      name: t("Connection String"),
      fieldName: "connectionString",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "ltePlanSize",
      name: t("Lte Plan Size"),
      fieldName: "ltePlanSize",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "publicKey",
      name: t("Public Key"),
      fieldName: "publicKey",
      minWidth: 150,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "rsshPort",
      name: t("Rssh Port"),
      fieldName: "rsshPort",
      minWidth: 100,
      isSortable: true,
      onRender: (item, _, column) => <OnPass item={item} column={column} />,
    },
    {
      key: "wifiPasswordForAP",
      name: t("Wifi Password For AP"),
      fieldName: "wifiPasswordForAP",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wifiSSID",
      name: t("Wifi SSID"),
      fieldName: "wifiSSID",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "iMEI",
      name: t("IMEI"),
      fieldName: "iMEI",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirepassSinkNodeAddress",
      name: t("Wirepass Sink Node Address"),
      fieldName: "wirepassSinkNodeAddress",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "wirepassChannel",
      name: t("Wirepass Channel"),
      fieldName: "wirepassChannel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "ethernetMacAddress",
      name: t("Ethernet Mac Address"),
      fieldName: "ethernetMacAddress",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "firmware",
      name: t("Firmware"),
      fieldName: "firmware",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "active",
      name: t("Active"),
      fieldName: "active",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "installationLocation",
      name: t("Installation Location"),
      fieldName: "installationLocation",
      minWidth: 150,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (gateway: WirelessGatewayResponse) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(gateway);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "4px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(gateway);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

export const WirelessGatewaysList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const hasWritePermission = auth.wirelessSensorContributor;
  const [selectedItems, setSelectedItems] = useState<ItemsSelected[]>();
  const items = useAppSelector(selectWirelessGateways);
  const status = useAppSelector(selectWirelessGatewaysStatus);

  const dispatch = useAppDispatch();

  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);

  useEffect(() => {
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
  }, [dispatch, projectStatus]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle) dispatch(listAsyncGateways(projs));
  }, [dispatch, projectStatus, status, projs]);

  const [selected, setSelected] = useState<{
    data: WirelessGatewayResponse;
    context: "add" | "edit" | "delete" | "editB";
    selItems?: ItemsSelected[];
  }>();

  const { filters, handleSearch } = useTableFilters<WirelessGatewayResponse>({
    keys: ["serialNumber", "firmware", "project", "company", "corporation"],
  });

  const onAdd = () => {
    setSelected({ data: null, context: "add" });
    //setSelectedWireless(undefined);
  };

  const onEdit = async (gateway: WirelessGatewayResponse) => {
    await listAsyncWirelessDetails(gateway.id!);
    setSelected({ data: gateway, context: "edit" });
    //setSelectedWireless(gateway);
  };

  const editBulkOnclick = (): boolean | void => {
    setSelected({ data: undefined, selItems: selectedItems, context: "editB" });
  };

  const onDelete = (gateway: WirelessGatewayResponse) =>
    setSelected({ data: gateway, context: "delete" });

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Gateways")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            icon: <AddRegular />,
            onClick: onAdd,
          },
          {
            key: "edit",
            text: t("Bulk Edit"),
            type: CommandBarItemType.Button,
            disabled: !(selectedItems?.length > 1),
            icon: <ColumnVerticalSectionEditIcon />,
            onClick: editBulkOnclick,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />

      <Table
        persistOpts={{
          key: "table-gateways",
          version: 2,
        }}
        header={{
          title: t("Gateways"),
        }}
        items={items}
        v8Columns={getColumns({
          t,
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        isError={status === Status.error}
        isLoading={status === Status.loading}
        defaultSelectedItems={(selectedItems || []).map((item) => item.id)}
        getRowId={getRowId}
        onSelectionChanged={(items: ItemsSelected[]) => setSelectedItems(items)}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{serialNumber}} gateway`, {
                serialNumber: selected?.data?.serialNumber,
              }),
            );
          } else {
            dispatch(listAsyncGateways(projs));
            notification.success(
              t(`{{serialNumber}} gateway deleted successfully`, {
                serialNumber: selected?.data?.serialNumber,
              }),
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      {["add"].includes(selected?.context) && (
        <AddDialog
          serialNumbers={items.map((gate) => gate.serialNumber)}
          show={["add"].includes(selected?.context)}
          onSuccess={(hasError, name: string) => {
            if (hasError) {
              const message = t(`Failed add {{name}}`, { name });

              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`{{name}} added successfully`, { name });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}

      {selected?.data && (
        <EditDialog
          data={selected?.data as WirelessGateway}
          serialNumbers={items.map((gate) => gate.serialNumber)}
          show={["edit"].includes(selected?.context)}
          onSuccess={(hasError, name: string) => {
            if (hasError) {
              const message = t(`Failed edit {{name}}`, { name });

              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`{{name}} edited successfully`, { name });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
      {["editB"].includes(selected?.context) && (
        <EditBulkDialog
          gatewaysNotCommon={selected?.selItems}
          options={projs.map((pro) => ({
            key: pro.id!,
            text: `${pro.name} (${pro.company.name})`,
          }))}
          data={selected?.selItems.at(0)}
          show={["editB"].includes(selected?.context)}
          onSuccess={(hasError) => {
            if (hasError) {
              const message = t(`Failed updating`);
              notification.error(message);
            } else {
              dispatch(listAsyncGateways(projs));

              const message = t(`Updated successfully`);

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
