// Types and Interfaces

import { Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, Delete20Regular } from "@fluentui/react-icons";
import type { CSSProperties } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar";
import { notification } from "../../../../common/Notification";
import Table, { useTableFilters } from "../../../../common/Table/v9";
import type { CreateProjectTriggerRequest, ProgrammedTrigger } from "../../Sensor/types";
import { useAddProjectTrigger, useListProjectTriggers } from "../hooks/useListProjectTriggers ";
import { TriggerAddDialog } from "./TriggerAddDialog";

type Column = {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  isSortable: boolean;
  isExportable?: boolean;
  onRender?: (item: ProgrammedTrigger) => React.ReactNode;
};

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onDelete: (trigger: ProgrammedTrigger) => void;
};

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const getColumns = ({ t, hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "frequency",
      name: t("Frequency"),
      fieldName: "frequency",
      minWidth: 150,
      isSortable: true,
      onRender: (trigger: ProgrammedTrigger) => (
        <span>{(trigger?.frequency || "")?.replace(/([A-Z])/g, " $1")?.trim()}</span>
      ),
    },

    {
      key: "time",
      name: t("Time"),
      fieldName: "time",
      minWidth: 100,
      isSortable: false,
      onRender: (trigger: ProgrammedTrigger) => (
        <span>{`${trigger?.hour?.toString().padStart(2, "0")}:${trigger?.minute
          ?.toString()
          ?.padStart(2, "0")}`}</span>
      ),
    },
    {
      key: "measurementSets",
      name: t("Measurement Sets"),
      fieldName: "measurementSets",
      minWidth: 200,
      isSortable: false,
      onRender: (trigger: ProgrammedTrigger) => <span>{trigger?.measurementSets?.join(", ")}</span>,
    },
    {
      key: "delay",
      name: t("Delay (min)"),
      fieldName: "delayPerSensorInMinutes",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (trigger: ProgrammedTrigger) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Delete")} positioning='above'>
            <span>
              <Delete20Regular
                style={{ color: "rgb(44, 82, 159)" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(trigger);
                }}
              />
            </span>
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

export const TriggersList: React.FC = () => {
  const { t } = useTranslation();
  const { projectId }: any = useParams();
  const { triggers, isLoading, error, refetchTriggers } = useListProjectTriggers(projectId);
  const { addTriggerAsync } = useAddProjectTrigger();

  const [selected, setSelected] = useState<{
    data: ProgrammedTrigger | undefined;
    context: "add" | "delete";
  }>();

  const onAdd = () => setSelected({ data: undefined, context: "add" });

  const commandBarItems: any = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Triggers")}</Text>,
    },
    {
      key: "add",
      text: t("Add"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAdd,
    },
  ];

  const { filters, handleSearch } = useTableFilters<ProgrammedTrigger>({
    keys: ["name"],
  });

  const onDelete = (trigger: ProgrammedTrigger) => {
    setSelected({ data: trigger, context: "delete" });
  };

  const handleAddSuccess = async (data: CreateProjectTriggerRequest) => {
    try {
      await addTriggerAsync(data);
      refetchTriggers();
      setSelected(undefined);
    } catch (error: any) {
      notification.error(error?.response?.data);
      console.error("Error adding trigger:", error);
    }
  };
  return (
    <div className='p-4'>
      <BaseCommandBar items={computeCommandBarItems(commandBarItems)} onSearch={handleSearch} />
      <div className='border rounded-lg'>
        <Table
          persistOpts={{
            key: "table-triggers",
            version: 2,
          }}
          header={{
            title: t("Triggers"),
          }}
          v8Columns={getColumns({
            t,
            hasActions: true,
            onDelete,
          })}
          items={triggers}
          filters={filters}
          isLoading={isLoading}
          isError={!!error}
        />
      </div>
      <DeleteDialog
        data={{
          ids: selected?.data ? [selected.data.id] : [],
          name: selected?.data?.name || "Trigger",
        }}
        open={selected?.context === "delete"}
        route={"dws/v1/triggers/programmed/projects/" + projectId + "/triggers/"}
        onDismiss={() => setSelected(undefined)}
        onSuccess={() => {
          refetchTriggers();
          setSelected(undefined);
        }}
      />

      <TriggerAddDialog
        projectId={projectId}
        hidden={selected?.context !== "add"}
        onDismiss={() => {
          setSelected(undefined);
        }}
        onSuccess={handleAddSuccess}
      />
    </div>
  );
};

export default TriggersList;
