import { format as fnsFormat } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import PeriodIcon from "../../../../../../assets/svg/TasksIcon";
import { dropdownIconStyle } from "../../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../../../modules/common/components/CustomDateDialog";
import { onRenderLabel } from "../../../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../../common/Dropdown";
import { getPeriodDropdownOptions } from "../../../../MachineCVDriveLoadPage/config";
import usePerformanceStore from "../../../hooks/usePerformanceStore";

const Header = () => {
  const { transparent } = useDropdownStyles();
  const { t } = useTranslation();
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] = useState(false);
  const { period, updateStore } = usePerformanceStore((store: any) => ({
    period: store.period,
    updateStore: store.updateStore,
  }));

  const onPeriodChange = (ev: any, selectedItem: any) => {
    const key = selectedItem.optionValue;
    const text = selectedItem.optionText;
    if (key === getPeriodDropdownOptions(t)[6].key) {
      setIsCustomPeriodVisible(true);
    } else {
      updateStore({ period: { key, text } });
    }
  };

  const onCustomDateChange = ({ start, end }: any) => {
    updateStore({
      period: {
        ...getPeriodDropdownOptions(t)[6],
        startDate: fnsFormat(new Date(start), FORMAT),
        endDate: fnsFormat(new Date(end), FORMAT),
      },
    });
  };

  return (
    <div className='mb-8 torque-header'>
      <div className='semibold-title'>{t("Load & Performance")}</div>
      <Dropdown
        style={{ minWidth: "max-content" }}
        className={transparent}
        button={onRenderLabel({
          label: t("Period"),
          icon: <PeriodIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: t(period.text),
        })}
        options={getPeriodDropdownOptions(t)}
        selectedOptions={[period.key]}
        onOptionSelect={onPeriodChange}
      />
      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </div>
  );
};

export default Header;
