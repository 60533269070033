import { useQuery } from "@tanstack/react-query";

import { DEFAULT_REFETCH_INTERVAL } from "../config/constants";
import { getApiClient } from "../modules/core/apiClient/useApiStore";

export type UseMachineUsersQueryOpts = {
  machineId: string;
  reload?: boolean;
};

export type User = {
  displayName: string;
  email: string;
  memberId: string;
  userPrincipalName: string;
  lastName: string;
  firstName: string;
  jobTitle: string;
  companyName: string;
  department: string;
  streetAddress: string;
  city: string;
  stateOrProvince: string;
  zipOrPostalCode: string;
  countryOrRegion: string;
  mobilePhone: string;
};

export const getUsersOptions = (users: User[]) => {
  return users.map((user) => ({
    key: user.memberId,
    text: user.displayName,
  }));
};

export const getUserById = (users: User[], id: string) => {
  return users.find((user) => user.memberId === id)?.displayName || "";
};

export const getUserInitials = (name = "") => {
  return name
    .split(" ")
    .map((item) => item[0])
    .join("");
};

export const useMachineUsersQuery = (opts: UseMachineUsersQueryOpts) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    ["users-list", opts],
    () =>
      getApiClient()
        .get<User[]>(`/meta/machinecv/v1/peers/machines/${opts.machineId}`)
        .then(({ data }) => data),
    {
      staleTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      refetchOnMount: (query) => query.isStale(),
    },
  );

  return {
    usersList: data,
    usersPersonas: data.map((user) => ({
      size: 10,
      imageInitials: getUserInitials(user.displayName as string),
      text: user.displayName,
      secondaryText: user.jobTitle,
      id: user.memberId,
    })),
    isLoading,
    isError,
  };
};
