import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { CSSProperties } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../common/Checkbox";
import BaseDialog, { BaseDialogTitle } from "../../../common/Dialog";
import SpinButton from "../../../common/SpinButton";
import { Stack } from "../../../Stack";
import type { UpdateConfiguration } from "./api";
import { editConfigurationUpdate } from "./api";

type EditConditionProps = any & {
  machineId: string;
  data: UpdateConfiguration;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
  onDismiss?: () => void;
  open: boolean;
};

const spinButtonStyles: CSSProperties = {
  width: 250,
};

export const ConfigurationEditor: React.FC<EditConditionProps> = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maxDataAgeInDaysError, setMaxDataAgeInDaysError] = useState<number>(
    data.maxDataAgeInDaysError,
  );
  const [maxDataAgeInDaysWarning, setMaxDataAgeInDaysWarning] = useState<number>(
    data.maxDataAgeInDaysWarning,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setIsActive(data.isActive);
    setMaxDataAgeInDaysError(data.maxDataAgeInDaysError);
    setMaxDataAgeInDaysWarning(data.maxDataAgeInDaysWarning);
  }, [data]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await editConfigurationUpdate(machineId, {
        id: data.id,
        isActive,
        maxDataAgeInDaysError,
        maxDataAgeInDaysWarning,
      });
      onSuccess(!("status" in response));
    } catch (error) {
      console.error("Error for Update Configuration:", error);
      setErrorMessage(t("Failed to update configuration. Please try again."));
      onSuccess(false);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }, [
    t,
    machineId,
    data.id,
    isActive,
    maxDataAgeInDaysError,
    maxDataAgeInDaysWarning,
    onSuccess,
    handleClose,
  ]);

  const isSubmitDisabled = useMemo(() => {
    return (
      isLoading ||
      (isActive === data.isActive &&
        maxDataAgeInDaysError === data.maxDataAgeInDaysError &&
        maxDataAgeInDaysWarning === data.maxDataAgeInDaysWarning)
    );
  }, [
    isLoading,
    isActive,
    data.isActive,
    maxDataAgeInDaysError,
    data.maxDataAgeInDaysError,
    maxDataAgeInDaysWarning,
    data.maxDataAgeInDaysWarning,
  ]);

  return (
    <BaseDialog {...rest} open={show} surfaceStyle={{ width: 300 }} onOpenChange={handleClose}>
      <BaseDialogTitle>{t("Edit configuration")}</BaseDialogTitle>
      <DialogContent>
        <Stack horizontalAlign='center' verticalAlign='center'>
          <Checkbox label={t("Is Active")} checked={isActive} onChange={setIsActive} />

          <SpinButton
            label={t("Max Data Age In Days Warning")}
            value={maxDataAgeInDaysWarning}
            min={1}
            style={spinButtonStyles}
            onChange={(value) => setMaxDataAgeInDaysWarning(value)}
          />
          <SpinButton
            label={t("Max Data Age In Days Error")}
            value={maxDataAgeInDaysError}
            min={1}
            style={spinButtonStyles}
            onChange={(value) => setMaxDataAgeInDaysError(value)}
          />
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        </Stack>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isSubmitDisabled}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleSubmit}
          >
            {t("Save Changes")}
          </Button>
          <Button appearance='transparent' onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
