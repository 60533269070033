/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { DialogSize } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import { selectCompanies } from "../Companies/reducer";
import { selectCorporations } from "../Corporations/reducer";
import FormDialog from "../Generic/FormDialog";
import { selectMachines } from "../Machines/reducer";
import { selectProjects } from "../Projects/reducer";
import { DashboardMetaDataAPI } from "./api";
import type { AddEditDashboardFormData, DashBoardToList } from "./models";
import { PowerBIEmbedItemParentType } from "./models";
import { listAsyncDashB } from "./reducer";

type BasicDialogProps = {
  onClose: () => void;
};

type EditDashboardDialogProps = BasicDialogProps & {
  entry: DashBoardToList;
};

type AddEditDashboardDialogProps = {
  title: string;
  entry?: DashBoardToList;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: AddEditDashboardFormData) => void;
};

const uuidRegex = new RegExp(
  "[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}",
);

function getComboBoxOptions(data) {
  return data.map((item) => ({ key: item.id, text: item.name }));
}

const getFormItemProps: (t, parentOptions) => FormItemProps[] = (t, parentOptions) => [
  {
    name: "parentType",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Parent type *") },
    options: Object.keys(PowerBIEmbedItemParentType).map((key) => {
      return { key: key, text: t(PowerBIEmbedItemParentType[key]) };
    }),
  },
  {
    name: "parentId",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Parent") },
    options: parentOptions,
  },
  {
    name: "reportId",
    type: FormItemType.TextField,
    groupProps: { label: t("Report ID *") },
  },
  {
    name: "groupId",
    type: FormItemType.TextField,
    groupProps: { label: t("Group ID *") },
  },
];

/**
 * Gets the Dashboard add edit dialog component.
 * @param title The form dialog title.
 * @param entry The dashboard list entry.
 * @param isLoading Value indicating whether the form must be in loading state.
 * @param onSubmit Method called when the submit button is clicked.
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Dashboard add edit dialog component.
 */
const AddEditDashboardDialog = ({
  title,
  entry,
  isLoading,
  onSubmit,
  onClose,
}: AddEditDashboardDialogProps) => {
  const { t } = useTranslation();

  const corporations = useAppSelector(selectCorporations);
  const companies = useAppSelector(selectCompanies);
  const projects = useAppSelector(selectProjects);
  const machines = useAppSelector(selectMachines);

  const parentOptions = {
    Corporation: getComboBoxOptions(corporations),
    Company: getComboBoxOptions(companies),
    Project: getComboBoxOptions(projects),
    Machine: getComboBoxOptions(machines),
  };

  const formSchema = useMemo(
    () =>
      z.object({
        parentId: z.any(),
        parentType: z.any(),
        reportId: z
          .string()
          .min(1, t("This field is required."))
          .regex(uuidRegex, t("The value must be an UUID.")),
        groupId: z
          .string()
          .min(1, t("This field is required."))
          .regex(uuidRegex, t("The value must be an UUID.")),
      }),
    [t],
  );

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    ...{
      defaultValues: {
        parentId: entry?.parentId || "",
        parentType: entry?.parentType || "Corporation",
        reportId: entry?.id || "",
        groupId: entry?.groupId || "",
      },
    },
  });

  // Handlers
  const onSubmitHandler = (formData: any) => {
    if (!formData) {
      return;
    }

    const data: AddEditDashboardFormData = {
      groupId: formData.groupId,
      id: formData.reportId,
      parentType: formData.parentType,
      parentId: formData.parentId,
    };

    onSubmit?.(data);
  };

  return (
    <FormDialog
      title={title}
      isLoading={isLoading}
      isValid={isValid && isDirty}
      size={DialogSize.M}
      onSubmit={handleSubmit(onSubmitHandler)}
      onClose={onClose}
    >
      {renderFormItems(getFormItemProps(t, parentOptions[watch().parentType]), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};

/**
 * Gets the Add dashboard dialog component.
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Add dashboard dialog component.
 */
export const DashboardAddDialog = ({ onClose }: BasicDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Handlers
  const onSubmit = (formData: AddEditDashboardFormData) => {
    setIsLoading(true);
    DashboardMetaDataAPI.createOrUpdateDashboard(formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(t("Failure: Creating PowerBI Dashboard."));
        return;
      }

      dispatch(listAsyncDashB());
      notification.success(t("Success: Creating PowerBI Dashboard."));
      onClose?.();
    });
  };

  return (
    <AddEditDashboardDialog
      title={t("Add New Dashboard")}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

/**
 * Gets the Edit dashboard dialog component.
 * @param entry the Dashboard table entry
 * @param onClose Method called when the close button or cancel button are clicked.
 * @returns The Edit dashboard dialog component.
 */
export const DashboardEditDialog = ({ entry, onClose }: EditDashboardDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Handlers
  const onSubmit = (formData: AddEditDashboardFormData) => {
    setIsLoading(true);
    DashboardMetaDataAPI.createOrUpdateDashboard(formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(t("Failure: Creating PowerBI Dashboard."));
        return;
      }

      dispatch(listAsyncDashB());
      notification.success(t("Success: Creating PowerBI Dashboard."));
      onClose?.();
    });
  };

  return (
    <AddEditDashboardDialog
      title={t("Edit Dashboard")}
      entry={entry}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
