import { paths } from "./iconPaths";

interface IconProperties {
  className?: string;
  viewBox?: string;
  title?: string;
  style?: any;
  role?: string;
  width?: string;
  height?: string;
  color?: string;
  name: any;
}

const Icon: React.FC<IconProperties> = ({
  viewBox = "0 0 24 24",
  width = "24",
  height = "24",
  role = "img",
  color = "inherit",
  title,
  name,
  ...props
}) => {
  if (!paths?.[name]) {
    return null;
  }

  return (
    <svg viewBox={viewBox} width={width} height={height} role={role} color={color} {...props}>
      {title && <title>{title}</title>}
      {paths?.[name]()}
    </svg>
  );
};

export default Icon;
