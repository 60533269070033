/* eslint-disable jsx-a11y/click-events-have-key-events */

import type { User } from "../../../../Hooks/useUsers";
import { getUserById } from "../../../../Hooks/useUsers";
import { format, formatFileSize } from "../../../../utils";
import { File } from "../../../common/Preview";
import type { Column } from "../../../common/Table/v9";

type GetColumnsOpts = {
  t: any;
  users: User[];
};

export const getColumns = ({ t, users }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 150,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "addedBy",
    fieldName: "addedBy",
    name: t("Added By"),
    minWidth: 100,
    isSortable: true,
    isExportable: false,
    onRender: (item) => getUserById(users, item.memberId),
  },
  {
    key: "added",
    fieldName: "added",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => (item?.added ? format(new Date(item.added)) : ""),
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "type",
    fieldName: "type",
    name: t("Type"),
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "sizeInBytes",
    fieldName: "sizeInBytes",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => formatFileSize(item.sizeInBytes),
  },
];
