import "./styles.scss";

import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
} from "@fluentui/react-components";
import { ChevronDownRegular, NavigationRegular } from "@fluentui/react-icons";
import type { PropsWithChildren } from "react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { type NavLinkProps, useNavigate } from "react-router-dom";
import * as ReactRouterDOM from "react-router-dom";

import { useMachineDriveLoad } from "../../../Components/MachineCV/MachineCVDriveLoadPage/hooks/useMachineDriveLoad";
import { usePerformanceRequest } from "../../../Components/MachineCV/MachineCVPerformancePage/hooks/usePerformanceRequest";
import { Stack } from "../../../Components/Stack";
import {
  useLocationSearch,
  useMachineCVBreadcrumb,
  useWebAnalysisPermissions,
} from "../../../Hooks";
import MachineCVBreadcrumb from "./MachineCVBreadcrumb";

const navLinkStyle = ({ isActive }: { isActive: boolean }) => ({
  textDecoration: "none",
  color: "black",
  padding: "4px 8px",
  fontSize: "14px",
  ...(isActive && { borderBottom: "2px solid #2c529f" }),
});

type NavigationBarProps = React.HTMLAttributes<HTMLElement> & {
  isPerformanceAvailable: boolean;
  machineDriveLoadData: any;
};

const NavLink = ({ to, style, children }: NavLinkProps) => {
  return (
    <ReactRouterDOM.NavLink to={to} style={style}>
      {children}
    </ReactRouterDOM.NavLink>
  );
};

const NavigationBar = ({
  isPerformanceAvailable,
  machineDriveLoadData,
  ...rest
}: NavigationBarProps) => {
  const { t } = useTranslation();
  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const [{ search }] = useLocationSearch();

  return (
    <Stack {...rest} horizontal>
      <NavLink to={`/machine-cv/summary${search}`} style={navLinkStyle}>
        {t("Summary")}
      </NavLink>
      {machineDriveLoadData.calculateDriveLoad && (
        <NavLink to={`/machine-cv/drive-load${search}`} style={navLinkStyle}>
          {t("Drive load")}
        </NavLink>
      )}
      {isPerformanceAvailable && (
        <NavLink to={`/machine-cv/performance${search}`} style={navLinkStyle}>
          {t("Performance")}
        </NavLink>
      )}
      <NavLink to={`/machine-cv/sensors${search}`} style={navLinkStyle}>
        {t("Sensors")}
      </NavLink>
      <NavLink to={`/machine-cv/tasks${search}`} style={navLinkStyle}>
        {t("Tasks")}
      </NavLink>
      <NavLink to={`/machine-cv/comments${search}`} style={navLinkStyle}>
        {t("Comments")}
      </NavLink>
      <NavLink to={`/machine-cv/info${search}`} style={navLinkStyle}>
        {t("Info")}
      </NavLink>
      {hasMachineCvAdministratorPermission && (
        <NavLink to={`/machine-cv/documents${search}`} style={navLinkStyle}>
          {t("Documents")}
        </NavLink>
      )}
      <NavLink to={`/machine-cv/reports${search}`} style={navLinkStyle}>
        {t("Reports")}
      </NavLink>
      <NavLink to={`/machine-cv/alerts${search}`} style={navLinkStyle}>
        {t("Alerts")}
      </NavLink>
    </Stack>
  );
};

const NavigationMenu = ({
  isPerformanceAvailable,
  machineDriveLoadData,
  ...rest
}: NavigationBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const [{ search }] = useLocationSearch();

  const menuSummary = {
    key: `nav-summary-${search}`,
    text: t("Summary"),
    onClick: () => navigate(`/machine-cv/summary${search}`),
  };

  const menuDriveLoad = {
    key: `nav-drive-load-${search}`,
    text: t("Drive Load"),
    onClick: () => navigate(`/machine-cv/drive-load${search}`),
  };

  const menuPerformance = {
    key: `nav-performance-${search}`,
    text: t("Performance"),
    onClick: () => navigate(`/machine-cv/performance${search}`),
  };

  const menuDocuments = {
    key: `nav-documents-${search}`,
    text: t("Documents"),
    onClick: () => navigate(`/machine-cv/documents${search}`),
  };

  const menuReports = {
    key: `nav-reports-${search}`,
    text: t("Reports"),
    onClick: () => navigate(`/machine-cv/reports${search}`),
  };

  const result = [
    {
      key: `nav-sensors-${search}`,
      text: t("Sensors"),
      onClick: () => navigate(`/machine-cv/sensors${search}`),
    },
    {
      key: `nav-tasks-${search}`,
      text: t("Tasks"),
      onClick: () => navigate(`/machine-cv/tasks${search}`),
    },
    {
      key: `nav-comments-${search}`,
      text: t("Comments"),
      onClick: () => navigate(`/machine-cv/comments${search}`),
    },
    {
      key: `nav-info-${search}`,
      text: t("Info"),
      onClick: () => navigate(`/machine-cv/info${search}`),
    },
  ];

  if (isPerformanceAvailable) {
    result.unshift(menuPerformance);
  }
  if (machineDriveLoadData.calculateDriveLoad) {
    result.unshift(menuDriveLoad);
  }
  if (hasMachineCvAdministratorPermission) {
    result.push(menuDocuments);
  }

  result.unshift(menuSummary);
  result.push(menuReports);

  return (
    <div {...rest}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button appearance='transparent' style={{ minWidth: 80, fontSize: 18, gap: 4 }}>
            <NavigationRegular />
            <ChevronDownRegular />
          </Button>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {result.map((item) => (
              <MenuItem key={item.key} onClick={item.onClick}>
                {item.text}
              </MenuItem>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

const MachineCVNavigationBar = ({ children }: PropsWithChildren) => {
  const [{ id }, ,]: any = useLocationSearch();

  const { data: breadcrumbData, isLoading: isBreadcrumbLoading } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  const { data: machineDriveLoadData, isLoading: isMachineDriveLoadLoading }: any =
    useMachineDriveLoad(id);

  const { data: machinePerformanceData, isLoading: isMachinePerformanceLoading }: any =
    usePerformanceRequest(id);

  const isPerformanceAvailable =
    machinePerformanceData?.calculatePerformance && machinePerformanceData?.perfSignalId;

  const ref = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0);
  }, [isMachineDriveLoadLoading, isMachinePerformanceLoading]);

  if (isBreadcrumbLoading) {
    return <Spinner size='tiny' />;
  }

  return (
    <>
      <Stack
        horizontal
        className='breadcrumb-container'
        horizontalAlign='space-between'
        verticalAlign='center'
        style={{ padding: "8px 16px", borderBottom: "1px solid #EDEBE9" }}
      >
        <MachineCVBreadcrumb data={breadcrumbData} width={width} />
        {!isMachineDriveLoadLoading && !isMachinePerformanceLoading && (
          <div ref={ref} className='machine-cv-navigation'>
            <NavigationBar
              className='machine-cv-navigation-bar'
              isPerformanceAvailable={isPerformanceAvailable}
              machineDriveLoadData={machineDriveLoadData}
            />
            <NavigationMenu
              className='machine-cv-navigation-menu'
              isPerformanceAvailable={isPerformanceAvailable}
              machineDriveLoadData={machineDriveLoadData}
            />
          </div>
        )}
      </Stack>
      {children}
    </>
  );
};

export default MachineCVNavigationBar;
