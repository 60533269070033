import * as _ from "lodash-es";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import type { DefaultNodeProps, useTreeState } from "react-hyper-tree";
import type { TreeNode } from "react-hyper-tree/dist/helpers/node";

import { prefetchTrendMeasuredDataInAPeriod } from "../../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../../types";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import { queryClient } from "../../../core";
import { CHART_NUMBER_OF_POINTS } from "../../constants/controlOptions";
import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import useControlsStore from "../../hooks/useControlsStore";
import useGroupingErrorMessages from "../../hooks/useGroupingErrorMessages";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import getPeriodFilters from "../../utils/getPeriodFilters";

interface UseCustomSelectNodeArgs extends DefaultNodeProps {
  handlers: ReturnType<typeof useTreeState>["handlers"];
}

const useCustomSelectNode = ({ node, handlers, onToggle, onSelect }: UseCustomSelectNodeArgs) => {
  const { period, trendsGrouping } = useControlsStore((store) => ({
    period: store.period,
    trendsGrouping: store.trendsGrouping,
  }));

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  const { addSelectedSignal, removeSelectedSignal, changeErrorMessage, toggleIsErrorMessageOpen } =
    useSelectedSignalsStore((store) => ({
      changeErrorMessage: store.changeErrorMessage,
      toggleIsErrorMessageOpen: store.toggleIsErrorMessageOpen,
      addSelectedSignal: store.addSelectedSignal,
      removeSelectedSignal: store.removeSelectedSignal,
    }));

  const [isSearchVisible, setLocalIsSearchVisible] = useState(false);

  const toggleSearchVisibility = useCallback(() => {
    setLocalIsSearchVisible((prev) => !prev);
  }, []);

  const { getSignalGroupByMachineErrorMessage, getGroupEverythingErrorMessage } =
    useGroupingErrorMessages();

  const uncollapseSignals = () => {
    const previousExpandedMachinesSignalsList =
      (queryClient.getQueryData(["trend-signals-list"]) as TreeNode[]) || [];

    const nextExpandedMachinesSignalsList = [
      ...previousExpandedMachinesSignalsList,
      ...node.getChildren().map((item) => item.getData()),
    ];

    queryClient.setQueryData(["trend-signals-list"], nextExpandedMachinesSignalsList);
  };

  const collapseSignals = () => {
    const previousExpandedMachinesSignalsList =
      (queryClient.getQueryData(["trend-signals-list"]) as TreeNode[]) || [];

    const nextExpandedMachinesSignalsList = _.differenceWith(
      previousExpandedMachinesSignalsList,
      node.getChildren().map((item) => item.getData()),
      _.isEqual,
    );

    queryClient.setQueryData(["trend-signals-list"], nextExpandedMachinesSignalsList);
  };

  const handleItemSelect = (nodeId: string) => {
    const node: any = handlers.getNode(nodeId) as TreeNode;

    const data = node.data as ResponseSimplifiedSignal;

    if (!node.isSelected()) {
      if (trendsGrouping?.key === TrendGroupingEnum.GROUP_BY_MACHINE) {
        const message = getSignalGroupByMachineErrorMessage(node.data);
        if (message) {
          toggleIsErrorMessageOpen();
          changeErrorMessage(message);
          return;
        }
      }

      if (trendsGrouping?.key === TrendGroupingEnum.GROUP_EVERYTHING) {
        const message = getGroupEverythingErrorMessage(1);
        if (message) {
          toggleIsErrorMessageOpen();
          changeErrorMessage(message);
          return;
        }
      }

      addSelectedSignal(data);
      handlers.setSelected(nodeId, true);

      const periodFilters = getPeriodFilters(period, node.getData());
      const nodeData = node.getData();

      // prefetch the data for the plot
      prefetchTrendMeasuredDataInAPeriod({
        machineId: nodeData.machineId,
        signalId: node.id.toString(),
        startDate: periodFilters.startDate,
        endDate: periodFilters.endDate,
        numberOfPoints: CHART_NUMBER_OF_POINTS,
        signalType: nodeData.dataType,
        refreshInterval,
      });
    } else {
      handlers.setSelected(nodeId, false);
      removeSelectedSignal(data);
    }
  };

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!node.hasChildren() && !node.asyncNode) {
      if (!node.isSelected()) {
        // The signal is selected
        if (trendsGrouping?.key === TrendGroupingEnum.GROUP_BY_MACHINE) {
          const message = getSignalGroupByMachineErrorMessage(node.getData() as any);
          if (message) {
            toggleIsErrorMessageOpen();
            changeErrorMessage(message);
            return;
          }
        }

        if (trendsGrouping?.key === TrendGroupingEnum.GROUP_EVERYTHING) {
          const message = getGroupEverythingErrorMessage(1);
          if (message) {
            toggleIsErrorMessageOpen();
            changeErrorMessage(message);
            return;
          }
        }

        onSelect(e);
        addSelectedSignal(node.getData() as ResponseSimplifiedSignal);

        const periodFilters = getPeriodFilters(period, node.getData() as any);
        const nodeData = node.getData();

        // prefetch the data for the plot
        prefetchTrendMeasuredDataInAPeriod({
          machineId: nodeData.machineId,
          signalId: node.id.toString(),
          startDate: periodFilters.startDate,
          endDate: periodFilters.endDate,
          numberOfPoints: CHART_NUMBER_OF_POINTS,
          signalType: nodeData.dataType,
          refreshInterval,
        });
      } else {
        // The signal is unselected
        onSelect(e);
        removeSelectedSignal(node.getData() as ResponseSimplifiedSignal);
      }
    }
  };

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // We are at the machine level
    if (node.asyncNode) {
      if (!node.isOpened()) {
        // We are uncollapsing children (signals)
        uncollapseSignals();
      } else {
        // We are collapsing children (signals)
        collapseSignals();
      }
    }

    onToggle(e);
  };

  const isLeafNode = !node.hasChildren() && !node.asyncNode;
  const showEmptyIcon = isLeafNode && !node.isSelected();
  const showFullIcon = isLeafNode && node.isSelected();
  const showCloseIcon = node.options.opened && (node.hasChildren() || node.asyncNode);
  const showOpenIcon = !node.options.opened && (node.hasChildren() || node.asyncNode);

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isLeafNode) {
      handleSelect(e);
    } else {
      handleToggle(e);
    }
  };

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(["trend-signals-list"]);
    };
  }, []);

  return {
    isLeafNode,
    showEmptyIcon,
    showFullIcon,
    showCloseIcon,
    showOpenIcon,
    isSearchVisible,
    handleSelect,
    handleToggle,
    handleItemClick,
    handleItemSelect,
    toggleSearchVisibility,
  };
};

export default useCustomSelectNode;
