import type { MessageBarProps } from "@fluentui/react-components";
import { MessageBar, MessageBarBody } from "@fluentui/react-components";
import RCNotification from "rc-notification";
import type { NotificationInstance as RCNotificationInstance } from "rc-notification/lib/Notification";

import { Theme } from "../../../Theme";

type NotifyOpts = {
  type: MessageBarProps["intent"];
  message: string;
  duration?: number;
};

type NotificationType = MessageBarProps["intent"];

const types: NotificationType[] = ["success", "error", "info", "warning"];

type NotificationApi = {
  success(message: string): void;
  error(message: string): void;
  info(message: string): void;
  warning(message: string): void;
  open(opts: NotifyOpts): void;
};

let instance: RCNotificationInstance | null = null;

const getRCNotificationInstance = (callback: (instance: RCNotificationInstance) => void) => {
  if (instance) {
    callback(instance);
    return;
  }

  RCNotification.newInstance(
    {
      prefixCls: "mcv-notification",
      maxCount: 2,
      style: {
        position: "fixed",
        top: 74,
        left: "50%",
        transform: "translateX(-50%)",
        pointerEvents: "none",
        zIndex: 10000000,
      },
    },
    (notification) => {
      if (instance) {
        callback(instance);
      }

      instance = notification;

      callback(notification);
    },
  );
};

const notify = ({ type, message, duration = 4 }: NotifyOpts): void => {
  getRCNotificationInstance((notification) => {
    notification.notice({
      duration: duration,
      style: {
        padding: 6,
      },
      content: (
        <Theme>
          <MessageBar intent={type}>
            <MessageBarBody>{message}</MessageBarBody>
          </MessageBar>
        </Theme>
      ),
    });
  });
};

export const notification = {
  open: notify,
} as NotificationApi;

types.forEach((type) => {
  notification[type] = (message) => notification.open({ message, type });
});
