import { Button, Text } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import classnames from "classnames";
import type { CSSProperties, PropsWithChildren } from "react";
import { useState } from "react";

import { Stack } from "../Stack";

type ToggleContainerProps = {
  id?: string;
  title: string;
  sideText: string;
  open: boolean;
  show: boolean;
  onOpen?: (id: string) => void;
};

type ToggleIconProps = {
  open: boolean;
};

const textEllipsis: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "80%",
  color: "#fff",
  paddingLeft: "0.5em",
};

const onHoverStyle = {
  root: {
    selectors: {
      ":hover": { background: "#0078D4 !important" },
    },
  },
};

/**
 * Gets the toggle icon component
 *  @param open A value indicating whether the toggle is opened or closed.
 *  @returns The stylized toggle icon component
 */
const ToggleIcon = ({ open }: ToggleIconProps) => {
  return (
    <Button
      appearance='transparent'
      className='toggle-overview-button'
      icon={open ? <ChevronUpRegular /> : <ChevronDownRegular />}
    />
  );
};

/**
 * The toggle container component.
 * @param id The element HTML id.
 * @param title The title.
 * @param sideText The side text.
 * @param open A value indicating the default state.
 * @param show A value indicating whether or not the control should be shown.
 * @param onOpen Function called when the toggle container is opening.
 * @param children The React props.children property.
 * @returns the ToggleContainer component.
 */
export const ToggleContainer = ({
  id,
  title,
  sideText,
  open,
  show,
  children,
  onOpen,
}: PropsWithChildren<ToggleContainerProps>) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleClick = (e) => {
    !isOpen && onOpen?.(e.currentTarget.id);
    setIsOpen(!isOpen);
  };

  return (
    <Stack
      style={{
        contentVisibility: "visible",
        paddingBottom: "0.5em",
        display: show ? "flex" : "none",
      }}
    >
      <Stack
        horizontal
        id={id}
        verticalAlign='center'
        className={classnames({ header: true, "header--open": isOpen })}
        style={{ padding: "0.10em" }}
        onClick={handleClick}
      >
        <Text style={textEllipsis}>{sideText}</Text>
        <Stack horizontal className='header-items' verticalAlign='center'>
          <Text style={textEllipsis} size={300}>
            {title}
          </Text>
        </Stack>
        <Stack horizontal horizontalAlign='end'>
          <ToggleIcon open={isOpen} />
        </Stack>
      </Stack>
      <div hidden={!isOpen}>{children}</div>
    </Stack>
  );
};
