import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { TabItemProps } from "../../../common/TabMenu";
import { TabMenu } from "../../../common/TabMenu";
import { TRIGGERS_TAB } from "../Sensor/SensorTabs";
import TelemetryTab from "./TelemetryTab";
import TriggerStatusTab from "./TriggerStatusTab";

export const TRIGGER_STATUS_TAB = "Trigger Status";
export const TELEMETRY_TAB = "Telemetry";

const MachineTabs = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TELEMETRY_TAB);

  const tabItems: TabItemProps[] = [
    {
      key: TELEMETRY_TAB,
      title: t("Telemetry"),
      content: <TelemetryTab />,
      tabProps: {
        className: "tab-container",
      },
    },
    {
      key: TRIGGER_STATUS_TAB,
      title: t("Trigger Status"),
      content: <TriggerStatusTab />,
      tabProps: {
        className: "tab-container",
      },
    },
  ];

  return (
    <>
      <TabMenu
        tabs={tabItems}
        selectedValue={activeTab}
        onTabSelect={(e, data) => {
          const activePivot = (data.value as string) ?? undefined;
          setActiveTab(activePivot || TRIGGERS_TAB);
        }}
      />
    </>
  );
};

export default MachineTabs;
