import { isValidDalogId, isValidIMEI, isValidMacAddress } from "../../../../schema/Utils";
import type { Company } from "../../../../types";
import type { Corporation } from "../../../Corporations/models";
import { maxRsshPort, minRsshPort } from "../../../Gateways/AddDialog";
import type { MachineToList } from "../../../Machines/models";
import type { Project } from "../../../Projects/models";
import type { WirelessSensorNodeResponse } from "../../../SensorNodes/models";
import type { SubmissionSupport } from "../../models";
import type { CorporationSubmission } from "../models";
import type { ValidationResult } from "../SubmissionsDetails";
import { filterGatewaySupport } from "./submissionHelpers";

export function validateInput(
  t,
  submission: CorporationSubmission,
  corpos: Corporation[],
  companies: Company[],
  projs: Project[],
  machs: MachineToList[],
  sensors: WirelessSensorNodeResponse[],
  supportRaw: SubmissionSupport,
): ValidationResult[] {
  const errors: Map<string, string[]> = new Map();

  const addError = (uniqueId: string, message: string) => {
    if (!errors.has(uniqueId)) {
      errors.set(uniqueId, []);
    }
    errors.get(uniqueId)!.push(message);
  };
  // Corporation level validation
  if (!submission.name?.trim()) {
    addError(submission.uniqueId, t("Corporation name is required"));
  }

  if (submission.id) {
    const existing = corpos.some((ele) => ele.id === submission.id);
    if (!existing) {
      addError(
        submission.uniqueId,
        t(
          "Selected corporation does not exist id: {{submissionId}} : It may mean that the corporation was deleted.",
          { submissionId: submission.id },
        ),
      );
    }
    if (!submission?.companies?.length) {
      addError(
        submission.uniqueId,
        t(
          "Since you are providing a Corporation Id: The 'companies' list is either null or empty. Please provide valid companies information.",
        ),
      );
    }
  } else {
    const existing = corpos.some((ele) => ele.name === submission.name);
    if (existing) {
      addError(
        submission.uniqueId,
        t("Name of corporation {{submissionName}}, you want to add, already exist", {
          submissionName: submission.name,
        }),
      );
    }
  }

  // Company level validation
  for (const company of submission?.companies || []) {
    if (!company.name?.trim()) {
      addError(company.uniqueId, t("Company name is required"));
    }

    if (company.id) {
      const existing = companies.some((ele) => ele.id === company.id);
      if (!existing) {
        addError(
          company.uniqueId,
          t(
            "Selected company does not exist id: {{companyId}} : It may mean that the company was deleted.",
            { companyId: company.id },
          ),
        );
      }
      if (!company?.projects?.length) {
        addError(
          company.uniqueId,
          t(
            "Since you are providing a Company Id: The 'projects' list is either null or empty. Please provide valid project information.",
          ),
        );
      }
    } else {
      const existing = companies.some((ele) => ele.name === company.name);
      if (existing) {
        addError(
          company.uniqueId,
          t("Name of company {{companyName}}, you want to add, already exist", {
            companyName: company.name,
          }),
        );
      }
    }

    // Project level validation
    for (const project of company.projects || []) {
      // Validate required fields for new projects
      if (!project.id) {
        if (!project.name?.trim()) {
          addError(project.uniqueId, t("Project name is required when creating a new project"));
        }
      }

      if (project.id) {
        const existing = projs.some((ele) => ele.id === project.id);
        if (!existing) {
          addError(
            project.uniqueId,
            t(
              "Selected project does not exist id: {{projectId}} : It may mean that the project was deleted.",
              { projectId: project.id },
            ),
          );
        }

        if (!project?.machines?.length && !project?.gateways?.length) {
          addError(
            project.uniqueId,
            t(
              "Since you are providing a Project Id: The 'machines' || 'gateways' list is either null or empty. Please provide valid 'machines' || 'gateways' information.",
            ),
          );
        }
      }

      // Gateway validation
      for (const gateway of project.gateways || []) {
        if (!gateway.serialNumber?.trim()) {
          addError(gateway.uniqueId, t("Gateway serial number is required"));
        }

        if (!gateway.installationLocation?.trim()) {
          addError(
            gateway.uniqueId,
            t(
              "Gateway installation location cannot be undefined or empty for serialNumber: {{serialNumber}}",
              { serialNumber: gateway.serialNumber },
            ),
          );
        }
      }

      // Machine and sensor validation
      for (const machine of project.machines || []) {
        if (machine.id) {
          const existing = machs.some((ele) => ele.id === machine.id);
          if (!existing) {
            addError(
              machine.uniqueId,
              t(
                "Selected machine does not exist id: {{machineId}} : It may mean that the machine was deleted.",
                { machineId: machine.id },
              ),
            );
          }

          if (!machine.sensors?.length) {
            addError(
              machine.uniqueId,
              t(
                "Since you are providing a Machine Id: The 'sensors' list is either null or empty. Please provide valid 'sensors' information.",
              ),
            );
          }
        }

        if (!machine.name?.trim()) {
          addError(machine.uniqueId, t("Machine name cannot be undefined or empty"));
        }

        // Gearbox validation
        if (machine.gearbox) {
          if (machine.gearbox.power && isNaN(machine.gearbox.power)) {
            addError(machine.uniqueId, t("Gearbox power must be a valid number"));
          }
          if (machine.gearbox.rotationalSpeed && isNaN(Number(machine.gearbox.rotationalSpeed))) {
            addError(machine.uniqueId, t("Gearbox rotational speed must be a valid number"));
          }
        }

        // Sensor validation
        if (machine?.sensors?.length) {
          for (const sensor of machine.sensors) {
            if (!sensor.sensorSerialNumber?.trim()) {
              addError(sensor.uniqueId, t("Sensor serial number is required"));
            }

            // Check for duplicate sensor
            const existing = sensors.some(
              (ele) => ele.sensorNodeId === sensor.sensorSerialNumber?.toLowerCase(),
            );
            if (!existing) {
              addError(
                sensor.uniqueId,
                t("Sensor with serial number {{serialNumber}} does not exist", {
                  serialNumber: sensor.sensorSerialNumber,
                }),
              );
            }

            // Check required fields
            const requiredFields = {
              sensorNo: sensor.sensorNo?.trim(),
              sensorDescription: sensor.sensorDescription?.trim(),
              mountingDirection: sensor.mountingDirection,
              mountingType: sensor.mountingType,
            };

            Object.entries(requiredFields).forEach(([field, value]) => {
              if (!value) {
                addError(
                  sensor.uniqueId,
                  t("{{field}} is required for sensor {{serialNumber}}", {
                    field: field,
                    serialNumber: sensor.sensorSerialNumber || "Unknown",
                  }),
                );
              }
            });
          }
        }
      }
    }
  }

  const supportFilter = filterGatewaySupport(supportRaw, submission);

  // Gateway support validation for new gateways
  for (const gateway of supportFilter.gatewaysSupportToAdd) {
    if (!gateway.serialNumber?.trim()) {
      addError(gateway.matchUniqueId, t("Gateway serial number cannot be undefined or empty"));
    }

    if (!gateway.adminPassword?.trim()) {
      addError(gateway.matchUniqueId, t("Admin password is required for new gateways"));
    }

    if (!gateway.userPassword?.trim()) {
      addError(gateway.matchUniqueId, t("User password is required for new gateways"));
    }

    if (gateway.ltePlanSize) {
      const ltePlanSize = Number(gateway.ltePlanSize);
      if (isNaN(ltePlanSize) || ltePlanSize <= 0) {
        addError(
          gateway.matchUniqueId,
          t("LTE plan size must be a valid positive number for gateway: {{serialNumber}}", {
            serialNumber: gateway.serialNumber,
          }),
        );
      }
    }

    if (gateway.rsshPort) {
      const port = Number(gateway.rsshPort);
      if (isNaN(port) || port < minRsshPort || port > maxRsshPort) {
        addError(
          gateway.matchUniqueId,
          t("RSSH port must be a valid number between {{minRsshPort}} and {{maxRsshPort}}", {
            minRsshPort,
            maxRsshPort,
          }),
        );
      }
    }

    if (gateway.ethernetMacAddress && !isValidMacAddress(gateway.ethernetMacAddress)) {
      addError(gateway.matchUniqueId, t("Invalid MAC address format"));
    }

    if (gateway.iMEI && !isValidIMEI(gateway.iMEI)) {
      addError(gateway.matchUniqueId, t("Invalid IMEI format"));
    }
  }

  // Gateway support update validation
  for (const gateway of supportFilter.gatewaysSupportToUpdate) {
    if (!gateway.serialNumber?.trim()) {
      addError(gateway.matchUniqueId, t("Gateway serial number cannot be undefined or empty"));
    }

    if (gateway.ltePlanSize) {
      const ltePlanSize = Number(gateway.ltePlanSize);
      if (isNaN(ltePlanSize) || ltePlanSize <= 0) {
        addError(
          gateway.matchUniqueId,
          t("LTE plan size must be a valid positive number for gateway: {{serialNumber}}", {
            serialNumber: gateway.serialNumber,
          }),
        );
      }
    }

    if (!gateway.id?.trim()) {
      addError(
        gateway.matchUniqueId,
        t("Gateway ID cannot be undefined or empty for serial number: {{serialNumber}}", {
          serialNumber: gateway.serialNumber,
        }),
      );
    }

    if (gateway.rsshPort) {
      const port = Number(gateway.rsshPort);
      if (isNaN(port) || port < 1024 || port > 65535) {
        addError(
          gateway.matchUniqueId,
          t("RSSH port must be a valid number between 1024 and 65535"),
        );
      }
    }

    if (gateway.ethernetMacAddress && !isValidMacAddress(gateway.ethernetMacAddress)) {
      addError(gateway.matchUniqueId, t("Invalid MAC address format"));
    }

    if (gateway.iMEI && !isValidIMEI(gateway.iMEI)) {
      addError(gateway.matchUniqueId, t("Invalid IMEI format"));
    }
  }

  // Machine support validation
  for (const machine of supportFilter.machinesSupport) {
    if (!machine.dalogId?.trim()) {
      addError(
        machine.matchUniqueId,
        t("Machine dalogId cannot be undefined or empty for machine: {{machineName}}", {
          machineName: machine.nameMachine,
        }),
      );
    }
    if (!isValidDalogId(machine.dalogId)) {
      addError(
        machine.matchUniqueId,
        t("Invalid dalogId format for machine {{machineName}}. Format must be (xx-xxx-xx)", {
          machineName: machine.nameMachine,
        }),
      );
    }
    if (machs.some((ele) => ele.dalogId === machine.dalogId)) {
      addError(
        machine.matchUniqueId,
        t("DalogId {{dalogId}} already exists for machine {{machineName}}", {
          dalogId: machine.dalogId,
          machineName: machine.nameMachine,
        }),
      );
    }
  }

  // Convert Map to array of ValidationResult
  return Array.from(errors.entries()).map(([matchUniqueId, messages]) => ({
    matchUniqueId,
    messages,
  }));
}
