import type { Theme as FluentUiTheme } from "@fluentui/react-components";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

const fluent9Theme: FluentUiTheme = {
  ...webLightTheme,
  fontFamilyBase:
    '"Open Sans", "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  colorBrandBackground: "#2c529f",
  colorCompoundBrandBackground: "#2c529f",
  // colorBrandBackgroundHover: "#2c529f",
  colorCompoundBrandStroke: "#2c529f",
};

export const Theme = ({ children }) => {
  return <FluentProvider theme={fluent9Theme}>{children}</FluentProvider>;
};
