import type {
  SelectTabData,
  SelectTabEvent,
  TabListProps,
  TabProps,
  TabValue,
} from "@fluentui/react-components";
import { Tab, TabList } from "@fluentui/react-components";
import React, { useMemo, useState } from "react";

import { Stack } from "../../Stack";

export type TabItemProps = {
  key: string;
  title: JSX.Element | string;
  content: JSX.Element;
  tabProps?: Omit<TabProps, "value"> & { value?: string };
  show?: boolean;
};

type TabMenuProps = TabListProps & {
  showActivated?: boolean;
  tabs: TabItemProps[];
  tabsContainer?: React.HTMLAttributes<HTMLDivElement>;
  stackProps?: any;
};

export const TabMenu = ({
  tabs: incomingTabs,
  tabsContainer,
  showActivated,
  selectedValue = "",
  stackProps,
  ...props
}: TabMenuProps) => {
  const tabs = useMemo(() => {
    if (showActivated) {
      return incomingTabs.filter((tab) => tab.show);
    }

    return incomingTabs;
  }, [incomingTabs, showActivated]);

  const [customSelectedValue, setCustomSelectedValue] = useState<TabValue>(
    selectedValue || tabs[0]?.tabProps?.value || tabs[0].key,
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setCustomSelectedValue(data.value);

    props?.onTabSelect && props.onTabSelect(event, data);
  };

  return (
    <Stack {...stackProps}>
      <TabList selectedValue={customSelectedValue} {...props} onTabSelect={onTabSelect}>
        {tabs.map((tab) => (
          <Tab key={tab.key} {...tab.tabProps} value={tab?.tabProps?.value ?? tab.key}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <div {...tabsContainer}>
        {tabs.map((tab) => (
          <React.Fragment key={tab?.tabProps?.value ?? tab.key}>
            {customSelectedValue === (tab?.tabProps?.value ?? tab.key) && tab.content}
          </React.Fragment>
        ))}
      </div>
    </Stack>
  );
};
