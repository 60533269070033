import { Spinner } from "@fluentui/react-components";
import type { FC } from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Stack } from "../../../Stack";
import { useValidation } from "../hooks/useValidation";
import type { GatewaySubmission } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";
import { SupportContext } from "../SupportContext";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";
import DialogSelector from "./DialogSelector";

const getGatewaySchema = (t: any) =>
  z.object({
    serialNumber: z.string().min(1, t("This field is required")),
    installationLocation: z.string().min(1, t("This field is required")),
  });

type GatewaySchemaKeys = keyof z.infer<ReturnType<typeof getGatewaySchema>>;

interface GatewaySupportInputProps {
  context: SecondPanelContext;
}

const GatewaySupportInput: FC<GatewaySupportInputProps> = ({ context }) => {
  const { t } = useTranslation();
  const { support } = useContext(SupportContext);
  const [loading, isLoading] = useState(false);
  const [renderGateway, setRenderGateway] = useState(0);

  const gatewaySchema = useMemo(() => getGatewaySchema(t), [t]);

  const { errors, validateField, setValue } = useValidation({
    schema: gatewaySchema,
    mode: "onChange",
    defaultValues: context.initialValues.gat,
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setRenderGateway((prev) => prev + 1);
        isLoading(false);
      }, 2000);
    }
  }, [loading]);

  const updateGatewayField = async (field: GatewaySchemaKeys, value: string, isToLoad = false) => {
    const trimmedValue = value.trim();
    const gatNew: Partial<GatewaySubmission> = {
      [field]: trimmedValue,
      uniqueId: context.initialValues.gat.uniqueId,
    };
    context.updateFunction(gatNew, context.initialValues.gat.uniqueId);

    setValue(field, trimmedValue);
    await validateField(field, trimmedValue);

    isToLoad && isLoading(true);
  };

  return (
    <>
      <TextFieldWithDocuments
        value={context.initialValues.gat?.serialNumber || ""}
        disabled={context.context !== "Gateway"}
        label={t("Gateway Serial")}
        files={context.initialValues.gat?.files}
        fieldName='serialNumber'
        errorMessage={errors.serialNumber?.message}
        onChange={(newVal) => updateGatewayField("serialNumber", newVal, true)}
      />

      <TextFieldWithDocuments
        value={context.initialValues.gat?.installationLocation || ""}
        disabled={context.context !== "Gateway"}
        label={t("Location: ({{userDescription}})", {
          userDescription: context.initialValues.gat?.locationOfInstallationDescription ?? "",
        })}
        files={context.initialValues.gat?.files}
        fieldName='installationLocation'
        errorMessage={errors.installationLocation?.message}
        onChange={(newVal) => updateGatewayField("installationLocation", newVal)}
      />

      {loading ? (
        <Stack horizontalAlign='center' verticalAlign='center' style={{ height: "600px" }}>
          <Spinner size='medium' label={t("Loading...")} />
        </Stack>
      ) : (
        support && <DialogSelector key={renderGateway} />
      )}
    </>
  );
};

export default GatewaySupportInput;
