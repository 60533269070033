import { Text } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { topControlsStyles } from "../../../../modules/analysis-trend-view/components/TrendViewControls/TrendViewControls";
import { Stack } from "../../../Stack";

const styles = {
  headerText: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    minHeight: 30,
  },
};

const WirelessSensorsLayoutHeader = () => {
  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
      style={topControlsStyles}
      id='top-controls'
    >
      <Stack horizontal className='ws-sidebar-header'>
        <Text style={styles.headerText}>{t("Sensor Configurator")}</Text>
      </Stack>
    </Stack>
  );
};

export default WirelessSensorsLayoutHeader;
