/* eslint-disable react-hooks/exhaustive-deps */
import { Text, Tooltip } from "@fluentui/react-components";
import { AddRegular, Delete20Regular, Edit20Regular } from "@fluentui/react-icons";
import { RefreshIcon } from "@fluentui/react-icons-mdl2";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { format } from "../../schema/Utils";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import type { Column } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { Stack } from "../Stack";
import { DeleteConfirm } from "./DeleteConfirm";
import { MachineAddDialog, MachineEditDialog } from "./MachineAddEditDialogs";
import type { MachineToList, ResponseMachineDetails } from "./models";
import { listAsyncMachines, selectMachinesStatus, selectMachinesToList } from "./reducer";

enum UserActions {
  None,
  Add,
  Edit,
  Delete,
}

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (machine: MachineToList) => void;
  onDelete: (machine: MachineToList) => void;
};

type ActionDialogRequest = {
  selectedItem?: MachineToList;
  action: UserActions;
};

type ActionDialogProps = ActionDialogRequest & {
  machines: MachineToList[];
  onClose: () => void;
};

export const machinesContext = createContext<MachineToList[] | undefined>(undefined);

const getCommandBarItems = (
  t,
  title,
  hasWritePermission: boolean,
  onAdd: () => void,
  onClick: () => void,
) => {
  const titleProp: any = {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => <Text style={titleStyle}>{title}</Text>,
  };

  const refreshButtonProp: any = {
    key: "refresh",
    text: t("Refresh"),
    type: CommandBarItemType.Button,
    icon: <RefreshIcon />,
    onClick: onClick,
  };

  const actionButtonsProps: any = [
    {
      key: "add",
      text: t("Add"),
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAdd,
    },
  ];

  const result: any = hasWritePermission
    ? [titleProp, ...actionButtonsProps, refreshButtonProp]
    : [titleProp, refreshButtonProp];
  return result;
};

const getColumns = ({ t, hasActions, onEdit, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "dalogId",
      name: "Dalog Id",
      fieldName: "dalogId",
      minWidth: 100,
      isSortable: true,
      onRender: ({ id, dalogId }: ResponseMachineDetails) => (
        <Link to={id} style={linkStyle}>
          {dalogId}
        </Link>
      ),
    },
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "projectName",
      name: t("Project"),
      fieldName: "projectName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "companyName",
      name: t("Company"),
      fieldName: "companyName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "corporationName",
      name: t("Corporation"),
      fieldName: "corporationName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "manufacturer",
      name: t("Manufacturer"),
      fieldName: "manufacturer",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "customerCode",
      name: t("Customer Code"),
      fieldName: "customerCode",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ trendDataFrom }: ResponseMachineDetails) =>
        trendDataFrom && format(new Date(trendDataFrom)),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseMachineDetails) => dataUntil && format(new Date(dataUntil)),
    },
  ];
  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (machine: MachineToList) => (
        <div key={machine.dalogId + "actions"} style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Edit")}>
            <Edit20Regular
              style={{ color: "#2c529f", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(machine);
              }}
            />
          </Tooltip>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <Delete20Regular
              style={{ color: "#2c529f", cursor: "pointer", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(machine);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  return columns;
};

const ActionDialog = ({
  machines,
  selectedItem,
  action,
  onClose,
}: ActionDialogProps): JSX.Element | null => {
  let result: JSX.Element | null = null;
  if (!machines) {
    return result;
  }

  switch (action) {
    case UserActions.Add:
      result = (
        <MachineAddDialog dalogIds={machines.map((gate) => gate.dalogId)} onClose={onClose} />
      );
      break;

    case UserActions.Edit:
      if (selectedItem) {
        result = (
          <MachineEditDialog
            tableItem={selectedItem}
            dalogIds={machines.map((gate) => gate.dalogId)}
            onClose={onClose}
          />
        );
      }

      break;

    case UserActions.Delete:
      if (selectedItem) {
        result = <DeleteConfirm data={selectedItem} onClose={onClose} />;
      }

      break;
  }

  return result;
};

const MachinesList = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const dispatch = useAppDispatch();
  const [action, setAction] = useState<ActionDialogRequest>({
    selectedItem: null,
    action: UserActions.None,
  });
  const { filters, handleSearch } = useTableFilters<MachineToList>({
    keys: [
      "dalogId",
      "name",
      "corporationName",
      "companyName",
      "projectName",
      "manufacturer",
      "customerCode",
    ],
  });

  const hasWritePermission = auth.metaDataContributor;

  // Gets the machines list.
  useEffect(() => {
    if (machineStatus !== Status.void) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, [machineStatus]);

  // Handlers.
  const onAdd = () => {
    setAction({ selectedItem: null, action: UserActions.Add });
  };

  const onEdit = (machine: MachineToList) => {
    setAction({ selectedItem: machine, action: UserActions.Edit });
  };

  const onDelete = (machine: MachineToList) => {
    setAction({ selectedItem: machine, action: UserActions.Delete });
  };

  const onClose = () => {
    setAction({ selectedItem: null, action: UserActions.None });
  };

  return (
    <Stack style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems(t, t("Machines"), hasWritePermission, onAdd, () =>
            dispatch(listAsyncMachines()),
          ),
        )}
        onSearch={handleSearch}
      />

      <div style={{ height: "100%" }}>
        <Table
          persistOpts={{
            key: "table-machines",
            version: 2,
          }}
          header={{
            title: t("Machines"),
          }}
          items={machines}
          v8Columns={getColumns({
            t,
            hasActions: hasWritePermission,
            onEdit,
            onDelete,
          })}
          filters={filters}
          isLoading={machineStatus === Status.loading}
          isError={machineStatus === Status.error}
        />
      </div>

      <ActionDialog
        action={action.action}
        selectedItem={action.selectedItem}
        machines={machines}
        onClose={onClose}
      />
    </Stack>
  );
};

export default MachinesList;
