import type { DropdownProps } from "@fluentui/react-components";
import { Dropdown as FluentUiDropdown } from "@fluentui/react-components";

import { defaultOnRenderOption } from "../Option";
import { useDropdownStyles } from "./useDropdownStyles";

type DropdownType = { options: any[]; onRenderOption?: (option: any) => any } & DropdownProps;

export function TransparentDropdown({ ...rest }: DropdownType) {
  const { transparent } = useDropdownStyles();
  return <Dropdown style={{ padding: "2px 8px" }} className={transparent} {...rest} />;
}

export default function Dropdown({
  multiselect = false,
  disabled,
  selectedOptions = [],
  onOptionSelect,
  options,
  style,
  onRenderOption = defaultOnRenderOption,
  ...rest
}: DropdownType) {
  return (
    <FluentUiDropdown
      multiselect={multiselect}
      disabled={disabled}
      selectedOptions={selectedOptions}
      style={style}
      value={[...selectedOptions].join(", ")}
      onOptionSelect={onOptionSelect}
      {...rest}
    >
      {options.map(onRenderOption)}
    </FluentUiDropdown>
  );
}
