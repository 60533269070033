/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { AxiosInstance } from "axios";
import isEqual from "lodash.isequal";
import { useContext, useEffect, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Combobox from "../../../common/Combobox";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import type { FormItemProps } from "../../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../../common/Form";
import { notification } from "../../../common/Notification";
import { useLargeCommentField } from "../../../common/TextArea";
import { Stack } from "../../../Stack";
import { VpnConnectionsAPI, VpnPCsAPI } from "../../Schema/api";
import type {
  AutomationStatus,
  ConnectionEntryDetailed,
  ConnectionStatus,
  MetadataProject,
  MetadataVpnPC,
} from "../../Schema/models";
import { AutomationStatusType, ConnectionStatusType } from "../../Schema/models";
import type { AddEditDialogProps, BasicDialogProps } from "../../Schema/viewModels";
import { Utils } from "../../Utils/utils";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { FormItemRow, FormItemSection } from "../generic/FormDialogComponents";

type AddConnectionDialogProps = BasicDialogProps & {
  projects: MetadataProject[];
};

type EditConnectionDialogProps = AddConnectionDialogProps & {
  open?: boolean;
  item: ConnectionEntryDetailed;
};

type AddEditConnectionDialogProps = AddEditDialogProps<ConnectionEntryDetailed> & {
  open?: boolean;
  projects: MetadataProject[];
  axiosInstance: AxiosInstance;
};

type FormData = {
  id?: string;
  projectId?: string;
  vpnPcId?: string;
  automationStatusKey: string;
  automationStatusComments?: string;
  connectionStatusKey: string;
  connectionStatusComments?: string;
  connectionDetails?: string;
};

const formSchema = z.object({
  automationStatusKey: z.string(),
  automationStatusComments: z.string().optional(),
  connectionStatusKey: z.string(),
  connectionStatusComments: z.string().optional(),
  connectionDetails: z.string().optional(),
});

/**
 * Gets the form props
 * @returns The form item props array.
 */
const getFormProps = (t, textAreaClass): FormItemProps[] => {
  const result: FormItemProps[] = [
    {
      name: "automationStatusKey",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Automation status *") },
      options: Object.keys(AutomationStatusType).map((key) => {
        return {
          key: key,
          text: AutomationStatusType[key as keyof typeof AutomationStatusType],
        };
      }),
      placeholder: t("Select the automation status..."),
      allowFreeform: false,
    },
    {
      name: "automationStatusComments",
      type: FormItemType.TextArea,
      groupProps: { label: t("Automation status comments") },
    },
    {
      name: "connectionStatusKey",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Connection status *") },
      options: Object.keys(ConnectionStatusType).map((key) => {
        return {
          key: key,
          text: ConnectionStatusType[key as keyof typeof ConnectionStatusType],
        };
      }),
      placeholder: t("Select the connection status..."),
    },
    {
      name: "connectionStatusComments",
      type: FormItemType.TextArea,
      groupProps: {
        label: t("Connection status comments"),
      },
    },
    {
      name: "connectionDetails",
      type: FormItemType.TextArea,
      groupProps: {
        label: t("Connection details"),
        labelProps: { style: { minWidth: 160, marginRight: 8, alignSelf: "flex-start" } },
      },
      className: textAreaClass,
    },
  ];

  return result;
};

/**
 * Gets the initial form values
 * @param item The VPN connection detailed entry.
 * @returns The form data default values
 */
const getInitialValues = (item: ConnectionEntryDetailed | undefined): FormData => {
  const result: FormData = {
    automationStatusKey:
      item?.automationStatus.status || Utils.getAutomationStatusKey(AutomationStatusType.Unknown),
    automationStatusComments: item?.automationStatus.comment || "",
    connectionStatusKey:
      item?.connectionStatus.status || Utils.getConnectionStatusKey(ConnectionStatusType.Unknown),
    connectionStatusComments: item?.connectionStatus.comment || "",
    connectionDetails: item?.connectionDetails || "",
  };

  return result;
};

/**
 * Gets the VPN PC ID
 * @param vpnPCs the VPN PCs list
 * @param id the ID to compare
 * @returns the VPN PC Id if exists. Otherwise an empty string.
 */
const getVpnPcId = (vpnPCs: MetadataVpnPC[], id: string): string => {
  const vpnPC = vpnPCs.find((pc) => pc.id === id);
  return vpnPC?.id || "";
};

/**
 * Gets the Add VPN Connection Dialog component.
 * @param companies The reference companies list.
 * @param projects The reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add VPN Connection dialog component.
 */
export const AddConnectionDialog = ({ projects, onClose }: AddConnectionDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const onSubmit = (formData: ConnectionEntryDetailed) => {
    if (!formData || !axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.create(axiosInstance, formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          t(`Failure adding a VPN connection entry: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success adding a new VPN Connection entry."));
      onClose?.(true);
    });
  };

  return (
    <AddEditConnectionDialog
      open={true}
      axiosInstance={axiosInstance!}
      projects={projects}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Edit VPN Connection Dialog component.
 * @param item The VPN connection item to edit.
 * @param projects The reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Edit VPN Connection dialog component.
 */
export const EditConnectionDialog = ({
  open,
  item,
  projects,
  onClose,
}: EditConnectionDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const onSubmit = (formData: ConnectionEntryDetailed) => {
    if (!formData || !axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.update(axiosInstance, formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure updating a VPN connection entry: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success updating a new VPN Connection entry."));
      onClose?.(true);
    });
  };

  return (
    <AddEditConnectionDialog
      open={open}
      axiosInstance={axiosInstance!}
      item={item}
      projects={projects}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Add-Edit VPN Connection dialog.
 * @param item The VPN connection item to edit.
 * @param axiosInstance The axios instance.
 * @param projects The reference projects list.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onSubmit The method called when the submit button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const AddEditConnectionDialog = ({
  open = false,
  item,
  axiosInstance,
  projects,
  isLoading,
  onSubmit,
  onClose,
}: AddEditConnectionDialogProps) => {
  const { largeArea } = useLargeCommentField();
  const { t } = useTranslation();
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("...");
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState(item?.projectId || "");
  const [vpnPCs, setVpnPCs] = useState<MetadataVpnPC[]>([]);
  const [selectedVpnPcId, setSelectedVpnPcId] = useState("");
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    ...{
      defaultValues: getInitialValues(item),
    },
  });

  // Gets the VPN PCs list.
  useEffect(() => {
    if (!axiosInstance) {
      return;
    }

    VpnPCsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(t(`Failure getting VPN PCs list: ${response.statusText}.`));
        return;
      }

      setVpnPCs(response.data);
    });
  }, []);

  // Gets the selected VPN PC.
  useEffect(() => {
    const vpnPcId = vpnPCs.find((pc) => pc.id === item?.vpnPcId)?.id || "";
    setSelectedVpnPcId(vpnPcId);
  }, [vpnPCs]);

  // Gets the company information.
  useEffect(() => {
    const project = projects.find((p) => p.id === selectedProjectId);
    if (!project) {
      return;
    }

    setSelectedCompanyName(project.company?.name || "");
  }, [projects, selectedProjectId]);

  // Checks whether the entity data has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    const areEqual =
      isEqual(control._defaultValues, control._formValues) &&
      item?.projectId === selectedProjectId &&
      item?.vpnPcId === selectedVpnPcId;
    setDataHasChanged(!areEqual);
  }, [watch()]);

  // Handlers
  const onSubmitHandler = (formData: FormData) => {
    // Builds the Vpn connection entry.
    const automationStatus: AutomationStatus = {
      status: formData.automationStatusKey as keyof typeof AutomationStatusType,
      comment: formData.automationStatusComments,
    };

    const connectionStatus: ConnectionStatus = {
      status: formData.connectionStatusKey as keyof typeof ConnectionStatusType,
      comment: formData.connectionStatusComments,
    };

    // Sets project name
    const projectName =
      projects.find((p) => p.id === selectedProjectId)?.name || selectedProjectId.toString();

    let newItem: ConnectionEntryDetailed = {
      projectId: selectedProjectId,
      projectName: projectName,
      vpnPcId: selectedVpnPcId!,
      automationStatus: automationStatus,
      connectionStatus: connectionStatus,
      connectionDetails: formData.connectionDetails || "",
      emailDetails: "",
    };

    // Sets the Id and emails details for items to be edited.
    if (item) {
      newItem = { ...newItem, id: item.id, emailDetails: item.emailDetails };
    }

    onSubmit?.(newItem);
  };

  return (
    <BaseDialog open={open} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onClose}>
      <BaseDialogTitle>{item ? t("Edit VPN Connection") : t("Add VPN Connection")}</BaseDialogTitle>

      <DialogContent>
        <Stack verticalAlign='center'>
          <FormItemSection title={t("General Properties")}>
            <FormItemRow label={t("Company")}>
              <p className='label-value-text' style={{ paddingBottom: "8px" }}>
                {selectedCompanyName}
              </p>
            </FormItemRow>
            <FormItemRow label={t("Project *")}>
              <Combobox
                options={projects.map((p) => {
                  return { key: p.id, text: p.name };
                })}
                value={item?.projectId || ""}
                onChange={(key) => setSelectedProjectId(`${key}`)}
              />
            </FormItemRow>
            <FormItemRow label={"VPN PC *"}>
              <Combobox
                options={vpnPCs.map((pc) => {
                  return { key: pc?.id || "", text: pc?.name };
                })}
                value={item ? getVpnPcId(vpnPCs, item.vpnPcId!) : ""}
                onChange={(key) => setSelectedVpnPcId(`${key}`)}
              />
            </FormItemRow>
          </FormItemSection>
          <FormItemSection title={t("Connection Properties")}>
            {renderFormItems(getFormProps(t, largeArea), {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
          </FormItemSection>
        </Stack>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={
              !isValid ||
              !dataHasChanged ||
              isLoading ||
              selectedProjectId === "" ||
              selectedVpnPcId === ""
            }
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={handleSubmit(onSubmitHandler)}
          >
            {t("Save Changes")}
          </Button>
          <Button onClick={onClose}>{t("Cancel")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
