import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import { STATUSES_COLOR } from "./config";
import PerformanceStatus from "./PerformanceStatus";

const LoadAndPerformance = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { driveLoad = {} } = useDriveLoadStore((store: any) => ({
    driveLoad: store.driveLoad,
  }));

  return (
    <div {...rest} className={classNames("load-and-performance", className)}>
      <div className='semibold-title mb-8'>{t("Load & Performance")}</div>
      <div className='white-container'>
        <div className='mb-8'>
          <div className='body-text mb-8'>{t("Average")}</div>
          <div
            className='status-box'
            style={{
              backgroundColor: STATUSES_COLOR[driveLoad.currentAverageLoad],
            }}
          >
            {driveLoad.currentAverageLoad}
          </div>
        </div>
        <div className='mb-8'>
          <div className='body-text mb-8'>{t("Dynamic")}</div>
          <div
            className='status-box'
            style={{
              backgroundColor: STATUSES_COLOR[driveLoad.currentDynamicLoad],
            }}
          >
            {driveLoad.currentDynamicLoad}
          </div>
        </div>
        <div className='mb-8'>
          <div className='body-text mb-8'>{t("Performance")}</div>
          <PerformanceStatus value={driveLoad.currentPerformance} />
        </div>
      </div>
    </div>
  );
};

export default LoadAndPerformance;
