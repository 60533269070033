import { Text, Tooltip } from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import type { Condition } from "../../../modules/machine-cv/constants";
import { getConditionFormat } from "../../../modules/machine-cv/constants";

type SummaryBadgeProps = {
  description?: string;
  value?: string;
  isTooltip?: any;
};

export const SummaryBadge = ({ description, value, isTooltip }: SummaryBadgeProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isTooltip && description && (
        <>
          <Tooltip
            withArrow
            relationship='label'
            content={description}
            showDelay={0}
            hideDelay={0}
            positioning='below'
          >
            <Info20Regular
              aria-describedby='signal-info-tooltip'
              style={{ cursor: "pointer", marginRight: 4 }}
            />
          </Tooltip>
        </>
      )}
      {!isTooltip && description && (
        <Text
          style={{
            color: "#fff",
            marginRight: "auto",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {description}
        </Text>
      )}
      <Text style={{ color: "#fff" }}>{getConditionFormat(t)[value as Condition]}</Text>
    </>
  );
};
