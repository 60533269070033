import { conditionColor } from "../../../../../../../../../modules/machine-cv/constants";
import Slice from "./Slice";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export type SliceType = {
  percentage: number;
  color: string;
  text: any;
  onClick?: () => void;
  dataKey?: string;
  dataValue?: string;
};

export function renderPieChartSlices(slices: SliceType[]) {
  const radius = 100;
  const centerX = 100;
  const centerY = 100;

  let startAngle = 0; // Start from the top (90° or -π/2)

  return slices.map(({ percentage, ...rest }, index) => {
    // Calculate angles
    const sliceAngle = (percentage / 100.001) * 2 * Math.PI;
    const endAngle = startAngle + sliceAngle;

    // Arc endpoint
    const x1 = centerX + radius * Math.cos(startAngle - Math.PI / 2);
    const y1 = centerY + radius * Math.sin(startAngle - Math.PI / 2);
    const x2 = centerX + radius * Math.cos(endAngle - Math.PI / 2);
    const y2 = centerY + radius * Math.sin(endAngle - Math.PI / 2);

    // Determine if the arc is greater than 180 degrees
    const largeArcFlag = sliceAngle > Math.PI ? 1 : 0;

    // Path for the slice
    const pathData = `
      M ${centerX},${centerY}
      L ${x1},${y1}
      A ${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2}
      Z
    `;

    // Uncomment if we need text inside the PieChart
    // const midAngle = startAngle + sliceAngle / 2;

    // const labelX = centerX + (radius - 20) * Math.cos(midAngle - Math.PI / 2);
    // const labelY = centerY + (radius - 20) * Math.sin(midAngle - Math.PI / 2);

    // Update start angle for the next slice
    startAngle = endAngle;

    return <Slice key={index} pathData={pathData} {...rest} />;
  });
}

export const getSlicesDataByKey = ({ data, key, colors }): SliceType[] => {
  let conditionCount = {};

  const mappedItemsToConditions = data.reduce(
    (acc: any, curr: any, _, array) => {
      const itemKey = curr[key];

      if (!acc[itemKey]) {
        acc[itemKey] = {
          percentage: 0,
          color: colors[itemKey] || getRandomColor(),
          text: 0,
          [key]: itemKey,
          dataKey: key,
          dataValue: itemKey,
        };
        conditionCount[itemKey] = 0;
      }

      conditionCount[itemKey] = conditionCount[itemKey] + 1;

      acc[itemKey].percentage = (conditionCount[itemKey] * 100) / array.length;
      acc[itemKey].text = conditionCount[itemKey];

      return acc;
    },
    {}
  );

  return Object.values(mappedItemsToConditions);
};
