import type {
  DialogProps,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Field,
  Label,
  Spinner,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { SkypeCheckIcon } from "@fluentui/react-icons-mdl2";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import type { RoleType } from "../../schema/status";
import { ScopeLevelType, Status } from "../../schema/status";
import { format } from "../../schema/Utils";
import Checkbox from "../common/Checkbox";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { getDayPickerStrings } from "../common/Form";
import { NoData } from "../common/NoData";
import { notification } from "../common/Notification";
import type { personaProps, ServiceAccountAddRoleToShow } from "../ServiceAccount/AddDialog";
import { ScopeSelections } from "../ServiceAccount/ScopesSelections";
import { Stack } from "../Stack";
import type { IRole } from "./models";
import { listAsyncRoles, selectRoles, selectRolesStatus } from "./reducer";
import type { CustomItemsType } from "./ScopesOfRole/reducer";
import {
  listAsyncScopesOfRole,
  selectScopesOfRoleItems,
  selectScopesOfRoleStatus,
} from "./ScopesOfRole/reducer";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

type AddRolesPersonaDialogProps = Omit<DialogProps, "children" | "open"> & {
  show: boolean;
  onClose: () => void;
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<React.SetStateAction<ServiceAccountAddRoleToShow[]>>;
  persona: personaProps;
};

interface pivotsProps {
  name: string;
  completed: boolean;
  availableOnComplete: string[];
  next?: string;
  prev?: string;
}

interface pivotsCustom {
  selected: string;
  items: pivotsProps[];
}

export type RoleItemDescription = {
  roleId: string;
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  scopeLevelId?: string | undefined;
  hasScope: boolean;
};

export const AddRolePersona = ({
  show,
  onClose,
  rolesToAdd,
  setRolesToAdd,
  persona,
  ...rest
}: AddRolesPersonaDialogProps) => {
  const { t } = useTranslation();
  const [pivotProps, setPivotProps] = useState<pivotsCustom>({
    selected: persona.root ? "expiration" : "scope",
    items: [],
  });
  const dispatch = useAppDispatch();
  const [rolesId, setRolesId] = useState<RoleItemDescription[] | undefined>();

  const rolesStatus = useAppSelector(selectRolesStatus);
  const roles = useAppSelector(selectRoles);
  const allItems: CustomItemsType[] = useAppSelector(selectScopesOfRoleItems);
  const scopePerRoleStatus = useAppSelector(selectScopesOfRoleStatus);
  const [withOutExpiration, setWithOutExpiration] = useState(false);
  const [controlledItems, setControlledItems] = useState<ControlledItem[]>();

  const [expiresAtUtc, setExpiresAtUtc] = useState<string>();

  useEffect(() => {
    const myRoles = roles.filter((el) => persona.persona.includes(el.type));
    setRolesId(
      myRoles.map((ele: IRole) => {
        return { roleId: ele.id, role: ele.type, hasScope: ele.hasScope };
      }),
    );
    return () => {};
  }, [persona, roles]);

  useEffect(() => {
    rolesId && persona.root === false
      ? setControlledItems(
          allItems?.map((ele) => {
            if (ele.name === "name") return { ...ele, check: true };
            else return { ...ele, check: false };
          }),
        )
      : rolesId &&
        setControlledItems(
          allItems?.map((ele) => {
            if (ele.scope === ScopeLevelType.Root) return { ...ele, check: true };
            else return { ...ele, check: false };
          }),
        );

    return () => {};
  }, [allItems, persona.root, rolesId]);

  useEffect(() => {
    if (rolesStatus === Status.void) dispatch(listAsyncRoles());
    else if (rolesStatus === Status.idle && rolesId)
      dispatch(listAsyncScopesOfRole(rolesId.at(0).roleId));
  }, [dispatch, rolesStatus, rolesId]);

  useEffect(() => {
    const itemsSelBool = controlledItems?.filter((ele) => ele.check === true).length > 0;
    setPivotProps({
      selected: pivotProps.selected,
      items: persona.root
        ? [
            {
              name: "expiration",
              completed: itemsSelBool,
              availableOnComplete: ["review"],
              next: "review",
            },
            {
              name: "review",
              completed: itemsSelBool,
              availableOnComplete: ["expiration"],
              prev: "expiration",
            },
          ]
        : [
            {
              name: "scope",
              completed: itemsSelBool,
              availableOnComplete: ["expiration"],
              next: "expiration",
            },
            {
              name: "expiration",
              completed: itemsSelBool,
              availableOnComplete: ["scope", "review"],
              next: "review",
              prev: "members",
            },
            {
              name: "review",
              completed: itemsSelBool,
              availableOnComplete: ["scope", "expiration"],
              prev: "expiration",
            },
          ],
    });
    return () => {};
  }, [pivotProps.selected, controlledItems, persona.root]);

  const handleClose = () => {
    setWithOutExpiration(false);
    setPivotProps({
      selected: undefined,
      items: [],
    });
    setRolesId(undefined);
    onClose?.();
  };

  const handleAdd = async () => {
    const auxItems: ServiceAccountAddRoleToShow[] = [];
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);

    const rootScope = allItems?.find((ele) => ele.scope === ScopeLevelType.Root);

    rolesId.forEach((rol) =>
      filteredItems.forEach((ele) =>
        auxItems.push({
          roleId: rol.roleId,
          scopeLevelId: rol.hasScope ? ele.scopeId : rootScope?.scopeId,
          resource: rol.hasScope ? ele.name : "",
          role: rol.role,
          scopeResourceId: rol.hasScope ? ele.scopeResourceId : undefined,
          scopeLevel: rol.hasScope ? ele.scope : ScopeLevelType.Root,
          expiresAtUtc: expiresAtUtc,
        }),
      ),
    );

    setWithOutExpiration(false);
    setPivotProps({
      selected: "scope",
      items: [],
    });

    const filteredArr = [...auxItems, ...rolesToAdd].reduce((acc, current) => {
      const x = acc.find(
        (item) =>
          item.roleId === current.roleId &&
          item.scopeLevelId === current.scopeLevelId &&
          item.scopeResourceId === current.scopeResourceId,
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        notification.info(t("One or more items are already selected."));
        return acc;
      }
    }, []);

    setRolesToAdd(filteredArr);

    handleClose();
  };

  const onLinkClick = (item) => {
    const myProps = pivotProps.items.find((ite) => ite.name === pivotProps.selected);
    const myPropsToGo = pivotProps.items.find((ite) => ite.name === item.props.itemKey);
    if (myProps.completed || myPropsToGo.completed) {
      setPivotProps({
        selected: item.props.itemKey,
        items: [...pivotProps.items],
      });
    } else {
      notification.warning(t("Please complete the {{name}} selection", { name: myProps.name }));
    }
  };

  const scopePivot = useCallback(() => {
    return (
      <>
        {scopePerRoleStatus === Status.idle ? (
          <Stack>
            <ScopeSelections
              controlledItems={controlledItems}
              setControlledItems={setControlledItems}
            />
          </Stack>
        ) : scopePerRoleStatus === Status.loading ? (
          <Spinner></Spinner>
        ) : (
          <Stack style={{ height: "100vh" }} verticalAlign='space-around'>
            <NoData />
          </Stack>
        )}
      </>
    );
  }, [scopePerRoleStatus, controlledItems]);

  const expirationPivot = useCallback(() => {
    return (
      <Stack horizontalAlign='center' style={{ gap: 8 }}>
        <Checkbox
          label={t("Without expiration date.")}
          style={{ padding: 2 }}
          checked={withOutExpiration}
          onChange={(checked) => {
            setWithOutExpiration(checked);
            if (checked === true) setExpiresAtUtc(undefined);
          }}
        />
        {!withOutExpiration ? (
          <>
            <Field style={{ gap: 8 }}>
              <Label style={{ textAlign: "center" }}>{t("Select the expiration date")}</Label>
              <DatePicker
                style={{ width: "20vw", textAlign: "center" }}
                placeholder={t("Select a date")}
                aria-label={t("Select a date")}
                strings={getDayPickerStrings(t)}
                onSelectDate={(date) => setExpiresAtUtc(format(date))}
              />
            </Field>
            <h5>{t("Permission expires on (UTC)")}</h5>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  }, [withOutExpiration, t]);

  const reviewPivot = useCallback(() => {
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);
    return (
      <>
        {
          <>
            <Stack verticalFill horizontalAlign='stretch' style={{ padding: 40, paddingLeft: 80 }}>
              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3> {t("Roles:")}</h3>
                </Stack.Item>
                <Stack.Item>
                  {rolesId.map((rol) => (
                    <div key={rol.roleId}>
                      <Stack.Item>
                        <b>{rol.role}</b>
                      </Stack.Item>
                    </div>
                  ))}
                </Stack.Item>
              </Stack>

              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3>{t("Scopes:")} </h3>
                </Stack.Item>
                <Stack.Item>
                  {filteredItems?.map((ele) => (
                    <div key={ele.scopeResourceId}>
                      <Stack.Item>
                        <b>{ele.scope}</b>: {ele.name}.
                      </Stack.Item>
                    </div>
                  ))}
                </Stack.Item>
              </Stack>

              <Stack horizontal>
                <Stack.Item style={{ width: "20vw" }}>
                  <h3> {t("Expires:")} </h3>
                </Stack.Item>
                <Stack.Item>{expiresAtUtc ? "True" : "False"}</Stack.Item>
              </Stack>
              {expiresAtUtc ? (
                <Stack horizontal>
                  <Stack.Item style={{ width: "20vw" }}>
                    <h3> {t("Expires on:")} </h3>
                  </Stack.Item>
                  <Stack.Item>{expiresAtUtc}</Stack.Item>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </>
        }
      </>
    );
  }, [controlledItems, expiresAtUtc, rolesId, t]);

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    !persona.root ? "scope" : "expiration",
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{ width: DialogSize.L }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Add Role")}</BaseDialogTitle>

      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("Pivots to add permission")}
          onTabSelect={onTabSelect}
        >
          {!persona.root && (
            <Tab value='scope'>
              <div>
                {rolesId && controlledItems?.filter((ele) => ele.check === true).length > 0 ? (
                  <>
                    <SkypeCheckIcon />{" "}
                    {t("Scope [{{scopeName}}]", {
                      scopeName: controlledItems
                        ?.filter((item) => item.check === true)
                        .length.toString(),
                    })}
                  </>
                ) : (
                  <>{t("Scope")}</>
                )}
              </div>
            </Tab>
          )}
          <Tab value='expiration'>
            <div>
              {rolesId &&
              controlledItems?.filter((ele) => ele.check === true).length > 0 &&
              (expiresAtUtc || withOutExpiration) ? (
                <>
                  <SkypeCheckIcon /> {t("Expiration")}
                </>
              ) : (
                <> {t("Expiration")}</>
              )}
            </div>
          </Tab>
          <Tab value='review'>{t("Review")}</Tab>
        </TabList>
        {selectedValue === "scope" && (
          <div
            style={{
              height: "60vh",
              position: "relative",
              overflowY: "auto",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {scopePivot()}
          </div>
        )}
        {selectedValue === "expiration" && (
          <div
            style={{
              height: "60vh",
              position: "relative",
              overflowY: "auto",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack verticalFill verticalAlign='center' style={{ height: "50vh" }}>
              {expirationPivot()}
            </Stack>
          </div>
        )}
        {selectedValue === "review" && rolesId && (
          <div
            style={{
              height: "60vh",
              position: "relative",
              overflowY: "auto",
              overscrollBehavior: "contain",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {reviewPivot()}
          </div>
        )}
        <DialogActions>
          <Button
            appearance='primary'
            disabled={!(rolesId && controlledItems?.filter((ele) => ele.check === true).length > 0)}
            onClick={handleAdd}
          >
            {t("Add Role")}
          </Button>
          <Button onClick={handleClose}>{t("Close")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
