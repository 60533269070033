import { useTranslation } from "react-i18next";

import ChoiceGroup from "../../../../../../Components/common/ChoiceGroup";
import useLayoutSettingsStore from "../../useLayoutSettingsStore";

const getOptions = (t) => [
  { key: "normal", text: t("Normal") },
  { key: "syncAll", text: t("Sync all left Y-Axes") },
];

export default function SyncSettings() {
  const { t } = useTranslation();
  const { updateStore, syncSettingsMode, resetSettings } = useLayoutSettingsStore((store: any) => ({
    updateStore: store.updateStore,
    syncSettingsMode: store.syncSettingsMode,
    resetSettings: store.resetSettings,
  }));

  function onChoiceChange(ev, option) {
    resetSettings();
    updateStore({ syncSettingsMode: option?.value });
  }

  return (
    <ChoiceGroup
      style={{ flexDirection: "row" }}
      options={getOptions(t)}
      defaultValue={syncSettingsMode}
      onChange={onChoiceChange}
    />
  );
}
