import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Divider,
  DrawerBody,
  DrawerFooter,
  InlineDrawer,
  makeStyles,
  Spinner,
} from "@fluentui/react-components";
import { Home20Regular } from "@fluentui/react-icons";
import {
  ChatBotIcon,
  ContactIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
  PermissionsIcon,
  RobotIcon,
} from "@fluentui/react-icons-mdl2";
import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import type { IAuthorizationResult } from "../../Hooks";
import { useAppDispatch, useAppSelector, useAuthorization, useNetwork } from "../../Hooks";
import { Status } from "../../schema/status";
import { notification } from "../common/Notification";
import Icon from "../CustomIcons";
import { listAsyncDashB, selectDashboardsStatus, toShowLoad } from "../DashboardsMetadata/reducer";
import { listAsyncProjs, selectProjects, selectProjectsStatus } from "../Projects/reducer";
import { Stack } from "../Stack";
import type { ResponseUserGet } from "../UsersRBCA/models";

export const isClickedAccountIconContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const isClickedUpdateUserContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export const isClickedUpdateProfilePhotoContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export interface localAccountInfoProps {
  val: ResponseUserGet | undefined;
  set: React.Dispatch<React.SetStateAction<ResponseUserGet | undefined>>;
}

export const localAccountInfo = createContext<localAccountInfoProps | undefined>(undefined);

interface navLinkGroupDes {
  navLinkGroup: any;
  canI: boolean | undefined;
  name: string;
}

export const authContext = createContext<IAuthorizationResult | undefined>(undefined);

const useStyle = makeStyles({
  button: {
    width: "100%",
    maxWidth: "100%",
    borderRadius: "0px",
    background: "white",
    justifyContent: "flex-start",
    alignItems: "center",

    "&:hover": {
      background: "#FAF9F8",
      borderLeft: "3px solid #2B53A0",
    },

    "& .fui-Button__icon": {
      fontSize: "24px",
      width: "32px",
      height: "32px",
    },
  },
  buttonIcon: {
    fontSize: "18px",
    // width: "32px",
    // height: "32px",
  },
  buttonWaffle: {
    width: "100%",
    maxWidth: "100%",
    borderRadius: "0px",
    justifyContent: "flex-start",

    "&:hover": {
      background: "#FAF9F8",
    },
  },
});

export const LeftMenuAlt: React.FunctionComponent<React.PropsWithChildren<unknown>> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const auth = useAuthorization();
  const dashboardStatus = useAppSelector(selectDashboardsStatus);
  const [isWaffle, setIsWaffle] = useState(false);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);

  const location = useLocation();
  const amIOnline = useNetwork();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (amIOnline && !auth.loading) notification.success(t("We are back!"));
    else if (!amIOnline)
      notification.warning(t("We are offline, please check your internet connection."));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amIOnline]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        //context?.setValue(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    if (projectStatus === Status.void) dispatch(listAsyncProjs());
    if (dashboardStatus === Status.void && projectStatus === Status.idle)
      dispatch(listAsyncDashB());
    if (projectStatus === Status.idle && dashboardStatus === Status.idle) {
      dispatch(toShowLoad(projs));
    }
  }, [dashboardStatus, dispatch, projectStatus, projs, isAuthenticated]);

  const waffleGroup = [
    {
      name: "waffleGroup",
      links: [
        {
          name: "",
          url: "./",
          icon: isWaffle ? <DoubleChevronLeftIcon /> : <DoubleChevronRightIcon />,
          key: "waffle",
          title: isWaffle ? t("Show less information") : t("Show more information"),
        },
      ],
    },
  ];

  const homeGroup = [
    {
      name: "homeGroup",
      links: [
        {
          name: t("Home"),
          url: "/",
          icon: <Home20Regular />,
        },
      ],
    },
  ];

  const assistantGroup = [
    {
      name: "assistantGroup",
      links: [
        {
          name: t("Assistant"),
          url: "/assistant",
          icon: <ChatBotIcon style={{ fontSize: 20 }} />,
        },
      ],
    },
  ];

  const machineCvGroup = [
    {
      name: "machineCvGroup",
      links: [
        {
          name: t("MachineCV"),
          url: "/machine-cv",
          icon: <Icon name='MachineCV' className={classes.buttonIcon} />,
        },
      ],
    },
  ];

  const analysisGroup = [
    {
      name: "analysisGroup",
      links: [
        {
          name: t("Trend View"),
          url: "/trend-view",
          icon: <Icon name='TrendView' className={classes.buttonIcon} />,
        },
        {
          name: t("Raw Data"),
          url: "/raw-data",
          icon: <Icon name='RawView' className={classes.buttonIcon} />,
        },
      ],
    },
  ];

  const wirelessGroup = [
    {
      name: "wirelessGroup",
      links: [
        {
          name: t("Gateways"),
          url: "/gateways",
          icon: <Icon name='Gateways' className={classes.buttonIcon} />,
        },
        {
          name: t("Sensor Nodes"),
          url: "/sensorNode",
          icon: <Icon name='SensorsNodes' className={classes.buttonIcon} />,
        },
        {
          name: t("Sensor Configurator"),
          url: "/sensor-configurator",
          icon: <Icon name='SensorConfiguration' className={classes.buttonIcon} />,
        },
      ],
    },
  ];

  const metaDataGroup: any = [
    {
      name: "metaDataGroup",
      links: [
        {
          name: t("Corporations"),
          url: "/corporations",
          icon: <Icon name='Corporations' className={classes.buttonIcon} />,
        },
        {
          name: t("Companies"),
          url: "/companies",
          icon: <Icon name='Companies' className={classes.buttonIcon} />,
        },
        {
          name: t("Projects"),
          url: "/projects",
          icon: <Icon name='Projects' className={classes.buttonIcon} />,
        },
        {
          name: t("Machines"),
          url: "/machines",
          icon: <Icon name='Machines' className={classes.buttonIcon} />,
        },
      ],
    },
  ];

  auth.imageAdministrator &&
    metaDataGroup.at(0).links.push({
      name: t("Images"),
      url: "/images",
      icon: <Icon name='Images' className={classes.buttonIcon} />,
      title: t("Images"),
    });

  metaDataGroup.at(0).links.push({
    name: t("Dataloggers"),
    url: "/dataloggers",
    icon: <Icon name='Datalogger' className={classes.buttonIcon} />,
    title: t("Dataloggers"),
  });

  auth.powerBiContributor &&
    metaDataGroup.at(0).links.push({
      name: t("DBoard Management"),
      url: "/dashboardsMetaData",
      icon: <Icon name='DashBoardsManagement' className={classes.buttonIcon} />,
      title: t("Dashboards Management"),
    });

  auth.vpnReader &&
    metaDataGroup.at(0).links.push({
      name: t("Vpn Connections"),
      url: "/vpnConnections",
      icon: <Icon name='VpnIcon' width='18' height='18' className={classes.buttonIcon} />,
      title: t("Vpn Connections"),
    });

  auth.submissionAdministrator &&
    metaDataGroup.at(0).links.push({
      name: t("Submissions"),
      url: "/submissions",
      icon: <Icon name='ApprovalSubmissions' className={classes.buttonIcon} />,
      title: t("Submissions"),
    });

  const rBACGroup = [
    {
      name: "rBACGroup",
      links: [
        {
          name: t("Permissions"),
          url: "/permissions",
          icon: <PermissionsIcon className={classes.buttonIcon} />,
        },
        {
          name: t("Service Accounts"),
          url: "/services",
          icon: <RobotIcon className={classes.buttonIcon} />,
        },
        {
          name: t("Users"),
          url: "/users",
          icon: <ContactIcon className={classes.buttonIcon} />,
        },
      ],
    },
  ];

  const allLinksToSelect: navLinkGroupDes[] = [
    { name: t("Home Group"), canI: true, navLinkGroup: homeGroup },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("Assistant Group"),
      canI: auth.userAdministrator,
      navLinkGroup: assistantGroup,
    },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("MachineCv Group"),
      canI: auth.machineCvReader,
      navLinkGroup: machineCvGroup,
    },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("Analysis Group"),
      canI: auth.measuredDataReader,
      navLinkGroup: analysisGroup,
    },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("Wireless Group"),
      canI: auth.wirelessSensorReader,
      navLinkGroup: wirelessGroup,
    },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("MetaData Group"),
      canI: auth.metaDataContributor,
      navLinkGroup: metaDataGroup,
    },
    { name: t("Separator"), canI: undefined, navLinkGroup: undefined },
    {
      name: t("RBAC Group"),
      canI: auth.userAdministrator,
      navLinkGroup: rBACGroup,
    },
  ];

  const handleToggleWaffle = () => {
    setIsWaffle((prev) => !prev);
  };

  return (
    <>
      {!auth.loading ? (
        <Stack
          horizontal
          style={{
            position: "fixed",
            top: 50,
            left: 0,
            height: "100vh",
            zIndex: 999,
            width: "100%",
          }}
        >
          <InlineDrawer
            separator
            open
            style={{ height: "calc(100vh - 50px)", width: isWaffle ? 300 : 60 }}
          >
            <DrawerBody
              tabIndex={0}
              role='group'
              aria-label='Example scrolling content'
              style={{ paddingLeft: "0px", paddingTop: "0px", paddingRight: "0px" }}
            >
              <Stack>
                <Stack>
                  {allLinksToSelect
                    .filter((linkOb) => linkOb.canI === true)
                    .map((linkGroup, index) =>
                      linkGroup.navLinkGroup ? (
                        <React.Fragment key={linkGroup.navLinkGroup[0]?.name}>
                          {linkGroup.navLinkGroup[0].links.map((linkItem) => (
                            <Stack.Item key={linkItem.name}>
                              <Link to={linkItem.url}>
                                <Button
                                  appearance='transparent'
                                  icon={linkItem.icon}
                                  className={classes.button}
                                  style={
                                    linkItem.url === `/${location.pathname.split("/")[1]}`
                                      ? { background: "#FAF9F8", borderLeft: "3px solid #2B53A0" }
                                      : {}
                                  }
                                >
                                  {isWaffle && linkItem.name}
                                </Button>
                              </Link>
                            </Stack.Item>
                          ))}
                          <Divider style={{ flexGrow: 0 }} />
                        </React.Fragment>
                      ) : (
                        <Divider key={index} />
                      ),
                    )}
                </Stack>
              </Stack>
            </DrawerBody>

            <DrawerFooter style={{ padding: "0px" }}>
              <Button
                appearance='transparent'
                icon={waffleGroup[0].links[0].icon}
                className={classes.buttonWaffle}
                style={{ padding: "8px 15px 8px 15px" }}
                onClick={handleToggleWaffle}
              />
            </DrawerFooter>
          </InlineDrawer>

          <authContext.Provider value={{ ...auth }}>{props.children}</authContext.Provider>
        </Stack>
      ) : (
        <Spinner label={t("Loading data...")} />
      )}
    </>
  );
};
