import {
  Button,
  DialogActions,
  DialogContent,
  Input,
  Spinner,
  Text,
} from "@fluentui/react-components";
import { useContext, useId, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import { VpnPCsAPI } from "../../Schema/api";
import type { MetadataVpnPC } from "../../Schema/models";
import type { AddEditDialogProps, BasicDialogProps } from "../../Schema/viewModels";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { FormItemRow } from "../generic/FormDialogComponents";

type EditVpnPcDialogProps = BasicDialogProps & {
  item: MetadataVpnPC;
};

/**
 * Gets the Add VPN PC dialog component.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add VPN PC dialog component.
 */
export const AddVpnPcDialog = ({ open, onClose }: BasicDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Method called when the submit button is clicked.
  const onSubmitHandler = (data: MetadataVpnPC) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.create(axiosInstance, data).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          t(`Failure creating a VPN PC: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success creating a VPN PC."));
      onClose?.(true);
    });
  };

  return (
    <AddEditVpnPcDialog
      open={open}
      isLoading={isLoading}
      onSubmit={onSubmitHandler}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Edit VPN PC dialog component.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Edit VPN PC dialog component.
 */
export const EditVpnPcDialog = ({ open, item, onClose }: EditVpnPcDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Method called when the submit button is clicked.
  const onSubmitHandler = (data: MetadataVpnPC) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.update(axiosInstance, data).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure updating a VPN PC: ${response.statusText}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success updating a VPN PC."));
      onClose?.(true);
    });
  };

  return (
    <AddEditVpnPcDialog
      open={open}
      item={item}
      isLoading={isLoading}
      onSubmit={onSubmitHandler}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Add Edit VPN PC dialog component.
 * @param item The VPN PC item.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onSubmit The method called when the submit button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add Edit VPN PC dialog component.
 */
const AddEditVpnPcDialog = ({
  open = false,
  item,
  isLoading,
  onSubmit,
  onClose,
}: AddEditDialogProps<MetadataVpnPC>) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const [pcName, setPcName] = useState<string>(item ? item.name : "");
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const errorMessageId = useId();
  const hasError = dataHasChanged && pcName === "";

  const onSubmitHandler = () => {
    let newItem: MetadataVpnPC = { name: pcName.trim() };
    if (item) {
      newItem = { ...newItem, id: item.id };
    }

    onSubmit?.(newItem);
  };

  return (
    <BaseDialog open={open} surfaceStyle={{ maxWidth: DialogSize.S }} onOpenChange={onClose}>
      <BaseDialogTitle>{item ? t("Edit VPN PC") : t("Add VPN PC")}</BaseDialogTitle>

      <DialogContent>
        <FormItemRow label={t("VPN PC Name *")}>
          <Input
            style={{ width: "100%", ...(hasError && { borderColor: "#a4262c" }) }}
            aria-describedby={errorMessageId}
            value={pcName}
            onChange={(_, data) => {
              if (data?.value.length > 0) {
                setIsValid(true);
                setDataHasChanged(true);
              }

              setPcName(data?.value || "");
            }}
          />

          <Text
            style={{ display: "block", marginTop: 5, fontSize: 12, color: "#a4262c" }}
            id={errorMessageId}
          >
            {hasError ? t("This field is required.") : ""}
          </Text>
        </FormItemRow>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || !isValid || hasError}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onSubmitHandler}
          >
            {t("Save Changes")}
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
