import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { MoreVerticalRegular } from "@fluentui/react-icons";
import classNames from "classnames";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useLocationSearch,
  useMachineUsersQuery,
  useWebAnalysisPermissions,
} from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import type { MachineCVComment } from "../../../../../types";
import { Stack } from "../../../../Stack";
import { useCommentsQuery } from "../../hooks/useCommentsQuery";
import useTagsStore from "../../hooks/useTagsStore";
import { Tag } from "../Tags/Tag";
import { CommentDialog } from "./CommentDialog";
import Files from "./Files";

const PostedAt = ({ date }: { date: string }) => {
  const { t } = useTranslation();
  const daysDiff = dayjs().diff(date, "days");
  const hoursAndMinutes = dayjs(date).format("HH:mm");

  if (!daysDiff) {
    return <>{t(`today at {{hoursAndMinutes}}`, { hoursAndMinutes })}</>;
  }

  if (daysDiff === 1) {
    return <>{t(`yesterday at {{hoursAndMinutes}}`, { hoursAndMinutes })}</>;
  }

  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  return (
    <Stack horizontal className='date-posted'>
      <div>{formattedDate}</div>
      <div>{hoursAndMinutes}</div>
    </Stack>
  );
};

type MachineCVCommentProps = {
  comment: MachineCVComment;
};

export const Comment = ({ comment }: MachineCVCommentProps) => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();
  const { refetch } = useCommentsQuery({ machineId: id as string });
  const { usersList, usersPersonas } = useMachineUsersQuery({
    machineId: id as string,
  });
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { useReadyPermission, checkCreatorUserIDMatch } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const { addSelectedTag } = useTagsStore((store) => ({
    addSelectedTag: store.addSelectedTag,
  }));

  const isSameUser = checkCreatorUserIDMatch(comment?.createdById || "");

  const hasEditDeleteActions = hasMachineCvAdministratorPermission || isSameUser;

  const user = useMemo(() => {
    const userPersona = usersPersonas.find((user) => user.text === comment.createdBy);

    return {
      text: userPersona?.text || comment.createdBy,
      createdAt: comment.createdAt,
    };
  }, [usersPersonas]);

  const isDalogMember = usersList.find(
    ({ email, displayName }) => user?.text === displayName && email.includes("@dalog.net"),
  );

  const actionOptions = [
    // Temporary removed Reply functionality
    // {
    //   key: 'reply',
    //   text: t('Reply'),
    //   onClick: () => setShowReplyDialog(true),
    // },
  ];

  if (hasEditDeleteActions) {
    actionOptions.push(
      {
        key: "edit",
        text: t("Edit"),
        onClick: () => setShowCommentsDialog(true),
      },
      {
        key: "delete",
        text: t("Delete"),
        onClick: () => setShowDeleteDialog(true),
      },
    );
  }

  return (
    <>
      <Stack
        className={classNames("comment", {
          reverse: !!isDalogMember,
        })}
      >
        <Stack horizontal verticalAlign='center' className='avatar-details'>
          {isDalogMember ? <div className='dalog-tag'>DALOG</div> : null}

          <div>{user?.text}</div>
          <div>
            <PostedAt date={comment.createdAt} />
          </div>
        </Stack>

        <Stack horizontal reversed={!isDalogMember} className='comment-content'>
          <Avatar name={user?.text ?? ""} color='colorful' />
          <Stack horizontal className='comment-text-wrap'>
            <Stack className='comment-text'>
              {comment.tags.length > 0 ? (
                <div className='tags-comment-container'>
                  {comment.tags.map((tag: any, idx) => (
                    <Tag key={idx} name={tag.name} id={tag.id} onTagClick={addSelectedTag} />
                  ))}
                </div>
              ) : null}

              {comment.text}

              {comment.files.length !== 0 && <Files files={comment.files} />}
            </Stack>

            {actionOptions?.length > 0 ? (
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button
                    style={{ minWidth: 0, height: "fit-content" }}
                    appearance='transparent'
                    icon={<MoreVerticalRegular />}
                  />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    {actionOptions.map((item) => (
                      <MenuItem key={item.key} onClick={item.onClick}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </MenuList>
                </MenuPopover>
              </Menu>
            ) : null}
          </Stack>
        </Stack>
      </Stack>

      <CommentDialog
        open={showCommentsDialog}
        comment={comment}
        onDismiss={() => setShowCommentsDialog(false)}
      />
      <CommentDialog
        isReply={true}
        comment={{
          ...comment,
          text: t(`Reply to:\n{{commentText}}`, { commentText: comment.text }),
        }}
        open={showReplyDialog}
        onDismiss={() => setShowReplyDialog(false)}
      />
      <DeleteDialog
        data={{
          ids: [comment.id],
          name: "1 comment",
        }}
        open={showDeleteDialog}
        route={`/meta/machinecv/v1/comments`}
        onDismiss={() => setShowDeleteDialog(false)}
        onSuccess={refetch}
      />
    </>
  );
};
