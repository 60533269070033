import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export interface AlertsStore {
  alertsOptions: Record<string, string[]>;
  updateAlertsOptions: (signalId: string, alertsOptions: string[]) => void;
  getSignalAlertsOptions: (signalId: string) => string[];
}

const useAlertsStore = createWithEqualityFn<AlertsStore>(
  (set, get) => ({
    alertsOptions: {},
    updateAlertsOptions: (signalId: string, alertOptions: string[]) => {
      set((state) => ({ alertsOptions: { ...state.alertsOptions, [signalId]: alertOptions } }));
    },
    getSignalAlertsOptions: (signalId: string) => get().alertsOptions[signalId] || [],
  }),
  shallow,
);

export default useAlertsStore;
