import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ZodSchema } from "zod";
import { z } from "zod";

import { formatFileSize } from "../../schema/Utils";
import BaseDialog from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import type { Column } from "../common/Table/v9";
import type { TableProps } from "../common/Table/v9/Table";
import Table from "../common/Table/v9/Table";
import Uploader from "../common/Uploader";
import type { AcceptedFile } from "../common/Uploader/Uploader";
import type { CreateFile } from "../FIles/models";

const defaultFields: FormItemProps[] = [
  {
    name: "description",
    type: FormItemType.TextField,
    groupProps: { label: t("Description") },
  },
];

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumns = ({ onRemove, t }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      return (
        <Link href='#' style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}>
          {isValid ? formatFileSize(size) : t("Too large")}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 68,
    onRender: ({ id }) => (
      <Tooltip relationship='label' content={t("Delete")}>
        <Button
          icon={<DeleteRegular />}
          aria-label={t("Delete")}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(id);
          }}
        />
      </Tooltip>
    ),
  },
];

const defaultSchema = z.object({
  description: z
    .string()
    .max(200, {
      message: t("Description must contain at most 200 character(s)"),
    })
    .optional(),
});

type DocumentDialogFieldsSchema = {
  schema: ZodSchema;
  fields: FormItemProps[];
};

type AddDocumentDialogProps = {
  hidden: boolean;
  onClose: () => void;
  onSubmit: (files: CreateFile[]) => void;
  formFields?: DocumentDialogFieldsSchema;
  accept: string;
};

const AddDocumentDialog = ({
  hidden,
  onClose,
  onSubmit,
  formFields = { fields: defaultFields, schema: defaultSchema },
  accept,
}: AddDocumentDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<AcceptedFile[]>([]);
  const { fields, schema } = formFields;

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: localFiles,
      perPage: 5,
      hidePerPage: true,
      v8Columns: getColumns({
        t,
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [localFiles, t],
  );

  useEffect(() => {
    if (hidden) {
      reset();
      setLocalFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
  });

  const onFormSubmit = handleSubmit(async (data: any) => {
    const files = localFiles.map((file) => ({ ...file, ...data }));
    await onSubmit(files);
    onClose();
  });

  const isDisabled = useMemo(
    () => isSubmitting || !isValid || localFiles.filter(({ isValid }) => isValid).length === 0,
    [isSubmitting, isValid, localFiles],
  );

  const onUpload = (accepted: AcceptedFile[]) => {
    setLocalFiles((prev: AcceptedFile[]) => [...accepted, ...prev]);
  };

  return (
    <BaseDialog open={!hidden}>
      <DialogTitle>{t("Add new document")}</DialogTitle>
      <DialogContent>
        <form onSubmit={onFormSubmit}>
          {renderFormItems(fields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <Uploader accept={accept} onChange={onUpload} />
          <Table {...tableProps} />
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isDisabled}
              icon={isSubmitting ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Upload")}
            </Button>
            <Button onClick={onClose}>{t("Cancel")}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddDocumentDialog;
