import { differenceInDays } from "date-fns";
import { BookmarkType } from "../../../modules/common/components/Bookmarks";
import { SHORT_FORMAT } from "../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { format } from "date-fns";

export const MACHINE_CONDITION_FILTERS = ["Faulty"];

const now = new Date();

function getLastDataUpdate(trendDataUntil) {
  const timeDifference = differenceInDays(now, new Date(trendDataUntil));

  let lastDataUpdate = "Late";
  if (timeDifference <= 4) lastDataUpdate = "Up to date";
  if (timeDifference > 4 && timeDifference <= 14) lastDataUpdate = "At risk";

  return lastDataUpdate;
}

export function getMachines(data) {
  return (data || [])
    .flatMap(({ companies }) =>
      companies.flatMap(({ projects }) =>
        projects.flatMap(({ metaData, machines }) => {
          return machines.map((machine, index) => {
            const coordinatesAddFactor = index / 10000;

            const lastDataUpdate = getLastDataUpdate(
              machine.metaData.trendDataUntil
            );

            const trendDataUntil = format(
              new Date(machine.metaData.trendDataUntil),
              SHORT_FORMAT
            );

            return {
              uniqId: machine?.metaData?.dalogId + "-" + metaData.id,
              type: BookmarkType.Machine,
              isMachine: true,
              continent: metaData?.continent,
              country: metaData?.country,
              coordinates: [
                metaData?.longitude + coordinatesAddFactor,
                metaData?.latitude + coordinatesAddFactor,
              ],
              machineId: machine?.metaData?.id,
              condition: machine?.condition,
              projectName: metaData?.name,
              projectId: metaData?.id,
              title: machine?.metaData?.name,
              lastDataUpdate,
              trendDataUntil,
            };
          });
        })
      )
    )
    .filter(({ condition }) => !MACHINE_CONDITION_FILTERS.includes(condition));
}
