import { makeStyles, mergeClasses, Overflow, OverflowItem } from "@fluentui/react-components";

import OverflowMenu from "./OverflowMenu";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
    height: "44px",
    minWidth: 0,
    overflow: "hidden",
    width: "100%",
  },
});

export const CommandBar = ({ items, innerFarItems, wrapperStyle = {} }) => {
  const styles = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        overflow: "auto",
        ...wrapperStyle,
      }}
    >
      <Overflow>
        <div className={mergeClasses(styles.container)}>
          {items.map(({ key, onRender }) => (
            <OverflowItem key={key} id={key}>
              <div style={{ minWidth: "max-content" }}>{onRender()}</div>
            </OverflowItem>
          ))}
          <OverflowMenu items={items} />
        </div>
      </Overflow>
      {innerFarItems.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "max-content",
          }}
        >
          {innerFarItems.map((item) => (
            <div key={item.key}>{item.onRender()}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
