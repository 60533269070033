import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import { RefreshIcon, SettingsIcon } from "@fluentui/react-icons-mdl2";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import SchemeIcon from "../../../../assets/svg/SchemeIcon";
import BaseCommandBar, { CommandBarItemType } from "../../../../Components/common/CommandBar";
import { computeCommandBarItems } from "../../../../Components/common/CommandBar/methods";
import { Stack } from "../../../../Components/Stack";
import useChartLabels from "../../hooks/useChartLabels";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useWaterfallStore from "../../hooks/useWaterfallStore";
import { buttonsContainer, exportElement, headerTitle } from "../../styles/ChartSection.styles";
import type { WaterfallChartMethods } from "../../types";
import { renderCustomLabel } from "../HeaderControls/HeaderControls";
import type { IColorScale } from "../WaterfallChart/config";
import { getColorScaleOptions } from "../WaterfallChart/config";

type ChartSectionHeaderProps = {
  id: string;
  title: string;
  isSpectrum?: boolean;
  items?: any | undefined;

  timestamp?: string;
  waterfallMethods?: WaterfallChartMethods;
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
  maximizeAction?: () => void;
};

const useStyles = makeStyles({
  divider: {
    alignItems: "flex-start",
    height: "25px",
    marginLeft: "10px",

    "&:before": {
      "border-color": "#E1DFDD",
      "border-width": "1px",
    },

    "&:after": {
      "border-color": "#E1DFDD",
      "border-width": "1px",
    },
  },
});

const ChartSectionHeader = ({
  id,
  title,
  isSpectrum,
  items,
  timestamp,
  waterfallMethods,
  maximizeIcon,
  maximizeLabel,
  maximizeAction,
}: ChartSectionHeaderProps) => {
  const classes = useStyles();

  const shouldShowChartLabels = useChartLabels();

  const { t } = useTranslation();

  const { isWaterfallLayout } = useLayoutTypes();

  const formattedTimestamp = timestamp ? format(new Date(timestamp), "dd MMM yyyy HH:mm") : "";

  const { interpolation, colorScale, plotType, setInterpolation, setColorScale, setPlotType } =
    useWaterfallStore((state) => ({
      interpolation: state.interpolation,
      colorScale: state.colorScale,
      plotType: state.plotType,
      setInterpolation: state.setInterpolation,
      setColorScale: state.setColorScale,
      setPlotType: state.setPlotType,
    }));

  const getInterpolationOptions = (t) => [
    {
      key: "none",
      text: t("None"),
    },
    {
      key: "best",
      text: t("Best"),
    },
  ];

  const commandBarItems: any = [
    {
      key: "reset",
      text: t("Reset"),
      type: CommandBarItemType.Button,
      icon: <RefreshIcon />,
      style: { marginLeft: 16, height: 36 },
      onClick: () => waterfallMethods?.resetPlot(),
    },
    {
      key: "save",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button appearance='subtle' icon={<SaveRegular />}>
                {t("Save as")}
              </Button>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem>
                  <Button
                    appearance='transparent'
                    onClick={() =>
                      waterfallMethods?.savePlotAs({
                        value: "png",
                      })
                    }
                  >
                    PNG
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    appearance='transparent'
                    onClick={() =>
                      waterfallMethods?.savePlotAs({
                        value: "jpeg",
                      })
                    }
                  >
                    JPG
                  </Button>
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        );
      },
    },
    {
      key: "plot_type",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: plotType,
        options: [
          {
            key: "heatmap",
            text: t("Heatmap"),
          },
          // {
          //   key: "contour",
          //   text: "Contour",
          // },
          // {
          //   key: "surface",
          //   text: "3D",
          // },
        ],
        onChange: (_: never, item: { data: any }) =>
          setPlotType(item.data as "heatmap" | "contour" | "surface"),
        onRenderLabelProps: () =>
          renderCustomLabel({
            text: t("Chart view"),
            icon: LayoutIcon,
            disabled: false,
          }),
      },
    },
    {
      key: "reduction",
      text: t("Reduction"),
      type: CommandBarItemType.Button,
      icon: <SettingsIcon />,
      style: { marginLeft: 0, marginRight: 10, height: 36 },
      onClick: () => waterfallMethods?.toggleReductionDialog(),
    },
    {
      key: "interpolation",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: interpolation,
        options: getInterpolationOptions(t),
        onChange: (_: never, { data }) => {
          setInterpolation(data as string);
        },
        onRenderLabel: () =>
          renderCustomLabel({
            text: t("Interpolation"),
            icon: LayoutIcon,
            disabled: false,
          }),
      },
    },
    {
      key: "scheme",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: colorScale.key,
        options: getColorScaleOptions(t),
        onChange: (_: never, { data }) => {
          const colorScale = getColorScaleOptions(t).find(({ key }) => data === key);
          setColorScale(colorScale as IColorScale);
        },
        onRenderLabel: () =>
          renderCustomLabel({
            text: t("Scheme"),
            icon: SchemeIcon,
            disabled: false,
          }),
      },
    },
  ];

  return (
    <Stack horizontal verticalAlign='center' horizontalAlign='space-between'>
      <Stack horizontal verticalAlign='center' style={{ width: "50%" }}>
        <Text size={400} style={headerTitle}>
          {title}
          <Divider vertical className={classes.divider} />
        </Text>

        {(isSpectrum || isWaterfallLayout || items) && items && (
          <BaseCommandBar items={computeCommandBarItems(items)} />
        )}

        {isWaterfallLayout && <BaseCommandBar items={computeCommandBarItems(commandBarItems)} />}
      </Stack>
      <Stack horizontal verticalAlign='center' style={buttonsContainer}>
        <Text className='range'>{formattedTimestamp}</Text>
        <Button
          appearance='transparent'
          style={{ padding: 0, minWidth: 0 }}
          onClick={maximizeAction}
        >
          {maximizeIcon}
          {shouldShowChartLabels && <span>{maximizeLabel}</span>}
        </Button>
        <div className={id} style={exportElement} />
      </Stack>
    </Stack>
  );
};

export default ChartSectionHeader;
