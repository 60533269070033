import { Spinner } from "@fluentui/react-components";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "../../../../Components/Stack";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { format } from "../../../common/utils/signalFormatter";
import useMachineMetaData from "../../hooks/useMachineMetaData";

const plotContainerStyles = {
  height: 575,
  background: "#FFF",
  borderRadius: 2,
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.13)",
  marginBottom: "1rem",
};

export const TrendViewPlotContainer = ({ className, children, ...rest }: any) => (
  <Stack
    {...rest}
    verticalAlign='center'
    horizontalAlign='center'
    className={classNames("trend-view-plot-container", className)}
    style={{ ...plotContainerStyles, ...rest?.style }}
  >
    {children}
  </Stack>
);

type TrendViewPlotSpinnerProps = any & {
  text?: string;
};

export const TrendViewPlotSpinner = ({ text, ...rest }: TrendViewPlotSpinnerProps) => {
  const { t } = useTranslation();

  return (
    <TrendViewPlotContainer {...rest} className={"trend-view-plot-container-loading"}>
      <Spinner size='small' label={text ?? t("Loading trend view chart...")} />
    </TrendViewPlotContainer>
  );
};

export const TrendViewPlotNoData = ({ signal }: { signal: ResponseSimplifiedSignal }) => {
  const formattedSignal = format(signal);

  return (
    <TrendViewPlotContainer className='trend-view-plot-container-no-data'>
      <span>
        <Trans i18nKey={"<0>{{formattedSignal}}</0> has no data"} values={{ formattedSignal }}>
          <b>{formattedSignal}</b> has no data
        </Trans>
      </span>
    </TrendViewPlotContainer>
  );
};

export const TrendViewGroupedPlotNoData = ({ machineId }: { machineId: string }) => {
  const { t } = useTranslation();

  const { corporation, company, project, machine } = useMachineMetaData(machineId);

  if (machineId === "all") {
    return (
      <TrendViewPlotContainer>
        <span>{t("One or more selected signals has no data")}</span>
      </TrendViewPlotContainer>
    );
  }

  const name = `${corporation?.name} > ${company?.name} > ${project?.name} > ${machine?.name}`;

  return (
    <TrendViewPlotContainer>
      <span>
        <Trans
          shouldUnescape={true}
          i18nKey={"One or more selected signals from <b>{{name}}</b> has no data"}
          values={{ name }}
          components={{ b: <b /> }}
        >
          One or more selected signals from <b>{name}</b> has no data
        </Trans>
      </span>
    </TrendViewPlotContainer>
  );
};
