import "./style.scss";

import { Spinner } from "@fluentui/react-components";
import { EraseToolIcon } from "@fluentui/react-icons-mdl2";
import classnames from "classnames";
import { format } from "date-fns";
import type { SetStateAction } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import HarmonicsIcon from "../../../../assets/svg/HarmonicsIcon";
import Breadcrumb from "../../../../Components/common/Breadcrumb";
import { CommandBarItemType } from "../../../../Components/common/CommandBar";
import { useMaximize, useShortTrendFFTReducedAsZip, useUniqueId } from "../../../../Hooks";
import type {
  WaterfallChartInterface,
  WaterfallChartMethods,
  WaterfallChartSectionProps,
} from "../../../analysis-raw-data/types";
import getPeriodFilters from "../../../analysis-trend-view/utils/getPeriodFilters";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";
import useHarmonicsStoreV0 from "../../hooks/useHarmonicsStoreV0";
import useHeaderControlsStore from "../../hooks/useHeaderControlsStore";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import useWaterfallStore from "../../hooks/useWaterfallStore";
import ChartSectionHeader from "../ChartSectionHeader";
import { useCatalogueStore } from "../FrequencyCatalogueDialog/hooks";
import WaterfallChart from "../WaterfallChart/WaterfallChart";

interface ShortTrendFFTReducedAsZipProps extends WaterfallChartInterface {
  isLoading: boolean;
  isError: boolean;
  progress: number;
}

const DownloadProgress = ({ progress }: { progress: number }) => {
  const { t } = useTranslation();

  return progress === 0 ? (
    <small>
      {t("Download progress: Preparing your data")} <br />
    </small>
  ) : (
    <small>
      {t("Download progress: {{progress}}%", { progress })} <br />
    </small>
  );
};

const WaterfallChartSection = ({ signal }: WaterfallChartSectionProps) => {
  const uniqueId = useUniqueId();
  const { t } = useTranslation();

  const [waterfallMethods, setWaterfallMethods] = useState({});

  const { setSelectedSignalValues } = useCatalogueStore((store: any) => ({
    setSelectedSignalValues: store.setSelectedSignalValues,
  }));

  const { reduction, yaxisStartDate, yaxisEndDate } = useWaterfallStore((state) => ({
    reduction: state.reduction,
    yaxisStartDate: state.yaxisStartDate,
    yaxisEndDate: state.yaxisEndDate,
  }));

  const { corporation, company, project, machine } = useSignalMetaData(signal);

  const { period } = useHeaderControlsStore((state) => ({
    period: state.period,
  }));

  const periodFilters = useMemo(() => getPeriodFilters(period, signal), [period?.key, signal]);

  const {
    data = [],
    isLoading,
    isError,
    progress,
  } = useShortTrendFFTReducedAsZip(
    {
      signalId: signal.id as string,
      startDateTime: periodFilters?.startDate as string,
      endDateTime: periodFilters?.endDate as string,
      reduction: reduction as number,
    },
    {
      enabled: !!reduction,
      cacheTime: 0,
    },
  ) as unknown as ShortTrendFFTReducedAsZipProps;

  const { isChartMaximized, isChartHidden, maximizeAction, maximizeIcon, maximizeLabel } =
    useMaximize({
      id: uniqueId,
      page: "waterfall",
    });

  const { updateHarmonics } = useHarmonicsStoreV0();

  const { toggleIsCatalogueOpen } = useCatalogueStore((store: any) => ({
    toggleIsCatalogueOpen: store.toggleIsCatalogueOpen,
  }));

  const items = [
    { text: `${corporation?.name}`, key: "corporation" },
    { text: `${company?.name}`, key: "company" },
    { text: `${project?.name}`, key: "project" },
    { text: `${machine?.name}`, key: "machine" },
    { text: `${signalFormatter(signal)}`, key: "signal" },
  ];

  const menuItems: any = [
    {
      key: "setharmonics",
      text: t("Freq. Cat."),
      type: CommandBarItemType.Button,
      icon: <HarmonicsIcon />,
      onClick: () => toggleIsCatalogueOpen(signal.machineId, true),
    },
    {
      key: "clearharmonics",
      type: CommandBarItemType.Button,
      icon: <EraseToolIcon />,
      onClick: () => {
        updateHarmonics(signal.machineId!, "harmonicsOne", { type: "RESET" });
        updateHarmonics(signal.machineId!, "harmonicsTwo", { type: "RESET" });
        updateHarmonics(signal.machineId!, "harmonicsThree", { type: "RESET" });
      },
    },
  ];

  useEffect(() => {
    setSelectedSignalValues(signal.id, { chartType: "Waterfall" });
  }, []);

  if (isLoading || data.length === 0)
    return (
      <div
        style={{
          height: 600,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Spinner size='small' label={t("Loading data...")} />
        <DownloadProgress progress={progress} />
      </div>
    );

  if (isError)
    return (
      <div>
        <div>{t("Something went wrong")}!</div>
      </div>
    );

  return (
    <div
      className={classnames("waveform-chart-section", {
        "raw-data-chart-section--hidden": isChartHidden,
        "raw-data-chart-section--maximized": isChartMaximized,
      })}
    >
      <ChartSectionHeader
        id={uniqueId}
        title={t("Waterfall")}
        items={menuItems}
        waterfallMethods={waterfallMethods as WaterfallChartMethods}
        {...{ maximizeAction, maximizeIcon, maximizeLabel }}
      />

      <WaterfallChart
        data={data}
        onInit={(methods: SetStateAction<WaterfallChartMethods>) => setWaterfallMethods(methods)}
      />

      <div className='waterfall-footer'>
        <Breadcrumb className='waterfall-footer__breadcrumb' items={items} />

        {yaxisStartDate && yaxisEndDate && (
          <div className='waterfall-footer__period'>
            {format(new Date(yaxisEndDate), "dd MMM yyyy HH:mm")} -{" "}
            {format(new Date(yaxisStartDate), "dd MMM yyyy HH:mm")}
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterfallChartSection;
