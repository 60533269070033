import type { SpinButtonProps } from "@fluentui/react-components";
import { Field, Label, SpinButton as FluentUiSpinButton } from "@fluentui/react-components";
import { isNumber } from "lodash-es";
import type { CSSProperties } from "react";

type CustomSpinButtonProps = Omit<SpinButtonProps, "onChange"> & {
  label?: string;
  onChange?: any;
  labelStyle?: CSSProperties;
  fieldStyle?: CSSProperties;
};

export const sideBySide: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

export default function SpinButton({
  onChange,
  label = "",
  fieldStyle,
  labelStyle,
  ...rest
}: CustomSpinButtonProps) {
  function onSpinButtonChange(_: any, data) {
    const displayValue = Number(data.displayValue);
    if (typeof data?.value === "undefined" && isNumber(displayValue)) {
      return onChange(displayValue || 0);
    }
    return onChange(data?.value || 0);
  }

  if (label) {
    return (
      <Field style={{ gap: "4px", marginTop: "8px", ...fieldStyle }}>
        <Label style={{ fontWeight: 600, ...labelStyle }}>{label}</Label>
        <FluentUiSpinButton onChange={onSpinButtonChange} {...rest} />
      </Field>
    );
  }

  return <FluentUiSpinButton onChange={onSpinButtonChange} {...rest} />;
}
