import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useMachineCVBreadcrumb } from "../../../Hooks";
import { breadcrumbDefaultResponse } from "../../../modules/common/services/api/MachineCVBreadcrumb";
import type { TaskOverview } from "../../../types";
import TasksTable from "./components/TableSection/TasksTable";
import { TaskAddDialog } from "./components/TaskAdd";
import { TaskEditDialog } from "./components/TaskEdit";
import { useTaskQuery } from "./hooks/useTaskQuery";
import useTasksStore from "./hooks/useTasksStore";

const Page = () => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();

  const { data: breadcrumdResponse = breadcrumbDefaultResponse } = useMachineCVBreadcrumb({
    machineId: id as string,
    options: { enabled: !!id, retry: 0 },
  });

  const { reload, showAdd, edit, dispatch } = useTasksStore((state) => ({
    reload: state.reload,
    showAdd: state.showAdd,
    edit: state.edit,
    dispatch: state.dispatch,
  }));

  const { items, isError, isLoading } = useTaskQuery({
    reload,
    machineId: id as string,
  });

  const itemsRegistry = useMemo(
    () =>
      items.reduce(
        (acc: { done: TaskOverview[]; others: TaskOverview[] }, curr) => {
          if (curr.status === "Done") {
            acc.done.push(curr);
          } else {
            acc.others.push(curr);
          }
          return acc;
        },
        {
          done: [],
          others: [],
        },
      ),
    [items],
  );

  return (
    <>
      <TasksTable
        items={itemsRegistry.others}
        isError={isError}
        isLoading={isLoading}
        itemsKey='others'
        tableTitle={t("All Tasks")}
      />

      <TasksTable
        items={itemsRegistry.done}
        isError={isError}
        isLoading={isLoading}
        itemsKey='done'
        tableTitle={t("Completed Tasks")}
      />

      <TaskAddDialog
        open={showAdd}
        onDismiss={() => dispatch({ type: "SHOW_ADD", payload: false })}
        onSuccess={() => dispatch({ type: "SET_RELOAD" })}
      />
      <TaskEditDialog
        open={Boolean(edit.id)}
        breadcrumb={breadcrumdResponse}
        onDismiss={() => dispatch({ type: "SET_EDIT", payload: { id: "" } })}
      />
    </>
  );
};

export default Page;
