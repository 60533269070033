import { Button } from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";

type UploaderButtonProps = {
  onClick: (e) => void;
};

const UploaderButton = ({ onClick }: UploaderButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      appearance='primary'
      style={{
        marginTop: 25,
      }}
      onClick={onClick}
    >
      {t("Add document")}
    </Button>
  );
};

export default UploaderButton;
