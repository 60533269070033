import type { ButtonProps } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

type UploadButtonProps = Omit<ButtonProps, "onClick"> & {
  accept: string;
  onFilesSelected: (files: File[]) => void;
  multiple?: boolean;
};

export const UploadDefaultButton = ({
  accept,
  onFilesSelected,
  multiple,
  ...rest
}: UploadButtonProps) => {
  const { t } = useTranslation();
  const htmlFileInputRef = useRef(null);

  // Handlers
  const onFilesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length === 0) {
      return;
    }

    onFilesSelected?.(Array.from(event.target.files));
  };

  return (
    <div>
      <Button disabled={rest?.disabled} onClick={() => htmlFileInputRef?.current.click()}>
        {t("Browse...")}
      </Button>
      <input
        ref={htmlFileInputRef}
        type='file'
        accept={accept}
        style={{ display: "none" }}
        multiple={multiple}
        onChange={onFilesChanged}
      />
    </div>
  );
};
