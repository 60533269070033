import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { SendRegular } from "@fluentui/react-icons";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextField from "../../../common/TextField";
import { Stack } from "../../../Stack";
import type { Message } from "../models";
import { MessageItem } from "./MessageItem";

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  messageList: {
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column-reverse",
    marginBottom: "20px",
  },
  inputContainer: {
    display: "flex",
    gap: "8px",
    padding: "16px",
    backgroundColor: "white",
    borderTop: "1px solid #edebe9",
  },
  spinner: {
    margin: "auto",
  },
});

interface SubmissionPreviewMessagesScreenProps {
  messages: Message[];
  creatorName: string;
  isLoading?: boolean;
  onSent: (message: string) => void;
  isSending?: boolean;
}

const ChatMessageDisplay: React.FC<SubmissionPreviewMessagesScreenProps> = ({
  messages,
  creatorName,
  isLoading,
  onSent,
  isSending,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const styles = useStyles();

  const mapMessages = (messages: Message[] | undefined) => {
    if (!messages) return [];
    return messages
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .map((message) => {
        return {
          name: message.createdBy,
          date: dayjs(message.createdAt).format("YYYY-MM-DD HH:mm"),
          message: message.text,
          role: creatorName === message.createdBy ? "Contributor" : "Administrator",
          isMe: creatorName !== message.createdBy,
        };
      });
  };

  return (
    <Stack className={styles.container}>
      <div className={styles.messageList}>
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          mapMessages(messages).map((message, index) => (
            <MessageItem key={index} message={message} />
          ))
        )}
      </div>

      <div className={styles.inputContainer}>
        <TextField
          style={{ width: "100%" }}
          value={comment}
          placeholder={t("Comment")}
          onChange={setComment}
        />
        <Button
          icon={<SendRegular />}
          disabled={isSending || !comment.trim()}
          onClick={() => {
            onSent(comment);
            setComment("");
          }}
        />
      </div>
    </Stack>
  );
};

export default ChatMessageDisplay;
