/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line jsx-a11y/click-events-have-key-events

import { Button, makeStyles } from "@fluentui/react-components";
import { CancelIcon } from "@fluentui/react-icons-mdl2";
import classNames from "classnames";
import { useState } from "react";

import { useLocationSearch } from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { useCommentsQuery } from "../../hooks/useCommentsQuery";
import { useTagsQuery } from "../../hooks/useTagsQuery";

type TagProps = {
  name: string;
  id: string;
  hasDelete?: boolean;
  onTagClick?: (tag: any) => void;
};

const useStyles = makeStyles({
  tagButton: {
    minWidth: "0px",
    padding: "0px",

    "& .fui-Button__icon": {
      height: "unset",
      width: "unset",
      marginLeft: "4px",
    },
  },
});

export const Tag = ({ name, id, hasDelete, onTagClick }: TagProps) => {
  const [{ id: machineId }, ,] = useLocationSearch();
  const { tagButton } = useStyles();

  const [isDeleteTagDialogHidden, setIsDeleteTagDialogHidden] = useState(true);
  const toggleIsDeleteTagDialogHidden = () => setIsDeleteTagDialogHidden(!isDeleteTagDialogHidden);
  const { refetch } = useTagsQuery({ machineId: machineId as string });
  const { refetch: refetchComments } = useCommentsQuery({
    machineId: machineId as string,
  });

  const onTagNameClick = () => {
    if (!onTagClick) {
      return;
    }

    onTagClick({ key: name, name });
  };

  return (
    <div className='tag-container'>
      <span
        className={classNames("tag", {
          pointer: onTagClick,
        })}
        onClick={onTagNameClick}
      >
        {name}
      </span>
      {hasDelete && (
        <>
          <Button
            className={tagButton}
            appearance='transparent'
            icon={<CancelIcon style={{ fontSize: 12 }} />}
            onClick={toggleIsDeleteTagDialogHidden}
          />
          <DeleteDialog
            data={{ ids: [id as string], name }}
            route={"meta/machinecv/v1/tags"}
            open={!isDeleteTagDialogHidden}
            onDismiss={toggleIsDeleteTagDialogHidden}
            onSuccess={() => {
              refetch();
              refetchComments();
            }}
          />
        </>
      )}
    </div>
  );
};
