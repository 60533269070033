import "./styles.scss";

import { Button, Tooltip } from "@fluentui/react-components";
import { ArrowClockwiseRegular, ZoomInRegular, ZoomOutRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Stack } from "../../../../Stack";
import { Spin } from "../../../Spin";

export const ImagePreview = ({ file, name }) => {
  const { t } = useTranslation();

  if (!file) {
    return <Spin />;
  }

  return (
    <TransformWrapper
      centerOnInit
      initialScale={0.9}
      minScale={0.5}
      maxScale={4}
      panning={{ activationKeys: [] }}
      doubleClick={{ mode: "reset" }}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <Stack horizontal className='image-preview-action-buttons'>
            <Tooltip withArrow relationship='label' content={t("Zoom in")}>
              <Button
                appearance='transparent'
                icon={<ZoomInRegular />}
                onClick={(e) => {
                  e.stopPropagation();
                  zoomIn();
                }}
              />
            </Tooltip>
            <Tooltip withArrow relationship='label' content={t("Zoom out")}>
              <Button
                appearance='transparent'
                icon={<ZoomOutRegular />}
                onClick={(e) => {
                  e.stopPropagation();
                  zoomOut();
                }}
              />
            </Tooltip>
            <Tooltip withArrow relationship='label' content={t("Refresh")}>
              <Button
                appearance='transparent'
                icon={<ArrowClockwiseRegular />}
                onClick={(e) => {
                  e.stopPropagation();
                  resetTransform();
                }}
              />
            </Tooltip>
          </Stack>
          <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
            <img
              src={file.src}
              alt={name}
              style={{
                maxWidth: "90vw",
                maxHeight: "calc(100vh - 120px)",
                objectFit: "contain",
              }}
              draggable={false}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};
