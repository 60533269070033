import type { SearchBoxProps } from "@fluentui/react-components";
import { SearchBox, Text } from "@fluentui/react-components";
import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar from "../../common/CommandBar";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";
import { Stack } from "../../Stack";

export type SectionCommandBarItem = {
  key: string;
  onRender: () => JSX.Element;
};

type SectionContainerProps = React.HTMLAttributes<HTMLElement> & {
  title: string;
  isLoading?: boolean;
  noData?: boolean;
  commandBarItems?: SectionCommandBarItem[];
  searchBoxProps?: SearchBoxProps;
};

const SectionContainer = ({
  title,
  commandBarItems,
  isLoading,
  noData,
  searchBoxProps,
  children,
  className,
  ...rest
}: PropsWithChildren<SectionContainerProps>) => {
  const { t } = useTranslation();
  let content: React.ReactNode = children;
  if (isLoading === true) {
    content = <Spin style={{ height: "100%" }} />;
  } else if (noData === true) {
    content = <NoData />;
  }

  return (
    <section {...rest} className={classNames("summary-section-container", className)}>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center' style={{ gap: 5 }}>
        <Stack horizontal verticalAlign='center' style={{ height: "30px", width: "100%", gap: 5 }}>
          <Text key={`section-title-${title}`} size={400} as='h3' className='summary-section-title'>
            {title}
          </Text>
          {commandBarItems?.length > 0 ? <BaseCommandBar items={commandBarItems} /> : null}
        </Stack>
        {searchBoxProps && (
          <SearchBox
            {...searchBoxProps}
            className={classNames("summary-section-search-box", searchBoxProps.className)}
            placeholder={t(`Search`)}
          />
        )}
      </Stack>
      <div className='summary-section-content'>{content}</div>
    </section>
  );
};

export default SectionContainer;
