import { Switch, Text, Tooltip } from "@fluentui/react-components";
import { format as fnsFormat } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import InfoIcon from "../../../../assets/svg/InfoIcon";
import RefreshIcon from "../../../../assets/svg/RefreshIcon";
import PeriodIcon from "../../../../assets/svg/TasksIcon";
import TrendsIcon from "../../../../assets/svg/TrendsIcon";
import {
  CommandBarItemType,
  computeCommandBarItems,
  default as BaseCommandBar,
} from "../../../../Components/common/CommandBar";
import { Stack } from "../../../../Components/Stack";
import { FORMAT } from "../../../analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../common/components/CustomDateDialog";
import { getRefreshOptions } from "../../../common/constants/refreshOptions";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import {
  getPeriodOptions,
  getTrendsGroupingOptions,
  PeriodOptionsEnum,
} from "../../constants/controlOptions";
import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import useGroupingErrorMessages from "../../hooks/useGroupingErrorMessages";
import TrendViewSelectSignalsInfo from "../TrendViewSelectSignalsInfo";
import { ErrorDialog } from "./ErrorDialog";
import ReferenceSignalDropdown from "./ReferenceSignalDropdown";
import { dropdownIconStyle } from "./TrendView.styles";
import useTrendViewControls from "./useTrendViewControls";

const styles = {
  headerText: {
    fontWeight: 600,
    fontSize: "18px",
  },
};

interface TrendViewControlsProps {
  title: string;
}

export const topControlsStyles = {
  background: "#FAF9F8",
  width: "100%",
  zIndex: 9,
  minHeight: 62,
};

const TrendViewControls: React.FC<TrendViewControlsProps> = ({ title }) => {
  const { t } = useTranslation();

  const {
    controlsStore,
    selectedSignalsStore: {
      errorMessage,
      isErrorMessageOpen,
      toggleIsErrorMessageOpen,
      changeErrorMessage,
    },
  } = useTrendViewControls();

  const [visibleCustomDateDialog, setVisibleCustomDateDialog] = useState(false);

  const { getGroupByMachineErrorMessage, getGroupEverythingErrorMessage } =
    useGroupingErrorMessages();

  const handleDismiss = () => {
    toggleIsErrorMessageOpen();
  };

  const { refreshInterval, setRefreshInterval } = useRefreshStore((store) => ({
    refreshInterval: store.refreshInterval,
    setRefreshInterval: store.setRefreshInterval,
  }));

  const items: any = [
    {
      key: "trends",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: getTrendsGroupingOptions(t),
        defaultSelectedKey: controlsStore.trendsGrouping?.key || undefined,
        onChange: (_: never, { data, text }) => {
          if (data === TrendGroupingEnum.GROUP_BY_MACHINE) {
            const message = getGroupByMachineErrorMessage();
            if (message) {
              toggleIsErrorMessageOpen();
              changeErrorMessage(message);
              return;
            }
          }
          if (data === TrendGroupingEnum.GROUP_EVERYTHING) {
            const message = getGroupEverythingErrorMessage();
            if (message) {
              toggleIsErrorMessageOpen();
              changeErrorMessage(message);
              return;
            }
          }
          controlsStore.changeTrendsGrouping({ key: data, text });
        },
        onRenderLabelProps: {
          label: t("Trends"),
          icon: <TrendsIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "reference",
      type: CommandBarItemType.Custom,
      onRender: () => <ReferenceSignalDropdown />,
    },
    {
      key: "period",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: getPeriodOptions(t),
        disabled: refreshInterval?.key !== "off",
        defaultSelectedKey: controlsStore.period?.key || undefined,
        onChange: (_: never, { data, text }) => {
          if (data !== PeriodOptionsEnum.CustomDate) {
            controlsStore.changePeriod({ key: data, text });
          } else {
            setVisibleCustomDateDialog(true);
          }
        },
        onRenderLabelProps: {
          label: t("Period"),
          icon: <PeriodIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    /// Remove Live data command bar item.
    {
      key: "refresh",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: getRefreshOptions(t),
        defaultSelectedKey: refreshInterval?.key || undefined,
        onChange: (_: never, { data, text }) => setRefreshInterval({ key: data, text }),
        onRenderLabelProps: {
          label: t("Live data"),
          icon: <RefreshIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "toggle",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <>
          <Switch
            label={t("Average trend")}
            style={{ marginBottom: 0, marginRight: 10, fontWeight: 500 }}
            checked={controlsStore.averageTrend}
            onChange={(_, { checked }) => controlsStore.changeAverageTrend(checked)}
          />
        </>
      ),
    },
    {
      key: "toggle_2",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <>
          <Switch
            label={t("Connect time axis")}
            style={{ marginBottom: 0, fontWeight: 600 }}
            checked={controlsStore.isTimeAxisConnected}
            onChange={(_, { checked }) => controlsStore.changeTimeAxis(checked)}
          />
        </>
      ),
    },
  ];

  // Remove Reference command bar item
  if (controlsStore.trendsGrouping?.key !== TrendGroupingEnum.SEPARATED) {
    items.splice(1, 1);
  }

  const onCustomDateChange = ({ start, end }: any) => {
    controlsStore.changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: t(PeriodOptionsEnum.CustomDate),
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign='space-between'
        verticalAlign='center'
        style={topControlsStyles}
        id='top-controls'
      >
        <Stack horizontal className='sidebar-header'>
          <Text style={styles.headerText}>{title}</Text>
          <Tooltip
            withArrow
            content={<TrendViewSelectSignalsInfo />}
            relationship='description'
            showDelay={0}
            hideDelay={0}
            positioning='below-start'
          >
            <span style={{ cursor: "pointer", marginLeft: "8px" }}>
              <InfoIcon />
            </span>
          </Tooltip>
        </Stack>

        <BaseCommandBar wrapperStyle={{ marginLeft: 20 }} items={computeCommandBarItems(items)} />
      </Stack>
      <ErrorDialog open={isErrorMessageOpen} content={errorMessage} onDismiss={handleDismiss} />
      <CustomDateDialog
        initialValues={controlsStore.period}
        hidden={!visibleCustomDateDialog}
        setVisible={setVisibleCustomDateDialog}
        onSubmit={onCustomDateChange}
      />
    </>
  );
};

export default TrendViewControls;
