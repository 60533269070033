import type { AxiosInstance } from "axios";

import AppLayout from "../../../../templates/AppLayout";
import { File } from "./File";

interface FilePreviewTypes {
  axiosInstance: AxiosInstance;
  file: any;
}

export const FilePreview = ({ axiosInstance, file }: FilePreviewTypes) => (
  <AppLayout axiosInstance={axiosInstance}>
    <File file={file} />
  </AppLayout>
);
