import {
  Button,
  DialogActions,
  DialogContent,
  Label,
  Spinner,
  Text,
} from "@fluentui/react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import TextArea from "../../../common/TextArea";
import { VpnConnectionsAPI } from "../../Schema/api";
import type { ConnectionEntryDetailed, MetadataProject } from "../../Schema/models";
import { Utils } from "../../Utils/utils";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";

type EmailDetailsDialogProps = {
  open?: boolean;
  item: ConnectionEntryDetailed | null;
  projects: MetadataProject[];
  onClose: () => void;
};

/**
 * The email details dialog component.
 * @param entry The VPN connections table item
 * @param projects the reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns the Email details dialog component
 */
const EmailDetailsDialog = ({ open = false, item, projects, onClose }: EmailDetailsDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [currentValue, setCurrentValue] = useState<string>(item?.emailDetails || "");
  const [textFieldValue, setTextFieldValue] = useState<string>(item?.emailDetails || "");
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  // Gets the project name
  useEffect(() => {
    const project = projects.find((p) => p.id === item?.projectId);
    if (!project) {
      return;
    }

    setProjectName(project.name);
  }, [item?.projectId, projects]);

  if (!item) {
    return null;
  }

  const onEmailContactsChanged = (value) => {
    // Validates the emails
    const hasErrors = !Utils.emailsAreValid(value);
    const dataIsEqual = !Utils.emailsHaveChanged(value, currentValue);
    if (!hasErrors) {
      setDataHasChanged(!dataIsEqual);
    }

    // Sets the new emails contacts
    setTextFieldValue(value);
    setErrorMessage(hasErrors ? t("One or many email addresses are not valid.") : "");
  };

  const onSendEmailHandler = () => {
    // Saves the email information if it has changed.
    if (dataHasChanged) {
      onSaveChangesHandler(false);
    }

    window.open(
      `mailto:${textFieldValue}?subject=DALOG%20VPN-${projectName.toUpperCase()}&body=Body%20goes%20here`,
    );
  };

  const onSaveChangesHandler = (close: boolean) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    const newEntry: ConnectionEntryDetailed = {
      ...item,
      projectName: projectName,
      emailDetails: textFieldValue.trim(),
    };

    // Sends the request.
    VpnConnectionsAPI.update(axiosInstance, newEntry).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure updating VPN Connection entry: {{statusText}}.`, {
            statusText: response.statusText,
          }),
        );
        return;
      }

      notification.success(t("Success updating VPN Connection entry."));

      if (!close) {
        setCurrentValue(newEntry?.emailDetails || "");
        setDataHasChanged(false);
        return;
      }

      onClose();
    });
  };

  return (
    <BaseDialog open={open} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onClose}>
      <BaseDialogTitle>{t("Email Contacts")} </BaseDialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Label htmlFor='email-details-dialog'>{t("Customer email contacts *")}</Label>

          <TextArea
            id='email-details-dialog'
            aria-describedby={"email-details-dialog-error"}
            style={{ ...(errorMessage && { borderColor: "#a4262c" }) }}
            value={textFieldValue}
            onChange={onEmailContactsChanged}
          />
          <Text
            style={{ display: "block", marginTop: 5, fontSize: 12, color: "#a4262c" }}
            id={"email-details-dialog-error"}
          >
            {errorMessage}
          </Text>
        </div>

        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || errorMessage.length > 0 || textFieldValue.length === 0}
            onClick={onSendEmailHandler}
          >
            {t("Send Email")}
          </Button>
          <Button
            disabled={!dataHasChanged || isLoading || errorMessage.length > 0}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={() => onSaveChangesHandler(true)}
          >
            {t("Save Changes")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default EmailDetailsDialog;
