import { Button, DialogActions, DialogContent } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFileDownload } from "../../../../../Hooks";
import DownloadDialog from "../../../../../modules/common/components/Dialogs/DownloadDialog";
import BaseDialog, { BaseDialogTitle } from "../../../Dialog";
import { NoData } from "../../../NoData";
import { Spin } from "../../../Spin";
import type { FilePreviewProps } from "../../utils";
import { blobToBase64, FileType } from "../../utils";
import { ImagePreview } from "../ImagePreview";
import { PDFPreview } from "../PDFPreview";

const contentStyles: React.CSSProperties = {
  overflow: "auto",
  height: "100%",
  position: "relative",
};

interface FilePreviewModalProps {
  isOpen: boolean;
  id: string;
  name: string;
  fileType: FileType;
  onClose: () => void;
}

export const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
  isOpen,
  id,
  name,
  fileType,
  onClose,
}) => {
  const { downloadFile, getFile, showDownloadDialog } = useFileDownload();
  const { t } = useTranslation();
  const [file, setFile] = useState<FilePreviewProps>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFile({ id })
      .then((fileResponse) => {
        blobToBase64(fileResponse.data)
          .then((res) => {
            setFile({
              src: res as string,
              type: fileResponse.data.type === FileType.Pdf ? FileType.Pdf : FileType.Img,
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <Spin text={t("Loading...")} />;
    }

    if (isError) {
      return <NoData text={t("Error loading file")} />;
    }

    if (fileType === FileType.Img) {
      return <ImagePreview file={file} name={name} />;
    }

    if (fileType === FileType.Pdf) {
      return <PDFPreview src={file?.src} />;
    }

    return null;
  };

  return (
    <>
      <BaseDialog
        open={isOpen}
        modalType='non-modal'
        hasResize={true}
        onOpenChange={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <BaseDialogTitle>{name}</BaseDialogTitle>

        <DialogContent
          style={{ height: "calc(100% - 40px)", overflow: "unset" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={contentStyles}>{renderContent()}</div>

          <DialogActions>
            <Button
              appearance='primary'
              onClick={(e) => {
                e.stopPropagation();
                downloadFile({ id, name });
              }}
            >
              {t("Download")}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t("Close")}
            </Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
      <DownloadDialog open={showDownloadDialog} />
    </>
  );
};
