import "../../styles.scss";

import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
} from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular, MoreVerticalRegular } from "@fluentui/react-icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useWebAnalysisPermissions } from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import type { Group } from "../../../../../types";
import { NoData } from "../../../../common/NoData";
import type { TableProps } from "../../../../common/Table/v9/Table";
import Table from "../../../../common/Table/v9/Table";
import { Stack } from "../../../../Stack";
import useInfoStore from "../../hooks/useInfoStore";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import { FormType } from "../CommandBar";
import EntryDialog from "../dialogs/EntryDialog/EntryDialog";
import GroupDialog from "../dialogs/GroupDialog";
import { getColumns } from "./columns";

interface MachineCVInfoMachineDetailsProps {
  group: Group;
  allGroups: Group[];
}

const MachineCVInfoMachineDetails: React.FC<MachineCVInfoMachineDetailsProps> = ({
  group,
  allGroups,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);

  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermissions = useReadyPermission({
    permissionRoles: ["MachineCvAdministrator"],
  });

  const hasMachineCvContributorPermissions = useReadyPermission({
    permissionRoles: ["MachineCvContributor"],
  });

  const show3Dots =
    hasMachineCvAdministratorPermissions ||
    (hasMachineCvContributorPermissions && group.name.trim() === "Customer Feedback");

  const [isAddEntryDialogHidden, setIsAddEntryDialogHidden] = useState(true);
  const toggleIsAddEntryDialogHidden = () => setIsAddEntryDialogHidden(!isAddEntryDialogHidden);

  const [isEditGroupDialogHidden, setIsEditGroupDialogHidden] = useState(true);
  const toggleIsEditGroupDialogHidden = () => setIsEditGroupDialogHidden(!isEditGroupDialogHidden);

  const [isDeleteGroupDialogHidden, setIsDeleteGroupDialogHidden] = useState(true);
  const toggleIsDeleteGroupDialogHidden = () =>
    setIsDeleteGroupDialogHidden(!isDeleteGroupDialogHidden);

  const infoStore = useInfoStore();

  const groupEntryIDs = group.entries.map(({ id }) => id as string);

  const style = [
    {},
    {
      width: "100%",
    },
    {
      width: "calc(50% - 5px)",
    },
    {
      width: "calc(33.33% - 5px)",
    },
  ];

  const columns = useMemo(() => {
    return getColumns({ t, group, hasActions: show3Dots });
  }, [t, group, show3Dots]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-info" + group.name,
        version: 1,
      },
      items: group.entries,
      v8Columns: columns,
    }),
    [group, columns],
  );

  const groupMenuActions = [
    {
      key: "add_entry",
      text: t("Add Entry"),
      onClick: () => toggleIsAddEntryDialogHidden(),
    },
  ];

  group.name.trim() !== "Customer Feedback" &&
    groupMenuActions.push(
      {
        key: "edit_group",
        text: t("Edit Group"),
        onClick: () => toggleIsEditGroupDialogHidden(),
      },
      {
        key: "delete_group",
        text: t("Delete Group"),
        onClick: () => toggleIsDeleteGroupDialogHidden(),
      },
    );

  return (
    <div
      className='info-machine-details'
      style={{
        ...style[infoStore.perRow],
        minHeight: !group?.entries?.length && isOpen ? "260px" : "0px",
      }}
    >
      <Stack
        horizontal
        style={{
          background: "#2C529F",
          padding: "0px 8px",
          cursor: "pointer",
          marginTop: "10px",
        }}
        horizontalAlign='space-between'
        verticalAlign='center'
        onClick={toggleIsOpen}
      >
        <Text style={{ color: "#fff" }}>{group.name}</Text>
        <Stack horizontal>
          {show3Dots && (
            <Menu
              open={isEditMenuOpen}
              positioning='below-end'
              onOpenChange={(e, data) => {
                e.stopPropagation();
                setIsEditMenuOpen(data.open);
              }}
            >
              <MenuTrigger disableButtonEnhancement>
                <Button
                  style={{ minWidth: 0, height: "fit-content", color: "#fff" }}
                  appearance='transparent'
                  icon={<MoreVerticalRegular />}
                />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {groupMenuActions.map((item) => (
                    <MenuItem key={item.key} onClick={item.onClick}>
                      {item.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </MenuPopover>
            </Menu>
          )}

          <Button
            style={{ color: "#fff" }}
            appearance='transparent'
            className='toggle-overview-button'
            icon={!isOpen ? <ChevronDownRegular /> : <ChevronUpRegular />}
            onClick={toggleIsOpen}
          />
        </Stack>
      </Stack>
      {!isOpen ? null : group.entries.length > 0 ? (
        <Table {...tableProps} className='info-table' />
      ) : (
        <NoData style={{ background: "white", height: "calc(100% - 42px)" }} />
      )}
      <EntryDialog
        open={!isAddEntryDialogHidden}
        formType={FormType.New}
        group={group}
        onDismiss={toggleIsAddEntryDialogHidden}
      />
      <GroupDialog
        open={!isEditGroupDialogHidden}
        formType={FormType.Edit}
        group={group}
        allGroups={allGroups}
        onDismiss={toggleIsEditGroupDialogHidden}
      />
      <DeleteDialog
        open={!isDeleteGroupDialogHidden}
        data={{ ids: [group.id, ...groupEntryIDs], name: group.name }}
        route={"meta/machinecv/v1/infos"}
        onDismiss={toggleIsDeleteGroupDialogHidden}
        onSuccess={refetch}
      />
    </div>
  );
};

export default MachineCVInfoMachineDetails;
