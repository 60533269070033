import type { ComboboxProps } from "@fluentui/react-components";
import { Field } from "@fluentui/react-components";
import { Controller } from "react-hook-form";

import Combobox from "../../Combobox";
import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormDropdownProps = BaseFormFieldProps &
  ComboboxProps & {
    name: string;
    options: any[];
    renderOption?: ({ key, text }: { key: any; text: string }) => any;
    allowFreeform?: boolean;
    multiSelect?: boolean;
    fieldProps?: any;
  };

export const FormDropdown = ({
  name,
  defaultValue,
  groupProps,
  fieldProps = {},
  hookProps: { control, errors },
  options,
  required = false,
  ...rest
}: FormDropdownProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value = "" } }) => {
        return (
          <Field
            required={required}
            style={{ width: "100%" }}
            validationMessage={errors?.[name]?.message || ""}
            {...fieldProps}
          >
            <Combobox
              options={options}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            />
          </Field>
        );
      }}
    />
  </FormGroup>
);
