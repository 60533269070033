import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "../../../../Components/common/Table/v9";
import { Stack } from "../../../../Components/Stack";
import { useMachineList } from "../../../../Hooks";
import type { ResponseSimplifiedSignalList } from "../../../../types";
import { format } from "../../../common/utils/signalFormatter";
import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import useControlsStore from "../../hooks/useControlsStore";
import type { TrendViewRawDetails } from "../../types";
import { getColumns } from "./columns";

const getValueOrDefault = (value: any) => (Number.isNaN(value) ? "-" : value);

interface TrendViewGroupedInfoProps {
  data: Array<TrendViewRawDetails & { name: string }>;
  signals: ResponseSimplifiedSignalList;
}

const TrendViewGroupedInfo: React.FC<TrendViewGroupedInfoProps> = ({ data, signals }) => {
  const { t } = useTranslation();

  const { data: machineList } = useMachineList({ enabled: false });

  const trendsGrouping = useControlsStore((store) => store.trendsGrouping);

  const hasProjectAndMachineCols = trendsGrouping?.key !== TrendGroupingEnum.GROUP_BY_MACHINE;

  const getProjectAndMachine = useCallback(
    (machineId: string) => {
      if (!hasProjectAndMachineCols) {
        return null;
      }

      const machine = machineList?.find(({ id }) => id === machineId);

      return {
        project: machine?.project?.name,
        machine: machine?.name,
      };
    },
    [hasProjectAndMachineCols, machineList],
  );

  const allData = useMemo(
    () =>
      data.map((rawDetails, index) => ({
        index,
        name: format(signals[index]),
        unit: signals[index].unit,
        last: rawDetails ? getValueOrDefault(rawDetails.all.last) : "-",
        min: rawDetails ? getValueOrDefault(rawDetails.all.minimum) : "-",
        max: rawDetails ? getValueOrDefault(rawDetails.all.maximum) : "-",
        avg: rawDetails ? getValueOrDefault(rawDetails.all.average) : "-",
        stDev: rawDetails ? getValueOrDefault(rawDetails.all.stDev) : "-",
        ...getProjectAndMachine(signals[index].machineId!),
      })),
    [data, signals, getProjectAndMachine],
  );

  const currentViewData = useMemo(
    () =>
      data.map((rawDetails, index) => ({
        index,
        name: format(signals[index]),
        unit: signals[index].unit,
        last: rawDetails ? getValueOrDefault(rawDetails.selectedRange.last) : "-",
        min: rawDetails ? getValueOrDefault(rawDetails.selectedRange.minimum) : "-",
        max: rawDetails ? getValueOrDefault(rawDetails.selectedRange.maximum) : "-",
        avg: rawDetails ? getValueOrDefault(rawDetails.selectedRange.average) : "-",
        stDev: rawDetails ? getValueOrDefault(rawDetails.selectedRange.stDev) : "-",
        ...getProjectAndMachine(signals[index].machineId!),
      })),
    [data, signals, getProjectAndMachine],
  );

  return (
    <Stack
      style={{
        backgroundColor: "#FAF9F8",
        marginTop: 16,
        gap: 24,
      }}
    >
      <Table
        persistOpts={{
          key: "signal-grouped-all-data",
          version: 2,
        }}
        items={allData}
        v8Columns={getColumns({
          t,
          header: t("All data"),
          hasProjectAndMachineCols,
        })}
      />
      <Table
        persistOpts={{
          key: "signal-grouped-current-view",
          version: 2,
        }}
        items={currentViewData}
        v8Columns={getColumns({
          t,
          header: t("Current view"),
          hasProjectAndMachineCols,
        })}
      />
    </Stack>
  );
};

export default TrendViewGroupedInfo;
