/* eslint-disable react-hooks/exhaustive-deps */

import { Image } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultImageProfileBase64 } from "../../schema/Constants";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { DialogSize } from "../common/Dialog";
import type { AcceptedFile } from "../common/Uploader/Uploader";
import FormDialog from "../Generic/FormDialog";
import { Stack } from "../Stack";
import { updateUserPhoto } from "../UsersRBCA/api";
import type { UserPicture } from "../UsersRBCA/models";
import { LocalAccountInfo } from "./context";

type UpdateUserPhotoProps = {
  onSuccess: (hasError: boolean, displayName: string) => void;
  onClose: () => void;
};

const formatImageBase64 = (image: string) => {
  return `data:image/jpeg;base64,${image}`;
};

const getCommandBarItemProps = (
  t,
  onUpload: (files: AcceptedFile[]) => void,
  onDelete: () => void,
): any => {
  const result: any = [
    {
      key: "upload-photo",
      text: t("Upload"),
      ariaLabel: t("Upload photo"),
      type: CommandBarItemType.Upload,
      onRenderProps: {
        maxSize: 100000000,
        accept: "image/*",
        multiple: false,
        onChange: onUpload,
      },
    },
    {
      key: "delete-photo",
      text: t("Delete"),
      ariaLabel: t("Delete photo"),
      type: CommandBarItemType.Button,
      icon: <DeleteRegular />,
      onClick: () => onDelete(),
    },
  ];

  return result;
};

const UpdateUserPhotoDialog = ({ onSuccess, onClose }: UpdateUserPhotoProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const myAccount = useContext(LocalAccountInfo);
  const [selectedImgBase64, setSelectedImgBase64] = useState<string>(
    myAccount?.val?.pictureBase64 || defaultImageProfileBase64,
  );
  const [photoHasChanged, setPhotoHasChanged] = useState<boolean>(false);

  // Checks whether the photo has changed
  useEffect(() => {
    setPhotoHasChanged(
      selectedImgBase64 !== defaultImageProfileBase64 &&
        selectedImgBase64 !== myAccount?.val?.pictureBase64,
    );
  }, [selectedImgBase64]);

  // Handlers
  const onSubmitClick = () => {
    const aux: UserPicture = {
      id: myAccount.val.memberId,
      pictureBase64: selectedImgBase64 !== defaultImageProfileBase64 ? selectedImgBase64 : "",
    };

    setIsLoading(true);
    updateUserPhoto(aux).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        onSuccess?.(true, t("User profile photo"));
        return;
      }

      onSuccess?.(false, t("User profile photo"));
      myAccount.set({
        ...myAccount.val,
        pictureBase64: selectedImgBase64,
      });
      onClose?.();
    });
  };

  const onDeleteClick = () => {
    setSelectedImgBase64(defaultImageProfileBase64);
  };

  const onUploadFileClick = (files: AcceptedFile[]) => {
    const file = files[0]?.file;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target?.result;
      setSelectedImgBase64(btoa(bstr as string));
    };
    reader.readAsBinaryString(file);
  };

  return (
    <FormDialog
      title={t("Change Profile Photo")}
      isLoading={isLoading}
      isValid={photoHasChanged}
      size={DialogSize.S}
      onSubmit={onSubmitClick}
      onClose={onClose}
    >
      <Stack horizontalAlign='center' style={{ padding: 10, gap: 2 }}>
        <div className='box-shadow-panel' style={{ borderRadius: "100%", overflow: "hidden" }}>
          <Image
            src={formatImageBase64(selectedImgBase64)}
            alt={t("User profile photo.")}
            width={250}
            height={250}
            fit='cover'
            loading='lazy'
          />
        </div>
        <BaseCommandBar
          items={computeCommandBarItems(
            getCommandBarItemProps(t, onUploadFileClick, onDeleteClick),
          )}
        />
      </Stack>
    </FormDialog>
  );
};

export default UpdateUserPhotoDialog;
