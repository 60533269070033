import type { MachineCvOverviewProject } from "../../../../types";
import { NoData } from "../../../common/NoData";
import { Stack } from "../../../Stack";
import { MachineCard } from "./MachineCard";

type DetailedLayoutProps = {
  project: MachineCvOverviewProject;
};

const DetailedLayout = ({ project }: DetailedLayoutProps) => {
  if (project.machines.length === 0) {
    return <NoData style={{ margin: "16px 0px" }} />;
  }

  return (
    <Stack horizontal className='machine-cv-overview-grid'>
      {project.machines.map((machine) => (
        <MachineCard key={machine.metaData.id} machine={machine} />
      ))}
    </Stack>
  );
};

export default DetailedLayout;
