import "./style.scss";

import { Field, Label, makeStyles, Slider } from "@fluentui/react-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Combobox from "../../../../../../Components/common/Combobox";
import SpinButton from "../../../../../../Components/common/SpinButton";
import { Stack } from "../../../../../../Components/Stack";
import { FACTOR_LIMITS, OFFSET_LIMITS } from "../../constants";
import useLayoutSettingsStore from "../../useLayoutSettingsStore";

const useStyles = makeStyles({
  incrementInput: {
    minWidth: 0,
    maxWidth: "120px",

    "& .fui-Combobox__input": {
      width: "100%",
    },
  },

  slider: {
    width: "100%",

    "& .fui-Slider__rail": {
      ":before": {
        background: "rgb(243, 242, 241)",
      },
    },
  },
});

export default function YAxisSettings() {
  const classes = useStyles();
  const { step, selectedYAxisIndex, yAxesSettings, updateYAxisSliderSetting, updateStore } =
    useLayoutSettingsStore((store: any) => ({
      step: store.step,
      selectedYAxisIndex: store.selectedYAxisIndex,
      yAxesSettings: store.yAxesSettings,
      updateStore: store.updateStore,
      updateYAxisSliderSetting: store.updateYAxisSliderSetting,
    }));

  const { t } = useTranslation();

  const settingsStore = yAxesSettings?.[selectedYAxisIndex];

  const factor = settingsStore?.factor || 0;
  const offset = settingsStore?.offset || 0;

  const Y_AXIS_SETTINGS = useMemo(() => {
    return [
      {
        label: t("Factor"),
        id: "factor",
        min: FACTOR_LIMITS.min,
        max: FACTOR_LIMITS.max,
        sliderValue: factor,
        disabled: false,
        step,
      },

      {
        label: t("Offset"),
        id: "offset",
        min: OFFSET_LIMITS.min,
        max: OFFSET_LIMITS.max,
        sliderValue: offset,
        disabled: factor < -95 ? true : false,
        step,
      },
    ];
  }, [offset, factor, step, t]);

  const incremenetOptions = [
    { key: "0.1", text: "0.1" },
    { key: "0.5", text: "0.5" },
    { key: "1", text: "1" },
    { key: "5", text: "5" },
    { key: "10", text: "10" },
  ];

  const renderYAxisSetting = useCallback((setting: any) => {
    const { label, id, sliderValue, min, max, step, disabled } = setting;

    return (
      <Stack key={label} className='chart-layout-modal_setting'>
        <Label style={{ fontWeight: 600 }}>{label}</Label>
        <div className='chart-layout-modal_setting--content'>
          <SpinButton
            disabled={disabled}
            value={sliderValue}
            min={min}
            max={max}
            step={step}
            onChange={(value) => {
              updateYAxisSliderSetting(value, id);
            }}
          />

          <Slider
            className={classes.slider}
            min={min}
            max={max}
            step={step}
            value={sliderValue}
            onChange={(_, data) => updateYAxisSliderSetting(data.value, id)}
          />

          {/* <SliderArrow
              customFunc={() => increment({ setting: id, changeValue: updateYAxisSliderSetting, max })}
              icon={<ChevronRight />}
            /> */}
        </div>
      </Stack>
    );
  }, []);

  return (
    <>
      <Field>
        <Label>{t("Increment value")}</Label>
        <Combobox
          className={classes.incrementInput}
          value={String(step)}
          options={incremenetOptions}
          onChange={(key) => {
            const numValue = Number(key);
            updateStore({ step: numValue });
          }}
        />
      </Field>

      <div className='input-description'>{t("Use for fine tuning of Factor and Offset")}</div>

      {Y_AXIS_SETTINGS.map(renderYAxisSetting)}
    </>
  );
}
