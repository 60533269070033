import { Button, Spinner, Switch, Text } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../assets/styles";
import AlertsIcon from "../../../../assets/svg/AlertsIcon";
import { onRenderLabel } from "../../../../Components/common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../Components/common/Dropdown";
import { notification } from "../../../../Components/common/Notification";
import type { XYChartStyles } from "../../../../Components/common/XYChart/XYChart";
import { Stack } from "../../../../Components/Stack";
import {
  AppBreakpoints,
  useBreakpoint,
  useMaximize,
  useUniqueId,
  useWebAnalysisPermissions,
} from "../../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../../types";
import useAlertsStore from "../../../analysis-trend-view/hooks/useAlertsStore";
import type { Condition } from "../../../machine-cv/constants";
import { conditionColor, getConditionFormat } from "../../../machine-cv/constants";
import type { Range } from "../../hooks/useControlsStore";
import useControlsStore from "../../hooks/useControlsStore";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import useSignalMonitoring from "../../hooks/useSignalMonitoring";
import useStatistics from "../../hooks/useStatistics";
import { TrendViewInfoHorizontal, TrendViewInfoVertical } from "../TrendViewInfo";
import TrendViewPlot from "../TrendViewPlot";
import { TrendViewPlotNoData, TrendViewPlotSpinner } from "../TrendViewPlotPlaceholders";
import { useTrendViewMachineAlerts } from "./useTrendViewAlerts";

interface TrendViewSectionHeaderProps {
  noTitle: boolean;
  noStatistics: boolean;
  noMaximize: boolean;
}

interface TrendViewSectionProps {
  signal: ResponseSimplifiedSignal;
  headerProps?: TrendViewSectionHeaderProps;
  chartStyles?: XYChartStyles;
  disableReloadOnZoom?: boolean;
  showConditionStatus?: boolean;
}

const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  width: "100%",
};

const isValidAlertLimit = (alertValue: number | null | undefined): boolean => {
  if (alertValue === null || alertValue === undefined) return false;
  if (Number.isNaN(alertValue)) return false;
  if (alertValue === 0) return false;
  return true;
};

const TrendViewSection: React.FC<TrendViewSectionProps> = ({
  signal,
  headerProps = {},
  chartStyles,
  disableReloadOnZoom,
  showConditionStatus = true,
}) => {
  const { t } = useTranslation();
  const { transparent } = useDropdownStyles();

  const alertsDropdownOptions = useMemo(
    () => [
      { key: "indicationLow", text: t("Indication Lower Limit") },
      { key: "indicationHigh", text: t("Indication Upper Limit") },
      { key: "alertLow", text: t("Alert Lower Limit") },
      { key: "alertHigh", text: t("Alert Upper Limit") },
      { key: "dangerLow", text: t("Danger Lower Limit") },
      { key: "dangerHigh", text: t("Danger Upper Limit") },
    ],
    [t],
  );

  const { noTitle, noStatistics, noMaximize } = headerProps as TrendViewSectionHeaderProps;
  const {
    referenceSignalId,
    connectedTimeRange,
    setConnectedTimeRange,
    period,
    loadingCharts,
    averageTrend,
  } = useControlsStore((state) => ({
    loadingCharts: state.loadingCharts,
    period: state.period,
    referenceSignalId: state.referenceSignal?.key,
    connectedTimeRange: state.connectedTimeRange,
    setConnectedTimeRange: state.setConnectedTimeRange,
    averageTrend: state.averageTrend,
  }));

  const { useReadyPermissionGeneral } = useWebAnalysisPermissions();
  const hasWirelessSensorReaderPermissions = useReadyPermissionGeneral({
    permissionRoles: ["WirelessSensorReader", "WirelessSensorContributor"],
  });

  const { updateAlertsOptions, alertsOptions } = useAlertsStore((state) => ({
    updateAlertsOptions: state.updateAlertsOptions,
    alertsOptions: state.alertsOptions,
  }));

  const uniqueId = useUniqueId();

  const { maximizeAction, maximizeIcon, maximizeLabel, isChartMaximized, isChartHidden } =
    useMaximize({ id: uniqueId, page: "trend-view" });

  const { corporation, company, project, machine } = useSignalMetaData(signal);

  const { isLoading, isSuccessReferenceSignal, signalPlotAxis, referenceSignalPlotAxis } =
    useSignalMonitoring(signal);

  const signalId = useMemo(
    () => (averageTrend && signal?.p12h ? signal?.p12h?.id : signal.id),
    [averageTrend],
  );

  const { data: alertsData = {}, isSuccess } = useTrendViewMachineAlerts(
    {
      machineId: signal.machineId as string,
      signalId,
    },
    { enabled: !!signalId },
  );

  const [selectedRange, setSelectedRange] = useState<Range>({
    startIndex: 0,
    endIndex: signalPlotAxis.x.length - 1,
  });

  const setRange = (selectedIndexes: Range, selectedTimes: Range) => {
    setSelectedRange(selectedIndexes);
    setConnectedTimeRange({ range: selectedTimes, signalId: signal.id ?? "" });
  };

  useEffect(() => {
    if (isSuccess) {
      const machineHighLowAlerts = ["alertHigh", "alertLow"].filter(
        (alertType) => alertsData?.[alertType],
      );
      if (machineHighLowAlerts.length > 0) {
        updateAlertsOptions(signalId as string, machineHighLowAlerts);
      }
    }
  }, [isSuccess, signalId]);

  useEffect(() => {
    setSelectedRange({ startIndex: 0, endIndex: signalPlotAxis.x.length - 1 });
  }, [signalPlotAxis.x]);

  const { trendViewRawDetails, referenceSignalRawDetails } = useStatistics({
    signalPlotAxis,
    referenceSignalPlotAxis,
    selectedRange,
    isLoading,
    isSuccessReferenceSignal,
  });

  const [isRawDetailsOpen, setIsRawDetailsOpen] = useState<boolean | undefined>(false);

  const breakpoint = useBreakpoint();

  const { data: signalsList } = useQuery(["signals-list"], () => [] as ResponseSimplifiedSignal[], {
    enabled: false,
  });

  const referenceSignal = signalsList?.find((item) => item.id === referenceSignalId);

  if (isLoading) {
    return <TrendViewPlotSpinner />;
  }

  if (signalPlotAxis.x.length === 0) {
    return <TrendViewPlotNoData signal={signal} />;
  }

  return (
    <div
      className={classNames("trend-view-section", {
        loading: loadingCharts,
        "trend-view-chart-section--hidden": isChartHidden,
        "trend-view-chart-section--maximized": isChartMaximized,
      })}
    >
      <Stack style={{ width: "100%" }}>
        {loadingCharts && (
          <Spinner className='loading-chart-data' size='small' label={t("Loading data...")} />
        )}
        <Stack horizontal verticalAlign='center' horizontalAlign='space-between'>
          <Stack horizontal verticalAlign='center'>
            {!noTitle && (
              <Text
                style={{
                  borderRight: "1px solid rgb(200, 198, 196)",
                  paddingRight: "16px",
                  marginLeft: "16px",
                  marginRight: "16px",
                  fontWeight: 600,
                }}
              >
                {t("Trend View")}
              </Text>
            )}

            <Dropdown
              className={transparent}
              multiselect={true}
              button={onRenderLabel({
                label: t("Alerts"),
                icon: <AlertsIcon style={dropdownIconStyle} />,
              })}
              options={alertsDropdownOptions}
              selectedOptions={alertsOptions[signal.id]}
              onOptionSelect={(_, data) => {
                const selectedOptions = data.selectedOptions;
                const alertValue = alertsData?.[data.optionValue];

                if (!isValidAlertLimit(alertValue)) {
                  const alertName =
                    alertsDropdownOptions.find((opt) => opt.key === data.optionValue)?.text ||
                    data.optionValue;

                  notification.warning(
                    t("No {{alertName}} configured for this signal", {
                      alertName,
                    }),
                  );
                  return;
                }

                updateAlertsOptions(signal.id, selectedOptions);
              }}
            />

            {hasWirelessSensorReaderPermissions &&
              signal.sensorSerialNo &&
              signal.sensorSerialNo.trim() !== "" && (
                <Stack.Item>
                  <strong>
                    {t("Serial No: {{sensorSerialNo}}", {
                      sensorSerialNo: signal.sensorSerialNo,
                    })}
                  </strong>
                </Stack.Item>
              )}
          </Stack>
          <Stack horizontal style={{ gap: 16 }}>
            {!noStatistics && (
              <Switch
                label={t("Statistics")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0 0 8px",
                }}
                checked={isRawDetailsOpen}
                onChange={(_, { checked }) => setIsRawDetailsOpen(checked)}
              />
            )}
            {!noMaximize && (
              <Button
                appearance='transparent'
                style={{ padding: 0, minWidth: 0 }}
                onClick={maximizeAction}
              >
                {maximizeIcon}
                <span>{maximizeLabel}</span>
              </Button>
            )}
            <div className={`export-${machine?.id}-${signal.id}`} style={{ marginRight: 16 }} />

            {showConditionStatus ? (
              <Stack
                horizontal
                horizontalAlign='center'
                verticalAlign='center'
                style={{
                  ...badgeContainerStyles,
                  width: 200,
                  backgroundColor: conditionColor[signal.condition as Condition],
                }}
              >
                <Text style={{ color: "#fff" }}>
                  {getConditionFormat(t)[signal.condition as Condition]}
                </Text>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <TrendViewPlot
          isAverageTrend={averageTrend}
          loadingCharts={loadingCharts}
          selectedPeriod={period}
          connectedTimeRange={connectedTimeRange}
          setSelectedRange={setRange}
          signalPlotAxis={signalPlotAxis}
          referenceSignalPlotAxis={referenceSignalPlotAxis}
          referenceSignal={referenceSignal}
          hasReferenceSignal={
            isSuccessReferenceSignal &&
            signal.machineId === referenceSignal?.machineId &&
            referenceSignalPlotAxis?.x.length > 0
          }
          signalId={signalId}
          corporation={corporation}
          company={company}
          project={project}
          machine={machine}
          signal={signal}
          exportSelector={`export-${machine?.id}-${signal.id}`}
          chartStyles={chartStyles}
          disableReloadOnZoom={disableReloadOnZoom}
        />
      </Stack>

      {isRawDetailsOpen && trendViewRawDetails && breakpoint === AppBreakpoints.LaptopLarge && (
        <TrendViewInfoVertical
          rawDetails={trendViewRawDetails}
          referenceSignalRawDetails={referenceSignalRawDetails}
        />
      )}

      {isRawDetailsOpen && trendViewRawDetails && breakpoint !== AppBreakpoints.LaptopLarge && (
        <TrendViewInfoHorizontal
          referenceSignalRawDetails={referenceSignalRawDetails}
          rawDetails={trendViewRawDetails}
        />
      )}
    </div>
  );
};

export default TrendViewSection;
