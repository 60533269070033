import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import BaseDialog from "../../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../../common/Form";
import type { FormItemProps } from "../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../../common/Notification";
import { useTask } from "../../../hooks/useTask";
import { useTaskUpdate } from "../../../hooks/useTaskMutations";
import useTasksStore from "../../../hooks/useTasksStore";

type TaskDetailsDialogProps = {
  id: string;
  onSuccess?: () => void;
  onDismiss?: () => void;
  open: boolean;
} & Omit<DialogProps, "children">;

const getSchema = (t) =>
  z.object({
    subject: z.string(),
    description: z
      .string()
      .max(512, {
        message: t("Description must contain at most 512 character(s)"),
      })
      .optional(),
  });

export const TaskDetailsDialog: FC<TaskDetailsDialogProps> = (props) => {
  const { t } = useTranslation();
  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));
  const { task, getTaskDetails } = useTask({ taskId: edit.id });
  const { updateTaskAsync } = useTaskUpdate();
  const [isLoading, setIsLoading] = useState(false);
  const schema = useMemo(() => getSchema(t), [t]);

  useEffect(() => {
    reset(task);
  }, [task?.subject, task?.description]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    defaultValues: useMemo(
      () => ({
        subject: task?.subject,
        description: task?.description,
      }),
      [task?.subject, task?.description],
    ),
    schema,
  });

  const taskFields: FormItemProps[] = [
    {
      name: "subject",
      type: FormItemType.TextField,
      groupProps: { label: t("Subject") },
    },
    {
      name: "description",
      type: FormItemType.TextArea,
      groupProps: { label: t("Description") },
    },
  ];

  const { dispatch } = useTasksStore((state) => ({
    dispatch: state.dispatch,
  }));

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    await updateTaskAsync({ id: edit.id || "", task: { ...task, ...data } })
      .then(() => {
        notification.success(t("Task successfully updated!"));
        dispatch({ type: "SET_RELOAD" });
        getTaskDetails();
        onClose();
      })
      .catch(() => notification.error(t("Error updating task")))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog {...props}>
        <DialogTitle>{t("Edit task details")}</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            {renderFormItems(taskFields, {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {t("Save changes")}
              </Button>
              <Button appearance='transparent' onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
    </>
  );
};
