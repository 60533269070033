/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { CookiesProvider } from "react-cookie";

import { Layout } from "./Components/Layout/Layout";
import { Theme } from "./Theme";

export const App: React.FunctionComponent = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, user]);

  return (
    <Theme>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <Layout />
      </CookiesProvider>
    </Theme>
  );
};
