import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../Hooks";
import { isValidDalogId, validateNumericField } from "../../../../schema/Utils";
import TextField from "../../../common/TextField";
import { selectMachinesToList } from "../../../Machines/reducer";
import { useValidation } from "../hooks/useValidation";
import type { GearboxSubmission, MachineSubmission } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";
import { SupportContext } from "../SupportContext";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";

interface MachineSupportInputProps {
  context: SecondPanelContext;
  matchUniqueId: string;
}

export const MachineSupportInput: React.FC<MachineSupportInputProps> = ({
  context,
  matchUniqueId,
}) => {
  const { t } = useTranslation();
  const machines = useAppSelector(selectMachinesToList);
  const { support, updateSupportEle } = useContext(SupportContext);
  const toUpdate = support?.machinesSupport.find((mach) => mach.matchUniqueId === matchUniqueId);

  // Check if this is an existing machine
  const isExistingMachine = !!context.initialValues.mach.id;
  const existingMachine = isExistingMachine
    ? machines.find((m) => m.id === context.initialValues.mach.id)
    : null;

  const { validateField, setValue } = useValidation({
    customValidators: {
      dalogId: (value) => {
        // Skip validation for existing machines
        if (isExistingMachine) return undefined;

        if (machines.some((ele) => ele.dalogId === value)) {
          return t("The dalogId already exists");
        }
        if (!isValidDalogId(value)) {
          return t("The format is XX-XXX-XX.");
        }
        return undefined;
      },
      gearboxPower: (value) => validateNumericField(value, t("Power"), t),
      gearboxRotationalSpeed: (value) => validateNumericField(value, t("Rotational speed"), t),
    },
    mode: "onChange",
    defaultValues: context.initialValues.mach,
  });

  const handleMachineChange = async (field: keyof MachineSubmission, value: string) => {
    // Only allow changes if it's not an existing machine
    if (isExistingMachine) return;

    const trimmedValue = value.trim();
    const machNew: Partial<MachineSubmission> = {
      [field]: trimmedValue,
      uniqueId: context.initialValues.mach.uniqueId,
    };
    context.updateFunction(machNew, context.initialValues.mach.uniqueId);
    setValue(field as any, trimmedValue);
  };

  const handleGearboxChange = async (field: keyof GearboxSubmission, value: string) => {
    // Only allow changes if it's not an existing machine
    if (isExistingMachine) return;

    let processedValue: string | number | undefined;

    if (["power", "rotationalSpeed"].includes(field)) {
      const numericValue = Number(value);
      processedValue = isNaN(numericValue) ? value : numericValue;
    } else {
      processedValue = value.trim();
    }

    const gearboxNew: Partial<GearboxSubmission> = { [field]: processedValue };
    const machNew: Partial<MachineSubmission> = { gearbox: gearboxNew };

    context.updateFunction(machNew, context.initialValues.mach.uniqueId);
    setValue(`gearbox.${field}` as any, processedValue);
  };

  return (
    <>
      <TextFieldWithDocuments
        label={t("Machine Name")}
        value={
          isExistingMachine ? existingMachine?.name || "" : context.initialValues.mach.name || ""
        }
        disabled={context.context !== "Machine" || isExistingMachine}
        files={context.initialValues.mach.files}
        fieldName='name'
        onChange={(newVal) => handleMachineChange("name", newVal)}
      />

      <TextField
        value={isExistingMachine ? existingMachine?.dalogId || "" : toUpdate?.dalogId || ""}
        label={t("Dalog ID")}
        disabled={context.context !== "Machine" || isExistingMachine}
        onChange={async (newVal) => {
          if (isExistingMachine) return;
          await validateField("dalogId", newVal);
          const trimmedVal = newVal?.trim() || "";
          updateSupportEle({
            dalogId: trimmedVal,
            matchUniqueId: context.initialValues.mach.uniqueId,
          });
          setValue("dalogId", trimmedVal);
        }}
      />

      {!isExistingMachine && (
        <>
          <TextFieldWithDocuments
            label={t("Manufacturer")}
            value={context.initialValues.mach.manufacturer || ""}
            disabled={context.context !== "Machine"}
            files={context.initialValues.mach.files}
            fieldName='manufacturer'
            onChange={(newVal) => handleMachineChange("manufacturer", newVal)}
          />

          <TextFieldWithDocuments
            label={t("Customer Code")}
            value={context.initialValues.mach.customerCode || ""}
            disabled={context.context !== "Machine"}
            files={context.initialValues.mach.files}
            fieldName='customerCode'
            onChange={(newVal) => handleMachineChange("customerCode", newVal)}
          />

          <TextFieldWithDocuments
            label={t("Process")}
            value={context.initialValues.mach.process || ""}
            disabled={context.context !== "Machine"}
            files={context.initialValues.mach.files}
            fieldName='process'
            onChange={(newVal) => handleMachineChange("process", newVal)}
          />

          {/* Gearbox Fields in Accordion */}
          {
            <Accordion collapsible>
              <AccordionItem value='gearbox'>
                <AccordionHeader
                  expandIconPosition='end'
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    padding: "10px 10px",
                    backgroundColor: "#f0f5ff",
                    borderRadius: "4px",
                    borderLeft: "4px solid #0078d4",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {t("Gearbox Details")}
                  </span>
                </AccordionHeader>
                <AccordionPanel>
                  <TextFieldWithDocuments
                    label={t("Gearbox Manufacturer")}
                    value={context.initialValues.mach.gearbox.manufacturer || ""}
                    disabled={context.context !== "Machine"}
                    files={context.initialValues.mach.files}
                    fieldName='gearbox/manufacturer'
                    onChange={(newVal) => handleGearboxChange("manufacturer", newVal)}
                  />

                  <TextFieldWithDocuments
                    label={t("Gearbox Notation")}
                    value={context.initialValues.mach.gearbox.notation || ""}
                    disabled={context.context !== "Machine"}
                    files={context.initialValues.mach.files}
                    fieldName='gearbox/notation'
                    onChange={(newVal) => handleGearboxChange("notation", newVal)}
                  />

                  <TextFieldWithDocuments
                    label={t("Gearbox Power")}
                    value={context.initialValues.mach.gearbox.power?.toString() || ""}
                    fieldName='gearbox/power'
                    disabled={context.context !== "Machine"}
                    onChange={async (newVal) => {
                      await handleGearboxChange("power", newVal);
                      await validateField("gearboxPower", newVal);
                    }}
                  />

                  <TextFieldWithDocuments
                    label={t("Gearbox Rotational Speed")}
                    value={context.initialValues.mach.gearbox.rotationalSpeed?.toString() || ""}
                    fieldName='gearbox/rotationalSpeed'
                    disabled={context.context !== "Machine"}
                    onChange={async (newVal) => {
                      await handleGearboxChange("rotationalSpeed", newVal);
                      await validateField("gearboxRotationalSpeed", newVal);
                    }}
                  />

                  <TextFieldWithDocuments
                    label={t("Gearbox Serial Number")}
                    value={context.initialValues.mach.gearbox.serialnumber || ""}
                    disabled={context.context !== "Machine"}
                    files={context.initialValues.mach.files}
                    fieldName='gearbox/serialnumber'
                    onChange={(newVal) => handleGearboxChange("serialnumber", newVal)}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          }
        </>
      )}
    </>
  );
};
