import "./styles.scss";

import classNames from "classnames";

import type { StackItemProps } from "./types";

const ALIGNMENT_OPTIONS = {
  auto: "auto",
  stretch: "stretch",
  baseline: "baseline",
  start: "flex-start",
  center: "center",
  end: "flex-end",
};

export function StackItem({
  className = "",
  align = "auto",
  basis = "auto",
  order = 0,
  verticalFill,
  grow,
  shrink,
  children,
  ...rest
}: StackItemProps) {
  const flexGrow = typeof grow === "boolean" ? 1 : grow;
  const flexShrink = typeof shrink === "boolean" ? 1 : shrink;

  const height = !verticalFill ? "auto" : "100%";

  return (
    <div
      className={classNames("ms-StackItem", "default-stack-item-styles", className)}
      style={{
        alignSelf: ALIGNMENT_OPTIONS[align],
        flexBasis: basis,
        flexGrow,
        flexShrink,
        height,
        order,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
