import { Button, DialogActions, DialogContent, Text } from "@fluentui/react-components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../common/Dialog";
import Dropdown from "../../../../common/Dropdown";
import { notification } from "../../../../common/Notification";

export const measurementSettingOptions = [
  {
    key: "MeasSet0",
    text: "MeasSet0",
  },
  {
    key: "MeasSet1",
    text: "MeasSet1",
  },
  {
    key: "MeasSet4",
    text: "MeasSet4",
  },
];

interface ManualTriggerSettingsProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: (selectedKeys: string[]) => Promise<void>;
  trendAndRaw?: boolean;
}

const ManualTriggerSettings = ({
  isOpen,
  onDismiss,
  onSubmit,
  trendAndRaw = false,
}: ManualTriggerSettingsProps) => {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const availableOptions = measurementSettingOptions.filter(
    (option) => trendAndRaw || option.key === "MeasSet0",
  );

  const validateSelection = useCallback(
    (newSelection: string[]): boolean => {
      if (!trendAndRaw) {
        return newSelection.length <= 1 && (!newSelection.length || newSelection[0] === "MeasSet0");
      }
      if (newSelection.length <= 1) return true;
      if (newSelection.length > 2) return false;
      const hasSet0 = newSelection.includes("MeasSet0");
      const hasSet1 = newSelection.includes("MeasSet1");
      const hasSet4 = newSelection.includes("MeasSet4");
      return hasSet0 && ((hasSet1 && !hasSet4) || (hasSet4 && !hasSet1));
    },
    [trendAndRaw],
  );

  const onDropdownChange = useCallback(
    (ev, data) => {
      if (validateSelection(data.selectedOptions)) {
        setSelectedKeys(data.selectedOptions);
      } else {
        notification.error(t("Invalid measurement set combination"));
      }
    },
    [t, validateSelection],
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(selectedKeys);
      onDismiss();
    } catch (error) {
      notification.error(t("Manual trigger error"));
    } finally {
      setLoading(false);
    }
  };

  const getSelectionDescription = () => {
    if (!trendAndRaw) {
      return "MeasSet0 only";
    }
    return t("Select either a single MeasSet or MeasSet0 + MeasSet1/4");
  };

  return (
    <BaseDialog open={isOpen} surfaceStyle={{ width: DialogSize.S }} onOpenChange={onDismiss}>
      <BaseDialogTitle>{t("On-demand Trigger")}</BaseDialogTitle>

      <DialogContent>
        <Text
          size={200}
          style={{
            display: "block",
            color: "#666",
            marginBottom: "8px",
          }}
        >
          {getSelectionDescription()}
        </Text>

        <Dropdown
          multiselect={true}
          disabled={loading}
          options={availableOptions}
          selectedOptions={selectedKeys}
          style={{ width: "100%" }}
          onOptionSelect={onDropdownChange}
        />

        <DialogActions>
          <Button
            appearance='primary'
            disabled={loading || selectedKeys.length === 0}
            onClick={handleSubmit}
          >
            {loading ? t("Triggering...") : t("Trigger sensor")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default ManualTriggerSettings;
