import type { SVGProps } from 'react';
import React from 'react';

const InfoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.992 6.91C8.96974 6.78705 8.90225 6.6769 8.80283 6.60122C8.70341 6.52554 8.57926 6.48983 8.45482 6.50112C8.33039 6.51241 8.21469 6.56988 8.13051 6.66222C8.04633 6.75455 7.99977 6.87505 8 7V11.502L8.008 11.592C8.03026 11.7149 8.09775 11.8251 8.19717 11.9008C8.29659 11.9765 8.42074 12.0122 8.54518 12.0009C8.66961 11.9896 8.78531 11.9321 8.86949 11.8398C8.95367 11.7475 9.00023 11.6269 9 11.502V7L8.992 6.91ZM9.299 4.75C9.299 4.55109 9.21998 4.36032 9.07933 4.21967C8.93868 4.07902 8.74791 4 8.549 4C8.35009 4 8.15932 4.07902 8.01867 4.21967C7.87802 4.36032 7.799 4.55109 7.799 4.75C7.799 4.94891 7.87802 5.13968 8.01867 5.28033C8.15932 5.42098 8.35009 5.5 8.549 5.5C8.74791 5.5 8.93868 5.42098 9.07933 5.28033C9.21998 5.13968 9.299 4.94891 9.299 4.75ZM16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8ZM1.5 8C1.5 7.08075 1.68106 6.1705 2.03284 5.32122C2.38463 4.47194 2.90024 3.70026 3.55025 3.05025C4.20026 2.40024 4.97194 1.88463 5.82122 1.53284C6.6705 1.18106 7.58075 1 8.5 1C9.41925 1 10.3295 1.18106 11.1788 1.53284C12.0281 1.88463 12.7997 2.40024 13.4497 3.05025C14.0998 3.70026 14.6154 4.47194 14.9672 5.32122C15.3189 6.1705 15.5 7.08075 15.5 8C15.5 9.85652 14.7625 11.637 13.4497 12.9497C12.137 14.2625 10.3565 15 8.5 15C6.64348 15 4.86301 14.2625 3.55025 12.9497C2.2375 11.637 1.5 9.85652 1.5 8Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default InfoIcon;
