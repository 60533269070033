import classNames from "classnames";

import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import PerformancePlot from "./Chart";

const PerformanceChart = ({ data, signals, isLoading, noData }: any) => {
  return (
    <div className={classNames("white-container", { flex: isLoading || noData })}>
      {isLoading && <Spin className='m-a' />}
      {noData && <NoData className='m-a' />}
      {!noData && <PerformancePlot data={data} signals={signals} />}
    </div>
  );
};

export default PerformanceChart;
