import { useRef, useState } from "react";

import { useClickOutside } from "../../../../Hooks";
import { conditionColor } from "../../../../modules/machine-cv/constants";
import { Stack } from "../../../Stack";
import type { TreeItem } from "./index";
import NodeTooltip from "./NodeTooltip";

type NodeComponentProps = {
  nodeData: TreeItem;
};

const NodeComponent = ({ nodeData }: NodeComponentProps) => {
  const { name, icon, condition } = nodeData;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const ref = useRef(null);

  useClickOutside(ref, () => {
    if (tooltipVisible) setTooltipVisible(false);
  });

  return (
    <>
      {condition && (
        <div
          key={
            "node-" +
            (name ||
              Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15))
          }
        >
          <>
            <Stack
              className='tree-node-container'
              style={{ backgroundColor: "white" }}
              //onClick={() => setTooltipVisible(true)}
            >
              {name && <span className='name'>{name}</span>}
              <div className='icons-container'>
                {icon && <>{icon}</>}
                {condition && (
                  <span style={{ background: conditionColor[condition] }} className='condition' />
                )}
              </div>
              {tooltipVisible && (
                <div ref={ref} className='node-tooltip-container'>
                  <NodeTooltip data={nodeData} />
                </div>
              )}
            </Stack>
          </>
        </div>
      )}
    </>
  );
};

export default NodeComponent;
