import { Button, Text } from "@fluentui/react-components";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import FirstPageIcon from "../../../assets/svg/FirstPageIcon";
import LastPageIcon from "../../../assets/svg/LastPageIcon";
import NextPageIcon from "../../../assets/svg/NextPageIcon";
import PreviousPageIcon from "../../../assets/svg/PreviousPageIcon";
import type { StackProps } from "../../Stack";
import { Stack } from "../../Stack";
import Dropdown from "../Dropdown";
import { perPageOptions } from "./constants";

type To = "PREVIOUS_PAGE" | "NEXT_PAGE" | "FIRST_PAGE" | "LAST_PAGE";

export type PaginationProps = Omit<StackProps, "onChange"> & {
  total: number;
  perPage: number;
  current: number;
  hidePerPage: boolean;
  onChange?: (page: number) => void;
  setPerPage?: (page: number) => void;
};

const btnStyles = {
  border: 0,
  minWidth: 52,
  minHeight: 32,
};

const Pagination = ({
  perPage,
  total,
  current,
  onChange,
  setPerPage,
  hidePerPage,
  style = {},
  ...rest
}: PaginationProps) => {
  const { t } = useTranslation();

  const pages = useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  const canPrevious = current === 0;

  const canNext = current === pages - 1;

  const change = (to: To) => {
    switch (to) {
      case "PREVIOUS_PAGE":
      case "FIRST_PAGE": {
        if (current !== 0) {
          const index = to === "FIRST_PAGE" ? 0 : current - 1;

          onChange?.(index);
        }

        break;
      }
      case "NEXT_PAGE":
      case "LAST_PAGE": {
        if (current !== pages - 1) {
          const index = to === "LAST_PAGE" ? pages - 1 : current + 1;

          onChange?.(index);
        }

        break;
      }
    }
  };

  useEffect(() => {
    if (pages > 0 && current > pages - 1) {
      change("PREVIOUS_PAGE");
    }
  }, [pages, current]);

  if (pages <= 1 && hidePerPage) {
    return null;
  }

  return (
    <Stack
      horizontal
      style={{
        gap: 8,
        ...style,
      }}
      verticalAlign='center'
      className='pagination'
      aria-label='pagination navigation'
      {...rest}
    >
      <Button
        style={btnStyles}
        disabled={canPrevious}
        aria-label={t("Go to first page")}
        onClick={() => change("FIRST_PAGE")}
      >
        <FirstPageIcon />
      </Button>
      <Button
        style={btnStyles}
        disabled={canPrevious}
        aria-label={t("Go to previous page")}
        onClick={() => change("PREVIOUS_PAGE")}
      >
        <PreviousPageIcon />
      </Button>
      <Text
        style={{
          backgroundColor: "#fff",
          borderRadius: 2,
          color: "rgb(50, 49, 48)",
          cursor: "default",
          padding: "4px 8px",
          height: 32,
          minWidth: 52,
          lineHeight: 21,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {current + 1} / {pages}
      </Text>
      <Button
        style={btnStyles}
        disabled={canNext}
        aria-label={t("Go to next page")}
        onClick={() => change("NEXT_PAGE")}
      >
        <NextPageIcon />
      </Button>
      <Button
        style={btnStyles}
        disabled={canNext}
        aria-label={t("Go to last page")}
        onClick={() => change("LAST_PAGE")}
      >
        <LastPageIcon />
      </Button>
      {!hidePerPage && (
        <div className='table-per-page'>
          <span>{t("Per page:")}</span>
          <Dropdown
            disabled={true}
            style={{ minWidth: "unset" }}
            placeholder={t("Select an option")}
            selectedOptions={[`${perPage ? perPage : ""}`]}
            options={perPageOptions}
            onOptionSelect={(event, option: any) => {
              if (setPerPage) {
                setPerPage(parseInt(option.optionValue, 10));
              }
            }}
          />
        </div>
      )}
    </Stack>
  );
};

type PaginationItem = {
  key: string;
  Element: JSX.Element;
};

export type PaginationItemsProps = Omit<PaginationProps, "total" | "current"> & {
  items: PaginationItem[];
};

const PaginationItems = ({ items, perPage = 3, ...rest }: PaginationItemsProps) => {
  const [page, setPage] = useState(0);

  const data = items
    .slice(page * perPage, (page + 1) * perPage)
    .map(({ key, Element }) => <Fragment key={key}>{Element}</Fragment>);

  return (
    <div className='pagination-items'>
      {data}
      <Pagination
        current={page}
        total={items.length}
        perPage={perPage}
        onChange={(page) => setPage(page)}
        {...rest}
      />
    </div>
  );
};

export default Pagination;

Pagination.Items = PaginationItems;
