import type {
  DialogProps,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Spinner,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import { SkypeCheckIcon } from "@fluentui/react-icons-mdl2";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppSelector } from "../../Hooks";
import { defaultImageProfileBase64, getUserFieldsReq, titleStyle } from "../../schema/Constants";
import { Status } from "../../schema/status";
import { areObjectsEqual } from "../../schema/Utils";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import type { UserExtendedProperties } from "../ServiceAccount/models";
import { Stack } from "../Stack";
import { detailsUsersRBAC, updateUserRBAC } from "./api";
import type { UserDetails, UserUpdate } from "./models";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";
import { UpdateUserPhoto } from "./UpdateUserPhoto";

type DetailsDialogProps = Omit<DialogProps, "children" | "open"> & {
  data?: UserDetails;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialog = ({ data, show, onSuccess, onClose, ...rest }: DetailsDialogProps) => {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      z.object({
        email: z
          .string()
          .email({ message: t("Invalid email address") })
          .min(1, { message: t("This field is required") }),
        lastName: z.string().min(1, { message: t("This field is required") }),
        firstName: z.string().min(1, { message: t("This field is required") }),
        jobTitle: z.string().optional(),
        companyName: z.string().optional(),
        department: z.string().optional(),
        streetAddress: z.string().optional(),
        city: z.string().optional(),
        stateOrProvince: z.string().optional(),
        zipOrPostalCode: z.string().optional(),
        countryOrRegion: z.string().optional(),
        mobilePhone: z
          .union([
            z.string().length(0, { message: t("Invalid number") }),
            z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
          ])
          .optional()
          .transform((e) => (e === "" ? undefined : e)),
        memberId: z.string().optional(),
        displayName: z.string().optional(),
        initialPassword: z.string().optional(),
      }),
    [t],
  );

  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [pictureToAdd, setPictureToAdd] = useState(defaultImageProfileBase64);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        email: data.properties?.email ? data.properties?.email : undefined,
        lastName: data.properties?.lastName ? data.properties?.lastName : undefined,
        firstName: data.properties?.firstName ? data.properties?.firstName : undefined,
        jobTitle: data.properties?.jobTitle ? data.properties?.jobTitle : undefined,
        companyName: data.properties?.companyName ? data.properties?.companyName : undefined,
        department: data.properties?.department ? data.properties?.department : undefined,
        streetAddress: data.properties?.streetAddress ? data.properties?.streetAddress : undefined,
        city: data.properties?.city ? data.properties?.city : undefined,
        stateOrProvince: data.properties?.stateOrProvince
          ? data.properties?.stateOrProvince
          : undefined,
        zipOrPostalCode: data.properties?.zipOrPostalCode
          ? data.properties?.zipOrPostalCode
          : undefined,
        countryOrRegion: data.properties?.countryOrRegion
          ? data.properties?.countryOrRegion
          : undefined,
        mobilePhone: data.properties?.mobilePhone ? data.properties?.mobilePhone : undefined,
        memberId: data.properties?.memberId ? data.properties?.memberId : undefined,
        displayName: data.properties?.displayName ? data.properties?.displayName : undefined,
        initialPassword: data?.properties?.initialPassword
          ? data?.properties?.initialPassword
          : undefined,
      },
    }),
  });

  useEffect(() => {
    setIsFormChanged(!areObjectsEqual(control._formValues, control._defaultValues));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const handleClose = () => {
    setLoading(false);
    setPictureToAdd("");
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const aux: UserUpdate = {
      ...(formData as UserExtendedProperties),
    };
    await updateUserRBAC(aux).then((response) => onSuccess("status" in response, ""));

    handleClose();
  });

  const [selectedValue, setSelectedValue] = useState<TabValue>("properties");

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <BaseDialog
      {...rest}
      open={show}
      surfaceStyle={{
        width: DialogSize.L,
        maxWidth: "100vw",
      }}
      onOpenChange={handleClose}
    >
      <BaseDialogTitle>{t("Details User Account")}</BaseDialogTitle>
      <DialogContent>
        <TabList
          selectedValue={selectedValue}
          aria-label={t("Pivots to edit service account")}
          onTabSelect={onTabSelect}
        >
          <Tab value='properties'>{t("Properties")}</Tab>
          <Tab value='roles'>{t("Roles")}</Tab>
          <Tab value='setPicture'>
            {pictureToAdd !== defaultImageProfileBase64 && <SkypeCheckIcon />}
            <Text>{t("Picture")}</Text>
          </Tab>
        </TabList>
        <div>
          {selectedValue === "properties" && (
            <Stack horizontalAlign='baseline' verticalAlign='center'>
              <div style={{ marginTop: "50px" }}>
                <Text style={titleStyle}>{t("General")}</Text>
              </div>
              <form onSubmit={onSubmit}>
                <Stack
                  wrap
                  horizontal
                  style={{ maxWidth: "70vw", padding: "35px", gap: 8 }}
                  horizontalAlign='stretch'
                  verticalAlign='center'
                >
                  {renderFormItems(
                    getUserFieldsReq({
                      t,
                      isEmailDisabled: true,
                      withMemberIdRead: true,
                      withUserFieldsExtra: true,
                      withUserFieldsNonReq: true,
                    }) as FormItemProps[],

                    {
                      control,
                      errors: errors as { [schemaProp: string]: FieldError },
                    },
                  ).map((ele) => (
                    <Stack.Item key={ele.key}> {ele}</Stack.Item>
                  ))}
                </Stack>
                <DialogActions>
                  <Button
                    type='submit'
                    disabled={isLoading || !isValid || !isFormChanged}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                  >
                    {t("Save Changes")}
                  </Button>
                </DialogActions>
              </form>
            </Stack>
          )}
          {selectedValue === "roles" && (
            <RolesComponentDetails
              data={data}
              memberId={data.properties?.memberId}
              detailsFunction={detailsUsersRBAC}
            />
          )}
          {selectedValue === "setPicture" && (
            <Stack horizontalAlign='center' style={{ padding: "20px", gap: 20 }}>
              <Stack.Item style={{ padding: "20px", width: "-webkit-fill-available" }}>
                <UpdateUserPhoto
                  displayName={data.properties?.displayName ? data.properties?.displayName : ""}
                  memberId={data.properties?.memberId}
                  pictureBase64={data?.pictureBase64 ? data?.pictureBase64 : undefined}
                />
              </Stack.Item>
            </Stack>
          )}
        </div>
      </DialogContent>
    </BaseDialog>
  );
};
