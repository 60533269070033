import { useEffect, useRef } from "react";

/**
 * Generic useEventListener hook to handle DOM and custom events.
 * @param eventType The event type to listen for.
 * @param callback The event handler function.
 * @param element The target element (default: window).
 */
function useEventListener<
  K extends keyof GlobalEventHandlersEventMap | string,
  E extends Event = K extends keyof GlobalEventHandlersEventMap
    ? GlobalEventHandlersEventMap[K]
    : CustomEvent,
>(eventType: K, callback: (event: E) => void, element: EventTarget = window) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!element) return;

    const eventHandler = (event: Event) => callbackRef.current(event as E);

    element.addEventListener(eventType, eventHandler);

    return () => {
      element.removeEventListener(eventType, eventHandler);
    };
  }, [eventType, element]);
}

export default useEventListener;
