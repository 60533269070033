import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { editWirelessSensorNode } from "./api";
import type {
  RequestWirelessSensorNodeAdd,
  WirelessSensorNode,
  WirelessSensorNodeResponse,
} from "./models";
import { listAsyncSensorNodes, selectWirelessSensorNodes } from "./reducer";

/**
 * Edit Sensor Dialog Props type
 * */
type EditSensorDialogProps = Omit<DialogProps, "children" | "open"> & {
  data: WirelessSensorNodeResponse | null;
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: WirelessSensorNodeResponse | RequestWirelessSensorNodeAdd,
    context: "add" | "edit",
  ) => void;
  onClose: () => void;
};

/**
 * Gets the validation Zod schema
 * @param sensorNodes The sensor nodes reference list for validation
 * @returns the Zod object.
 */
const getFormSchema = (t, sensorNodes: WirelessSensorNodeResponse[]) =>
  z
    .object({
      id: z.string().optional(),
      sensorNodeId: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Sensor node Id must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      deviceModel: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Device Model must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .optional(),
      deviceClass: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Device Class must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .optional(),
      isActive: z.boolean().optional(),
    })
    .refine(
      (input) => {
        const newSensorNodeId = input?.sensorNodeId?.trim().toLowerCase();
        if (!newSensorNodeId) {
          return true;
        }

        return (
          sensorNodes.findIndex(
            (value) =>
              value.sensorNodeId.trim().toLowerCase() === newSensorNodeId && value.id !== input.id,
          ) === -1
        );
      },
      {
        path: ["sensorNodeId"],
        message: t("The Sensor Node Id already exists"),
      },
    );

/**
 * Gets the form fields
 * @param control the form control
 * @param errors the field errors
 * @returns the form fields.
 */
const getFormFields = ({ t, control, errors }: any) => {
  const formProps: FormItemProps[] = [
    {
      name: "sensorNodeId",
      type: FormItemType.TextField,
      groupProps: { label: t("Sensor Node Id / Sensor Serial No *") },
    },
    {
      name: "deviceModel",
      type: FormItemType.TextField,
      groupProps: { label: t("Device Model *") },
    },
    {
      name: "deviceClass",
      type: FormItemType.TextField,
      groupProps: { label: t("Device Class *") },
    },
    {
      name: "isActive",
      type: FormItemType.Checkbox,
      groupProps: { label: t("Is Active") },
    },
  ];

  return renderFormItems(formProps, {
    control,
    errors: errors,
  });
};

/**
 * EditDialog component
 * @param data The sensor node response
 * @param show True to display the component. Otherwise false.
 * @param onSuccess Function that will be called when the form has been successfully submitted.
 * @param onClose Function that will be called when the component is closing.
 * @returns the EditDialog component.
 */
export const EditDialog = ({ data, show, onSuccess, onClose, ...rest }: EditSensorDialogProps) => {
  // Hooks
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const sensorNodes = useAppSelector(selectWirelessSensorNodes);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useZodForm({
    mode: "onChange",
    schema: getFormSchema(t, sensorNodes),
    ...(!!data && {
      defaultValues: {
        id: data.id,
        sensorNodeId: data.sensorNodeId,
        deviceModel: data.deviceModel?.toString(),
        deviceClass: data.deviceClass?.toString(),
        isActive: data.isActive,
      },
    }),
  });

  // Gets the sensor nodes list.
  useEffect(() => {
    dispatch(listAsyncSensorNodes({ wireless: [], machs: [] }));
  }, [dispatch]);

  // Event handlers
  const handleClose = () => {
    setLoading(false);
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    const newData: WirelessSensorNode = {
      ...data,
      deviceClass: Number(formData.deviceClass),
      deviceModel: Number(formData.deviceModel),
      sensorNodeId: formData.sensorNodeId,
      isActive: formData.isActive,
    };

    // Sends the request to update the wireless sensor node.
    setLoading(true);
    await editWirelessSensorNode(newData).then((response) => {
      const hasError = "status" in response;
      onSuccess(hasError, newData, "edit");
      if (!hasError) {
        handleClose();
      }
    });
  });

  // Gets the form fields
  const formFields = getFormFields({
    t,
    control: control,
    errors: errors as { [schemaProp: string]: FieldError },
  });

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{t("Edit sensor Node")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {formFields.map((field) => field)}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isValid || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
