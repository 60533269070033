/* eslint-disable @typescript-eslint/no-explicit-any */
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Spinner } from "@fluentui/react-components";
import classNames from "classnames";
import { debounce, isEmpty, isEqual } from "lodash-es";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import DalogLogo from "../../../../assets/images/dalog-logo.svg";
import { default as chartSymbols } from "../../../../assets/svg/ChartSymbolsIcons";
import type { XYChartRef } from "../../../../Components/common/XYChart";
import { getDateAxis, getLabel, XYChart } from "../../../../Components/common/XYChart";
import {
  createChartExport,
  updateExportButtonText,
} from "../../../../Components/common/XYChart/export";
import { createGroupedTooltip } from "../../../../Components/common/XYChart/tooltip";
import { DEFAULT_DEBOUNCE } from "../../../../config/constants";
import { getRangeIndexes } from "../../../../utils";
import { createRange } from "../../../analysis-raw-data/components/TrendChart/TrendChart";
import { watermarkLogos } from "../../../analysis-raw-data/constants/chartWatermarkLogos";
import type { ConnectedTimeRange } from "../../hooks/useControlsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import { getRangeFormat } from "../../utils/getRangeFormat";
import { isOutOfSync, zoomToDates } from "../../utils/zoom";
import ChartLayoutModal from "../ChartLayoutModal";
import useLayoutSettingsStore from "../ChartLayoutModal/useLayoutSettingsStore";
import type { ChartData, EntityIds, TrendViewPlotProps } from "../TrendViewGroupedPlot/config";
import {
  createSeries,
  getDataFirstTimestamp,
  getDataLastTimestamp,
  removePreviousSeries,
} from "../TrendViewGroupedPlot/methods";
import { useTrendViewGroupedZoomInData } from "./useTrendViewGroupedZoomInData";

const chartControlsHeight = "41px";

// Calculate the scale of the legend items
const MAX_ITEMS = 8;
const MIN_SCALE = 0.4;
const MAX_SCALE = 1.0;
function calculateScale(itemCount: number): number {
  const scale = 1.3 - (itemCount * (MAX_SCALE - MIN_SCALE)) / MAX_ITEMS;
  return Math.max(scale, 0.93);
}

const TrendViewPlot = ({
  isAverageTrend,
  machineId,
  data,
  signals,
  numberOfSignals,
  connectedTimeRange,
  setSelectedRange,
  corporation,
  company,
  project,
  machine,
  exportSelector,
  hideRange,
  selectedTableEvent,
  chartStyles,
  style,
  ...rest
}: TrendViewPlotProps) => {
  const { t } = useTranslation();

  const ref = useRef<XYChartRef | null>(null);

  const signalsMinMaxValues = useMemo(() => {
    const registry = data?.reduce(
      (acc, item: any) => {
        signals.forEach((_, idx) => {
          const itemValue = item?.[`value_${idx}`];
          if (itemValue !== undefined) {
            acc.max = Math.max(acc.max, itemValue);
            acc.min = Math.min(acc.min, itemValue);
          }
        });
        return acc;
      },
      { min: Infinity, max: -Infinity },
    );

    return registry || { min: 0, max: 0 };
  }, [data, signals.length]);

  const rangeRef = useRef("");
  const legendRef = useRef<any>(null);
  const breadcrumbRef = useRef<any>(null);
  const dataRef = useRef<ChartData[]>([]);
  const { handleZoomChange } = useTrendViewGroupedZoomInData();
  const { isLoadingMultipleSignals } = useSelectedSignalsStore((state) => ({
    isLoadingMultipleSignals: state.isLoadingMultipleSignals,
  }));

  const { resetSettings, getSelectedAxisStartEnd, syncSettingsMode, updateStore } =
    useLayoutSettingsStore((store: any) => ({
      resetSettings: store.resetSettings,
      getSelectedAxisStartEnd: store.getSelectedAxisStartEnd,
      syncSettingsMode: store.syncSettingsMode,
      updateStore: store.updateStore,
    }));
  const entityIds = useMemo<EntityIds>(
    () => ({
      xAxis: `x-axis-trend-view-grouped-plot-${machineId}`,
      legend: `legend-trend-view-grouped-plot-${machineId}`,
      outOfSync: `out-of-sync-trend-view-grouped-plot-${machineId}`,
      noAverageTrend: `no-average-trend-view-grouped-plot-${machineId}`,
    }),
    [],
  );
  const onStartEndChanged = (_: unknown, target: am5.Scrollbar | undefined) => {
    if (!ref.current || !target || data.length === 0) return;
    const start = target.get("start") ?? 0;
    const end = target.get("end") ?? 1;
    const xAxis = getDateAxis(entityIds.xAxis);
    const times: ConnectedTimeRange["range"] = {
      startIndex: new Date(xAxis.positionToDate(start)).getTime(),
      endIndex: new Date(xAxis.positionToDate(end)).getTime(),
    };
    const indexes = getRangeIndexes({
      data: data.map(({ date_0 }) => ({ date: date_0 })),
      start: times.startIndex,
      end: times.endIndex,
    });
    const currentRange: ConnectedTimeRange["range"] = {
      startIndex: data[indexes.startIndex].date_0,
      endIndex: data[indexes.endIndex].date_0,
    };

    rangeRef.current = getRangeFormat(times.startIndex, times.endIndex);
    handleZoomChange(times);
    setSelectedRange && setSelectedRange(indexes, currentRange);
  };

  const handleStartEndChanged = debounce(onStartEndChanged, DEFAULT_DEBOUNCE);

  useEffect(() => {
    if (!ref?.current) return;

    if (ref.current?.chart.isVisible()) {
      const isVisible = !!signals.filter((item: any) => item.noAverageTrend)[0];
      getLabel(entityIds.noAverageTrend)?.set("visible", isVisible);
    }
  }, [signals, isAverageTrend]);

  // Draws the chart.
  useEffect(() => {
    if (ref?.current === null) {
      return;
    }
    // === Padding ===
    ref.current.chart.set("paddingRight", 10);
    // === X Axis ===
    const xAxis = ref.current.chart.xAxes.push(
      am5xy.DateAxis.new(ref.current.root, {
        id: entityIds.xAxis,
        baseInterval: { timeUnit: "second", count: 1 },
        renderer: am5xy.AxisRendererX.new(ref.current.root, {}),
        maxDeviation: 0,
        marginTop: 16,
      }),
    );
    xAxis.get("renderer").labels.template.set("fontSize", 10);
    // === Series ===
    createSeries({ ref, xAxis, data, signals, numberOfSignals });
    // === Scrollbar ===
    ref.current.chart.get("scrollbarX")?.on("start", handleStartEndChanged);
    ref.current.chart.get("scrollbarX")?.on("end", handleStartEndChanged);
    // === Reset modal settings when clicking Zoom Out Button ===
    ref.current.chart.zoomOutButton.events.on("click", () => resetSettings("all"));
    // Pre-zoom
    ref.current.chart.series.getIndex(0)?.events.once("datavalidated", () => {
      if (machineId === "all") return;
      if (connectedTimeRange) {
        getLabel(entityIds.outOfSync).set(
          "visible",
          isOutOfSync(
            data.map(({ date_0 }) => ({ date: date_0 })),
            connectedTimeRange.range,
          ) && connectedTimeRange.signalId !== signals[0].id,
        );
        zoomToDates(
          xAxis,
          data.map(({ date_0 }) => ({ date: date_0 })),
          connectedTimeRange.range,
        );
      }
    });

    // === Footer Label ===
    const breadcrumbRoot = am5.Root.new(`breadcrumb-container-${machineId}`);

    if (machineId !== "all" && machineId !== "all-modal") {
      const breadcrumbContainer = document.getElementById(`breadcrumb-container-${machineId}`);
      const breadcrumb = breadcrumbRoot.container.children.push(
        am5.Label.new(breadcrumbRoot, {
          html: `
          <span style='font-size: 12px;color: #878583;'>
            ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name}  〉 <strong style='color: #333;'>${machine?.name}</strong>
          </span>`,
          width: am5.percent(100),
          fill: am5.color("#878583"),
        }),
      );
      breadcrumb.events.on("boundschanged", () => {
        if (breadcrumbContainer) {
          breadcrumbContainer.style.height = breadcrumb.height() + "px";
        }
      });
      breadcrumbRef.current = breadcrumb;
    }

    // === Legend ===
    const legend = ref.current.chart.bottomAxesContainer.children.push(
      am5.Legend.new(ref.current.root, {
        centerX: am5.percent(0),
        x: 0,
        layout: ref.current.root.horizontalLayout,
      }),
    );

    // === Set legend marker icon
    legend.events.on("datavalidated", () => {
      if (ref?.current === null) {
        return;
      }

      const markers = legend.markers.values;
      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];
        const icon = am5.Picture.new(ref.current.root, {
          width: 16,
          height: 16,
          src: `${chartSymbols.svgs[i]}`,
        });
        marker.children.setAll([icon]);
      }
      const itemCount = legend.data.length;
      const newScale = calculateScale(itemCount);
      itemCount > 3 && legend.set("layout", ref.current.root.gridLayout);
      itemCount <= 3 && legend.set("layout", ref.current.root.horizontalLayout);
      legend.itemContainers.template.setAll({
        scale: newScale,
      });
      legend.labels.template.setAll({
        fontSize: 12 * newScale,
      });
      legend.markers.template.setAll({
        width: 16 * newScale,
        height: 16 * newScale,
      });

      legend.markDirtySize();
      legend.markDirty();
    });

    // === Lower marker opacity when disabled

    legend.markers.template.setup = (marker) => {
      marker.states.create("disabled", {
        opacity: 0.5,
      });
    };

    // === Legend marker label font size

    legend.labels.template.setAll({
      oversizedBehavior: "wrap",
      fontSize: 12,
    });

    legend.template?.setAll({ layout: ref.current.root.gridLayout });

    // === Remove default legend marker
    legend.markerRectangles.template.setAll({
      visible: false,
      width: 0,
      height: 0,
    });

    legend.data.setAll(ref.current.chart.series.values);
    legendRef.current = legend;

    // === Out of sync box ===
    ref.current.chart.plotContainer.children.push(
      am5.Label.new(ref.current.root, {
        id: entityIds.outOfSync,
        html: `<div class="out-of-sync">${t("Chart out of sync. Check signal start-end date.")}</div>`,
        centerX: am5.p50,
        centerY: am5.p100,
        x: am5.p50,
        y: am5.percent(95),
        visible: false,
      }),
    );

    // === No average trend data ===
    ref.current.chart.plotContainer.children.push(
      am5.Label.new(ref.current.root, {
        id: entityIds.noAverageTrend,
        html: `<div class="out-of-sync">${t("One or more signals “*” are missing averaged data. Please deselect signals with “*”.")}</div>`,
        centerX: am5.p50,
        centerY: am5.p100,
        x: am5.p50,
        y: am5.percent(95),
        visible: !!signals.filter((item: any) => item.noAverageTrend)[0],
      }),
    );

    // === watermarks ===
    watermarkLogos.forEach((logoData) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const logo = am5.Picture.new(ref.current.root, {
        src: DalogLogo,
        height: logoData.height,
        opacity: logoData.opacity,
        x: logoData.x,
        y: logoData.y,
        centerX: logoData.centerX,
        centerY: logoData.centerY,
      });
      ref.current?.chart.plotContainer.children.push(logo);
    });

    createGroupedTooltip({
      ref: ref.current,
      type: "date",
    });

    createChartExport({
      root: ref.current.root,
      chart: ref.current.chart,
      selector: exportSelector,
      corporation: corporation?.name,
      company: company?.name,
      project: project?.name,
      machine: machine?.name,
    });

    function openChartLayout(e: { preventDefault: () => void }) {
      e.preventDefault();
      updateStore({ isChartLayoutOpen: true });
    }

    ref.current.root.dom.addEventListener("contextmenu", openChartLayout);

    return () => {
      ref?.current?.root?.dom?.removeEventListener("contextmenu", openChartLayout);
      handleStartEndChanged.cancel();
      breadcrumbRoot.dispose();
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    if (!ref?.current || hideRange) return;
    if (ref.current.chart.series.length === numberOfSignals && isEqual(dataRef.current, data))
      return;

    dataRef.current = [...data];

    function resetSeries() {
      removePreviousSeries({ ref });
      // Create current series
      createSeries({
        ref,
        xAxis: getDateAxis(entityIds.xAxis),
        data,
        signals,
        numberOfSignals,
      });
    }

    resetSeries();
    updateStore({ resetSeries });
    // Update legend
    legendRef?.current?.data.setAll(ref.current.chart.series.values);

    // Set default value for range
    if (data.length > 0) {
      const lastTimestamp = getDataLastTimestamp(data[data.length - 1]);
      const firstTimestamp = getDataFirstTimestamp(data[0]);

      rangeRef.current = getRangeFormat(firstTimestamp, lastTimestamp);
    }
  }, [numberOfSignals, data[0]]);

  useEffect(() => {
    if (!ref?.current) return;

    const yAxisSettings =
      syncSettingsMode === "syncAll" ? { ...signalsMinMaxValues, strictMinMax: true } : {};

    removePreviousSeries({ ref });

    // Create current series
    createSeries({
      ref,
      xAxis: getDateAxis(entityIds.xAxis),
      data,
      signals,
      numberOfSignals,
      yAxisSettings,
      getSelectedAxisStartEnd,
    });

    // Update legend
    legendRef?.current?.data.setAll(ref.current.chart.series.values);
  }, [numberOfSignals, syncSettingsMode, signalsMinMaxValues.min, signalsMinMaxValues.max]);

  useEffect(() => {
    if (!ref?.current || machineId === "all") {
      return;
    }

    getLabel(entityIds.outOfSync).set(
      "visible",
      isOutOfSync(
        data.map(({ date_0 }) => ({ date: date_0 })),
        connectedTimeRange?.range,
      ),
    );

    if (!connectedTimeRange || connectedTimeRange.signalId === machineId) {
      return;
    }

    zoomToDates(
      getDateAxis(entityIds.xAxis),
      data.map(({ date_0 }) => ({ date: date_0 })),
      connectedTimeRange.range,
    );
  }, [connectedTimeRange]);

  useEffect(() => {
    if (!ref?.current) return;
    const xAxis = getDateAxis(entityIds.xAxis);
    if (!isEmpty(selectedTableEvent)) {
      createRange({
        value: new Date(selectedTableEvent.timeStamp).getTime(),
        color: am5.color(0x000000),
        root: ref.current.root,
        chart: ref.current.chart,
        xAxis,
      });
    } else {
      if (xAxis.axisRanges.values.length > 0) {
        xAxis.axisRanges.removeIndex(0);
      }
    }
  }, [selectedTableEvent]);

  useEffect(() => {
    updateExportButtonText({ exportId: exportSelector, translate: t });
  }, [exportSelector, t]);

  return (
    <div
      {...rest}
      style={{
        height: `calc(100% - ${chartControlsHeight} - ${breadcrumbRef?.current?.height() || 0}px`,
        ...style,
      }}
    >
      {isLoadingMultipleSignals && (
        <Spinner className='loading-chart-data' size='small' label={t("Loading data...")} />
      )}
      <div
        className={classNames("grouped-chart-container", {
          loading: isLoadingMultipleSignals,
        })}
      >
        {!hideRange && <p className='range'>{rangeRef.current}</p>}
        <XYChart
          ref={ref}
          customSettings={{
            hideYscrollbar: true,
            //hideYscrollbar: false,
          }}
          styles={{
            root: {
              height: !hideRange ? "calc(100% - 36px)" : "100%",
              minHeight: "200px",
            },
            chart: {
              height: "100%",
            },
            ...chartStyles,
          }}
          exportSelector={exportSelector}
        />
        <div id={`breadcrumb-container-${machineId}`} />
      </div>

      <ChartLayoutModal
        series={ref.current?.chart?.series?.values}
        yAxes={ref.current?.chart?.yAxes?.values}
      />
    </div>
  );
};

export default TrendViewPlot;
