import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import debounce from "lodash-es/debounce";
import type { Dispatch } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../../assets/styles";
import FilterIcon from "../../../../../assets/svg/FilterIcon";
import { DEFAULT_DEBOUNCE } from "../../../../../config/constants";
import BaseCommandBar from "../../../../common/CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "../../../../common/CommandBar/methods";
import useTasksStore from "../../hooks/useTasksStore";
import type { PageActions, PageState } from "../../pageReducer";

const getFilters: (t) => any[] = (t) => [
  { key: "all", text: t("All"), data: "all" },
  { key: "assignedtome", text: t("Assigned to me"), data: "assignedtome" },
  { key: "createdbyme", text: t("Created by me"), data: "createdbyme" },
];

const getStatues: (t) => any[] = (t) => [
  { key: "All", text: t("All"), data: "All" },
  { key: "ToDo", text: t("To do"), data: "ToDo" },
  { key: "InProgress", text: t("In progress"), data: "InProgress" },
  { key: "Cancelled", text: t("Cancelled"), data: "Cancelled" },
];

type CommandBarProps = {
  filter: PageState["filter"];
  status: PageState["status"];
  pageDispatch: Dispatch<PageActions>;
  itemsKey: "others" | "done";
  setShowDelete: (state: boolean) => void;
};

export const CommandBar = ({
  filter,
  status,
  pageDispatch,
  itemsKey,
  setShowDelete,
}: CommandBarProps) => {
  const { t } = useTranslation();
  const { selectedItems, dispatch } = useTasksStore((state) => ({
    selectedItems: state.selectedItems[itemsKey],
    dispatch: state.dispatch,
  }));

  const isCompletedTasksTable = itemsKey === "done";

  const handleSearch = debounce((value: string) => {
    pageDispatch({ type: "SET_SEARCH", payload: value?.trim() });
  }, DEFAULT_DEBOUNCE);

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, []);

  const hasDelete = !!selectedItems.length;

  const onSearch = (value: string) => handleSearch(value);

  const onAdd = () => {
    dispatch({ type: "SHOW_ADD", payload: true });
  };

  const onDelete = () => setShowDelete(true);

  const onFilter = (item: any | undefined) =>
    pageDispatch({ type: "SET_FILTER", payload: item?.data });

  const onStatus = (item: any | undefined) =>
    pageDispatch({ type: "SET_STATUS", payload: item?.data });

  const getCommandBarItems = () =>
    [
      !isCompletedTasksTable && {
        key: "new",
        text: t("New item"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        icon: <AddRegular />,
        onClick: onAdd,
      },
      {
        key: "filter",
        text: t("Filter"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: filter,
          options: getFilters(t),
          onChange: (_: never, item: { data: any }) => onFilter(item),
          onRenderLabelProps: {
            label: t("Filter"),
            icon: <FilterIcon fill='rgb(44, 82, 159)' style={dropdownIconStyle} />,
          },
        },
      },
      !isCompletedTasksTable && {
        key: "status",
        text: t("Status"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: status,
          options: getStatues(t),
          onChange: (_: never, item: { data: any }) => onStatus(item),
          onRenderLabelProps: {
            label: t("Status"),
            icon: <InfoIcon />,
          },
        },
      },
      {
        key: "Delete",
        text: t("Delete"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        disabled: !hasDelete,
        icon: <DeleteRegular />,
        onClick: onDelete,
      },
    ].filter(Boolean);

  const items = getCommandBarItems() as any;

  return <BaseCommandBar items={computeCommandBarItems(items)} onSearch={onSearch} />;
};
