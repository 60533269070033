import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import type { FC } from "react";
import { useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import BaseDialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";
import { useThreadAdd } from "../../hooks/useThreadMutations";
import { useThreadsQuery } from "../../hooks/useThreadsQuery";

type ThreadDialogProps = {
  onSuccess?: () => void;
  onDismiss?: () => void;
} & Omit<DialogProps, "children">;

const schema = z.object({
  text: z.string(),
  // message: z.string(),
});

const getThreadFields: (t) => FormItemProps[] = (t) => [
  {
    name: "text",
    type: FormItemType.TextField,
    placeholder: t("Thread title"),
    groupProps: { label: t("Title") },
  },
];

export const ThreadDialog: FC<ThreadDialogProps> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    schema,
    defaultValues: {
      text: "",
    },
  });

  const [{ id }] = useLocationSearch();
  const { addThreadAsync } = useThreadAdd();
  const { refetch } = useThreadsQuery({ machineId: id as string });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const submitData = {
      ...data,
      text: data.text,
      machineId: id || "",
    };
    await addThreadAsync(submitData)
      .then(() => {
        refetch();
        onClose();
        reset();
        notification.success(t("Thread created successfully"));
      })
      .catch(() => {
        notification.error(t("Error creating thread"));
      })
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog {...props}>
        <DialogTitle>{t("New thread")}</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            {renderFormItems(getThreadFields(t), {
              control,
              errors: errors as { [schemaProp: string]: FieldError },
            })}
            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {t("Create")}
              </Button>
              <Button appearance='transparent' onClick={onClose}>
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
    </>
  );
};
