import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  container: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },

  machineContainer: {
    backgroundColor: "#faf9f8",
    borderRadius: "6px",
    margin: "8px 0",
    overflow: "hidden",
    transition: "all 0.3s ease",
    ":hover": {
      backgroundColor: "#edebe9",
    },
  },

  machineHeader: {
    padding: "12px 16px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderBottom: `1px solid #edebe9`,
  },

  sensorContainer: {
    padding: "8px 16px 8px 32px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    ":hover": {
      backgroundColor: "#f3f2f1",
    },
  },

  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    padding: "0 0 16px 0",
    borderBottom: `1px solid #edebe9`,
  },

  selectionControls: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
});
