import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../../../../Components/common/Dialog";
import { notification } from "../../../../Components/common/Notification";
import { getApiClient, validateStatus } from "../../../core/apiClient/useApiStore";

export type DeleteItemDataProps = {
  name: string;
  ids: string[];
};

type DeleteDialogProps = Omit<DialogProps, "children"> & {
  route: string;
  onSuccess?: () => void;
  onClose?: () => void;
  confirmText?: string;
  closeText?: string;
  data: DeleteItemDataProps;
  open: boolean;
  onDismiss?: () => void;
};

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  confirmText,
  closeText,
  onClose,
  route,
  onSuccess,
  data,
  ...rest
}) => {
  const { ids, name } = data;
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync } = useMutation((ids: string[]) =>
    Promise.allSettled(
      ids.map((id) => getApiClient().delete(`${route}/${id}`, { validateStatus })),
    ),
  );

  const handleMutateAsync = async () => {
    setLoading(true);

    const settled = await mutateAsync(ids);

    const errors = settled.filter((response) => {
      if (response.status === "rejected") {
        return true;
      }

      return response?.value?.status < 200 || response?.value?.status >= 300;
    });

    if (errors.length === 0) {
      onSuccess?.();
      notification.success(t(`{{name}} deleted successfully`, { name }));
    } else {
      if (errors.length === ids.length) {
        notification.error(
          t(`Failed deleting {{multipleIds}} {{name}}`, {
            name,
            multipleIds: ids.length > 1 ? ids.length : "",
          }),
        );
      } else {
        onSuccess?.();
        notification.error(
          t(`Failed deleting {{errorNumber}} out of {{idNumber}} {{name}}`, {
            errorNumber: errors.length,
            idNumber: ids.length,
            name,
          }),
        );
      }
    }

    rest?.onDismiss?.();
    setLoading(false);
  };

  const onDeleteClick = async () => {
    await handleMutateAsync();
  };

  const onCloseClick = () => {
    onClose?.();
    rest?.onDismiss?.();
  };

  return (
    <BaseDialog {...rest} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onCloseClick}>
      <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>
      <DialogContent>
        <Text>
          {t("You are about to delete")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {ids.length > 1 ? `${ids.length} ${name}` : name}
          </span>
        </Text>
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onDeleteClick}
          >
            {confirmText || t("Delete")}
          </Button>
          <Button onClick={onCloseClick}>{closeText || t("Cancel")}</Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DeleteDialog;
