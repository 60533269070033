import { Text, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { ClipboardListIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkA } from "react-router-dom";

import { iconStyle, linkStyle, pageStyle, titleStyle } from "../../schema/Constants";
import { format } from "../../schema/Utils";
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../common/CommandBar";
import { notification } from "../common/Notification";
import type { Column } from "../common/Table/v9";
import Table, { useTableFilters } from "../common/Table/v9";
import DeleteDialog from "../Generic/DeleteDialog";
import { useViewport } from "../Layout/Content";
import { removeSubmissions, submissionsList } from "./api";
import { LogSubmissionsList } from "./LogSubmissionsList";
import type { SubmissionForList } from "./models";

// --- Columns ---
type GetColumnsOpts = {
  t: any;
  onDelete: (submission: SubmissionForList) => void;
};

const getColumns = ({ t, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "sID",
      name: t("SID"),
      fieldName: "sID",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sID }: SubmissionForList) => (
        <LinkA to={id} style={linkStyle}>
          {sID}
        </LinkA>
      ),
    },
    {
      key: "createdAt",
      name: t("Created At"),
      fieldName: "createdAt",
      minWidth: 200,
      isSortable: true,
      onRender: (item: SubmissionForList) =>
        item?.createdAt ? format(new Date(item.createdAt)) : "",
    },
    {
      key: "createdBy",
      name: t("Created By"),
      fieldName: "createdBy",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (item: SubmissionForList) => (
        <div style={{ display: "flex" }}>
          <Tooltip withArrow relationship='label' content={t("Delete")}>
            <span>
              <DeleteRegular
                style={iconStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(item);
                }}
              />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return columns;
};

export const SubmissionsList = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { bigScreen } = useViewport();
  const [items, setItems] = useState<SubmissionForList[]>([]);
  const { filters, handleSearch } = useTableFilters<SubmissionForList>({
    keys: ["sID", "createdBy"],
  });
  const [showLogs, setShowLogs] = useState(false);

  const [selected, setSelected] = useState<
    | {
        data: SubmissionForList;
        context: "delete";
      }
    | undefined
  >();

  useEffect(() => {
    setLoading(true);
    submissionsList()
      .then((list) => {
        setItems(list);
        setLoading(false);
      })
      .catch((event) => {
        setItems([]);
        notification.error(event);
      });

    return;
  }, []);

  const getCommandBarItems = (title: string): any => [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{title}</Text>,
    },
    {
      key: "log",
      text: t("Log"),
      type: CommandBarItemType.Button,
      icon: <ClipboardListIcon />,
      onClick: () => setShowLogs(true),
    },
  ];

  const onDelete = (submission: SubmissionForList) =>
    setSelected({ data: submission, context: "delete" });

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItems(t("Submissions")))}
        onSearch={handleSearch}
      />

      <Table
        persistOpts={{
          key: "table-submissions",
          version: 2,
        }}
        header={{
          title: t("Submissions"),
        }}
        items={items}
        v8Columns={getColumns({ t, onDelete })}
        filters={filters}
        isLoading={isLoading}
        isError={false}
        perPage={bigScreen ? 20 : 10}
      />

      <LogSubmissionsList show={showLogs} onClose={() => setShowLogs(false)} />

      {selected?.data && (
        <DeleteDialog
          open={selected?.context === "delete"}
          id={selected.data.id}
          identifier={"Submission Entry"}
          propertyName={"sID"}
          referenceString={selected.data.sID}
          isLoading={isLoading}
          onDelete={() => {
            removeSubmissions(selected.data.id).then((resp) => {
              setSelected(undefined);
              if ("data" in resp) {
                notification.success(t("Submission deleted"));
                setLoading(true);
                submissionsList()
                  .then((list) => {
                    setItems(list);
                    setLoading(false);
                  })
                  .catch((event) => {
                    setItems([]);
                    notification.error(event);
                  });
              } else notification.error(t("Issue deleting submission."));
            });
          }}
          onClose={() => setSelected(undefined)}
        />
      )}
    </div>
  );
};
