import { Button, Label } from "@fluentui/react-components";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Icon from "../CustomIcons";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { Stack } from "../Stack";

interface items404WithPermission {
  key: string;
  icon: any;
  textField: string;
  url: string;
  canI: boolean;
}

export const NotFoundRoute: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const auth = useContext(authContext);
  const navigate = useNavigate();

  const iconsAll: items404WithPermission[] = [
    {
      key: "trend-view",
      textField: t("Trend View"),
      icon: <Icon name='TrendView' width='67' height='40' viewBox='0 0 20 20' />,
      url: "trend-view",
      canI: auth.measuredDataReader,
    },
    {
      key: "raw-data",
      textField: t("Raw View"),
      icon: <Icon name='RawView' width='67' height='40' viewBox='0 0 20 20' />,
      url: "raw-data",
      canI: auth.measuredDataReader,
    },
    {
      key: "machine-cv",
      textField: t("Machine CV"),
      icon: <Icon name='MachineCV' width='67' height='40' viewBox='0 0 20 20' />,
      url: "machine-cv",
      canI: auth.machineCvReader,
    },
  ];

  return (
    <Stack verticalFill horizontalAlign='center' verticalAlign='center'>
      <Stack horizontalAlign='center' style={{ gap: 10, textAlign: "center" }}>
        <Stack
          style={{
            fontSize: "42px",
            fontFamily: "Open Sans",
            lineHeight: "52px",
            color: "rgb(24, 46, 90)",
            fontWeight: 600,
          }}
        >
          404
        </Stack>
        <Stack
          style={{
            fontSize: "20px",
            fontFamily: "Open Sans",
            lineHeight: "28px",
            color: "rgb(24, 46, 90)",
            fontWeight: 600,
          }}
        >
          {t("Amazing! You found the unfoundable.")}
        </Stack>

        <Stack
          style={{
            fontSize: "14px",
            fontFamily: "Open Sans",
            color: "#000",
            fontWeight: "400",
            width: "452px",
          }}
        >
          {t(
            "Sadly you can’t do much here, but can find other useful information on the links below or just go on homepage.",
          )}
        </Stack>

        <Button style={{ marginTop: "15px" }} appearance='primary' onClick={() => navigate("")}>
          {t("Take me Home")}
        </Button>
      </Stack>

      <Stack style={{ marginTop: "100px" }}>
        <Label
          style={{
            fontSize: "12px",
            fontFamily: "Open Sans",
            lineHeight: "16px",
            color: "#000",
            fontWeight: "600",
            paddingBottom: 5,
          }}
        >
          {t("Quick links")}
        </Label>
        <Stack horizontal>
          {iconsAll
            .filter((ic) => ic.canI === true)
            .map((ico) => (
              <div key={ico.key}>
                <Stack
                  verticalFill
                  style={{
                    textAlign: "center",
                    paddingRight: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(ico.url, { replace: false })}
                >
                  {ico.icon}
                  <Label
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#000",
                      fontWeight: "450",
                    }}
                  >
                    {ico.textField}
                  </Label>
                </Stack>
              </div>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
