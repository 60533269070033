import {
  Button,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SearchBox,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import type { CSSProperties } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";

import FastTrendSignalIcon from "../../../../assets/svg/FastTrendSignalIcon";
import RawDataSignalIcon from "../../../../assets/svg/RawDataSignalIcon";
import TrendSignalIcon from "../../../../assets/svg/TrendSignalIcon";
import DataTypeEnum from "../../constants/DataTypeEnum";

const filteredItemsStyle: CSSProperties = {
  width: "100%",
  height: 164,
  minWidth: 250,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const textEllipsis: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: 280,
};

export type SignalItem = {
  id: string;
  name: string;
  label: string;
  icon: DataTypeEnum;
};

const createMenuItems = (
  t: any,
  items: SignalItem[],
  hasNoneItem: boolean,
  onSelect: (item: Omit<SignalItem, "icon"> | undefined) => void,
): any[] => {
  if (items.length === 0) {
    return [];
  }

  const menuItems = items.map((child) => ({
    key: child.id,
    data: child.name,
    text: child.label,
    onClick: (
      ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
      { key, data, text }: any,
    ) => {
      ev.preventDefault();
      onSelect({
        id: key,
        name: data,
        label: text as string,
      });
    },
    onRenderIcon: () => {
      switch (child.icon) {
        case DataTypeEnum.Trend:
          return <TrendSignalIcon />;
        case DataTypeEnum.FastTrend:
          return <FastTrendSignalIcon />;
        case DataTypeEnum.RawData:
          return <RawDataSignalIcon />;
        default:
          return null;
      }
    },
  }));

  if (hasNoneItem) {
    return [
      {
        key: "none",
        text: t("None"),
        onClick: () => onSelect(undefined),
      },
      ...menuItems,
    ];
  }

  return menuItems;
};

type SignalsAutocompleteProps = any & {
  selectedItems?: any;
  items: SignalItem[];
  hasNoneItem?: boolean;
  onSelectSignal: (item: Omit<SignalItem, "icon"> | undefined) => void;
  buttonProps?: any;
};

const SignalsAutocomplete = ({
  items: _items,
  selectedItems,
  hasNoneItem = false,
  onSelectSignal,
  buttonProps = {},
  ...rest
}: SignalsAutocompleteProps) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [items, setItems] = useState(() => createMenuItems(t, _items, hasNoneItem, onSelectSignal));

  const setInitial = useCallback(
    () => setItems(createMenuItems(t, _items, hasNoneItem, onSelectSignal)),
    [t],
  );

  const onChange = useCallback((newValue?: string) => {
    if (!newValue || newValue.trim().length === 0) {
      setInitial();
      return;
    }

    const trimmedValue = newValue?.trim().toLowerCase();

    const filteredItems = createMenuItems(t, _items, false, onSelectSignal).filter(
      ({ data, text }) =>
        data?.toLowerCase().indexOf(trimmedValue) !== -1 ||
        text?.toLowerCase().indexOf(trimmedValue) !== -1,
    );

    if (!filteredItems || !filteredItems.length) {
      filteredItems.push({
        key: "no_results",
        onRender: () => (
          <div key='no_results' style={filteredItemsStyle}>
            <span>{t("No signals found")}</span>
          </div>
        ),
      });
    }

    setItems(filteredItems);
  }, []);

  const { text = "" } = buttonProps;

  return (
    <Menu
      open={openMenu}
      onOpenChange={(e, data) => {
        e.stopPropagation();
        setOpenMenu(data.open);
        setInitial();
      }}
    >
      <MenuTrigger>
        <Button
          appearance='transparent'
          icon={rest?.onRenderMenuIcon ? rest?.onRenderMenuIcon() : null}
          disabled={!items.length}
          {...buttonProps}
        >
          {text}
        </Button>
      </MenuTrigger>

      <MenuPopover style={{ minWidth: 350 }} onClick={(e) => e.stopPropagation()}>
        <MenuList style={{ padding: 8 }} onClick={(e) => e.stopPropagation()}>
          <SearchBox
            aria-label={t("Search")}
            placeholder={t("Search")}
            style={{ width: "100%" }}
            onClick={(e) => e.stopPropagation()}
            onAbort={setInitial}
            onChange={(_, data) => onChange(data.value)}
          />

          <FixedSizeList
            height={165}
            itemCount={items.length}
            itemSize={33}
            width='100%'
            className='signals-autocomplete-list'
          >
            {({ index, style }) => {
              const { key, text, data, onClick, onRenderIcon, onRender } = items[index];

              if (key === "no_results") {
                return onRender();
              }

              return (
                <div
                  style={style}
                  className={
                    selectedItems?.filter((item: any) => item.id === key)[0] ? "selected" : ""
                  }
                >
                  <Tooltip relationship='label' content={text}>
                    <Button
                      appearance='transparent'
                      icon={onRenderIcon ? onRenderIcon() : null}
                      style={{ padding: "5px 0px" }}
                      onClick={(e) => {
                        onClick(e, { key, data, text });
                      }}
                    >
                      <Text style={textEllipsis}>{text}</Text>
                    </Button>
                  </Tooltip>
                </div>
              );
            }}
          </FixedSizeList>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default SignalsAutocomplete;
