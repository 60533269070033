import "./styles.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch, useSearch } from "../../../Hooks";
import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import type { Report } from "../../../types";
import Table from "../../common/Table/v9/Table";
import { TaskAddDialog } from "../MachineCVTasksPage/components/TaskAdd";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import { ReportDocumentDialog } from "./components/ReportDocumentDialog";
import { useReportsQuery } from "./hooks/useReportsQuery";
import type { UserActionState } from "./models";
import { UserActions } from "./models";

type ActionDialogProps = {
  state: UserActionState;
  onClose: (refresh: boolean) => void;
};

const ActionDialog = ({ state, onClose }: ActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (state.action) {
    case UserActions.ReportAdd:
    case UserActions.ReportEdit:
      result = <ReportDocumentDialog report={state.report} onClose={onClose} />;
      break;
    case UserActions.ReportDelete:
      if (!state.report) {
        break;
      }

      result = (
        <DeleteDialog
          open={true}
          data={{
            ids: [state.report.id],
            name: "1 report",
          }}
          route={"/meta/machinecv/v1/logEntries"}
          onDismiss={() => onClose?.(false)}
          onSuccess={() => onClose?.(true)}
        />
      );
      break;
  }

  return result;
};

const Page = () => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const [state, setState] = useState<UserActionState>({
    action: UserActions.None,
  });
  const { reports, isError, isLoading, refetch } = useReportsQuery({
    machineId: id as string,
  });
  const filteredReports = reports?.filter((item) => item.category !== "Alert") || [];

  const { data, handleSearch } = useSearch<Report>({
    data: filteredReports,
    keys: ["text"],
  });

  // Handlers
  const onClose = (refresh: boolean) => {
    setState({ action: UserActions.None });
    refresh && refetch();
  };

  return (
    <div className='machine-cv-reports-table'>
      <CommandBar
        handleSearch={handleSearch}
        onAdd={() => setState({ action: UserActions.ReportAdd })}
      />
      <Table
        persistOpts={{
          key: "table-reports",
          version: 1,
        }}
        header={{
          title: t("Reports"),
        }}
        items={data}
        isLoading={isLoading}
        isError={isError}
        v8Columns={getColumns({
          t,
          onEdit: (report) => setState({ action: UserActions.ReportEdit, report }),
          onDelete: (report) => setState({ action: UserActions.ReportDelete, report }),
          onAddTask: (report) => setState({ action: UserActions.TaskAdd, report }),
        })}
      />
      <ActionDialog state={state} onClose={onClose} />
      <TaskAddDialog
        open={!(state.action !== UserActions.TaskAdd)}
        description={state.report?.text ?? ""}
        onDismiss={() => onClose?.(false)}
      />
    </div>
  );
};

export default Page;
