import type { RadioGroupProps } from "@fluentui/react-components";
import { Radio, RadioGroup } from "@fluentui/react-components";

export type ChoiceGroupProps = RadioGroupProps & {
  options: any[];
  renderRadioOption?: any;
};

export function defaultRenderRadioOption({ key, text, required = false }) {
  return <Radio key={key} value={key} label={text} required={required} />;
}

export default function ChoiceGroup({
  options,
  renderRadioOption = defaultRenderRadioOption,
  ...rest
}: ChoiceGroupProps) {
  return <RadioGroup {...rest}>{options.map(renderRadioOption)}</RadioGroup>;
}
