import saveAs from "file-saver";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { notification } from "../common/Notification";
import type { AddDocumentBase, UploadedFile } from "../Generic/AddDocumentDialogOneFile";
import AddDocumentDialogOneFile from "../Generic/AddDocumentDialogOneFile";
import { D850CFGConvert } from "../Machines/api";
import type {
  D325TableItem,
  D850EcoTableItem,
  D850TableItem,
  DBasicTableItem,
} from "./tableItemModels";

type D850CfgDialogProps = {
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  onClose: () => void;
};

/**
 * Gets the D850 config upload dialog.
 * @param tableItem The table item.
 * @param onClose Function called when the dialog needs to be closed. It must be implemented.
 * @returns The Upload dialog component.
 */
const D850CfgUploadDialog = ({ tableItem, onClose }: D850CfgDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (files: UploadedFile<AddDocumentBase>[]) => {
    setIsLoading(true);
    // Sends request.
    D850CFGConvert(tableItem.machineIds[0], files.at(0).file).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        saveAs(new Blob([response.data], { type: "application/vnd.sqlite3" }), `signal.db`);

        notification.success(t("Success: Converting xlsx file to SQLite CFG for D-850 Linux box."));

        onClose?.();
        return;
      }

      notification.error(
        t(
          "Failure: Converting xlsx file to SQLite CFG for D-850 Linux box. Please try again later.",
        ),
      );
    });
  };

  return (
    <AddDocumentDialogOneFile
      hidden={false}
      isSubmitting={isLoading}
      extension={".xlsx"}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default D850CfgUploadDialog;
