// Original path: src/src/Hooks/useFile.ts
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { notification } from "../Components/common/Notification";
import type { UploadedFile } from "../Components/common/Uploader/types";
import { getApiClient, validateStatus } from "../modules/core/apiClient/useApiStore";

type FileUploadProps<T> = {
  machineId: string;
  files: UploadedFile<T>[];
};

export const getFileIds = (response: PromiseSettledResult<any>[]) => {
  return (
    response.filter(({ status }) => status === "fulfilled") as PromiseFulfilledResult<any>[]
  ).map(({ value }) => value.data.id);
};

export const useFileUpload = <T>() => {
  const { t } = useTranslation();

  const { mutateAsync } = useMutation(({ machineId, files }: FileUploadProps<T>) =>
    Promise.allSettled(
      files
        .filter(({ isValid }) => isValid)
        .map((item) =>
          getApiClient().post(
            "/files/v1/files",
            {
              machineId,
              ...item,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          ),
        ),
    ),
  );

  const handleFileUploadAsync = async ({ machineId, files }: FileUploadProps<T>) => {
    const settled = await mutateAsync({ machineId, files });

    const errors = settled.filter(({ status }) => status === "rejected");

    if (errors.length === 0) {
      notification.success(t(`Files uploaded successfully`));
    } else {
      if (errors.length === files.length) {
        notification.error(t(`Failed uploading files`));
      } else {
        notification.error(
          t(`Failed uploading {{failedFiles}} out of {{totalFiles}} files`, {
            failedFiles: errors.length,
            totalFiles: files.length,
          }),
        );
      }
    }
  };

  return {
    uploadFilesAsync: mutateAsync,
    uploadFilesAsyncWithNotifications: handleFileUploadAsync,
  };
};

export const useFileDelete = () => {
  const { t } = useTranslation();
  const { mutateAsync } = useMutation((ids: string[]) =>
    Promise.allSettled(
      ids.map((id) => getApiClient().delete(`/files/v1/files/${id}`, { validateStatus })),
    ),
  );

  const handleFileDeleteAsync = async (ids: string[]) => {
    const settled = await mutateAsync(ids);

    const errors = settled.filter(({ status }) => status === "rejected");

    if (errors.length === 0) {
      notification.success(t(`Files deleted successfully`));
    } else {
      if (errors.length === ids.length) {
        notification.error(t(`Failed deleting files`));
      } else {
        notification.error(
          t(`Failed deleting {{failedFiles}} out of {{totalFiles}} files`, {
            failedFiles: errors.length,
            totalFiles: ids.length,
          }),
        );
      }
    }
  };

  return {
    deleteFilesAsync: mutateAsync,
    deleteFilesAsyncWithNotifications: handleFileDeleteAsync,
  };
};

export type FileData = {
  id: string;
  name: string;
};

export type GetFileProps = {
  id: string;
};

export const useFileDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { showDownloadDialog, setShowDownloadDialog } = useFileDownloadDialog((state) => ({
    setShowDownloadDialog: state.setShowDownloadDialog,
    showDownloadDialog: state.showDownloadDialog,
  }));

  const getFile = ({ id }: GetFileProps) =>
    getApiClient().get(`files/v1/files/${id}`, { responseType: "blob" });

  const download = async ({ id, name }: FileData) =>
    await getFile({ id })
      .then(({ data }) => {
        saveAs(data, name);
      })
      .catch((err) => {
        console.error(err);
      });

  const downloadFile = async ({ id, name }: FileData) => {
    setIsLoading(true);
    setShowDownloadDialog(true);
    await download({ id, name }).finally(() => {
      setIsLoading(false);
      setShowDownloadDialog(false);
    });
  };

  const downloadMultipleFiles = async (files: FileData[]) => {
    setIsLoading(true);
    setShowDownloadDialog(true);
    await Promise.allSettled(files.map(({ id, name }: FileData) => download({ id, name }))).finally(
      () => {
        setIsLoading(false);
        setShowDownloadDialog(false);
      },
    );
  };

  return {
    getFile,
    downloadFile,
    downloadMultipleFiles,
    isLoading,
    showDownloadDialog,
  };
};

export const useFileDownloadDialog = createWithEqualityFn<{
  showDownloadDialog: boolean;
  setShowDownloadDialog: (show: boolean) => void;
}>()(
  (set) => ({
    showDownloadDialog: false,
    setShowDownloadDialog: (show: boolean) =>
      set(() => ({
        showDownloadDialog: show,
      })),
  }),
  shallow,
);
