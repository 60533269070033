import { AddRegular } from "@fluentui/react-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../assets/styles";
import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import useWebAnalysisPermissions from "../../../../Hooks/useWebAnalysisPermissions";
import type { Group } from "../../../../types";
import BaseCommandBar from "../../../common/CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "../../../common/CommandBar/methods";
import useInfoStore from "../hooks/useInfoStore";
import GroupDialog from "./dialogs/GroupDialog";

const getMenuItems = (t) => [
  {
    key: "0",
    text: t("Auto align"),
    style: {},
  },
  {
    key: "1",
    text: t("Single item per row"),
    style: {},
  },
  {
    key: "2",
    text: t("Two items per row"),
    style: {},
  },
  {
    key: "3",
    text: t("Three items per row"),
    style: {},
  },
];

export enum FormType {
  Edit = "Edit",
  New = "New",
}

type InfosCommandBarProps = {
  handleSearch?: (value: string) => void;
  allGroups: Group[];
};

const CommandBar = ({ handleSearch, allGroups }: InfosCommandBarProps) => {
  const { t } = useTranslation();
  const { perRow, dispatch } = useInfoStore((state) => ({
    perRow: state.perRow,
    dispatch: state.dispatch,
  }));

  const [isAddGroupDialogHidden, setIsAddGroupDialogHidden] = useState(true);
  const toggleIsAddGroupDialogHidden = () => setIsAddGroupDialogHidden(!isAddGroupDialogHidden);

  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  function getItems(hasMachineCvAdministratorPermission: boolean): any {
    const baseItems: any = [
      {
        key: "layout",
        text: t("Layout"),
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: `${perRow}`,
          options: getMenuItems(t),
          onChange: (_: never, item: { data: string }) =>
            dispatch({
              type: "SET_PER_ROW",
              payload: parseInt((item?.data as string) || "0"),
            }),
          onRenderLabelProps: {
            label: t("Layout"),
            icon: <LayoutIcon fill='rgb(44, 82, 159)' style={dropdownIconStyle} />,
          },
        },
      },
    ];

    if (hasMachineCvAdministratorPermission) {
      const newGroupItem: any = {
        key: "new",
        text: t("New group"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        icon: <AddRegular />,
        onClick: toggleIsAddGroupDialogHidden,
      };

      return [newGroupItem, ...baseItems];
    }

    return baseItems;
  }

  const items = getItems(hasMachineCvAdministratorPermission);

  return (
    <>
      <BaseCommandBar items={computeCommandBarItems(items)} onSearch={handleSearch} />
      <GroupDialog
        open={!isAddGroupDialogHidden}
        formType={FormType.New}
        allGroups={allGroups}
        onDismiss={toggleIsAddGroupDialogHidden}
      />
    </>
  );
};

export default CommandBar;
