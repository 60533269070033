import { Button, DialogActions, DialogContent, Spinner, Tooltip } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { axiosInstance } from "../..";
import { formatFileSize } from "../../schema/Utils";
import type { ObjectWithKey } from "../../types";
import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";
import { FilePreview } from "../common/Preview";
import type { Column, TableProps } from "../common/Table/v9";
import Table from "../common/Table/v9";
import { addBulkFiles, getFilesOfMachine } from "../FIles/api";
import type { CreateFile, File } from "../FIles/models";
import AddDocumentDialog from "./AddDocumentDialog";
import { NoFilesToSelect } from "./NoFilesToSelect";

type GetColumnsOpts = {
  t: any;
  idSelected: string;
  onSelected: (id: string) => void;
};

const getColumns = ({ t, idSelected, onSelected }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (file) => {
      return (
        <FilePreview
          axiosInstance={axiosInstance}
          file={{
            name: file.name,
            id: file.id,
          }}
        />
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    //isSortable: true,
    onRender: ({ sizeInBytes }: File) => {
      return (
        <span style={{ fontSize: 13, color: "currentColor" }}>{formatFileSize(sizeInBytes)}</span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 150,
    onRender: ({ id }) => (
      <div key={id + "actions"} style={{ display: "flex" }}>
        {onSelected && (
          <Tooltip
            key={id + "actions2"}
            withArrow
            relationship='label'
            content={t("Select as Icon")}
          >
            <Button
              appearance={idSelected === id ? "primary" : "outline"}
              onClick={(e) => {
                e.stopPropagation();
                onSelected(id);
              }}
            >
              {t("Selected Icon")}
            </Button>
          </Tooltip>
        )}
      </div>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type SelectFilesOfMachineDialogProps = {
  machineId: string;
  iconId: string;
  hidden: boolean;
  onClose: () => void;
  onSubmit: (id: string) => void;
};

type ItemsSelected = File & ObjectWithKey;

const SelectFileIconOfMachine = ({
  machineId,
  iconId,
  hidden,
  onClose,
  onSubmit,
}: SelectFilesOfMachineDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<ItemsSelected[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>(iconId);
  const [showAddMoreFiles, setShowAddMoreFiles] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    refreshFiles(machineId, setLocalFiles, iconId, isMounted);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-files-dialog-machine",
        version: 1,
      },
      items: localFiles,
      v8Columns: getColumns({
        t,
        idSelected: selectedId,
        onSelected: (fileId) => {
          setSelectedId(fileId);
        },
      }),
    }),
    [localFiles, selectedId, t],
  );

  return (
    <>
      <BaseDialog
        open={!hidden}
        surfaceStyle={{ width: DialogSize.L }}
        onOpenChange={() => {
          onClose();
          setSelectedId(undefined);
        }}
      >
        <BaseDialogTitle>{t("Choose Icon from Files")}</BaseDialogTitle>
        <DialogContent>
          {!localFiles ? (
            <Spinner size='small' />
          ) : localFiles.length > 0 ? (
            <>
              <Table {...tableProps} />
              <Button
                onClick={() => {
                  setShowAddMoreFiles(true);
                }}
              >
                {t("Add more image files")}
              </Button>
              <DialogActions>
                <Button
                  appearance='primary'
                  disabled={selectedId === undefined || selectedId === null}
                  icon={isSubmitting ? <Spinner size='extra-tiny' /> : null}
                  onClick={() => {
                    setIsSubmitting(true);
                    onSubmit(selectedId);
                    setSelectedId(undefined);
                    onClose();
                    setIsSubmitting(false);
                  }}
                >
                  {t("Assign Machine Icon")}
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    setSelectedId(undefined);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </DialogActions>
            </>
          ) : (
            <NoFilesToSelect
              machineId={machineId}
              onClose={() => onClose()}
              onSubmit={() => refreshFiles(machineId, setLocalFiles, iconId, true)}
            />
          )}
        </DialogContent>
      </BaseDialog>
      {showAddMoreFiles && (
        <AddDocumentDialog
          hidden={!showAddMoreFiles}
          accept={".jpg,.jpeg,.png"}
          onClose={() => setShowAddMoreFiles(false)}
          onSubmit={(data) => {
            const filesToCreate: CreateFile[] = data.map((ele) => {
              return {
                description: ele.description,
                file: ele.file,
                machineId: machineId,
                name: ele.name,
              };
            });
            addBulkFiles(filesToCreate)
              .then((resp) => console.log({ resp }))
              .finally(() => refreshFiles(machineId, setLocalFiles, iconId, true));
          }}
        />
      )}
    </>
  );
};

export default SelectFileIconOfMachine;

function refreshFiles(
  machineId: string,
  setLocalFiles: React.Dispatch<React.SetStateAction<ItemsSelected[]>>,
  iconId: string,
  isMounted: boolean,
) {
  getFilesOfMachine(machineId).then((resp: File[]) => {
    isMounted &&
      setLocalFiles(
        resp
          .filter((ele) => ele.type.split("/").at(0) === "image")
          .sort((a, b) => (iconId === a.id ? -1 : iconId === b.id ? 1 : a.name > b.name ? 1 : -1)),
      );
  });
}
