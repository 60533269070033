import { SearchBox, Text } from "@fluentui/react-components";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { dropdownIconStyle } from "../../../../assets/styles";
import ColumnsIcon from "../../../../assets/svg/ColumnsIcon";
import FilterIcon from "../../../../assets/svg/FilterIcon";
import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import { DEFAULT_DEBOUNCE } from "../../../../config/constants";
import type { Condition } from "../../../../modules/machine-cv/constants";
import { getConditionFormat } from "../../../../modules/machine-cv/constants";
import BaseCommandBar from "../../../common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
  onRenderLabel,
} from "../../../common/CommandBar/methods";
import type { Layout, SearchBy } from "../hooks/useOverviewStore";
import useOverviewStore from "../hooks/useOverviewStore";

const getLayoutOptions = (t) => [
  { key: "detailed", text: t("Detailed view"), data: "detailed" },
  { key: "compact", text: t("Compact view"), data: "compact" },
];

const getConditionOptions = (t) => [
  { key: "All", text: t("All"), data: "All" },
  { key: "Ok", text: t("Ok"), data: "Ok" },
  { key: "Indication", text: t("Indication"), data: "Indication" },
  { key: "Alert", text: t("Alert"), data: "Alert" },
  { key: "Danger", text: t("Danger"), data: "Danger" },
  {
    key: "NotInstalled",
    text: getConditionFormat(t)["NotInstalled"],
    data: "NotInstalled",
  },
  {
    key: "NoStatus",
    text: getConditionFormat(t)["NoStatus"],
    data: "NoStatus",
  },
  { key: "Faulty", text: t("Faulty"), data: "Faulty" },
];

const getColumnOptions = (t) => [
  { key: "dalogId", text: t("Dalog Id"), data: "dalogId" },
  { key: "machineName", text: t("Machine"), data: "machineName" },
  { key: "customerCode", text: t("Code"), data: "customerCode" },
  { key: "conditionName", text: t("Condition"), data: "conditionName" },
  {
    key: "conditionComment",
    text: t("Condition Comment"),
    data: "conditionComment",
  },
  { key: "activeTasks", text: t("Active Tasks"), data: "activeTasks" },
  {
    key: "taskDescription",
    text: t("Task Description"),
    data: "taskDescription",
  },
  { key: "taskResponsible", text: t("Responsible"), data: "taskResponsible" },
  { key: "taskDueDate", text: t("Next due date"), data: "taskDueDate" },
];

const getSearchByOptions = (t) => [
  { key: "machine", text: t("Machine"), data: "machine" },
  { key: "project", text: t("Project"), data: "project" },
  { key: "company", text: t("Company"), data: "company" },
  { key: "corporation", text: t("Corporation"), data: "corporation" },
];

export const dropdownStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    selectors: {
      ":hover": {
        backgroundColor: "rgb(243, 242, 241)",
        color: "rgb(32, 31, 30)",
      },
    },
  },
  dropdown: {
    width: "auto",
  },
  title: {
    lineHeight: 32,
    fontWeight: 600,
  },
};

const searchByDropdownStyles = {
  root: {
    backgroundColor: "#2c529f !important",
    borderRadius: "2px 0px 0px 2px",
  },
  title: {
    color: "white !important",
    lineHeight: 32,
    fontWeight: 600,
  },
  caretDown: {
    color: "white !important",
  },
};

const CommandBar = () => {
  const { search, changeSearch, condition, changeCondition, searchBy, changeSearchBy } =
    useOverviewStore();
  const { t } = useTranslation();

  const { layout, columns, changeLayout, changeColumns } = useOverviewStore((state) => ({
    layout: state.layout,
    columns: state.columns,
    changeLayout: state.changeLayout,
    changeColumns: state.changeColumns,
  }));

  const handleSearch = debounce((value: string) => {
    changeSearch(value.trim());
  }, DEFAULT_DEBOUNCE);

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, []);

  const items: any = [
    {
      key: "logo",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <Text
          style={{
            fontSize: 18,
            fontWeight: 600,
            borderRight: "1px solid #EDEBE9",
            paddingRight: 32,
            marginRight: 32,
          }}
        >
          {t("Machine CV")}
        </Text>
      ),
    },
    {
      key: "layout",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: layout,
        styles: dropdownStyles,
        options: getLayoutOptions(t),
        onChange: (_: never, option: { data: string }) => changeLayout(option?.data as Layout),
        onRenderLabelProps: {
          label: t("Layout"),
          icon: <LayoutIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "condition",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: condition,
        options: getConditionOptions(t),
        onChange: (_: never, option: { data: string }) =>
          changeCondition(option?.data as Condition),
        onRenderLabelProps: {
          label: t("Filter by condition"),
          icon: <FilterIcon fill='#2c529f' style={dropdownIconStyle} />,
        },
      },
    },
    ...(layout === "compact"
      ? [
          {
            key: "columns",
            className: "secondary-dropdown",
            type: CommandBarItemType.Dropdown,
            onRenderProps: {
              multiselect: true,
              defaultSelectedKey: columns,
              options: getColumnOptions(t),
              onChange: (_, data) => {
                changeColumns(data.selectedOptions);
              },
              onRenderLabelProps: () =>
                onRenderLabel({
                  label: t("Columns"),
                  icon: <ColumnsIcon fill='#2c529f' style={dropdownIconStyle} />,
                }),
            },
          },
        ]
      : []),
  ];

  const farItems = [
    {
      key: "search-by",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: searchBy,
        options: getSearchByOptions(t),
        styles: searchByDropdownStyles,
        onChange: (_: never, option: { data: SearchBy }) => changeSearchBy(option.data),
      },
    },
    {
      key: "search",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <SearchBox
          placeholder={`${t("Search by")} ${t(searchBy)}`}
          defaultValue={search}
          style={{ width: 300, maxWidth: 300, minWidth: 300 }}
          onChange={(_, { value: newValue }) => handleSearch(newValue ?? "")}
        />
      ),
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      farItems={computeCommandBarItems(farItems)}
    />
  );
};

export default CommandBar;
