import { AddRegular, TagRegular } from "@fluentui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar from "../../../common/CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "../../../common/CommandBar/methods";
import SearchByTags from "./Tags/SearchByTags";

type ThreadCommentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
  onManageTags: () => void;
};

export const CommandBar = ({
  handleSearch,
  onAdd,
  onManageTags,
}: ThreadCommentsCommandBarProps) => {
  const { t } = useTranslation();
  const items: any = [
    {
      key: "newComment",
      text: t("New comment"),
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      icon: <AddRegular />,
      onClick: onAdd,
    },
    {
      key: "tags",
      text: t("Manage tags"),
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      icon: <TagRegular />,
      onClick: onManageTags,
    },
  ];

  const farItems = [
    {
      key: "tagSearch",
      type: CommandBarItemType.Custom,
      onRender: () => <SearchByTags />,
      className: "secondary-dropdown",
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      farItems={computeCommandBarItems(farItems)}
      onSearch={handleSearch}
    />
  );
};
