import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { maxLengthType1, maxLengthType2, textStyle } from "../../../../schema/Constants";
import BaseDialog, { BaseDialogTitle } from "../../../common/Dialog";
import type { FormItemProps } from "../../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../../common/Form";
import {
  addFrequencyCatalogueItem,
  deleteFrequencyCatalogueItem,
  editFrequencyCatalogueItem,
} from "./api";
import type { CreateFrequencyCatalogueItem, FrequencyCatalogueDbEntry } from "./models";

const getSchema = (t, context: "add" | "edit" | "delete" | "addWithGroup") =>
  z
    .object({
      groupName: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType2,
          }),
        }),
      itemName: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType2,
          }),
        }),
      frequencyInHz: z.string().optional(),
      description: z
        .string()
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .optional(),
    })
    .refine(
      (input) => {
        if (!input.frequencyInHz) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.frequencyInHz);
      },
      {
        path: ["frequencyInHz"],
        message: t("Try a number."),
      },
    );

function getFields(t, isDisabled: boolean): FormItemProps[] {
  return [
    {
      name: "groupName",
      type: FormItemType.TextField,
      groupProps: { label: t("Group Name") },
      disabled: isDisabled,
    },
    {
      name: "itemName",
      type: FormItemType.TextField,
      groupProps: { label: t("Item Name") },
    },
    {
      name: "frequencyInHz",
      type: FormItemType.TextField,
      groupProps: { label: t("Frequency In Hz") },
    },
    {
      name: "description",
      type: FormItemType.TextField,
      groupProps: { label: "Description" },
    },
  ];
}

type CUDDialogProps = Omit<DialogProps, "children"> & {
  machineId: string;
  data: undefined | string | FrequencyCatalogueDbEntry;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete" | "addWithGroup") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete" | "addWithGroup";
};

export const CUDDialog = ({
  machineId,
  data,
  onSuccess,
  onClose,
  context,
  ...rest
}: CUDDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t, context), [t, context]);

  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && data && typeof data !== "string"
      ? {
          defaultValues: {
            groupName: data.groupName,
            itemName: data.itemName,
            frequencyInHz: data.frequencyInHz.toString(),
            description: data.description,
          },
        }
      : {
          defaultValues: {
            groupName: data as string,
          },
        }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          groupName: data?.groupName,
          itemName: data?.itemName,
          frequencyInHz: data?.frequencyInHz.toString(),
          description: data?.description || "",
        })
      : typeof data === "string"
        ? reset({
            groupName: data,
            itemName: "",
            frequencyInHz: "0.0",
            description: "",
          })
        : reset({
            groupName: "",
            itemName: "",
            frequencyInHz: "0.0",
            description: "",
          });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: CreateFrequencyCatalogueItem = {
      groupName: formData.groupName,
      itemName: formData.itemName,
      frequencyInHz: Number(formData.frequencyInHz) as number,
      description: formData.description ?? "",
    };

    switch (context) {
      case "add":
        await addFrequencyCatalogueItem(machineId, toSend).then((response) =>
          onSuccess("status" in response, context),
        );
        break;
      case "addWithGroup":
        await addFrequencyCatalogueItem(machineId, toSend).then((response) =>
          onSuccess("status" in response, context),
        );
        break;
      case "edit":
        await editFrequencyCatalogueItem(
          typeof data !== "string" && "id" in data ? data.id : "",
          machineId,
          typeof data !== "string" && "id" in data && { id: data.id, ...toSend },
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteFrequencyCatalogueItem(typeof data === "string" && data, machineId).then(
          (response) => onSuccess("status" in response, context),
        );
        break;
    }

    handleClose();
  });

  const handleDelete = async () => {
    setLoading(true);

    await deleteFrequencyCatalogueItem(typeof data === "string" && data, machineId).then(
      (response) => onSuccess("status" in response, context),
    );

    handleClose();
  };

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog {...rest} onOpenChange={handleClose}>
          <BaseDialogTitle>
            {context === "addWithGroup"
              ? t("Add a new item to a new group")
              : context.charAt(0).toUpperCase() +
                context.slice(1) +
                " " +
                t("Frequency Catalogue Item")}
          </BaseDialogTitle>
          <DialogContent>
            {["add", "edit", "addWithGroup"].includes(context) ? (
              <form onSubmit={onSubmit}>
                {renderFormItems(getFields(t, context === "add"), {
                  control,
                  errors: errors as { [schemaProp: string]: FieldError },
                })}
                <DialogActions>
                  <Button
                    appearance='primary'
                    type='submit'
                    disabled={isLoading || !isValid}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                  >
                    {t("Save Changes")}
                  </Button>
                  <Button appearance='transparent' onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                </DialogActions>
              </form>
            ) : ["delete"].includes(context) ? (
              <>
                <div style={textStyle}>
                  {t("You are about to delete selected Frequency Catalogue Item.")}
                </div>

                <DialogActions>
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                  <Button onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
              </>
            ) : null}
          </DialogContent>
        </BaseDialog>
      )}
    </>
  );
};
