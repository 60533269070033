import type { DialogProps } from "@fluentui/react-components";
import {
  Button,
  DialogActions,
  DialogContent,
  Field,
  Label,
  Spinner,
} from "@fluentui/react-components";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { textStyle } from "../../schema/Constants";
import Combobox from "../common/Combobox";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import type { ResponseMachineDetails } from "../Machines/models";
import { addSensor, deleteSensor, editSensor } from "./api";
import type { RequestSensorAdd, Sensor } from "./models";

const getSchema = () =>
  z.object({
    sensorId: z.string().optional().nullable(),
    sensorNo: z.string().optional().nullable(),
    sensorSerialNo: z.string().optional().nullable(),
    sensorDescription: z.string().optional().nullable(),
    mountingType: z.string().optional().nullable(),
    mountingDirection: z.string().optional().nullable(),
  });

const fields: FormItemProps[] = [
  {
    name: "sensorId",
    type: FormItemType.TextField,
    groupProps: { label: t("Sensor Id") },
    disabled: true,
  },
  {
    name: "sensorNo",
    type: FormItemType.TextField,
    groupProps: { label: t("Sensor No") },
  },
  {
    name: "sensorSerialNo",
    type: FormItemType.TextField,
    groupProps: { label: t("Sensor Serial No") },
  },
  {
    name: "sensorDescription",
    type: FormItemType.TextField,
    groupProps: { label: t("Description") },
  },
  {
    name: "mountingType",
    type: FormItemType.TextField,
    groupProps: { label: t("Mounting Type") },
  },
  {
    name: "mountingDirection",
    type: FormItemType.TextField,
    groupProps: { label: t("Mounting Direction") },
  },
];

type AddOrEditDialogProps = Omit<DialogProps, "children" | "open"> & {
  machineId: string;
  data: RequestSensorAdd | Sensor | string | undefined;
  machines?: ResponseMachineDetails[];
  show: boolean;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialog = ({
  machineId,
  data,
  machines,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(), []);

  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState<any>(
    data && typeof data !== "string" && "id" in data && data?.machineId ? data?.machineId : "",
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data &&
      typeof data !== "string" && {
        defaultValues: {
          sensorId: "id" in data ? data.id : "",
          sensorNo: data?.sensorNo,
          sensorSerialNo: data?.sensorSerialNo,
          sensorDescription: data?.sensorDescription,
          mountingType: data?.mountingType,
          mountingDirection: data?.mountingDirection,
        },
      }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          sensorId: "id" in data ? data.id : "",
          sensorNo: data?.sensorNo,
          sensorSerialNo: data?.sensorSerialNo,
          sensorDescription: data?.sensorDescription,
          mountingType: data?.mountingType,
          mountingDirection: data?.mountingDirection,
        })
      : reset({
          sensorId: "",
          sensorNo: "",
          sensorSerialNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: RequestSensorAdd = {
      sensorNo: formData.sensorNo,
      sensorSerialNo: formData.sensorSerialNo,
      sensorDescription: formData.sensorDescription,
      mountingType: formData.mountingType,
      mountingDirection: formData.mountingDirection,
    };

    switch (context) {
      case "add":
        await addSensor(machineId, toSend).then((response) =>
          onSuccess("status" in response, context),
        );
        break;
      case "edit":
        await editSensor(
          machineId,
          typeof data !== "string" && "id" in data ? data?.id : "",
          typeof data !== "string" &&
            "id" in data && { id: data?.id, machineId: idSelected, ...toSend },
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteSensor(machineId, typeof data === "string" && data).then((response) =>
          onSuccess("status" in response, context),
        );
        break;
    }

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
          <BaseDialogTitle>
            {context.charAt(0).toUpperCase() + context.slice(1) + " " + t("Sensor")}
          </BaseDialogTitle>
          <DialogContent>
            {["add", "edit"].includes(context) ? (
              <form onSubmit={onSubmit}>
                {context === "edit" ? (
                  <Field validationMessage={idSelected ? "" : t("Select a Machine")}>
                    <Label>{t("Select Machine (From same project) *")}</Label>
                    <Combobox
                      key={30}
                      options={machines
                        ?.map((mach) => ({
                          key: mach.id,
                          text: mach.name,
                        }))
                        .sort((a, b) => (a.text > b.text ? 1 : -1))}
                      value={idSelected}
                      onChange={(key) => setIdSelected(key)}
                    />
                  </Field>
                ) : null}
                {renderFormItems(data ? fields : fields.slice(1), {
                  control,
                  errors: errors as { [schemaProp: string]: FieldError },
                })}
                <DialogActions>
                  <Button
                    appearance='primary'
                    type='submit'
                    disabled={isLoading || !isValid || idSelected === undefined}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                  >
                    {t("Save Changes")}
                  </Button>
                  <Button onClick={handleClose}>{t("Cancel")}</Button>
                </DialogActions>
              </form>
            ) : ["delete"].includes(context) ? (
              <>
                <div style={textStyle}>{t("You are about to delete selected Sensor.")}</div>

                <DialogActions>
                  <Button
                    appearance='primary'
                    disabled={isLoading}
                    icon={isLoading ? <Spinner size='extra-tiny' /> : null}
                    onClick={onSubmit}
                  >
                    {t("Delete")}
                  </Button>
                  <Button onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
              </>
            ) : null}
          </DialogContent>
        </BaseDialog>
      )}
    </>
  );
};
