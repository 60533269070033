import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../../../Components/common/Table/v9";
import { Stack } from "../../../../Components/Stack";
import type { TrendViewRawDetails } from "../../types";

interface TrendViewInfoProps {
  rawDetails: TrendViewRawDetails;
  referenceSignalRawDetails: TrendViewRawDetails | undefined;
}

const getCommonColumns = (t) => [
  {
    key: "Last",
    fieldName: "Last",
    name: t("Last"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Min",
    fieldName: "Min",
    name: t("Min"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Max",
    fieldName: "Max",
    name: t("Max"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "Avg",
    fieldName: "Avg",
    name: t("Avg"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "StDev",
    fieldName: "StDev",
    name: t("StDev"),
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: "RCorr",
    fieldName: "RCorr",
    name: t("RCorr"),
    minWidth: 100,
    maxWidth: 100,
  },
];

const getAllDataColumns = (t) => [
  {
    key: "All data",
    fieldName: "All data",
    name: t("All data"),
    minWidth: 100,
    maxWidth: 100,
  },
  ...getCommonColumns(t),
];

const getStatisticsDataColumns = (t) => [
  {
    key: "Current view",
    fieldName: "Current view",
    name: t("Current view"),
    minWidth: 100,
    maxWidth: 100,
  },
  ...getCommonColumns(t),
];

const TrendViewInfo: React.FC<TrendViewInfoProps> = ({ rawDetails, referenceSignalRawDetails }) => {
  const { t } = useTranslation();

  return (
    <Stack
      style={{
        gap: 24,
        margin: "16px 0",
      }}
    >
      <Table
        persistOpts={{
          key: "signal-specific-data-keys",
          version: 2,
        }}
        v8Columns={getAllDataColumns(t)}
        items={[
          {
            "All data": "Reference",
            Last: referenceSignalRawDetails ? referenceSignalRawDetails.all.last : "-",
            Min: referenceSignalRawDetails ? referenceSignalRawDetails.all.minimum : "-",
            Max: referenceSignalRawDetails ? referenceSignalRawDetails.all.maximum : "-",
            Avg: referenceSignalRawDetails ? referenceSignalRawDetails.all.average : "-",
            StDev: referenceSignalRawDetails ? referenceSignalRawDetails.all.stDev : "-",
            RCorr: "-",
          },
          {
            "All data": "Signal",
            Last: rawDetails.all.last,
            Min: rawDetails.all.minimum,
            Max: rawDetails.all.maximum,
            Avg: rawDetails.all.average,
            StDev: rawDetails.all.stDev,
            RCorr: isNaN(rawDetails.all.rCorr) ? "-" : rawDetails.all.rCorr,
          },
        ]}
      />
      <Table
        persistOpts={{
          key: "signal-specific-data-values",
          version: 2,
        }}
        v8Columns={getStatisticsDataColumns(t)}
        items={[
          {
            "Current view": "Reference",
            Last: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.last : "-",
            Min: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.minimum : "-",
            Max: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.maximum : "-",
            Avg: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.average : "-",
            StDev: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.stDev : "-",
            "R Corr": "-",
          },
          {
            "Current view": "Signal",
            Last: rawDetails.selectedRange.last,
            Min: rawDetails.selectedRange.minimum,
            Max: rawDetails.selectedRange.maximum,
            Avg: rawDetails.selectedRange.average,
            StDev: rawDetails.selectedRange.stDev,
            "R Corr": isNaN(rawDetails.selectedRange.rCorr) ? "-" : rawDetails.selectedRange.rCorr,
          },
        ]}
      />
    </Stack>
  );
};

export default TrendViewInfo;
