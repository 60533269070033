import { t } from "i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { getPeriodDropdownOptions } from "../../MachineCVDriveLoadPage/config";
import { STATISTICS_TAB_1 } from "../components/Statistics/Tabs/config";

export const performanceStoreInitialValues = {
  period: getPeriodDropdownOptions(t)[4],
  statisticsActiveTab: STATISTICS_TAB_1,
  isIndicesModalOpen: false,
  selectedTimeStamp: "",
  selectedSignals: [],
  isGroupedEverything: false,
  isWarningModalOpen: false,
};

const usePerformanceStore = createWithEqualityFn(
  (set, get: any) => ({
    ...performanceStoreInitialValues,
    getStoreValue: (key: any) => get()[key],
    updateStore: (updatedStoreProperties: any = {}) =>
      set((store) => {
        return {
          ...store,
          ...updatedStoreProperties,
        };
      }),
  }),
  shallow,
);

export default usePerformanceStore;
