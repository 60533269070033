import { Text } from "@fluentui/react-components";

type MarkdownTextProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
};

const MarkdownText = ({ text, ...rest }: MarkdownTextProps) => {
  return (
    <div className='markdown-text-container' {...rest}>
      <Text size={300} as='p' wrap style={{ whiteSpace: "pre-wrap" }}>
        {text ?? ""}
      </Text>
    </div>
  );
};

export default MarkdownText;
