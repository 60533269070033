import "./style.scss";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../Hooks";
import { MapProvider } from "../../../../common/Map";
import { TabMenu } from "../../../../common/TabMenu";
import { selectUserSettings } from "../../../../Settings/selectors";
import { type Mode, modeIcons } from "../../hooks/useModeSelect";
import Map from "./Map";
import { Continents } from "./Map/components/Continents";
import Overview from "./Overview";

const onRenderTitle: any = ({ title, key }) => {
  const ModeIcon = modeIcons[key as Mode];

  return (
    <div className='mode-tab'>
      <ModeIcon />
      {title}
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();
  const {
    dashboardSettings: { machinesCounter, conditions, lastDataUpdate, worldMap },
  } = useAppSelector(selectUserSettings);

  const hasOverviewTab = machinesCounter || conditions || lastDataUpdate;

  const MODE_OPTIONS = useMemo(
    () =>
      [
        {
          key: "overview",
          title: onRenderTitle({ title: t("Overview"), key: "overview" }),
          show: hasOverviewTab,
          content: <Overview />,
        },
        {
          key: "map",
          title: onRenderTitle({ title: t("Map"), key: "map" }),
          show: worldMap,
          content: (
            <div>
              <Continents />
              <Map />
            </div>
          ),
        },
      ].filter(Boolean),
    [hasOverviewTab, t, worldMap],
  );

  const [mode, setDataMode] = useState(localStorage.getItem("dashboard-tab") || "overview");

  return (
    <MapProvider defaultValues={{ latitude: 17.55, longitude: 3.17, zoom: 1.5 }}>
      {MODE_OPTIONS.length > 0 ? (
        <TabMenu
          showActivated
          className='mode-select-tab-menu'
          tabs={MODE_OPTIONS}
          selectedValue={mode}
          onTabSelect={(event, tab) => {
            setDataMode(tab?.value as Mode);
            localStorage.setItem("dashboard-tab", `${tab?.value}`);
          }}
        />
      ) : null}
    </MapProvider>
  );
};

export default Dashboard;
