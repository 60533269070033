/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, Tooltip } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import type { User } from "../../../../../../Hooks";
import { getUserById, useWebAnalysisPermissions } from "../../../../../../Hooks";
import { format } from "../../../../../../utils";
import { File } from "../../../../../common/Preview";
import type { Column } from "../../../../../common/Table/v9";

type TaskDocumentsColumnsProps = {
  t: any;
  onRemove: ({ ids, name }: { ids: string[]; name: string }) => void;
  users: User[];
};

type ActionsMenuProps = {
  id: string;
  name: string;
  onRemove: any;
};

const ActionsMenu = ({ id, name, onRemove }: ActionsMenuProps) => {
  const { t } = useTranslation();
  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  if (!hasMachineCvAdministratorPermission) {
    return <>-</>;
  }

  return (
    <Tooltip relationship='label' content={t("Delete")}>
      <Button
        appearance='transparent'
        icon={<DeleteRegular />}
        aria-label={t("Delete")}
        onClick={(e) => {
          e.stopPropagation();
          onRemove({ ids: [id], name });
        }}
      />
    </Tooltip>
  );
};

export const getColumns = ({ t, onRemove, users }: TaskDocumentsColumnsProps): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "addedBy",
    fieldName: "addedBy",
    name: t("Added By"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => getUserById(users, item.userId),
  },
  {
    key: "added",
    fieldName: "added",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => format(new Date(item.added)),
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 68,
    onRender: ({ id, name, memberId }) => {
      const actionsMenuProps = {
        id,
        name,
        memberId,
        onRemove,
      };
      return <ActionsMenu {...actionsMenuProps} />;
    },
  },
];
