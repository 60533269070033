import { ContactIcon, RingerIcon, SettingsIcon } from "@fluentui/react-icons-mdl2";
import { useTranslation } from "react-i18next";

import EditUserNotificationPage from "../UserNotifications/EditUserNotificationPage";
import Contact from "./Sections/Contact";
import Dashboard from "./Sections/Dashboard";
import Information from "./Sections/Information";
import List from "./Sections/List";
import Tabs from "./Tabs/Tabs";
// import Security from "./Sections/Security";

const iconStyle = {
  fontSize: 16,
  marginRight: 10,
};

const Settings = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Account"),
      icon: <ContactIcon style={iconStyle} />,
      hasIcon: true,
      content: [
        { label: t("Contact"), content: <Contact /> },
        { label: t("Information"), hasIcon: false, content: <Information /> },
        // { label: t("Security"), hasIcon: false, content: <Security /> },
      ],
    },
    {
      label: t("General"),
      icon: <SettingsIcon style={iconStyle} />,
      hasIcon: true,
      content: [
        { label: t("Dashboard"), hasIcon: false, content: <Dashboard /> },
        { label: t("List"), hasIcon: false, content: <List /> },
        // { label: t("Notifications"), hasIcon: false, content: <Notifications /> },
      ],
    },
    {
      label: t("Notifications"),
      icon: <RingerIcon style={iconStyle} />,
      hasIcon: true,
      content: <EditUserNotificationPage />,
    },
  ];

  return <Tabs hasExtraClass hasHeaderContent tabs={tabs} />;
};

export default Settings;
