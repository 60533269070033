/* eslint-disable jsx-a11y/click-events-have-key-events */

import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/v9";

export const getColumns = (t): Column[] => [
  {
    key: "nodeId",
    fieldName: "nodeId",
    name: t("Sensor Serial"),
    isSortable: true,
    minWidth: 200,
  },
  // {
  //   key: 'sensorDalogName',
  //   fieldName: 'sensorDalogName',
  //   name:t( 'Sensor DALOG Name'),
  //   minWidth: 100,
  //   isSortable: true,
  // },
  // {
  //   key: 'gatewayName',
  //   fieldName: 'gatewayName',
  //   name:t( 'Gateway Name'),
  //   minWidth: 100,
  //   isSortable: true,
  // },
  {
    key: "status",
    fieldName: "status",
    name: t("Status"),
    minWidth: 200,
    isSortable: true,
    // isExportable: false,
  },
  {
    key: "lastUpdate",
    fieldName: "lastUpdate",
    name: t("Last Update"),
    minWidth: 200,
    isSortable: true,
    onRender: (item) => format(new Date(item.lastUpdate)),
  },
  {
    key: "errors",
    fieldName: "errors",
    name: t("Comments"),
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
  },
];
