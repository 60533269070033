import classNames from "classnames";

import useSectionsStore from "../../../../Hooks/useSectionsStore";
import { useSelectedSignalsStore } from "../../../../modules/analysis-raw-data";
import SpectrumChartSection from "../../../../modules/analysis-raw-data/components/SpectrumChartSection";
import WaveformChartSection from "../../../../modules/analysis-raw-data/components/WaveformChartSection";
import { useDataPointsWithTimestamps } from "../../../../modules/analysis-raw-data/hooks/useDataPoints";
import { Stack } from "../../../Stack";

const CompareLayoutTwoMachines = () => {
  const selectedSignals = useSelectedSignalsStore((store) => store.selectedSignals);
  const getDataPoints = useDataPointsWithTimestamps();
  const isAnyMaximized = useSectionsStore((store) => store.isAnyMaximized());

  return (
    <>
      {selectedSignals.map((selectedSignal) => {
        const dataPoints = getDataPoints(selectedSignal.id);
        if (dataPoints !== undefined && dataPoints.length !== 0)
          return (
            <Stack
              key={selectedSignal.id + "-" + selectedSignal.machineId}
              horizontal
              style={{ display: "block", marginBottom: 15, paddingBottom: 15 }}
              className={classNames({
                "border--hidden": isAnyMaximized,
              })}
            >
              <Stack
                key={(dataPoints.at(0)?.id as string) + "-" + selectedSignal.id}
                horizontal
                className={classNames("layout-grid", {
                  "layout-grid--hidden": isAnyMaximized,
                })}
              >
                <WaveformChartSection
                  signal={selectedSignal}
                  dataPointTimestamp={dataPoints.at(0)?.timestamp}
                  dataPoint={dataPoints.at(0)?.id as string}
                />
                <SpectrumChartSection
                  signal={selectedSignal}
                  dataPointTimestamp={dataPoints.at(0)?.timestamp}
                  dataPoint={dataPoints.at(0)?.id as string}
                />
              </Stack>
            </Stack>
          );
      })}
    </>
  );
};

export default CompareLayoutTwoMachines;
