import { Button, Label } from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { isArray, omit } from "lodash-es";
import type { CSSProperties } from "react";

import { Stack } from "../../Stack";
import { TransparentDropdown } from "../Dropdown";
import Uploader from "../Uploader";

export const onRenderLabel = ({
  label,
  icon,
  text = "",
  textStyle = {},
}: {
  label: string;
  icon: JSX.Element;
  text?: string;
  textStyle?: CSSProperties;
}) => {
  return (
    <Stack horizontal verticalAlign='center'>
      {icon}
      <Label style={{ marginLeft: 4, fontWeight: 400 }}>{label}</Label>
      <div style={{ marginLeft: 8, fontWeight: 600, ...textStyle }}>{text}</div>
    </Stack>
  );
};

export enum CommandBarItemType {
  Button = "Button",
  Upload = "Upload",
  Dropdown = "Dropdown",
  Custom = "Custom",
}

export const computeCommandBarItems = (items: any[]): any[] => {
  const computedItems = items.map((item: any) => {
    switch (item.type) {
      case CommandBarItemType.Button: {
        const { text = "", icon = null, appearance = "subtle", disabled = false, onClick } = item;
        return {
          onRender: () => (
            <Button
              style={{ minWidth: 0 }}
              disabled={disabled}
              icon={icon}
              appearance={appearance}
              onClick={onClick}
            >
              {text}
            </Button>
          ),
          ...omit(item, "type"),
        };
      }
      case CommandBarItemType.Upload: {
        const { text = "Import", onRenderProps } = item;

        return {
          onRender: () => (
            <Uploader {...onRenderProps}>
              <Button appearance='subtle' icon={<ArrowUploadRegular />}>
                {text}
              </Button>
            </Uploader>
          ),
          ...omit(item, "type", "onRenderProps"),
        };
      }
      case CommandBarItemType.Dropdown: {
        const { onRenderProps } = item;
        const {
          onRenderLabelProps = {},
          defaultSelectedKey,
          disabled,
          onChange,
          options,
          multiselect,
          positioning = "below-end",
          ...rest
        } = onRenderProps;

        const defaultKeys = isArray(defaultSelectedKey)
          ? [...defaultSelectedKey]
          : [defaultSelectedKey];

        const value = options
          .filter(({ key }) => defaultKeys.includes(key))
          .map(({ text }) => text)
          .join(", ");

        let button = null;
        if (typeof onRenderLabelProps === "object") {
          const { label, icon, text } = onRenderLabelProps;

          button = onRenderLabel({ label, icon, text: text || value || "" });
        } else {
          button = onRenderLabelProps();
        }

        return {
          onRender: () => {
            return (
              <TransparentDropdown
                multiselect={multiselect}
                disabled={disabled}
                selectedOptions={defaultKeys}
                button={button}
                options={options}
                positioning={positioning}
                onOptionSelect={(event, option) => {
                  onChange(event, {
                    data: option.optionValue,
                    text: option.optionText,
                    selectedOptions: option.selectedOptions,
                  });
                }}
                {...rest}
              />
            );
          },
          ...omit(item, "type", "onRenderProps"),
        };
      }

      case CommandBarItemType.Custom: {
        return { ...omit(item, "type") };
      }
      default: {
        return null;
      }
    }
  });

  return computedItems;
};
