import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { TableProps } from "../../../../common/Table/v9/Table";
import Table from "../../../../common/Table/v9/Table";
import { getColumns } from "./columns";

type DocumentsTableProps = {
  items: any[];
  setItems?: (prev: any) => void;
  columns?: any[];
};

const DocumentsTable: FC<DocumentsTableProps> = ({ items = [], setItems, columns }) => {
  const { t } = useTranslation();
  const onRemove = (fileId: string) => {
    if (!setItems) return;

    setItems((prev: any) => [...prev.filter(({ id }: { id: string }) => id !== fileId)]);
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items,
      perPage: 5,
      hidePerPage: true,
      v8Columns:
        columns ||
        getColumns({
          t,
          onRemove,
        }),
    }),
    [items, t],
  );

  return <Table {...tableProps} />;
};

export default DocumentsTable;
