import {
  Button,
  DialogActions,
  DialogContent,
  Field,
  Label,
  Spinner,
} from "@fluentui/react-components";
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { format } from "../../../schema/Utils";
import Combobox from "../../common/Combobox";
import BaseDialog, { BaseDialogTitle } from "../../common/Dialog";
import type { FormItemProps } from "../../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../../common/Form";
import { notification } from "../../common/Notification";
import type { ResponseSimplifiedSignal, StandstillSettings, StandstillSignal } from "../models";
import { MachineStandstillSettingsAddOrUpdate } from "./api";

const getSchema = (t) =>
  z
    .object({
      thresholdHigh1: z.string().optional().nullable(),
      thresholdLow1: z.string().optional().nullable(),
      thresholdHigh2: z.string().optional().nullable(),
      thresholdLow2: z.string().optional().nullable(),
    })
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.thresholdHigh1) {
          return valid.test(input.thresholdHigh1);
        } else return true;
      },
      {
        path: ["thresholdHigh1"],
        message: t("Try a decimal number"),
      },
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdLow1) {
          return valid.test(input.thresholdLow1);
        } else return true;
      },
      {
        path: ["thresholdLow1"],
        message: t("Try a decimal number"),
      },
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdHigh2) {
          return valid.test(input.thresholdHigh2);
        } else return true;
      },
      {
        path: ["thresholdHigh2"],
        message: t("Try a decimal number"),
      },
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);
        if (input.thresholdLow2) {
          return valid.test(input.thresholdLow2);
        } else return true;
      },
      {
        path: ["thresholdLow2"],
        message: t("Try a decimal number"),
      },
    );

type EditStandstillProps = {
  standstillSettings: StandstillSettings;
  signals: ResponseSimplifiedSignal[];
  show: boolean;
  onSuccess: () => void;
  onClose: () => void;
  machineId: string;
};

export const EditStandstill = ({
  standstillSettings,
  signals,
  show,
  onSuccess,
  onClose,
  machineId,
}: EditStandstillProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState(
    standstillSettings?.primary ? standstillSettings?.primary.signalId : "",
  );

  function setSelectedId(key, setState) {
    setState(key);
  }

  const [idSelected2, setIdSelected2] = useState<string>(
    standstillSettings?.secondary ? standstillSettings?.secondary.signalId : "",
  );

  const schema = useMemo(() => getSchema(t), [t]);
  const thresholdFields: FormItemProps[] = [
    {
      name: "thresholdHigh1",
      type: FormItemType.TextField,
      groupProps: { label: t("High") },
    },
    {
      name: "thresholdLow1",
      type: FormItemType.TextField,
      groupProps: { label: t("Low") },
    },
    {
      name: "thresholdHigh2",
      type: FormItemType.TextField,
      groupProps: { label: t("High") },
    },
    {
      name: "thresholdLow2",
      type: FormItemType.TextField,
      groupProps: { label: t("Low") },
    },
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!standstillSettings && {
      defaultValues: {
        thresholdHigh1: standstillSettings?.primary?.thresholdHigh
          ? standstillSettings?.primary?.thresholdHigh.toString()
          : "",
        thresholdLow1: standstillSettings?.primary?.thresholdLow
          ? standstillSettings?.primary?.thresholdLow.toString()
          : "",
        thresholdHigh2: standstillSettings?.secondary?.thresholdHigh
          ? standstillSettings?.secondary?.thresholdHigh.toString()
          : "",
        thresholdLow2: standstillSettings?.secondary?.thresholdLow
          ? standstillSettings?.secondary?.thresholdLow.toString()
          : "",
      },
    }),
  });

  const fieldAll = renderFormItems(thresholdFields, {
    control,
    errors: errors as { [schemaProp: string]: FieldError },
  });

  const getStandstillThreshold = (value: string): number | undefined => {
    let result: number | undefined = undefined;
    const text = value?.trim();
    if (text && typeof parseFloat(text) === "number") {
      result = Number(text);
    }

    return result;
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const primarySignal: StandstillSignal = {
      signalId: idSelected,
      thresholdHigh: getStandstillThreshold(formData.thresholdHigh1),
      thresholdLow: getStandstillThreshold(formData.thresholdLow1),
    };

    const secondarySignal: StandstillSignal = {
      signalId: idSelected2,
      thresholdHigh: getStandstillThreshold(formData.thresholdHigh2),
      thresholdLow: getStandstillThreshold(formData.thresholdLow2),
    };

    const dataToUpdate: StandstillSettings = {
      primary: idSelected ? primarySignal : null,
      secondary: idSelected2 ? secondarySignal : null,
    };

    await MachineStandstillSettingsAddOrUpdate(machineId, dataToUpdate).then((response) => {
      response["status"] >= 200 && response["status"] < 300
        ? onSuccess()
        : response["text"]
          ? notification.error(response["text"])
          : notification.error(`${t("Something went wrong")}.`);
    });
    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      <BaseDialog open={show} onOpenChange={handleClose}>
        <BaseDialogTitle>{standstillSettings ? t("Update") : t("Add")}</BaseDialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <Field>
              <Label>{t("Select Primary Signal *")}</Label>
              <Combobox
                key={30}
                value={idSelected}
                options={signals
                  ?.map((sig) => ({
                    key: sig.id,
                    text:
                      sig.name +
                      " (" +
                      format(new Date(sig.dataFrom)) +
                      ", " +
                      format(new Date(sig.dataUntil)) +
                      ")",
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1))}
                onChange={(key) => setSelectedId(key, setIdSelected)}
              />
            </Field>
            {fieldAll.at(0)}
            {fieldAll.at(1)}
            <Field>
              <Label>{t("Select Secondary Signal")}</Label>
              <Combobox
                key={20}
                options={signals
                  ?.map((sig) => ({
                    key: sig.id,
                    text:
                      sig.name +
                      " (SS No: " +
                      sig.sensorSerialNo +
                      ") (" +
                      format(new Date(sig.dataFrom)) +
                      ", " +
                      format(new Date(sig.dataUntil)) +
                      ") ",
                  }))
                  .sort((a, b) => (a.text > b.text ? 1 : -1))}
                value={idSelected2}
                onChange={(key) => setSelectedId(key, setIdSelected2)}
              />
            </Field>
            {fieldAll.at(2)}
            {fieldAll.at(3)}
            <DialogActions>
              <Button
                appearance='primary'
                type='submit'
                disabled={isLoading}
                icon={isLoading ? <Spinner size='extra-tiny' /> : null}
              >
                {t("Save Changes")}
              </Button>
              <Button
                appearance='transparent'
                onClick={() => {
                  handleClose();
                }}
              >
                {t("Cancel")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
    </>
  );
};
