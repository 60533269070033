import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner, Text } from "@fluentui/react-components";
import { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useProjects } from "../../Hooks";
import { maxLengthType1 } from "../../schema/Constants";
import BaseDialog, { BaseDialogTitle } from "../common/Dialog";
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import FormItemRow from "../Generic/FormItemRow";
import { addWirelessGateway } from "./api";
import type { RequestWirelessGatewayAdd } from "./models";

export const minRsshPort = 1;
export const maxRsshPort: number = 65535 * 2;

const getSchema = (serialNumbers: string[], t) =>
  z
    .object({
      projectId: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Serial Number must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: t("The serial number already exists"),
          },
        ),
      adminPassword: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Admin Password must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      userPassword: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`User Password must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      ltePlanSize: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
      active: z.boolean().optional(),
      installationLocation: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: t("Try a number between (1.0:1000.0)"),
      },
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.rsshPort);
      },
      {
        path: ["rsshPort"],
        message: t(`Try a number between ({{minRsshPort}}:{{maxRsshPort}})`, {
          minRsshPort,
          maxRsshPort,
        }),
      },
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          !isNaN(rsshPortNumber) && rsshPortNumber >= minRsshPort && rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: t(`Try a number between ({{minRsshPort}}:{{maxRsshPort}})`, {
          minRsshPort,
          maxRsshPort,
        }),
      },
    );

const getGatewayFields: (t, projects) => FormItemProps[] = (t, projects) => [
  {
    name: "projectId",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Project") },
    options: Array.from(projects.values()).map((project: any) => {
      return { key: project?.id, text: project?.name };
    }),
  },
  {
    name: "serialNumber",
    type: FormItemType.TextField,
    groupProps: { label: t("Serial Number *") },
  },
  {
    name: "adminPassword",
    type: FormItemType.TextField,
    groupProps: { label: t("Admin Password *") },
  },
  {
    name: "userPassword",
    type: FormItemType.TextField,
    groupProps: { label: t("User Password *") },
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: t("Lte Plan Size *") },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: t("Public Key") },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: t("Rssh Port") },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi Password For AP") },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi SSID") },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: t("IMEI") },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Sink Node Address") },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Channel") },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Ethernet Mac Address") },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: t("Firmware") },
  },
  {
    name: "active",
    type: FormItemType.Checkbox,
    groupProps: { label: t("Active") },
  },
  {
    name: "installationLocation",
    type: FormItemType.TextField,
    groupProps: { label: t("Installation Location") },
  },
];

type AddDialogProps = Omit<DialogProps, "children" | "open"> & {
  serialNumbers: string[];
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const AddDialog = ({ serialNumbers, show, onSuccess, onClose, ...rest }: AddDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(serialNumbers, t), [serialNumbers, t]);
  const { projects } = useProjects();
  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      projectId: "",
      serialNumber: "",
      adminPassword: "",
      userPassword: "",
      wirepassChannel: "26",
      active: true,
    },
  });

  // Handlers
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    !formData?.projectId && notification.warning(t("Un-parented gateway"));

    const toSend: RequestWirelessGatewayAdd = {
      projectId: formData.projectId,
      adminPassword: formData.adminPassword,
      serialNumber: formData.serialNumber,
      userPassword: formData.userPassword,
      ltePlanSize: Number(formData.ltePlanSize) as number,
      rsshPort: Number(formData.rsshPort) as number,
      publicKey: formData.publicKey,
      wifiPasswordForAP: formData.wifiPasswordForAP,
      wifiSSID: formData.wifiSSID,
      iMEI: formData.iMEI,
      wirepassSinkNodeAddress: formData.wirepassSinkNodeAddress,
      wirepassChannel: formData.wirepassChannel,
      ethernetMacAddress: formData.ethernetMacAddress,
      firmware: formData.firmware,
      active: formData.active,
      installationLocation: formData.installationLocation,
    };

    await addWirelessGateway(toSend).then((response) =>
      onSuccess("status" in response, toSend.serialNumber),
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog {...rest} open={show} onOpenChange={handleClose}>
      <BaseDialogTitle>{t("Add new Gateway")}</BaseDialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormItemRow label={t("Company")} style={{ marginBottom: "0.75em" }}>
            <Text as='p' size={300} style={{ fontWeight: 600 }}>
              {projects.get(watch().projectId)?.company?.name || ""}
            </Text>
          </FormItemRow>

          {renderFormItems(getGatewayFields(t, projects), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogActions>
            <Button
              appearance='primary'
              type='submit'
              disabled={isLoading || !isValid || !isDirty}
              icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            >
              {t("Save Changes")}
            </Button>
            <Button appearance='transparent' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};
