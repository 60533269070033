import { differenceInHours, endOfDay } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocationSearch } from "../../../Hooks";
import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import type { ObjectWithKey } from "../../../types";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";
import type { TableProps } from "../../common/Table/v9";
import Table, { useTableFilters } from "../../common/Table/v9";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import { ThreadDialog } from "./components/ThreadAdd";
import { useThreadsQuery } from "./hooks/useThreadsQuery";
import useThreadsStore from "./hooks/useThreadsStore";

export const filterByTime = (days: number, threads: any[]) => {
  if (!days) return threads;

  const hours = days * 24;
  const today = endOfDay(new Date());

  return threads.filter(({ createdAt }) => differenceInHours(today, new Date(createdAt)) <= hours);
};

const Page = () => {
  const { t } = useTranslation();
  const [{ id }, ,] = useLocationSearch();
  const [{ search }] = useLocationSearch();

  const { threads, isError, isLoading, refetch } = useThreadsQuery({
    machineId: id || "",
  });

  const { filters, handleSearch } = useTableFilters<any>({
    keys: ["id", "text"],
  });

  const [daysFilter, setDaysFilter] = useState<number>(0);
  const data = useMemo<any[]>(
    () => filterByTime(daysFilter as number, threads),
    [threads, daysFilter],
  );

  const { selectedItems, showDelete, showAdd, dispatch } = useThreadsStore((state) => ({
    showDelete: state.showDelete,
    selectedItems: state.selectedItems as any[],
    showAdd: state.showAdd,
    dispatch: state.dispatch,
  }));

  const handleSelect = useCallback(
    (items: ObjectWithKey[]) => {
      dispatch({ type: "SET_SELECTION", payload: items });
    },
    [dispatch],
  );

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-threads",
        version: 1,
      },
      header: {
        title: t("Threads"),
      },
      items: data,
      filters,
      isLoading,
      isError,
      v8Columns: getColumns({ t, locationSearch: search }),
      onSelectionChanged: (items: ObjectWithKey[]) => handleSelect(items),
    }),
    [t, data, filters, isLoading, isError, search, handleSelect],
  );

  const onFilter = (days: number) => {
    setDaysFilter(days);
  };

  if (isLoading) {
    return <Spin style={{ height: "calc(100vh - 50px)" }} />;
  }

  if (isError) {
    return <NoData style={{ height: "calc(100vh - 50px)" }} />;
  }

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <CommandBar handleSearch={handleSearch} filter={daysFilter} onFilter={onFilter} />
        <Table {...tableProps} />
      </div>
      <ThreadDialog
        open={showAdd}
        onDismiss={() => dispatch({ type: "SHOW_ADD", payload: false })}
      />
      <DeleteDialog
        data={{
          ids: selectedItems.map((item) => item?.id),
          name: selectedItems.length === 1 ? selectedItems[0]?.text : "items",
        }}
        open={showDelete}
        route={"/meta/machinecv/v1/comments"}
        onDismiss={() => dispatch({ type: "SHOW_DELETE", payload: false })}
        onSuccess={refetch}
      />
    </>
  );
};

export default Page;
