import { makeStyles, Persona } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";

import { useAppSelector } from "../../Hooks";
import { getObjectUrlFromBase64 } from "../../schema/Utils";
import { selectUserAccountInitialProfilePicture } from "../UserAccount/selectors";
import { IsClickedAccountIconContext, LocalAccountInfo } from "./context";

const useStyles = makeStyles({
  persona: {
    "& .fui-Avatar__initials": {
      background: "#2b53a0",
      color: "#fff",
    },
  },
});

const Account = ({ ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const classes = useStyles();

  const [keyChange, setKeyChange] = useState(1);

  const isClickedAccountIcon = useContext(IsClickedAccountIconContext);
  const myAccount = useContext(LocalAccountInfo);

  const profilePicture = useAppSelector(selectUserAccountInitialProfilePicture);

  useEffect(() => {
    setKeyChange((prev) => prev + 1);
  }, [myAccount?.val?.pictureBase64]);

  function OnClickHandle() {
    return isClickedAccountIcon ? isClickedAccountIcon((prev) => !prev) : null;
  }

  return (
    <Persona
      {...rest}
      key={keyChange}
      size='large'
      name={myAccount?.val?.displayName}
      avatar={{
        image: { src: profilePicture ? getObjectUrlFromBase64(profilePicture) : undefined },
      }}
      primaryText={null}
      className={classes.persona}
      onClick={OnClickHandle}
    />
  );
};

export default Account;
