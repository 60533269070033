import { Button } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import TextArea from "../../../common/TextArea";
import { Stack } from "../../../Stack";
import styles from "./QuestionInput.module.css";

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (newValue?: string) => {
    if (!newValue) {
      setQuestion("");
    } else if (newValue.length <= 1000) {
      setQuestion(newValue);
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextArea
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
      />
      <div className={styles.questionInputButtonsContainer}>
        <Button
          appearance='transparent'
          className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
          aria-label={t("Ask question button")}
          onClick={sendQuestion}
        >
          <Send28Filled primaryFill='rgba(115, 118, 225, 1)' />
        </Button>
      </div>
    </Stack>
  );
};
