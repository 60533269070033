import {
  Button,
  DialogActions,
  DialogContent,
  Input,
  Label,
  Spinner,
  Text,
} from "@fluentui/react-components";
import type { FormEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";

type DeleteDialogProps = {
  id: string;
  open?: boolean;
  identifier: string;
  propertyName: string;
  referenceString: string;
  isLoading: boolean;
  onDelete: (id: string) => void;
  onClose: () => void;
};

/**
 * Gets the generic delete dialog component.
 * @param id The item Id.
 * @param identifier The item identifier
 * @param propertyName The property named used to confirm deletion.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onDelete Method called when the delete button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const DeleteDialog = ({
  id,
  open = false,
  identifier,
  propertyName,
  referenceString,
  isLoading,
  onDelete,
  onClose,
}: DeleteDialogProps) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [textFieldValue, setTextFieldValue] = useState<string>("");

  // Method called when the Delete button is clicked.
  const onDeleteHandler = () => {
    if (!id) {
      return;
    }

    onDelete?.(id);
  };

  // Method called when the text field value is changed.
  const onTextChanged = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, data?: any) => {
    if (!data?.value) {
      setErrorMessage("");
      setTextFieldValue("");
      return;
    }

    setErrorMessage(
      data?.value.toLowerCase().trim() === referenceString.toLowerCase().trim()
        ? ""
        : t(`The input {{propertyName}} is not the same as the entry {{propertyName}}.`, {
            propertyName,
          }),
    );
    setTextFieldValue(data?.value);
  };

  return (
    <BaseDialog open={open} surfaceStyle={{ width: DialogSize.M }} onOpenChange={onClose}>
      <BaseDialogTitle>{t("Attention!")}</BaseDialogTitle>

      <DialogContent>
        <p className='normal-text'>
          {t("You are about to permanently delete a {{identifier}}.", {
            identifier,
          })}
        </p>
        <br />

        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Label htmlFor='delete-dialog'>
            {t(`Please confirm typing the {{propertyName}}: *`, {
              propertyName,
            })}
          </Label>
          <Input
            style={{ ...(errorMessage && { borderColor: "#a4262c" }) }}
            id='delete-dialog'
            aria-describedby={"delete-dialog-error"}
            value={textFieldValue}
            onChange={onTextChanged}
          />
          <Text
            style={{ display: "block", marginTop: 5, fontSize: 12, color: "#a4262c" }}
            id={"delete-dialog-error"}
          >
            {errorMessage}
          </Text>
        </div>

        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading || textFieldValue.length === 0 || errorMessage.length > 0}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onDeleteHandler}
          >
            {t("Delete")}
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DeleteDialog;
