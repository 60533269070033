export enum FileType {
  Img = "image/png",
  Pdf = "application/pdf",
}

export type FilePreviewProps = {
  src: string;
  type: FileType;
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
