import { AutomationStatusType, ConnectionStatusType } from "../Schema/models";

const emailRegex = new RegExp("(.*)\\@(.*)\\.(.{2,})");

export class Utils {
  static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  static getAutomationStatusKey = (
    status: AutomationStatusType,
  ): keyof typeof AutomationStatusType => {
    const index: number = Object.values(AutomationStatusType).indexOf(status);
    if (index < 0) {
      return this.getAutomationStatusKey(AutomationStatusType.Unknown);
    }

    return Object.keys(AutomationStatusType)[index] as keyof typeof AutomationStatusType;
  };

  static getConnectionStatusKey = (
    status: ConnectionStatusType,
  ): keyof typeof ConnectionStatusType => {
    const index: number = Object.values(ConnectionStatusType).indexOf(status);
    if (index < 0) {
      return this.getConnectionStatusKey(ConnectionStatusType.Unknown);
    }

    return Object.keys(ConnectionStatusType)[index] as keyof typeof ConnectionStatusType;
  };

  static getConnectionStatusColor = (statusStr: string): string => {
    switch (statusStr) {
      case ConnectionStatusType.Unknown:
        return "#b3b3b3";
      case ConnectionStatusType.NotConfigured:
        return "#5a71a9";
      case ConnectionStatusType.NotWorking:
        return "#ff7e0d";
      case ConnectionStatusType.Working:
        return "#66cd7d";
    }
  };

  static emailsAreValid = (emails: string) => {
    const emailsList: string[] = emails
      .trim()
      .split(";")
      .filter((email) => email.length > 0);

    let result = true;
    emailsList.forEach((email) => {
      result = result && emailRegex.test(email.trim());
    });

    return result;
  };

  static emailsHaveChanged = (newEmails: string, oldEmails: string) => {
    const newEmailsList: string[] = newEmails
      .trim()
      .split(";")
      .filter((email) => email.length > 0);

    const oldEmailsList: string[] = oldEmails
      .trim()
      .split(";")
      .filter((email) => email.length > 0);

    let result = false;
    newEmailsList.forEach((email) => {
      result = result || !oldEmails.includes(email.trim());
    });

    oldEmailsList.forEach((email) => {
      result = result || !newEmails.includes(email.trim());
    });

    return result;
  };

  static getTime = (date: Date): string => {
    const hours = (date.getHours() + 1).toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  static getDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = (date.getDate() + 1).toString().padStart(2, "0");

    return `${day}.${month}.${year}`;
  };

  static firstToUpperCase = (item: string): string => {
    return item?.replace(/^\w/, (c) => c.toUpperCase());
  };
}
