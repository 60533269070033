import { makeStyles } from "@fluentui/react-components";
import { CloudAddRegular } from "@fluentui/react-icons";
import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from "../../CommandBar";
import type { Type } from "./export";
import { save } from "./export";
import type { TableProps } from "./Table";

const exportOptions: any = [
  { key: "csv", text: "CSV", data: "csv" },
  { key: "xlsx", text: "Excel", data: "xlsx" },
];

type HeaderProps = {
  title: string;
  exportDisabled: boolean;
  data: unknown[];
  columns: TableProps["tableColumns"];
  isExportable?: boolean;
};

const useStyles = makeStyles({
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",

    "& .fui-Dropdown__button": {
      "& .default-stack-styles": {
        marginRight: "-10px",
      },

      "& .fui-Label": {
        cursor: "pointer !important",
      },
    },
  },
});

const Header = ({ title, exportDisabled, data, columns, isExportable = true }: HeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSave = useCallback(
    (type: Type) => {
      save({ filename: title, type, data, columns });
    },
    [data, columns],
  );

  const items: any = useMemo(
    () => [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <span style={{ fontWeight: 600 }}>{title}</span>,
      },
    ],
    [title],
  );

  const farItems: any = useMemo(() => {
    const result = [];
    if (isExportable) {
      result.push({
        key: "export",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          disabled: exportDisabled || data.length === 0,
          options: exportOptions,
          notifyOnReselect: true,
          onChange: (_: never, item: { data: any }) => {
            handleSave(item.data as Type);
          },
          onRenderLabelProps: {
            label: t("Export"),
            icon: <CloudAddRegular style={{ fontSize: 26, minWidth: "20px" }} />,
          },
        },
      });
    }
    return result;
  }, [exportDisabled, data.length, handleSave, isExportable, t]);

  return (
    <div role='row' className={classnames("table-header", classes.header)}>
      <BaseCommandBar
        items={computeCommandBarItems(items)}
        farItems={computeCommandBarItems(farItems)}
        wrapperStyle={{ overflow: "unset" }}
      />
    </div>
  );
};

export default Header;
