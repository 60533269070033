import { Text } from "@fluentui/react-components";
import { isEmpty } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CalendarIcon from "../../../../../assets/svg/CalendarIcon";
import { useLocationSearch } from "../../../../../Hooks";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { onRenderLabel } from "../../../../common/CommandBar/methods";
import Dropdown, { useDropdownStyles } from "../../../../common/Dropdown";
import {
  generateMonthsOptions,
  generateYearsOptions,
} from "../../../MachineCVSummaryPage/DetailsSection/OperationsTab/methods";
import { useTrendSignalsList } from "../../../MachineCVSummaryPage/hooks/useTrendSignalsList";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import { YEARLY_EVENTS } from "./config";
import EventNameFilter from "./EventsTable/EventNameFilter";

const EventsHeader = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { transparent } = useDropdownStyles();
  const [{ id }, ,]: any = useLocationSearch();
  const {
    events: { year, month, activeTab },
    updateDriveLoadStore,
  } = useDriveLoadStore((store: any) => ({
    events: store.events,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));

  const { signals, isLoading: loadingSignals } = useTrendSignalsList(id as string);
  const operationSignal = useMemo(() => {
    return signals.filter((signal: any) => {
      return signal.name === "operation_hours_OP";
    })[0];
  }, [signals, loadingSignals]);

  const { dataFrom, dataUntil } = operationSignal || {};
  const years: any = generateYearsOptions(dataFrom, dataUntil) || [];
  const [months, setMonths] = useState(
    generateMonthsOptions(year?.start || years[0].start, year?.end || years[0].end) || [],
  );

  useEffect(() => {
    const selectedYear = isEmpty(year) ? years[0] : year;
    const selectedMonth = isEmpty(month) ? months[0] : month;
    updateDriveLoadStore({
      events: { year: selectedYear, month: selectedMonth, activeTab },
    });
  }, []);

  const yearOptions = years.map(({ year }: any) => ({ text: year, key: year }));

  return (
    <div {...rest} className='torque-header'>
      <Text size={400} as='h2' style={{ fontWeight: 600, marginLeft: "1em", paddingRight: "1em" }}>
        {t("Events")}
      </Text>
      <Dropdown
        style={{ minWidth: "max-content", padding: "2px 8px" }}
        className={transparent}
        button={onRenderLabel({
          label: t("Year"),
          icon: <CalendarIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: year.year,
        })}
        options={yearOptions}
        selectedOptions={[year.year]}
        onOptionSelect={(_, option) => {
          const selectedYear = years.filter(({ year }: any) => year === option?.optionValue)[0];
          if (selectedYear.year !== year.year) {
            const newMonthOptions =
              generateMonthsOptions(selectedYear.start, selectedYear.end) || [];
            setMonths(newMonthOptions);
            updateDriveLoadStore({
              events: {
                month: newMonthOptions[0],
                year: selectedYear,
                activeTab,
              },
            });
          }
        }}
      />
      <Dropdown
        disabled={activeTab === YEARLY_EVENTS}
        style={{ minWidth: "max-content", padding: "2px 8px" }}
        className={transparent}
        button={onRenderLabel({
          label: t("Month"),
          icon: <CalendarIcon fill='#2c529f' style={dropdownIconStyle} />,
          text: t(month.month),
        })}
        options={months.map(({ month }: any) => ({
          key: month,
          text: t(month),
        }))}
        selectedOptions={[month.month]}
        onOptionSelect={(ev: any, option: any) => {
          const selectedMonth = months.filter(({ month }: any) => month === option.optionValue)[0];
          updateDriveLoadStore({
            events: { year, month: selectedMonth, activeTab },
          });
        }}
      />
      <EventNameFilter />
    </div>
  );
};

export default EventsHeader;
