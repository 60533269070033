import type { FieldProps, InputProps } from "@fluentui/react-components";
import { Input } from "@fluentui/react-components";
import { type CSSProperties } from "react";

import { LabeledField } from "./LabeledField";
import { PasswordField } from "./PasswordField";

type CustomInputProps = Omit<InputProps, "onChange"> & {
  label?: any;
  onChange?: any;
  labelStyle?: CSSProperties;
  fieldStyle?: CSSProperties;
  fieldWrapperProps?: FieldProps;
};

export default function TextField({
  onChange,
  fieldStyle,
  fieldWrapperProps,
  labelStyle,
  label,
  type,
  ...rest
}: CustomInputProps) {
  function onInputChange(_: any, data) {
    if (type === "number") {
      const value = Number(data?.value) || 0;
      return onChange(value);
    }
    return onChange(data.value);
  }

  if (type === "password") {
    return <PasswordField onInputChange={onInputChange} {...rest} />;
  }

  if (label) {
    return (
      <LabeledField
        label={label}
        fieldStyle={fieldStyle}
        fieldWrapperProps={fieldWrapperProps}
        labelStyle={labelStyle}
      >
        <Input onChange={onInputChange} {...rest} />
      </LabeledField>
    );
  }

  return <Input onChange={onInputChange} {...rest} />;
}
