/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./styles.scss";

import { Button, DialogActions, DialogContent, Text } from "@fluentui/react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { axiosInstance } from "../../../..";
import BaseDialog, { BaseDialogTitle } from "../../../common/Dialog";
import { FilePreview } from "../../../common/Preview";
import TextField from "../../../common/TextField";
import Attachment from "../Attachment";

export const TextFieldWithDocuments = ({
  value,
  onChange,
  files = [],
  fieldName,
  errorMessage = "",
  ...props
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const relevantFiles = files.filter((file) => file.fieldName === fieldName);

  const getTextFieldCustomStyles = () => {
    return {
      paddingRight: relevantFiles.length ? "80px" : undefined,
    };
  };

  const renderAttachmentButton = () => {
    if (!relevantFiles.length) return null;

    return (
      <span
        role='button'
        tabIndex={0}
        className='attachmentButton'
        style={{
          position: "absolute",
          right: "8px",
          top: "50%",
          zIndex: 1,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDialogOpen(true);
        }}
      >
        <Attachment style={{ flexShrink: 0 }} />
        <span>({relevantFiles.length})</span>
      </span>
    );
  };

  return (
    <div className='text-field-with-documents' style={{ position: "relative" }}>
      <TextField
        defaultValue={value}
        style={getTextFieldCustomStyles()}
        fieldWrapperProps={{ validationMessage: errorMessage }}
        onChange={onChange}
        {...props}
      />
      {renderAttachmentButton()}

      <BaseDialog open={isDialogOpen} onOpenChange={() => setIsDialogOpen(false)}>
        <BaseDialogTitle>
          <Text>{t(`{{fieldName}} Associated Documents`, { fieldName })}</Text>

          <Text>
            {t(`Showing ${relevantFiles.length} document`, {
              filesNum: relevantFiles.length,
              count: relevantFiles.length,
            })}
          </Text>
        </BaseDialogTitle>
        <DialogContent>
          <div className='filesContainer'>
            {relevantFiles.map((file, idx) => (
              <div key={file.id} className='fileItem'>
                <FilePreview
                  axiosInstance={axiosInstance}
                  file={{
                    id: file.id,
                    name: `${file.fieldName} - ${idx + 1}`,
                  }}
                />
              </div>
            ))}
          </div>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>{t("Close")}</Button>
          </DialogActions>
        </DialogContent>
      </BaseDialog>
    </div>
  );
};
