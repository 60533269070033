import type { DialogProps } from "@fluentui/react-components";
import { Button, DialogActions, DialogContent, Spinner } from "@fluentui/react-components";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import BaseDialog, { BaseDialogTitle, DialogSize } from "../common/Dialog";

type ConfirmDialogProps = Omit<DialogProps, "children" | "open"> & {
  title: string;
  isLoading: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  message?: string;
};

export const ConfirmDialog = ({
  title,
  isLoading,
  onConfirm,
  onDismiss,
  children,
  ...rest
}: PropsWithChildren<ConfirmDialogProps>) => {
  const { t } = useTranslation();
  return (
    <BaseDialog
      {...rest}
      open={true}
      surfaceStyle={{ width: DialogSize.M }}
      onOpenChange={onDismiss}
    >
      <BaseDialogTitle>{title}</BaseDialogTitle>

      <DialogContent>
        {children}
        <DialogActions>
          <Button
            appearance='primary'
            disabled={isLoading}
            icon={isLoading ? <Spinner size='extra-tiny' /> : null}
            onClick={onConfirm}
          >
            {t("Yes")}
          </Button>
          <Button disabled={isLoading} onClick={onDismiss}>
            {t("No")}
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
