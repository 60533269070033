import {
  Button,
  DialogActions,
  makeStyles,
  Persona as V9Persona,
} from "@fluentui/react-components";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultImageProfileBase64 } from "../../schema/Constants";
import { getObjectUrlFromBase64 } from "../../schema/Utils";
import { notification } from "../common/Notification";
import { Stack } from "../Stack";
import { pictureGetUsersRBAC, pictureUpdateUsersRBAC } from "./api";
import type { MyAccountInfo, UserPicture } from "./models";

const useStyles = makeStyles({
  persona: {
    width: "120px",
    height: "120px",

    "& .fui-Avatar__initials": {
      background: "#005B70",
      color: "#fff",
      width: "120px",
      height: "120px",
      fontSize: "40px",
    },
  },
});

interface IPropsAdd {
  displayName: string;
  memberId?: string;
  pictureBase64?: string;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  imageBase64?: string;
  setImageBase64?: Dispatch<SetStateAction<string>>;
  setMyAccount?: Dispatch<React.SetStateAction<MyAccountInfo>>;
}

export const UpdateUserPhoto: React.FunctionComponent<IPropsAdd> = (props: IPropsAdd) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [keyInput, setKeyInput] = useState<number>(0);
  const [imageBase64, setImageBase64] = useState<string>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const [currentImageBase64, setCurrentImageBase64] = useState<string>(props.pictureBase64);

  useEffect(() => {
    if (props.memberId)
      pictureGetUsersRBAC(props.memberId).then(
        (pic) =>
          "pictureBase64" in pic &&
          setImageBase64(pic.pictureBase64 ? pic.pictureBase64 : undefined),
      );
  }, [props.memberId]);

  const handleClickConfirm = async () => {
    if (props.memberId) {
      const aux: UserPicture = {
        id: props.memberId,
        pictureBase64: imageBase64,
      };
      pictureUpdateUsersRBAC(props.memberId, aux).then((pic) =>
        "pictureBase64" in pic && pic.pictureBase64
          ? notification.success(t("Picture updated"))
          : notification.warning(t("Something went wrong")),
      );
      if (props.memberId) setImageBase64(undefined);
      pictureGetUsersRBAC(props.memberId).then((pic) =>
        "pictureBase64" in pic && pic.pictureBase64
          ? setCurrentImageBase64(pic.pictureBase64)
          : notification.warning(t("The updated picture is null")),
      );
    } else {
      props.setImageBase64(imageBase64);
      props.setShowModal(false);
    }

    setKeyInput((prev) => prev + 1);
  };

  const handleOnChange = (e: any) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target?.result;
        setImageBase64(btoa(bstr as string));
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <>
      <input
        key={keyInput}
        ref={hiddenFileInput}
        type={"file"}
        id={"profilePhoto"}
        accept={".jpg, .jpeg, .png"}
        style={{ display: "none" }}
        onChange={handleOnChange}
      />
      <Stack horizontalAlign='center' verticalAlign='center' style={{ gap: 8 }}>
        <Stack
          horizontal
          style={{ maxWidth: "70vw", gap: 8 }}
          horizontalAlign='center'
          verticalAlign='center'
        >
          <Stack.Item>
            <V9Persona
              name={props.displayName}
              primaryText={null}
              size='extra-large'
              avatar={{
                image: {
                  src: currentImageBase64
                    ? getObjectUrlFromBase64(defaultImageProfileBase64)
                    : undefined,
                },
              }}
              className={classes.persona}
            />
          </Stack.Item>
          {imageBase64 && (
            <Stack.Item>
              <V9Persona
                name={props.displayName}
                primaryText={null}
                size='extra-large'
                avatar={{
                  image: {
                    src: getObjectUrlFromBase64(imageBase64),
                  },
                }}
                className={classes.persona}
              />
            </Stack.Item>
          )}
        </Stack>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button appearance='primary' onClick={imageBase64 ? handleClickConfirm : handleClick}>
            {imageBase64 ? t("Confirm") : t("Choose File")}
          </Button>
        </DialogActions>
      </Stack>
    </>
  );
};
