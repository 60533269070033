import "./Overview.scss";

import { Switch, Text } from "@fluentui/react-components";
import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { format } from "../../../../../utils";
import { Stack } from "../../../../Stack";
import StatusBadge from "../StatusBadge";

interface OverviewProps {
  status?: string;
  machine?: {
    customerCode: string;
    dalogId: string;
    manufacturer: string;
    name: string;
    notation: string;
    process: string;
    projectId: string;
    type: string;
    dataFrom: string;
    dataUntil: string;
    eventDataFrom: string;
    eventDataUntil: string;
    fastTrendDataFrom: string;
    fastTrendDataUntil: string;
    id: string;
    shortTermDataFrom: string;
    shortTermDataUntil: string;
    trendDataFrom: string;
    trendDataUntil: string;
    drawingIds: {
      fileId: string;
      sortIndex: number;
    }[];
    gearbox: {
      manufacturer: string;
      name: string;
      notation: string;
      power: number;
      rotationalSpeed: number;
      serialnumber: string;
    };
  };
  hideTopSection?: boolean;
  onHideTopSection?: () => void;
  onAddTask: () => void;
  onAddDocument: () => void;
  title?: string | undefined;
  description?: string | undefined;
  customBadge?: ReactElement;
}

const Overview = ({
  machine,
  status,
  hideTopSection,
  onHideTopSection,
  onAddTask,
  onAddDocument,
  title,
  description,
  customBadge,
}: OverviewProps) => {
  const { t } = useTranslation();
  const condition = {
    id: null,
    value: status,
  };

  return (
    <Stack className='overview-container'>
      <Stack horizontal style={{ gap: 16 }} className='overview-container__left-side'>
        <Stack style={{ borderRight: "1px solid #E1DFDD", paddingRight: 16 }}>
          <Text size={400} as='h2' className='overview-title'>
            {title ||
              t(`Condition Overview ({{machineName}})`, {
                machineName: machine?.name,
              })}
          </Text>
          <Text size={300} className='overview-subtitle' as='span'>
            {format(new Date())}
          </Text>
          {description && (
            <Text size={300} className='overview-description'>
              {description}
            </Text>
          )}
        </Stack>
        {onHideTopSection ? (
          <>
            <Switch
              label={t("Drawings & Conditions")}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 0 0 8px",
                fontWeight: 600,
              }}
              checked={!hideTopSection}
              onChange={(_, { checked }) => onHideTopSection && onHideTopSection()}
            />
          </>
        ) : null}
      </Stack>
      <Stack horizontal className='overview-container__right-side'>
        <StatusBadge
          data={condition}
          customBadge={customBadge}
          onAddTask={onAddTask}
          onAddDocument={onAddDocument}
        />
      </Stack>
    </Stack>
  );
};

export default Overview;
