import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../Hooks";
import {} from "../../../../schema/Constants";
import { Status } from "../../../../schema/status";
import BaseCommandBar from "../../../common/CommandBar";
import Table, { useTableFilters } from "../../../common/Table/v9";
import type { Configuration } from "../../models";
import { getColumnsConfigs } from "../columnsHelper";
import { selectMachDetails, selectMachineDetailsStatus } from "../reducer";

export default function ConfigsContent({ setActionEditConfiguration }) {
  const { t } = useTranslation();

  const status = useAppSelector(selectMachineDetailsStatus);

  const items = useAppSelector(selectMachDetails);

  const configsFilter = useTableFilters<Configuration>({
    keys: [
      "name",
      "boxType",
      "dalogType",
      "operator",
      "isMatCfg",
      "version",
      "time",
      "md5Hash",
      "isActive",
    ],
  });

  return (
    <>
      <BaseCommandBar items={[]} onSearch={configsFilter.handleSearch} />
      <Table
        persistOpts={{
          key: "table-configs",
          version: 2,
        }}
        header={{
          title: t("Configs"),
        }}
        items={items?.configurations}
        v8Columns={getColumnsConfigs({
          t,
          onEdit(config) {
            setActionEditConfiguration({
              data: {
                id: config.id,
                isActive: config.isActive,
                maxDataAgeInDaysError: config.maxDataAgeInDaysError,
                maxDataAgeInDaysWarning: config.maxDataAgeInDaysWarning,
              },
              context: "edit",
            });
          },
        })}
        filters={configsFilter.filters}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );
}
