import { Label, Spinner, Text } from "@fluentui/react-components";

import { Stack } from "../../../Stack";

/**
 * HOC to add a loading panel to a wrapped component when the data parameter is not available
 * @param data The data
 * @param wrappedComponent The wrapped component.
 * @returns The loading panel, when the data is null. Otherwise the wrapped component.
 */
export const withLoadingPanelHOC = (t, data: any, wrappedComponent: JSX.Element): JSX.Element => {
  let result: JSX.Element = (
    <Stack
      horizontalAlign='center'
      verticalAlign='center'
      role='loading panel'
      style={{ height: "100%", marginTop: "auto", marginBottom: "auto", gap: 5, padding: 5 }}
    >
      <Stack.Item>
        <Spinner size='small' />
      </Stack.Item>
      <Stack.Item>
        <Label>{t("Loading data..")}.</Label>
      </Stack.Item>
    </Stack>
  );
  if (data) {
    result = wrappedComponent;
  }

  return result;
};

/**
 * HOC to add a text to a wrapped component when the count parameter is zero
 * @param data The data
 * @param wrappedComponent The wrapped component.
 * @returns The text, when the count is zero. Otherwise the wrapped component.
 */
export const withNoItemsPanelHOC = (
  t,
  count: number,
  wrappedComponent: JSX.Element,
): JSX.Element => {
  let result: JSX.Element = (
    <Text size={300} block={true}>
      {t("No items.")}
    </Text>
  );

  if (count > 0) {
    result = wrappedComponent;
  }

  return result;
};
