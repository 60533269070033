import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import { Status } from "../../../../schema/status";
import TextField from "../../../common/TextField";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../../../Corporations/reducer";
import { useValidation } from "../hooks/useValidation";
import type { CorporationSubmission } from "../models";
import type { SecondPanelContext } from "../SubmissionsDetails";

interface CorporationFieldsProps {
  context: SecondPanelContext;
}

const corporationSchema = z.object({
  name: z.string().min(1, "Corporation name is required"),
});

export const CorporationFields: React.FC<CorporationFieldsProps> = ({ context }) => {
  const { t } = useTranslation();
  const corporations = useAppSelector(selectCorporations);
  const corporationStatus = useAppSelector(selectCorporationsStatus);
  const dispatch = useAppDispatch();
  const [corporationSub, setCorporationSub] = useState<CorporationSubmission>(
    context.initialValues.corpo,
  );

  useEffect(() => {
    if (corporationStatus === Status.void) dispatch(listAsyncCorpo());
  }, [dispatch, corporationStatus]);

  const {
    errors: validationErrors,
    validateField,
    setValue,
  } = useValidation({
    schema: corporationSchema,
    customValidators: {
      name: (value) => {
        if (!value?.trim()) {
          return "Corporation name is required";
        }
        if (corporations.some((corporation) => corporation.name === value)) {
          return "Corporation name already exists";
        }
        return undefined;
      },
    },
    mode: "onChange",
    defaultValues: corporationSub,
  });

  const handleFieldChange = async (newValue: string) => {
    const trimmedValue = newValue.trim();
    const corpNew: CorporationSubmission = {
      name: trimmedValue,
      uniqueId: corporationSub.uniqueId,
    };

    await validateField("name", trimmedValue);
    setValue("name", trimmedValue);
    setCorporationSub((prev) => ({ ...prev, ...corpNew }));
    context.updateFunction(corpNew, corporationSub.uniqueId);
  };

  return (
    <TextField
      value={corporationSub.name || ""}
      disabled={!!context.initialValues.corpo.id || context.context !== "Corporation"}
      label={`${t("Corporation")}:`}
      fieldWrapperProps={{
        validationMessage: validationErrors.name?.message,
      }}
      onChange={(newVal) => handleFieldChange(newVal || "")}
    />
  );
};
