import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import { ClockIcon } from "@fluentui/react-icons-mdl2";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BaseCommandBar from "../../../common/CommandBar";
import { CommandBarItemType, computeCommandBarItems } from "../../../common/CommandBar/methods";
import useThreadsStore from "../hooks/useThreadsStore";

type DocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onFilter: (days: any) => void;
  filter: any;
};

export const getThreadCreatedDropdown: (t) => any[] = (t) => [
  { key: 1, text: t("Today") },
  { key: 7, text: t("Past Week") },
  { key: 30, text: t("Past Month") },
  { key: 365, text: t("Past Year") },
  { key: 0, text: t("All Time") },
];

export const CommandBar = ({ handleSearch, onFilter, filter }: DocumentsCommandBarProps) => {
  const { t } = useTranslation();
  const onDelete = () => dispatch({ type: "SHOW_DELETE", payload: true });
  const onAdd = () => dispatch({ type: "SHOW_ADD", payload: true });

  const { selectedItems, dispatch } = useThreadsStore((state) => ({
    selectedItems: state.selectedItems as any[],
    dispatch: state.dispatch,
  }));

  const items: any[] = useMemo(
    () => [
      {
        key: "newThread",
        text: t("New thread"),
        type: CommandBarItemType.Button,
        icon: <AddRegular />,
        onClick: onAdd,
      },
      {
        key: "createdFilter",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: filter,
          options: getThreadCreatedDropdown(t),
          onChange: (_: never, item: { data: string }) => onFilter(item.data),
          onRenderLabelProps: {
            label: t("Time"),
            icon: <ClockIcon />,
          },
        },
      },
      {
        key: "delete",
        text: t("Delete"),
        type: CommandBarItemType.Button,
        icon: <DeleteRegular />,
        onClick: onDelete,
        ...(selectedItems.length === 0 && {
          disabled: true,
        }),
      },
    ],
    [selectedItems.length, t],
  );

  return <BaseCommandBar items={computeCommandBarItems(items)} onSearch={handleSearch} />;
};
