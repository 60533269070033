import { Text, Tooltip } from "@fluentui/react-components";

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Condition } from "../../../../modules/machine-cv/constants";
import { getConditionFormat } from "../../../../modules/machine-cv/constants";
import type { Column } from "../../../common/Table/v9";

export const getColumns = (t): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Signal"),
    minWidth: 50,
    maxWidth: 100,
    flexGrow: 1,
    isSortable: true,
    isResizable: true,
    onRender: (item) => (
      <Tooltip withArrow relationship='label' content={item.name}>
        <span>{item.name}</span>
      </Tooltip>
    ),
  },
  {
    key: "sensorNo",
    fieldName: "sensorNo",
    name: t("Sensor No"),
    minWidth: 80,
    isSortable: true,
    isResizable: true,
  },
  {
    key: "signalDescription",
    fieldName: "signalDescription",
    name: t("Description"),
    minWidth: 100,
    isSortable: true,
    isResizable: true,
    flexGrow: 2,
    onRender: (item: any) => (
      <Tooltip withArrow relationship='label' content={item.signalDescription}>
        <Text
          size={300}
          as='p'
          title={item.signalDescription}
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          {item.signalDescription}
        </Text>
      </Tooltip>
    ),
  },
  {
    key: "conditionKey",
    fieldName: "condition",
    name: t("Condition"),
    minWidth: 100,
    maxWidth: 100,
    isSortable: true,
    getCellClassName: (item) =>
      `Compact-DataGridCell Home-DataGridCell--${item?.condition} MachineCV-Summary-DataGridCell`,
    onRender: (item) => getConditionFormat(t)[item.condition as Condition],
  },
];
