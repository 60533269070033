import { default as chartSymbolsIcons } from "../../../../assets/svg/ChartSymbolsIcons";

type GetColumnsOpts = {
  t: any;
  header: string;
  hasProjectAndMachineCols: boolean;
};

export const getColumns = ({ t, header, hasProjectAndMachineCols }: GetColumnsOpts) => {
  const columns = [
    {
      key: "name",
      fieldName: "name",
      name: header,
      minWidth: 250,
      onRender: ({ name, index }) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 4, width: "max-content" }}>
            {chartSymbolsIcons.components[index as number]}
            {name}
          </div>
        );
      },
    },
    {
      key: "unit",
      fieldName: "unit",
      name: t("Unit"),
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "last",
      fieldName: "last",
      name: t("Last"),
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "min",
      fieldName: "min",
      name: t("Min"),
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "max",
      fieldName: "max",
      name: t("Max"),
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "avg",
      fieldName: "avg",
      name: t("Avg"),
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "stDev",
      fieldName: "stDev",
      name: t("StDev"),
      minWidth: 50,
      maxWidth: 100,
    },
  ];

  if (hasProjectAndMachineCols) {
    columns.splice(
      2,
      0,
      {
        key: "project",
        fieldName: "project",
        name: t("Project"),
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: "machine",
        fieldName: "machine",
        name: t("Machine"),
        minWidth: 100,
        maxWidth: 100,
      },
    );
  }

  return columns;
};
