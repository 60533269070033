import { Text } from "@fluentui/react-components";
import { useState } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useAppDispatch, useProjects } from "../../Hooks";
import { DialogSize } from "../common/Dialog";
/* eslint-disable react-hooks/exhaustive-deps */
import type { FormItemProps } from "../common/Form";
import { FormItemType, renderFormItems, useZodForm } from "../common/Form";
import { notification } from "../common/Notification";
import FormDialog from "../Generic/FormDialog";
import FormItemRow from "../Generic/FormItemRow";
import { DataloggersAPI } from "./api";
import type { DataloggerDialogsProps, DataloggerFormData } from "./formModels";
import type { D325, D850, D850Eco, DBasic } from "./models";
import { AdjustmentCardTypes, D850OS, DModels } from "./models";
import { listDataloggersAsync } from "./reducer";
import { Utils } from "./utils";

/**
 * Gets the form schema
 * @param model The current datalogger model.
 * @returns The form schema.
 */
const getFormSchema = (model, t) => {
  // Basic schema
  const defaultFormSchema = {
    projectId: z.string(),
    model: z.string(),
  };

  const d325Fields = {
    macAddress: z
      .string()
      .length(17, t("The MAC address must have the form XX-XX-XX-XX-XX-XX."))
      .regex(Utils.regexMacAddress, t("The MAC address must have the form XX-XX-XX-XX-XX-XX.")),
    firmwareVersion: z.string().min(1, { message: t("Please insert a Firmware version.") }),
  };

  const d555Fields = {
    ipAddress: z
      .union([
        z.string().length(0, {
          message: t("Please insert an IP address of the form xxx.xxx.xxx.xxx"),
        }),
        z.string().regex(Utils.regexIpAddress),
      ])
      .optional()
      .transform((e) => (e === "" ? "" : e)),
    subnetMask: z
      .union([
        z.string().length(0, {
          message: t("Please insert an IP address of the form xxx.xxx.xxx.xxx"),
        }),
        z.string().regex(Utils.regexIpAddress),
      ])
      .optional()
      .transform((e) => (e === "" ? "" : e)),
    gateway: z
      .union([
        z.string().length(0, {
          message: t("Please insert an IP address of the form xxx.xxx.xxx.xxx"),
        }),
        z.string().regex(Utils.regexIpAddress),
      ])
      .optional()
      .transform((e) => (e === "" ? "" : e)),
  };

  const d850EcoFields = {
    basisCardMacAddress: z
      .string()
      .length(17, t("The MAC address must have the form XX-XX-XX-XX-XX-XX."))
      .regex(Utils.regexMacAddress, t("The MAC address must have the form XX-XX-XX-XX-XX-XX.")),
    adjustmentCardType: z.string().optional(),
  };

  if (model === DModels.D325) {
    return {
      ...defaultFormSchema,
      ...d325Fields,
    };
  }

  if (model === DModels.D555) {
    return {
      ...defaultFormSchema,
      ...d325Fields,
      ...d555Fields,
    };
  }

  if (model === DModels.D650)
    return {
      ...defaultFormSchema,
      ...d325Fields,
      ...d555Fields,
    };

  if (model === DModels.D850) {
    return {
      ...defaultFormSchema,
      operativeSystem: z.string().min(1, { message: t("Please select an operative system.") }),
      ...d555Fields,
      ...d850EcoFields,
    };
  }

  if (model === DModels.D850Eco) {
    return {
      ...defaultFormSchema,
      ...d850EcoFields,
    };
  }
};

/**
 * Gets the form props.
 * @param model The current datalogger model.
 * @param disableModelSelection Value determining whether the model dropdown field should be disabled.
 * @param projectsRef The projects reference list.
 * @param onModelChanged Function called when the datalogger model is changed.
 * @returns The form item props array.
 */
const getFormProps = (model: DModels, projects, disableModelSelection, t): FormItemProps[] => {
  // Basic datalogger form fields.
  const result: FormItemProps[] = [
    {
      name: "projectId",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Project *") },
      options: Array.from(projects.values()).map((project: any) => {
        return { key: project.id, text: project.name };
      }),
    },
    {
      name: "model",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Model *") },
      options: Object.keys(DModels).map((model) => {
        return { key: model, text: DModels[model] };
      }),
      disabled: disableModelSelection,
    },
  ];

  switch (model) {
    case DModels.D325:
    case DModels.D555:
    case DModels.D650:
      result.push(
        {
          name: "macAddress",
          type: FormItemType.TextField,
          groupProps: { label: t("MAC Address *") },
        },
        {
          name: "firmwareVersion",
          type: FormItemType.TextField,
          groupProps: { label: t("Firmware Version *") },
        },
      );

      if (model !== DModels.D325) {
        result.push(
          {
            name: "ipAddress",
            type: FormItemType.TextField,
            groupProps: { label: t("IP Address") },
          },
          {
            name: "subnetMask",
            type: FormItemType.TextField,
            groupProps: { label: t("Subnet Mask") },
          },
          {
            name: "gateway",
            type: FormItemType.TextField,
            groupProps: { label: t("Gateway") },
          },
        );
      }
      break;

    case DModels.D850:
    case DModels.D850Eco:
      // Adds fields for the 850 and 850 Eco dataloggers.
      if (model === DModels.D850) {
        result.push(
          {
            name: "operativeSystem",
            type: FormItemType.Dropdown,
            groupProps: { label: t("Operative System *") },
            options: Object.keys(D850OS).map((os) => {
              return { key: os, text: D850OS[os] };
            }),
            placeholder: t("Select an operative system..."),
          },
          {
            name: "ipAddress",
            type: FormItemType.TextField,
            groupProps: { label: t("IP Address") },
          },
          {
            name: "subnetMask",
            type: FormItemType.TextField,
            groupProps: { label: t("Subnet Mask") },
          },
          {
            name: "gateway",
            type: FormItemType.TextField,
            groupProps: { label: t("Gateway") },
          },
        );
      }

      if (model === DModels.D850Eco || model === DModels.D850) {
        result.push(
          {
            name: "basisCardMacAddress",
            type: FormItemType.TextField,
            groupProps: { label: t("BasisCard MAC Address *") },
          },
          {
            name: "adjustmentCardType",
            type: FormItemType.Dropdown,
            groupProps: { label: t("Adjustment Card Type") },
            options: Object.keys(AdjustmentCardTypes).map((type) => {
              return { key: type, text: AdjustmentCardTypes[type] };
            }),
            placeholder: t("Select a type..."),
          },
        );
      }
      break;
  }

  return result;
};

const getDefaultValues = (
  model: DModels,
  entry: D325 | DBasic | D850Eco | D850,
): DataloggerFormData => {
  let result: DataloggerFormData = {
    projectId: entry?.projectId || "",
    model: Utils.getDModelsKey(model),
  };

  switch (model) {
    case DModels.D325:
      const d325Entry = entry as D325;
      if (d325Entry) {
        result = {
          ...result,
          macAddress: d325Entry.macAddress,
          firmwareVersion: d325Entry.firmwareVersion,
        };
      }

      break;
    case DModels.D555:
    case DModels.D650:
      const dBasicEntry = entry as DBasic;
      if (dBasicEntry) {
        result = {
          ...result,
          macAddress: dBasicEntry.macAddress,
          firmwareVersion: dBasicEntry.firmwareVersion,
          ipAddress: dBasicEntry.ipAddress ? dBasicEntry.ipAddress : "",
          subnetMask: dBasicEntry.subnetMask ? dBasicEntry.subnetMask : "",
          gateway: dBasicEntry.gateway ? dBasicEntry.gateway : "",
        };
      }

      break;

    case DModels.D850Eco:
      const d850EcoEntry = entry as D850Eco;
      if (d850EcoEntry) {
        result = {
          ...result,
          basisCardMacAddress: d850EcoEntry.basisCard.macAddress,
          adjustmentCardType: d850EcoEntry.adjustmentCard.adjustmentCardType,
        };
      }

      break;

    case DModels.D850:
      const d850Entry = entry as D850;
      if (d850Entry) {
        result = {
          ...result,
          operativeSystem: d850Entry.operativeSystem,
          basisCardMacAddress: d850Entry.basisCard.macAddress,
          adjustmentCardType: d850Entry.adjustmentCard.adjustmentCard,
          ipAddress: d850Entry.ipAddress ? d850Entry.ipAddress : "",
          subnetMask: d850Entry.subnetMask ? d850Entry.subnetMask : "",
          gateway: d850Entry.gateway ? d850Entry.gateway : "",
        };
      }
      break;
  }

  return result;
};

const DataloggerAddEditForm = ({
  model,
  title,
  entry,
  isLoading,
  disableModelSelection,
  onSubmit,
  onClose,
}: DataloggerDialogsProps) => {
  const { t } = useTranslation();

  const selectedModel = model || DModels.D850;
  const { projects } = useProjects();

  const formSchema = z.object(getFormSchema(selectedModel, t));

  const defaultValues: DataloggerFormData = entry
    ? getDefaultValues(selectedModel, entry)
    : {
        model: selectedModel,
        projectId: entry?.projectId || "",
        macAddress: "",
      };

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    defaultValues,
  });

  // Function called when the submit button is clicked.
  const onSubmitHandler = handleSubmit((formData: DataloggerFormData) => {
    onSubmit?.(formData);
  });

  return (
    <FormDialog
      title={title}
      size={DialogSize.M}
      isLoading={isLoading}
      isValid={isValid && isDirty}
      onSubmit={onSubmitHandler}
      onClose={onClose}
    >
      <FormItemRow label={t("Company")} style={{ marginBottom: "0.75em" }}>
        <Text size={300} style={{ fontWeight: 600 }}>
          {projects?.get(entry?.projectId)?.company?.name || ""}
        </Text>
      </FormItemRow>
      {renderFormItems(getFormProps(DModels[watch().model], projects, disableModelSelection, t), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};

/**
 * Builds a new datalogger entry.
 * @param formData The form data.
 * @returns The datalogger item.
 */
const buildNewDataloggerEntry = (formData: DataloggerFormData): D325 | DBasic | D850Eco | D850 => {
  const model = DModels[formData.model];
  let result: D325 | DBasic | D850Eco | D850;
  switch (model) {
    case DModels.D325:
      result = {
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        machineIds: [],
      } as D325;
      break;

    case DModels.D555:
    case DModels.D650:
      result = {
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        machineIds: [],
        ipAddress:
          !formData.ipAddress || formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          !formData.subnetMask || formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          !formData.gateway || formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as DBasic;
      break;

    case DModels.D850Eco:
      result = {
        projectId: formData.projectId,
        basisCard: {
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          adjustmentCardType: formData.adjustmentCardType,
        },
        machineIds: [],
      } as D850Eco;
      break;

    case DModels.D850:
      result = {
        projectId: formData.projectId,
        operativeSystem: formData.operativeSystem,
        basisCard: {
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          adjustmentCard: formData.adjustmentCardType,
        },
        machineIds: [],
        ipAddress:
          !formData.ipAddress || formData.ipAddress.length === 0
            ? null
            : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          !formData.subnetMask || formData.subnetMask.length === 0
            ? null
            : Utils.formatIpAddress(formData.subnetMask),
        gateway:
          !formData.gateway || formData.gateway.length === 0
            ? null
            : Utils.formatIpAddress(formData.gateway),
      } as D850;
      break;
  }

  return result;
};

/**
 * Builds an updated datalogger entry from an existing entry.
 * @param model The datalogger model.
 * @param item The existing entry.
 * @param formData The form data.
 * @returns The updated entry.
 */
const buildDataloggerEntry = (
  model: DModels,
  item: D325 | DBasic | D850Eco | D850,
  formData: DataloggerFormData,
): D325 | DBasic | D850Eco | D850 => {
  let result: D325 | DBasic | D850Eco | D850;
  switch (model) {
    case DModels.D325:
      result = {
        ...item,
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
      } as D325;
      break;

    case DModels.D555:
    case DModels.D650:
      result = {
        ...item,
        projectId: formData.projectId,
        macAddress: formData.macAddress.trim(),
        firmwareVersion: formData.firmwareVersion.trim(),
        ipAddress:
          formData.ipAddress.length === 0 ? null : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          formData.subnetMask.length === 0 ? null : Utils.formatIpAddress(formData.subnetMask),
        gateway: formData.gateway.length === 0 ? null : Utils.formatIpAddress(formData.gateway),
      } as DBasic;
      break;

    case DModels.D850Eco:
      const d850Eco = item as D850Eco;
      if (!d850Eco) {
        break;
      }

      result = {
        ...d850Eco,
        projectId: formData.projectId,
        basisCard: {
          ...d850Eco.basisCard,
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          ...d850Eco.adjustmentCard,
          adjustmentCardType: formData.adjustmentCardType,
        },
      } as D850Eco;
      break;

    case DModels.D850:
      const d850 = item as D850;
      if (!d850) {
        break;
      }

      result = {
        ...d850,
        operativeSystem: formData.operativeSystem,
        projectId: formData.projectId,
        basisCard: {
          ...d850.basisCard,
          macAddress: formData.basisCardMacAddress.trim(),
        },
        adjustmentCard: {
          ...d850.adjustmentCard,
          adjustmentCard: formData.adjustmentCardType,
        },
        ipAddress:
          formData.ipAddress.length === 0 ? null : Utils.formatIpAddress(formData.ipAddress),
        subnetMask:
          formData.subnetMask.length === 0 ? null : Utils.formatIpAddress(formData.subnetMask),
        gateway: formData.gateway.length === 0 ? null : Utils.formatIpAddress(formData.gateway),
      } as D850;
      break;
  }

  return result;
};

/**
 * Gets the datalogger add dialog component.
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The datalogger add dialog component.
 */
export const DataloggerAddDialog = ({ onClose }: DataloggerDialogsProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: DataloggerFormData) => {
    // Builds the new item.
    const model: DModels = DModels[formData.model];
    const newItem: D325 | DBasic | D850Eco | D850 = buildNewDataloggerEntry(formData);

    // Sends the request
    setIsLoading(true);
    DataloggersAPI.create(model, newItem).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          t(`Failure: Adding a new {{model}} datalogger. Please try again later.`, { model }),
        );
        return;
      }

      notification.success(t(`Success: Adding a new {{model}} datalogger.`, { model }));
      dispatch(listDataloggersAsync(model)());
      onClose();
    });
  };

  return (
    <DataloggerAddEditForm
      isLoading={isLoading}
      entry={null}
      title={t("Add New Datalogger")}
      disableModelSelection={false}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the datalogger edit dialog component.
 * @param model The datalogger model.
 * @param entry The datalogger table item entry
 * @param onClose Event handler that must be implemented to close this dialog.
 * @returns The datalogger edit dialog component.
 */
export const DataloggerEditDialog = ({ model, entry, onClose }: DataloggerDialogsProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: DataloggerFormData) => {
    // Builds the new item.

    let newItem: D325 | DBasic | D850Eco | D850 = buildDataloggerEntry(model, entry, formData);

    // Resets the machines Ids if the project entry has been changed.
    if (newItem.projectId !== entry.projectId) {
      newItem = { ...newItem, machineIds: [] };
    }

    // // Sends the request
    // setIsLoading(true);
    // DataloggersAPI.update(model, newItem).then((response) => {
    //   setIsLoading(false);
    //   if (response.status !== 200) {
    //     notification.error(
    //       t(`Failure: Updating a new {{model}} datalogger. Please try again later.`, { model }),
    //     );
    //     return;
    //   }

    //   notification.success(t(`Success: Updating a new {{model}} datalogger.`, { model }));
    //   dispatch(listDataloggersAsync(model)());
    //   onClose();
    // });
  };

  return (
    <DataloggerAddEditForm
      model={model}
      isLoading={isLoading}
      entry={entry}
      title={t("Edit Datalogger")}
      disableModelSelection={true}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
