import { t } from "i18next";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

interface LayoutStoreProps {
  layout: any | undefined;
  isLayoutReset: boolean | undefined;
  containerWidth: number;
  changeLayout: (trendsGrouping: any | undefined) => void;
  setLayoutReset: (value: boolean | undefined) => void;
  setContainerWidth: (value: number) => void;
}

const useLayoutStore = createWithEqualityFn<LayoutStoreProps>(
  (set) => ({
    layout: {
      key: "waveform-spectrum",
      text: t("Waveform and Spectrum"),
      data: { reset: true },
    },
    //layout: { key: 'waterfall', text: 'Waterfall', data: { reset: true } },
    isLayoutReset: false,
    containerWidth: 0,
    changeLayout: (layout: any | undefined) => set({ layout }),
    setLayoutReset: (value: boolean | undefined) => set({ isLayoutReset: value }),
    setContainerWidth: (value: number) => set({ containerWidth: value }),
  }),
  shallow,
);

export default useLayoutStore;
